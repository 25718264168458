import React, { FC } from "react";
import { Switch, Redirect, RouteComponentProps } from "react-router-dom";
import ParamsProvider from "src/app/common/components/ParamsProvider";
import MdrtStampIconDetailPage from "./Create/MdrtStampIconDetailPage";
import PruRoute from "src/app/common/components/PruRoute";
import { NOT_FOUND_ERROR_PAGE_PATH } from "src/app/common/constants";
import { MDRT_HUB_BASE_PATH, MDRT_STAMP_ICON_BASE_PATH } from "../../constants";
import MdrtStampIconListingPage from "./List/MdrtStampIconListingPage";

const MdrtStampIconRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={`${MDRT_HUB_BASE_PATH}${MDRT_STAMP_ICON_BASE_PATH}`}
        to={`${MDRT_HUB_BASE_PATH}${MDRT_STAMP_ICON_BASE_PATH}/list`}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${MDRT_STAMP_ICON_BASE_PATH}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={[]}>
            <MdrtStampIconListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${MDRT_STAMP_ICON_BASE_PATH}/edit/:id`}
        component={MdrtStampIconDetailPage}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${MDRT_STAMP_ICON_BASE_PATH}/create`}
        component={MdrtStampIconDetailPage}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default MdrtStampIconRoutes;
