export const NOTIFICATION_TEMPLATE_BASE = '/notification';

export const NOTIFICATION_BASE_PATH = '/notification';
export const NOTIFICATION_MANUAL_PATH = '/manual';
export const NOTIFICATION_AUTO_PATH = '/auto';
export const NOTIFICATION_AUTO_LOG_PATH = '/log';
export const NOTIFICATION_AUTO_CONFIG_PATH = '/config';

//  These constants map to aside menu, header menu to display title
export const NOTIFICATION_TITLE = 'title.notification';

//  These constants map to permission control, identical to permission enum defined below
export const NOTIFICATION_NAMESPACE = 'NOTIFICATION';
export const AUTO_NOTIFICATION_CONFIG_NAMESPACE = 'AUTO-NOTIFICATION-CONFIG';
export const AUTO_NOTIFICATION_LOG_NAMESPACE = 'AUTO-NOTIFICATION-LOG';

export enum AdminNotificationPermissionType {
  NOTIFICATION_READ = 'READ_ANY_NOTIFICATION',
  NOTIFICATION_CREATE = 'CREATE_ANY_NOTIFICATION',
  NOTIFICATION_UPDATE = 'UPDATE_ANY_NOTIFICATION',
  AUTO_NOTIFICATION_CONFIG_READ = 'READ_ANY_AUTO-NOTIFICATION-CONFIG',
  AUTO_NOTIFICATION_CONFIG_CREATE = 'CREATE_ANY_AUTO-NOTIFICATION-CONFIG',
  AUTO_NOTIFICATION_CONFIG_UPDATE = 'UPDATE_ANY_AUTO-NOTIFICATION-CONFIG',
  AUTO_NOTIFICATION_LOG_READ = 'READ_ANY_AUTO-NOTIFICATION-LOG',
  AUTO_NOTIFICATION_LOG_CREATE = 'CREATE_ANY_AUTO-NOTIFICATION-LOG',
  AUTO_NOTIFICATION_LOG_UPDATE = 'UPDATE_ANY_AUTO-NOTIFICATION-LOG',
}

export enum NotificationType {
  INBOX = 'inbox',
  PUSH = 'push',
  PUSH_INBOX = 'push-inbox',
}

export enum InboxStatusType {
  WAIT_FOR_FETCH = 'WAIT_FOR_FETCH',
  FETCHING = 'FETCHING',
  FETCHED = 'FETCHED',
}

export enum ContentType {
  FIXED = 'FIXED',
  VARIABLE = 'VARIABLE',
}

export enum RedirectType {
  pageInApp = 'app',
  URL = 'url',
  notDirect = 'no',
}

export enum OpenInDevice {
  external = 'external',
  webview = 'webview',
}

export enum AddressType {
  STATIC_URL = 'staticUrl',
  VARIABLE_URL = 'variableUrl',
}

export enum ReceiverType {
  'all-pruforce-users' = 'ALL',
  'specific-receivers' = 'TARGET',
  'exclusive-receivers' = 'EXCLUSIVE',
  'designation-receivers' = 'GROUP',

  //PVA
  'all-traditional-agents' = 'ALL_AGENT',
  'pruventure' = 'PRUVENTURE',
  'all-ad-bdm' = 'ALL_BDM',
  'pruventure-bdm' = 'PRUVENTURE_BDM',
  'group-of-users' = 'GROUP_OF_USERS',
}

export enum NotificationFilterReceiverType {
  'specific-receivers' = 'TARGET',
  'all-pruforce-users' = 'ALL',
}

export enum TriggerTime {
  sendImmediately = 'immediately',
  specificTime = 'specific',
}

export enum SetOnTop {
  yes = 'Y',
  no = 'N',
}

export const DATE_ERROR_TEXT = 'End date must be after Begin date';

export const MANDATORY_FIELD_ERROR_TEXT = 'This field is mandatory';

export const DUPLICATED_AGENT_CODE = 'Has duplicated agent code';

export const DATE_FORMAT_ERROR_TEXT = 'Invalid date format';

export const LINK_INVALID_ERROR = 'component.form-link-text';

export const duplicatedAgentcode = 'E002';

export enum notificationFrom {
  portal = 'PORTAL',
  system = 'SYSTEM',
}

export const NOTIFICATION_MANUAL_TITLE = 'menu.notification.manual';
export const NOTIFICATION_AUTO_TITLE = 'menu.notification.auto';
export const NOTIFICATION_AUTO_LOG = 'menu.notification.auto.log';
export const NOTIFICATION_AUTO_CONFIG = 'menu.notification.auto.config';

//PVA
export const InboxContentType = [
  { label: 'Fixed template', value: 'FIXED' },
  { label: 'Customized template', value: 'CUSTOMIZED' },
];
export const ListReceiver = [
  { label: 'All traditional agents', value: 'ALL_AGENT' },
  { label: 'Pruventure', value: 'PRUVENTURE' },
  { label: 'AD BDM', value: 'ALL_BDM' },
  { label: 'Pruventure BDM', value: 'PRUVENTURE_BDM' },
  { label: 'Group of users', value: 'GROUP_OF_USERS' },
];
const CategoryTypeDefault: { [key: string]: string } = {
  System: 'inbox_system',
  Performance: 'inbox_performance',
  Recruitment: 'inbox_recruitment',
  Training: 'inbox_training',
  Policy: 'inbox_policy',
  PulseLeads: 'inbox_leads',
  Sales: 'inbox_sales',
  Event: 'inbox_event',
};
const categoryType: { [key: string]: any } = {};
(window.envConfig['ADMIN_PORTAL_NOTIFICATION_CATEGORY_TYPE_ENABLED'] || '').split(',').forEach((item: string) => {
  categoryType[item] = CategoryTypeDefault[item];
});
export const CategoryType = categoryType;
