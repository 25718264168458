import { FC } from 'react';
import { useIntl } from 'react-intl';
import { map } from 'lodash';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
  Radio,
  Checkbox,
} from '@mui/material';
import { regionLocale } from 'src/app/i18n';
import { FormMode } from 'src/app/common/types';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import {
  EventAccessibilityEnum,
  EventAudienceEnum,
  EventCategoryStatusEnum,
  EventCategoryItem,
  EventHostEnum,
} from 'src/app/modules/event-v2/types';
import { useStyles } from './event-category-form-dialog.style';
import { useEventCategoryFormDialog } from './event-category-form-dialog.hook';

type EventCategoryFormDialogProps = {
  open: boolean;
  formMode: FormMode;
  eventCategoryItem?: EventCategoryItem;
  onClose: () => void;
  onRefresh: () => void;
};

export const EventCategoryFormDialog: FC<EventCategoryFormDialogProps> = ({
  open,
  formMode,
  eventCategoryItem,
  onClose,
  onRefresh,
}) => {
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();

  // custom-hook
  const { formState, reminderDialog, errorState, formDispatch, setReminderDialog, onDismissErrorHandler, onSubmit } =
    useEventCategoryFormDialog({
      formMode,
      eventCategoryItem,
      onClose,
      onRefresh,
    });
  const viewMode = formMode === FormMode.VIEW;

  return (
    <>
      <Dialog open={reminderDialog.open} classes={{ paper: classes.reminderDialogPaper }}>
        <DialogTitle>{Translation('reminder_title')}</DialogTitle>
        <DialogContent>{Translation('event.common.inactive_category_reminder')}</DialogContent>
        <DialogActions>
          <Button className={classes.blueText} onClick={() => setReminderDialog({ open: false })}>
            {Translation('app.button.cancel')}
          </Button>
          <Button
            className={classes.blueText}
            onClick={() => {
              onSubmit();
              setReminderDialog({ open: false });
            }}
          >
            {Translation('app.button.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog classes={{ paper: classes.dialogPaper }} open={open} onClose={onClose}>
        <div className={classes.container}>
          {regionLocale.map((locale) => (
            <div key={`category-name-${locale}`} className={`${classes.rowContainer} ${classes.rowSpacing}`}>
              <div className={classes.labelContainer}>
                <span className={classes.label}>
                  {Translation(`event.common.category_name.${locale}`)}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <div className={classes.fieldContainer}>
                <TextField
                  disabled={viewMode}
                  fullWidth
                  className={classes.noMargin}
                  margin="dense"
                  variant="outlined"
                  error={errorState.mandatory[`name-${locale}`]}
                  helperText={errorState.mandatory[`name-${locale}`] && MANDATORY_FIELD_ERROR_TEXT}
                  value={formState.name[locale] || ''}
                  onChange={(e) => {
                    onDismissErrorHandler(`name-${locale}`, e.target.value);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'name', value: { ...formState.name, [locale]: e.target.value } },
                    });
                  }}
                />
              </div>
            </div>
          ))}
          <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
            <div className={classes.labelContainer}>
              <span className={classes.label}>{Translation('event.common.description')} :</span>
            </div>
            <div className={classes.fieldContainer}>
              <TextField
                disabled={viewMode}
                fullWidth
                multiline
                className={classes.noMargin}
                margin="dense"
                variant="outlined"
                InputProps={{
                  classes: {
                    input: classes.textArea,
                  },
                }}
                value={formState.description || ''}
                onChange={(e) => {
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'description', value: e.target.value },
                  });
                }}
              />
            </div>
          </div>
          {/* Hide PVA 24.04 */}
          {/* <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
            <div className={classes.labelContainer}>
              <span className={classes.label}>
                {Translation('event.common.host')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div className={classes.fieldContainer}>
              <FormControl disabled={formMode !== FormMode.CREATE} error={errorState.mandatory.host}>
                <div className={classes.radioGroup}>
                  {map(EventHostEnum, (option) => (
                    <FormControlLabel
                      className={classes.formControlLabel}
                      key={`host-${option}`}
                      checked={formState.host?.includes(option)}
                      control={
                        <Checkbox
                          onChange={(e) => {
                            const curVal = e.target.value as EventHostEnum;
                            const oldHosts = formState.host ? [...formState.host] : [];
                            let newHosts;
                            if (e.target.checked) {
                              newHosts = [...oldHosts, curVal];
                            } else {
                              newHosts = oldHosts?.filter((item) => item !== curVal);
                            }
                            onDismissErrorHandler('host', e.target.value);
                            formDispatch({
                              type: 'MODIFY_FIELD',
                              payload: { field: 'host', value: newHosts?.length > 0 ? newHosts : undefined },
                            });
                          }}
                        />
                      }
                      label={Translation(`event.common.host.${option.toLowerCase()}`)}
                      value={option}
                    />
                  ))}
                </div>
                {errorState.mandatory.host && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
              </FormControl>
            </div>
          </div> */}
          <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
            <div className={classes.labelContainer}>
              <span className={classes.label}>
                {Translation('event.common.accessibility')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div className={classes.fieldContainer}>
              <FormControl disabled={formMode !== FormMode.CREATE} error={errorState.mandatory.accessibility}>
                <RadioGroup
                  className={classes.radioGroup}
                  value={formState.accessibility || ''}
                  onChange={(e) => {
                    onDismissErrorHandler(['accessibility', 'audience'], e.target.value);
                    formDispatch({
                      type: 'SET_FORM_STATE',
                      payload: {
                        value: {
                          accessibility: e.target.value as EventAccessibilityEnum,
                          audience:
                            e.target.value === EventAccessibilityEnum.INTERNAL ? EventAudienceEnum.AGENT : undefined,
                        },
                      },
                    });
                  }}
                >
                  {map(EventAccessibilityEnum, (option) => (
                    <FormControlLabel
                      className={classes.formControlLabel}
                      key={`accessibility-${option}`}
                      control={<Radio />}
                      label={Translation(`event.common.accessibility.${option.toLowerCase()}`)}
                      value={option}
                    />
                  ))}
                </RadioGroup>
                {errorState.mandatory.accessibility && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
              </FormControl>
            </div>
          </div>
          {/* Hide PVA 24.04 */}
          {/* {formState.accessibility === EventAccessibilityEnum.PUBLIC && (
            <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
              <div className={classes.labelContainer}>
                <span className={classes.label}>
                  {Translation('event.common.audience')}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <div className={classes.fieldContainer}>
                <FormControl disabled={formMode !== FormMode.CREATE} error={errorState.mandatory.audience}>
                  <RadioGroup
                    className={classes.radioGroup}
                    value={formState.audience || ''}
                    onChange={(e) => {
                      onDismissErrorHandler('audience', e.target.value);
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: { field: 'audience', value: e.target.value },
                      });
                    }}
                  >
                    <FormControlLabel
                      className={classes.formControlLabel}
                      key={`audience-${EventAudienceEnum.SALES_PROSPECT}`}
                      control={<Radio />}
                      label={Translation(`event.common.audience.${EventAudienceEnum.SALES_PROSPECT.toLowerCase()}`)}
                      value={EventAudienceEnum.SALES_PROSPECT}
                    />
                    <FormControlLabel
                      className={classes.formControlLabel}
                      key={`audience-${EventAudienceEnum.RECRUITMENT_PROSPECT}`}
                      control={<Radio />}
                      label={Translation(
                        `event.common.audience.${EventAudienceEnum.RECRUITMENT_PROSPECT.toLowerCase()}`,
                      )}
                      value={EventAudienceEnum.RECRUITMENT_PROSPECT}
                    />
                  </RadioGroup>
                  {errorState.mandatory.audience && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
                </FormControl>
              </div>
            </div>
          )} */}
          <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
            <div className={classes.labelContainer}>
              <span className={classes.label}>
                {Translation('event.common.status')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div className={classes.fieldContainer}>
              <FormControl disabled={viewMode} error={errorState.mandatory.status}>
                <RadioGroup
                  className={classes.radioGroup}
                  value={formState.status || ''}
                  onChange={(e) => {
                    onDismissErrorHandler('status', e.target.value);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'status', value: e.target.value },
                    });
                  }}
                >
                  {map(EventCategoryStatusEnum, (option) => (
                    <FormControlLabel
                      className={classes.formControlLabel}
                      key={`status-${option}`}
                      control={<Radio />}
                      label={Translation(`event.common.category_status.${option.toLowerCase()}`)}
                      value={option}
                    />
                  ))}
                </RadioGroup>
                {errorState.mandatory.status && <FormHelperText>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>}
              </FormControl>
            </div>
          </div>
          <div className={classes.footerContainer}>
            <Button variant="contained" color="inherit" onClick={() => onClose()}>
              {Translation('app.button.cancel')}
            </Button>
            <Button
              disabled={viewMode}
              variant="contained"
              color="secondary"
              onClick={() => {
                if (formState.status === EventCategoryStatusEnum.INACTIVE) {
                  setReminderDialog({ open: true });
                } else {
                  onSubmit();
                }
              }}
            >
              {Translation('app.button.confirm')}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
