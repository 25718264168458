import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useDataProvider } from 'src/app/common/utils';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { PaginateList } from 'src/app/common/types/common-types';
import { LeadReassignmentFailedItem } from 'src/app/modules/PulseLeads/types/lead-reassignment-types';
import {
  fetchLeadReassignmentFailedList,
  LeadReassignmentFailedListParam,
} from 'src/app/modules/PulseLeads/network/leadReassignmentCrud';
import LeadReassignmentFailedList from './components/LeadReassignmentFailedList';

type LeadReassignmentFailedListingPageProps = {} & ParamsProps;

const initialState: LeadReassignmentFailedListParam = {
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): LeadReassignmentFailedListParam => {
  return initialParams
    ? {
        page: 1,
        limit: 20,
      }
    : initialState;
};

const LeadReassignmentFailedListingPage: FC<LeadReassignmentFailedListingPageProps> = ({
  initialParams,
  onChangeQueryParams,
}) => {
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();
  const history = useHistory();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<LeadReassignmentFailedListParam>(paramsInitiator(initialParams));
  const [leadReassignmentFailedList, setLeadReassignmentFailedList] =
    useState<PaginateList<LeadReassignmentFailedItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<LeadReassignmentFailedItem>>(
    async () => {
      if (id) {
        try {
          if (onChangeQueryParams) {
            onChangeQueryParams(filterState);
          }
          return await fetchLeadReassignmentFailedList(id, filterState, dispatch);
        } catch (err) {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        }
      }
    },
    setLeadReassignmentFailedList,
    false,
  );

  return (
    <>
      <LeadReassignmentFailedList
        isLoading={isLoading}
        leadReassignmentFailedList={leadReassignmentFailedList}
        onRefresh={refreshData}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default LeadReassignmentFailedListingPage;
