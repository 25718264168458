import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import Form from 'src/app/common/components/Form';
import FileUploader from 'src/app/common/components/FileUploader';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';

interface FormListProp {
  disabled: boolean;
  form: any;
  classes?: any;
}

export default function SubFormLiveStreamCreate(props: FormListProp) {
  const { disabled, classes } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [errors, SetErrors] = useState<Record<string, boolean>>({});

  const DEFAULT_REQUIRED_RULES = [
    { required: true, message: MANDATORY_FIELD_ERROR_TEXT },
    () => ({
      async validator(rule: any, value: any) {
        if (value) {
          if (errors[rule.field]) {
            SetErrors({ ...errors, [rule.field]: false });
          }
        } else {
          if (!errors[rule.field]) {
            SetErrors({ ...errors, [rule.field]: true });
          }
          throw new Error(`${rule.field} is required`);
        }
      },
    }),
  ];

  const pleaseEnter = Translation('app.input.placeholder.please-enter');

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        { mimeType: fileType, accessLevel: 'anonymous', module: 'livestream' },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        return { id: result.blobId, fileName: fileName, url: result.url, type: fileType };
      }
    } catch (err) {
    } finally {
    }
  };

  return (
    <>
      <Form.Item
        name={'name'}
        label={Translation('livestream.management.formLabel.name')}
        rules={DEFAULT_REQUIRED_RULES}
      >
        <TextField
          className={classes.borderError}
          error={errors?.name}
          disabled={disabled}
          margin="dense"
          variant="outlined"
          fullWidth
          placeholder={pleaseEnter}
          inputProps={{ maxLength: 200 }}
        />
      </Form.Item>
      <Form.Item
        name={'description'}
        label={Translation('livestream.management.formLabel.description')}
        rules={DEFAULT_REQUIRED_RULES}
      >
        <TextField
          className={classes.borderError}
          error={errors?.description}
          disabled={disabled}
          margin="dense"
          variant="outlined"
          fullWidth
          multiline
          placeholder={pleaseEnter}
          inputProps={{ maxLength: 1000 }}
          InputProps={{
            classes: {
              input: classes.textArea,
            },
          }}
        />
      </Form.Item>
      <Form.Item
        name={'avatar'}
        label={Translation('livestream.management.formLabel.avater')}
        rules={DEFAULT_REQUIRED_RULES}
      >
        <FileUploader
          color="secondary"
          disabled={disabled}
          showAllowText={Translation('component.formSelectItem.image_max_5MB')}
          upload={handleUploadFile}
          allowedFileTypes={['jpg', 'png', 'jpeg', 'mp4']}
          maxFileSize={5}
          showPreview
          needCompressImage={true}
        />
      </Form.Item>
      <Form.Item
        name={'banner'}
        label={Translation('livestream.management.formLabel.banner')}
        rules={DEFAULT_REQUIRED_RULES}
      >
        <FileUploader
          color="secondary"
          disabled={disabled}
          showAllowText={Translation('component.formSelectItem.image_max_5MB')}
          upload={handleUploadFile}
          allowedFileTypes={['jpg', 'png', 'jpeg', 'mp4']}
          maxFileSize={5}
          showPreview
          needCompressImage={true}
        />
      </Form.Item>
    </>
  );
}
