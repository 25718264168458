import React, { FC } from "react";
import { Switch, Redirect, RouteComponentProps } from "react-router-dom";
import ParamsProvider from "src/app/common/components/ParamsProvider";
import PruRoute from "src/app/common/components/PruRoute";
import { NOT_FOUND_ERROR_PAGE_PATH } from "src/app/common/constants";
import { MDRT_HUB_BASE_PATH, ACHIEVEMENT_UPLOAD_BASE_PATH } from "../../constants";
import AchievementUploadListingPage, { achievementUploadFilterKeys } from "./List/AchievementUploadListingPage";
import UploadDetailPage from "./Upload/UploadDetailPage";
import AchievementDetailPage from "./Create/AchievementDetailPage";

const AchievementUploadRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={`${MDRT_HUB_BASE_PATH}${ACHIEVEMENT_UPLOAD_BASE_PATH}`}
        to={`${MDRT_HUB_BASE_PATH}${ACHIEVEMENT_UPLOAD_BASE_PATH}/list`}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${ACHIEVEMENT_UPLOAD_BASE_PATH}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={achievementUploadFilterKeys}>
            <AchievementUploadListingPage />
          </ParamsProvider>
        )}
      />
       <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${ACHIEVEMENT_UPLOAD_BASE_PATH}/add`}
        component={AchievementDetailPage}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${ACHIEVEMENT_UPLOAD_BASE_PATH}/edit/:id`}
        component={AchievementDetailPage}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${ACHIEVEMENT_UPLOAD_BASE_PATH}/upload`}
        component={UploadDetailPage}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default AchievementUploadRoutes;
