import { useState } from 'react';
import { Radio, FormControlLabel } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import FileUploader from '../FileUploader';
import { FileObj } from 'src/app/modules/Notification/types/notification-types';
import { createBlob, getBlob } from '../../network';
import { fileUpload, toAbsoluteUrl } from '../../utils';
import { useDispatch } from 'react-redux';
import { Translation } from 'src/app/i18n';

interface CheckGroupProps {
  value?: any;
  onChange?: any;
  options: any[];
  disabled: boolean;
  moduleType?: string;
}

export default function RadioGroupBoolean(props: CheckGroupProps) {
  const [optionValue, setOptionValue] = useState(props.value);

  const [userFile, SetUserFile] = useState<FileObj | undefined>();

  const dispatch = useDispatch();

  const handleChange = (key: string, index: number) => (e: any) => {
    const value = e.target.value;
    // props.options[index].value = checked;
    setOptionValue((prev: any) => {
      prev.type = value;

      props.onChange(prev);
      return prev;
    });
  };

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        {
          mimeType: fileType,
          accessLevel: 'anonymous',
          // module: `material_${(moduleType || '').toLowerCase()}`,
        },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        return {
          id: result.blobId,
          fileName: fileName,
          url: result.url,
          type: fileType,
        };
      }
    } catch (err) {
    } finally {
    }
  };

  const onChangeFileUploader = (item: any) => {
    setOptionValue((prev: any) => {
      prev.userFile = item;

      props.onChange(prev);

      return prev;
    });

    return item ? item : undefined;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%',
        marginBottom: 10,
      }}
    >
      <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
        {props.options.map((item: any, index: any) => {
          return (
            <>
              <FormControlLabel
                key={item.key}
                control={
                  <Radio
                    name="radio"
                    disabled={props.disabled || item?.isRequired}
                    onChange={handleChange(item.value, index)}
                    value={item.value}
                    checked={optionValue.type === item.value}
                  />
                }
                label={item.label}
              />
            </>
          );
        })}
      </RadioGroup>

      {props.options.map((item, index) => (
        <>
          {item.isUpload && optionValue.type == 'targetsCustomer' && (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FileUploader
                  disabled={props.disabled}
                  upload={handleUploadFile}
                  value={props.value.userFile}
                  onChange={onChangeFileUploader}
                  showAllowText={'Upload the excel file list of customer'}
                  maxFileSize={10}
                  allowedFileTypes={[
                    'csv',
                    'xlsx',
                    'xls',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-excel',
                  ]}
                />
              </div>
              <br />
              {!props.value.userFile && (
                <div
                  style={{
                    flexBasis: '100%',
                    height: 'auto',
                  }}
                >
                  <a href={toAbsoluteUrl('/templates/Template_Upload_Client_Number.csv')}>
                    {Translation('recruitment.form.downloadTemplate')}
                  </a>
                </div>
              )}
            </>
          )}
        </>
      ))}
    </div>
  );
}
