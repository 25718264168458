import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { SurveyPaginateList } from '../../../../types/survey-types';
import PruTable from '../../../../../../common/components/PruTable/PruTable';
import { SURVEY_BASE_PATH, SURVEY_LEADER_PATH } from '../../../../constants';
import { useHistory } from 'react-router-dom';
import { getDefaultDisplayDate } from '../../../../../../common/utils/time-utils';

type AgentLeaderSurveyListProps = {
  isLoading: boolean;
  surveyList?: SurveyPaginateList;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (sortingState: { key: string; value?: string }) => void;
};

const AgentLeaderSurveyList: FC<AgentLeaderSurveyListProps> = ({
  isLoading,
  onRefresh,
  onChangePage,
  surveyList,
  onSort,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <PruTable
      disableBulkSelect
      dataSource={surveyList?.items}
      isLoading={isLoading}
      onRefresh={onRefresh}
      title={Translation('survey.list')}
      headerBtnDef={[
        {
          color: 'primary',
          title: Translation('survey.newSurvey'),
          onClick: () => history.push(`${SURVEY_BASE_PATH}${SURVEY_LEADER_PATH}/create`),
        },
      ]}
      operationDef={[
        {
          title: Translation('section.common.operation.edit'),
          tooltipText: 'Edit Survey',
          onClick: (row) => history.push(`${SURVEY_BASE_PATH}${SURVEY_LEADER_PATH}/edit/${row.id}`),
        },
        {
          title: Translation('section.common.operation.viewResults'),
          tooltipText: 'View Survey Results',
          onClick: (row) => history.push(`${SURVEY_BASE_PATH}${SURVEY_LEADER_PATH}/${row.id}/results`),
        },
      ]}
      columnDef={[
        {
          isId: true,
          hidden: true,
          keyIndex: 'id',
          displayName: '',
          renderData: () => '',
        },
        {
          keyIndex: 'title',
          displayName: Translation('survey.common.title'),
          renderData: (row) => row.title,
        },
        {
          keyIndex: 'noOfQuestions',
          align: 'center',
          displayName: Translation('survey.common.table.status'),
          renderData: (row) => String(row.status),
        },
        {
          keyIndex: 'startDate',
          align: 'center',
          displayName: Translation('survey.common.startDate'),
          renderData: (row) => getDefaultDisplayDate(row.startDate, 'datetime'),
        },
        {
          keyIndex: 'endDate',
          align: 'center',
          displayName: Translation('survey.common.endDate'),
          renderData: (row) => getDefaultDisplayDate(row.endDate, 'datetime'),
        },
        {
          keyIndex: 'createDateSort',
          align: 'center',
          displayName: Translation('survey.common.createdAt'),
          renderData: (row) => getDefaultDisplayDate(row.createdAt, 'datetime'),
          sortable: true,
          onSort: (sort) => {
            onSort({ key: 'createDateSort', value: sort['createDateSort'] });
          },
        },
        {
          keyIndex: 'lastUpdateDateSort',
          align: 'center',
          displayName: Translation('survey.common.updatedAt'),
          sortable: true,
          onSort: (sort) => {
            onSort({
              key: 'lastUpdateDateSort',
              value: sort['lastUpdateDateSort'],
            });
          },
          renderData: (row) => getDefaultDisplayDate(row.updatedAt, 'datetime'),
        },
      ]}
      totalPages={surveyList?.totalPages}
      totalRecords={surveyList?.totalRecords}
      onChangePage={onChangePage}
    />
  );
};

export default AgentLeaderSurveyList;
