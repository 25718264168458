import { makeStyles } from 'tss-react/mui';
import { Select, SelectChangeEvent, Theme, Typography } from '@mui/material';
import React, { FC } from 'react';
import { Link, Redirect, Switch } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import { toAbsoluteUrl } from 'src/app/common/utils';
import Callback from './Callback';
import StaffLogin from './StaffLogin';

// PVA
const TMS_LOGIN_URL = `${window.envConfig['REACT_APP_TMS_LOGIN_URL']}`;
const MAGNUM_PURE_LOGIN_URL = `${window.envConfig['REACT_APP_MAGNUM_PURE_LOGIN_URL']}`;

const AuthPage: FC = () => {
  // PVA
  const [loginSystem, setLoginSystem] = React.useState('More');
  const handleLoginSystemChange = (event: SelectChangeEvent) => {
    setLoginSystem((prev) => {
      if (event.target.value === 'TMS') {
        window.location.href = TMS_LOGIN_URL;
      } else if (event.target.value === 'MagnumPure') {
        window.location.href = MAGNUM_PURE_LOGIN_URL;
      }
      return event.target.value;
    });
  };

  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.aside}>
        <Link to="/" className={classes.logo}>
          <img
            alt="Logo"
            className={classes.image}
            src={toAbsoluteUrl('/media/pruforce-logo/pruforce_logo_color.png')}
          />
        </Link>
        <div className={classes.asideContent}>
          <h3 className={classes.title}>PRUforce Admin Portal</h3>
          <p className={classes.subtitle}>Manage PRUforce data via this portal.</p>
        </div>
        <Typography className={classes.AsideFooter}>&copy; 2020 Prudential</Typography>
      </div>

      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          <Switch>
            <PruRoute path="/auth/login/callback" component={Callback} />
            <PruRoute path="/auth/login" component={StaffLogin} />
            {/* <PruRoute path="/auth/login" component={Login} /> */}
            <Redirect exact from="/auth" to="/auth/login" />
            <Redirect to="/auth/login" />
          </Switch>
        </div>
        <div className={classes.mobileFooterWrapper}>
          <Select native value={loginSystem} onChange={handleLoginSystemChange}>
            <option value={'More'}>&nbsp;More</option>
            <option value={'TMS'}>&nbsp;TMS</option>
            <option value={'MagnumPure'}>&nbsp;Magnum Pure</option>
          </Select>
          <Typography className={classes.AsideMobileFooter}>&copy; 2020 Prudential</Typography>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffff',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  aside: {
    backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-2.jpg')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    padding: 32,
    display: 'flex',
    flexDirection: 'column',
    color: '#ffffff',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 600,
      flex: 1,
    },
  },
  logo: {
    marginTop: 16,
  },
  image: {
    maxHeight: 55,
  },
  asideContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  title: {
    fontSize: 26,
    marginBottom: 16,
  },
  subtitle: {
    opacity: 0.8,
    fontWeight: 'lighter',
  },
  AsideFooter: {
    marginTop: 32,
    opacity: 0.7,
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  mobileFooterWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    marginTop: 16,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  AsideMobileFooter: {
    marginBottom: '0.5rem',
    marginTop: '0.5rem',
    color: '#7E8299',
    fontWeight: 500,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    padding: '1.75rem',
    overflow: 'hidden',
  },
  content: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      marginTop: '7.5rem',
    },
  },
  loginTitle: {
    fontSize: 26,
  },
  loginSubtitle: {
    color: '#B5B5C3',
    fontWeight: 500,
    marginBottom: 65,
  },
}));
