const env = window.envConfig['REACT_APP_ENV'];

type EVENT_TEMPLATE_CONFIG_TYPE = {
  [key: `TEMPLATE_${string}`]: {
    id: string;
    label: string;
    url: string;
  };
};

export const EVENT_GP_TEMPLATE_CONFIG = {
  TEMPLATE_1: {
    id: 'AD_EVENT_GP_1',
    label: '1 - GP thường (Đứng, hình ảnh trên)',
    url: env === 'prod' ? 'https://pru-force-bdm-gp.webflow.io/' : 'https://pru-force-bdm-gp-uat.webflow.io/',
  },
  TEMPLATE_2: {
    id: 'AD_EVENT_GP_2',
    label: '2 - GP đặc biệt (Đứng, hình ảnh giữa)',
    url:
      env === 'prod'
        ? 'https://pru-force-bdm-gp.webflow.io/no-title'
        : 'https://pru-force-bdm-gp-uat.webflow.io/no-title',
  },
  TEMPLATE_3: {
    id: 'AD_EVENT_GP_3',
    label: '3 - GP đặc biệt (Đứng, fix tên chương trình)',
    url:
      env === 'prod'
        ? 'https://pru-force-bdm-gp.webflow.io/no-banner'
        : 'https://pru-force-bdm-gp-uat.webflow.io/no-banner',
  },
  TEMPLATE_4: {
    id: 'AD_EVENT_GP_4',
    label: '4 - GP thường (Ngang, hình ảnh bên trái)',
    url:
      env === 'prod'
        ? 'https://pru-force-bdm-gp.webflow.io/landscape'
        : 'https://pru-force-bdm-gp-uat.webflow.io/landscape',
  },
};

export const EVENT_BOP_TEMPLATE_CONFIG = {
  TEMPLATE_1: {
    id: 'AD_EVENT_BOP_1',
    label: '1 - BOP thường (Đứng, hình ảnh trên)',
    url: env === 'prod' ? 'https://pru-force-bdm-bop.webflow.io/' : 'https://pru-force-bdm-bop-uat.webflow.io/',
  },
  TEMPLATE_2: {
    id: 'AD_EVENT_BOP_2',
    label: '2 - BOP đặc biệt (Đứng, hình ảnh giữa)',
    url:
      env === 'prod'
        ? 'https://pru-force-bdm-bop.webflow.io/no-title'
        : 'https://pru-force-bdm-bop-uat.webflow.io/no-title',
  },
  TEMPLATE_3: {
    id: 'AD_EVENT_BOP_3',
    label: '3 - BOP đặc biệt (Đứng, fix tên chương trình)',
    url:
      env === 'prod'
        ? 'https://pru-force-bdm-bop.webflow.io/no-banner'
        : 'https://pru-force-bdm-bop-uat.webflow.io/no-banner',
  },
  TEMPLATE_4: {
    id: 'AD_EVENT_BOP_4',
    label: '4 - BOP thường (Ngang, hình ảnh bên trái)',
    url:
      env === 'prod'
        ? 'https://pru-force-bdm-bop.webflow.io/landscape'
        : 'https://pru-force-bdm-bop-uat.webflow.io/landscape',
  },
};

export const defaultTextColorOptions = [{ value: '#FFFFFF', label: '#FFFFFF' }];

export enum YesAndNo {
  'yes' = 'Y',
  'no' = 'N',
}

export enum EViewType {
  AllTraditionalAgents = 'allTraditionalAgents',
  AgentLeaders = 'agentLeaders',
  PruventureLeaders = 'pruventureLeaders',
  Pruventure = 'pruventure',
  GroupOfUsers = 'groupOfUsers',
}
