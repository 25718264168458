import { ETypeOfView, ITypeOfView } from "src/app/common/types/view-types";
import { mainLang, subLang } from "src/app/modules/Operation/constants";
import { PaginateList, PaginateListMore } from "src/app/common/types/common-types";

export enum LanguageType {
  "en" = "en",
  "zh-Hant" = "zh-Hant",
}

export type StrapiLang = "en" | "zh-Hant";

export enum localeToStrapiLanguage {
  "en" = "en",
  "zh-Hant" = "zh-Hant",
}

export enum StrapiLanguageToLocale {
  "en" = "en",
  "zh-Hant" = "zhHk",
}

export type ImageItem = {
  id: string;
  fileName: string;
  url: string;
  type: string;
  importId: string;
};

export type LivestreamInfoState = {
  name?: string;
  description?: string;
  avatar?: ImageItem | null;
  banner?: ImageItem | null;
};

export type LivestreamFormState = {
  _id?: string | null;
  userType: ETypeOfView,
  userIncludeSA: boolean,
  userFile: any,
  userList: [] | null,
  releaseAt?: Date | null;
  status?: StatusLivestream;
  startAt?: Date | null;
  endAt?: Date | null;
  link?: string | null;
};

export type LocalizationItem = {
  id: string;
  locale: string;
  published_at: Date;
  _id: string;
};

export type LivestreamItem = {
  _id: string;
  name: string;
  description: string;
  avatar: ImageItem | null;
  banner: ImageItem | null;
  userType: ETypeOfView,
  userIncludeSA: boolean,
  userFile: any,
  userList: [] | null,
  releaseAt: Date;
  startAt?: Date;
  endAt?: Date;
  link?: string;
  status: StatusLivestream;
  createdAt: Date;
  updatedAt: Date;
  updateBy: string;
  updated_by: Object;
};

export type LivestreamPaginateList = PaginateListMore<LivestreamItem>;

export type LivestreamInput = {
  id?: string | null;
  locale: string;
  name: string;
  description: string;
  avatar: ImageItem | null;
  banner: ImageItem | null;
  typeOfView: string;
  releaseAt: Date;
  startAt?: Date | null;
  endAt?: Date | null;
  link?: string;
  status: StatusLivestream;
  localizations: string[];
  isHidden?: boolean
};

export enum FormMode {
  CREATE = "create",
  EDIT = "edit",
  VIEW = "view",
}

export enum StatusLivestream {
  COMING_SOON = "coming-soon",
  BROADCASTING = "broadcasting",
  BROADCASTED = "broadcasted",
  HIDDEN = "hidden"
}

export enum SubmitActionEnum {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  START = "START",
  END = "END",
}

export enum ELinkType {
  Notification = "infonoti",
}

export enum ENotificationType {
  Push_Inbox = "PUSH_INBOX",
  Push = "PUSH",
  Inbox = "INBOX",
}

export enum ENotificationCategory {
  System = "inbox_system",
  Performance = "inbox_performance",
  Recruitment = "inbox_recruitment",
  Training = "inbox_training",
  Policy = "inbox_policy",
  PulseLeads = "inbox_leads",
  Sales = "inbox_sales",
  Event = "inbox_event",
}

export const EReceiverPushNoti = {
  allAgents: "allAgents",
  allTraditionalAgents: "allTraditionalAgents",
  pruventure: "pruventure",
  groupOfUsers: "groupOfUsers",
}

export enum EReceiverMessagePushNoti {
  allAgents = "ALL_AGENT",
  pruventure = "PRUVENTURE",
  groupOfUsers = "GROUP_OF_USERS",
}

export const formatEReceiverPushNoti = (type: string) => {
  switch (type) {
    case EReceiverPushNoti.allAgents:
    case EReceiverPushNoti.allTraditionalAgents:
      return EReceiverMessagePushNoti.allAgents;
    case EReceiverPushNoti.pruventure:
      return EReceiverMessagePushNoti.pruventure;
    case EReceiverPushNoti.groupOfUsers:
      return EReceiverMessagePushNoti.groupOfUsers;
    default:
      return null;
  }
};

export enum StatusOrder {
  COMING_SOON = 2,
  BROADCASTING = 1,
  BROADCASTED = 3,
}
