import { MaterialStatus } from '../constants';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { Dispatch } from 'react';
import { LibraryItem } from '../types/library-types';

// PVA
import { EModuleTypes } from '../types/library-types';

export enum FormMode {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
}

export const isDateValid = (date: Date) => {
  return !Number.isNaN(date.getTime());
};

export const pathExtractor = (currentPath: string, basePath: string) => {
  let path = currentPath.split(basePath).pop();
  if (path) {
    let pathArr = path.split('/');
    let formMode = pathArr[1] as FormMode;
    if (formMode === FormMode.CREATE) {
      return {
        formMode,
      };
    } else if (formMode === FormMode.EDIT || formMode === FormMode.VIEW) {
      return {
        formMode,
        code: pathArr.pop() || '',
      };
    }
  }
  return {
    formMode: FormMode.CREATE,
  };
};

export const formatWeights = (resources: any, weightObj: any = {}) => {
  resources.forEach((item: any) => {
    const moduleColumn = item.fields.moduleColumn;
    moduleColumn.forEach((colum: any) => {
      if (weightObj.hasOwnProperty(colum.column)) {
        weightObj[colum.column].push(colum.weight.toString());
      } else {
        weightObj[colum.column] = [colum.weight.toString()];
      }
    });
  });

  return weightObj;
};

export const removeMaterialFromSets = (
  underMaterialSetIDs: string[] = [],
  resourceId: string = '',
  blockType: string,
  cb: () => void,
) => {
  if (!underMaterialSetIDs || underMaterialSetIDs?.length === 0) {
    cb();
    return;
  }

  underMaterialSetIDs?.map(async (id: string) => {
    // const needUpdateMaterial = await queryDeliveryEntries({
    //   contentType: blockType,
    //   otherQueryParams: {
    //     'sys.id': id
    //   },
    //   withID: true,
    // });
    // let materialSets = needUpdateMaterial.data[0].fields.materialSet;
    // materialSets = materialSets && materialSets.filter((item: any) => {
    //   return item.sys.id !== resourceId
    // });
    // const result = materialSets.map((material: any) => {
    //   const {
    //     sys: { id },
    //   } = material;
    //   return { sys: { id, linkType: 'Entry', type: 'Link' } };
    // });
    // updateEntry({ resource: { materialSet: result }, lang: 'en', multiLangResource: {}, entryId: id }).then(() => {
    //   cb();
    // });
  });
};

export const updateUnderMaterialSetIDs = (
  isDeleted: boolean,
  materialSets: any,
  currentId: string,
  blockType: string,
) => {
  // if (!materialSets) {
  //   return [Promise.resolve()]
  // }
  // let resultPromise = materialSets && materialSets.map(async (item: any) => {
  //   const {
  //     sys: { id },
  //   } = item;
  //   const needUpdateMaterial = await queryDeliveryEntries({
  //     contentType: blockType,
  //     otherQueryParams: {
  //       'sys.id': id
  //     },
  //     withID: true,
  //   });
  //   let underMaterialSetIDs = needUpdateMaterial.data[0].fields.underMaterialSetIDs || [];
  //   if (isDeleted && underMaterialSetIDs.includes(currentId)) {
  //     underMaterialSetIDs = underMaterialSetIDs.filter((setId: string) => setId !== currentId);
  //   }
  //   if (!isDeleted && !underMaterialSetIDs.includes(currentId)) {
  //     underMaterialSetIDs.push(currentId);
  //   }
  //   await new Promise((resolve: any) => {
  //     // updateEntry({ resource: { underMaterialSetIDs: underMaterialSetIDs }, lang: 'en', multiLangResource: {}, entryId: id }).then(() => {
  //     //   resolve();
  //     // });
  //   })
  // });
  // return resultPromise;
};

export const getStatusValue = (type: string) => {
  return MaterialStatus[type].label || type;
};

export const renderDisplayStatusField = (rowData: LibraryItem): string => {
  // COE
  // return `component.status.${rowData.status?.toLowerCase()}`;

  // PVA
  if (rowData.publishedAt) {
    if (rowData.publishToSetOnly) {
      return 'component.status.publishedToSetOnly';
    } else {
      return 'component.status.published';
    }
  } else {
    if (rowData.lastPublishTime) {
      return 'component.status.unpublished';
    } else {
      return 'component.status.draft';
    }
  }
};

export const getType = (type: string) => {
  if (!type) {
    return '';
  }
  const ref = {
    file: 'File',
    materialSet: 'Material Set',
    content: 'Content',
    link: 'Link',
    text: 'Text',
    picture: 'Picture',
  };
  return ref[type as keyof typeof ref] || '';
};

export const resetReadLog = (data: any, dispatch?: Dispatch<any>) => {
  return apiClient
    .delete(`${ADMIN_URL}/content/readlog`, {
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

// PVA
export const isSaleModule = (moduleName: string) => {
  return moduleName === EModuleTypes.Sale;
};

export const isRecruitModule = (moduleName: string) => {
  return moduleName === EModuleTypes.Recruit;
};
