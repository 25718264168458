import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { PaginateList } from 'src/app/common/types/common-types';
import { useDataProvider } from 'src/app/common/utils';
import { getDayStart, getDayEnd } from 'src/app/common/utils/time-utils';
import { fetchMdrtPassportList, MdrtPassportListParams } from '../../../network/mdrtPassportCrud';
import { MdrtPassportItem } from '../../../types/mdrt-passport-types';
import MdrtPassportList from './components/MdrtPassportList';

type MdrtPassportListingPageProps = {} & ParamsProps;

const initialState: MdrtPassportListParams = {
  agentCode: '',
  lastUpdatedDateFrom: null,
  lastUpdatedDateTo: null,
  page: 0,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): MdrtPassportListParams => {
  return initialParams
    ? {
        agentCode: initialParams.name ?? '',
        lastUpdatedDateFrom: initialParams.lastUpdatedDateFrom
          ? getDayStart(new Date(initialParams.lastUpdatedDateFrom))
          : null,
        lastUpdatedDateTo: initialParams.lastUpdatedDateTo
          ? getDayEnd(new Date(initialParams.lastUpdatedDateTo))
          : null,
        page: 0,
        limit: 5,
      }
    : initialState;
};

export const mdrtPassportFilterKeys = ['agentCode', 'lastUpdatedDateFrom', 'lastUpdatedDateTo'];

const MdrtPassportListingPage: FC<MdrtPassportListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<MdrtPassportListParams>(paramsInitiator(initialParams));
  const [mdrtPassportList, setMdrtPassportList] = useState<PaginateList<MdrtPassportItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<MdrtPassportItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchMdrtPassportList(filterState, dispatch);
      } catch (err) {}
    },
    setMdrtPassportList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('mdrtHub.mdrtPassport.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('mdrtHub.mdrtPassport.filter.agentCode'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'lastUpdatedDateFrom',
            fieldTo: 'lastUpdatedDateTo',
            initialDateFrom: filterState.lastUpdatedDateFrom,
            initialDateTo: filterState.lastUpdatedDateTo,
            displayName: Translation('mdrtHub.mdrtPassport.filter.lastUpdatedDate'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            agentCode: data.agentCode,
            lastUpdatedDateFrom: data.lastUpdatedDateFrom,
            lastUpdatedDateTo: data.lastUpdatedDateTo,
          })
        }
        fetchData={refreshData}
      />
      <MdrtPassportList
        isLoading={isLoading}
        onRefresh={refreshData}
        mdrtPassportList={mdrtPassportList}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default MdrtPassportListingPage;
