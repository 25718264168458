import React from 'react';
import { getDisplayDate } from 'src/app/common/utils/time-utils';
import { regionLocale } from 'src/app/i18n';

interface tableConfigType {
  title: string;
  prop: string;
  align?: any;
  render?: any;
  minWidth?: number;
  sorter?: boolean | false;
}

const tableConfig = (Translation: any, i18ConfigData: any): tableConfigType[] => {
  if (i18ConfigData.length === 0) {
    return [];
  }

  return [
    {
      title: `${Translation('event.greeting_card.table.header.content')}`,
      prop: `i18n_content_en`,
      render: (row: any) => (
        <span>
          {row.i18nDataList
            ? row.i18nDataList.find((item: any) => {
                return item.lang === 'en';
              }).content
            : ''}
        </span>
      ),
    },
    {
      title: `${Translation('event.greeting_card.table.header.title')}`,
      prop: `i18n_title_en`,
      render: (row: any) => (
        <span>
          {row.i18nDataList
            ? row.i18nDataList.find((item: any) => {
                return item.lang === 'en';
              }).content
            : ''}
        </span>
      ),
    },
    {
      title: Translation('event.greeting_card.table.header.publishTime'),
      prop: 'publishTime',
      render: (row: any) => (
        <span>{row.publishTime ? getDisplayDate(row.publishTime, 'YYYY-MM-DD HH:mm:ss') : ''}</span>
      ),
    },
    {
      title: Translation('event.greeting_card.table.header.createTime'),
      prop: 'createTime',
      render: (row: any) => <span>{getDisplayDate(row.createTime, 'YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: Translation('event.greeting_card.table.header.modifyTime'),
      prop: 'modifyTime',
      render: (row: any) => <span>{getDisplayDate(row.modifyTime, 'YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: Translation('event.greeting_card.table.header.modifier'),
      prop: 'modifier',
    },
  ];
};

export default tableConfig;
