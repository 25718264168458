import { map } from 'lodash';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { PaginateList } from 'src/app/common/types/common-types';
import { useDataProvider } from 'src/app/common/utils';
import { getDayStart, getDayEnd } from 'src/app/common/utils/time-utils';
import { fetchMdrtList, MdrtListParams } from '../../network/mdrtCrud';
import { MdrtItem, PublishStatus } from '../../types/mdrt-types';
import MdrtList from './components/MdrtList';

type MdrtListingPageProps = {} & ParamsProps;

const initialState: MdrtListParams = {
  name: '',
  status: '',
  createdDateFrom: null,
  createdDateTo: null,
  page: 0,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): MdrtListParams => {
  return initialParams
    ? {
        name: initialParams.name ?? '',
        status: initialParams.status ?? '',
        createdDateFrom: initialParams.createdDateFrom ? getDayStart(new Date(initialParams.createdDateFrom)) : null,
        createdDateTo: initialParams.createdDateTo ? getDayEnd(new Date(initialParams.createdDateTo)) : null,
        page: 0,
        limit: 5,
      }
    : initialState;
};

export const mdrtFilterKeys = ['name', 'status', 'createdDateFrom', 'createdDateTo'];

const MdrtListingPage: FC<MdrtListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<MdrtListParams>(paramsInitiator(initialParams));
  const [mdrtList, setMdrtList] = useState<PaginateList<MdrtItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<MdrtItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchMdrtList(filterState, dispatch);
      } catch (err) {}
    },
    setMdrtList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('mdrt.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'name',
            initialValue: filterState.name,
            displayName: Translation('mdrt.common.name'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'status',
            initialValue: filterState.status,
            displayName: Translation('mdrt.common.status'),
            choices: [
              { displayName: 'All', value: '' },
              ...map(PublishStatus, (option: string) => ({ displayName: option, value: option })),
            ],
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'createdDateFrom',
            fieldTo: 'createdDateTo',
            initialDateFrom: filterState.createdDateFrom,
            initialDateTo: filterState.createdDateTo,
            displayName: Translation('mdrt.filter.createdDate'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            name: data.name,
            status: data.status || '',
            createdDateFrom: data.createdDateFrom,
            createdDateTo: data.createdDateTo,
          })
        }
        fetchData={refreshData}
      />
      <MdrtList
        isLoading={isLoading}
        onRefresh={refreshData}
        mdrtList={mdrtList}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default MdrtListingPage;
