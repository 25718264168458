import React, { FC, useState, useEffect } from 'react';
import {
  TableContainer,
  TableFooter,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Dialog,
  DialogTitle,
} from '@mui/material';
import { PruForm } from 'src/app/common/components/PruForm';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import ProTableCell from 'src/app/common/components/ProTable/ProTableCell';
import StickyTableCell from 'src/app/common/components/ProTable/StickyTableCell';
import PruTableLoading from 'src/app/common/components/Table/PruTableLoading';
import PruTableEmptyRow from 'src/app/common/components/Table/PruTableEmptyRow';
import PruTablePaginationActions from 'src/app/common/components/Table/PruTablePaginationActions';
import tableHeader from './tableHeader';
import tableConfig from './tableConfig';
import { styles } from '../styles';
import { getTitles, addTitle, editTitle } from '../../../../network/greetingCardCrud';
import PopUp from './components/popUp';
import { AuthenticationState } from '@pruforce/common-adminweb-sdk';
import { RootState } from 'src/redux/store';
import { regionLocale } from 'src/app/i18n';
import { PublishStatus } from '../../../../types/greeting-card-type';

const TitleList: FC<any> = (props) => {
  const intl = useIntl();
  const { classes } = styles();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState<{ [key: string]: any }[]>([]);
  const [popUpVisible, setPopUpVisible] = useState<boolean>(false);
  const [pageData, setPageData] = useState<{ [key: string]: any }>({
    pageNum: 1,
    pageSize: 10,
  });
  const initialDefaultValue = {
    status: PublishStatus.UNPUBLISHED,
  };
  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>(initialDefaultValue);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [isView, setIsView] = useState<boolean>(false);
  const [titleId, setTitleId] = useState<string>('');
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  useEffect(() => {
    const fetchAPI = async () => {
      setFormLoading(true);
      await fetchData();
      setFormLoading(false);
    };
    fetchAPI();
    return;
    // eslint-disable-next-line
  }, []);

  const fetchData = (newFilterState?: any) => {
    setFormLoading(true);
    const newFilter = newFilterState || {};
    const requestParams = {
      ...pageData,
      ...newFilter,
    };

    getTitles(requestParams)
      .then((res: any) => {
        setTableData(res.data ? res.data.values : []);
        setPageData({
          pageNum: res.data?.page || 1,
          pageSize: res.data?.size || 0,
          total: res.data?.total || 0,
        });
        setFormLoading(false);
      })
      .catch((err: any) => {
        setFormLoading(false);
      });
  };

  const onChangePage = (pageNum: number) => {
    const newPageData = {
      pageNum,
    };
    setPageData(newPageData);
    fetchData({ ...newPageData });
  };

  const onChangePageRows = (rows: any) => {
    const newPageData = {
      pageNum: 1,
      pageSize: rows,
    };
    setPageData(newPageData);
    fetchData({ ...newPageData });
  };

  const openPopUp = () => {
    setPopUpVisible(true);
  };

  const onSave = async (values: any) => {
    setFormLoading(true);
    const { name1, name2 } = values;
    const i18nDataList = [
      {
        lang: regionLocale.length > 0 && regionLocale[0],
        name: name1,
      },
      {
        lang: regionLocale.length > 0 && regionLocale[1],
        name: name2,
      },
    ];

    const data = {
      ...values,
      i18nDataList,
      titleId,
      modifier: user && user.username,
    };

    try {
      if (titleId) {
        await editTitle(data);
      } else {
        await addTitle(data);
      }

      setTitleId('');
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: Translation('global.submit.success'),
            content: Translation('global.submit.success'),
          },
        ]),
      );
      await refreshData();
      setFormLoading(false);
      setPopUpVisible(false);
    } catch (error) {
      setFormLoading(false);
      setPopUpVisible(false);
      setTitleId('');
    }
  };

  const refreshData = async () => {
    fetchData();
  };

  const onEdit = (row: any) => {
    setTitleId(row.titleId);
    const i18nDataList = row.i18nDataList && row.i18nDataList;
    const name1 = i18nDataList && i18nDataList[0].name;
    const name2 = i18nDataList && i18nDataList[1].name;
    setPopUpVisible(true);
    setInitialValues({ ...row, ...{ name1, name2, status: row.status, titleId: row.titleId } });
  };

  const onChangeStatus = (row: any) => {
    editTitle({
      titleId: row.titleId,
      status: row.status == PublishStatus.PUBLISHED ? PublishStatus.UNPUBLISHED : PublishStatus.PUBLISHED,
      modifier: user && user.username,
    })
      .then((res: any) => {
        refreshData();
        setFormLoading(false);
      })
      .catch((err: any) => {
        setFormLoading(false);
      });
  };

  const onCancel = () => {
    setPopUpVisible(false);
    setInitialValues(initialDefaultValue);
    setTitleId('');
    setTimeout(() => {
      setIsView(false);
    }, 200);
  };

  return (
    <>
      <PruForm
        config={tableHeader(Translation, openPopUp, refreshData)}
        data={[]}
        onChange={() => {}}
        space={20}
        style={{ padding: '15px 20px', marginTop: 20, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
      ></PruForm>

      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableConfig(Translation, regionLocale).map((item) => (
                <ProTableCell key={item.prop} dataIndex={item.prop}>
                  {item.title}
                </ProTableCell>
              ))}
              <StickyTableCell className={`sticky-cell`}>
                {Translation('component.formLabel.operation')}
              </StickyTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <PruTableLoading isLoading={formLoading} />
            <PruTableEmptyRow isEmpty={tableData.length === 0} />
            {tableData.length > 0 &&
              tableData.map((row) => (
                <TableRow>
                  {tableConfig(Translation, regionLocale).map((config) => (
                    <TableCell>{config.render ? config.render(row) : row[config.prop]}</TableCell>
                  ))}
                  <StickyTableCell className={`sticky-cell`}>
                    <div className={classes.operationContainer}>
                      {row.status == PublishStatus.UNPUBLISHED && (
                        <div
                          style={{ marginRight: 10 }}
                          className={`${classes.operationBtn}`}
                          onClick={() => onEdit(row)}
                        >
                          {Translation('component.formTitle.edit')}
                        </div>
                      )}
                      {
                        <div
                          style={{ marginRight: 10 }}
                          className={`${classes.operationBtn}`}
                          onClick={() => onChangeStatus(row)}
                        >
                          {row.status == PublishStatus.PUBLISHED
                            ? Translation('app.button.unpublish')
                            : Translation('app.button.publish')}
                        </div>
                      }
                    </div>
                  </StickyTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter component="div" className={`table-footer-css`}>
        <TableRow component="div">
          <TablePagination
            align="right"
            rowsPerPageOptions={[5, 10, 20, 50]}
            colSpan={12}
            component="div"
            count={pageData.total || 0}
            rowsPerPage={pageData.pageSize}
            page={pageData.pageNum - 1 || 0}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={(e, page) => onChangePage(page + 1)}
            onRowsPerPageChange={(e) => onChangePageRows(e.target.value)}
            ActionsComponent={PruTablePaginationActions}
          />
        </TableRow>
      </TableFooter>

      <Dialog open={popUpVisible} onClose={isView ? onCancel : () => {}} fullWidth>
        <DialogTitle>
          {isView
            ? Translation('global.view.text')
            : titleId
            ? Translation('global.edit.text')
            : Translation('app.button.addNew')}{' '}
          {`${Translation('event.greeting_card.title.form')}`}
        </DialogTitle>
        <PopUp
          visible={popUpVisible}
          onCancel={onCancel}
          onSave={onSave}
          i18ConfigData={regionLocale}
          initialValues={initialValues}
          confirmLoading={formLoading}
          disabled={isView}
          titleId={titleId}
        />
      </Dialog>
    </>
  );
};

export default TitleList;
