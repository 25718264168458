import { Dispatch } from 'react';
import {
  LivestreamItem,
  LivestreamInput,
  StatusLivestream,
  LivestreamPaginateList,
  StatusOrder,
} from '../Livestream-management/types/livestream-type';
import { NOTIFICATION_URL, apiClient, apiErrorHandler } from 'src/app/common/network';

const formatDataFiltersList = (params: LivestreamParams) => {
  let data: any = {
    filters: {
      createdAt: {
        $gte: params.createdAtFrom,
        $lt: params.createdAtTo,
      },
    },
    sort: {
      statusOrder: 1,
      releaseAt: -1,
      createdAt: -1,
    },
    page: params.page,
    limit: params.limit,
  };

  Object.entries(params).forEach((entry) => {
    const [key, value] = entry;

    if (value) {
      switch (key) {
        case 'name':
          data.filters.name = { $regex: '.*' + params.name + '.*' };

          break;
        case 'status':
          if (params.status === 'hidden') {
            data.filters.isHidden = true;
          } else {
            data.filters.status = params.status;
            data.filters.isHidden = { $in: [null, false] };
          }

          break;
      }
    }
  });

  return data;
};

export type LivestreamParams = {
  createdAtFrom: Date | null;
  createdAtTo: Date | null;
  name: string;
  status: string;
  page: number;
  limit: number;
};

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

export const createLivesteam = async (data: LivestreamInput, dispatch?: Dispatch<any>): Promise<LivestreamItem> => {
  return apiClient
    .post(`${ADMIN_URL}/admin/livestream`, data)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const findOneLivestream = (code: string, dispatch?: Dispatch<any>): Promise<LivestreamItem> => {
  return apiClient
    .get(`${ADMIN_URL}/admin/livestream/${code}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchLivestreamList = async (params: LivestreamParams, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .post(`${ADMIN_URL}/admin/livestream/get-list`, formatDataFiltersList(params))
    .then((response) => {
      return {
        total: response.data.pagination.totalItems,
        totalPages: response.data.pagination.totalPage,
        limit: response.data.pagination.limit,
        items: response.data.items,
        totalRecords: response.data.pagination.totalItems,
      };
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const findBroadcastingLivestream = async (dispatch?: Dispatch<any>): Promise<LivestreamPaginateList> => {
  return apiClient
    .post(`${ADMIN_URL}/admin/livestream/get-list`, {
      filters: {
        status: StatusLivestream.BROADCASTING,
      },
      sort: {
        createdAt: -1,
      },
    })
    .then((response) => {
      return {
        total: response.data.pagination.totalItems,
        totalPages: response.data.pagination.totalPage,
        limit: response.data.pagination.limit,
        items: response.data.items,
        totalRecords: response.data.pagination.totalItems,
      };
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyLivestream = async (
  code: string,
  data: LivestreamInput,
  dispatch?: Dispatch<any>,
): Promise<LivestreamItem> => {
  return apiClient
    .put(`${ADMIN_URL}/admin/livestream/${code}`, data)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateEndLivestream = async (
  code: string,
  status: StatusLivestream,
  dispatch?: Dispatch<any>,
): Promise<LivestreamItem> => {
  return apiClient
    .put(`${ADMIN_URL}/admin/livestream/${code}`, {
      status,
      endAt: new Date(),
      statusOrder: StatusOrder.BROADCASTED,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const closeParticipationsLivestream = async (code: string) => {
  const url = `${ADMIN_URL}/livestream/category/close`;
  return apiClient
    .post<LivestreamItem>(
      url,
      { livestreamId: code },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err);
    });
};

export const createNotiForLivestream = async (data: any, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .post(`${NOTIFICATION_URL}/messages/incentive`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const hiddenLivestream = async (code: string, data: any, dispatch?: Dispatch<any>): Promise<LivestreamItem> => {
  return apiClient
    .put(`${ADMIN_URL}/admin/livestream/${code}/toggle-hidden`, data)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteLivestream = async (code: string, dispatch?: Dispatch<any>): Promise<LivestreamItem> => {
  return apiClient
    .delete(`${ADMIN_URL}/admin/livestream/${code}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const trackDeleteLivestream = async (data: {}, dispatch?: Dispatch<any>): Promise<any> => {
  const url = `${ADMIN_URL}/delete-history/delete-livestream`;

  apiClient
    .post(url, data, { responseType: 'text' })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteAllDataLivestream = async (livestreamId: string) => {
  const url = `${ADMIN_URL}/admin/livestream/category/delete-all-data`;
  return apiClient
    .post(
      url,
      { livestreamId: livestreamId },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err);
    });
};
