import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import { isPermitted } from 'src/app/common/utils';
import {
  AdminCustomerCampaignPermissionType,
  CUSTOMER_CAMPAIGN_BASE_PATH,
  CUSTOMER_CAMPAIGN_NAMESPACE,
  CUSTOMER_CAMPAIGN_TITLE,
} from './constants';
import CustomerCampaignRoutes from './pages/customerCampaignRoutes';

export const customerCampaignModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(CUSTOMER_CAMPAIGN_TITLE),
    path: CUSTOMER_CAMPAIGN_BASE_PATH,
    icon: '/media/svg/icons/Communication/customer-campaign.svg',
    namespace: CUSTOMER_CAMPAIGN_NAMESPACE,
    component: CustomerCampaignRoutes,
    enableRead: isPermitted(
      [
        AdminCustomerCampaignPermissionType.CUSTOMER_CAMPAIGN_READ,
        AdminCustomerCampaignPermissionType.CUSTOMER_CAMPAIGN_CREATE,
        AdminCustomerCampaignPermissionType.CUSTOMER_CAMPAIGN_UPDATE,
      ],
      permissions,
    ),
  };
};
