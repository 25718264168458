import React, { FC } from 'react';
import { SurveyResult, SurveySummaryResult, SurveyResultItem } from 'src/app/modules/Survey/types/survey-types';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { SURVEY_BASE_PATH, SURVEY_NON_PROSPECT_PATH } from 'src/app/modules/Survey/constants';
import SurveyAnalytics from 'src/app/common/components/Survey/SurveyAnalytics';
import SurveySummary from 'src/app/common/components/Survey/SurveySummary';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';

type SurveyAnalyticSectionProps = {
  results: SurveyResult;
  summaries: SurveySummaryResult;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  btnContainer: {
    display: 'flex',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
}));

const NonProspectSurveyAnalyticSection: FC<SurveyAnalyticSectionProps> = ({ results, summaries }) => {
  const history = useHistory();
  const commonClasses = useCommonStyles().classes;
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  let result: Record<string, unknown>[] = results.surveyResults.reduce(
    (res: Record<string, unknown>[], item: SurveyResultItem) => {
      if (item.body) res.push(item.body);
      return res;
    },
    [],
  );
  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>{Translation('survey.viewResults')}</div>
        </div>
        <div className={classes.btnContainer}>
          <Button variant="contained" onClick={() => history.push(`${SURVEY_BASE_PATH}${SURVEY_NON_PROSPECT_PATH}`)}>
            {Translation('app.button.back')}
          </Button>
        </div>
      </div>
      <div style={{}}>
        <SurveySummary summary={summaries} />
      </div>

      <SurveyAnalytics
        questions={results.surveyBody}
        results={result}
        options={{
          //  Enable Commercial License
          haveCommercialLicense: true,
        }}
      />
    </div>
  );
};

export default NonProspectSurveyAnalyticSection;
