import React, { FC, useState, useEffect } from 'react';
import {
  TableContainer,
  TableFooter,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Dialog,
  DialogTitle,
} from '@mui/material';
import { PruForm } from 'src/app/common/components/PruForm';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import ProTableCell from 'src/app/common/components/ProTable/ProTableCell';
import StickyTableCell from 'src/app/common/components/ProTable/StickyTableCell';
import PruTableLoading from 'src/app/common/components/Table/PruTableLoading';
import PruTableEmptyRow from 'src/app/common/components/Table/PruTableEmptyRow';
import PruTablePaginationActions from 'src/app/common/components/Table/PruTablePaginationActions';
import filterConfig from './filterConfig';
import tableHeader from './tableHeader';
import tableConfig from './tableConfig';
import { styles } from '../styles';
import { getCategoryList, addCategory, editCategory } from '../../../components/BdmCategory/network/categoryCrud';
import PopUp from './components/popUp';
import { useSelector } from 'react-redux';
import { AuthenticationState } from '@pruforce/common-adminweb-sdk';
import { RootState } from 'src/redux/store';
import { regionLocale } from 'src/app/i18n';

const CategoryList: FC<any> = (props) => {
  const intl = useIntl();
  const { classes } = styles();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const filterInitData = {};

  const { enableRead, enableCreate, enableUpdate } = props;

  const [filterData, setFilterData] = useState<{ [key: string]: any }>(filterInitData);
  const [filterSavedData, setFilterSavedData] = useState<{ [key: string]: any }>(filterInitData);
  const [tableData, setTableData] = useState<{ [key: string]: any }[]>([]);
  const [pageData, setPageData] = useState<{ [key: string]: any }>({});
  const [popUpVisible, setPopUpVisible] = useState<boolean>(false);
  const initialCatetoryValue = {};

  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>(initialCatetoryValue);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [isView, setIsView] = useState<boolean>(false);
  const [isCategoryError, setIsCategoryError] = useState<boolean>(false);

  const [categoryId, setCategoryId] = useState<string>('');
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  useEffect(() => {
    const fetchAPI = async () => {
      setFormLoading(true);
      await fetchData();
      setFormLoading(false);
    };

    fetchAPI();

    return;
    // eslint-disable-next-line
  }, []);

  const fetchData = (newFilterState?: any) => {
    setFormLoading(true);
    const newFilter = newFilterState || {};
    const requestParams = {
      ...filterData,
      ...newFilter,
    };

    getCategoryList(requestParams)
      .then((res: any) => {
        setTableData(res.data ? res.data : []);

        setFormLoading(false);
      })
      .catch((err: any) => {
        console.log(err.message);
        setFormLoading(false);
      });
  };

  const handleSort = (sorter: any) => {
    let sortItem;
    let sortOrder;
    Object.entries(sorter).forEach(([item, order]) => {
      sortItem = item;
      sortOrder = order;
    });
    const data = {
      ...filterSavedData,
      sortItem,
      sortOrder: sortOrder ? (sortOrder as string).toUpperCase() : sortOrder,
    };

    const newPageData = {
      ...pageData,
      pageNum: 1,
    };

    setFilterSavedData(data);
    fetchData({ ...data, ...newPageData });
  };

  const openPopUp = () => {
    setCategoryId('');
    setInitialValues(initialCatetoryValue);
    setIsCategoryError(false);
    setPopUpVisible(true);
  };

  const onSave = async (values: any) => {
    setFormLoading(true);

    const data = {
      ...values,
      name: values.name.trim() || '',
      description: values.description || '',
      modifier: user && user.username,
    };
    let checkCategory;
    try {
      if (categoryId) {
        checkCategory = await editCategory(categoryId, data);
      } else {
        checkCategory = await addCategory(data);
      }
      if (checkCategory.data.tag === 'FAIL') {
        // setCategoryId('');
        // dispatch(appendAlertItem([
        //     {
        //       severity: AlertType.WARNING,
        //       title: Translation('global.submit.fail'),
        //       content: checkCategory.data.message,
        //     },
        //   ]),
        // );

        setIsCategoryError(true);
        setFormLoading(false);
      } else {
        setCategoryId('');
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: Translation('global.submit.success'),
              content: Translation('global.submit.success'),
            },
          ]),
        );
        await refreshData();
        setFormLoading(false);
        setPopUpVisible(false);
        setIsCategoryError(false);
      }
    } catch (error) {
      setFormLoading(false);
      setIsCategoryError(false);
      setPopUpVisible(false);
      setCategoryId('');
    }
  };

  const refreshData = async () => {
    const newPageData = {
      ...pageData,
    };
    setPageData(newPageData);
    fetchData({ ...filterSavedData, ...newPageData });
  };

  const onEdit = (row: any) => {
    setCategoryId(row._id);
    setPopUpVisible(true);
    setInitialValues({ ...row, ...{ categoryId: row._id } });
  };

  const updateCategoryError = () => {
    setIsCategoryError(false);
  };

  const onCancel = () => {
    setPopUpVisible(false);

    setInitialValues(initialCatetoryValue);
    setCategoryId('');

    setTimeout(() => {
      setIsView(false);
    }, 200);
  };

  const onReset = () => {
    setFilterData(filterInitData);
  };

  return (
    <>
      <PruForm
        config={tableHeader(Translation, openPopUp, refreshData, enableCreate)}
        data={[]}
        onChange={() => {}}
        space={20}
        style={{ padding: '15px 20px', marginTop: 20, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
      ></PruForm>

      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableConfig(Translation, regionLocale).map((item) => (
                <ProTableCell key={item.prop} dataIndex={item.prop} sorter={item.sorter} handleSort={handleSort}>
                  {item.title}
                </ProTableCell>
              ))}
              <StickyTableCell className={`sticky-cell`}>
                {Translation('component.formLabel.operation')}
              </StickyTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <PruTableLoading isLoading={formLoading} />
            <PruTableEmptyRow isEmpty={tableData.length === 0} />
            {tableData.length > 0 &&
              tableData.map((row) => (
                <TableRow>
                  {tableConfig(Translation, regionLocale).map((config) => (
                    <TableCell>{config.render ? config.render(row) : row[config.prop]}</TableCell>
                  ))}
                  <StickyTableCell className={`sticky-cell`}>
                    <div className={classes.operationContainer}>
                      {enableUpdate && (
                        <div
                          style={{ marginRight: 10 }}
                          className={`${classes.operationBtn}`}
                          onClick={() => onEdit(row)}
                        >
                          {Translation('component.formTitle.edit')}
                        </div>
                      )}
                    </div>
                  </StickyTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={popUpVisible} onClose={isView ? onCancel : () => {}} fullWidth>
        <DialogTitle>
          {isView
            ? Translation('global.view.text')
            : categoryId
            ? Translation('global.edit.text')
            : Translation('global.add.text')}{' '}
          {`${Translation('component.formLabel.category')}`}
        </DialogTitle>
        <PopUp
          visible={popUpVisible}
          onCancel={onCancel}
          onSave={onSave}
          updateCategoryError={updateCategoryError}
          i18ConfigData={regionLocale}
          categoryIntialValues={initialValues}
          confirmLoading={formLoading}
          disabled={isView}
          isCategoryError={isCategoryError}
          categoryId={categoryId}
        />
      </Dialog>
    </>
  );
};

export default CategoryList;
