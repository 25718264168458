import { forEach } from 'lodash';
import { PaginateListMore } from '../../../common/types/common-types';
import { Dispatch } from 'react';
import { apiClient, apiErrorHandler } from 'src/app/common/network';
import { ReportListParams, ReportItem } from '../types/newRegisteredCandidateTypes';
import moment from 'moment';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

const formatDataFilters = (params: ReportListParams) => {
  let data: any = {
    dataFilters: [],
    page: params.page,
    limit: params.limit,
  };
  forEach(params, (param, key) => {
    if (param) {
      switch (key) {
        case 'registerDateFrom':
          data.dataFilters.push({
            name: 'createdAt',
            value: param,
            type: 'Date',
            operator: 'gt',
          });
          break;
        case 'registerDateTo':
          data.dataFilters.push({
            name: 'createdAt',
            value: param,
            type: 'Date',
            operator: 'lt',
          });
          break;
        case 'agentCode':
          data.dataFilters.push({
            name: 'agentCode',
            value: param,
            type: 'String',
            operator: 'search',
          });
          break;
        case 'agentName':
          data.dataFilters.push({
            name: 'agentName',
            value: param,
            type: 'String',
            operator: 'search',
          });
          break;
        case 'agentLeaderCode':
          data.dataFilters.push({
            name: 'leaderCode',
            value: param,
            type: 'String',
            operator: 'search',
          });
          break;
        case 'agentLeaderName':
          data.dataFilters.push({
            name: 'leaderName',
            value: param,
            type: 'String',
            operator: 'search',
          });
          break;
        case 'candidateName':
          data.dataFilters.push({
            name: 'fullName',
            value: param,
            type: 'String',
            operator: 'search',
          });
          break;
      }
    }
  });

  data.dataFilters.push({
    name: 'type',
    value: 'candidate',
    type: 'String',
    operator: 'eq',
  });

  return data;
};

export const fetchReportDownload = (params: ReportListParams, dispatch?: Dispatch<any>): void => {
  const uri = `${ADMIN_URL}/report/new-registered-candidate/export`;
  const data = formatDataFilters(params);

  apiClient
    .post<Blob>(uri, data, { responseType: 'text' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'New Registered Candidate Report_' + moment(new Date()).format('YYYYMMDD') + '.csv',
      );
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNewsRegisteredCandidateReport = async (
  params: ReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<ReportItem>> => {
  const uri = `${ADMIN_URL}/report/new-registered-candidate`;
  const data = formatDataFilters(params);

  return apiClient
    .post<PaginateListMore<ReportItem>>(uri, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
