import React, { FC, useState } from 'react';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { getDayEnd, getFirstDayOfMonth, getDayStart, getLastDayOfMonth } from '../../../../../common/utils/time-utils';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { PaginateListMore } from 'src/app/common/types/common-types';
import { useLang } from 'src/app/i18n';
import moment from 'moment';
import { CandidateDocumentReportListParams } from '../../../types/candidateDocumentReportTypes';
import {
  fetchCandidateDocumentReport,
  fetchCandidateDocumentReportDownload,
} from '../../../network/candidateDocumentReportCrud';

type CandidateDocumentReportProps = {} & ParamsProps;

const initialState: CandidateDocumentReportListParams = {
  requestedDateFrom: getDayStart(new Date()),
  requestedDateTo: getDayEnd(new Date()),
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): CandidateDocumentReportListParams => {
  return initialParams
    ? {
        requestedDateFrom: initialParams.requestedDateFrom
          ? getDayStart(new Date(initialParams.requestedDateFrom))
          : getFirstDayOfMonth(new Date()),
        requestedDateTo: initialParams.requestedDateTo
          ? getLastDayOfMonth(new Date(initialParams.requestedDateTo))
          : getLastDayOfMonth(new Date()),
        page: 1,
        limit: 5,
      }
    : initialState;
};

const CandidateDocumentReport: FC<CandidateDocumentReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilterValue = paramsInitiator(initialParams);
  const currentLanguage = useLang();

  const [filterState, setFilterState] = useState<CandidateDocumentReportListParams>(defaultFilterValue);
  const [reportList, setReportList] = useState<PaginateListMore<any>>();
  const { isLoading, refreshData } = useDataProvider<PaginateListMore<any>>(
    async () => {
      try {
        return await fetchCandidateDocumentReport(filterState, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvtDownload = () => {
    try {
      fetchCandidateDocumentReportDownload(filterState, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('report.candidate.document.filter')}
        itemDef={[
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'requestedDateFrom',
            fieldTo: 'requestedDateTo',
            initialDateFrom: filterState.requestedDateFrom,
            initialDateTo: filterState.requestedDateTo,
            defaultDateFrom: defaultFilterValue.requestedDateFrom,
            defaultDateTo: defaultFilterValue.requestedDateTo,
            displayName: Translation('report.candidate.document.filter.assessDate'),
            isRequired: true,
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            requestedDateFrom: data.requestedDateFrom,
            requestedDateTo: data.requestedDateTo,
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.candidate.document.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvtDownload(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            keyIndex: 'AGNAME',
            align: 'center',
            displayName: 'Name',
            renderData: (row) => (row.AGNAME ? row.AGNAME : '-'),
          },
          {
            keyIndex: 'AGID',
            align: 'center',
            displayName: 'ID Number',
            renderData: (row) => (row.AGID ? row.AGID : '-'),
          },
          {
            keyIndex: 'AGCLNAME',
            align: 'center',
            displayName: 'Contract Number',
            renderData: (row) => (row.AGCLNAME ? row.AGCLNAME : '-'),
          },
          {
            keyIndex: 'MAINDOC',
            align: 'center',
            displayName: 'MAINDOC',
            renderData: (row) => (row.MAINDOC ? row.MAINDOC : '-'),
          },
          {
            keyIndex: 'SUBDOC',
            align: 'center',
            displayName: 'SUBDOC',
            renderData: (row) => (row.SUBDOC ? row.SUBDOC : '-'),
          },
          {
            keyIndex: 'BATCHNO',
            align: 'center',
            displayName: 'BATCHNO',
            renderData: (row) => (row.BATCHNO ? row.BATCHNO : '-'),
          },
          {
            keyIndex: 'uploadAt',
            align: 'center',
            displayName: 'ORGDOCCREATIONS',
            renderData: (row) => (row.uploadAt ? moment(row.uploadAt).format('DD/MM/YYYY') : '-'),
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default CandidateDocumentReport;

function formatClassification(value: string | null) {
  switch (value) {
    case 'KHONG DAT':
      return 'KHÔNG ĐẠT';
    case 'RAT TOT':
      return 'RẤT TỐT';
    case 'TOT':
      return 'TỐT';
    case 'CHAP NHAN':
      return 'CHẤP NHẬN';
    case 'TU CHOI':
      return 'TỪ CHỐI';
    default:
      return value;
  }
}
