import React, { FC } from "react";
import { Switch, Redirect, RouteComponentProps } from "react-router-dom";
import ParamsProvider from "src/app/common/components/ParamsProvider";
import MdrtDetailPage from "./Create/MdrtPassportDetailPage";
import PruRoute from "src/app/common/components/PruRoute";
import { NOT_FOUND_ERROR_PAGE_PATH } from "src/app/common/constants";
import { MDRT_HUB_BASE_PATH, MDRT_PASSPORT_BASE_PATH } from "../../constants";
import MdrtPassportListingPage, { mdrtPassportFilterKeys } from "./List/MdrtPassportListingPage";
import UploadDetailPage from "./Upload/UploadDetailPage";

const MdrtPassportRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={`${MDRT_HUB_BASE_PATH}${MDRT_PASSPORT_BASE_PATH}`}
        to={`${MDRT_HUB_BASE_PATH}${MDRT_PASSPORT_BASE_PATH}/list`}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${MDRT_PASSPORT_BASE_PATH}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={mdrtPassportFilterKeys}>
            <MdrtPassportListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${MDRT_PASSPORT_BASE_PATH}/edit/:id`}
        component={MdrtDetailPage}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${MDRT_PASSPORT_BASE_PATH}/add`}
        component={MdrtDetailPage}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${MDRT_PASSPORT_BASE_PATH}/upload`}
        component={UploadDetailPage}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default MdrtPassportRoutes;
