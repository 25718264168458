import { useState, useEffect, useRef, HTMLAttributes } from 'react';
import { CSVLink } from 'react-csv';

type AsyncCsvLinkProps<T> = {
  isDisabled: boolean;
  filename: string;
  dataParser: (data: T) => string[][] | string;
  asyncCall: () => Promise<T>;
} & HTMLAttributes<HTMLDivElement>;

function AsyncCsvLink<T = any>({
  isDisabled,
  filename,
  dataParser,
  asyncCall,
  children,
  ...rest
}: AsyncCsvLinkProps<T>) {
  const [csvData, setCsvData] = useState<T | undefined>();
  const csvInstance = useRef<any>(null);

  const onClickDownload = async () => {
    if (isDisabled) return;
    try {
      const result = await asyncCall();
      setCsvData(result);
    } catch (err) {}
  };

  useEffect(() => {
    if (csvData && csvInstance && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData(undefined);
      });
    }
  }, [csvData]);

  return (
    <>
      <div {...rest} onClick={onClickDownload}>
        {children}
      </div>
      {csvData && (
        <CSVLink
          ref={csvInstance}
          enclosingCharacter=""
          data={dataParser(csvData)}
          filename={filename}
          target="_blank"
        />
      )}
    </>
  );
}

export default AsyncCsvLink;
