import React from 'react';
import { Button } from '@mui/material';
import { PublishStatus } from '../../../../types/greeting-card-type';

const filterConfig = (Translation: any, onSearch: any, onReset: any) => {
  return [
    [
      { type: 'header', label: Translation('event.greeting_card.template.filter'), sm: true },
      {
        type: 'custom',
        sm: false,
        render: () => (
          <Button variant="contained" onClick={onReset}>
            {Translation('golbal.filters.reset')}
          </Button>
        ),
      },
      {
        type: 'custom',
        sm: false,
        render: () => (
          <Button variant="contained" color="secondary" onClick={onSearch}>
            {Translation('golbal.filters.search')}
          </Button>
        ),
      },
    ],
    [
      {
        type: 'dateRange',
        label: Translation('event.greeting_card.template.filter.requested_date'),
        prop: 'timeRange',
        sm: false,
        format: 'dd/MM/yyyy HH:mm:ss',
        labelDateEnd: Translation('event.greeting_card.template.filter.to_date'),
      },
      {
        type: 'select',
        label: Translation('event.greeting_card.template.filter.status'),
        prop: 'status',
        sm: false,
        opts: [
          { label: Translation('component.status.all'), value: '' },
          { label: Translation('component.status.published'), value: PublishStatus.PUBLISHED },
          { label: Translation('component.status.unpublished'), value: PublishStatus.UNPUBLISHED },
        ],
      },
    ],
  ];
};

export default filterConfig;
