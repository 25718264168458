import { Dispatch } from 'react';
import { forEach } from 'lodash';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { PaginateList } from 'src/app/common/types/common-types';
import { I18nDataItem } from 'src/app/i18n';
import { AgentLeadSource, ReassignAgentItem, LeadScopingTypeEnum } from '../types/agent-types';
import { CampaignDropdownItem } from 'src/app/modules/PulseLeads/types/campaign-types';

const agentEndpoint = 'agent';
const leadEndpoint = 'leads';

export type AgentLeadSourceListParam = {
  sourceName: string;
  sortBy: string;
  page: number;
  limit: number;
};

export type GetScopeDropdownParam = {
  agents: string[];
  scopeType: LeadScopingTypeEnum;
};

export type AttachmentBody = {
  blobId: string;
  filename: string;
};

export type CreateAgentLeadSourceBody = {
  sourceName: string;
  i18nData: I18nDataItem;
  image: AttachmentBody;
  parent?: string;
  isDefault?: boolean,
  createdBy: string;
  updatedBy: string;
};

export type UpdateAgentLeadSourceBody = {
  sourceName: string;
  i18nData: I18nDataItem;
  image: AttachmentBody;
  parent?: string;
  isDefault?: boolean,
  updatedBy: string;
};

export type ReassignLeadBody = {
  reassignAgentList: ReassignAgentItem[];
  reassignSourceType: string;
  specificCampaignList?: string[];
  specificLeadSourceList?: string[];
  leadActivityStatus: string;
  isCopyEngagement: boolean;
  isCopyCalendar: boolean;
  updateActivityStatus: string;
  isAddReassignLabel: boolean;
  isSendNotification: boolean;
};

export type RedistributeLeadBody = {
  agentList: string[];
  reassignSourceType: string;
  specificCampaignList?: string[];
  specificLeadSourceList?: string[];
  leadActivityStatus: string;
  isCopyEngagement: boolean;
  isCopyCalendar: boolean;
  updateActivityStatus: string;
  isAddReassignLabel: boolean;
  isSendNotification: boolean;
};

export type UpdateAutoDropLeadConfigDto = {
  enabled: boolean;
  day?: number;
  updatedBy?: string;
};

export type LeadConfigDto = {
  name: string;
  content: Record<string, any>;
  region?: string;
  channel?: string;
  createdBy?: string;
  updatedBy?: string;
};

export const fetchAgentLeadSourceList = async (
  params: AgentLeadSourceListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<AgentLeadSource>> => {
  let queryPath = `${ADMIN_URL}/${agentEndpoint}?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<PaginateList<AgentLeadSource>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchAgentLeadSource = async (id: string, dispatch?: Dispatch<any>): Promise<AgentLeadSource> => {
  return apiClient
    .get<AgentLeadSource>(`${ADMIN_URL}/${agentEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchScopeDropdown = async (
  params: GetScopeDropdownParam,
  dispatch?: Dispatch<any>,
): Promise<CampaignDropdownItem[]> => {
  let queryPath = `${ADMIN_URL}/${leadEndpoint}/scopes/dropdown?`;
  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key === 'agents') {
          param.forEach((agent) => {
            queryPath += `${key}=${agent}&`;
          });
        }
      }
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });

  return apiClient
    .get<CampaignDropdownItem[]>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createAgentLeadSource = async (
  body: CreateAgentLeadSourceBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${agentEndpoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyAgentLeadSource = async (
  id: string,
  body: UpdateAgentLeadSourceBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${agentEndpoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const reassignLead = async (body: ReassignLeadBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${agentEndpoint}/reassign-lead`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const redistributeLead = async (body: RedistributeLeadBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${agentEndpoint}/redistribute-lead`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteAgentLeadSource = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete<void>(`${ADMIN_URL}/${agentEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getLeadAutoDropRule = async (dispatch?: Dispatch<any>): Promise<LeadConfigDto> => {
  return apiClient
    .get(`${ADMIN_URL}/${leadEndpoint}/auto-drop-lead-config`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateLeadAutoDropRule = async (
  body: UpdateAutoDropLeadConfigDto,
  dispatch?: Dispatch<any>,
): Promise<any> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/${leadEndpoint}/auto-drop-lead-config`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
