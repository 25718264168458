import React from 'react';
import { Button } from '@mui/material';
import { PublishStatus } from '../../../../types/greeting-card-type';

const filterConfig = (Translation: any, onSearch: any, onReset: any, listData: any) => {
  return [
    [
      { type: 'header', label: Translation('event.greeting_card.content.filter'), sm: true },
      {
        type: 'custom',
        sm: false,
        render: () => (
          <Button variant="contained" onClick={onReset}>
            {Translation('golbal.filters.reset')}
          </Button>
        ),
      },
      {
        type: 'custom',
        sm: false,
        render: () => (
          <Button variant="contained" color="secondary" onClick={onSearch}>
            {Translation('golbal.filters.search')}
          </Button>
        ),
      },
    ],
    [
      { type: 'input', label: Translation('event.greeting_card.content.filter.content'), prop: 'content', sm: false },
      { type: 'input', label: Translation('event.greeting_card.content.filter.modifier'), prop: 'modifier', sm: false },
      {
        type: 'select',
        label: Translation('event.greeting_card.title.filter.title'),
        prop: 'titleId',
        sm: false,
        opts: listData.titleList,
      },
      {
        type: 'select',
        label: Translation('event.greeting_card.template.filter.status'),
        prop: 'status',
        sm: false,
        opts: [
          { label: Translation('component.status.all'), value: '' },
          { label: Translation('component.status.published'), value: PublishStatus.PUBLISHED },
          { label: Translation('component.status.unpublished'), value: PublishStatus.UNPUBLISHED },
        ],
      },
    ],
  ];
};

export default filterConfig;
