import React, { FC, useMemo, useReducer, useState } from 'react';
import {
  CanBeShared,
  FileUpload,
  i18nDataFieldType,
  MdrtBannerDetail,
  MdrtBannerFormMode,
  MdrtBannerFormState,
  OpenByType,
  PublishStatus,
  Recursion,
  RedirectType,
} from '../../../../types/mdrt-banner-types';
import {
  Button,
  FormControlLabel,
  RadioGroup,
  TextField,
  Radio,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Checkbox,
} from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils/form-error-utils';
import { MANDATORY_FIELD_ERROR_TEXT, MDRT_HUB_BASE_PATH, MDRT_BANNER_BASE_PATH } from '../../../../constants';
import { create, update } from 'src/app/modules/MdrtHub/network/mdrtBannerCrud';
import DateTimeRangePicker from 'src/app/common/components/DateTimeRangePicker';
import FormItemDateCustom from './FormItemDateCustom';
import { set, cloneDeep } from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { RegionLocale, regionLocale, regionLocaleMap } from 'src/app/i18n';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { ExpandMore } from '@mui/icons-material';
import FileUploader from 'src/app/common/components/FileUploader';

type MdrtBannerDetailFormProps = {
  itemId?: string;
  formMode: MdrtBannerFormMode;
  itemDetail?: MdrtBannerDetail;
  onReload: () => void;
  onRouteTo: (route: string) => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  rowContainer2: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  fieldContainer: {
    width: 140,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  dateDivider: {
    marginLeft: 8,
    marginRight: 8,
  },
  sectionMargin: {
    marginBottom: 20,
    paddingLeft: 15,
  },
  sectionMarginNoLeft: {
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },

  flexItem: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '33.333333%',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  iconContainer: {
    width: 60,
    height: 60,
    overflow: 'hidden',
    borderRadius: 12,
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  accordHeading: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '15%',
    flexShrink: 0,
  },
  paddingLeftZero: {
    paddingLeft: 0,
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  root: {
    '& .MuiFormHelperText-root': {
      fontSize: '0.95rem',
      marginLeft: '0px !important',
    },
  },
  errorCustom: {
    color: '#f018a6',
    marginLeft: 0,
    marginRight: 14,
    marginTop: 4,
    margin: 0,
    fontSize: '0.95rem',
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: 400,
    lineHeight: 1.66,
  },
}));

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    //@ts-ignore
    field: Recursion<MdrtBannerFormState> | i18nDataFieldType;
    value: any;
  };
};

type UploadAction = {
  type: 'UPLOAD_IMG';
  payload: {
    field: Recursion<MdrtBannerFormState>;
    id: string;
    url: string;
    fileName: string;
    fileType: string;
  };
};

type MdrtFormAction = ModifyFieldAction | UploadAction;

// init variable
const regionLocales = regionLocale;
const mainLocale = regionLocale[0] || RegionLocale.ENGLISH;
const linkTypeTransMap: any = {
  no: 'component.formSelectItem.no-link',
  external: 'component.formSelectItem.external-url',
};
const initialState: MdrtBannerFormState = {
  id: undefined,
  linkType: null,
  linkTo: '',
  canShare: null,
  openBy: null,
  typeOfViews: [],
  groupUserFile: undefined,
  groupUserList: [],
  publishStatus: null,
  i18nData: regionLocales.reduce((preValue: any, current: any) => {
    preValue[current] = {
      banner: undefined,
      name: '',
    };
    return preValue;
  }, {} as any),
  effectiveDate: [],
};

const formReducer = (state: MdrtBannerFormState, action: MdrtFormAction): MdrtBannerFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      if (action.payload.field.includes('i18nData')) {
        let i18nDataNew = state.i18nData;
        const path = action.payload.field.replace('i18nData.', '');
        set(i18nDataNew, path, action.payload.value);

        return {
          ...state,
          i18nData: i18nDataNew,
        };
      } else {
        return {
          ...state,
          [action.payload.field]: action.payload.value,
        };
      }
    case 'UPLOAD_IMG':
      if (action.payload.field.includes('i18nData')) {
        let i18nDataNew = state.i18nData;
        const path = action.payload.field.replace('i18nData.', '');

        if (action.payload.id) {
          set(i18nDataNew, path, {
            id: action.payload.id,
            fileName: action.payload.fileName,
            url: action.payload.url,
            fileType: action.payload.fileType,
          });
        } else {
          set(i18nDataNew, path, undefined);
        }

        return {
          ...state,
          i18nData: i18nDataNew,
        };
      } else {
        return {
          ...state,
          [action.payload.field]: {
            id: action.payload.id,
            fileName: action.payload.fileName,
            url: action.payload.url,
            fileType: action.payload.fileType,
          },
        };
      }
    default:
      return state;
  }
};

const detailToStateConvertor = (detail: MdrtBannerDetail): MdrtBannerFormState => {
  return {
    id: detail.id,
    linkType: detail.linkType,
    linkTo: detail.linkTo,
    canShare: detail.canShare ? CanBeShared.yes : detail.canShare === false ? CanBeShared.no : null,
    openBy: detail.openBy,
    typeOfViews: detail.typeOfViews,
    groupUserFile: detail.groupUserFile,
    groupUserList: detail.groupUserList,
    i18nData: detail.i18nData,
    publishStatus: detail.publishStatus,
    effectiveDate: [detail.effectiveDateFrom, detail.effectiveDateTo],
  };
};

const generateDefaultExpandPanel = (regionLocales: string[]) => {
  let expandPanel: any = {};
  regionLocales.map((item) => {
    return (expandPanel[item] = true);
  });
  return expandPanel;
};

const MdrtBannerDetailForm: FC<MdrtBannerDetailFormProps> = ({ itemId, formMode, itemDetail, onReload, onRouteTo }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const [panelExpand, setPanelExpand] = useState<any>(generateDefaultExpandPanel(regionLocales));
  const [checkBoxCopyMainLang, setCheckBoxCopyMainLang] = useState(false);

  const handleChange = (locale: string) => (event: any, isExpanded: boolean) => {
    setPanelExpand({ ...panelExpand, [locale]: isExpanded });
  };

  const copyMainLangVersion = (e: React.ChangeEvent<HTMLInputElement>, locale: string) => {
    // e.preventDefault();
    e.stopPropagation();
    if (e.target.value === 'false') {
      if (formState.i18nData[mainLocale].name) {
        onDismissErrorHandler(`i18nData.${locale}.name`, formState.i18nData[mainLocale].name);
      }
      if (formState.i18nData[mainLocale].banner) {
        onDismissErrorHandler(`i18nData.${locale}.banner`, formState.i18nData[mainLocale].banner);
      }
      formDispatch({
        type: 'MODIFY_FIELD',
        payload: {
          field: `i18nData.${locale}`,
          value: { ...formState.i18nData[mainLocale] },
        },
      });
    }
    setCheckBoxCopyMainLang(!checkBoxCopyMainLang);
  };
  const [isLoading, setIsLoading] = useState(false);

  const [formState, formDispatch] = useReducer(
    formReducer,
    itemDetail ? detailToStateConvertor(itemDetail) : cloneDeep(initialState),
  );

  const isDisabled = useMemo(() => {
    return formMode === MdrtBannerFormMode.CREATE || formMode === MdrtBannerFormMode.EDIT ? false : true;
  }, [formMode]);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'linkType',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'linkTo',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => formState.linkType === RedirectType.external && !!!formState.linkTo,
    },
    {
      name: 'openBy',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => formState.linkType === RedirectType.external && !!!formState.openBy,
    },
    {
      name: 'canShare',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () =>
        formState.linkType === RedirectType.external &&
        formState.openBy === OpenByType['pruforce-webview'] &&
        !!!formState.canShare,
    },
    {
      name: 'publishStatus',
      fieldType: ErrorFieldType.MANDATORY,
    },
    ...regionLocales.map((locale: any) => ({
      name: `i18nData.${locale}.name`,
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        return !formState.i18nData[locale].name;
      },
    })),
    ...regionLocales.map((locale: any) => ({
      name: `i18nData.${locale}.banner`,
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        return !(
          formState.i18nData[locale].banner &&
          formState.i18nData[locale].banner?.id &&
          formState.i18nData[locale].banner?.url &&
          formState.i18nData[locale].banner?.fileName
        );
      },
    })),
  ]);

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        {
          mimeType: fileType,
          accessLevel: 'anonymous',
          module: 'mdrt-banner',
        },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Upload successfully - ${fileName}`,
            },
          ]),
        );
        return {
          id: result.blobId,
          fileName: fileName,
          url: result.url,
          type: fileType,
        };
      }
    } catch (err) {
    } finally {
    }
  };

  const onChangeBannerFile = (result: any, locale: string) => {
    formDispatch({
      type: 'UPLOAD_IMG',
      payload: {
        field: `i18nData.${locale}.banner`,
        id: result?.id || '',
        url: result?.url || '',
        fileName: result?.fileName || '',
        fileType: result?.type || '',
      },
    });
    onDismissErrorHandler(`i18nData.${locale}.banner`, result);
  };

  const onChangeLinkType = (value: string) => {
    onDismissErrorHandler('linkType', value);
    formDispatch({
      type: 'MODIFY_FIELD',
      payload: {
        field: 'linkType',
        value: value,
      },
    });
  };

  const onSubmitForm = async () => {
    setIsLoading(true);
    let { hasError } = onSubmitErrorValidator();

    if (!hasError) {
      const detail: MdrtBannerDetail = {
        id: formState.id,
        linkType: formState.linkType,
        linkTo: formState.linkType === RedirectType.external ? formState.linkTo : null,
        openBy: formState.linkType === RedirectType.external ? formState.openBy : null,
        canShare:
          formState.linkType === RedirectType.external && formState.openBy === OpenByType['pruforce-webview']
            ? formState.canShare === CanBeShared.yes
              ? true
              : false
            : null,
        effectiveDateFrom: formState.effectiveDate[0],
        effectiveDateTo: formState.effectiveDate[1],
        publishStatus: formState.publishStatus ? formState.publishStatus : PublishStatus.UNPUBLISHED,
        typeOfViews: [],
        groupUserFile: null,
        groupUserList: [],
        i18nData: formState.i18nData,
      };

      try {
        if (formMode === MdrtBannerFormMode.CREATE) {
          await create(detail, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Created successfully`,
              },
            ]),
          );
        } else {
          detail.id = itemId;
          await update(detail, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Updated successfully`,
              },
            ]),
          );
        }
        onRouteTo(`${MDRT_HUB_BASE_PATH}${MDRT_BANNER_BASE_PATH}`);
      } catch (err) {}
    }
    setTimeout(() => setIsLoading(false), 2000);
  };

  return (
    <>
      <div className={classes.container}>
        {/* Top */}
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>
              {Translation(
                formMode === MdrtBannerFormMode.CREATE
                  ? 'app.button.add'
                  : formMode === MdrtBannerFormMode.EDIT
                  ? 'app.button.edit'
                  : 'app.button.view',
              )}
            </div>
          </div>
          <Button variant="contained" onClick={() => onRouteTo(`${MDRT_HUB_BASE_PATH}${MDRT_BANNER_BASE_PATH}/list`)}>
            Back
          </Button>
        </div>

        {/* Link Type */}
        <div className={classes.sectionMargin}>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('mdrtHub.mdrtBanner.detail.linkType')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <RadioGroup
                aria-label="linkType"
                name="linkType"
                className={classes.radioGroup}
                value={formState.linkType}
                onChange={(e) => {
                  onChangeLinkType(e.target.value);
                }}
              >
                {Object.entries(RedirectType).map(([_, value]) => {
                  return (
                    <FormControlLabel
                      key={value}
                      value={value}
                      control={<Radio disabled={isDisabled} />}
                      label={Translation(linkTypeTransMap[value])}
                    />
                  );
                })}
              </RadioGroup>
              <p className={classes.errorCustom}>{errorState.mandatory.linkType && MANDATORY_FIELD_ERROR_TEXT}</p>
            </div>
          </div>

          {/* Link To */}
          {formState.linkType === RedirectType.external && (
            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation('mdrtHub.mdrtBanner.detail.linkTo')}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <TextField
                  style={{ width: '100%' }}
                  className={classes.root}
                  disabled={isDisabled}
                  error={errorState.mandatory.linkTo}
                  margin="dense"
                  variant="outlined"
                  helperText={errorState.mandatory.linkTo && MANDATORY_FIELD_ERROR_TEXT}
                  value={formState.linkTo}
                  onChange={(e) => {
                    onDismissErrorHandler('linkTo', e.target.value);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      //@ts-ignore
                      payload: { field: 'linkTo', value: e.target.value },
                    });
                  }}
                  inputProps={{ maxLength: 100 }}
                />
              </div>
            </div>
          )}

          {/* Open By */}
          {formState.linkType === RedirectType.external && (
            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation('mdrtHub.mdrtBanner.detail.openBy')}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <RadioGroup
                  aria-label="openBy"
                  name="openBy"
                  className={classes.radioGroup}
                  value={formState.openBy}
                  onChange={(e) => {
                    onDismissErrorHandler('openBy', e.target.value);
                    //@ts-ignore
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: {
                        field: 'openBy',
                        value: e.target.value,
                      },
                    });
                  }}
                >
                  {Object.entries(OpenByType).map(([label, value]) => {
                    return (
                      <FormControlLabel
                        key={value}
                        value={value}
                        control={<Radio disabled={isDisabled} />}
                        label={Translation(`component.formSelectItem.${label}`)}
                      />
                    );
                  })}
                </RadioGroup>
                <p className={classes.errorCustom}>{errorState.mandatory.openBy && MANDATORY_FIELD_ERROR_TEXT}</p>
              </div>
            </div>
          )}

          {/* Can Be Shared */}
          {formState.linkType === RedirectType.external && formState.openBy === OpenByType['pruforce-webview'] && (
            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation('mdrtHub.mdrtBanner.detail.canBeShared')}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <RadioGroup
                  aria-label="canShare"
                  name="canShare"
                  className={classes.radioGroup}
                  value={formState.canShare}
                  onChange={(e) => {
                    onDismissErrorHandler('canShare', e.target.value);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: {
                        field: 'canShare',
                        value: e.target.value,
                      },
                    });
                  }}
                >
                  {Object.entries(CanBeShared).map(([label, value]) => (
                    <FormControlLabel
                      key={value}
                      value={value}
                      control={<Radio disabled={isDisabled} />}
                      label={Translation(`app.select.${label}`)}
                    />
                  ))}
                </RadioGroup>
                <p className={classes.errorCustom}>{errorState.mandatory.canShare && MANDATORY_FIELD_ERROR_TEXT}</p>
              </div>
            </div>
          )}
        </div>

        {/* Region Locale Map */}
        <div className={classes.sectionMarginNoLeft}>
          <div className={classes.rowContainer}>
            {regionLocales.map((locale: RegionLocale, index: any) => {
              return (
                <Accordion
                  key={index}
                  expanded={panelExpand[locale as string]}
                  onChange={handleChange(locale)}
                  style={{ marginBottom: 0, width: '100%' }}
                >
                  <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography className={classes.accordHeading}>
                      {Translation(regionLocaleMap[locale].name)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: 'block' }}>
                    {index === 0 || isDisabled ? null : (
                      <>
                        <Checkbox
                          value={checkBoxCopyMainLang}
                          onChange={(e) => copyMainLangVersion(e, locale)}
                          name="copyMainLangVersion"
                          color="secondary"
                          style={{ paddingLeft: 0 }}
                        />
                        <span className={classes.field}>
                          {Translation('newsArticle.common.useMainLangVersionToFillIn', {
                            lang: Translation(regionLocaleMap[mainLocale].name),
                          })}
                        </span>
                      </>
                    )}
                    <div>
                      <div className={classes.rowContainer} style={{ marginBottom: 15 }}>
                        <div className={classes.fieldContainer}>
                          <span className={classes.field}>
                            {Translation('mdrtHub.mdrtBanner.detail.name')}
                            <span className={classes.mandatory}>*</span> :
                          </span>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                          <TextField
                            disabled={isDisabled}
                            className={classes.root}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            error={errorState.mandatory[`i18nData.${locale}.name`]}
                            helperText={errorState.mandatory[`i18nData.${locale}.name`] && MANDATORY_FIELD_ERROR_TEXT}
                            value={formState.i18nData[locale].name}
                            onChange={(e) => {
                              onDismissErrorHandler(`i18nData.${locale}.name`, e.target.value);
                              formDispatch({
                                type: 'MODIFY_FIELD',
                                payload: {
                                  field: `i18nData.${locale}.name`,
                                  value: e.target.value,
                                },
                              });
                            }}
                            placeholder={Translation('component.hint.recommend-image-format')}
                          />
                        </div>
                      </div>

                      <div className={classes.rowContainer} style={{ marginBottom: 5 }}>
                        <div className={classes.fieldContainer}>
                          <span className={classes.field}>
                            {Translation('mdrtHub.mdrtBanner.detail.banner')}
                            <span className={classes.mandatory}>*</span> :
                          </span>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                          <FileUploader
                            value={formState.i18nData[locale].banner as any}
                            color="secondary"
                            disabled={isDisabled}
                            showAllowText={Translation('component.hint.recommend-image-format')}
                            upload={handleUploadFile}
                            allowedFileTypes={['jpg', 'png', 'jpeg']}
                            maxFileSize={5}
                            showPreview
                            // needCompressImage={true}
                            onChange={(e) => onChangeBannerFile(e, locale)}
                          />
                          <p className={classes.errorCustom}>
                            {errorState.mandatory[`i18nData.${locale}.banner`] && MANDATORY_FIELD_ERROR_TEXT}
                          </p>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </div>

        {/* Receiver Setting */}
        <div className={classes.sectionMargin}>
          {/* Type of view */}
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('mdrtHub.mdrtBanner.detail.typeOfView')} :</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <RadioGroup aria-label="typeOfUsers" name="typeOfUsers" className={classes.radioGroup}>
                <FormControlLabel
                  checked={true}
                  control={<Radio disabled={isDisabled} />}
                  label={Translation('component.formSelectItem.viewType.all-traditional-agents')}
                />
              </RadioGroup>
            </div>
          </div>

          {/* Effective Date */}
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('mdrtHub.mdrtBanner.detail.effectiveDate')} :</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <FormItemDateCustom
                value={formState.effectiveDate}
                onChange={(e: any) => {
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'effectiveDate', value: e },
                  });
                }}
              >
                <DateTimeRangePicker disabled={isDisabled} />
              </FormItemDateCustom>
            </div>
          </div>

          {/* Publish Now */}
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('mdrtHub.mdrtBanner.detail.publishNow')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <RadioGroup
                aria-label="publishStatus"
                name="publishStatus"
                className={classes.radioGroup}
                value={formState.publishStatus}
                onChange={(e) => {
                  onDismissErrorHandler('publishStatus', e.target.value);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: {
                      field: 'publishStatus',
                      value: e.target.value,
                    },
                  });
                }}
              >
                <FormControlLabel
                  value={PublishStatus.PUBLISHED}
                  control={<Radio disabled={isDisabled} />}
                  label={Translation('component.formSelectItem.save-publish')}
                />
                <FormControlLabel
                  value={PublishStatus.UNPUBLISHED}
                  control={<Radio disabled={isDisabled} />}
                  label={Translation('component.formSelectItem.save-as-draft')}
                />
              </RadioGroup>
              <p className={classes.errorCustom}>{errorState.mandatory.publishStatus && MANDATORY_FIELD_ERROR_TEXT}</p>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className={classes.footerContainer}>
          {isDisabled ? (
            <></>
          ) : (
            <>
              <Button
                variant="contained"
                onClick={() => onRouteTo(`${MDRT_HUB_BASE_PATH}${MDRT_BANNER_BASE_PATH}/list`)}
              >
                {Translation('app.button.cancel')}
              </Button>
              <Button
                style={{ marginLeft: 25 }}
                variant="contained"
                color="secondary"
                disabled={isLoading}
                onClick={() => onSubmitForm()}
              >
                {Translation('app.button.save')}
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MdrtBannerDetailForm;
