import React, { FC, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { achievementUploadFile } from '../../../network/achievementUploadCrud';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import useFilterStyles from 'src/app/common/styles/filter-styles';
import { toAbsoluteUrl } from 'src/app/common/utils';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { ErrorUploadFile } from '../../../types/achievement-upload-types';
import { MDRT_HUB_BASE_PATH, ACHIEVEMENT_UPLOAD_BASE_PATH } from '../../../constants';
import { RouteComponentProps } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

type AchievementUploadDetailPageProps = {} & RouteComponentProps & ParamsProps;

interface ValidateModel {
  file: any;
  size: number;
  fileName: string;
  isSubmitBeforeUpload?: boolean;
}

const useStyles = makeStyles()((theme: any) => ({
  exampleFile: {
    color: 'black',
    fontSize: 16,
    cursor: 'pointer',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 25,
  },
  sectionMargin: {
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  fieldContainer: {
    width: 200,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const UploadDetailPage: FC<AchievementUploadDetailPageProps> = ({ history }) => {
  const maxSize = 2097152;
  const intl = useIntl();
  const { classes } = useStyles();
  const filterClasses = useFilterStyles().classes;
  const dispatch = useDispatch();

  const Translation = (id: string) => intl.formatMessage({ id });

  const commonClasses = useCommonStyles().classes;

  const [uploadFile, seUploadFile] = useState();
  const [fileName, setFileName] = useState('');
  let form: any = useRef();

  const initForm = () => {
    seUploadFile(undefined);
    setFileName('');
  };

  const [isOverSize, setIsOverSize] = useState<boolean>(false);
  const [isSubmitBeforeUpload, setIsSubmitBeforeUpload] = useState<boolean>(false);
  const [isWrongFormat, setIsWrongFormat] = useState<boolean>(false);
  const [isSubmitting, setIsSubmiting] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<ErrorUploadFile>();

  const verifyFile = (fileName: string) => {
    var val = fileName.toLowerCase(),
      regex = new RegExp('(.*?).(csv)$');

    if (!regex.test(val)) {
      return false;
    } else {
      return true;
    }
  };

  const validate = (validateModel: ValidateModel) => {
    validateModel.file ? setIsSubmitBeforeUpload(false) : setIsSubmitBeforeUpload(true);

    !verifyFile(validateModel.fileName) ? setIsWrongFormat(true) : setIsWrongFormat(false);

    validateModel.size > maxSize ? setIsOverSize(true) : setIsOverSize(false);
  };

  const submitAchievementUpload = async () => {
    if (form) {
      form.reset();
    }

    if (!uploadFile) {
      setIsSubmitBeforeUpload(true);
      return;
    }
    setSubmitError(undefined);

    const res = await achievementUploadFile(uploadFile).catch((err) => {
      initForm();
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: Translation('global.submit.fail'),
            content: Translation('global.submit.fail'),
          },
        ]),
      );
      setIsWrongFormat(true);
    });

    if (res) {
      if (res.messageCode === 'success') {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Record saved successfully.`,
            },
          ]),
        );
        setIsSubmiting(false);
        initForm();
        history.push(`${MDRT_HUB_BASE_PATH}${ACHIEVEMENT_UPLOAD_BASE_PATH}`);
      } else {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: Translation('global.submit.fail'),
              content: Translation('global.submit.fail'),
            },
          ]),
        );
        setIsSubmiting(false);
        setIsOverSize(false);
        setSubmitError(res.data);
      }
    }
  };

  const onChangeUpload = (e: any) => {
    validate({
      fileName: e.target.files[0].name,
      size: e.target.files[0].size,
      file: e.target.files[0],
    });

    seUploadFile(e.target.files[0]);
    setFileName(e.target.files[0].name);

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsText(file);
  };

  return (
    <form ref={(el) => (form = el)}>
      <div style={{ marginBottom: 20 }} className={filterClasses.root}>
        <div style={{ marginBottom: 5 }} className="PruFilter-header-container">
          <div className={classes.sectionMargin}>
            <div className={classes.divideMargin}>
              <div className={commonClasses.header}>{Translation('mdrtHub.achievementUpload.title')}</div>
            </div>

            <div style={{ marginBottom: 10 }} className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>{Translation('mdrtHub.achievementUpload.import')}:</span>
              </div>
              <input
                style={{ display: 'none' }}
                id="contained-button-file"
                type="file"
                accept=".csv"
                onChange={(e: any) => {
                  onChangeUpload(e);
                  setIsSubmiting(false);
                }}
              />
              <label style={{ marginRight: 15, width: 200 }} htmlFor="contained-button-file">
                <Button variant="contained" color="secondary" component="span">
                  Choose File
                </Button>
                <div style={{ wordBreak: 'break-all' }}>{fileName ? <div> {fileName}</div> : null}</div>
              </label>
              <div>
                <p>
                  Download the template file below and replace the content with the achievement data that will be
                  uploaded in MDRT Hub Template
                </p>
                <a
                  download
                  href={toAbsoluteUrl('/templates/Template_Mdrt_Achievement_Upload.csv')}
                  className={classes.exampleFile}
                >
                  ACHIEVEMENT UPLOAD
                </a>
              </div>
            </div>
            {isSubmitBeforeUpload ? (
              <div style={{ marginBottom: 10 }} className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}></span>
                </div>
                <div className={classes.mandatory}>{Translation('mdrtHub.common.import')}</div>
              </div>
            ) : null}
            {isOverSize ? (
              <div style={{ marginBottom: 10 }} className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}></span>
                </div>
                <div className={classes.mandatory}>{Translation('mdrtHub.common.overSize')}</div>
              </div>
            ) : null}
            {isWrongFormat ? (
              <div style={{ marginBottom: 10 }} className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}></span>
                </div>
                <div className={classes.mandatory}>{Translation('mdrtHub.common.wrongFormat')}</div>
              </div>
            ) : null}

            {submitError ? (
              <>
                {submitError.duplicateAgentAndYear && submitError.duplicateAgentAndYear.length ? (
                  <ul className={classes.mandatory}>
                    {submitError.duplicateAgentAndYear.map((dup) => {
                      const [agentCode, year] = dup.split(' ');
                      return <li>{`Agent ${agentCode} and the year ${year} were duplicated.`}</li>;
                    })}
                  </ul>
                ) : null}
                {submitError.notFoundAgent && submitError.notFoundAgent.length ? (
                  <ul className={classes.mandatory}>
                    {submitError.notFoundAgent.map((agentCode) => (
                      <li>{`Can not find agent code ${agentCode}`}</li>
                    ))}
                  </ul>
                ) : null}
                {submitError.termAgent && submitError.termAgent.length ? (
                  <ul className={classes.mandatory}>
                    {submitError.termAgent.map((agentCode) => (
                      <li>{`Can not upload for this agent ${agentCode} because agent is not active`}</li>
                    ))}
                  </ul>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
        <div className={classes.footerContainer}>
          <Button
            variant="contained"
            color="secondary"
            disabled={isSubmitting || isOverSize || isWrongFormat || isSubmitBeforeUpload}
            onClick={(e: any) => {
              setIsSubmiting(true);
              e.preventDefault();
              submitAchievementUpload();
            }}
          >
            {Translation('mdrtHub.common.submit')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default UploadDetailPage;
