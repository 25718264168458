import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from '@mui/material';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import {
  DURATION_ENDDATE_ERROR,
  DURATION_STARTDATE_ERROR,
  EVENT_NAME_LENGTH_ERROR,
  InNotificationCategory,
  InPublishSettingReceiver,
  InPublishSettingTime,
  InvitationFormMode,
  InvitationNotificationType,
  INVITATION_LETTER_BASE_PATH,
  LOCATION_LENGTH_ERROR,
  NOTICONTENT_LENGTH_ERROR,
  NOTITITILE_LENGTH_ERROR,
  PUBLICTIME_STARTDATE_ERROR,
} from './constants';
import FileUploader from 'src/app/common/components/FileUploader';
import { toAbsoluteUrl } from 'src/app/common/utils';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils/form-error-utils';
import { MANDATORY_FIELD_ERROR_TEXT } from '../../../constants';
import moment from 'moment';
import { InvitationDetailType, InvitationFormState } from '../types/invitation-letter-type';
import { apiErrorHandler, createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import QRCode from 'qrcode.react';
import { createNewInvitation, getInvitationDetail } from './api';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import {
  downloadTemplate,
  DOWNLOAD_TEMPLATE_URL,
  uploadExcel,
} from 'src/app/modules/Notification/redux/notificationCrud';
import { makeStyles } from 'tss-react/mui';
import { LayoutSplashScreen } from 'src/app/layout';
import { PruDatePicker, PruDateTimePicker } from 'src/app/common/components/PruDatePicker';
import { ArrowDownward, RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { MuiColorInput } from 'mui-color-input';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  loadingContainer: {
    width: '100%',
    padding: 50,
    display: 'flex',
    justifyContent: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  textAreaRowContainer: {
    display: 'flex',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    minWidth: 160,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  fieldContainer: {
    width: 160,
    boxSizing: 'border-box',
  },
  checkboxFieldContainer: {
    paddingTop: 9,
    minWidth: 160,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  inputMargin: {
    marginRight: 20,
  },
  dateDivider: {
    marginLeft: 8,
    marginRight: 8,
  },
  sectionMargin: {
    marginBottom: 15,
  },
  divideMargin: {
    marginBottom: 10,
  },
  normalBox: {
    width: 250,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  settingTable: {
    width: '50%',
  },
  childContent: {
    width: '100%',
    padding: '15px',
    borderRadius: '3px',
    border: '1px solid #0000003b',
  },
  descriptionText: {
    marginBottom: '10px',
    fontStyle: 'italic',
  },
  colorPickInput: {
    width: '25px',
    height: '25px',
    border: '0.5px solid #E5E5E5',
    marginRight: '10px',
  },
  qrCodeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
  },
}));

const initialState: InvitationFormState = {
  eventName: '',
  location: '',
  durationStartDate: null,
  durationEndDate: null,
  textColor: '#FFFFFF',
  invitationImage: undefined,
  agenda: undefined,
  notificationType: InvitationNotificationType.INBOX,
  notificationCategory: InNotificationCategory.Event,
  notificationMsgTitle: '',
  notificationMsgContent: '',
  notificationPushContent: '',
  notificationPushTitle: '',
  receiver: InPublishSettingReceiver.ALL_AGENT,
  receiverIncludeSA: false,
  receiverUploadFile: undefined,
  publicTimeOpt: '',
  publicTime: null,
  qrCodeActive: false,
  status: 'UNPUBLISHED',
};

const detailToStateConvertor = (detail: InvitationDetailType): InvitationFormState => {
  return {
    eventName: detail?.eventName,
    location: detail?.location,
    durationStartDate: detail?.durationStartDate,
    durationEndDate: detail?.durationEndDate,
    textColor: detail?.textColor,
    invitationImage: {
      id: detail?.invitationImage?.blobId,
      fileName: detail?.invitationImage?.fileName,
      url: detail?.invitationImage?.url,
      type: detail?.invitationImage?.type,
    },
    agenda: {
      id: detail?.agenda?.blobId,
      fileName: detail?.agenda?.fileName,
      url: detail?.agenda?.url,
      type: detail?.agenda?.type,
    },
    notificationType: detail?.notification?.type,
    notificationCategory: detail?.notification?.category,
    notificationMsgTitle: detail?.notification?.inboxTitle,
    notificationMsgContent: detail?.notification?.inboxContent,
    notificationPushContent: detail?.notification?.pushContent,
    notificationPushTitle: detail?.notification?.pushTitle,
    receiver: detail?.receiver?.type,
    receiverIncludeSA: detail?.receiver?.includeSA,
    receiverUploadFile: {
      importId: detail?.receiver?.userFile?.blobId,
      fileName: detail?.receiver?.userFile?.fileName,
      url: detail?.receiver?.userFile?.url,
      type: detail?.receiver?.userFile?.type,
    },
    publicTimeOpt: detail?.publicTime?.type,
    publicTime: detail?.publicTime?.specificTime,
    qrCodeActive: detail?.active,
    status: detail?.status,
    manualActive: detail?.manualActive,
  };
};

const stateToDetail = (formState: InvitationFormState): InvitationDetailType => {
  const detail: InvitationDetailType = {
    eventName: formState.eventName,
    location: formState.location,
    durationStartDate: formState?.durationStartDate,
    durationEndDate: formState?.durationEndDate,
    textColor: formState?.textColor || '',
    invitationImage: {
      blobId: formState?.invitationImage?.id,
      fileName: formState?.invitationImage?.fileName,
      url: formState?.invitationImage?.url,
      type: formState?.invitationImage?.type,
    },
    agenda: {
      blobId: formState?.agenda?.id || '',
      fileName: formState?.agenda?.fileName || '',
      url: formState?.agenda?.url || '',
      type: formState?.agenda?.type || '',
    },
    notification: {
      type: formState?.notificationType,
      inboxTitle: formState?.notificationMsgTitle,
      inboxContent: formState?.notificationMsgContent || '',
      pushContent: formState?.notificationPushContent || '',
      pushTitle: formState?.notificationPushTitle,
      category: formState?.notificationCategory,
    },
    receiver: {
      type: formState?.receiver,
      includeSA: formState?.receiverIncludeSA,
      userFile: {
        blobId: formState?.receiverUploadFile?.importId || '',
        fileName: formState?.receiverUploadFile?.fileName || '',
        url: formState?.receiverUploadFile?.url || '',
        type: formState?.receiverUploadFile?.type || '',
      },
    },
    active: formState?.qrCodeActive,
    status: formState?.publicTimeOpt === InPublishSettingTime.PublicNow ? 'PUBLISHED' : 'UNPUBLISHED',
    publicTime: {
      type: formState.publicTimeOpt,
      specificTime: formState?.publicTimeOpt === InPublishSettingTime.PublicNow ? new Date() : formState.publicTime,
    },
  };
  return detail;
};

export type ErrorState = {
  mandatory: {
    eventName: boolean;
    location: boolean;
    durationStartDate: boolean;
    durationEndDate: boolean;
    textColor: boolean;
    invitationImage: boolean;
    agenda: boolean;
    notificationType: boolean;
    notificationCategory: boolean;
    notificationPushTitle: boolean;
    notificationMsgTitle: boolean;
    receiver: boolean;
    receiverUploadFile: boolean;
    publicTimeOpt: boolean;
    qrCodeActive: boolean;
    publicTime: boolean;
  };
  immediate: {
    endDateBeforeDurationStartDate: boolean;
    currentDateBeforeDurationStartDate: boolean;
    currentDateBeforePublicTime: boolean;
  };
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof InvitationFormState;
    value: any;
  };
};

type ModifyAllFieldAction = {
  type: 'MODIFY_ALL_FIELD';
  payload: InvitationDetailType;
};

type SwitchInvitationNotificationType = {
  type: 'SWITCH_NOTIFICATION_TYPE';
  payload: {
    value: InvitationNotificationType;
  };
};

type FillPushIntoInbox = {
  type: 'FILL_PUSH_INTO_INBOX';
};

type InvitationFormAction =
  | ModifyFieldAction
  | ModifyAllFieldAction
  | SwitchInvitationNotificationType
  | FillPushIntoInbox;

const incentiveFormReducer = (state: InvitationFormState, action: InvitationFormAction): InvitationFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'MODIFY_ALL_FIELD':
      return detailToStateConvertor(action.payload);
    case 'SWITCH_NOTIFICATION_TYPE':
      if (
        (state.notificationType === InvitationNotificationType.INBOX &&
          action.payload.value === InvitationNotificationType.PUSH) ||
        (state.notificationType === InvitationNotificationType.PUSH &&
          action.payload.value === InvitationNotificationType.INBOX)
      ) {
        return {
          ...state,
          notificationType: action.payload.value,
          notificationMsgTitle: '',
          notificationMsgContent: '',
          notificationPushTitle: '',
          notificationPushContent: '',
        };
      } else {
        return {
          ...state,
          notificationType: action.payload.value,
        };
      }

    case 'FILL_PUSH_INTO_INBOX':
      return {
        ...state,
        notificationMsgTitle: state.notificationPushTitle,
        notificationMsgContent: state.notificationPushContent,
      };
  }
};

interface EventDetailPageProps {
  enableUpdate: boolean;
}

export default function InvitationLetterDetail({ enableUpdate }: EventDetailPageProps) {
  const [formState, formDispatch] = useReducer(incentiveFormReducer, initialState);
  const [displayEventQrCode, setDisplayEventQrCode] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { classes } = useStyles();
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const { formMode, code } = useMemo(() => {
    let formMode;
    if (window.location.href.endsWith(`/create`)) {
      formMode = InvitationFormMode.CREATE;
    } else if (window.location.href.endsWith(`/view/${id}`)) {
      formMode = InvitationFormMode.VIEW;
    } else if (window.location.href.endsWith(`/edit/${id}`)) {
      formMode = InvitationFormMode.EDIT;
    }
    return {
      formMode,
      code: id ? id : undefined,
    };
    // eslint-disable-next-line
  }, [window.location.href]);
  const isDisabled = useMemo(() => {
    return formState?.status === 'PUBLISHED' || formMode === InvitationFormMode.VIEW ? true : false;
  }, [formState.status, formMode]);

  const commonClasses = useCommonStyles().classes;
  const history = useHistory();
  const dispatch = useDispatch();

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, immediateErrorValidator } =
    useErrorHandler<ErrorState>(formState, [
      {
        name: 'eventName',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'location',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'durationStartDate',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'durationEndDate',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'endDateBeforeDurationStartDate',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.durationStartDate && formState.durationEndDate) {
            return !!moment(new Date(formState.durationStartDate)).isAfter(moment(new Date(formState.durationEndDate)));
          } else {
            return false;
          }
        },
      },
      {
        name: 'currentDateBeforeDurationStartDate',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.durationStartDate) {
            return !!moment(new Date()).isAfter(moment(new Date(formState.durationStartDate)), 'minute');
          } else {
            return false;
          }
        },
      },
      {
        name: 'textColor',
        fieldType: ErrorFieldType.IMMEDIATE,
      },
      {
        name: 'invitationImage',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'agenda',
        fieldType: ErrorFieldType.IMMEDIATE,
      },
      {
        name: 'notificationType',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'notificationCategory',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'notificationPushTitle',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () =>
          (formState.notificationType === InvitationNotificationType.PUSH ||
            formState.notificationType === InvitationNotificationType.PUSH_INBOX) &&
          !formState.notificationPushTitle,
      },
      {
        name: 'notificationMsgTitle',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () =>
          (formState.notificationType === InvitationNotificationType.INBOX ||
            formState.notificationType === InvitationNotificationType.PUSH_INBOX) &&
          !formState.notificationMsgTitle,
      },
      {
        name: 'receiver',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'receiverUploadFile',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (
            formState?.receiver === InPublishSettingReceiver.GROUP_OF_USERS &&
            !formState?.receiverUploadFile?.importId
          ) {
            return true;
          } else {
            return false;
          }
        },
      },
      {
        name: 'publicTimeOpt',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'publicTime',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => {
          if (formState.publicTimeOpt === InPublishSettingTime.PublicSpecificTime && !formState.publicTime) {
            return true;
          }
          return false;
        },
      },
      {
        name: 'currentDateBeforePublicTime',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.publicTimeOpt === InPublishSettingTime.PublicSpecificTime && formState.publicTime) {
            return !!moment(new Date()).isAfter(moment(new Date(formState.publicTime)), 'minute');
          } else {
            return false;
          }
        },
      },
    ]);

  useEffect(
    () => {
      immediateErrorValidator();
    },
    // eslint-disable-next-line
    [formState.durationStartDate, formState.durationEndDate, formState.publicTime],
  );

  useEffect(() => {
    if (window.location.href.endsWith(`/view/${id}`)) {
      setDisplayEventQrCode(true);
    }
  }, [window.location.href]);

  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);

  const onSubmitForm = async () => {
    let { hasError } = onSubmitErrorValidator();

    if (!formState?.invitationImage?.id) {
      return dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: `Invitation Image can not blank`,
          },
        ]),
      );
    }

    if (!formState?.receiver) {
      return dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: `Receiver can not blank`,
          },
        ]),
      );
    }

    if (!formState?.publicTimeOpt) {
      return dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: `Publictime can not blank`,
          },
        ]),
      );
    }

    if (formState?.receiver === InPublishSettingReceiver.GROUP_OF_USERS && !formState?.receiverUploadFile?.importId) {
      return dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: `Group of user file can not blank`,
          },
        ]),
      );
    }

    if (!hasError) {
      const detail: InvitationDetailType = { ...stateToDetail(formState) };
      try {
        setSubmitLoading(true);
        if (formMode === InvitationFormMode.CREATE) {
          detail.manualActive = false;
          detail.status = moment(new Date()).isSameOrAfter(moment(new Date(detail?.publicTime?.specificTime || '')))
            ? 'PUBLISHED'
            : 'UNPUBLISHED';
          await createNewInvitation(detail, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Record saved successfully`,
              },
            ]),
          );
        } else if (code && formMode === InvitationFormMode.EDIT) {
          detail.id = code;
          detail.status = moment(new Date()).isSameOrAfter(moment(new Date(detail?.publicTime?.specificTime || '')))
            ? 'PUBLISHED'
            : 'UNPUBLISHED';
          await createNewInvitation(detail, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Record updated successfully`,
              },
            ]),
          );
        }
        history.push(INVITATION_LETTER_BASE_PATH);
        setSubmitLoading(false);
      } catch (err) {
        setSubmitLoading(false);
      }
    }
  };

  const handleToggleActive = async (toggleActive: boolean): Promise<void> => {
    const detail: InvitationDetailType = { ...stateToDetail(formState) };
    try {
      detail.id = code;
      detail.active = toggleActive;
      detail.manualActive = true;
      detail.status = moment(new Date()).isSameOrAfter(moment(new Date(detail?.publicTime?.specificTime || '')))
        ? 'PUBLISHED'
        : 'UNPUBLISHED';
      await createNewInvitation(detail, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Toggle active successfully`,
          },
        ]),
      );
      formDispatch({
        type: 'MODIFY_FIELD',
        payload: {
          field: 'qrCodeActive',
          value: toggleActive,
        },
      });
    } catch (error) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: `Toggle active failed`,
          },
        ]),
      );
    }
  };

  const fetchData = () => {
    if (!code) {
      return;
    }
    setIsLoading(true);
    getInvitationDetail(code)
      .then((res: any) => {
        formDispatch({
          type: 'MODIFY_ALL_FIELD',
          payload: res.data,
        });

        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
        throw apiErrorHandler(err, dispatch);
      });
  };

  useEffect(() => {
    fetchData();
  }, [code]);

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        { mimeType: fileType, accessLevel: 'anonymous', module: 'event' },
        dispatch,
      );

      await fileUpload(createBlobRes.url, file, () => {});

      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);

      const result = blobDetail[0];
      if (result) {
        return {
          id: result.blobId,
          fileName: fileName,
          url: result.url,
          type: fileType,
        };
      }
    } catch (err) {
    } finally {
    }
  };

  // const handleUploadFileReceiver = async ({
  //   file,
  //   fileName,
  //   fileType,
  //   description = '',
  // }: {
  //   file: any;
  //   fileName: string;
  //   fileType: string;
  //   description?: string;
  // }) => {
  //   try {
  //     const createBlobRes = await createBlob(
  //       { mimeType: fileType, accessLevel: 'anonymous', module: 'event' },
  //       dispatch,
  //     );
  //     await fileUpload(createBlobRes.url, file, () => {});
  //     const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
  //     const result = blobDetail[0];
  //     if (result) {
  //       let dataFile: any = {
  //         id: result.blobId,
  //         fileName: fileName,
  //         url: result.url,
  //         type: fileType,
  //       };
  //       return dataFile;
  //     }
  //   } catch (err) {
  //   } finally {
  //   }
  // };

  const downloadQR = () => {
    const canvas = document.getElementById('invitation-qrcode');
    if (canvas) {
      const pngUrl = (canvas as any).toDataURL('image/png').replace('image/png', 'image/octet-stream');
      console.log('pngUrl', pngUrl);
      let downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = 'qrcode.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const toDownloadUploadedFile =
    (fileName: string, url: string) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      url = `${url}?contentType=FIXED`;
      downloadTemplate(`${fileName}.xlsx`, url, {}, dispatch);
    };

  const toDownloadTemplate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const url = `${DOWNLOAD_TEMPLATE_URL}?contentType=FIXED`;
    downloadTemplate('Template_Group_Of_User.xlsx', url, {}, dispatch);
  };

  return (
    <>
      {isLoading ? (
        <LayoutSplashScreen />
      ) : (
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <div className={classes.rowContainer}>
              <div className={commonClasses.header}>
                {Translation(
                  formMode === InvitationFormMode.CREATE
                    ? 'event.invitation.form.title.add'
                    : isDisabled
                    ? 'event.invitation.form.title.view'
                    : 'event.invitation.form.title.edit',
                )}
              </div>
            </div>
            <Button variant="contained" onClick={() => history.push(INVITATION_LETTER_BASE_PATH)}>
              {Translation('app.button.back')}
            </Button>
          </div>

          {/* Basic Information */}
          <div className={classes.sectionMargin}>
            <div className={classes.divideMargin}>
              <span className={classes.subHeader}>{Translation('event.invitation.form.title.basicInfo')}</span>
            </div>

            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation('event.invitation.form.formField.event_name')}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <TextField
                  disabled={isDisabled}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  error={
                    errorState.mandatory.eventName ||
                    (typeof formState?.eventName === 'string' && formState?.eventName?.toString().length > 255)
                  }
                  helperText={
                    errorState.mandatory.eventName
                      ? MANDATORY_FIELD_ERROR_TEXT
                      : typeof formState?.eventName === 'string' &&
                        formState?.eventName?.toString().length > 255 &&
                        EVENT_NAME_LENGTH_ERROR
                  }
                  value={formState.eventName}
                  onChange={(event) => {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: {
                        field: 'eventName',
                        value: event.target.value,
                      },
                    });
                    onDismissErrorHandler('eventName', event.target.value);
                  }}
                />
              </div>
            </div>

            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation('event.invitation.form.formField.location')}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <TextField
                  disabled={isDisabled}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  error={
                    errorState.mandatory.location ||
                    (typeof formState?.location === 'string' && formState?.location?.toString().length > 255)
                  }
                  helperText={
                    errorState.mandatory.location
                      ? MANDATORY_FIELD_ERROR_TEXT
                      : typeof formState?.location === 'string' &&
                        formState?.location?.toString().length > 255 &&
                        LOCATION_LENGTH_ERROR
                  }
                  value={formState.location}
                  onChange={(event) => {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'location', value: event.target.value },
                    });
                    onDismissErrorHandler('location', event.target.value);
                  }}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation('event.invitation.form.formField.duration')}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <PruDateTimePicker
                slotProps={{
                  textField: {
                    error:
                      errorState.mandatory.durationStartDate || errorState.immediate.currentDateBeforeDurationStartDate,
                    helperText: !isDisabled
                      ? errorState.mandatory.durationStartDate
                        ? MANDATORY_FIELD_ERROR_TEXT
                        : errorState.immediate.currentDateBeforeDurationStartDate && DURATION_STARTDATE_ERROR
                      : '',
                  },
                }}
                onChange={(date) => {
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'durationStartDate', value: date },
                  });
                  onDismissErrorHandler('durationStartDate', date);
                }}
                value={formState.durationStartDate}
                disabled={isDisabled}
                format="DD/MM/YYYY HH:mm"
                // KeyboardButtonProps={{
                //   'aria-label': 'change date',
                // }}
              />
              <div className={classes.dateDivider}>-</div>
              <PruDateTimePicker
                slotProps={{
                  textField: {
                    error: errorState.mandatory.durationEndDate || errorState.immediate.endDateBeforeDurationStartDate,
                    helperText: errorState.mandatory.durationEndDate
                      ? MANDATORY_FIELD_ERROR_TEXT
                      : errorState.immediate.endDateBeforeDurationStartDate && DURATION_ENDDATE_ERROR,
                  },
                }}
                onChange={(date) => {
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'durationEndDate', value: date },
                  });
                  onDismissErrorHandler('durationEndDate', date);
                }}
                value={formState.durationEndDate}
                disabled={isDisabled}
                format="DD/MM/YYYY HH:mm"
                // KeyboardButtonProps={{
                //   'aria-label': 'change date',
                // }}
              />
            </div>
          </div>

          {/* Design invitation */}
          <div className={classes.sectionMargin}>
            <div className={classes.divideMargin}>
              <span className={classes.subHeader}>{Translation('event.invitation.form.title.design_invitation')}</span>
            </div>

            <div className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>{Translation('event.invitation.form.formField.text_color')} :</span>
              </div>
              <div
                style={{
                  backgroundColor: formState.textColor,
                  width: '20px',
                  height: '20px',
                  marginRight: '20px',
                  border: '1px solid #D2D2D2',
                }}
              ></div>
              {isDisabled ? (
                <div style={{ color: formState.textColor }}>{formState.textColor}</div>
              ) : (
                <MuiColorInput
                  disabled={isDisabled}
                  name="color"
                  margin="dense"
                  variant="outlined"
                  error={errorState.mandatory.textColor}
                  helperText={errorState.mandatory.textColor && MANDATORY_FIELD_ERROR_TEXT}
                  value={formState.textColor as any}
                  onChange={(textColor) => {
                    onDismissErrorHandler('textColor', textColor);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'textColor', value: textColor },
                    });
                  }}
                />
              )}
            </div>

            <div className={classes.rowContainer} style={{ marginTop: '10px' }}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation('event.invitation.form.formField.image')}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <FileUploader
                upload={handleUploadFile}
                disabled={isDisabled}
                color="secondary"
                maxFileSize={2}
                showAllowText={Translation('incentive.form.formField.descriptionImageUploader')}
                allowedFileTypes={['jpg', 'png', 'jpeg', 'pdf']}
                value={formState.invitationImage?.id ? formState.invitationImage : null}
                onChange={(data) => {
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'invitationImage', value: data },
                  });
                }}
              />
            </div>

            <div className={classes.rowContainer} style={{ marginTop: '15px' }}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>{Translation('event.invitation.form.formField.agenda')} :</span>
              </div>
              <FileUploader
                upload={handleUploadFile}
                disabled={isDisabled}
                color="secondary"
                maxFileSize={2}
                showAllowText={Translation('invitation.form.formField.agendaImageUploader')}
                allowedFileTypes={['jpg', 'png', 'jpeg']}
                value={formState.agenda?.id ? formState.agenda : null}
                onChange={(data) => {
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'agenda', value: data },
                  });
                }}
              />
            </div>
          </div>

          {/*Notification Setting */}
          <div className={classes.sectionMargin}>
            <div className={classes.divideMargin}>
              <span className={classes.subHeader}>
                {Translation('event.invitation.form.title.notification_setting')}
              </span>
            </div>
            <div className={classes.textAreaRowContainer}>
              <div className={classes.checkboxFieldContainer}>
                <span className={classes.field}>
                  {Translation('component.formLabel.notification_type')}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <FormControl error disabled={isDisabled}>
                <RadioGroup
                  aria-label="notificationType"
                  name="notificationType"
                  style={{ display: 'flex', flexDirection: 'row' }}
                  value={formState.notificationType}
                  onChange={(e) =>
                    formDispatch({
                      type: 'SWITCH_NOTIFICATION_TYPE',
                      payload: {
                        value: e.target.value as InvitationNotificationType,
                      },
                    })
                  }
                >
                  {Object.entries(InvitationNotificationType).map(([value, label]) => (
                    <FormControlLabel
                      style={{ marginBottom: 0 }}
                      key={value}
                      value={value}
                      control={<Radio />}
                      label={Translation(`event.invitation.form.formField.notification.${label}`)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          </div>

          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('component.formLabel.category')} :</span>
            </div>
            <div style={{ flexGrow: 1 }}>{formState.notificationCategory}</div>
          </div>

          <div className={classes.rowContainer} style={{ marginTop: '20px' }}>
            <div className={classes.textAreaFieldContainer}>
              <span className={classes.field}></span>
            </div>
            <div className={classes.childContent}>
              {formState.notificationType === InvitationNotificationType.PUSH ||
              formState.notificationType === InvitationNotificationType.PUSH_INBOX ? (
                <div>
                  <div className={classes.divideMargin}>
                    <span className={classes.subHeader}>{Translation('incentive.form.title.push')}</span>
                  </div>
                  <div className={classes.rowContainer}>
                    <div className={classes.fieldContainer}>
                      <span className={classes.field}>
                        {Translation('incentive.form.formField.title')}
                        <span className={classes.mandatory}>*</span> :
                      </span>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <TextField
                        disabled={isDisabled}
                        error={
                          errorState.mandatory.notificationPushTitle ||
                          (typeof formState?.notificationPushTitle === 'string' &&
                            formState?.notificationPushTitle?.toString().length > 50)
                        }
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        helperText={
                          errorState.mandatory.notificationPushTitle
                            ? MANDATORY_FIELD_ERROR_TEXT
                            : typeof formState?.notificationPushTitle === 'string' &&
                              formState?.notificationPushTitle?.toString().length > 50 &&
                              NOTITITILE_LENGTH_ERROR
                        }
                        value={formState.notificationPushTitle}
                        onChange={(e) => {
                          onDismissErrorHandler('notificationPushTitle', e.target.value);
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'notificationPushTitle',
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.textAreaRowContainer}>
                    <div className={classes.textAreaFieldContainer}>
                      <span className={classes.field}>{Translation('incentive.form.formField.content')}</span>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <TextField
                        disabled={isDisabled}
                        InputProps={{
                          classes: {
                            input: classes.textArea,
                          },
                        }}
                        error={
                          typeof formState?.notificationPushContent === 'string' &&
                          formState?.notificationPushContent?.toString().length > 255
                        }
                        helperText={
                          typeof formState?.notificationPushContent === 'string' &&
                          formState?.notificationPushContent?.toString().length > 255 &&
                          NOTICONTENT_LENGTH_ERROR
                        }
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        multiline
                        value={formState.notificationPushContent}
                        onChange={(e) =>
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'notificationPushContent',
                              value: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              {formState.notificationType === InvitationNotificationType.INBOX ||
              formState.notificationType === InvitationNotificationType.PUSH_INBOX ? (
                <div>
                  <div className={classes.divideMargin}>
                    <span className={classes.subHeader}>{Translation('event.invitation.form.title.inbox')}</span>
                  </div>
                  {formState.notificationType === InvitationNotificationType.PUSH_INBOX && !isDisabled ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={isDisabled}
                          color="secondary"
                          name="fillInbox"
                          onChange={(e) => e.target.checked && formDispatch({ type: 'FILL_PUSH_INTO_INBOX' })}
                        />
                      }
                      label={Translation('event.invitation.form.formField.fillInboxOption')}
                      labelPlacement="end"
                    />
                  ) : (
                    ''
                  )}
                  <div className={classes.rowContainer}>
                    <div className={classes.fieldContainer}>
                      <span className={classes.field}>
                        {Translation('event.invitation.form.formField.title')}
                        <span className={classes.mandatory}>*</span> :
                      </span>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <TextField
                        disabled={isDisabled}
                        error={
                          errorState.mandatory.notificationMsgTitle ||
                          (typeof formState?.notificationMsgTitle === 'string' &&
                            formState?.notificationMsgTitle?.toString().length > 50)
                        }
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        helperText={
                          errorState.mandatory.notificationMsgTitle
                            ? MANDATORY_FIELD_ERROR_TEXT
                            : typeof formState?.notificationMsgTitle === 'string' &&
                              formState?.notificationMsgTitle?.toString().length > 50 &&
                              NOTITITILE_LENGTH_ERROR
                        }
                        value={formState.notificationMsgTitle}
                        onChange={(e) => {
                          onDismissErrorHandler('notificationMsgTitle', e.target.value);
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'notificationMsgTitle',
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.textAreaRowContainer}>
                    <div className={classes.textAreaFieldContainer}>
                      <span className={classes.field}>{Translation('event.invitation.form.formField.content')}</span>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <TextField
                        disabled={isDisabled}
                        InputProps={{
                          classes: {
                            input: classes.textArea,
                          },
                        }}
                        error={
                          typeof formState?.notificationMsgContent === 'string' &&
                          formState?.notificationMsgContent?.toString().length > 255
                        }
                        helperText={
                          typeof formState?.notificationMsgContent === 'string' &&
                          formState?.notificationMsgContent?.toString().length > 255 &&
                          NOTICONTENT_LENGTH_ERROR
                        }
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        multiline
                        value={formState.notificationMsgContent}
                        onChange={(e) =>
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'notificationMsgContent',
                              value: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {/* Publish Settings */}
          <div className={classes.sectionMargin}>
            <div className={classes.divideMargin}>
              <span className={classes.subHeader}>{Translation('event.invitation.form.title.publish_setting')}</span>
            </div>

            <div className={classes.textAreaRowContainer}>
              <div className={classes.checkboxFieldContainer}>
                <span className={classes.field}>
                  {Translation('component.formLabel.receiver')}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <FormControl error disabled={isDisabled}>
                <RadioGroup
                  aria-label="receiverType"
                  name="receiverType"
                  style={{ display: 'flex', flexDirection: 'row' }}
                  value={formState.receiver}
                  onChange={(event) => {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: {
                        field: 'receiver',
                        value: event.target.value,
                      },
                    });
                  }}
                >
                  {Object.entries(InPublishSettingReceiver).map(([value, label]) => (
                    <FormControlLabel
                      style={{ marginBottom: 0 }}
                      key={value}
                      value={value}
                      control={<Radio />}
                      label={Translation(`event.invitation.form.formField.setting.${label}`)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
            {formState.receiver === InPublishSettingReceiver.ALL_AGENT ? (
              <div className={classes.textAreaRowContainer}>
                <div className={classes.checkboxFieldContainer} />
                <div style={{ marginTop: '20px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={formState.receiverIncludeSA}
                        onChange={(e) =>
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'receiverIncludeSA',
                              value: e.target.checked,
                            },
                          })
                        }
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<RadioButtonChecked />}
                      />
                    }
                    label={Translation('component.formSelectItem.viewType.include-sa')}
                    labelPlacement="end"
                  />
                </div>
              </div>
            ) : formState.receiver === InPublishSettingReceiver.GROUP_OF_USERS ? (
              <div className={classes.textAreaRowContainer}>
                <div className={classes.checkboxFieldContainer} />
                <div style={{ marginTop: '20px' }}>
                  {/* <FileUploader
                    color="secondary"
                    disabled={isDisabled}
                    showAllowText={Translation('component.hint.recommend-excel-format')}
                    allowedFileTypes={[
                      'csv',
                      'xlsx',
                      'xls',
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                      'application/vnd.ms-excel',
                    ]}
                    maxFileSize={10}
                    value={formState.receiverUploadFile?.importId ? formState.receiverUploadFile : null}
                    upload={handleUploadFileReceiver}
                    onChange={(data) => {
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: { field: 'receiverUploadFile', value: data },
                      });
                    }}
                  /> */}

                  <FileUploader
                    maxFileSize={10}
                    color="secondary"
                    disabled={isDisabled}
                    showAllowText={
                      isDisabled ? null : (
                        <Button style={{ marginLeft: 16 }} color="secondary" onClick={toDownloadTemplate}>
                          {Translation('component.hint.recommend-excel-format')}
                        </Button>
                      )
                    }
                    allowedFileTypes={[
                      'csv',
                      'xlsx',
                      'xls',
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                      'application/vnd.ms-excel',
                    ]}
                    upload={(params: any) => uploadExcel({ ...params, inclusive: true })}
                    download={toDownloadUploadedFile}
                    value={formState.receiverUploadFile?.importId ? formState.receiverUploadFile : null}
                    onChange={(data) => {
                      console.log(data);
                      const value = data ? { ...data, fileName: data.fileBlobName } : null;
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: { field: 'receiverUploadFile', value },
                      });
                    }}
                  />
                  {/* <div>
                    <a href={toAbsoluteUrl('/templates/Template_Group_Of_User.csv')}>
                      {Translation('recruitment.form.downloadTemplate')}
                    </a>
                  </div> */}
                </div>
              </div>
            ) : (
              ''
            )}

            <div className={classes.textAreaRowContainer} style={{ marginTop: '20px' }}>
              <div className={classes.checkboxFieldContainer}>
                <span className={classes.field}>
                  {Translation('component.formLabel.public_time')}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>

              <FormControl error disabled={isDisabled}>
                <RadioGroup
                  aria-label="receiverType"
                  name="receiverType"
                  style={{ display: 'flex', flexDirection: 'row' }}
                  value={formState.publicTimeOpt}
                  onChange={(event) => {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: {
                        field: 'publicTimeOpt',
                        value: event.target.value,
                      },
                    });
                  }}
                >
                  {Object.entries(InPublishSettingTime).map(([value, label]) => (
                    <FormControlLabel
                      style={{ marginBottom: 0 }}
                      key={value}
                      value={value}
                      control={<Radio />}
                      label={Translation(`event.invitation.form.formField.setting.${label}`)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>

            {formState.publicTimeOpt === InPublishSettingTime.PublicSpecificTime ? (
              <div className={classes.textAreaRowContainer}>
                <div className={classes.checkboxFieldContainer} />
                <PruDateTimePicker
                  slotProps={{
                    textField: {
                      error: errorState.mandatory.publicTime || errorState.immediate.currentDateBeforePublicTime,
                      helperText: !isDisabled
                        ? errorState.mandatory.publicTime
                          ? MANDATORY_FIELD_ERROR_TEXT
                          : errorState.immediate.currentDateBeforePublicTime && PUBLICTIME_STARTDATE_ERROR
                        : '',
                    },
                  }}
                  onChange={(date) => {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'publicTime', value: date },
                    });
                    onDismissErrorHandler('publicTime', date);
                  }}
                  disabled={isDisabled}
                  format="DD/MM/YYYY HH:mm"
                  value={formState.publicTime}
                  // KeyboardButtonProps={{
                  //   'aria-label': 'change date',
                  // }}
                />
              </div>
            ) : null}
          </div>

          {/* Event QR code */}
          {displayEventQrCode && formState.eventName ? (
            <div className={classes.sectionMargin}>
              <div className={classes.divideMargin}>
                <span className={classes.subHeader}>{Translation('event.invitation.form.title.qr_code')}</span>
              </div>
              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('event.invitation.form.formField.active')} :</span>
                </div>
                <Switch
                  checked={formState?.qrCodeActive}
                  onChange={(event) => handleToggleActive(event.target.checked)}
                />
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* QR code */}
          {displayEventQrCode && formState.eventName ? (
            <div className={classes.sectionMargin}>
              <div className={classes.divideMargin}></div>
              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer} />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      height: 'auto',
                      margin: '0 ',
                      maxWidth: 80,
                      width: '100%',
                    }}
                  >
                    <QRCode
                      id="invitation-qrcode"
                      value={code || ''}
                      size={300}
                      viewBox={`0 0 300 300`}
                      style={{
                        height: 'auto',
                        maxWidth: '100%',
                        width: '100%',
                      }}
                      includeMargin={true}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      marginLeft: '20px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      downloadQR();
                    }}
                  >
                    <div>
                      <ArrowDownward />
                    </div>
                    <div style={{ color: '#87BEF6' }}>Download</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {!isDisabled ? (
            <div className={classes.footerContainer} style={{ marginTop: '30px' }}>
              <Button variant="contained" onClick={() => history.push(INVITATION_LETTER_BASE_PATH)}>
                Cancel
              </Button>
              <Button
                style={{ marginLeft: 25 }}
                variant="contained"
                color="secondary"
                onClick={() => onSubmitForm()}
                disabled={submitLoading}
              >
                Submit
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
}
