import React, { FC} from "react";
import { RouteComponentProps, Switch, Redirect } from "react-router-dom";
// import { EVENT_BASE_PATH, EVENT_CATEGORY_PATH } from "../../constants";
import PruRoute from "../../../../common/components/PruRoute";
import EventCategoryListPage from "./List";
import { ModulePermissionProps } from "../../../../common/module/types";

const eventCategoryPath = `/bdm-templete/category`;

const BdmTempleteCategoryPage: FC<RouteComponentProps & ModulePermissionProps> = ({
    history,
    location,
    match,
    enableRead,
    enableCreate,
    enableUpdate
  }) => {
  return (
    <EventCategoryListPage
    enableRead={enableRead}
    enableCreate={enableCreate}
    enableUpdate={enableUpdate}
  />
  )
};

export default BdmTempleteCategoryPage;