import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { PaginateListMore } from 'src/app/common/types/common-types';
import { IReportFilter, ICustomerSurveyItem } from '../../../types/customer-survey-types';
import {
  fetchCustomerSurveyReport,
  fetchCustomerSurveyReportFile,
  fetchTotalRecordCustomerSurveyReport,
} from '../../../network/customerSurveyReportCrud';
import moment from 'moment';
import PruTableCustom from 'src/app/common/components/PruTable/PruTableCustom';
import { useDataProvider } from 'src/app/common/utils';

type CustomerSurveyReportProps = {} & ParamsProps;

const initialState: IReportFilter = {
  agentCode: '',
  leaderCode: '',
  surveyTitle: '',
  fromDate: moment().subtract(3, 'months').startOf('day').toDate(),
  toDate: moment().endOf('day').toDate(),
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): IReportFilter => {
  return initialParams
    ? {
        agentCode: '',
        leaderCode: '',
        surveyTitle: '',
        fromDate: moment().subtract(3, 'months').startOf('day').toDate(),
        toDate: moment().endOf('day').toDate(),
        page: 1,
        limit: 5,
      }
    : initialState;
};

const SurveyCustomerReport: FC<CustomerSurveyReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilter = paramsInitiator(initialParams);

  const [filterState, setFilterState] = useState<IReportFilter>(defaultFilter);
  const [reportList, setReportList] = useState<PaginateListMore<ICustomerSurveyItem>>();
  const { isLoading, refreshData } = useDataProvider<PaginateListMore<ICustomerSurveyItem>>(
    async () => {
      try {
        const params = handleParams(filterState);
        const result = await Promise.all([
          fetchCustomerSurveyReport(params, dispatch),
          fetchTotalRecordCustomerSurveyReport(params, dispatch),
        ]);
        return {
          ...result[0],
          ...result[1],
        };
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsv = () => {
    try {
      const params = handleParams(filterState);
      fetchCustomerSurveyReportFile(params, dispatch);
    } catch (err) {}
  };

  const handleParams = (filters: IReportFilter) => {
    return {
      ...filterState,
    };
  };

  const handleBeforeChangeFilter = (filters: any) => {
    let fromDate = filters.fromDate ? moment(filters.fromDate).startOf('day').toDate() : filters.fromDate;
    let toDate = filters.toDate ? moment(filters.toDate).endOf('day').toDate() : filters.toDate;

    if (!fromDate && !toDate) {
      fromDate = moment().subtract(3, 'months').startOf('day').toDate();
      toDate = moment().endOf('day').toDate();
    } else if (!fromDate) {
      fromDate = moment(toDate).subtract(3, 'months').startOf('day').toDate();
    } else if (!toDate) {
      toDate = moment(fromDate).add(3, 'months').endOf('day').toDate();
    }

    filters.fromDate = fromDate;
    filters.toDate = toDate;

    return filters;
  };

  return (
    <>
      <PruFilter
        title={Translation('report.customerSurvey.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('report.customerSurvey.filter.agentCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'leaderCode',
            initialValue: filterState.leaderCode,
            displayName: Translation('report.customerSurvey.filter.leaderCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'surveyTitle',
            initialValue: filterState.surveyTitle,
            displayName: Translation('report.customerSurvey.filter.surveyTitle'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'fromDate',
            fieldTo: 'toDate',
            initialDateFrom: filterState.fromDate,
            initialDateTo: filterState.toDate,
            defaultDateFrom: defaultFilter.fromDate,
            defaultDateTo: defaultFilter.toDate,
            displayName: Translation('report.customerSurvey.filter.surveyDate'),
            periodTime: { month: 3 },
          },
        ]}
        onChangeFilter={(data) => {
          const dataFormat = handleBeforeChangeFilter(data);
          return setFilterState({
            ...filterState,
            ...dataFormat,
          });
        }}
        fetchData={refreshData}
      />
      <PruTableCustom
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        isCombineHeader={true}
        onRefresh={refreshData}
        title={Translation('report.customerSurvey.title')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsv(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            keyIndex: 'agentCode',
            displayName: Translation('report.customerSurvey.listing.agentCode'),
            rowSpan: 2,
            renderData: (row: any) => row.agentCode || '-',
          },
          {
            keyIndex: 'agentName',
            displayName: Translation('report.customerSurvey.listing.agentName'),
            rowSpan: 2,
            renderData: (row: any) => row.agentName || '-',
          },
          {
            keyIndex: 'leaderCode',
            displayName: Translation('report.customerSurvey.listing.leaderCode'),
            rowSpan: 2,
            renderData: (row: any) => row.leaderCode || '-',
          },
          {
            keyIndex: 'leaderName',
            displayName: Translation('report.customerSurvey.listing.leaderName'),
            rowSpan: 2,
            renderData: (row: any) => row.leaderName || '-',
          },
          {
            keyIndex: 'surveyTitle',
            displayName: Translation('report.customerSurvey.listing.surveyTitle'),
            rowSpan: 2,
            renderData: (row: any) => row.surveyData?.title || '-',
          },
          {
            keyIndex: 'bySurvey',
            displayName: Translation('report.customerSurvey.listing.bySurvey'),
            align: 'center',
            colspan: 1,
            renderData: (row: any) => '',
          },
          {
            keyIndex: 'totalSurveySent',
            displayName: Translation('report.customerSurvey.listing.totalSurveySent'),
            align: 'center',
            isCombine: true,
            renderData: (row: any) => String(row.totalSent || 0),
          },
          {
            keyIndex: 'blank',
            displayName: '',
            rowSpan: 2,
            renderData: (row: any) => '',
          },
          {
            keyIndex: 'byProspect',
            displayName: Translation('report.customerSurvey.listing.byProspect'),
            align: 'center',
            colspan: 4,
            renderData: (row: any) => '',
          },
          {
            keyIndex: 'numberOfSent',
            displayName: Translation('report.customerSurvey.listing.numberOfSent'),
            isCombine: true,
            align: 'center',
            renderData: (row: any) => String(row.numberOfSent || 0),
          },
          {
            keyIndex: 'numberOfNotOpened',
            displayName: Translation('report.customerSurvey.listing.numberOfNotOpened'),
            isCombine: true,
            align: 'center',
            renderData: (row: any) => String(row.numberOfNotOpened || 0),
          },
          {
            keyIndex: 'numberOfOpened',
            displayName: Translation('report.customerSurvey.listing.numberOfOpened'),
            isCombine: true,
            align: 'center',
            renderData: (row: any) => String(row.numberOfOpened || 0),
          },
          {
            keyIndex: 'numberOfReplied',
            displayName: Translation('report.customerSurvey.listing.numberOfReplied'),
            isCombine: true,
            align: 'center',
            renderData: (row: any) => String(row.numberOfReplied || 0),
          },
          {
            keyIndex: 'surveyCreatedDate',
            rowSpan: 2,
            align: 'center',
            displayName: Translation('report.customerSurvey.listing.surveyCreatedDate'),
            renderData: (row: any) =>
              row.groupSurveyCreatedAt ? moment(row.groupSurveyCreatedAt).format('DD/MM/YYYY HH:mm') : '-',
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default SurveyCustomerReport;
