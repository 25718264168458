import { Dispatch } from 'react';
import { ActivityListParams, ActivityPaginateList } from '../pages/Livestream/Activity/types/activity-type';
import {
  DetailedCommentListParams,
  DetailedCommentPaginateList,
} from '../pages/Livestream/DetailedComment/types/detail-comment-type';
import { PerformanceListParams, PerformancePaginateList } from '../pages/Livestream/Performance/types/performance-type';
import { apiClient, apiErrorHandler } from 'src/app/common/network';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;
// const ADMIN_URL = `http://localhost:3203`;

export const fetchDetailedCommentList = async (
  params: DetailedCommentListParams,
  dispatch?: Dispatch<any>,
): Promise<DetailedCommentPaginateList> => {
  const url = `${ADMIN_URL}/report/livestream/detailed-comment`;

  return apiClient
    .post(url, params)
    .then((response: any) => response.data)
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchDetailedCommentDownload = async (params: DetailedCommentListParams, dispatch?: Dispatch<any>) => {
  const url = `${ADMIN_URL}/report/livestream/detailed-comment/export`;

  apiClient
    .post(url, params)
    .then((response: any) => {
      const url = window.URL.createObjectURL(
        new Blob(['\uFEFF' + response.data], {
          type: 'text/csv;charset=utf-8;',
        }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'livestream-detailed-comment' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchActivityList = async (
  params: ActivityListParams,
  dispatch?: Dispatch<any>,
): Promise<ActivityPaginateList> => {
  const url = `${ADMIN_URL}/report/livestream/activity`;

  return apiClient
    .post(url, params)
    .then((response: any) => response.data)
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchActivityDownload = async (params: ActivityListParams, dispatch?: Dispatch<any>) => {
  const url = `${ADMIN_URL}/report/livestream/activity/export`;

  apiClient
    .post(url, params)
    .then((response: any) => {
      const url = window.URL.createObjectURL(
        new Blob(['\uFEFF' + response.data], {
          type: 'text/csv;charset=utf-8;',
        }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'livestream-activity' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchPerformanceList = async (
  params: PerformanceListParams,
  dispatch?: Dispatch<any>,
): Promise<PerformancePaginateList> => {
  const url = `${ADMIN_URL}/report/livestream/performance`;

  return apiClient
    .post(url, params)
    .then((response: any) => response.data)
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchPerformanceDownload = async (params: PerformanceListParams, dispatch?: Dispatch<any>) => {
  const url = `${ADMIN_URL}/report/livestream/performance/export`;

  apiClient
    .post(url, params)
    .then((response: any) => {
      const url = window.URL.createObjectURL(
        new Blob(['\uFEFF' + response.data], {
          type: 'text/csv;charset=utf-8;',
        }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'livestream-performance' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getBlob = async (params: any, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get<any>(`${ADMIN_URL}/resource`, { params })
    .then((response: any) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
