import { forEach, cloneDeep } from 'lodash';
import { PaginateListMore } from '../../../common/types/common-types';
import { Dispatch } from 'react';
import { apiClient, apiErrorHandler } from 'src/app/common/network';
import { ReportItem, ReportListParams } from '../types/qrActivityTypes';
import moment from 'moment';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

const formatDataFilters = (params: ReportListParams) => {
  let data: any = {
    dataFilters: [],
    page: params.page,
    limit: params.limit,
  };
  forEach(params, (param, key) => {
    switch (key) {
      case 'requestedDateFrom':
        data.dataFilters.push({
          name: 'createdAt',
          value: param,
          type: 'Date',
          operator: 'gt',
        });
        break;
      case 'requestedDateTo':
        data.dataFilters.push({
          name: 'createdAt',
          value: param,
          type: 'Date',
          operator: 'lt',
        });
        break;
      case 'agentCodeScanner':
        data.dataFilters.push({
          name: 'agentCodeScanner',
          value: param ? param : '',
          type: 'string',
          operator: 'search',
        });
        break;
      case 'agentCode':
        data.dataFilters.push({
          name: 'agentCode',
          value: param ? param : '',
          type: 'string',
          operator: 'search',
        });
        break;
      case 'lang':
        data.dataFilters.push({
          name: 'lang',
          value: param ? param : '',
          type: 'string',
          operator: 'eq',
        });
        break;
    }
  });
  return data;
};

export const fetchQrActivityReportDownload = (params: ReportListParams, dispatch?: Dispatch<any>): void => {
  const uri = `${ADMIN_URL}/report/qr-code/activity/export`;
  const newParams = cloneDeep(params);
  newParams.limit = 999999999;
  const data = formatDataFilters(newParams);

  apiClient
    .post<Blob>(uri, data, { responseType: 'text' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'QR Activity Report_' + moment(new Date()).format('YYYYMMDD') + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchQrActivityReport = async (
  params: ReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<ReportItem>> => {
  const uri = `${ADMIN_URL}/report/qr-code/activity`;
  const data = formatDataFilters(params);

  return apiClient
    .post<PaginateListMore<ReportItem>>(uri, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
