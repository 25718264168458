export const LIVESTREAM_BASE_PATH = '/livestream';
export const LIVESTREAM_MANAGEMENT_BASE_PATH = '/livestream-management';

export const LIVESTREAM_NAMESPACE = 'LIVESTREAM';
export const LIVESTREAM_MANAGEMENT_NAMESPACE = 'LIVESTREAM-MANAGEMENT';

//  These constants map to aside menu, header menu to display title
export const LIVESTREAM_TITLE = 'livestream.title';

export const LIVESTREAM_MANAGEMENT_TITLE = 'livestream.management.title';

export const LIVESTREAM_MANAGEMENT_PATH = `${LIVESTREAM_BASE_PATH}${LIVESTREAM_MANAGEMENT_BASE_PATH}`;

export enum AdminLivestreamManagementPermissionType {
  LIVESTREAM_MANAGEMENT_READ = 'READ_ANY_LIVESTREAM-MANAGEMENT',
  LIVESTREAM_MANAGEMENT_CREATE = 'CREATE_ANY_LIVESTREAM-MANAGEMENT',
  LIVESTREAM_MANAGEMENT_UPDATE = 'UPDATE_ANY_LIVESTREAM-MANAGEMENT'
}

export const supportLangList = ['vi', 'en'];

export const mainLang = 'vi';
export const subLang = 'en';
