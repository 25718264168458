import { I18nGenericData } from 'src/app/i18n';
import { AttachmentDef, FormMode } from 'src/app/common/types';
import { FormCommonProps } from 'src/app/common/components/pru-stepped-form';
import { TargetApplicantRadioModeConfig } from 'src/app/common/components/target-applicant-section-v2';
import { EventHostEnum, EventCategoryItem } from './event-category-types';
import { ITypeOfView } from 'src/app/common/types/view-types';

export enum EventStatusEnum {
  UPCOMING = 'UPCOMING',
  ONGOING = 'ONGOING',
  PAST = 'PAST',
  CANCELED = 'CANCELED',
}

export enum EventPublishStatusEnum {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export enum EventTypeEnum {
  PHYSICAL = 'PHYSICAL',
  VIRTUAL = 'VIRTUAL',
  HYBRID = 'HYBRID',
}

export enum SessionEnrollmentEnum {
  ALL = 'ALL',
  SINGLE = 'SINGLE',
}

export enum RegistrationMethodEnum {
  TEMPLATE = 'TEMPLATE',
  LINK = 'LINK',
}

export enum RegistrationNotificationMethodEnum {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}

export enum RegistrationAttendanceRuleEnum {
  CHECK_IN = 'CHECK_IN',
  CHECK_IN_OUT = 'CHECK_IN_OUT',
}

export enum PublishAgentEnum {
  ALL = 'ALL',
  TARGET = 'TARGET',
}

export enum AgentForceEnrolEnum {
  NONE = 'NONE',
  PUBLISH_SETTING = 'PUBLISH_SETTING',
  TARGET = 'TARGET',
}

export enum SessionTypeEnum {
  SESSION = 'SESSION',
  SUB_SESSION = 'SUB_SESSION',
}

export type EventFormCommonProps = FormCommonProps & {
  formMode: FormMode;
  isPublished: boolean;
  disableEdit: boolean;
  walkInAllowed: boolean;
  eventCategoryList: EventCategoryItem[];
  eventTagList: EventTagItem[];
  eventItem?: EventItem;
  reloadData: () => Promise<void>;
};

export type EventTagItem = {
  name: string;
};

export type EventSessionItem = {
  key: string;
  type: SessionTypeEnum;
  startTime: Date;
  endTime: Date;
  name: I18nGenericData<string>;
  venue: I18nGenericData<string>;
  multiSubSession: boolean;
  quota?: string;
  subSessions: EventSessionItem[];
  subSessionEnrollment?: SessionEnrollmentEnum;
  regAttendanceRule?: RegistrationAttendanceRuleEnum;
  regAllowWalkIn?: boolean;
};

export type EventConfirmationBodyItem = {
  title: I18nGenericData<string>;
  body: I18nGenericData<string>;
};

export type EventItem = {
  /* Basics starts */
  _id: string;
  category: EventCategoryItem;
  shareToPublic: boolean;
  agentRequired: boolean;
  type: EventTypeEnum;
  eventDate: Date;
  startTime: Date;
  endTime: Date;
  host?: EventHostEnum;
  agencyCode?: string;

  organizer?: I18nGenericData<string>;
  name: I18nGenericData<string>;
  venue: I18nGenericData<string>;
  tags?: I18nGenericData<string[]>;

  /* Session starts */
  multiSession: boolean;
  sessions: EventSessionItem[];
  // multiSession true
  sessionEnrollment?: SessionEnrollmentEnum;
  // multiSession false
  quota?: string;
  /* Session ends */
  /* Basics ends */

  eventStatus: EventStatusEnum;
  publishStatus: EventPublishStatusEnum;

  /* Detail starts */
  eventImage?: I18nGenericData<AttachmentDef>;
  description?: I18nGenericData<string>;
  attachments?: I18nGenericData<AttachmentDef[]>;
  /* Detail ends */

  /* Registration starts */
  regQuotaPerAgent?: number | null;
  regStartDate?: Date;
  regEndDate?: Date;
  regMethod?: RegistrationMethodEnum;
  regLink?: string;
  regNotificationMethod?: RegistrationNotificationMethodEnum;
  regConfirmationBody?: EventConfirmationBodyItem;
  regAttendanceRule?: RegistrationAttendanceRuleEnum;
  regAllowWalkIn?: boolean | null;
  /* Registration ends */

  /* Registration Form starts */
  regFormBody?: Record<string, any>;
  /* Registration Form ends */

  /* Walk in Form starts */
  walkInFormBody?: Record<string, any>;
  /* Walk in Form ends */

  /* Publishing starts */
  pubAgent?: PublishAgentEnum;
  pubAgentConfig?: TargetApplicantRadioModeConfig;
  agentForceEnrol?: AgentForceEnrolEnum;
  agentForceEnrolConfig?: TargetApplicantRadioModeConfig;
  typeOfViews?: ITypeOfView[];

  /* Publishing ends */

  campaign?: string;
  createdBy: string;
  lastUpdatedBy: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type SessionFormDialogState = {
  open: boolean;
  sessionNum?: number;
  sessionItem?: EventSessionItem;
};

export type EventShortenCodeUrlItem = {
  shortenCodeUrl: string;
};
