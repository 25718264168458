export type IReportFilter = {
  fromDate: Date;
  toDate: Date;
  channelCandidate?: string;
  referralAgent?: string;
  unitCode?: string;
  officeCode?: string;
  agentCode?: string;
  branchCode?: string;
  leaderCode?: string;
  page: number;
  limit: number;
};

export type INewRegisterCandidateReportItem = {
  candidateName: string;
  phoneNumber: string;
  sharedByAGCode: string;
  sharedByAGName: string;
  leaderCode: string;
  leaderName: string;
  unitCode: string;
  officeCode: string;
  dateOfRegistration: string;
  callAction: string;
  dateOfCall: string;
  candidateType: string | null;
  candidateSource: string | null;
};

export type ICandidateInformationReportItem = {
  stt: number;
  candidateId?: string | number;
  channel: string;
  eRef: string;
  submissionDate: string;
  lastName: string;
  firstName: string;
  dob: string;
  genderDesc: string;
  gender: string;
  citizenId: string;
  idDate: string;
  idPlaceDesc: string;
  identityExpirationDate: string;
  residentAddress: string;
  residentAddressCode: string;
  residentAddressDesc: string;
  contactAddress: string;
  contactAddressCode: string;
  contactAddressDesc: string;
  despatchAddress: string;
  despatchAddressCode: string;
  despatchAddressDesc: string;
  email: string;
  phone: string;
  birthPlaceDesc: string;
  zalo: string;
  occupationDesc: string;
  occupation: string;
  educationDesc: string;
  education: string;
  marriedStatusDesc: string;
  marriedStatus: string;
  incomeDesc: string;
  leaderCode: string;
  leaderCodeDesc: string;
  bankCode: string;
  bankNameDesc: string;
  branchNameDesc: string;
  otherBankNameDesc: string;
  otherBranchNameDesc: string;
  bankAccount: string;
  taxNo: string;
  refAgentCode: string;
  refAgentName: string;
  potentialCustomer: string;
  potentialCustomerNumber: string;
  ethnicity: string;
  major: string;
  childrenNo: number;
  saleZone: string;
  officeCode: string;
  agCat: string;
  note: string;
  bsm: string;
  millenial: string;
  subChannel: string;
  oldIdentifyNumber?: string;
  isEditOcr?: boolean;
  idCard?: any; // object
};

export type ICandidateManagementReportItem = {
  _id: string;
  agentCode: string;
  role: string;
  unitCode: string;
  branchCode: string;
  officeCode: string;
  gadCode: string;
  landingPage: number;
  manual: number;
  contact: number;
  adminAssign: number;
};

export type IReportPerformanceFilter = {
  toDate?: Date | null;
  agentCode?: string;
  unitCode?: string;
  officeCode?: string;
  branchCode?: string;
  leaderCode?: string;
  activeStatus: string;
  page: number;
  limit: number;
};

export type ICandidatePerformanceReportItem = {
  _id: string;
  agentCode: string;
  unitCode: string;
  branchCode: string;
  officeCode: string;
  gaCode: string;
  leaderCode: string;
  joinDate: Date;
  prospect: number;
  activeStatus: string;
  fyp: number;
  ip: number;
};

export enum ChannelEnum {
  AD = 'Agency',
  PV = 'PRUVenture',
}
