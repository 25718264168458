import moment from 'moment';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { PaginateList } from 'src/app/common/types';
import { getFirstDayOfMonth, getLastDayOfMonth, useDataProvider } from 'src/app/common/utils';
import { getCustomerCampaign } from '../../network/customerCampaignCrud';
import { CustomerCampaignParams } from '../../types/customer-campaign-types';
import CustomerCampaignList from './components/customerCampaignList';

const paramsInitiator = (): CustomerCampaignParams => {
  return {
    startFromDate: getFirstDayOfMonth(new Date()),
    startToDate: getLastDayOfMonth(new Date()),
    endFromDate: getFirstDayOfMonth(new Date()),
    endToDate: getLastDayOfMonth(new Date()),
    status: '',
    campaignName: '',
    campaignId: '',
    page: 1,
    limit: 10,
  };
};

const CustomerCampaignPageIndex: FC<any> = ({ onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<CustomerCampaignParams>(paramsInitiator());
  const [customerCampaign, setCustomerCampaign] = useState<PaginateList<any>>();
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const { isLoading, refreshData } = useDataProvider<PaginateList<any>>(
    async () => {
      setFormLoading(true);
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        console.log('filterState', filterState);

        return await getCustomerCampaign(filterState, dispatch);
      } catch (err) {}
    },
    setCustomerCampaign,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('customer-campaign.filter.title')}
        itemDef={[
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'startFromDate',
            fieldTo: 'startToDate',
            initialDateFrom: filterState.startFromDate,
            initialDateTo: filterState.startToDate,
            displayName: Translation('customer-campaign.filter.startDate'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'endFromDate',
            fieldTo: 'endToDate',
            initialDateFrom: filterState.endFromDate,
            initialDateTo: filterState.endToDate,
            displayName: Translation('customer-campaign.filter.EndDate'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'status',
            // initialValue: filterState.status,
            displayName: Translation('customer-campaign.filter.status'),
            choices: [
              {
                displayName: 'All',
                value: '',
              },
              {
                displayName: 'Published',
                value: 'PUBLISHED',
              },
              {
                displayName: 'Unpublish',
                value: 'UNPUBLISHED',
              },
            ],
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'campaignName',
            // initialValue: filterState.campaignName,
            displayName: Translation('customer-campaign.filter.campaignName'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'campaignId',
            // initialValue: filterState.campaignId,
            displayName: Translation('customer-campaign.filter.campaignId'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            startFromDate: data.startFromDate,
            startToDate: data.startToDate,
            endFromDate: data.endFromDate,
            endToDate: data.endToDate,
            status: data.status,
            campaignName: data.campaignName,
            campaignId: data.campaignId,
          })
        }
        fetchData={refreshData}
      />
      <CustomerCampaignList
        isLoading={isLoading}
        onRefresh={refreshData}
        customerCampaignList={customerCampaign}
        onChangePage={(page: any, rowsPerPage: any) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        filterState={filterState}
      />
    </>
  );
};

export default CustomerCampaignPageIndex;
