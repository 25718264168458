import { forEach } from 'lodash';
import { Dispatch } from 'react';
import { PaginateList } from '../../../common/types/common-types';
import { getDayStart, getDayEnd } from 'src/app/common/utils/time-utils';
import { MdrtVideoLibraryDetail, MdrtVideoLibraryItem } from '../types/mdrt-video-library-types';
import { apiClient, apiErrorHandler } from 'src/app/common/network';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_MDRT']}`;

export type MdrtVideoLibraryListParams = {
  title: string;
  lastUpdatedDateFrom: Date | null;
  lastUpdatedDateTo: Date | null;
  page: number;
  limit: number;
};

export const create = async (
  data: MdrtVideoLibraryDetail,
  dispatch?: Dispatch<any>,
): Promise<MdrtVideoLibraryDetail> => {
  return apiClient
    .post<MdrtVideoLibraryDetail>(`${ADMIN_URL}/mdrt-hub/video-library`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const update = async (
  data: MdrtVideoLibraryDetail,
  dispatch?: Dispatch<any>,
): Promise<MdrtVideoLibraryDetail> => {
  return apiClient
    .put<MdrtVideoLibraryDetail>(`${ADMIN_URL}/mdrt-hub/video-library/${data.id}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchItem = async (id: string, dispatch?: Dispatch<any>): Promise<MdrtVideoLibraryDetail> => {
  return apiClient
    .get<MdrtVideoLibraryDetail>(`${ADMIN_URL}/mdrt-hub/video-library/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchList = async (
  params: MdrtVideoLibraryListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<MdrtVideoLibraryItem>> => {
  let queryPath = `${ADMIN_URL}/mdrt-hub/video-library/list?`;

  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.includes('From')) {
          queryPath += `${key}=${getDayStart(param).toISOString()}&`;
        }
        if (key.includes('To')) {
          queryPath += `${key}=${getDayEnd(param).toISOString()}&`;
        }
      }
    } else {
      queryPath += param ? `${key}=${param}&` : '';
    }
  });

  return apiClient
    .get<PaginateList<MdrtVideoLibraryItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const publish = async (id: string, dispatch?: Dispatch<any>): Promise<MdrtVideoLibraryDetail> => {
  return apiClient
    .patch<MdrtVideoLibraryDetail>(`${ADMIN_URL}/mdrt-hub/video-library/${id}/publish`, { id })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const unpublish = async (id: string, dispatch?: Dispatch<any>): Promise<MdrtVideoLibraryDetail> => {
  return apiClient
    .patch<MdrtVideoLibraryDetail>(`${ADMIN_URL}/mdrt-hub/video-library/${id}/unpublish`, { id })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteItem = async (id: string, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .delete(`${ADMIN_URL}/mdrt-hub/video-library/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
