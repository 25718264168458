export const SURVEY_BASE_PATH = "/survey";
export const SURVEY_MANAGEMENT_PATH = "/mgmt";

export const SURVEY_CANDIDATE_PATH = "/candidate";
export const SURVEY_LEADER_PATH = "/agent-leader";
export const SURVEY_NON_PROSPECT_PATH = "/non-prospect";
export const SURVEY_CUSTOMER_PATH = "/customer";
export const FLYERMESSAGELIMIT = 650;
export const SURVEY_CANDIDATE_RESOURCE = "candidateSurvey";
export const SURVEY_LEADER_RESOURCE = "agentLeaderSurvey";
export const SURVEY_NON_PROSPECT_RESOURCE = "nonProspectSurvey";

export const MANDATORY_FIELD_ERROR_TEXT = "This field is mandatory";
export const DATE_ERROR_TEXT = "End date must be after Begin date";

//  These constants map to aside menu, header menu to display title
export const SURVEY_TITLE = "title.survey";
export const SURVEY_MANAGEMENT_TITLE = "title.surveyManagement";
export const CANDIDATE_SURVEY_TITLE = "title.ci.survey.candidate";
export const LEADER_SURVEY_TITLE = "title.ci.survey.agentLeader";
export const NON_PROSPECT_SURVEY_TITLE = "title.ci.nonProspectSurvey";
export const CUSTOMER_SURVEY_TITLE = "title.ci.survey";

//  These constants map to permission control, identical to permission enum defined below
export const SURVEY_NAMESPACE = "SURVEY";
export const CANDIDATE_SURVEY_NAMESPACE = "CANDIDATE-SURVEY";
export const AGENT_LEADER_SURVEY_NAMESPACE = "AGENT-LEADER-SURVEY";
export const NON_PROSPECT_SURVEY_NAMESPACE = "NON-PROSPECT-SURVEY";
export const CUSTOMER_SURVEY_NAMESPACE = "CUSTOMER-SURVEY";

export enum AdminSurveyPermissionType {
  SURVEY_READ = "READ_ANY_SURVEY",
  SURVEY_CREATE = "CREATE_ANY_SURVEY",
  SURVEY_UPDATE = "UPDATE_ANY_SURVEY",
  CANDIDATE_SURVEY_READ = "READ_ANY_CANDIDATE-SURVEY",
  CANDIDATE_SURVEY_CREATE = "CREATE_ANY_CANDIDATE-SURVEY",
  CANDIDATE_SURVEY_UPDATE = "UPDATE_ANY_CANDIDATE-SURVEY",
  AGENT_LEADER_SURVEY_READ = "READ_ANY_AGENT-LEADER-SURVEY",
  AGENT_LEADER_SURVEY_CREATE = "CREATE_ANY_AGENT-LEADER-SURVEY",
  AGENT_LEADER_SURVEY_UPDATE = "UPDATE_ANY_AGENT-LEADER-SURVEY",
  NON_PROSPECT_SURVEY_READ = "READ_ANY_NON-PROSPECT-SURVEY",
  NON_PROSPECT_SURVEY_CREATE = "CREATE_ANY_NON-PROSPECT-SURVEY",
  NON_PROSPECT_SURVEY_UPDATE = "UPDATE_ANY_NON-PROSPECT-SURVEY",
  CUSTOMER_SURVEY_READ = "READ_ANY_CUSTOMER-SURVEY",
  CUSTOMER_SURVEY_CREATE = "CREATE_ANY_CUSTOMER-SURVEY",
  CUSTOMER_SURVEY_UPDATE = "UPDATE_ANY_CUSTOMER-SURVEY",
}

export const ASSIGN_SERVING_SERVEY_TITLE = "title.assignServingSurvey";
export const ASSIGN_SERVING_SERVEY_PATH = "/assign-serving-survey";
export const ASSIGN_SERVING_SERVEY_NAMESPACE = "ASSIGN-SERVING-SURVEY-UPLOAD";

export enum AdminAssignServingSurveyPermissionType {
  ASSIGN_SERVING_SURVEY_READ = "READ_ANY_ASSIGN-SERVING-SURVEY-UPLOAD",
  ASSIGN_SERVING_SURVEY_CREATE = "CREATE_ANY_ASSIGN-SERVING-SURVEY-UPLOAD",
  ASSIGN_SERVING_SURVEY_UPDATE = "UPDATE_ANY_ASSIGN-SERVING-SURVEY-UPLOAD"
};
