import { Document, Font, Image, Page, Text, StyleSheet } from '@react-pdf/renderer';

type QRCodePDFProps = {
  qrCodeDataURL: string;
  eventName?: string;
};

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmYUtvAw.ttf', // Roboto BoldfontWeight: 'bold',
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  boldText: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export const QRCodePDF = ({ qrCodeDataURL, eventName = '' }: QRCodePDFProps) => (
  <Document>
    <Page>
      <Image style={{ width: 480, height: 480, alignSelf: 'center' }} src={qrCodeDataURL} />
      <Text style={styles.boldText}>{eventName}</Text>
    </Page>
  </Document>
);
