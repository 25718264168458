import React, { FC, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Button, Checkbox, TextField, FormControl, Select, MenuItem, LinearProgress, Link } from '@mui/material';
import { createBlob, getBlob } from 'src/app/common/network';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { ErrorFieldType, useErrorHandler, ErrorFieldDef, fileUpload } from 'src/app/common/utils';
import { regionLocale, initI18nData, RegionLocale, I18nDataItem } from 'src/app/i18n';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { appendAlertItem, AlertType, AuthenticationState } from '@pruforce/common-adminweb-sdk';
import { RootState } from 'src/redux/store';
import { AgentLeadSourceFormMode, AgentLeadSource } from 'src/app/modules/PulseLeads/types/agent-types';
import { CampaignDropdownList, CampaignDropdownType } from 'src/app/modules/PulseLeads/types/campaign-types';
import { agentLeadSourcePath } from '../../AgentLeadSourceRoutes';
import {
  createAgentLeadSource,
  modifyAgentLeadSource,
  CreateAgentLeadSourceBody,
  UpdateAgentLeadSourceBody,
} from 'src/app/modules/PulseLeads/network/agentCrud';

type AgentLeadSourceFormProps = {
  formMode: AgentLeadSourceFormMode;
  agentLeadSource?: AgentLeadSource;
  agentLeadSourceDropdownList?: CampaignDropdownList;
  config?: any;
};

const FIELD_CONTAINER_WIDTH = 240;

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  fieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  innerFieldContainer: {
    width: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  iconContainer: {
    width: 60,
    height: 60,
    overflow: 'hidden',
    borderRadius: 12,
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 15,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  textAreaRowContainer: {
    width: '100%',
    display: 'flex',
  },
  textAreaFieldContainer: {
    paddingTop: 15,
    minWidth: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  optionDefault: {
    color: '#AAAAAA',
  },
}));

type AgentLeadSourceFormState = {
  sourceName?: string;
  i18nData: I18nDataItem;
  imageId?: string;
  imageUrl?: string;
  imageFileName?: string;
  parent?: string;
  isDefault?: boolean;
};

const initialState: AgentLeadSourceFormState = {
  sourceName: undefined,
  i18nData: { en: { name: undefined } },
  imageId: undefined,
  imageUrl: undefined,
  imageFileName: undefined,
  parent: undefined,
  isDefault: undefined,
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof AgentLeadSourceFormState;
    value: any;
  };
};

type UploadIconAction = {
  type: 'UPLOAD_IMAGE';
  payload: {
    blobId: string;
    filename: string;
    url: string;
  };
};

type AgentLeadSourceFormAction = ModifyFieldAction | UploadIconAction;

const formReducer = (state: AgentLeadSourceFormState, action: AgentLeadSourceFormAction): AgentLeadSourceFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'UPLOAD_IMAGE':
      return {
        ...state,
        imageId: action.payload.blobId,
        imageFileName: action.payload.filename,
        imageUrl: action.payload.url,
      };
    default:
      return state;
  }
};

const detailToStateConvertor = (
  regionLocale: RegionLocale[],
  agentLeadSource?: AgentLeadSource,
): AgentLeadSourceFormState => {
  const initialI18nData = initI18nData(regionLocale, agentLeadSource, agentLeadSource?.sourceName);
  return agentLeadSource
    ? {
        sourceName: agentLeadSource.sourceName,
        i18nData: initialI18nData,
        imageId: agentLeadSource.image?.blobId,
        imageFileName: agentLeadSource.image?.filename,
        imageUrl: agentLeadSource.image?.url,
        parent: agentLeadSource.parent as string,
        isDefault: agentLeadSource.isDefault
      }
    : {
        ...initialState,
        i18nData: initialI18nData,
      };
};

const getFieldConfig = (config: any) => {
  return {
    enableSubSource: config?.content?.Sales?.lead?.enableSubSource ? true : false,
  };
};

const AgentLeadSourceForm: FC<AgentLeadSourceFormProps> = ({
  formMode,
  agentLeadSource,
  agentLeadSourceDropdownList,
  config,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  const [imageUploadProgress, setImageUploadProgress] = useState<number>();
  const [formState, formDispatch] = useReducer(formReducer, detailToStateConvertor(regionLocale, agentLeadSource));
  const [isSubSource, setIsSubSource] = useState<boolean>(agentLeadSource?.parent ? true : false);

  const fieldConfig = getFieldConfig(config);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'image',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        return !!!(formState.imageId && formState.imageUrl);
      },
    },
    ...(() => {
      const errorFieldDef: ErrorFieldDef[] = [];
      regionLocale.forEach((locale) => {
        errorFieldDef.push({
          name: `i18nData-${locale}`,
          fieldType: ErrorFieldType.MANDATORY,
          condition: () => {
            return !!!formState.i18nData[locale]?.name;
          },
        });
      });
      return errorFieldDef;
    })(),
  ]);

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      try {
        const createBlobRes = await createBlob(
          { mimeType: file.type, accessLevel: 'public', module: 'pulseLeads' },
          dispatch,
        );
        await fileUpload(createBlobRes.url, file, setImageUploadProgress);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
        const result = blobDetail[0];
        if (result) {
          formDispatch({
            type: 'UPLOAD_IMAGE',
            payload: {
              blobId: result.blobId,
              filename: file.name,
              url: result.url,
            },
          });
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Upload icon successfully - ${file.name}`,
              },
            ]),
          );
          onDismissErrorHandler('image', file);
        }
      } finally {
        setImageUploadProgress(undefined);
      }
    }
  };

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      if (formMode === AgentLeadSourceFormMode.CREATE) {
        const body: CreateAgentLeadSourceBody = {
          sourceName: formState.sourceName || '',
          i18nData: formState.i18nData,
          image: {
            blobId: formState.imageId || '',
            filename: formState.imageFileName || '',
          },
          parent: formState.parent,
          isDefault: formState.isDefault,
          createdBy: user?.username || 'Default',
          updatedBy: user?.username || 'Default',
        };
        try {
          await createAgentLeadSource(body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: 'Lead Source saved successfully',
              },
            ]),
          );
          history.push(agentLeadSourcePath);
        } catch (err) {}
      } else if (agentLeadSource) {
        const body: UpdateAgentLeadSourceBody = {
          sourceName: formState.sourceName || '',
          i18nData: formState.i18nData,
          image: {
            blobId: formState.imageId || '',
            filename: formState.imageFileName || '',
          },
          parent: formState.parent,
          isDefault: formState.isDefault,
          updatedBy: user?.username || 'Default',
        };
        try {
          await modifyAgentLeadSource(agentLeadSource._id, body, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Lead Source updated successfully - ${agentLeadSource._id}`,
              },
            ]),
          );
          history.push(agentLeadSourcePath);
        } catch (err) {}
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {Translation(
              `pulseleads.agent.leadSource.form.title.${
                formMode === AgentLeadSourceFormMode.CREATE ? 'create' : 'edit'
              }`,
            )}
          </div>
        </div>
        <Button variant="contained" color="inherit" onClick={() => history.push(agentLeadSourcePath)}>
          {Translation('app.button.back')}
        </Button>
      </div>

      <div className={classes.sectionMargin}>
        <div style={{ marginBottom: 5 }} className={classes.rowContainer}>
          <div className={classes.fieldContainer} />
          {formState.imageUrl && (
            <div className={classes.iconContainer}>
              <img style={{ width: '100%', height: '100%' }} src={formState.imageUrl} alt="App Icon" />
            </div>
          )}
        </div>
        <div style={{ marginBottom: 10 }} className={classes.textAreaRowContainer}>
          <div style={{ paddingTop: 3 }} className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('pulseleads.agent.leadSource.image')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <input
              id="upload-lead-source-image"
              hidden
              type="file"
              accept="image/*"
              onClick={(e) => {
                const element = e.target as HTMLInputElement;
                element.value = '';
              }}
              onChange={(e) => handleFile(e)}
            />
            <div className={classes.rowContainer}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => document.getElementById('upload-lead-source-image')!.click()}
              >
                {Translation('app.button.chooseFile')}
              </Button>
              {formState.imageFileName && (
                <Link
                  style={{ marginLeft: 14 }}
                  target="_blank"
                  rel="noopener"
                  color="secondary"
                  href={formState.imageUrl}
                >
                  {formState.imageFileName}
                </Link>
              )}
              {errorState.mandatory.image && (
                <div style={{ marginLeft: 10 }} className={classes.errorText}>
                  {MANDATORY_FIELD_ERROR_TEXT}
                </div>
              )}
            </div>
            {!!imageUploadProgress && (
              <LinearProgress
                style={{ marginTop: 10 }}
                variant="determinate"
                color="secondary"
                value={imageUploadProgress}
              />
            )}
          </div>
        </div>

        {regionLocale.map((locale, index) => (
          <div className={classes.rowContainer} key={locale}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('pulseleads.agent.leadSource.name')} ({Translation(`navBar.lang.${locale}`)})
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }} className={classes.rowContainer}>
              <TextField
                style={{ width: 250, marginRight: 10 }}
                error={errorState.mandatory[`i18nData-${locale}`]}
                margin="dense"
                variant="outlined"
                helperText={errorState.mandatory[`i18nData-${locale}`] && MANDATORY_FIELD_ERROR_TEXT}
                value={formState.i18nData[locale]?.name}
                onChange={(e) => {
                  onDismissErrorHandler(`i18nData-${locale}`, e.target.value);
                  if (
                    (regionLocale.includes(RegionLocale.ENGLISH) && locale === RegionLocale.ENGLISH) ||
                    (!regionLocale.includes(RegionLocale.ENGLISH) && index === 0)
                  ) {
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'sourceName', value: e.target.value } });
                  }
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: {
                      field: 'i18nData',
                      value: {
                        ...formState.i18nData,
                        [locale]: {
                          name: e.target.value,
                        },
                      },
                    },
                  });
                }}
              />
              {fieldConfig.enableSubSource && index === 0 && (
                <>
                  <Checkbox
                    checked={isSubSource}
                    onChange={(e) => {
                      setIsSubSource(e.target.checked);
                      if (!e.target.checked) {
                        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'parent', value: undefined } });
                      }
                    }}
                  />
                  <span>{Translation('pulseleads.leadSource.common.isItASubSource')}</span>
                </>
              )}
              {index === 1 && (
                <>
                  <Checkbox
                    disabled={agentLeadSource?.isDefault}
                    checked={formState.isDefault}
                    onChange={(e) => {
                      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'isDefault', value: e.target.checked } });
                    }}
                  />
                  <span>{Translation('pulseleads.leadSource.common.autoAddLeadsSourceDefault')}</span>
                </>
              )}
            </div>
          </div>
        ))}

        {isSubSource && (
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('pulseleads.agent.common.parentAgentLeadSource')} :</span>
            </div>
            <FormControl margin="dense" variant="outlined">
              <Select
                style={{ minWidth: 350 }}
                value={formState.parent || ''}
                onChange={(e) => {
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'parent', value: e.target.value } });
                }}
              >
                <MenuItem className={classes.optionDefault} key={'option-default'} value={undefined}>
                  {Translation('common.dropdown.emptyValue')}
                </MenuItem>
                {agentLeadSourceDropdownList &&
                  agentLeadSourceDropdownList[CampaignDropdownType.AGENT_LEAD_SOURCE] &&
                  agentLeadSourceDropdownList[CampaignDropdownType.AGENT_LEAD_SOURCE]
                    .filter((dropdownItem) => dropdownItem.key !== agentLeadSource?._id)
                    .map((dropdownItem) => (
                      <MenuItem key={dropdownItem.key} value={dropdownItem.key}>
                        {dropdownItem.value}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </div>
        )}
      </div>

      <div className={classes.footerContainer}>
        <Button variant="contained" color="secondary" onClick={onSubmit}>
          {Translation('app.button.submit')}
        </Button>
      </div>
    </div>
  );
};

export default AgentLeadSourceForm;
