import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { map } from 'lodash';
import { useDataProvider, getDayStart, getDayEnd } from 'src/app/common/utils';
import { PaginateList } from 'src/app/common/types';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import {
  IncentiveTypeEnum,
  PublishStatusEnum,
  IncentiveListItem,
  IncentiveSetTypeEnum,
} from '../../../types/incentive-types';
import { fetchIncentiveList, IncentiveListParam } from '../../../network/incentiveCrud';
import IncentiveSetList from './components/IncentiveSetList';

type IncentiveSetListingPageProps = ParamsProps;

const initialState: IncentiveListParam = {
  type: IncentiveTypeEnum.INCENTIVE_SET,
  code: '',
  name: '',
  status: '',
  createdDateFrom: null,
  createdDateTo: null,
  startDateFrom: null,
  startDateTo: null,
  endDateFrom: null,
  endDateTo: null,
  isArchived: 'false',
  incentiveSetType: '',
  page: 1,
  limit: 5,
};

export const incentiveSetFilterKeys = [
  'code',
  'name',
  'status',
  'createdDateFrom',
  'createdDateTo',
  'startDateFrom',
  'startDateTo',
  'endDateFrom',
  'endDateTo',
  'isArchived',
  'incentiveSetType',
];

const paramsInitiator = (initialParams?: Record<string, string>): IncentiveListParam => {
  return initialParams
    ? {
        type: IncentiveTypeEnum.INCENTIVE_SET,
        code: initialParams.code ?? '',
        name: initialParams.name ?? '',
        status: initialParams.status ?? '',
        createdDateFrom: initialParams.createdDateFrom ? getDayStart(new Date(initialParams.createdDateFrom)) : null,
        createdDateTo: initialParams.createdDateTo ? getDayEnd(new Date(initialParams.createdDateTo)) : null,
        startDateFrom: initialParams.startDateFrom ? getDayStart(new Date(initialParams.startDateFrom)) : null,
        startDateTo: initialParams.startDateTo ? getDayEnd(new Date(initialParams.startDateTo)) : null,
        endDateFrom: initialParams.endDateFrom ? getDayStart(new Date(initialParams.endDateFrom)) : null,
        endDateTo: initialParams.endDateTo ? getDayEnd(new Date(initialParams.endDateTo)) : null,
        isArchived: initialParams.isArchived ?? 'false',
        incentiveSetType: initialParams.incentiveSetType ?? '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const IncentiveSetListingPage: FC<IncentiveSetListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<IncentiveListParam>(paramsInitiator(initialParams));
  const [incentiveSetList, setIncentiveSetList] = useState<PaginateList<IncentiveListItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<IncentiveListItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchIncentiveList(filterState, dispatch);
      } catch (err) {}
    },
    setIncentiveSetList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('incentive.set.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'code',
            initialValue: filterState.code,
            displayName: Translation('incentive.set.common.code'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'name',
            initialValue: filterState.name,
            displayName: Translation('incentive.set.common.name'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'status',
            initialValue: filterState.status,
            displayName: Translation('incentive.set.filter.status'),
            choices: [
              { displayName: '', value: '' },
              ...map(PublishStatusEnum, (option: string) => ({ displayName: option.toUpperCase(), value: option })),
            ],
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'createdDateFrom',
            fieldTo: 'createdDateTo',
            initialDateFrom: filterState.createdDateFrom,
            initialDateTo: filterState.createdDateTo,
            displayName: Translation('incentive.common.createdDate'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'startDateFrom',
            fieldTo: 'startDateTo',
            initialDateFrom: filterState.startDateFrom,
            initialDateTo: filterState.startDateTo,
            displayName: Translation('incentive.common.startDate'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'endDateFrom',
            fieldTo: 'endDateTo',
            initialDateFrom: filterState.endDateFrom,
            initialDateTo: filterState.endDateTo,
            displayName: Translation('incentive.common.endDate'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'isArchived',
            defaultValue: 'false',
            initialValue: filterState.isArchived,
            displayName: Translation('incentive.common.isArchived'),
            choices: [
              { displayName: 'True', value: 'true' },
              { displayName: 'False', value: 'false' },
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'incentiveSetType',
            initialValue: filterState.incentiveSetType,
            displayName: Translation('incentive.common.type'),
            choices: [
              { displayName: '', value: '' },
              ...map(IncentiveSetTypeEnum, (option: string) => ({ displayName: option, value: option.toUpperCase() })),
            ],
          },
        ]}
        onChangeFilter={(data) => {
          setFilterState({
            ...filterState,
            code: data.code,
            name: data.name,
            status: data.status || '',
            createdDateFrom: data.createdDateFrom,
            createdDateTo: data.createdDateTo,
            startDateFrom: data.startDateFrom,
            startDateTo: data.startDateTo,
            endDateFrom: data.endDateFrom,
            endDateTo: data.endDateTo,
            isArchived: data.isArchived,
            incentiveSetType: data.incentiveSetType,
          });
        }}
        fetchData={refreshData}
        isClearDateRange={true}
      />
      <IncentiveSetList
        isLoading={isLoading}
        onRefresh={refreshData}
        incentiveSetList={incentiveSetList}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default IncentiveSetListingPage;
