import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../common/constants';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import CustomerCampaignPageIndex from './List/customerCampaignListingIndex';
import { CUSTOMER_CAMPAIGN_BASE_PATH, CUSTOMER_CAMPAIGN_MANAGEMENT_PATH } from '../constants';
import CustomerCampaignCreatePage from './Create/CustomerCampaignCreatePage';

const CustomerCampaignRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={`${CUSTOMER_CAMPAIGN_BASE_PATH}`} to={`${CUSTOMER_CAMPAIGN_BASE_PATH}/list`} />
      <PruRoute exact path={`${CUSTOMER_CAMPAIGN_BASE_PATH}/list`} render={(props) => <CustomerCampaignPageIndex />} />
      <PruRoute exact path={`${CUSTOMER_CAMPAIGN_BASE_PATH}/view/:id`} component={CustomerCampaignCreatePage} />
      <PruRoute exact path={`${CUSTOMER_CAMPAIGN_BASE_PATH}/edit/:id`} component={CustomerCampaignCreatePage} />
      <PruRoute exact path={`${CUSTOMER_CAMPAIGN_BASE_PATH}/create`} component={CustomerCampaignCreatePage} />
    </Switch>
  );
};

export default CustomerCampaignRoutes;
