import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import { AdminMdrtPermissionType, MDRT_BASE_PATH, MDRT_NAMESPACE, MDRT_TITLE } from './constants';
import MdrtRoutes from './pages/MdrtRoutes';
import { isPermitted } from 'src/app/common/utils';

export const mdrtModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(MDRT_TITLE),
    path: MDRT_BASE_PATH,
    icon: '/media/svg/icons/Communication/Mdrt-config.svg',
    component: MdrtRoutes,
    namespace: MDRT_NAMESPACE,
    enableRead: isPermitted(
      [AdminMdrtPermissionType.MDRT_READ, AdminMdrtPermissionType.MDRT_CREATE, AdminMdrtPermissionType.MDRT_UPDATE],
      permissions,
    ),
  };
};
