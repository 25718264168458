import React, { FC, useState } from 'react';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { PaginateList } from '../../../../../common/types/common-types';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { getFirstDayOfMonth, getLastDayOfMonth } from '../../../../../common/utils/time-utils';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useIntl } from 'react-intl';
import { LivestreamParams, fetchLivestreamList } from '../../network/livestreamCrud';
import LivestreamList from './components/LivestreamList';
import { useLang } from 'src/app/i18n';

type livestreamListingPageProps = {} & ParamsProps;

const initialState: LivestreamParams = {
  name: '',
  createdAtFrom: null,
  createdAtTo: null,
  status: '',
  page: 1,
  limit: 10,
};

export const livestreamFilterKeys = ['name', 'status', 'createdAtFrom', 'createdAtTo'];

const paramsInitiator = (initialParams?: Record<string, string>): LivestreamParams => {
  return initialParams
    ? {
        name: initialParams.name ?? '',
        createdAtFrom: getFirstDayOfMonth(new Date()),
        createdAtTo: getLastDayOfMonth(new Date()),
        status: initialParams.status ?? '',
        page: 1,
        limit: 10,
      }
    : initialState;
};

const LivestreamListingPage: FC<livestreamListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const locale = useLang();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilterValue = paramsInitiator(initialParams);

  const [filterState, setFilterState] = useState<LivestreamParams>(paramsInitiator(initialParams));
  const [livestreamList, setLivestreamList] = useState<PaginateList<any>>();

  const { isLoading, refreshData } = useDataProvider<any>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }

        return await fetchLivestreamList(filterState, dispatch);
      } catch (err) {}
    },
    setLivestreamList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('livestream.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'name',
            initialValue: filterState.name,
            displayName: Translation('livestrean.filter.name'),
            maxLength: 200,
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'status',
            initialValue: filterState.status,
            displayName: Translation('livestrean.filter.status'),
            choices: [
              { displayName: Translation('livestrean.filter.status.display.all'), value: '' },
              { displayName: Translation('livestrean.filter.status.display.comingSoon'), value: 'coming-soon' },
              { displayName: Translation('livestrean.filter.status.display.broadcasting'), value: 'broadcasting' },
              { displayName: Translation('livestrean.filter.status.display.broadcasted'), value: 'broadcasted' },
              { displayName: Translation('livestrean.filter.status.display.hidden'), value: 'hidden' },
            ],
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'createdAtFrom',
            fieldTo: 'createdAtTo',
            initialDateFrom: filterState.createdAtFrom,
            initialDateTo: filterState.createdAtTo,
            displayName: Translation('livestrean.filter.createdAt'),
            isRequired: true,
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            name: data.name,
            createdAtFrom: data.createdAtFrom,
            createdAtTo: data.createdAtTo,
            status: data.status || '',
          })
        }
        fetchData={refreshData}
      />
      <LivestreamList
        isLoading={isLoading}
        onRefresh={refreshData}
        livestreamList={livestreamList}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        filterState={filterState}
        dispatch={dispatch}
      />
    </>
  );
};

export default LivestreamListingPage;
