import React, { FC, useState, useReducer, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { makeStyles } from 'tss-react/mui';
import { Button, FormControl, FormHelperText, LinearProgress, MenuItem, Select } from '@mui/material';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { createBlob, getBlob } from 'src/app/common/network';
import { csvHandler, ErrorFieldType, useErrorHandler, fileUpload } from 'src/app/common/utils';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import AsyncCsvLink from 'src/app/common/components/AsyncCsvLink';
import {
  isLeadReassignmentFileExist,
  createLeadReassignmentFile,
  downloadLeadReassignmentCsv,
  CreateLeadReassignmentFileBody,
} from 'src/app/modules/PulseLeads/network/leadReassignmentCrud';
import { LoadingButton } from '@mui/lab';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

const fieldsInFile = [
  'leadId',
  'leadRawId',
  'fromAgent',
  'toAgent',
  'campaignId',
  'leadReferredSource',
  'leadStatus',
  'firstName',
  'dob',
  'phoneNumber',
  'notification',
];

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rowContainer2: {
    display: 'flex',
    alignItems: 'start',
  },
  rowContainerCol: {
    display: 'flex',
    flexDirection: 'column',
    width: 135,
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  template: {
    color: 'grey',
  },
  fieldContainer: {
    width: 280,
    boxSizing: 'border-box',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  errorText: {
    fontSize: 12,
    color: '#F018A6',
  },
}));

type UploadFormState = {
  blobId?: string;
  filename?: string;
};

const initialState: UploadFormState = {
  blobId: undefined,
  filename: undefined,
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof UploadFormState;
    value: any;
  };
};

type UploadFileAction = {
  type: 'UPLOAD_FILE';
  payload: {
    blobId: string;
    filename: string;
  };
};

type ResetAction = {
  type: 'RESET_STATE';
};

type UploadFormAction = ModifyFieldAction | UploadFileAction | ResetAction;

const formReducer = (state: UploadFormState, action: UploadFormAction): UploadFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'UPLOAD_FILE':
      return {
        ...state,
        blobId: action.payload.blobId,
        filename: action.payload.filename,
      };
    case 'RESET_STATE':
      return initialState;
    default:
      return state;
  }
};

const LeadReassignmentUploadForm: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [isSubmit, setIsSubmit] = useState(false);
  const [uploading, setUploading] = useState<boolean>(false);

  const [formState, formDispatch] = useReducer(formReducer, initialState);
  const [uploadFileProgress, setUploadFileProgress] = useState<number>();

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'file',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        return !!!(formState.blobId && formState.filename);
      },
    },
  ]);

  const checkDataInFile = async (file: File) => {
    const data = await csvHandler(file);
    const headerRow = data[0];
    const dataRows = data.filter((_, index) => index > 0);
    const headerRowIndex = [];

    if (!headerRow || !headerRow.length) {
      return false;
    }
    for (let index = 0; index < headerRow.length; index++) {
      if (fieldsInFile.indexOf(headerRow[index]) === -1) {
        headerRow[index] = headerRow[index].replace(/^\uFEFF/g, '').replace(/^\u00EF?\u00BB\u00BF/g, '');
        headerRow[index] = headerRow[index].trim();

        if (fieldsInFile.indexOf(headerRow[index]) !== -1) {
          headerRowIndex.push(index);
        } else {
          return false;
        }
      } else {
        headerRowIndex.push(index);
      }
    }

    let isBlankInEndFile = false;
    let row = null;
    for (let index = 0; index < dataRows.length; index++) {
      row = dataRows[index];

      if (!row.length || row.length === 1) {
        row[0] = row[0].replace(/^\uFEFF/g, '').replace(/^\u00EF?\u00BB\u00BF/g, '');
        row[0] = row[0].trim();

        if (String(row[0]).trim() === '') {
          isBlankInEndFile = true;
        } else {
          return false;
        }
      } else {
        if (isBlankInEndFile) {
          return false;
        }
        for (let index = 0; index < headerRowIndex.length; index++) {
          if (row[index] && String(row[index]).trim() !== '') {
            // nothing
          } else {
            return false;
          }
        }
      }
    }

    return true;
  };

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setUploading(true);
      const file = e.target.files[0];

      if (file.size > 2 * 1024 * 1024) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: 'Error',
              content: `Maximum file 2Mb!`,
            },
          ]),
        );
        return;
      }

      const isValidFile = await checkDataInFile(file);
      if (!isValidFile) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: 'Error',
              content: `Error! Invalid parameter`,
            },
          ]),
        );
        setUploading(false);
        return;
      }

      if (await isLeadReassignmentFileExist(file.name, dispatch)) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: 'Error',
              content: `${file.name} has already been uploaded`,
            },
          ]),
        );
        setUploading(false);
      } else {
        try {
          const createBlobRes = await createBlob(
            { mimeType: file.type, accessLevel: 'public', originalFilename: file.name, module: 'pulseLeads' },
            dispatch,
          );
          await fileUpload(createBlobRes.url, file, setUploadFileProgress);
          const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
          const result = blobDetail[0];
          if (result) {
            formDispatch({
              type: 'UPLOAD_FILE',
              payload: {
                blobId: result.blobId,
                filename: file.name,
              },
            });
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: `Upload file successfully - ${file.name}`,
                },
              ]),
            );
            onDismissErrorHandler('file', file);
          }
        } finally {
          setUploadFileProgress(undefined);
          setUploading(false);
        }
      }
    }
  };

  const onSubmit = async () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      const body: CreateLeadReassignmentFileBody = {
        resourceId: formState.blobId,
        filename: formState.filename,
      };
      try {
        setIsSubmit((prev) => !prev);
        await createLeadReassignmentFile(body, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: 'Lead Reassignment uploaded successfully',
            },
          ]),
        );
        formDispatch({ type: 'RESET_STATE' });
      } catch (err) {
      } finally {
        setIsSubmit((prev) => !prev);
      }
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>{Translation(`title.pulseleads.leadReassignment`)}</div>
          </div>
        </div>
        <div>
          <div className={classes.rowContainer2} style={{ marginBottom: 10 }}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('pulseleads.leadReassignment.upload.leadReassignmentWithTemplate')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div>
              <input
                id="upload-file"
                hidden
                type="file"
                accept=".csv"
                onClick={(e) => {
                  const element = e.target as HTMLInputElement;
                  element.value = '';
                }}
                onChange={(e) => handleFile(e)}
              />
              <div className={classes.rowContainerCol}>
                <div>
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    onClick={() => document.getElementById('upload-file')!.click()}
                    loading={uploading}
                    loadingPosition="start"
                    startIcon={<ArrowCircleUpIcon />}
                  >
                    {Translation('app.button.chooseFile')}
                  </LoadingButton>
                </div>
                <div style={{ marginTop: 9, whiteSpace: 'nowrap' }}>
                  {formState.filename && <div>{formState.filename}</div>}
                  {errorState.mandatory.file && <div className={classes.errorText}>{MANDATORY_FIELD_ERROR_TEXT}</div>}
                </div>
              </div>

              {!!uploadFileProgress && (
                <LinearProgress
                  style={{ marginTop: 10 }}
                  variant="determinate"
                  color="secondary"
                  value={uploadFileProgress}
                />
              )}
            </div>

            <div className="col">
              <span>{Translation('pulseleads.leadReassignment.upload.leadReassignmentTemplateCaption')}</span>
              <AsyncCsvLink
                isDisabled={false}
                filename={`LeadReassign_ddmmyyyy.csv`}
                dataParser={(str) => str}
                asyncCall={() => downloadLeadReassignmentCsv()}
              >
                <Button style={{ padding: 1, fontSize: '12px' }}>{'Download Template'}</Button>
              </AsyncCsvLink>
            </div>
          </div>

          <div className={classes.footerContainer}>
            <Button variant="contained" color="secondary" onClick={onSubmit} disabled={isSubmit || !formState.blobId}>
              {Translation('app.button.submit')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadReassignmentUploadForm;
