import { PublishStatus } from "src/app/modules/PulseLeads/pages/Engagement/EngagementTopic/Detail/utils";
export interface completeMessage {
  title: string;
  content: string;
}

export type CandidateSurveyFormState = {
  startDate: Date | null;
  endDate: Date | null;
  consents: string[];
  body?: Record<string, unknown>;
  numberofTnc: number;
  completeMessage: completeMessage;
  surveyImage?: string;
  bannerId: string;
  status: string;
  flyerMessage: string;
  enableTargetAgents: boolean;
  targetAgents?: Array<{ agentCode: string }>;
};

export const CandidateinitialState: CandidateSurveyFormState = {
  startDate: null,
  endDate: null,
  consents: [],
  body: undefined,
  numberofTnc: 1,
  completeMessage: {
    title: "",
    content: "",
  },
  surveyImage: undefined,
  bannerId: "",
  status: PublishStatus.Draft.key,
  flyerMessage: "",
  enableTargetAgents: false,
  targetAgents: [],
};

export type ModifyFieldAction = {
  type: "MODIFY_FIELD";
  payload: {
    field: keyof CandidateSurveyFormState;
    value: any;
  };
};

export type ModifyCompleteMessageAction = {
  type: "MODIFY_COMPLETE_MESSAGE";
  payload: {
    field: keyof CandidateSurveyFormState;
    subField: any;
    value: any;
  };
};

export type CandidateSurveyErrorState = {
  mandatory: {
    startDate: boolean;
    endDate: boolean;
    body: boolean;
  };
  immediate: {
    endDateBeforeStartDate: boolean;
    flyerMessage: boolean;
  };
};
