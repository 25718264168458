import apiClient from '../axios';
import { ResourceCreateBlobRes, ResourceGetBlobRes } from '../../types';
import { Dispatch } from 'react';
import { ADMIN_URL } from '../constants';
import { apiErrorHandler } from '../apiErrorHandler';

export type CreateBlobParams = {
  mimeType: string;
  accessLevel: 'public' | 'private' | 'anonymous' | string;
  module?: string;
  originalFilename?: string;
};

export type GetBlobParams = {
  resourceIds: string | string[];
  random?: number;
};

export type GetContentParams = {
  resourceId: string;
  encoding?: string;
  safeMode?: boolean;
  autoDecrypt?: boolean;
  random?: number;
};

export const createBlob = async (
  params: CreateBlobParams,
  dispatch?: Dispatch<any>,
): Promise<ResourceCreateBlobRes> => {
  return apiClient
    .post<ResourceCreateBlobRes>(`${ADMIN_URL}/resource`, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      console.log('createBlob-err', err);

      throw apiErrorHandler(err, dispatch);
    });
};

export const getBlob = async (params: GetBlobParams, dispatch?: Dispatch<any>): Promise<ResourceGetBlobRes[]> => {
  if (!params.resourceIds) {
    throw new Error(`${ADMIN_URL}/resource, getBlob error: empty resourceIds`);
  }
  return apiClient
    .get<ResourceGetBlobRes[]>(`${ADMIN_URL}/resource`, { params })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getContent = async (params: GetContentParams, dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${ADMIN_URL}/resource/content`, { params })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createBlobAsAgent = async (
  params: CreateBlobParams,
  dispatch?: Dispatch<any>,
): Promise<ResourceCreateBlobRes[]> => {
  return apiClient
    .post<ResourceCreateBlobRes[]>(`${ADMIN_URL}/file-upload/blob`, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getBlobAsAgent = async (
  params: GetBlobParams,
  dispatch?: Dispatch<any>,
): Promise<ResourceGetBlobRes[]> => {
  return apiClient
    .get<ResourceGetBlobRes[]>(`${ADMIN_URL}/file-upload/${params.resourceIds}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
