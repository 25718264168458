import { combineReducers } from 'redux';
import { commonSlice } from "./common/commonSlice";
import { authReducer, authPersistConfig, alertReducer } from '@pruforce/common-adminweb-sdk';
import { configSlice, configPersistConfig } from '../app/modules/Config/_redux/configSlice';
import { persistReducer } from 'redux-persist';
import { salesKitSlice } from '../app/modules/sales-kit/redux/saleskit.slice';
import { layoutSlice } from './layout/layout-reducer';

export const rootReducer = combineReducers({
  common: commonSlice.reducer,
  alert: alertReducer,
  saleskit: salesKitSlice.reducer,
  auth: persistReducer(authPersistConfig, authReducer),
  config: persistReducer(configPersistConfig, configSlice.reducer),
  layout: layoutSlice.reducer,
});
