import React, { useState, ChangeEvent, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import { CircularProgress, FormLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Close } from '@mui/icons-material';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { Translation } from 'src/app/i18n';

const ImageUploaderMultiple = (props: any) => {
  const { onChange, maxImagesNumber = 1 } = props;

  const isFirstRender = useRef(true);

  const [images, setImages] = useState<any>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      onChange(images);
    }

    return () => {};
  }, [images]);

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        { mimeType: fileType, accessLevel: 'anonymous', module: 'updateCandidateAssessment' },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        return { id: result.blobId, fileName: fileName, url: result.url, type: fileType };
      }
    } catch (err) {
    } finally {
    }
  };

  const handleChangeImage = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    console.log(images, 'images');

    if (isOverkMaxFileNumbers(files, maxImagesNumber, `Maximum images is ${maxImagesNumber}`)) {
      return [...images];
    }

    let imageArr = [];

    setIsUploading(true);

    for (const file of files) {
      const { name: fileName, type: fileType } = file;

      const img = await handleUploadFile({
        file,
        fileName,
        fileType,
      });

      img && setImages((prev: any) => [...prev, img]);

      imageArr.push(img);
    }

    e.target.value = '';
    setIsUploading(false);

    return [...images, ...imageArr];
  };

  const handleRemoveImage = (index: number) => {
    setImages((prev: any) => {
      let images = [...prev];
      images.splice(index, 1);

      return images;
    });
  };

  const isOverkMaxFileNumbers = (files: any, maxFileNumber: number, maxFileErrorText: any) => {
    if (files?.length && files.length + images.length > maxFileNumber) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: Translation('global.submit.fail'),
            content: maxFileErrorText,
          },
        ]),
      );
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-flex-wrap">
        {images.length > 0 &&
          images.map((image: any, index: number) => (
            <div className="tw-mr-5 tw-relative" key={image.id}>
              <img className="tw-w-64 tw-h-64" src={image.url} alt={image.id} />
              <label className="tw-absolute tw-right-0 tw-top-0" onClick={() => handleRemoveImage(index)}>
                <Close />
              </label>
            </div>
          ))}
        {isUploading ? <CircularProgress className="tw-mr-2" /> : null}
      </div>

      {images.length < maxImagesNumber && (
        <>
          <FormLabel
            className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-border-solid tw-rounded-md tw-bg-blue-600 !tw-text-white !tw-p-3"
            htmlFor="input"
          >
            <AddIcon></AddIcon>Upload
          </FormLabel>
        </>
      )}
      <input
        style={{ display: 'none' }}
        accept="image/png, image/jpg, image/jpeg"
        type="file"
        id="input"
        multiple
        onChange={async (e) => {
          onChange(await handleChangeImage(e));
        }}
      />
    </>
  );
};

export default ImageUploaderMultiple;
