import { forEach } from 'lodash';
import { PaginateListMore } from '../../../common/types/common-types';
import { apiClient, apiErrorHandler } from 'src/app/common/network';
import { Dispatch } from 'react';
import { CandidateSurveyReportListParams } from '../types/candidateSurveyReportTypes';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

export const fetchCandidateSurveyReportDownload = (
  params: CandidateSurveyReportListParams,
  dispatch?: Dispatch<any>,
): void => {
  const uri = `${ADMIN_URL}/report/candidate/candidate-survey/export`;

  apiClient
    .post<Blob>(uri, { ...params }, { responseType: 'text' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report-candidate-survey' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCandidateSurveyReport = async (
  params: CandidateSurveyReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<any>> => {
  const uri = `${ADMIN_URL}/report/candidate/candidate-survey`;

  return apiClient
    .post<PaginateListMore<any>>(uri, { ...params })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
