import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
import { MdrtVideoLibraryDetail, MdrtVideoLibraryFormMode } from '../../../types/mdrt-video-library-types';
import { fetchItem } from '../../../network/mdrtVideoLibraryCrud';
import MdrtVideoLibraryDetailForm from './components/MdrtVideoLibraryDetailForm';
import { LayoutSplashScreen } from 'src/app/layout';

interface StateMdrtType {
  type: MdrtVideoLibraryFormMode;
}

const MdrtVideoLibraryDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode, itemId } = useMemo(() => {
    const state = location.state as StateMdrtType;
    return {
      formMode: state.type,
      itemId: id ? id : undefined,
    };
  }, [id, location]);

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemDetail, setItemDetail] = useState<MdrtVideoLibraryDetail>();

  const reloadData = () => {
    if (itemId) {
      setIsLoading(true);
      fetchItem(itemId, dispatch)
        .then((result) => {
          setItemDetail({ ...result, id: itemId });
        })
        .catch(() => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [itemId]);

  return (
    <>
      {formMode === MdrtVideoLibraryFormMode.CREATE && (
        <MdrtVideoLibraryDetailForm
          formMode={formMode}
          onReload={reloadData}
          onRouteTo={(route) => history.push(route)}
        />
      )}
      {formMode !== MdrtVideoLibraryFormMode.CREATE &&
        (isLoading || !itemDetail ? (
          <LayoutSplashScreen />
        ) : (
          <MdrtVideoLibraryDetailForm
            itemId={itemId}
            formMode={formMode}
            itemDetail={itemDetail}
            onReload={reloadData}
            onRouteTo={(route) => history.push(route)}
          />
        ))}
    </>
  );
};

export default MdrtVideoLibraryDetailPage;
