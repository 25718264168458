import React, { FC, useState, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, LinearProgress, CircularProgress } from '@mui/material';
import Form from 'src/app/common/components/Form';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { ErrorFieldType, useErrorHandler } from '../../../../../../../common/utils/form-error-utils';
import { PublishStatus } from '../../../../../types/ebirthday-card-type';
import { makeStyles } from 'tss-react/mui';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  fieldContainer: {
    width: 160,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  errorText: {
    fontSize: 10,
    color: '#f018a6',
    marginTop: 8,
  },
}));

export type TemplateImageItem = {
  blobId: string;
  fileName?: string;
  url?: string;
};

export type TemplateItem = {
  templateImage?: TemplateImageItem;
};

export type TemplateFormState = {
  fileId?: string;
  fileName?: string;
  fileUrl?: string;
  fileType?: string;
  templateImage?: TemplateImageItem;
};

type TemplateFormAction = ModifyFieldAction | UploadImageAction;

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof TemplateFormState;
    value: any;
  };
};

type UploadImageAction = {
  type: 'UPLOAD_IMAGE';
  payload: {
    id: string;
    fileUrl: string;
    fileType: string;
  };
};

const initialState: TemplateFormState = {
  templateImage: { blobId: '' },
  fileId: '',
  fileName: '',
  fileUrl: '',
  fileType: 'Image',
};

const templateFormReducer = (state: TemplateFormState, action: TemplateFormAction): TemplateFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'UPLOAD_IMAGE':
      return {
        ...state,
        fileUrl: action.payload.fileUrl,
        fileType: action.payload.fileType,
      };
  }
};

const detailToStateConvertor = (detail: TemplateItem): TemplateFormState => {
  return {
    templateImage: detail.templateImage,
  };
};

const PopUp: FC<any> = (props) => {
  // eslint-disable-next-line
  const { disabled, visible, isView, templateId, intialValues, onCancel, onSave, confirmLoading, templateItem } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });
  const { classes } = useStyles();
  const [form] = Form.useForm();
  const [formState, formDispatch] = useReducer(
    templateFormReducer,
    templateItem ? detailToStateConvertor(templateItem) : initialState,
  );
  const [uploadImageProgress, setUploadImageProgress] = useState<number>();

  const emptyValue = Translation('component.hint.empty-value-not-allowed');

  const onSubmitForm = (mode: PublishStatus) => {
    let { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      // Call Api and submit
      onSave({
        templateImage: formState.fileId
          ? {
              blobId: formState.fileId,
              fileName: formState.fileName,
              url: formState.fileUrl,
            }
          : undefined,
        status: mode,
      });
    }
  };

  const cancel = async () => {
    onCancel();
  };

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'fileName',
      fieldType: ErrorFieldType.MANDATORY,
    },
  ]);

  const handleImageFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const maxSize = 2;
      const file = e.target.files[0];
      const fileSize = file.size / 1024 / 1024;
      const fileType = file.type;
      e.target.value = '';
      const errMsgImage = TranslationWithVariable('global.max.file.size.allow', maxSize) || '';
      if (fileType.indexOf('image') > -1 && fileSize > maxSize) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: '',
              content: errMsgImage,
            },
          ]),
        );
        return;
      }
      try {
        const createBlobRes = await createBlob(
          { mimeType: fileType, accessLevel: 'anonymous', module: 'ebirthdaycard' },
          dispatch,
        );
        await fileUpload(createBlobRes.url, file, setUploadImageProgress);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
        const result = blobDetail[0];
        if (result) {
          formDispatch({
            type: 'UPLOAD_IMAGE',
            payload: {
              id: result.blobId,
              fileUrl: result.url,
              fileType: result.mimeType,
            },
          });
        }
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'fileId', value: result.blobId } });
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'fileName', value: result.blobName } });
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Upload image file successfully - ${file.name}`,
            },
          ]),
        );
        onDismissErrorHandler('fileName', result.blobName);
      } catch (err) {
      } finally {
        setUploadImageProgress(undefined);
      }
    }
  };

  const removeImageFile = () => {
    formDispatch({
      type: 'UPLOAD_IMAGE',
      payload: {
        id: '',
        fileUrl: '',
        fileType: 'Image',
      },
    });
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'fileId', value: '' } });
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'fileName', value: '' } });
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: `Remove image file successfully`,
        },
      ]),
    );
  };

  return (
    <>
      <Form form={form} initialValues={intialValues} className={classes.container}>
        <div className={classes.rowContainer}>
          <h5>{Translation('event.ebirthday_card.template.detail.upload_file')}</h5>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer} style={{ alignSelf: 'flex-start', paddingTop: '8px' }}>
            <span className={classes.field}>
              {Translation('event.ebirthday_card.template.detail.template_image')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <input
              id="upload-banner"
              hidden
              type="file"
              accept="image/jpg, image/jpeg, image/png"
              onChange={handleImageFile}
            />
            <div className={classes.rowContainer}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => document.getElementById('upload-banner')!.click()}
              >
                {formState.fileUrl
                  ? Translation('event.ebirthday_card.template.detail.update_file')
                  : Translation('event.ebirthday_card.template.detail.choose_file')}
              </Button>
              {formState.fileUrl ? (
                <Button
                  style={{ marginLeft: 20 }}
                  // disabled={isDisabled}
                  variant="contained"
                  color="secondary"
                  onClick={() => removeImageFile()}
                >
                  {Translation('event.ebirthday_card.template.detail.remove_file')}
                </Button>
              ) : null}
            </div>
            {errorState.mandatory.fileName && <div className={classes.errorText}>{emptyValue}</div>}
            {!!uploadImageProgress && (
              <LinearProgress style={{ marginTop: 10 }} variant="determinate" value={uploadImageProgress} />
            )}
          </div>
        </div>
        <div className={classes.rowContainer} style={{ marginTop: 15 }}>
          <div className={classes.fieldContainer}></div>
          <div style={{ flexGrow: 1 }}>
            <div className={classes.rowContainer}>
              <div>{formState.fileName ? formState.fileName : ''}</div>
            </div>
            <div className={classes.rowContainer}>
              {formState.fileUrl && (
                <div>
                  <img
                    style={{ width: 'auto', height: '80px', marginBottom: '20px' }}
                    src={formState.fileUrl}
                    alt="Template Image"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {!formState.fileUrl && (
          <div className={classes.rowContainer} style={{ marginTop: 15 }}>
            <div>{Translation('event.ebirthday_card.template.detail.image_maximum')}</div>
            <div>{Translation('event.ebirthday_card.template.detail.image_size')}</div>
          </div>
        )}
        <div className={classes.footerContainer}>
          {!disabled ? (
            <>
              {/* todo: cancel warling */}
              <Button variant="contained" onClick={cancel}>
                {Translation('app.button.cancel')}
              </Button>
              <Button
                disabled={confirmLoading}
                type="submit"
                style={{ marginLeft: 20 }}
                variant="contained"
                color="secondary"
                onClick={() => onSubmitForm(PublishStatus.UNPUBLISHED)}
              >
                {Translation('app.button.save')}
                {confirmLoading && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
              </Button>
              <Button
                disabled={confirmLoading}
                type="submit"
                style={{ marginLeft: 20 }}
                variant="contained"
                color="secondary"
                onClick={() => onSubmitForm(PublishStatus.PUBLISHED)}
              >
                {Translation('component.formSelectItem.save-publish')}
                {confirmLoading && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
              </Button>
            </>
          ) : null}
        </div>
      </Form>
    </>
  );
};

export default PopUp;
