import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import { isPermitted } from 'src/app/common/utils';
import * as constants from './constants';

import MandatoryTrainingCategoryPage from './pages/Category/Routes';
import MandatoryTrainingCollectionPage from './pages/Collection/Routes';

export const MandatoryTrainingModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });

  return {
    title: Translation(constants.MANDATORY_TRAINING_TITLE),
    path: constants.MANDATORY_TRAINING_BASE_PATH,
    icon: '/media/svg/icons/Text/Menu.svg',
    enableRead: isPermitted(Object.values(constants.AdminLeadsPermissionType), permissions),
    child: [
      {
        title: Translation(constants.MANDATORY_TRAINING_CATEGORY_TITLE),
        path: `${constants.MANDATORY_TRAINING_BASE_PATH}${constants.MANDATORY_TRAINING_CATEGORY_PATH}`,
        namespace: constants.MANDATORY_TRAINING_CATEGORY_NAMESPACE,
        component: MandatoryTrainingCategoryPage,
        enableRead: isPermitted(
          [
            constants.AdminLeadsPermissionType.MANDATORY_TRAINING_CATEGORY_CREATE,
            constants.AdminLeadsPermissionType.MANDATORY_TRAINING_CATEGORY_READ,
            constants.AdminLeadsPermissionType.MANDATORY_TRAINING_CATEGORY_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([constants.AdminLeadsPermissionType.MANDATORY_TRAINING_CATEGORY_CREATE], permissions),
        enableUpdate: isPermitted([constants.AdminLeadsPermissionType.MANDATORY_TRAINING_CATEGORY_UPDATE], permissions),
      },
      {
        title: Translation(constants.MANDATORY_TRAINING_COLLECTION_TITLE),
        path: `${constants.MANDATORY_TRAINING_BASE_PATH}${constants.MANDATORY_TRAINING_COLLECTION_PATH}`,
        namespace: constants.MANDATORY_TRAINING_COLLECTION_NAMESPACE,
        component: MandatoryTrainingCollectionPage,
        enableRead: isPermitted(
          [
            constants.AdminLeadsPermissionType.MANDATORY_TRAINING_COLLECTION_CREATE,
            constants.AdminLeadsPermissionType.MANDATORY_TRAINING_COLLECTION_READ,
            constants.AdminLeadsPermissionType.MANDATORY_TRAINING_COLLECTION_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted(
          [constants.AdminLeadsPermissionType.MANDATORY_TRAINING_COLLECTION_CREATE],
          permissions,
        ),
        enableUpdate: isPermitted(
          [constants.AdminLeadsPermissionType.MANDATORY_TRAINING_COLLECTION_UPDATE],
          permissions,
        ),
      },
    ],
  };
};
