import { IntlShape } from 'react-intl';
export enum ResourceType {
  file = 'file',
  link = 'link',
  content = 'content',
  text = 'text',
  materialSet = 'materialSet',
  picture = 'picture',
}

export enum YesAndNo {
  'yes' = 'Y',
  'no' = 'N',
}

export enum ProductType {
  'Conventional' = 'conventional',
  'Sharia' = 'sharia',
}

export enum RegisterPageType {
  'template' = 'template',
  'insert-url' = 'url',
}

export enum BottomBarShared {
  // 'recruit-link' = 'link',
  'name-card' = 'card',
}

export enum IsPublish {
  'save-publish' = 'Y',
  'save-as-draft' = 'N',
}

// eslint-disable-next-line
type PublishStatusProps = {
  label: string;
  key: string;
};

export const targetReceiver = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    allAgent: { label: Translation('resource_targetReceiver_allAgent'), key: 'all' },
    targetAgent: { label: Translation('resource_targetReceiver_targetAgent'), key: 'target' },
  };
};

export const targetType = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    byList: { label: Translation('resource_targetType_byList'), key: 'agentList' },
    byGroup: { label: Translation('resource_targetType_byGroup'), key: 'agentGroup' },
  };
};

export const productType = (intl: IntlShape) => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    conventional: { label: Translation('product_type_conventional'), key: 'conventional' },
    sharia: { label: Translation('product_type_sharia'), key: 'sharia' },
  };
};

export const PublishStatus: { [item: string]: any } = {
  Draft: {
    label: 'component.formSelectItem.save-as-draft',
    key: 'Draft',
  },
  Published: {
    label: 'section.common.operation.publish',
    key: 'Published',
  },
  PublishedToSetOnly: {
    label: 'component.formSelectItem.publish-to-set-only',
    key: 'PublishedToSetOnly',
  },
  Unpublished: {
    label: 'section.common.operation.unpublish',
    key: 'Unpublished',
  },
};

export const MaterialStatus: { [item: string]: any } = {
  Draft: {
    label: 'Draft',
  },
  Published: {
    label: 'Published',
  },
  PublishedToSetOnly: {
    label: 'Published to Set Only',
  },
  Unpublished: {
    label: 'Unpublished',
  },
};

export enum LibraryPublishStatus {
  Publish = 'Published',
  Unpublished = 'Unpublished',
  Draft = 'Draft',
  PublishToSetOnly = 'PublishedToSetOnly',
}

export const MAX_DATE = '2969-12-31T16:00:00.000Z';

export const MIN_DATE = '1969-12-31T16:00:00.000Z';

export enum LinkType {
  Url = 'url',
  UrlWithVideo = 'urlWithVideo',
  UrlWithInfor = 'urlWithInfor',
}

export const linkOptions = [
  { value: LinkType.Url, label: 'url' },
  { value: LinkType.UrlWithVideo, label: 'urlWithVideo' },
  { value: LinkType.UrlWithInfor, label: 'urlWithInfor' },
];

export enum EQuickQuote {
  Default = 'none',
  Hcr = 'hcr',
  Edu = 'edu',
  OptimalProtect = 'optimal-protect',
  Amassment = 'amassment',
  HealthInsurance = 'health-insurance',
  FlexibleInvestment = 'flexible-investment',
}

export const quickQuoteOptions = [
  { value: EQuickQuote.Default, label: 'None' },
  { value: EQuickQuote.Hcr, label: 'PRU - Hành Trang Vui Khỏe' },
  { value: EQuickQuote.Edu, label: 'PRU - Hành Trang Trưởng Thành' },
  { value: EQuickQuote.OptimalProtect, label: 'PRU - Bảo Vệ Tối Ưu' },
  { value: EQuickQuote.Amassment, label: 'PRU - Chủ Động Cuộc Sống' },
  { value: EQuickQuote.HealthInsurance, label: 'PRU - An Vui' },
  { value: EQuickQuote.FlexibleInvestment, label: 'PRU - Đầu tư linh hoạt' },
];

export enum TypeOfView {
  AllTraditionalAgents = 'allTraditionalAgents',
  AgentLeaders = 'agentLeaders',
  PruventureLeaders = 'pruventureLeaders',
  Pruventure = 'pruventure',
  GroupOfUsers = 'groupOfUsers',
}

export const typeOfViewOptions = [
  { value: TypeOfView.AllTraditionalAgents, label: 'all-traditional-agents' },
  { value: TypeOfView.AgentLeaders, label: 'agent-leaders' },
  { value: TypeOfView.PruventureLeaders, label: 'pruventure-leaders' },
  { value: TypeOfView.Pruventure, label: 'pruventure' },
  { value: TypeOfView.GroupOfUsers, label: 'group-of-users' },
];
