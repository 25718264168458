import React, { FC, useEffect, useMemo, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Tooltip,
  IconButton,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
} from '@mui/material';
import { Info, CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { PruDatePicker, PruDateTimePicker } from 'src/app/common/components/PruDatePicker';
import { regionLocale, initI18nGenericData, I18nGenericData, RegionLocale } from 'src/app/i18n';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { AttachmentDef } from 'src/app/common/types';
import AsyncCsvLink from 'src/app/common/components/AsyncCsvLink';
import { TargetApplicantTypeEnum } from 'src/app/common/components/target-applicant-section-v2';
import { MANDATORY_FIELD_ERROR_TEXT, DATE_ERROR_TEXT, DATE_FORMAT_ERROR_TEXT } from 'src/app/common/constants';
import {
  IncentiveFormMode,
  IncentiveTypeEnum,
  PublishStatusEnum,
  IncentiveDetail,
  IncentiveSetTypeEnum,
} from '../../../../types/incentive-types';
import { KpiTypeEnum, KpiItem } from '../../../../types/kpi-types';
import {
  CreateUpdateIncentiveBody,
  createNewIncentive,
  modifyIncentive,
  unpublishIncentive,
  fetchIncentiveAgentTarget,
  uploadExcelImportContent,
} from '../../../../network/incentiveCrud';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { createBlob, getBlob } from 'src/app/common/network';
import {
  preFillZeroList,
  getDayStart,
  getDayEnd,
  getConfigurations,
  fileUpload,
  useErrorHandler,
  ErrorFieldType,
  ErrorFieldDef,
  toAbsoluteUrl,
} from 'src/app/common/utils';
import { incentivePath } from '../../IncentiveRoutes';
import { IncentiveFormState, HighlightFormState, AwardFormState, incentiveFormReducer } from '../reducers';
import UpdateSetDialog from '../../components/UpdateSetDialog';
import HighlightSection from './HighlightSection';
import AwardSection from './AwardSection';
import { EIncentiveTemplateType, ENotificationType } from 'src/app/modules/Incentive/types/incentive-types';
import FileUploader from 'src/app/common/components/FileUploader';
import Tinymce from 'src/app/modules/News/pages/Article/View/components/Tinymce';
import { InPublishSettingReceiver } from 'src/app/modules/Event/pages/InvitationLetter/Detail/constants';
import CheckGroup from 'src/app/common/components/CheckGroup';
import { isEmptyArray } from 'formik';

type IncentiveDetailFormProps = {
  code?: string;
  formMode: IncentiveFormMode;
  kpiList: KpiItem[];
  nonRankKpiList: KpiItem[];
  categoryKpiList: KpiItem[];
  incentiveDetail?: IncentiveDetail;
  onRouteTo: (route: string) => void;
};

type UpdateSetDialogState = {
  open: boolean;
  mode?: PublishStatusEnum;
};

const detailToStateConvertor = (regionLocale: RegionLocale[], detail?: IncentiveDetail): IncentiveFormState => {
  const initI18nStringData = initI18nGenericData<string>(regionLocale);
  const initI18nFileData = initI18nGenericData<AttachmentDef>(regionLocale);
  if (detail) {
    return {
      code: detail.code,
      name: detail.name,
      incentiveSetType: detail.incentiveSetType,
      status: detail.status,
      startDate: detail.startDate,
      endDate: detail.endDate,
      detail: detail.detail,
      objective: detail.objective || initI18nStringData,
      bulletin: detail.bulletin || initI18nStringData,
      award: detail.award || initI18nStringData,
      incentiveImage: detail.incentiveImage || initI18nFileData,
      bulletinFile: detail.bulletinFile || initI18nFileData,
      highlights: detail.highlights || [],
      awards: detail.awards || [],
      stickOnTop: detail.stickOnTop ? true : false,
      publishDate: detail.publishDate,
      archiveDate: detail.archiveDate,
      targetApplicantType: detail.targetApplicantType,
      eligibleDesignations: detail.eligibleDesignations || [],
      eligibleSegments: detail.eligibleSegments || [],
      eligibleAgents: detail.eligibleAgents || [],
      excludedAgents: detail.excludedAgents || [],
      agentListFile: detail.agentListFile,
      resultFile: detail.resultFile,
      templateType: detail.template?.type || EIncentiveTemplateType.FIXED,
      templateBodyImg: detail.template?.bodyImg || null,
      templateHeaderImg: detail.template?.headerImg || null,
      templateFooterImg: detail.template?.footerImg || null,
      templateBody: detail.template?.body || '',
      isLimitRegister: detail.registerLimit?.isLimited ? true : false,
      limitNumber: detail.registerLimit?.limitNumber || null,
      registrationRequired: detail.registrationRequired ? true : false,
      notificationType: detail.notification?.type || ENotificationType.INBOX,
      receivers: detail.receivers,
      startEnrollmentDate: detail.startEnrollmentDate,
      endEnrollmentDate: detail.endEnrollmentDate,
      receiverUserFile: detail.receiverUserFile,
      includeSA: detail.includeSA,
    };
  } else {
    return {
      code: undefined,
      name: initI18nStringData,
      status: PublishStatusEnum.DRAFT,
      incentiveSetType: IncentiveSetTypeEnum.INCENTIVE,
      startDate: null,
      endDate: null,
      detail: initI18nStringData,
      objective: initI18nStringData,
      bulletin: initI18nStringData,
      award: initI18nStringData,
      incentiveImage: initI18nFileData,
      bulletinFile: initI18nFileData,
      highlights: [],
      awards: [],
      stickOnTop: false,
      publishDate: null,
      archiveDate: null,
      targetApplicantType: TargetApplicantTypeEnum.ALL,
      eligibleDesignations: [],
      eligibleSegments: [],
      eligibleAgents: [],
      excludedAgents: [],
      agentListFile: undefined,
      resultFile: undefined,
      templateType: EIncentiveTemplateType.FIXED,
      templateBodyImg: null,
      templateHeaderImg: null,
      templateFooterImg: null,
      templateBody: '',
      isLimitRegister: false,
      limitNumber: null,
      registrationRequired: false,
      notificationType: ENotificationType.INBOX,
      receivers: [],
      startEnrollmentDate: null,
      endEnrollmentDate: null,
      receiverUserFile: null,
      includeSA: false,
    };
  }
};
const FIELD_CONTAINER_WIDTH = 160;
const TEXT_FIELD_WIDTH = 200;
const DATE_PICKER_WIDTH = 240;

const useStyles = makeStyles()((theme) => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 18,
  },
  section: {
    padding: 32,
    marginBottom: 32,
    borderRadius: 8,
    backgroundColor: theme.palette.common.white,
  },
  loadingContainer: {
    width: '100%',
    padding: 50,
    display: 'flex',
    justifyContent: 'center',
  },
  subHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  fillInContainer: {
    marginLeft: 30,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rowSpacing: {
    marginBottom: 16,
  },
  detailSpacing: {
    marginBottom: 50,
  },
  detailTextContainer: {
    marginBottom: 24,
  },
  progressSpacing: {
    marginTop: 16,
    marginBottom: 16,
  },
  textOnlyRowSpacing: {
    marginBottom: 32,
  },
  multiRowContainer: {
    display: 'flex',
  },
  fieldContainer: {
    minWidth: FIELD_CONTAINER_WIDTH,
    boxSizing: 'border-box',
  },
  fieldPadding: {
    paddingTop: 7,
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  valueOnlyContainer: {
    marginLeft: FIELD_CONTAINER_WIDTH,
  },
  textField: {
    minWidth: TEXT_FIELD_WIDTH,
  },
  noMargin: {
    margin: 0,
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  datePicker: {
    minWidth: DATE_PICKER_WIDTH,
    margin: 0,
  },
  chooseFileSpacing: {
    marginLeft: 24,
  },
  checkboxPadding: {
    paddingLeft: 0,
  },
  radioButtonSpacing: {
    marginLeft: 32,
  },
  mandatory: {
    color: 'red',
  },
  dateDivider: {
    marginLeft: 8,
    marginRight: 8,
  },
  normalBox: {
    width: 250,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // marginTop: 70,
  },
  childContent: {
    width: '100%',
    padding: '15px',
    borderRadius: '3px',
    border: '1px solid #0000003b',
  },
  errorText: {
    fontSize: 10,
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: '#F018A6',
    marginTop: 4,
  },
}));

const IncentiveDetailForm: FC<IncentiveDetailFormProps> = ({
  code,
  formMode,
  kpiList,
  nonRankKpiList,
  categoryKpiList,
  incentiveDetail,
  onRouteTo,
}) => {
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const dispatch = useDispatch();
  const enableKpiShowHide = !!getConfigurations()?.Incentive?.criteriaCategory?.enableOnOffDisplay;
  const enableAgentTarget =
    getConfigurations()?.Incentive?.dataProvider === 'API' &&
    !!getConfigurations()?.Incentive?.individualTarget?.enableDownloadAllButton;
  const locale = RegionLocale.VIETNAMESE;

  const initialUploadProgress = initI18nGenericData<number>(regionLocale);
  const [uploadImageProgress, setUploadImageProgress] = useState<I18nGenericData<number>>(initialUploadProgress);
  const [uploadBulletinProgress, setUploadBulletinProgress] = useState<I18nGenericData<number>>(initialUploadProgress);

  const [updateSetDialogState, setUpdateSetDialogState] = useState<UpdateSetDialogState>({ open: false });

  const [formState, formDispatch] = useReducer(
    incentiveFormReducer,
    detailToStateConvertor(regionLocale, incentiveDetail),
  );

  const templateTypeOptions = [
    { value: EIncentiveTemplateType.FIXED, label: 'incentive.form.formField.selectTemplate.fixed' },
    { value: EIncentiveTemplateType.CUSTOMIZE, label: 'incentive.form.formField.selectTemplate.customize' },
  ];

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, immediateErrorValidator } = useErrorHandler(
    formState,
    [
      {
        name: 'code',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'startDate',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'startDate',
        fieldType: ErrorFieldType.IMMEDIATE,
        validateDate: true,
      },
      {
        name: 'endDate',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'endDate',
        fieldType: ErrorFieldType.IMMEDIATE,
        validateDate: true,
      },
      {
        name: 'endDateBeforeStartDate',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.startDate && formState.endDate) {
            return !!moment(new Date(formState.endDate)).isBefore(moment(new Date(formState.startDate)));
          } else {
            return false;
          }
        },
      },
      {
        name: 'highlights',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => {
          if (formState.highlights.length !== 0) {
            for (const highlight of formState.highlights) {
              if (!!!highlight.keyId) {
                return true;
              }
            }
          }
          return false;
        },
      },
      {
        name: 'templateBodyImg',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => {
          if (formState.templateType == EIncentiveTemplateType.FIXED && !formState.templateBodyImg) {
            return true;
          }
          return false;
        },
      },
      {
        name: 'templateBody',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => {
          if (formState.templateType == EIncentiveTemplateType.CUSTOMIZE && !formState.templateBody) {
            return true;
          }
          return false;
        },
      },
      {
        name: 'awards',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => {
          if (formState.awards.length !== 0) {
            for (const award of formState.awards) {
              for (const requirement of award.requirements) {
                for (const item of requirement.items) {
                  if (!!!item.keyId || !!!item.value || item.value.length === 0) {
                    return true;
                  }
                }
              }
            }
          }
          return false;
        },
      },
      {
        name: 'publishDate',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'publishDate',
        fieldType: ErrorFieldType.IMMEDIATE,
        validateDate: true,
      },
      {
        name: 'archiveDate',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'archiveDate',
        fieldType: ErrorFieldType.IMMEDIATE,
        validateDate: true,
      },
      {
        name: 'archiveDateBeforeEndDate',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.archiveDate && formState.endDate) {
            return !!moment(new Date(formState.archiveDate)).isBefore(moment(new Date(formState.endDate)));
          } else {
            return false;
          }
        },
      },
      // EnrollmentDate
      {
        name: 'startEnrollmentDate',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => {
          if (formState.registrationRequired && !formState.startEnrollmentDate) {
            return true;
          }
          return false;
        },
      },
      {
        name: 'startEnrollmentDate',
        fieldType: ErrorFieldType.IMMEDIATE,
        validateDate: true,
      },
      {
        name: 'endEnrollmentDate',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => {
          if (formState.registrationRequired && !formState.endEnrollmentDate) {
            return true;
          }
          return false;
        },
      },
      {
        name: 'endEnrollmentDate',
        fieldType: ErrorFieldType.IMMEDIATE,
        validateDate: true,
      },
      {
        name: 'receivers',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => isEmptyArray(formState.receivers),
      },
      {
        name: 'receiverUserFile',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => {
          if (
            !isEmptyArray(formState.receivers) &&
            formState.receivers.indexOf(InPublishSettingReceiver.GROUP_OF_USERS) > -1
          ) {
            var check = formState.receiverUserFile == undefined || formState.receiverUserFile == null;
            return check;
          }
          return false;
        },
      },
      ...(() => {
        let errorFieldDef: ErrorFieldDef[] = [];
        regionLocale.forEach((locale) => {
          errorFieldDef = [
            ...errorFieldDef,
            {
              name: `name-${locale}`,
              fieldType: ErrorFieldType.MANDATORY,
              condition: () => !!!formState.name[locale],
            },
            // {
            //   name: `detail-${locale}`,
            //   fieldType: ErrorFieldType.MANDATORY,
            //   condition: () => !!!formState.detail[locale],
            // },
          ];
        });
        return errorFieldDef;
      })(),
    ],
  );

  useEffect(
    () => {
      immediateErrorValidator();
    },
    // eslint-disable-next-line
    [formState.startDate, formState.endDate, formState.publishDate, formState.archiveDate],
  );

  const isDisabled = useMemo(() => {
    return formState.status && formState.status === PublishStatusEnum.PUBLISHED ? true : false;
  }, [formState.status]);

  const highlightSection = useMemo(() => {
    const getInitialHighlightFormState = (): HighlightFormState => {
      if (formState.highlights.length === 0) {
        return { highlights: [] };
      } else {
        const rankHighlight = formState.highlights.find(
          (highlight) => highlight.rankTopCount !== undefined && highlight.rankValueKeyId !== undefined,
        );
        return {
          highlights: formState.highlights,
          selectedRankKpiItem: rankHighlight
            ? kpiList.find((kpiItem) => kpiItem._id === rankHighlight.keyId)
            : undefined,
        };
      }
    };
    if (kpiList.length !== 0) {
      return (
        <HighlightSection
          style={{ minWidth: 450 }}
          isDisabled={isDisabled}
          kpiList={kpiList.filter((item) => item.type !== KpiTypeEnum.CATEGORY)}
          nonRankKpiList={nonRankKpiList.filter((item) => item.type !== KpiTypeEnum.CATEGORY)}
          initialFormState={getInitialHighlightFormState()}
          hasError={errorState.mandatory.highlights}
          onChange={(newHighlights) =>
            formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'highlights', value: newHighlights } })
          }
          onDismissErrorHandler={onDismissErrorHandler}
        />
      );
    }
  }, [isDisabled, incentiveDetail, kpiList, nonRankKpiList, errorState.mandatory.highlights]);

  const awardSection = useMemo(() => {
    const initialAwardFormState: AwardFormState = {
      awards: formState.awards,
      editableTiers: formState.awards.map((award) => ({
        editable: false,
        tierCode: award.tierCode,
        name: award.name,
        error: false,
      })),
    };
    if (kpiList.length !== 0) {
      return (
        <AwardSection
          isDisabled={isDisabled}
          enableKpiShowHide={enableKpiShowHide}
          nonRankKpiList={nonRankKpiList}
          categoryKpiList={categoryKpiList}
          initialFormState={initialAwardFormState}
          hasError={errorState.mandatory.awards}
          onChange={(newAwards) =>
            formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'awards', value: newAwards } })
          }
          onDismissErrorHandler={onDismissErrorHandler}
        />
      );
    }
  }, [isDisabled, incentiveDetail, nonRankKpiList, categoryKpiList, errorState.mandatory.awards]);

  const handleImageFile = async (e: React.ChangeEvent<HTMLInputElement>, locale: string) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.type.includes('image')) {
        const fileSize = file.size / 1024 / 1024;
        const errMsg = Translation('global.max.file.size.allow');
        if (fileSize > 2) {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: '',
                content: errMsg,
              },
            ]),
          );
          return;
        }

        try {
          const createBlobRes = await createBlob(
            { mimeType: file.type, accessLevel: 'public', module: 'incentive' },
            dispatch,
          );
          await fileUpload(createBlobRes.url, file, (value) => {
            setUploadImageProgress({ ...uploadImageProgress, [locale]: value });
          });
          const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
          const result = blobDetail[0];
          if (result) {
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: {
                field: 'incentiveImage',
                value: {
                  ...formState.incentiveImage,
                  [locale]: { blobId: result.blobId, filename: file.name, url: result.url },
                },
              },
            });
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: `Upload Image successfully - ${file.name}`,
                },
              ]),
            );
          }
        } catch (err) {
        } finally {
          setUploadImageProgress({ ...uploadImageProgress, [locale]: undefined });
        }
      } else {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: '',
              content: 'Please upload an image',
            },
          ]),
        );
      }
    }
  };

  const removeImageFile = (locale: string) => {
    formDispatch({
      type: 'MODIFY_FIELD',
      payload: { field: 'incentiveImage', value: { ...formState.incentiveImage, [locale]: undefined } },
    });
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: `Remove image file successfully`,
        },
      ]),
    );
  };

  const onUnpublish = async () => {
    if (code) {
      try {
        await unpublishIncentive(code, dispatch);
        window.location.reload();
      } catch (err) {}
    }
  };

  const onChangeReceiverFile = (result: any) => {
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'receiverUserFile', value: result } });
  };

  const handleUploadFileGroupOfUser = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        { mimeType: fileType, accessLevel: 'anonymous', module: 'incentive' },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        let dataFile: any = {
          id: result.blobId,
          fileName: fileName,
          url: result.url,
          type: fileType,
        };

        if (formState.templateType === EIncentiveTemplateType.CUSTOMIZE) {
          const importContent = await uploadExcelImportContent({ file, inclusive: true });
          dataFile['importContentId'] = importContent.id;
        }

        return dataFile;
      }
    } catch (err) {
    } finally {
    }
  };

  const onSubmitForm = async (mode: PublishStatusEnum, updateSet?: boolean) => {
    formState.name.en = formState.name.vi;
    formState.award.en = formState.award.vi;
    if (formState?.highlights) {
      formState.highlights.map((item) => {
        if (item.rankDescription) {
          item.rankDescription.en = item.rankDescription.vi;
        }
      });
    }

    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      const isPublishNow = mode === PublishStatusEnum.PUBLISHED; //CEO code && !formState.publishDate;
      const fromUpdateSetDialog = typeof updateSet === 'boolean';
      const details: CreateUpdateIncentiveBody = {
        type: IncentiveTypeEnum.INCENTIVE,
        code: formState.code ? formState.code.trim() : '',
        name: formState.name,
        incentiveSetType: formState.incentiveSetType,
        startDate: formState.startDate ? getDayStart(formState.startDate) : null,
        endDate: formState.endDate ? getDayEnd(formState.endDate) : null,
        detail: formState.detail,
        objective: formState.objective,
        bulletin: formState.bulletin,
        award: formState.award,
        incentiveImage: formState.incentiveImage,
        bulletinFile: formState.bulletinFile,
        highlights: formState.highlights,
        awards: formState.awards,
        stickOnTop: formState.stickOnTop,
        publishDate: formState.publishDate,
        archiveDate: formState.archiveDate ? getDayEnd(formState.archiveDate) : null,
        targetApplicantType: formState.targetApplicantType,
        eligibleDesignations: formState.eligibleDesignations,
        eligibleSegments: formState.eligibleSegments,
        eligibleAgents: preFillZeroList(formState.eligibleAgents),
        excludedAgents: preFillZeroList(formState.excludedAgents),
        agentListFile: formState.agentListFile,
        updateSet: updateSet ? true : false,
        //new field
        template: {
          type: formState.templateType,
          bodyImg: formState.templateBodyImg,
          headerImg: formState.templateHeaderImg,
          footerImg: formState.templateFooterImg,
          body: formState.templateBody,
        },
        registerLimit: {
          isLimited: formState.isLimitRegister,
          limitNumber: formState.limitNumber,
        },
        registrationRequired: formState.registrationRequired,
        notification: {
          type: formState.notificationType,
          msgTitle: '',
          msgContent: '',
          pushContent: '',
          pushTitle: '',
          category: '',
        },
        receivers: formState.receivers,
        startEnrollmentDate: formState.startEnrollmentDate ? getDayEnd(formState.startEnrollmentDate) : null,
        endEnrollmentDate: formState.endEnrollmentDate ? getDayEnd(formState.endEnrollmentDate) : null,
        receiverUserFile: formState.receiverUserFile,
        status: formState.status,
        includeSA: formState.includeSA,
      };
      if (isPublishNow) {
        details.status = PublishStatusEnum.PUBLISHED;
      }
      console.log(details);
      try {
        if (formMode === IncentiveFormMode.CREATE) {
          await createNewIncentive(details, dispatch);
          if (isPublishNow) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: `Record saved and published successfully - ${details.code}`,
                },
              ]),
            );
          } else {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: 'Success',
                  content: `Record saved successfully - ${details.code}`,
                },
              ]),
            );
          }
          onRouteTo(incentivePath);
        } else if (code && formMode === IncentiveFormMode.EDIT) {
          if (incentiveDetail?.setCode && !fromUpdateSetDialog) {
            setUpdateSetDialogState({ open: true, mode });
          } else {
            await modifyIncentive(code, details, dispatch);
            if (isPublishNow) {
              dispatch(
                appendAlertItem([
                  {
                    severity: AlertType.SUCCESS,
                    title: 'Success',
                    content: `Record updated and published successfully - ${code}`,
                  },
                ]),
              );
            } else {
              dispatch(
                appendAlertItem([
                  {
                    severity: AlertType.SUCCESS,
                    title: 'Success',
                    content: `Record updated successfully - ${code}`,
                  },
                ]),
              );
            }
            if (!fromUpdateSetDialog) {
              onRouteTo(incentivePath);
            }
          }
        }
      } catch (err) {
        if (fromUpdateSetDialog) {
          throw new Error('Update Failed');
        }
      }
    }
  };

  const onUpdate = async (updateSet: boolean) => {
    if (updateSetDialogState.mode) {
      try {
        await onSubmitForm(updateSetDialogState.mode, updateSet);
        if (updateSet) {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Set's duration, publish date and archive date are updated.`,
              },
            ]),
          );
        }
        setUpdateSetDialogState({ open: false });
        onRouteTo(incentivePath);
      } catch (err) {
        setUpdateSetDialogState({ open: false });
      }
    }
  };

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        { mimeType: fileType, accessLevel: 'anonymous', module: 'incentive' },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        return { id: result.blobId, fileName: fileName, url: result.url, type: fileType };
      }
    } catch (err) {
    } finally {
    }
  };

  const onChangeBodyImageFile = (result: any) => {
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'templateBodyImg', value: result } });
  };
  const onChangeHeaderImageFile = (result: any) => {
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'templateHeaderImg', value: result } });
  };
  const onChangeFooterImageFile = (result: any) => {
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'templateFooterImg', value: result } });
  };

  return (
    <>
      {incentiveDetail?.setCode && (
        <UpdateSetDialog
          open={updateSetDialogState.open}
          setCode={incentiveDetail.setCode}
          onClose={() => setUpdateSetDialogState({ open: false })}
          onUpdate={onUpdate}
        />
      )}
      <div>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>
              {formMode === IncentiveFormMode.CREATE ? 'Add' : isDisabled ? 'View' : 'Edit'} Incentive
            </div>
          </div>
          <Button variant="contained" color="inherit" onClick={() => onRouteTo(incentivePath)}>
            {Translation('app.button.back')}
          </Button>
        </div>
        {/* Basic Information */}
        <div className={classes.section}>
          <div className={classes.subHeaderContainer}>
            <span className={classes.subHeader}>{Translation('incentive.common.basic_information')}</span>
          </div>
          <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('incentive.common.code')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={isDisabled || formMode === IncentiveFormMode.EDIT}
                error={errorState.mandatory.code}
                className={`${classes.textField} ${classes.noMargin}`}
                margin="dense"
                variant="outlined"
                fullWidth
                helperText={errorState.mandatory.code && MANDATORY_FIELD_ERROR_TEXT}
                value={formState.code || ''}
                onChange={(e) => {
                  const onlyAlphNums = e.target.value.replace(/[^A-Za-z0-9]/g, '');
                  onDismissErrorHandler('code', onlyAlphNums);
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'code', value: onlyAlphNums } });
                }}
              />
            </div>
          </div>
          {/* {regionLocale.map((locale) => ( */}
          <div key={`incentive-name-${locale}`} className={`${classes.rowContainer} ${classes.rowSpacing}`}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation(`incentive.common.name`)}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={isDisabled}
                error={errorState.mandatory[`name-${locale}`]}
                className={classes.noMargin}
                margin="dense"
                variant="outlined"
                fullWidth
                helperText={errorState.mandatory[`name-${locale}`] && MANDATORY_FIELD_ERROR_TEXT}
                value={formState.name[locale] || ''}
                onChange={(e) => {
                  onDismissErrorHandler(`name-${locale}`, e.target.value);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'name', value: { ...formState.name, [locale]: e.target.value } },
                  });
                }}
              />
            </div>
          </div>
          {/* ))} */}
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('incentive.common.duration')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }} className={classes.rowContainer}>
              <PruDatePicker
                className={classes.datePicker}
                disabled={isDisabled}
                slotProps={{
                  textField: {
                    error:
                      errorState.immediate.startDate ||
                      errorState.immediate.endDateBeforeStartDate ||
                      errorState.mandatory.startDate,
                    helperText:
                      (errorState.immediate.startDate && DATE_FORMAT_ERROR_TEXT) ||
                      (errorState.mandatory.startDate && MANDATORY_FIELD_ERROR_TEXT),
                  },
                }}
                format="DD/MM/YYYY"
                value={formState.startDate}
                onChange={(date) => {
                  onDismissErrorHandler('startDate', date);
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'startDate', value: date } });
                }}
              />
              <div className={classes.dateDivider}>-</div>
              <PruDatePicker
                className={classes.datePicker}
                disabled={isDisabled}
                slotProps={{
                  textField: {
                    error:
                      errorState.immediate.endDate ||
                      errorState.immediate.endDateBeforeStartDate ||
                      errorState.mandatory.endDate,
                    helperText:
                      (errorState.immediate.endDate && DATE_FORMAT_ERROR_TEXT) ||
                      (errorState.immediate.endDateBeforeStartDate && DATE_ERROR_TEXT) ||
                      (errorState.mandatory.endDate && MANDATORY_FIELD_ERROR_TEXT),
                  },
                }}
                format="DD/MM/YYYY"
                value={formState.endDate}
                onChange={(date) => {
                  const dismissList = ['endDate'];
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'endDate', value: date } });
                  if (moment(date).isValid() && !formState.archiveDate) {
                    dismissList.push('archiveDate');
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'archiveDate', value: moment(date).add(90, 'days').toDate() },
                    });
                  }
                  onDismissErrorHandler(dismissList, date);
                }}
              />
            </div>
          </div>
        </div>

        {/* Incentive Details */}
        <div className={classes.section}>
          {/* {regionLocale.map((locale, index) => ( */}
          <div
            key={`incentive-detail-${locale}`}
            // className={`${index !== regionLocale.length - 1 ? classes.detailSpacing : undefined}`}
          >
            <div className={classes.detailTextContainer}>
              <div className={classes.subHeaderContainer}>
                <div className={classes.subHeader}>{Translation(`incentive.common.incentive_details`)}</div>
                {/* {index > 0 && (
                    <div className={classes.fillInContainer}>
                      <FormControlLabel
                        className={classes.noMargin}
                        disabled={isDisabled}
                        control={
                          <Checkbox
                            onChange={(e) => {
                              if (e.target.checked) {
                                onDismissErrorHandler(`detail-${locale}`, true);
                                formDispatch({
                                  type: 'FILL_IN_DETAIL',
                                  payload: { fromLocale: regionLocale[0], toLocale: locale },
                                });
                              }
                            }}
                          />
                        }
                        label={Translation('incentive.common.use_lang_to_fill_in', {
                          lang: Translation(`navBar.lang.${regionLocale[0]}`),
                        })}
                        labelPlacement="end"
                      />
                    </div>
                  )} */}
              </div>
              {/* Incentive Type */}
              <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('incentive.set.common.type')} :</span>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <FormControlLabel
                    className={classes.noMargin}
                    control={
                      <Checkbox
                        className={classes.checkboxPadding}
                        disabled={isDisabled}
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<CheckCircle />}
                        checked={
                          formState.incentiveSetType.toUpperCase() == IncentiveSetTypeEnum.INCENTIVE.toUpperCase()
                        }
                        onChange={() =>
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: { field: 'incentiveSetType', value: IncentiveSetTypeEnum.INCENTIVE.toUpperCase() },
                          })
                        }
                      />
                    }
                    label={IncentiveSetTypeEnum.INCENTIVE.toString()}
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    className={`${classes.noMargin} ${classes.radioButtonSpacing}`}
                    control={
                      <Checkbox
                        className={classes.checkboxPadding}
                        disabled={isDisabled}
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<CheckCircle />}
                        checked={
                          formState.incentiveSetType.toUpperCase() == IncentiveSetTypeEnum.COMPENSATION.toUpperCase()
                        }
                        onChange={() =>
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: {
                              field: 'incentiveSetType',
                              value: IncentiveSetTypeEnum.COMPENSATION,
                            },
                          })
                        }
                      />
                    }
                    label={IncentiveSetTypeEnum.COMPENSATION.toString()}
                    labelPlacement="end"
                  />
                </div>
              </div>
              {/* Registration Required */}
              <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>
                    {Translation('incentive.form.formField.registrationRequired')} :
                  </span>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <FormControlLabel
                    className={classes.noMargin}
                    control={
                      <Checkbox
                        className={classes.checkboxPadding}
                        disabled={isDisabled}
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<CheckCircle />}
                        checked={formState.registrationRequired}
                        onChange={(e) =>
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: { field: 'registrationRequired', value: e.target.checked },
                          })
                        }
                      />
                    }
                    label="Yes"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    className={`${classes.noMargin} ${classes.radioButtonSpacing}`}
                    control={
                      <Checkbox
                        className={classes.checkboxPadding}
                        disabled={isDisabled}
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<CheckCircle />}
                        checked={!formState.registrationRequired}
                        onChange={(e) =>
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: { field: 'registrationRequired', value: !e.target.checked },
                          })
                        }
                      />
                    }
                    label="No"
                    labelPlacement="end"
                  />
                </div>
              </div>
              {/* Enrollment Date */}
              {formState.registrationRequired ? (
                <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}>
                      {Translation('incentive.common.enrollmentDate')}
                      <span className={classes.mandatory}>*</span> :
                    </span>
                  </div>
                  <div style={{ flexGrow: 1 }} className={classes.rowContainer}>
                    <PruDatePicker
                      className={classes.datePicker}
                      disabled={isDisabled}
                      slotProps={{
                        textField: {
                          error:
                            errorState.immediate.startEnrollmentDate ||
                            errorState.immediate.endDateBeforeStartDate ||
                            errorState.mandatory.startEnrollmentDate,
                          helperText:
                            (errorState.immediate.startEnrollmentDate && DATE_FORMAT_ERROR_TEXT) ||
                            (errorState.mandatory.startEnrollmentDate && MANDATORY_FIELD_ERROR_TEXT),
                        },
                      }}
                      format="DD/MM/YYYY"
                      value={formState.startEnrollmentDate}
                      onChange={(date) => {
                        onDismissErrorHandler('startEnrollmentDate', date);
                        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'startEnrollmentDate', value: date } });
                      }}
                    />
                    <div className={classes.dateDivider}>-</div>
                    <PruDatePicker
                      className={classes.datePicker}
                      disabled={isDisabled}
                      slotProps={{
                        textField: {
                          error:
                            errorState.immediate.endEnrollmentDate ||
                            errorState.immediate.endDateBeforeStartDate ||
                            errorState.mandatory.endEnrollmentDate,
                          helperText:
                            (errorState.immediate.endEnrollmentDate && DATE_FORMAT_ERROR_TEXT) ||
                            (errorState.immediate.endDateBeforeStartDate && DATE_ERROR_TEXT) ||
                            (errorState.mandatory.endEnrollmentDate && MANDATORY_FIELD_ERROR_TEXT),
                        },
                      }}
                      format="DD/MM/YYYY"
                      value={formState.endEnrollmentDate}
                      onChange={(date) => {
                        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'endEnrollmentDate', value: date } });
                        if (moment(date).isValid() && !formState.endEnrollmentDate) {
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: { field: 'endEnrollmentDate', value: date },
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              ) : null}
              {/* Select Template */}
              <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('incentive.form.formField.selectTemplate')} :</span>
                </div>
                <TextField
                  disabled={isDisabled}
                  select
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  placeholder="Template type"
                  value={formState.templateType}
                  onChange={(e) =>
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'templateType', value: e.target.value } })
                  }
                >
                  {templateTypeOptions.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {Translation(item.label)}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {/* Incentive Body */}
              <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}></span>
                </div>
                <div className={classes.childContent}>
                  {formState.templateType === EIncentiveTemplateType.FIXED ? (
                    <div className={classes.rowContainer}>
                      <div className={classes.fieldContainer}>
                        <span className={classes.field}>
                          {Translation('incentive.form.formField.incentiveBody')}
                          <span className={classes.mandatory}>*</span> :
                        </span>
                        {errorState.mandatory.templateBodyImg && (
                          <div className={classes.errorText}>{Translation('field_mandatory_helper_label')}</div>
                        )}
                      </div>
                      <FileUploader
                        disabled={isDisabled}
                        upload={handleUploadFile}
                        color="secondary"
                        maxFileSize={2}
                        showAllowText={Translation('incentive.form.formField.descriptionImageUploader')}
                        allowedFileTypes={['jpg', 'png', 'jpeg', 'pdf']}
                        onChange={onChangeBodyImageFile}
                        value={formState.templateBodyImg}
                      />
                    </div>
                  ) : formState.templateType === EIncentiveTemplateType.CUSTOMIZE ? (
                    <>
                      <div className={classes.rowContainer} style={{ marginBottom: '10px' }}>
                        <div className={classes.fieldContainer}>
                          <span className={classes.field}>{Translation('incentive.form.formField.header')} :</span>
                        </div>
                        <FileUploader
                          disabled={isDisabled}
                          upload={handleUploadFile}
                          color="secondary"
                          maxFileSize={2}
                          showAllowText={Translation('incentive.form.formField.descriptionImageUploader')}
                          allowedFileTypes={['jpg', 'png', 'jpeg', 'pdf']}
                          onChange={onChangeHeaderImageFile}
                          value={formState.templateHeaderImg}
                        />
                      </div>
                      <div className={classes.rowContainer} style={{ marginBottom: '10px' }}>
                        <div className={classes.fieldContainer}>
                          <span className={classes.field}>{Translation('incentive.form.formField.footer')} :</span>
                        </div>
                        <FileUploader
                          disabled={isDisabled}
                          upload={handleUploadFile}
                          color="secondary"
                          maxFileSize={2}
                          showAllowText={Translation('incentive.form.formField.descriptionImageUploader')}
                          allowedFileTypes={['jpg', 'png', 'jpeg', 'pdf']}
                          onChange={onChangeFooterImageFile}
                          value={formState.templateFooterImg}
                        />
                      </div>
                      <div className={classes.rowContainer}>
                        <div className={classes.fieldContainer}>
                          <span className={classes.field}>
                            {Translation('incentive.form.formField.body')}
                            <span className={classes.mandatory}>*</span> :
                          </span>
                          {errorState.mandatory.templateBody && (
                            <div className={classes.errorText}>{Translation('field_mandatory_helper_label')}</div>
                          )}
                        </div>
                        <Tinymce
                          disabled={isDisabled}
                          value={formState.templateBody}
                          module={'incentive'}
                          accessLevel={'anonymous'}
                          onChange={(value: string) =>
                            formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'templateBody', value: value } })
                          }
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              {/* Register Limit */}
              {formState.registrationRequired ? (
                <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}>{Translation('incentive.form.formField.registerLimit')} :</span>
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <FormControlLabel
                      className={classes.noMargin}
                      control={
                        <Checkbox
                          className={classes.checkboxPadding}
                          disabled={isDisabled}
                          icon={<RadioButtonUnchecked />}
                          checkedIcon={<CheckCircle />}
                          checked={formState.isLimitRegister}
                          onChange={(e) =>
                            formDispatch({
                              type: 'MODIFY_FIELD',
                              payload: { field: 'isLimitRegister', value: e.target.checked },
                            })
                          }
                        />
                      }
                      label="Yes"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      className={`${classes.noMargin} ${classes.radioButtonSpacing}`}
                      control={
                        <Checkbox
                          className={classes.checkboxPadding}
                          disabled={isDisabled}
                          icon={<RadioButtonUnchecked />}
                          checkedIcon={<CheckCircle />}
                          checked={!formState.isLimitRegister}
                          onChange={(e) =>
                            formDispatch({
                              type: 'MODIFY_FIELD',
                              payload: { field: 'isLimitRegister', value: !e.target.checked },
                            })
                          }
                        />
                      }
                      label="No"
                      labelPlacement="end"
                    />
                  </div>
                </div>
              ) : null}
              {formState.registrationRequired && formState.isLimitRegister ? (
                <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}></span>
                  </div>
                  <div className={classes.childContent}>
                    <div className={classes.rowContainer}>
                      <div className={classes.fieldContainer}>
                        <span className={classes.field}>
                          {Translation('incentive.form.formField.registerLimit')}
                          <span className={classes.mandatory}>*</span> :
                        </span>
                      </div>
                      <TextField
                        disabled={isDisabled}
                        error={errorState.mandatory.limitNumber}
                        className={classes.noMargin}
                        margin="dense"
                        variant="outlined"
                        type="number"
                        helperText={errorState.mandatory.limitNumber && MANDATORY_FIELD_ERROR_TEXT}
                        value={formState.limitNumber}
                        onChange={(e) => {
                          const onlyNums = e.target.value.replace(/[^0-9]/g, '');
                          onDismissErrorHandler('limitNumber', e.target.value);
                          formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'limitNumber', value: onlyNums } });
                        }}
                        inputProps={{ min: 1 }}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              {/* <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>
                    {Translation('incentive.common.description')}
                    <span className={classes.mandatory}>*</span> :
                  </span>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <TextField
                    disabled={isDisabled}
                    InputProps={{
                      classes: {
                        input: classes.textArea,
                      },
                    }}
                    error={errorState.mandatory[`detail-${locale}`]}
                    className={classes.noMargin}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    multiline
                    helperText={errorState.mandatory[`detail-${locale}`] && MANDATORY_FIELD_ERROR_TEXT}
                    value={formState.detail[locale] || ''}
                    onChange={(e) => {
                      onDismissErrorHandler(`detail-${locale}`, e.target.value);
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: { field: 'detail', value: { ...formState.detail, [locale]: e.target.value } },
                      });
                    }}
                  />
                </div>
              </div>
              <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('incentive.common.objective')} :</span>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <TextField
                    disabled={isDisabled}
                    InputProps={{
                      classes: {
                        input: classes.textArea,
                      },
                    }}
                    className={classes.noMargin}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    multiline
                    value={formState.objective[locale] || ''}
                    onChange={(e) =>
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: { field: 'objective', value: { ...formState.objective, [locale]: e.target.value } },
                      })
                    }
                  />
                </div>
              </div>
              <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('incentive.common.bulletin')} :</span>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <TextField
                    disabled={isDisabled}
                    InputProps={{
                      classes: {
                        input: classes.textArea,
                      },
                    }}
                    className={classes.noMargin}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    multiline
                    value={formState.bulletin[locale] || ''}
                    onChange={(e) =>
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: { field: 'bulletin', value: { ...formState.bulletin, [locale]: e.target.value } },
                      })
                    }
                  />
                </div>
              </div> */}
              <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>
                    {Translation('incentive.common.award_description')}
                    <Tooltip title={Translation('incentive.common.award_description_tooltip')}>
                      <IconButton size={'small'} style={{ padding: 5, color: '#BBBBBB' }}>
                        <Info />
                      </IconButton>
                    </Tooltip>
                    :
                  </span>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <TextField
                    disabled={isDisabled}
                    inputProps={{ maxLength: 30 }}
                    // InputProps={{
                    //   classes: {
                    //     input: classes.textArea,
                    //   },
                    // }}
                    className={classes.noMargin}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    multiline
                    value={formState.award[locale] || ''}
                    onChange={(e) =>
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: { field: 'award', value: { ...formState.award, [locale]: e.target.value } },
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div>
              <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('incentive.common.upload_image')} :</span>
                </div>
                <div style={{ flexGrow: 1 }} className={classes.rowContainer}>
                  <input
                    id={`upload-image-${locale}`}
                    hidden
                    type="file"
                    accept="image/*"
                    disabled={!!uploadImageProgress[locale]}
                    onClick={(e) => {
                      const element = e.target as HTMLInputElement;
                      element.value = '';
                    }}
                    onChange={(e) => handleImageFile(e, locale)}
                  />
                  <Button
                    disabled={isDisabled}
                    variant="contained"
                    color="secondary"
                    onClick={() => document.getElementById(`upload-image-${locale}`)?.click()}
                  >
                    {Translation('app.button.chooseFile')}
                  </Button>
                  {formState.incentiveImage[locale]?.url ? (
                    <Button
                      className={classes.chooseFileSpacing}
                      disabled={isDisabled}
                      variant="contained"
                      color="secondary"
                      onClick={() => removeImageFile(locale)}
                    >
                      {Translation('app.button.removeFile')}
                    </Button>
                  ) : (
                    <div className={classes.chooseFileSpacing}>{Translation('common.image_upload_recommendation')}</div>
                  )}
                </div>
              </div>
              {uploadImageProgress[locale] && (
                <div className={`${classes.valueOnlyContainer} ${classes.progressSpacing}`}>
                  <LinearProgress variant="determinate" value={uploadImageProgress[locale]} />
                </div>
              )}
              {formState.incentiveImage[locale]?.url && (
                <div className={`${classes.valueOnlyContainer} ${classes.rowSpacing}`}>
                  <div className={classes.rowSpacing}>
                    <div>{formState.incentiveImage[locale]?.filename || ''}</div>
                  </div>
                  <div>
                    <img
                      style={{ width: 'auto', height: '150px' }}
                      src={formState.incentiveImage[locale]?.url}
                      alt="Incentive"
                    />
                  </div>
                </div>
              )}

              {/* <div className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation('incentive.common.upload_bulletin')} :</span>
                </div>
                <div style={{ flexGrow: 1 }} className={classes.rowContainer}>
                  <input
                    id={`upload-bulletin-${locale}`}
                    hidden
                    type="file"
                    accept=".pdf"
                    disabled={!!uploadBulletinProgress[locale]}
                    onClick={(e) => {
                      const element = e.target as HTMLInputElement;
                      element.value = '';
                    }}
                    onChange={(e) => handleBulletinFile(e, locale)}
                  />
                  <Button
                    disabled={isDisabled}
                    variant="contained"
                    color="secondary"
                    onClick={() => document.getElementById(`upload-bulletin-${locale}`)?.click()}
                  >
                    {Translation('app.button.chooseFile')}
                  </Button>
                  <div className={classes.chooseFileSpacing}>
                    {formState.bulletinFile[locale]?.filename || Translation('app.file.noFileChosen')}
                  </div>
                </div>
              </div>
              {uploadBulletinProgress[locale] && (
                <div className={`${classes.valueOnlyContainer} ${classes.progressSpacing}`}>
                  <LinearProgress variant="determinate" value={uploadBulletinProgress[locale]} />
                </div>
              )} */}
            </div>
          </div>
          {/* ))} */}
        </div>

        {/* Incentive Settings */}
        <div className={classes.section}>
          <div className={classes.subHeaderContainer}>
            <span className={classes.subHeader}>{Translation('incentive.common.incentive_setting')}</span>
          </div>
          <div className={classes.multiRowContainer}>
            <div className={`${classes.fieldContainer} ${classes.fieldPadding}`}>
              <span className={classes.field}>{Translation('incentive.common.highlights')} :</span>
            </div>
            <div style={{ flexGrow: 1 }}>{highlightSection}</div>
          </div>
        </div>

        {/* Award Settings */}
        <div className={classes.section}>
          <div className={classes.subHeaderContainer}>
            <span className={classes.subHeader}>{Translation('incentive.common.award_setting')}</span>
          </div>
          <div className={classes.multiRowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('incentive.common.tier_setting')} :</span>
            </div>
            <div style={{ flexGrow: 1, maxWidth: `calc(100% - ${FIELD_CONTAINER_WIDTH}px)` }}>{awardSection}</div>
          </div>
          {enableAgentTarget && code && (
            <div className={classes.multiRowContainer}>
              <div className={`${classes.fieldContainer} ${classes.fieldPadding}`}>
                <span className={classes.field}>{Translation('incentive.common.kpi_view')} :</span>
              </div>
              <div style={{ flexGrow: 1 }}>
                <AsyncCsvLink
                  isDisabled={false}
                  filename={`${code || ''}-agent-target.csv`}
                  dataParser={(str) => str}
                  asyncCall={() => fetchIncentiveAgentTarget(code, dispatch)}
                >
                  <Button variant="contained" color="secondary">
                    {Translation('incentive.common.agent_target')}
                  </Button>
                </AsyncCsvLink>
              </div>
            </div>
          )}
        </div>

        {/* Notification Settings */}
        <div className={classes.section}>
          <div className={classes.subHeaderContainer}>
            <span className={classes.subHeader}>{Translation('incentive.form.title.notificationSettings')}</span>
          </div>
          <div className={classes.multiRowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('component.formLabel.notification-type')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <FormControl error disabled={isDisabled}>
              <RadioGroup
                aria-label="notificationType"
                name="notificationType"
                value={formState.notificationType}
                onChange={(e) =>
                  formDispatch({
                    type: 'SWITCH_NOTIFICATION_TYPE',
                    payload: { value: e.target.value as ENotificationType },
                  })
                }
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                {Object.entries(ENotificationType).map(([value, label]) => (
                  <FormControlLabel
                    style={{ marginBottom: 0 }}
                    key={value}
                    value={value}
                    control={<Radio />}
                    label={Translation(`incentive.form.formField.notification.${label}`)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        {/* Publish Settings */}
        <div className={classes.section}>
          <div className={classes.subHeaderContainer}>
            <span className={classes.subHeader}>{Translation('incentive.common.publish_settings')}</span>
          </div>
          <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('incentive.common.stick_on_top')} :</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <FormControlLabel
                className={classes.noMargin}
                control={
                  <Checkbox
                    className={classes.checkboxPadding}
                    disabled={isDisabled}
                    icon={<RadioButtonUnchecked />}
                    checkedIcon={<CheckCircle />}
                    checked={formState.stickOnTop}
                    onChange={(e) =>
                      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'stickOnTop', value: e.target.checked } })
                    }
                  />
                }
                label="Yes"
                labelPlacement="end"
              />
              <FormControlLabel
                className={`${classes.noMargin} ${classes.radioButtonSpacing}`}
                control={
                  <Checkbox
                    className={classes.checkboxPadding}
                    disabled={isDisabled}
                    icon={<RadioButtonUnchecked />}
                    checkedIcon={<CheckCircle />}
                    checked={!formState.stickOnTop}
                    onChange={(e) =>
                      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'stickOnTop', value: !e.target.checked } })
                    }
                  />
                }
                label="No"
                labelPlacement="end"
              />
            </div>
          </div>
          <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('incentive.common.publish_date')} <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <PruDateTimePicker
                className={classes.datePicker}
                ampm={false}
                disabled={isDisabled}
                slotProps={{
                  textField: {
                    error: errorState.immediate.publishDate,
                    helperText: errorState.immediate.publishDate && DATE_FORMAT_ERROR_TEXT,
                  },
                }}
                format="DD/MM/YYYY HH:mm"
                disablePast
                value={formState.publishDate}
                onChange={(date) => {
                  const now = new Date();
                  if (moment(date).isBefore(now)) {
                    date = now;
                  }
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'publishDate', value: date } });
                }}
              />
            </div>
          </div>
          <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('incentive.common.archive_date')} <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <PruDatePicker
                className={classes.datePicker}
                disabled={isDisabled}
                slotProps={{
                  textField: {
                    error:
                      errorState.immediate.archiveDate ||
                      errorState.immediate.archiveDateBeforeEndDate ||
                      errorState.mandatory.archiveDate,
                    helperText:
                      (errorState.immediate.archiveDate && DATE_FORMAT_ERROR_TEXT) ||
                      (errorState.immediate.archiveDateBeforeEndDate &&
                        Translation('incentive.error.archive_date_before_end_date')) ||
                      (errorState.mandatory.archiveDate && MANDATORY_FIELD_ERROR_TEXT),
                  },
                }}
                format="DD/MM/YYYY"
                value={formState.archiveDate}
                onChange={(date) => {
                  onDismissErrorHandler('archiveDate', date);
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'archiveDate', value: date } });
                }}
              />
            </div>
          </div>
          {/* <div className={classes.multiRowContainer}>
            <div className={`${classes.fieldContainer} ${classes.fieldPadding}`}>
              <span className={classes.field}>{Translation('incentive.common.target_applicants')} :</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TargetApplicantSectionV2
                module="incentive"
                mode={TargetApplicantMode.SELECT}
                isDisabled={isDisabled}
                agentSegmentListParams={{ enableInIncentives: true, pagination: false }}
                initialFormState={{
                  targetApplicantType: formState.targetApplicantType,
                  eligibleDesignations: formState.eligibleDesignations,
                  eligibleSegments: formState.eligibleSegments,
                  eligibleAgents: formState.eligibleAgents,
                  excludedAgents: formState.excludedAgents,
                  agentListFile: formState.agentListFile,
                }}
                onChange={(newFormState) => formDispatch({ type: 'SET_FORM_STATE', payload: { value: newFormState } })}
              />
            </div>
          </div> */}
          <div className={`${classes.rowContainer} ${classes.rowSpacing}`}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('component.formLabel.receiver')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <FormControl error>
                <CheckGroup
                  disabled={isDisabled}
                  options={[
                    {
                      label: Translation(`component.formSelectItem.viewType.${InPublishSettingReceiver.ALL_AGENT}`),
                      value: InPublishSettingReceiver.ALL_AGENT,
                    },
                    {
                      label: Translation(`component.formSelectItem.viewType.${InPublishSettingReceiver.AGENT_LEADERS}`),
                      value: InPublishSettingReceiver.AGENT_LEADERS,
                    },
                    {
                      label: Translation(
                        `component.formSelectItem.viewType.${InPublishSettingReceiver.PRUVENTURE_LEADERS}`,
                      ),
                      value: InPublishSettingReceiver.PRUVENTURE_LEADERS,
                    },
                    {
                      label: Translation(`component.formSelectItem.viewType.${InPublishSettingReceiver.PRUVENTURE}`),
                      value: InPublishSettingReceiver.PRUVENTURE,
                    },
                    {
                      label: Translation(
                        `component.formSelectItem.viewType.${InPublishSettingReceiver.GROUP_OF_USERS}`,
                      ),
                      value: InPublishSettingReceiver.GROUP_OF_USERS,
                    },
                    // {
                    //   label: Translation(`component.formSelectItem.viewType.${InPublishSettingReceiver.INCLUDE_SA}`),
                    //   value: InPublishSettingReceiver.INCLUDE_SA,
                    // },
                  ]}
                  value={formState.receivers}
                  onChange={(e) => {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'receivers', value: e },
                    });
                  }}
                />
                {errorState.mandatory.receivers && (
                  <div className={classes.errorText}>{Translation('field_mandatory_helper_label')}</div>
                )}
              </FormControl>
              {formState.receivers.indexOf(InPublishSettingReceiver.ALL_AGENT) > -1 ? (
                <div style={{ width: 'auto' }}>
                  <FormControlLabel
                    style={{ flexBasis: '20%' }}
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={formState.includeSA}
                        onChange={(e) => {
                          formDispatch({
                            type: 'MODIFY_FIELD',
                            payload: { field: 'includeSA', value: e.target.checked },
                          });
                          if (e.target.checked) {
                          }
                        }}
                      />
                    }
                    label={Translation(`component.formSelectItem.viewType.${InPublishSettingReceiver.INCLUDE_SA}`)}
                    labelPlacement="end"
                  />
                  {errorState.mandatory.receivers && (
                    <div className={classes.errorText}>{Translation('field_mandatory_helper_label')}</div>
                  )}
                </div>
              ) : null}
              {formState.receivers.indexOf(InPublishSettingReceiver.GROUP_OF_USERS) > -1 ? (
                <div style={{ width: 'auto' }}>
                  <FileUploader
                    disabled={isDisabled}
                    upload={handleUploadFileGroupOfUser}
                    showAllowText={Translation('component.form.users-file-description')}
                    maxFileSize={10}
                    allowedFileTypes={[
                      // 'csv',
                      'xlsx',
                      'xls',
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                      'application/vnd.ms-excel',
                    ]}
                    onChange={onChangeReceiverFile}
                    value={formState.receiverUserFile}
                  />
                  {!formState.receiverUserFile ? (
                    <div style={{ marginTop: '10px' }}>
                      {formState.templateType === EIncentiveTemplateType.CUSTOMIZE ? (
                        <a href={toAbsoluteUrl('/templates/Template_Group_Of_User_Variable.xlsx')}>
                          {Translation('recruitment.form.downloadTemplate')}
                        </a>
                      ) : (
                        <a href={toAbsoluteUrl('/templates/Template_Group_Of_User.xlsx')}>
                          {Translation('recruitment.form.downloadTemplate')}
                        </a>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                  {errorState.mandatory.receiverUserFile && (
                    <div className={classes.errorText}>{Translation('field_mandatory_helper_label')}</div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <div className={classes.footerContainer}>
            {isDisabled ? (
              <Button variant="contained" color="secondary" onClick={onUnpublish}>
                {Translation('app.button.unpublish')}
              </Button>
            ) : (
              <>
                <Button variant="contained" color="secondary" onClick={() => onSubmitForm(PublishStatusEnum.DRAFT)}>
                  {Translation('app.button.saveAsDraft')}
                </Button>
                <Button
                  style={{ marginLeft: 25 }}
                  variant="contained"
                  color="secondary"
                  onClick={() => onSubmitForm(PublishStatusEnum.PUBLISHED)}
                >
                  {Translation('app.button.publish')}
                </Button>
              </>
            )}
          </div>
        </div>

        {/* Uploaded File Records */}
        {/* <div className={classes.section}>
          <div className={classes.subHeaderContainer}>
            <span className={classes.subHeader}>{Translation('incentive.common.past_files_record')}</span>
          </div>
          {regionLocale.map((locale) => {
            const filename = formState.bulletinFile[locale]?.filename || 'N/A';
            const url = formState.bulletinFile[locale]?.url;
            return (
              <div
                key={`incentive-bulletin-file-${locale}`}
                className={`${classes.rowContainer} ${classes.textOnlyRowSpacing}`}
              >
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>{Translation(`incentive.common.bulletin_filename.${locale}`)} :</span>
                </div>
                <div style={{ flexGrow: 1 }}>{renderDownloadLink(filename, url)}</div>
              </div>
            );
          })}
          <div className={`${classes.rowContainer} ${classes.textOnlyRowSpacing}`}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('incentive.common.result_filename')} :</span>
            </div>
            <div style={{ flexGrow: 1 }}>{formState.resultFile?.filename || 'N/A'}</div>
          </div>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('incentive.common.agent_list_filename')} :</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              {renderDownloadLink(formState.agentListFile?.filename || 'N/A', formState.agentListFile?.url)}
            </div>
          </div>

        </div>*/}
      </div>
    </>
  );
};

export default IncentiveDetailForm;
