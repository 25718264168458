import { Dispatch } from 'react';
import { apiClient, apiErrorHandler } from 'src/app/common/network';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

export const fetchCustomerCampaignList = async (params: any, dispatch?: Dispatch<any>): Promise<any> => {
  const url = `${ADMIN_URL}/report/customer-campaign/list`;

  return apiClient
    .post(url, params)
    .then((response: any) => response.data)
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCustomerCampaignDownload = async (params: any, dispatch?: Dispatch<any>) => {
  const url = `${ADMIN_URL}/report/customer-campaign/list/export`;

  apiClient
    .post(url, params)
    .then((response: any) => {
      const url = window.URL.createObjectURL(
        new Blob(['\uFEFF' + response.data], {
          type: 'text/csv;charset=utf-8;',
        }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'customer-campaign-report' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};
