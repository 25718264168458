import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import PruTableCustom from 'src/app/common/components/PruTable/PruTableCustom';
import { useDataProvider } from 'src/app/common/utils';
import { getDayStart, getDayEnd, getDisplayTimeForTimestamp, getDisplayDate } from 'src/app/common/utils/time-utils';
import { fetchPerformanceList, fetchPerformanceDownload } from 'src/app/modules/Report/network/livestreamReportCrud';
import { PerformanceListParams, PerformancePaginateList } from '../types/performance-type';

type PerformanceListingPageProps = {} & ParamsProps;

const initialState: PerformanceListParams = {
  requestedDateFrom: getDayStart(new Date()),
  requestedDateTo: getDayEnd(new Date()),
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): PerformanceListParams => {
  return initialParams
    ? {
        requestedDateFrom: initialParams.requestedDateFrom
          ? getDayStart(new Date(initialParams.requestedDateFrom))
          : getDayStart(new Date()),
        requestedDateTo: initialParams.requestedDateTo
          ? getDayEnd(new Date(initialParams.requestedDateTo))
          : getDayEnd(new Date()),
        page: 1,
        limit: 5,
      }
    : initialState;
};

const PerformanceListingPage: FC<PerformanceListingPageProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<PerformanceListParams>(paramsInitiator(initialParams));
  const [reportList, setReportList] = useState<PerformancePaginateList>();

  const { isLoading, refreshData } = useDataProvider<PerformancePaginateList>(
    async () => {
      try {
        return await fetchPerformanceList(filterState, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvDownload = () => {
    try {
      fetchPerformanceDownload(filterState, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('livestream.report.common.filter')}
        itemDef={[
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'requestedDateFrom',
            fieldTo: 'requestedDateTo',
            initialDateFrom: filterState.requestedDateFrom,
            initialDateTo: filterState.requestedDateTo,
            displayName: Translation('report.common.requestDate'),
            defaultDateFrom: initialState.requestedDateFrom,
            defaultDateTo: initialState.requestedDateTo,
            periodTime: { months: 3 },
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            requestedDateFrom: data.requestedDateFrom,
            requestedDateTo: data.requestedDateTo,
          })
        }
        fetchData={refreshData}
      />
      <PruTableCustom
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        isCombineHeader={true}
        title={Translation('livestream.performance.title')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvDownload(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: () => '',
          },
          {
            keyIndex: 'name',
            displayName: Translation('livestream.report.common.livestreamName'),
            rowSpan: 2,
            renderData: (row: any) => row.livestreamName,
          },
          {
            keyIndex: 'livestreamStartAt',
            displayName: Translation('livestream.report.common.livestreamStartAt'),
            rowSpan: 2,
            renderData: (row: any) => getDisplayDate(row.livestreamStartAt, 'YYYY-MM-DD'),
          },
          {
            keyIndex: 'livestreamTime',
            displayName: Translation('livestream.report.common.livestreamTime'),
            rowSpan: 2,
            renderData: (row: any) => getDisplayDate(row.livestreamStartAt, 'HH:mm:ss'),
          },
          {
            keyIndex: 'noOfViewer',
            displayName: Translation('livestream.performance.formLabel.noOfViewer'),
            align: 'center',
            colspan: 3,
            renderData: (row: any) => '',
          },
          {
            keyIndex: 'streaming',
            displayName: Translation('livestream.report.common.streaming'),
            isCombine: true,
            renderData: (row: any) => (row.noOfViewer.streaming || 0).toString(),
          },
          {
            keyIndex: 'playback',
            displayName: Translation('livestream.report.common.playback'),
            isCombine: true,
            renderData: (row: any) => (row.noOfViewer.playback || 0).toString(),
          },
          {
            keyIndex: 'total',
            displayName: Translation('livestream.report.common.total'),
            isCombine: true,
            renderData: (row: any) => (row.noOfViewer.total || 0).toString(),
          },
          {
            keyIndex: 'noOfComment',
            displayName: Translation('livestream.performance.formLabel.noOfComment'),
            rowSpan: 2,
            renderData: (row: any) => (row.noOfComment || 0).toString(),
          },
          {
            keyIndex: 'noOfLike',
            displayName: Translation('livestream.performance.formLabel.noOfLike'),
            rowSpan: 2,
            renderData: (row: any) => (row.noOfLike || 0).toString(),
          },
          {
            keyIndex: 'noOfShare',
            displayName: Translation('livestream.performance.formLabel.noOfShare'),
            rowSpan: 2,
            renderData: (row: any) => (row.noOfShare || 0).toString(),
          },
          {
            keyIndex: 'avgWatchingTime',
            displayName: Translation('livestream.performance.formLabel.avgWatchingTime'),
            align: 'center',
            colspan: 3,
            renderData: (row: any) => '',
          },
          {
            keyIndex: 'streaming1',
            displayName: Translation('livestream.report.common.streaming'),
            isCombine: true,
            renderData: (row: any) => getDisplayTimeForTimestamp(row.avgWatchingTime.streaming || 0),
          },
          {
            keyIndex: 'playback1',
            displayName: Translation('livestream.report.common.playback'),
            isCombine: true,
            renderData: (row: any) => getDisplayTimeForTimestamp(row.avgWatchingTime.playback || 0),
          },
          {
            keyIndex: 'total1',
            displayName: Translation('livestream.report.common.total'),
            isCombine: true,
            renderData: (row: any) => getDisplayTimeForTimestamp(row.avgWatchingTime.total || 0),
          },
          {
            keyIndex: 'streamingBounceRate',
            displayName: Translation('livestream.performance.formLabel.streamingBounceRate'),
            rowSpan: 2,
            renderData: (row: any) => `${Math.round(row.streamingBounceRate || 0).toString()}%`,
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default PerformanceListingPage;
