import { Dispatch } from 'react';
import { forEach } from 'lodash';
import moment from 'moment';
import { apiClient, apiErrorHandler } from 'src/app/common/network';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

export const getReportEventInvitation = async (params?: any) => {
  return apiClient.get(`${ADMIN_URL}/report/event/list`, {
    params,
  });
};

export const fetchEventReportDownload = (params: any, dispatch?: Dispatch<any>): void => {
  const uri = `${ADMIN_URL}/report/event/list/export`;

  apiClient
    .post<Blob>(uri, params, { responseType: 'text' })
    .then((response: any) => {
      const url = window.URL.createObjectURL(
        new Blob(['\uFEFF' + response.data], {
          type: 'text/csv;charset=utf-8;',
        }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Event report_' + moment(new Date()).format('YYYYMMDD') + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
