import React, { FC, useState, useEffect } from 'react';
import {
  TableContainer,
  TableFooter,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Dialog,
  DialogTitle,
} from '@mui/material';
import { PruForm } from 'src/app/common/components/PruForm';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import ProTableCell from 'src/app/common/components/ProTable/ProTableCell';
import StickyTableCell from 'src/app/common/components/ProTable/StickyTableCell';
import PruTableLoading from 'src/app/common/components/Table/PruTableLoading';
import PruTableEmptyRow from 'src/app/common/components/Table/PruTableEmptyRow';
import PruTablePaginationActions from 'src/app/common/components/Table/PruTablePaginationActions';
import filterConfig from './filterConfig';
import tableHeader from './tableHeader';
import tableConfig from './tableConfig';
import { styles } from '../styles';
import { getTemplates, addTemplate, editTemplate, deleteTemplate } from '../../../../network/eBirthdayCardCrud';
import PopUp from './components/popUp';
import DeleteDialog from './components/deleteDialog';
import { useSelector } from 'react-redux';
import { AuthenticationState } from '@pruforce/common-adminweb-sdk';
import { RootState } from 'src/redux/store';
import { regionLocale } from 'src/app/i18n';
import { getDayStart, getDayEnd } from '../../../../../../../app/common/utils/time-utils';
import { PublishStatus } from '../../../../types/ebirthday-card-type';

type DeleteDialogState = {
  open: boolean;
  id: string;
};

const initialDeleteDialogState: DeleteDialogState = {
  open: false,
  id: '',
};

const TemplateList: FC<any> = (props) => {
  const intl = useIntl();
  const { classes } = styles();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const filterInitData = {
    status: PublishStatus.ALL,
    timeRange: [getDayStart(new Date()), getDayEnd(new Date())],
  };

  const [filterData, setFilterData] = useState<{ [key: string]: any }>(filterInitData);
  const [filterSavedData, setFilterSavedData] = useState<{ [key: string]: any }>(filterInitData);
  const [tableData, setTableData] = useState<{ [key: string]: any }[]>([]);
  const [pageData, setPageData] = useState<{ [key: string]: any }>({
    pageNum: 1,
    pageSize: 10,
  });
  const [popUpVisible, setPopUpVisible] = useState<boolean>(false);
  const initialTemplateValue = {
    status: PublishStatus.PUBLISHED,
  };

  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>(initialTemplateValue);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [isView, setIsView] = useState<boolean>(false);
  const [templateId, settemplateId] = useState<string>('');
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);
  const [deleteDialogState, setDeleteDialogState] = useState<DeleteDialogState>(initialDeleteDialogState);

  useEffect(() => {
    const fetchAPI = async () => {
      setFormLoading(true);
      await fetchData();
      setFormLoading(false);
    };

    fetchAPI();

    return;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener('keypress', keyPressSearch);
    return () => {
      window.removeEventListener('keypress', keyPressSearch);
    };
    // eslint-disable-next-line
  }, [filterData]);

  const fetchData = (newFilterState?: any) => {
    setFormLoading(true);
    const newFilter = newFilterState || {};
    const requestParams = {
      ...filterData,
      ...pageData,
      ...newFilter,
    };

    if (requestParams.status == PublishStatus.ALL) {
      delete requestParams.status;
    }

    requestParams.startDate = requestParams.timeRange?.[0] || '';
    requestParams.endDate = requestParams.timeRange?.[1] || '';
    delete requestParams.timeRange;

    getTemplates(requestParams)
      .then((res: any) => {
        setTableData(res.data ? res.data.values : []);
        setPageData({
          pageNum: res.data?.page || 1,
          pageSize: res.data?.size || 0,
          total: res.data?.total || 0,
        });
        setFormLoading(false);
      })
      .catch((err: any) => {
        setFormLoading(false);
      });
  };

  const onSearch = () => {
    // setFilterData(filterData)
    const newPageData = {
      ...pageData,
      pageNum: 1,
    };
    setPageData(newPageData);
    setFilterSavedData(filterData);
    fetchData({ ...filterData, ...newPageData });
  };

  const keyPressSearch = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  const onChangePage = (pageNum: number) => {
    const newPageData = {
      ...pageData,
      pageNum,
    };
    setPageData(newPageData);
    fetchData({ ...filterSavedData, ...newPageData });
  };

  const onChangePageRows = (rows: any) => {
    const newPageData = {
      ...pageData,
      pageNum: 1,
      pageSize: rows,
    };
    setPageData(newPageData);
    fetchData({ ...filterSavedData, ...newPageData });
  };

  const handleSort = (sorter: any) => {
    let sortItem;
    let sortOrder;

    Object.entries(sorter).forEach(([item, order]) => {
      sortItem = item;
      sortOrder = order;
    });

    const data = {
      ...filterSavedData,
      sortItem,
      sortOrder: sortOrder ? (sortOrder as string).toUpperCase() : sortOrder,
    };

    const newPageData = {
      ...pageData,
      pageNum: 1,
    };

    setFilterSavedData(data);
    setPageData(newPageData);
    fetchData({ ...data, ...newPageData });
  };

  const openPopUp = () => {
    setPopUpVisible(true);
  };

  const onSave = async (values: any) => {
    setFormLoading(true);

    const data = {
      ...values,
      modifier: user && user.username,
    };

    try {
      if (templateId) {
        await editTemplate(data);
      } else {
        await addTemplate(data);
      }

      settemplateId('');
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: Translation('global.submit.success'),
            content: Translation('global.submit.success'),
          },
        ]),
      );
      await refreshData();
      setFormLoading(false);
      setPopUpVisible(false);
    } catch (error) {
      setFormLoading(false);
      setPopUpVisible(false);
      settemplateId('');
    }
  };

  const refreshData = async () => {
    const newPageData = {
      ...pageData,
      pageNum: 1,
    };
    setPageData(newPageData);
    fetchData({ ...filterSavedData, ...newPageData });
  };

  const onChangeStatus = (row: any) => {
    editTemplate({
      templateId: row.templateId,
      status: row.status == PublishStatus.PUBLISHED ? PublishStatus.UNPUBLISHED : PublishStatus.PUBLISHED,
      modifier: user && user.username,
    })
      .then((res: any) => {
        refreshData();
        setFormLoading(false);
      })
      .catch((err: any) => {
        setFormLoading(false);
      });
  };

  const onCancel = () => {
    setPopUpVisible(false);
    setInitialValues(initialTemplateValue);
    settemplateId('');
    setTimeout(() => {
      setIsView(false);
    }, 200);
  };

  const onReset = () => {
    setFilterData(filterInitData);
  };

  return (
    <>
      <PruForm
        config={filterConfig(Translation, onSearch, onReset)}
        data={filterData}
        onChange={setFilterData}
        space={20}
        style={{ padding: 20 }}
      ></PruForm>

      <PruForm
        config={tableHeader(Translation, openPopUp, refreshData)}
        data={[]}
        onChange={() => {}}
        space={20}
        style={{ padding: '15px 20px', marginTop: 20, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
      ></PruForm>

      <DeleteDialog
        key={`delete-dialog-${deleteDialogState.open}`}
        open={deleteDialogState.open}
        templateId={deleteDialogState.id}
        onClose={() => setDeleteDialogState(initialDeleteDialogState)}
        onRefresh={refreshData}
      />

      <TableContainer component={Paper} className="event-category-container">
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableConfig(Translation, regionLocale).map((item) => (
                <ProTableCell key={item.prop} dataIndex={item.prop} handleSort={handleSort}>
                  {item.title}
                </ProTableCell>
              ))}
              <StickyTableCell className={`sticky-cell`}>
                {Translation('component.formLabel.operation')}
              </StickyTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <PruTableLoading isLoading={formLoading} />
            <PruTableEmptyRow isEmpty={tableData.length === 0} />
            {tableData.length > 0 &&
              tableData.map((row) => (
                <TableRow>
                  {tableConfig(Translation, regionLocale).map((config) => (
                    <TableCell>{config.render ? config.render(row) : row[config.prop]}</TableCell>
                  ))}
                  <StickyTableCell className={`sticky-cell`}>
                    <div className={classes.operationContainer}>
                      {
                        <div
                          style={{ marginRight: 10 }}
                          className={`${classes.operationBtn}`}
                          onClick={() =>
                            setDeleteDialogState({
                              open: true,
                              id: row.templateId,
                            })
                          }
                        >
                          {Translation('app.button.delete')}
                        </div>
                      }
                      {
                        <div
                          style={{ marginRight: 10 }}
                          className={`${classes.operationBtn}`}
                          onClick={() => onChangeStatus(row)}
                        >
                          {row.status == PublishStatus.PUBLISHED
                            ? Translation('app.button.unpublish')
                            : Translation('app.button.publish')}
                        </div>
                      }
                    </div>
                  </StickyTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter component="div" className={`table-footer-css`}>
        <TableRow component="div">
          <TablePagination
            align="right"
            rowsPerPageOptions={[5, 10, 20, 50]}
            colSpan={12}
            component="div"
            count={pageData.total || 0}
            rowsPerPage={pageData.pageSize}
            page={pageData.pageNum - 1 || 0}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={(e, page) => onChangePage(page + 1)}
            onRowsPerPageChange={(e) => onChangePageRows(e.target.value)}
            ActionsComponent={PruTablePaginationActions}
          />
        </TableRow>
      </TableFooter>

      <Dialog open={popUpVisible} onClose={isView ? onCancel : () => {}} fullWidth>
        <DialogTitle>
          {isView
            ? Translation('global.view.text')
            : templateId
            ? Translation('global.edit.text')
            : Translation('app.button.addNew')}{' '}
          {`${Translation('event.ebirthday_card.template.form')}`}
        </DialogTitle>
        <PopUp
          visible={popUpVisible}
          onCancel={onCancel}
          onSave={onSave}
          intialValues={initialValues}
          confirmLoading={formLoading}
          disabled={isView}
          templateId={templateId}
        />
      </Dialog>
    </>
  );
};

export default TemplateList;
