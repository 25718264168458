export const EVENT_BASE_PATH = '/event-v1';
export const EVENT_CATEGORY_PATH = "/category";
export const EVENT_LIST_PATH = "/list";
export const EVENT_INVITATION_LETTER_PATH = "/invitation-letter";
export const EBIRTHDAY_CARD_PATH = "/ebirthday-card";
export const EBIRTHDAY_CARD_TEMPLATE_LIST_PATH = "/template";
export const EBIRTHDAY_CARD_CONTENT_LIST_PATH = "/content";
export const EBIRTHDAY_CARD_TITLE_LIST_PATH = "/title";
export const GREETING_CARD_PATH = "/greeting-card";
export const GREETING_CARD_TEMPLATE_LIST_PATH = "/template";
export const GREETING_CARD_CONTENT_LIST_PATH = "/content";
export const GREETING_CARD_TITLE_LIST_PATH = "/title";
export const DATE_ERROR_TEXT = 'Archive date must be after Publish date';
export const MANDATORY_FIELD_ERROR_TEXT = 'This field is mandatory';
export const EMAIL_FORMAT_ERROR_TEXT = "Please fill in a valid email address";

//  These constants map to aside menu, header menu to display title
export const EVENT_TITLE = "title.event";
export const EVENT_CATEGORY_TITLE = "title.event.category";
export const EVENT_LIST_TITLE = "title.event.list";
export const EVENT_INVITATION_LETTER_TITLE = "title.event.invitation_letter";
export const EBIRTHDAY_CARD_TITLE = "title.event.ebirthday_card";
export const EBIRTHDAY_CARD_TEMPLATE_LIST_TITLE = "title.event.ebirthday_card.template.list";
export const EBIRTHDAY_CARD_CONTENT_LIST_TITLE = "title.event.ebirthday_card.content.list";
export const EBIRTHDAY_CARD_TITLE_LIST_TITLE = "title.event.ebirthday_card.title.list";

export const GREETING_CARD_TITLE = "title.event.greeting_card";
export const GREETING_CARD_TEMPLATE_LIST_TITLE = "title.event.greeting_card.template.list";
export const GREETING_CARD_CONTENT_LIST_TITLE = "title.event.greeting_card.content.list";
export const GREETING_CARD_TITLE_LIST_TITLE = "title.event.greeting_card.title.list";

//  These constants map to permission control, identical to permission enum defined below
export const EVENT_CATEGORIES_NAMESPACE = "EVENT-CATEGORIES";
export const EVENT_LIST_NAMESPACE = "EVENT-LIST";
export const EVENT_INVITATION_LETTER_NAMESPACE = "EVENT-INVITATION-LETTER";
export const EBIRTHDAY_CARD_TEMPLATE_NAMESPACE = "EBIRTHDAY-CARD-TEMPLATE";
export const EBIRTHDAY_CARD_CONTENT_NAMESPACE = "EBIRTHDAY-CARD-CONTENT";
export const EBIRTHDAY_CARD_TITLE_NAMESPACE = "EBIRTHDAY-CARD-TITLE";

export const GREETING_CARD_TEMPLATE_NAMESPACE = "GREETING-CARD-TEMPLATE";
export const GREETING_CARD_CONTENT_NAMESPACE = "GREETING-CARD-CONTENT";
export const GREETING_CARD_TITLE_NAMESPACE = "GREETING-CARD-TITLE";


export enum AdminEventsPermissionType {
  // EVENT_CATEGORY_READ = "READ_ANY_EVENT-CATEGORIES",
  // EVENT_CATEGORY_CREATE = "CREATE_ANY_EVENT-CATEGORIES",
  // EVENT_CATEGORY_UPDATE = "UPDATE_ANY_EVENT-CATEGORIES",
  // EVENT_LIST_READ = "READ_ANY_EVENT-LIST",
  // EVENT_LIST_CREATE = "CREATE_ANY_EVENT-LIST",
  // EVENT_LIST_UPDATE = "UPDATE_ANY_EVENT-LIST",
  EBIRTHDAY_CARD_TEMPLATE_LIST_READ = "READ_ANY_EBIRTHDAY-CARD-TEMPLATE-LIST",
  EBIRTHDAY_CARD_TEMPLATE_LIST_CREATE = "CREATE_ANY_EBIRTHDAY-CARD-TEMPLATE-LIST",
  EBIRTHDAY_CARD_TEMPLATE_LIST_UPDATE = "UPDATE_ANY_EBIRTHDAY-CARD-TEMPLATE-LIST",
  EBIRTHDAY_CARD_CONTENT_LIST_READ = "READ_ANY_EBIRTHDAY-CARD-CONTENT-LIST",
  EBIRTHDAY_CARD_CONTENT_LIST_CREATE = "CREATE_ANY_EBIRTHDAY-CARD-CONTENT-LIST",
  EBIRTHDAY_CARD_CONTENT_LIST_UPDATE = "UPDATE_ANY_EBIRTHDAY-CARD-CONTENT-LIST",
  EBIRTHDAY_CARD_TITLE_LIST_READ = "READ_ANY_EBIRTHDAY-CARD-TITLE-LIST",
  EBIRTHDAY_CARD_TITLE_LIST_CREATE = "CREATE_ANY_EBIRTHDAY-CARD-TITLE-LIST",
  EBIRTHDAY_CARD_TITLE_LIST_UPDATE = "UPDATE_ANY_EBIRTHDAY-CARD-TITLE-LIST",
  EVENT_INVITATION_READ = "READ_ANY_EVENT-INVITATION-LETTER",
  EVENT_INVITATION_CREATE = "CREATE_ANY_EVENT-INVITATION-LETTER",
  EVENT_INVITATION_UPDATE = "UPDATE_ANY_EVENT-INVITATION-LETTER",

  GREETING_CARD_TEMPLATE_LIST_READ = "READ_ANY_GREETING-CARD-TEMPLATE",
  GREETING_CARD_TEMPLATE_LIST_CREATE = "CREATE_ANY_GREETING-CARD-TEMPLATE",
  GREETING_CARD_TEMPLATE_LIST_UPDATE = "UPDATE_ANY_GREETING-CARD-TEMPLATE",
  GREETING_CARD_CONTENT_LIST_READ = "READ_ANY_GREETING-CARD-CONTENT",
  GREETING_CARD_CONTENT_LIST_CREATE = "CREATE_ANY_GREETING-CARD-CONTENT",
  GREETING_CARD_CONTENT_LIST_UPDATE = "UPDATE_ANY_GREETING-CARD-CONTENT",
  GREETING_CARD_TITLE_LIST_READ = "READ_ANY_GREETING-CARD-TITLE",
  GREETING_CARD_TITLE_LIST_CREATE = "CREATE_ANY_GREETING-CARD-TITLE",
  GREETING_CARD_TITLE_LIST_UPDATE = "UPDATE_ANY_GREETING-CARD-TITLE",
};