import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { MdrtStoryDetail, MdrtStoryFormMode } from '../../../types/mdrt-story-types';
import { fetchItem } from '../../../network/mdrtStoryCrud';
import MdrtStoryDetailForm from './components/MdrtStoryDetailForm';

interface StateMdrtType {
  type: MdrtStoryFormMode;
}

const MdrtStoryDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode, itemId } = useMemo(() => {
    const state = location.state as StateMdrtType;
    return {
      formMode: state.type,
      itemId: id ? id : undefined,
    };
  }, [id, location]);

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemDetail, setItemDetail] = useState<MdrtStoryDetail>();

  const reloadData = () => {
    if (itemId) {
      setIsLoading(true);
      fetchItem(itemId, dispatch)
        .then((result) => {
          setItemDetail({ ...result, id: itemId });
        })
        .catch(() => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [itemId]);

  return (
    <>
      {formMode === MdrtStoryFormMode.CREATE && (
        <MdrtStoryDetailForm formMode={formMode} onReload={reloadData} onRouteTo={(route) => history.push(route)} />
      )}
      {formMode !== MdrtStoryFormMode.CREATE &&
        (isLoading || !itemDetail ? (
          <LayoutSplashScreen />
        ) : (
          <MdrtStoryDetailForm
            itemId={itemId}
            formMode={formMode}
            itemDetail={itemDetail}
            onReload={reloadData}
            onRouteTo={(route) => history.push(route)}
          />
        ))}
    </>
  );
};

export default MdrtStoryDetailPage;
