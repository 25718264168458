export const QA_INCOME_BASE_PATH = '/qa-income';
export const QA_INCOME_NAMESPACE = "QA_INCOME";

export const DATE_ERROR_TEXT = 'End date must be after Begin date';

export const MANDATORY_FIELD_ERROR_TEXT = 'This field is mandatory';
export const CHOOSE_ONE_ERROR_TEXT = 'One of the fields must be filled';

export const DATE_FORMAT_ERROR_TEXT = 'Invalid date format';
//  These constants map to aside menu, header menu to display title
export const QA_INCOME_TITLE = "title.qaIncome";

export enum AdminQAIncomePermissionType {
  QA_INCOME_READ = "READ_ANY_ROLE-PERMISSION",
  QA_INCOME_CREATE = "CREATE_ANY_ROLE-PERMISSION",
  QA_INCOME_UPDATE = "UPDATE_ANY_ROLE-PERMISSION"
};

export const supportLangList = ['vi', 'en'];

export const mainLang = 'vi';
export const subLang = 'en';


