import React, { FC } from 'react';
import { Tabs, Tab } from '@mui/material';
import { ParamsProps } from '../ParamsProvider';
import { makeStyles } from 'tss-react/mui';

type PruTabProps = {
  itemDef: PruTabItem[];
  value?: Number;
  className?: string;
  onChangeTab?: (event: any, tab: number) => void;
} & ParamsProps;

export type PruTabItem = {
  displayName: string;
  value: string;
};

const useStyles = makeStyles()(() => ({
  indicator: {
    backgroundColor: 'red',
  },
}));

const PruTab: FC<PruTabProps> = ({ itemDef, className, value, onChangeTab }) => {
  const { classes } = useStyles();
  // const [value, setValue] = React.useState(0);
  // const handleChange = (event: any, newValue: number) => {
  //   setValue(newValue);
  // };
  return (
    <Tabs value={value} onChange={onChangeTab} indicatorColor="primary" classes={{ indicator: classes.indicator }}>
      {itemDef.map((p, index) => (
        <Tab key={p.value} label={p.displayName} className={className} />
      ))}
    </Tabs>
  );
};

export default PruTab;
