import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  SURVEY_TITLE,
  SURVEY_BASE_PATH,
  AdminSurveyPermissionType,
  SURVEY_MANAGEMENT_TITLE,
  SURVEY_MANAGEMENT_PATH,
  SURVEY_NAMESPACE,
  ASSIGN_SERVING_SERVEY_TITLE,
  ASSIGN_SERVING_SERVEY_NAMESPACE,
  ASSIGN_SERVING_SERVEY_PATH,
  AdminAssignServingSurveyPermissionType,
  CANDIDATE_SURVEY_TITLE,
  SURVEY_CANDIDATE_PATH,
  CANDIDATE_SURVEY_NAMESPACE,
  LEADER_SURVEY_TITLE,
  SURVEY_LEADER_PATH,
  AGENT_LEADER_SURVEY_NAMESPACE,
  NON_PROSPECT_SURVEY_TITLE,
  SURVEY_NON_PROSPECT_PATH,
  NON_PROSPECT_SURVEY_NAMESPACE,
  CUSTOMER_SURVEY_NAMESPACE,
  SURVEY_CUSTOMER_PATH,
  CUSTOMER_SURVEY_TITLE,
} from './constants';
import { isPermitted } from 'src/app/common/utils';
import SurveyManagementPage from './pages/Management/SurveyManagementPage';
import AssignServingSurveyPage from './pages/Assign-Serving-Survey/AssignServingSurveyPage';
import CandidateSurveyManagementPage from './pages/Candidate/CandidateSurveyManagementPage';
import AgentLeaderSurveyManagementPage from './pages/AgentLeader/AgentLeaderSurveyManagementPage';
import NonProspectSurveyManagementPage from './pages/NonProspect/NonProspectSurveyManagementPage';
import CustomerSurveyManagementPage from '../sales-kit/pages/customer-survey/CustomerSurveyManagementPage';

export const surveyModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(SURVEY_TITLE),
    path: SURVEY_BASE_PATH,
    icon: '/media/svg/icons/Communication/Chat6.svg',
    enableRead: isPermitted([
      ...Object.values(AdminSurveyPermissionType),
      ...Object.values(AdminAssignServingSurveyPermissionType),
    ], permissions),
    child: [
      {
        title: Translation(SURVEY_MANAGEMENT_TITLE),
        path: `${SURVEY_BASE_PATH}${SURVEY_MANAGEMENT_PATH}`,
        namespace: SURVEY_NAMESPACE,
        component: SurveyManagementPage,
        enableRead: isPermitted(
          [
            AdminSurveyPermissionType.SURVEY_READ,
            AdminSurveyPermissionType.SURVEY_CREATE,
            AdminSurveyPermissionType.SURVEY_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminSurveyPermissionType.SURVEY_CREATE], permissions),
        enableUpdate: isPermitted([AdminSurveyPermissionType.SURVEY_UPDATE], permissions),
      },
      {
        title: Translation(ASSIGN_SERVING_SERVEY_TITLE),
        path: `${SURVEY_BASE_PATH}${ASSIGN_SERVING_SERVEY_PATH}`,
        namespace: ASSIGN_SERVING_SERVEY_NAMESPACE,
        component: AssignServingSurveyPage,
        enableRead: isPermitted([AdminAssignServingSurveyPermissionType.ASSIGN_SERVING_SURVEY_READ], permissions),
        enableCreate: isPermitted([AdminAssignServingSurveyPermissionType.ASSIGN_SERVING_SURVEY_CREATE], permissions),
        enableUpdate: isPermitted([AdminAssignServingSurveyPermissionType.ASSIGN_SERVING_SURVEY_UPDATE], permissions),
      },
      {
        title: Translation(CANDIDATE_SURVEY_TITLE),
        path: `${SURVEY_BASE_PATH}${SURVEY_CANDIDATE_PATH}`,
        namespace: CANDIDATE_SURVEY_NAMESPACE,
        component: CandidateSurveyManagementPage,
        enableRead: isPermitted(
          [
            AdminSurveyPermissionType.CANDIDATE_SURVEY_READ,
            AdminSurveyPermissionType.CANDIDATE_SURVEY_CREATE,
            AdminSurveyPermissionType.CANDIDATE_SURVEY_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminSurveyPermissionType.CANDIDATE_SURVEY_CREATE], permissions),
        enableUpdate: isPermitted([AdminSurveyPermissionType.CANDIDATE_SURVEY_UPDATE], permissions),
      },
      {
        title: Translation(LEADER_SURVEY_TITLE),
        path: `${SURVEY_BASE_PATH}${SURVEY_LEADER_PATH}`,
        namespace: AGENT_LEADER_SURVEY_NAMESPACE,
        component: AgentLeaderSurveyManagementPage,
        enableRead: isPermitted(
          [
            AdminSurveyPermissionType.AGENT_LEADER_SURVEY_READ,
            AdminSurveyPermissionType.AGENT_LEADER_SURVEY_CREATE,
            AdminSurveyPermissionType.AGENT_LEADER_SURVEY_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminSurveyPermissionType.AGENT_LEADER_SURVEY_CREATE], permissions),
        enableUpdate: isPermitted([AdminSurveyPermissionType.AGENT_LEADER_SURVEY_UPDATE], permissions),
      },
      {
        title: Translation(CUSTOMER_SURVEY_TITLE),
        path: `${SURVEY_BASE_PATH}${SURVEY_CUSTOMER_PATH}`,
        namespace: CUSTOMER_SURVEY_NAMESPACE,
        component: CustomerSurveyManagementPage,
        enableRead: isPermitted(
          [
            AdminSurveyPermissionType.CUSTOMER_SURVEY_READ,
            AdminSurveyPermissionType.CUSTOMER_SURVEY_CREATE,
            AdminSurveyPermissionType.CUSTOMER_SURVEY_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminSurveyPermissionType.CUSTOMER_SURVEY_CREATE], permissions),
        enableUpdate: isPermitted([AdminSurveyPermissionType.CUSTOMER_SURVEY_UPDATE], permissions),
      },
      {
        title: Translation(NON_PROSPECT_SURVEY_TITLE),
        path: `${SURVEY_BASE_PATH}${SURVEY_NON_PROSPECT_PATH}`,
        namespace: NON_PROSPECT_SURVEY_NAMESPACE,
        component: NonProspectSurveyManagementPage,
        enableRead: isPermitted(
          [
            AdminSurveyPermissionType.NON_PROSPECT_SURVEY_READ,
            AdminSurveyPermissionType.NON_PROSPECT_SURVEY_CREATE,
            AdminSurveyPermissionType.NON_PROSPECT_SURVEY_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminSurveyPermissionType.NON_PROSPECT_SURVEY_CREATE], permissions),
        enableUpdate: isPermitted([AdminSurveyPermissionType.NON_PROSPECT_SURVEY_UPDATE], permissions),
      },
    ],
  };
};
