import { ITypeOfView, ETypeOfView } from '../../../common/types/view-types';

export enum IncentiveStatus {
  NOT_BEGIN = 'Not Begin',
  IN_PROGRESS = 'In Progress',
  FINISHED = 'Finished',
}

export enum PublishStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export enum EnrolmentType {
  BY_SEGMENT = 'SEGMENTS',
  SPECIFIC = 'AGENTS',
}

export enum AwardScheme {
  INCENTIVE = 'FREE',
  MINI_INCENTIVE = 'RACE',
}

export enum IncentiveType {
  INCENTIVE = 'I',
  MINI_INCENTIVE = 'M',
}

export enum IncentiveFieldType {
  CURRENCY = 'CURRENCY',
  NUMBER = 'NUMBER',
}

export enum HighlightFieldType {
  CURRENCY = 'CURRENCY',
  NUMBER = 'NUMBER',
  RANK = 'RANK',
  VALUE = 'VALUE',
}

export enum AwardOperatorType {
  GREATER_THAN_EQUAL = '>=',
  SMALLER_THAN_EQUAL = '<=',
}

export enum IncentiveRuleType {
  CURRENCY = 'CURRENCY',
  NUMBER = 'NUMBER',
  VALUE = 'VALUE',
}

export type IncentiveItem = {
  id: string;
  code: string;
  name: string;
  startDate: string;
  endDate: string;
  createdDate: string;
  publishStatus: PublishStatus;
  incentiveStatus: IncentiveStatus;
  numOfEnrolledAgent: number;
  resultFileName: string;
};

export type IncentiveRequirementSet = {
  setId: string;
  operand: string;
  operator: string;
  type: string;
};

export type IncentiveRequirement = {
  operand: string;
  operator: AwardOperatorType;
  type: IncentiveFieldType;
  values: number[];
};

export type IncentiveAward = {
  requirements: IncentiveRequirement[];
  properties: {
    key: string;
    value: string;
  }[];
};

export type IncentiveProperty = {
  key: string;
  value: string;
};

export type IncentiveHighlight = {
  name: string;
  type: string;
};

export type IncentiveRule = {
  operand: string;
  operator: AwardOperatorType;
  type: IncentiveRuleType;
  values: string[];
};

export type IncentiveImageType = {
  blobId: string;
  filename: string;
  url?: string;
};

export type IncentiveDetail = {
  code: string;
  name: string;
  startDate: Date | null;
  endDate: Date | null;
  enrolmentStartDate: Date | null;
  enrolmentEndDate: Date | null;
  archiveDate: Date | null;
  publishDate: Date | null;
  status?: PublishStatus;
  bulletin?: string;
  bulletinFileId?: string;
  bulletinFileName?: string;
  imageFileId?: string;
  imageFileName?: string;
  incentiveImage?: IncentiveImageType;
  detail?: string;
  registrationRequired?: boolean;
  objective?: string;
  award?: string;
  quota?: number;
  type: IncentiveType;
  awardScheme: AwardScheme;
  enrolmentType: EnrolmentType;
  eligibleSegments: {
    designation: string[];
  };
  awards: IncentiveAward[];
  rules: IncentiveRule[];
  eligibleAgents: string[];
  excludedAgents: string[];
  properties: IncentiveProperty[];
  highlights: IncentiveHighlight[];
  resultFileName?: string;
  agentListFileName?: string;
  stickOnTop?: boolean;
  template: IncentiveTemplate;
  multiOption: {
    option?: number | null;
    optionName?: string | null;
    hasMany: boolean;
  };
  registerLimit: {
    isLimited: boolean;
    limitNumber: number | null;
  };
  notification: {
    type: ENotificationType;
    msgTitle?: string | null;
    msgContent?: string | null;
    pushContent?: string | null;
    pushTitle?: string | null;
    category: string;
  };
  receiver: ITypeOfView;
};

export type IncentiveFormState = {
  code?: string;
  name?: string;
  startDate: Date | null;
  endDate: Date | null;
  enrolmentStartDate: Date | null;
  enrolmentEndDate: Date | null;
  archiveDate: Date | null;
  publishDate: Date | null;
  registrationRequired: boolean;
  status?: PublishStatus;
  bulletin?: string;
  bulletinFileId?: string;
  bulletinFileName?: string;
  imageFileId?: string;
  imageFileName?: string;
  imageFileUrl?: string;
  detail?: string;
  objective?: string;
  award?: string;
  quota?: number;
  type: IncentiveType;
  awards?: IncentiveAward[];
  awardScheme: AwardScheme;
  enrolmentType: EnrolmentType;
  eligibleDesignations?: string[];
  eligibleAgents?: string[];
  excludedAgents?: string[];
  displayAll: boolean;
  agentListFileName?: string;
  highlights?: IncentiveHighlight[];
  rules?: IncentiveRule[];
  stickOnTop: boolean;
  templateType: EIncentiveTemplateType;
  templateBodyImg: any;
  templateHeaderImg: any;
  templateFooterImg: any;
  templateBody: string;
  multiOption: number | null;
  multiOptionName: string;
  isMultiOption: boolean;
  isLimitRegister: boolean;
  limitNumber: number | null;
  notificationType: ENotificationType;
  notificationCategory: string;
  notificationMsgTitle: string;
  notificationMsgContent: string;
  notificationPushContent: string;
  notificationPushTitle: string;
  receiverIncludeSA: boolean;
  receiverType: ETypeOfView;
  receiverUserFile: any;
};

export enum ENotificationType {
  INBOX = 'INBOX',
  PUSH = 'PUSH',
  PUSH_INBOX = 'PUSH_INBOX',
}

export enum ENotificationCategory {
  System = 'inbox_system',
  Performance = 'inbox_performance',
  Recruitment = 'inbox_recruitment',
  Training = 'inbox_training',
  Policy = 'inbox_policy',
  PulseLeads = 'inbox_leads',
  Sales = 'inbox_sales',
  Event = 'inbox_event',
}

export type IncentiveEnrolmentRecord = {
  code: string;
  name: string;
  agentCode: string;
  enrolmentDate: string;
  registrationRequired: string;
  startDate: string;
  endDate: string;
  registrationStatus: string;
};

export interface IncentiveTemplate {
  type: EIncentiveTemplateType;
  bodyImg?: any;
  headerImg?: any;
  footerImg?: any;
  body?: any;
}

export enum EIncentiveTemplateType {
  FIXED = 'FIXED',
  CUSTOMIZE = 'CUSTOMIZE',
}
