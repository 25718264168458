export enum ReportType {
  PRODUCTION_TARGET = "PF-RPT-PERF-001",
  INCENTIVE_RESULT = "PF-RPT-PERF-002",
  PRODUCTION_TARGET_MTD = "PF-RPT-PERF-003",
  ADBU_TARGET = "PF-RPT-PERF-004",
  NEWS_AGENTS="PF-RPT-NEWS-001",
  NEWS_COMMENTS="PF-RPT-NEWS-002",
  NEWS_INFO="PF-RPT-NEWS-003",
  NEWS_AGENT_ACTIVITY="PF-RPT-NEWS-004"
};

export type ReportItem = {
  _id: string;
  code: ReportType;
  status: string;
  parameters: Record<string, string>;
  requestedDate: string;
  startDate: string;
  completedDate: string;
  fileId: string;
  fileName: string;
};

export type ReportDownloadDetail = {
  url: string;
  fileName: string;
  contentType: string;
};

export type ReportDetail = {
  _id: string;
  code: string;
  parameters: Record<string, unknown>;
  status: string;
  requestedDate: string;
};


export type ReportActiveUserItem = {
  _id: string;
  agentCode: string,
  salesChannel: string,
  branchCode: string,
  functionName: string,
  officeCode: string,
  role: string,
  segment: string,
  unitCode: string
  total: string,
  createdAt: Date,
  updatedAt: Date,
};

export type ReportAppInstallmentItem = {
  _id: string;
  agentCode: string,
  salesChannel: string,
  branchCode: string,
  createdDate: Date,
  functionName: string,
  officeCode: string,
  role: string,
  segment: string,
  unitCode: string,
  total: string,
  installationDates: {date: string, version: string}[]
};
