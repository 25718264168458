import React, { FC, useState } from 'react';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { getDayEnd, getFirstDayOfMonth, getDayStart, getLastDayOfMonth } from '../../../../../common/utils/time-utils';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { PaginateListMore } from 'src/app/common/types/common-types';
import { useLang } from 'src/app/i18n';
import moment from 'moment';
import {
  fetchCandidateAssessmentReport,
  fetchCandidateAssessmentReportDownload,
} from '../../../network/candidateAssessmentReportCrud';
import { CandidateAssessmentReportListParams } from '../../../types/candidateAssessmentReportTypes';

type CandidateAssessmentReportProps = {} & ParamsProps;

const initialState: CandidateAssessmentReportListParams = {
  requestedDateFrom: getFirstDayOfMonth(new Date()),
  requestedDateTo: getLastDayOfMonth(new Date()),
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): CandidateAssessmentReportListParams => {
  return initialParams
    ? {
        requestedDateFrom: initialParams.requestedDateFrom
          ? getDayStart(new Date(initialParams.requestedDateFrom))
          : getFirstDayOfMonth(new Date()),
        requestedDateTo: initialParams.requestedDateTo
          ? getLastDayOfMonth(new Date(initialParams.requestedDateTo))
          : getLastDayOfMonth(new Date()),
        page: 1,
        limit: 5,
      }
    : initialState;
};

const CandidateAssessmentReport: FC<CandidateAssessmentReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilterValue = paramsInitiator(initialParams);
  const currentLanguage = useLang();

  const [filterState, setFilterState] = useState<CandidateAssessmentReportListParams>(defaultFilterValue);
  const [reportList, setReportList] = useState<PaginateListMore<any>>();
  const { isLoading, refreshData } = useDataProvider<PaginateListMore<any>>(
    async () => {
      try {
        return await fetchCandidateAssessmentReport(filterState, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvtDownload = () => {
    try {
      fetchCandidateAssessmentReportDownload(filterState, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('report.candidate.assessment.filter')}
        itemDef={[
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'requestedDateFrom',
            fieldTo: 'requestedDateTo',
            initialDateFrom: filterState.requestedDateFrom,
            initialDateTo: filterState.requestedDateTo,
            defaultDateFrom: defaultFilterValue.requestedDateFrom,
            defaultDateTo: defaultFilterValue.requestedDateTo,
            displayName: Translation('report.candidate.assessment.filter.assessDate'),
            isRequired: true,
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            requestedDateFrom: data.requestedDateFrom,
            requestedDateTo: data.requestedDateTo,
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.candidate.assessment.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvtDownload(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            keyIndex: 'AGNAME',
            displayName: 'AGNAME',
            align: 'center',
            renderData: (row) => row.firstName + ' ' + row.lastName,
          },
          {
            keyIndex: 'AGID',
            align: 'center',
            displayName: 'AGID',
            renderData: (row) => row.contractId,
          },
          {
            keyIndex: 'AGCLNAME',
            align: 'center',
            displayName: 'AGCLNAME',
            renderData: (row) => (row.contractName ? row.contractName : '-'),
          },
          {
            keyIndex: 'UNIT_CODE',
            align: 'center',
            displayName: 'UNIT_CODE',
            renderData: (row) => (row.leader ? (row.leader.unitPUM ? row.leader.unitPUM : row.leader.unitCode) : '-'),
          },
          {
            keyIndex: 'LEADER_CODE',
            align: 'center',
            displayName: 'LEADER_CODE',
            renderData: (row) => (row.leader ? row.leader.leaderCode : '-'),
          },
          {
            keyIndex: 'LEADER_NAME',
            align: 'center',
            displayName: 'LEADER_NAME',
            renderData: (row) => (row.leader ? row.leader.leaderName : '-'),
          },
          {
            keyIndex: 'AGE_FROM_25_TO_45',
            displayName: 'AGE FROM 21 TO 45',
            align: 'center',
            renderData: (row) =>
              row.recruitmentStandards ? (row.recruitmentStandards.isAgeValid ? 'YES' : 'NO') : '-',
          },
          {
            keyIndex: 'GRADUATED_HIGH_SCHOOL',
            align: 'center',
            displayName: 'GRADUATED HIGH SCHOOL',
            renderData: (row) =>
              row.recruitmentStandards ? (row.recruitmentStandards.isGraduatedHighSchool ? 'YES' : 'NO') : '-',
          },
          {
            keyIndex: 'EXPERIENCED_WORKING',
            align: 'center',
            displayName: 'EXPERIENCED WORKING',
            renderData: (row) =>
              row.recruitmentStandards ? (row.recruitmentStandards.isExperiencedWorking ? 'YES' : 'NO') : '-',
          },
          {
            keyIndex: 'MARRIED',
            align: 'center',
            displayName: 'MARRIED',
            renderData: (row) => (row.recruitmentStandards ? row.recruitmentStandards.isMarried : '-'),
          },
          {
            keyIndex: 'QSCORE',
            align: 'center',
            displayName: 'QSCORE',
            renderData: (row) => (row.totalScore ? row.totalScore : '-'),
          },
          {
            keyIndex: 'APPEARANCE_HEALTH',
            align: 'center',
            displayName: 'APPEARANCE/HEALTH',
            renderData: (row) =>
              row.generalReviews ? formatClassification(row.generalReviews.appearanceOrHealth) : '-',
          },
          {
            keyIndex: 'TECHNICAL_ABILITY',
            align: 'center',
            displayName: 'TECHNICAL ABILITY',
            renderData: (row) => (row.generalReviews ? formatClassification(row.generalReviews.technicalAbility) : '-'),
          },
          {
            keyIndex: 'COMUNICATION',
            align: 'center',
            displayName: 'COMUNICATION',
            renderData: (row) =>
              row.generalReviews ? formatClassification(row.generalReviews.communicationSkill) : '-',
          },
          {
            keyIndex: 'ATTITUDE',
            align: 'center',
            displayName: 'ATTITUDE',
            renderData: (row) => (row.generalReviews ? formatClassification(row.generalReviews.attitude) : '-'),
          },
          {
            keyIndex: 'COMMITMENT',
            align: 'center',
            displayName: 'COMMITMENT',
            renderData: (row) => (row.generalReviews ? formatClassification(row.generalReviews.commitment) : '-'),
          },
          {
            keyIndex: 'SOCIAL_RELATIONSHIP',
            align: 'center',
            displayName: 'SOCIAL RELATIONSHIP',
            renderData: (row) =>
              row.generalReviews ? formatClassification(row.generalReviews.socialRelationship) : '-',
          },
          {
            keyIndex: 'STATUS',
            align: 'center',
            displayName: 'STATUS',
            renderData: (row) =>
              row.isApproveByBDM
                ? row.isApproveByBDM === true
                  ? 'APPROVED'
                  : 'REJECT'
                : row.isApproveByAL !== null
                ? row.isApproveByAL === true
                  ? 'APPROVED'
                  : 'REJECT'
                : 'AWAITING BDM ASSESS',
          },
          {
            keyIndex: 'ASSESS_DATE',
            align: 'center',
            displayName: 'ASSESS_DATE',
            renderData: (row) =>
              row.BDMAssessAt
                ? moment(row.BDMAssessAt).format('DD/MM/YYYY')
                : row.createdAt
                ? moment(row.createdAt).format('DD/MM/YYYY')
                : '-',
          },
          {
            keyIndex: 'REASSESS',
            align: 'center',
            displayName: 'REASSESS',
            renderData: (row) => (row.isNeedBDMReAssess ? 'YES' : 'NO'),
          },
          {
            keyIndex: 'REASSESS_DATE',
            align: 'center',
            displayName: 'REASSESS_DATE',
            renderData: (row) => (row.reBDMAssessDate ? moment(row.reBDMAssessDate).format('DD/MM/YYYY') : '-'),
          },
          {
            keyIndex: 'ASSESS_BY_ROLE',
            align: 'center',
            displayName: 'ASSESS BY ROLE',
            renderData: (row) =>
              row.isAssessByAL === null && row.isAssessByBDM === false ? '-' : row.isAssessByAL ? 'AL' : 'BDM',
          },
          {
            keyIndex: 'ASSESS_BY_DESC',
            align: 'center',
            displayName: 'ASSESS BY DESC',
            renderData: (row) =>
              row.bdmInfo
                ? row.bdmInfo.bdmName !== null
                  ? row.bdmInfo.bdmName
                  : row.isAssessByAL === null && row.isAssessByBDM === false
                  ? '-'
                  : row.assessByDesc
                : '-',
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default CandidateAssessmentReport;

function formatClassification(value: string | null) {
  switch (value) {
    case 'KHONG DAT':
      return 'KHÔNG ĐẠT';
    case 'RAT TOT':
      return 'RẤT TỐT';
    case 'TOT':
      return 'TỐT';
    case 'CHAP NHAN':
      return 'CHẤP NHẬN';
    case 'TU CHOI':
      return 'TỪ CHỐI';
    default:
      return value;
  }
}
