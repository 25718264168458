import React, { FC, useState } from 'react';
import { useDataProvider } from 'src/app/common/utils';
import {
  fetchNewsRegisteredCandidateReport,
  fetchReportDownload,
} from '../../../network/newRegisteredCandidateReportCrud';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { getDayEnd, getDisplayDate, getFirstDayOfMonth, getDayStart } from '../../../../../common/utils/time-utils';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { PaginateListMore } from 'src/app/common/types/common-types';
import { ReportListParams, ReportItem } from '../../../types/newRegisteredCandidateTypes';

type NewRegisteredCandidateReportProps = {} & ParamsProps;

const initialState: ReportListParams = {
  registerDateFrom: getFirstDayOfMonth(new Date()),
  registerDateTo: getDayEnd(new Date()),
  agentCode: '',
  agentName: '',
  agentLeaderCode: '',
  agentLeaderName: '',
  candidateName: '',
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): ReportListParams => {
  return initialParams
    ? {
        registerDateFrom: initialParams.registerDateFrom
          ? getDayStart(new Date(initialParams.registerDateFrom))
          : getFirstDayOfMonth(new Date()),
        registerDateTo: initialParams.registerDateTo
          ? getDayEnd(new Date(initialParams.registerDateTo))
          : getDayEnd(new Date()),
        agentCode: '',
        agentName: '',
        agentLeaderCode: '',
        agentLeaderName: '',
        candidateName: '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const NewRegisteredCandidateReport: FC<NewRegisteredCandidateReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilterValue = paramsInitiator(initialParams);

  const [filterState, setFilterState] = useState<ReportListParams>(defaultFilterValue);
  const [reportList, setReportList] = useState<PaginateListMore<ReportItem>>();
  const { isLoading, refreshData } = useDataProvider<PaginateListMore<ReportItem>>(
    async () => {
      try {
        return await fetchNewsRegisteredCandidateReport(filterState, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvtDownload = () => {
    try {
      fetchReportDownload(filterState, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('report.recruitment.newRegisteredCandidate.filter')}
        itemDef={[
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'registerDateFrom',
            fieldTo: 'registerDateTo',
            initialDateFrom: filterState.registerDateFrom,
            initialDateTo: filterState.registerDateTo,
            defaultDateFrom: defaultFilterValue.registerDateFrom,
            defaultDateTo: defaultFilterValue.registerDateTo,
            displayName: Translation('report.recruitment.newRegisteredCandidate.label.registerDate'),
            required: true,
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('report.recruitment.newRegisteredCandidate.label.agentCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentName',
            initialValue: filterState.agentName,
            displayName: Translation('report.recruitment.newRegisteredCandidate.label.agentName'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentLeaderCode',
            initialValue: filterState.agentLeaderCode,
            displayName: Translation('report.recruitment.newRegisteredCandidate.label.agentLeaderCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentLeaderName',
            initialValue: filterState.agentLeaderName,
            displayName: Translation('report.recruitment.newRegisteredCandidate.label.agentLeaderName'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'candidateName',
            initialValue: filterState.candidateName,
            displayName: Translation('report.recruitment.newRegisteredCandidate.label.candidateName'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            agentCode: data.agentCode,
            agentName: data.agentName,
            agentLeaderCode: data.agentLeaderCode,
            agentLeaderName: data.agentLeaderName,
            candidateName: data.candidateName,
            registerDateFrom: data.registerDateFrom,
            registerDateTo: data.registerDateTo,
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.recruitment.newRegisteredCandidate.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvtDownload(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => row._id || '-',
          },
          {
            keyIndex: 'candidateName',
            displayName: 'Candidate Name',
            renderData: (row) => row.fullName || '-',
          },
          {
            keyIndex: 'phome',
            align: 'center',
            displayName: 'Phone Number',
            renderData: (row) => row.phone || '-',
          },
          {
            keyIndex: 'agentCode',
            align: 'center',
            displayName: 'Shared by AL/AG Code',
            renderData: (row) => row.agentCode || '-',
          },
          {
            keyIndex: 'agentName',
            displayName: 'Shared by AL/AG Name',
            renderData: (row) => row.agentName || '-',
          },
          {
            keyIndex: 'leaderCode',
            align: 'center',
            displayName: 'AL Code',
            renderData: (row) => row.leaderCode || '-',
          },
          {
            keyIndex: 'leaderName',
            displayName: 'AL Name',
            renderData: (row) => row.leaderName || '-',
          },
          {
            keyIndex: 'unitCode',
            align: 'center',
            displayName: 'Unit Code',
            renderData: (row) => row.agentUnitCode || '-',
          },
          {
            keyIndex: 'officeCode',
            align: 'center',
            displayName: 'Office Code',
            renderData: (row) => row.agentOfficeCode || '-',
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: 'Date Of Registration',
            renderData: (row) => getDisplayDate(row.createdAt, 'DD-MM-YYYY'),
          },
          {
            keyIndex: 'callStatus',
            align: 'center',
            displayName: 'Call Action(YES/NO)',
            renderData: (row) => (row.callStatus ? 'YES' : 'NO'),
          },
          {
            keyIndex: 'callDate',
            align: 'center',
            displayName: 'Date Of Call',
            renderData: (row) => (row.calledDate ? getDisplayDate(row.calledDate, 'DD-MM-YYYY') : '-'),
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default NewRegisteredCandidateReport;
