import { CommonOperatorEnum, IncentiveAward, IncentiveAwardCriteria } from '../../../../types/incentive-types';

type EditableTier = {
  editable: boolean;
  tierCode: string;
  name: string;
  error: boolean;
};

export type AwardFormState = {
  awards: IncentiveAward[];
  editableTiers: EditableTier[];
};

type AddTierAction = {
  type: 'ADD_TIER';
};

type RemoveTierAction = {
  type: 'REMOVE_TIER';
  payload: {
    awardIndex: number;
  };
};

type ModifyTierAction = {
  type: 'MODIFY_TIER';
  payload: {
    awardIndex: number;
    value: IncentiveAward;
  };
};

type SetEditableTierAction = {
  type: 'SET_EDITABLE_TIER';
  payload: {
    awardIndex: number;
    value: EditableTier;
  };
};

type AddCriteriaAction = {
  type: 'ADD_CRITERIA';
  payload: {
    awardIndex: number;
  };
};

type RemoveCriteriaAction = {
  type: 'REMOVE_CRITERIA';
  payload: {
    awardIndex: number;
    requirementIndex: number;
  };
};

type AddItemAction = {
  type: 'ADD_ITEM';
  payload: {
    awardIndex: number;
    requirementIndex: number;
  };
};

type RemoveItemAction = {
  type: 'REMOVE_ITEM';
  payload: {
    awardIndex: number;
    requirementIndex: number;
    itemIndex: number;
  };
};

type ModifyItemAction = {
  type: 'MODIFY_ITEM';
  payload: {
    awardIndex: number;
    requirementIndex: number;
    itemIndex: number;
    value: IncentiveAwardCriteria;
  };
};

export type AwardFormAction =
  | AddTierAction
  | RemoveTierAction
  | ModifyTierAction
  | SetEditableTierAction
  | AddCriteriaAction
  | RemoveCriteriaAction
  | AddItemAction
  | RemoveItemAction
  | ModifyItemAction;

export const awardFormReducer = (state: AwardFormState, action: AwardFormAction): AwardFormState => {
  let newAwards = [...state.awards];
  const newEditableTiers = [...state.editableTiers];
  switch (action.type) {
    case 'ADD_TIER':
      newAwards.push({
        tier: state.awards.length + 1,
        tierCode: `T${state.awards.length + 1}`,
        name: `Tier ${state.awards.length + 1}`,
        requirements: [
          {
            name: 'Criteria 1',
            names: {
              vi: 'Điều kiện 1',
              en: 'Criteria 1',
            },
            items: [
              {
                keyId: '',
                operator: CommonOperatorEnum.GREATER_THAN_EQUAL,
                value: '',
                isShown: true,
              },
            ],
          },
        ],
      });
      newEditableTiers.push({
        editable: false,
        tierCode: `T${state.awards.length + 1}`,
        name: `Tier ${state.awards.length + 1}`,
        error: false,
      });
      return {
        ...state,
        awards: newAwards,
        editableTiers: newEditableTiers,
      };
    case 'REMOVE_TIER':
      newAwards.splice(action.payload.awardIndex, 1);
      newAwards = newAwards.map((award, index) => ({ ...award, tier: index + 1 }));
      newEditableTiers.splice(action.payload.awardIndex, 1);
      return {
        ...state,
        awards: newAwards,
        editableTiers: newEditableTiers,
      };
    case 'MODIFY_TIER':
      newAwards[action.payload.awardIndex] = action.payload.value;
      return {
        ...state,
        awards: newAwards,
      };
    case 'SET_EDITABLE_TIER':
      newEditableTiers[action.payload.awardIndex] = action.payload.value;
      return {
        ...state,
        editableTiers: newEditableTiers,
      };
    case 'ADD_CRITERIA':
      newAwards[action.payload.awardIndex].requirements.push({
        name: `Criteria ${newAwards[action.payload.awardIndex].requirements.length + 1}`,
        names: {
          vi: `Điều kiện ${newAwards[action.payload.awardIndex].requirements.length + 1}`,
          en: `Criteria ${newAwards[action.payload.awardIndex].requirements.length + 1}`,
        },
        items: [
          {
            keyId: '',
            operator: CommonOperatorEnum.GREATER_THAN_EQUAL,
            value: '',
            isShown: true,
          },
        ],
      });
      return {
        ...state,
        awards: newAwards,
      };
    case 'REMOVE_CRITERIA':
      newAwards[action.payload.awardIndex].requirements.splice(action.payload.requirementIndex, 1);
      newAwards[action.payload.awardIndex].requirements = newAwards[action.payload.awardIndex].requirements.map(
        (requirement, index) => ({ ...requirement, name: `Criteria ${index + 1}` }),
      );
      return {
        ...state,
        awards: newAwards,
      };
    case 'ADD_ITEM':
      newAwards[action.payload.awardIndex].requirements[action.payload.requirementIndex].items.push({
        keyId: '',
        operator: CommonOperatorEnum.GREATER_THAN_EQUAL,
        value: '',
        isShown: true,
      });
      return {
        ...state,
        awards: newAwards,
      };
    case 'REMOVE_ITEM':
      newAwards[action.payload.awardIndex].requirements[action.payload.requirementIndex].items.splice(
        action.payload.itemIndex,
        1,
      );
      return {
        ...state,
        awards: newAwards,
      };
    case 'MODIFY_ITEM':
      newAwards[action.payload.awardIndex].requirements[action.payload.requirementIndex].items[
        action.payload.itemIndex
      ] = action.payload.value;
      return {
        ...state,
        awards: newAwards,
      };
    default:
      return state;
  }
};
