import React, { FC } from "react";
import { getDisplayDate } from "../../../../../../common/utils/time-utils";
import { PaginateList } from "../../../../../../common/types/common-types";
import { useIntl } from "react-intl";
import PruTable from "../../../../../../common/components/PruTable/PruTable";
import { useHistory } from "react-router-dom";
import {
  MdrtPassportBackgroundFormMode,
  MdrtPassportBackgroundItem,
} from "../../../../types/mdrt-passport-background-types";
import {
  MDRT_HUB_BASE_PATH,
  MDRT_PASSPORT_BACKGROUND_BASE_PATH,
} from "src/app/modules/MdrtHub/constants";

type MdrtPassportBackgroundListProps = {
  isLoading: boolean;
  itemList?: PaginateList<MdrtPassportBackgroundItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

const MdrtPassportBackgroundList: FC<MdrtPassportBackgroundListProps> = ({
  isLoading,
  itemList,
  onRefresh,
  onChangePage,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <>
      <PruTable
        title={Translation("mdrtHub.mdrtPassportBackground.listing")}
        disableBulkSelect
        headerBtnDef={[]}
        operationDef={[
          {
            title: Translation("app.button.edit"),
            tooltipText: "Edit MDRT",
            onClick: (row) =>
              history.push(
                `${MDRT_HUB_BASE_PATH}${MDRT_PASSPORT_BACKGROUND_BASE_PATH}/edit/${row._id}`,
                { type: MdrtPassportBackgroundFormMode.EDIT }
              ),
            condition: () => true,
          },
        ]}
        columnDef={[
          {
            keyIndex: "fileName",
            displayName: Translation(
              "mdrtHub.mdrtPassportBackground.list.fileName"
            ),
            renderData: (row) =>
              row?.backgroundImage?.fileName
                ? row.backgroundImage?.fileName
                : "",
          },
          {
            keyIndex: "uploadType",
            align: "center",
            displayName: Translation(
              "mdrtHub.mdrtPassportBackground.list.uploadType"
            ),
            renderData: (row) => {
              if (row.type) {
                let tmp = row.type.split("-");
                return tmp.reduce(
                  (value, current) =>
                    value +
                    " " +
                    current.charAt(0).toUpperCase() +
                    current.slice(1),
                  ""
                );
              } else {
                return "";
              }
            },
          },
          {
            keyIndex: "lastUpdatedDate",
            align: "center",
            displayName: Translation(
              "mdrtHub.mdrtPassportBackground.list.lastUpdatedDate"
            ),
            renderData: (row) =>
              row.updatedAt
                ? getDisplayDate(new Date(row.updatedAt), "YYYY-MM-DD HH:mm:ss")
                : "",
          },
          {
            keyIndex: "lastUpdatedBy",
            align: "center",
            displayName: Translation(
              "mdrtHub.mdrtPassportBackground.list.lastUpdatedBy"
            ),
            renderData: (row) => (row.updatedBy ? row.updatedBy : ""),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={itemList?.items}
        totalPages={itemList?.totalPages}
        totalRecords={itemList?.total}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default MdrtPassportBackgroundList;
