import React from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1040,
    width: '100vw',
    height: '100vh',
    background: '#000',
    opacity: '0.5',
  },
  modalWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1050,
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    outline: 0,
    justifyContent: 'center',
    alignItem: 'center',
    display: 'flex',
  },
  modal: {
    zIndex: 100,
    background: 'white',
    position: 'relative',
    margin: '16.75rem auto',
    borderRadius: '3px',
    padding: '2rem',
    width: '700px',
    maxHeight: '400px',
    overflowY: 'scroll',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  modalCloseButton: {
    fontSize: '2.4rem',
    fontWeight: 700,
    lineHeight: 1,
    cursor: 'pointer',
    border: 'none',
    background: '#DE1127',
    marginBottom: '10px',
    borderRadius: '10px',
    height: '40px',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100px',
  },
}));

const Modal = ({ isShowing, hide, element }: { isShowing: boolean; hide: any; element: any }) => {
  const { classes } = useStyles();

  return isShowing
    ? ReactDOM.createPortal(
        <>
          <div className={classes.modalOverlay} />
          <div className={classes.modalWrapper} aria-modal aria-hidden tabIndex={-1} role="dialog">
            <div className={classes.modal}>
              <div className={classes.modalHeader}>
                <button
                  type="button"
                  className={classes.modalCloseButton}
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={hide}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div>{element}</div>
            </div>
          </div>
        </>,
        document.body,
      )
    : null;
};

export default Modal;
