import { PaginateListMore } from '../../../common/types/common-types';
import { apiClient, apiErrorHandler } from 'src/app/common/network';
import { Dispatch } from 'react';
import { ICustomerSurveyItem, IReportFilter, ITotalCustomerSurvey } from '../types/customer-survey-types';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

export const fetchCustomerSurveyReport = async (
  params: IReportFilter,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<ICustomerSurveyItem>> => {
  const url = `${ADMIN_URL}/report/customer-survey/list`;

  return apiClient
    .post(url, params)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchTotalRecordCustomerSurveyReport = async (
  params: IReportFilter,
  dispatch?: Dispatch<any>,
): Promise<ITotalCustomerSurvey> => {
  const url = `${ADMIN_URL}/report/customer-survey/total`;

  return apiClient
    .post(url, params)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCustomerSurveyReportFile = (params: IReportFilter, dispatch?: Dispatch<any>): void => {
  const url = `${ADMIN_URL}/report/customer-survey/export`;

  apiClient
    .post(url, params, { responseType: 'text' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'customer-survey-report-' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
