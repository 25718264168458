import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import { isPermitted } from 'src/app/common/utils';
import {
  AGENCY_CAMPAIGN_TITLE,
  AGENCY_CAMPAIGN_CAMPAIGN_TYPE_TITLE,
  AGENCY_CAMPAIGN_APPROVAL_TITLE,
  AGENCY_CAMPAIGN_STAFF_CAMPAIGN_TITLE,
  AGENCY_CAMPAIGN_BASE_PATH,
  AGENCY_CAMPAIGN_CAMPAIGN_TYPE_PATH,
  AGENCY_CAMPAIGN_APPROVAL_PATH,
  AGENCY_CAMPAIGN_STAFF_CAMPAIGN_PATH,
  AGENCY_CAMPAIGN_CAMPAIGN_TYPE_NAMESPACE,
  AGENCY_CAMPAIGN_APPROVAL_NAMESPACE,
  AGENCY_CAMPAIGN_STAFF_CAMPAIGN_NAMESPACE,
  AdminAgencyCampaignPermissionType,
  AGENCY_CAMPAIGN_LM_DASHBOARD_TITLE,
  AGENCY_CAMPAIGN_LM_DASHBOARD_PATH,
  AGENCY_CAMPAIGN_BDM_DASHBOARD_PATH,
  AGENCY_CAMPAIGN_LM_DASHBOARD_NAMESPACE,
  AGENCY_CAMPAIGN_BDM_DASHBOARD_TITLE,
  AGENCY_CAMPAIGN_BDM_DASHBOARD_NAMESPACE,
} from './constants';
import CampaignTypeRoutes from './pages/CampaignType/CampaignTypeRoutes';
import ApprovalRoutes from './pages/Approval/ApprovalRoutes';
import StaffCampaignRoutes from './pages/StaffCampaign/CampaignRoutes';
import LMDashboardRoutes from './pages/LMDashboard/DashboardRoutes';
import BDMDashboardRoutes from './pages/BDMDashboard/DashboardRoutes';
export const agencyCampaignModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });

  return {
    title: Translation(AGENCY_CAMPAIGN_TITLE),
    path: AGENCY_CAMPAIGN_BASE_PATH,
    icon: '/media/svg/icons/Devices/Diagnostics.svg',
    enableRead: isPermitted(Object.values(AdminAgencyCampaignPermissionType), permissions),
    child: [
      {
        title: Translation(AGENCY_CAMPAIGN_CAMPAIGN_TYPE_TITLE),
        path: `${AGENCY_CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_CAMPAIGN_TYPE_PATH}`,
        namespace: AGENCY_CAMPAIGN_CAMPAIGN_TYPE_NAMESPACE,
        component: CampaignTypeRoutes,
        enableRead: isPermitted(
          [
            AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_CAMPAIGN_TYPE_READ,
            AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_CAMPAIGN_TYPE_CREATE,
            AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_CAMPAIGN_TYPE_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted(
          [AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_CAMPAIGN_TYPE_CREATE],
          permissions,
        ),
        enableUpdate: isPermitted(
          [AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_CAMPAIGN_TYPE_UPDATE],
          permissions,
        ),
      },
      {
        title: Translation(AGENCY_CAMPAIGN_APPROVAL_TITLE),
        path: `${AGENCY_CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_APPROVAL_PATH}`,
        namespace: AGENCY_CAMPAIGN_APPROVAL_NAMESPACE,
        component: ApprovalRoutes,
        enableRead: isPermitted(
          [
            AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_APPROVAL_READ,
            AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_APPROVAL_CREATE,
            AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_APPROVAL_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_APPROVAL_CREATE], permissions),
        enableUpdate: isPermitted([AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_APPROVAL_UPDATE], permissions),
      },
      {
        title: Translation(AGENCY_CAMPAIGN_STAFF_CAMPAIGN_TITLE),
        path: `${AGENCY_CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_STAFF_CAMPAIGN_PATH}`,
        namespace: AGENCY_CAMPAIGN_STAFF_CAMPAIGN_NAMESPACE,
        component: StaffCampaignRoutes,
        enableRead: isPermitted(
          [
            AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_STAFF_CAMPAIGN_READ,
            AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_STAFF_CAMPAIGN_CREATE,
            AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_STAFF_CAMPAIGN_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted(
          [AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_STAFF_CAMPAIGN_CREATE],
          permissions,
        ),
        enableUpdate: isPermitted(
          [AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_STAFF_CAMPAIGN_UPDATE],
          permissions,
        ),
      },
      {
        title: Translation(AGENCY_CAMPAIGN_LM_DASHBOARD_TITLE),
        path: `${AGENCY_CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_LM_DASHBOARD_PATH}`,
        namespace: AGENCY_CAMPAIGN_LM_DASHBOARD_NAMESPACE,
        component: LMDashboardRoutes,
        // enableRead : true,
        enableRead: isPermitted(
          [
            AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_LM_DASHBOARD_READ,
            AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_LM_DASHBOARD_CREATE,
            AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_LM_DASHBOARD_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_LM_DASHBOARD_CREATE], permissions),
        enableUpdate: isPermitted([AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_LM_DASHBOARD_UPDATE], permissions),
      },
      {
        title: Translation(AGENCY_CAMPAIGN_BDM_DASHBOARD_TITLE),
        path: `${AGENCY_CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_BDM_DASHBOARD_PATH}`,
        namespace: AGENCY_CAMPAIGN_BDM_DASHBOARD_NAMESPACE,
        component: BDMDashboardRoutes,
        // enableRead : true,
        enableRead: isPermitted(
          [
            AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_BDM_DASHBOARD_READ,
            AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_BDM_DASHBOARD_CREATE,
            AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_BDM_DASHBOARD_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted(
          [AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_BDM_DASHBOARD_CREATE],
          permissions,
        ),
        enableUpdate: isPermitted(
          [AdminAgencyCampaignPermissionType.AGENCY_CAMPAIGN_BDM_DASHBOARD_UPDATE],
          permissions,
        ),
      },
    ],
  };
};
