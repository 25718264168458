import { FC } from 'react';
import { map } from 'lodash';
import { useIntl } from 'react-intl';
import {
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { FormMode } from 'src/app/common/types';
import { ComponentProps } from 'src/app/common/components/pru-stepped-form';
// import { TargetApplicantMode, TargetApplicantSectionV2 } from 'src/app/common/components/target-applicant-section-v2';
import ButtonDialog from 'src/app/common/components/ButtonDialog';
import { EventPublishStatusEnum, PublishAgentEnum, EventFormCommonProps } from 'src/app/modules/event-v2/types';
import { useCommonFormStyles } from '../../../common';
import { usePublishStyles } from './publish-form.style';
import { usePublishForm } from './publish-form.hook';
import CheckBoxUserGroup from 'src/app/common/components/CheckBoxUserGroup';
import { typeOfViewOptions } from 'src/app/common/types/view-types';

type EventPublishFormProps = ComponentProps<EventFormCommonProps>;

export const EventPublishForm: FC<EventPublishFormProps> = ({ formCommonProps, ...rest }) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const { classes } = usePublishStyles();
  const { classes: commonFormClasses } = useCommonFormStyles();
  const { formMode, isPublished, disableEdit } = formCommonProps;
  const {
    formState,
    showConfirmDialog,
    errorState,
    allowCancel,
    formDispatch,
    setShowConfirmDialog,
    onDismissErrorHandler,
    typeOfViewsRules,
    onSubmit,
    onPublish,
  } = usePublishForm({
    formCommonProps,
    ...rest,
  });
  const viewMode = formMode === FormMode.CREATE || formMode === FormMode.VIEW;

  return (
    <>
      {showConfirmDialog && (
        <Dialog open={showConfirmDialog} onClose={() => setShowConfirmDialog(false)}>
          <div className={classes.dialogContainer}>{Translation('event.form.publish_today_reminder')}</div>
          <div className={classes.dialogButtonContainer}>
            <Button variant="contained" color="inherit" onClick={() => setShowConfirmDialog(false)}>
              {Translation('global.text.no')}
            </Button>
            <Button variant="contained" color="secondary" onClick={() => onSubmit(EventPublishStatusEnum.PUBLISHED)}>
              {Translation('global.text.yes')}
            </Button>
          </div>
        </Dialog>
      )}
      <div className={commonFormClasses.container}>
        <div>
          <div className={classes.fieldContainer}>
            <FormControl
              disabled={viewMode || disableEdit}
              className={commonFormClasses.radioGroupContainer}
              error={errorState.mandatory.pubAgent}
            >
              <FormLabel className={commonFormClasses.radioGroupLabel}>
                {Translation('event.form.publish_to_agent')}
                <span className={commonFormClasses.mandatory}>*</span>
              </FormLabel>
              <RadioGroup
                className={commonFormClasses.radioGroup}
                value={formState.pubAgent || ''}
                onChange={(e) => {
                  const dismissFields = [
                    'pubAgent',
                    'pubAgentConfig-targetAgentBy',
                    'pubAgentConfig-eligibleDesignations',
                    'pubAgentConfig-eligibleZones',
                  ];
                  onDismissErrorHandler(dismissFields, e.target.value);
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'pubAgent', value: e.target.value } });
                }}
              >
                {map(PublishAgentEnum, (option) => (
                  <FormControlLabel
                    key={`pubAgent-${option}`}
                    control={<Radio />}
                    label={Translation(`event.form.publish_to_agent.${option.toLowerCase()}`)}
                    value={option}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {errorState.mandatory.pubAgent && (
              <FormHelperText className={commonFormClasses.radioGroupMargin} error={errorState.mandatory.pubAgent}>
                {MANDATORY_FIELD_ERROR_TEXT}
              </FormHelperText>
            )}
          </div>
          {/* {formState.pubAgent === PublishAgentEnum.TARGET && (
            <TargetApplicantSectionV2
              module="event"
              mode={TargetApplicantMode.RADIO}
              isDisabled={viewMode || disableEdit}
              errorState={errorState}
              errorFieldPrefix="pubAgentConfig-"
              onDismissErrorHandler={onDismissErrorHandler}
              initialFormState={formState.pubAgentConfig}
              onChange={(newFormState) =>
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: {
                    field: 'pubAgentConfig',
                    value: newFormState,
                  },
                })
              }
            />
          )} */}
          {/* Event v2 PVA */}
          {formState.pubAgent === PublishAgentEnum.TARGET && (
            <>
              <FormControl
                className={commonFormClasses.radioGroupContainer}
                error={errorState.mandatory.typeOfView}
              >
                <FormLabel className={commonFormClasses.radioGroupLabel}>
                  {Translation('recruitment.form.typeOfView')}
                  <span className={commonFormClasses.mandatory}>*</span>
                </FormLabel>
                <CheckBoxUserGroup
                  disabled={viewMode || disableEdit}
                  options={typeOfViewOptions}
                  aria-label="viewType"
                  moduleType={'EventV2'}
                  value={formState.typeOfViews}
                  isGetAgentList={true}
                  onChange={(e) => {
                    console.log(e);

                    if (!typeOfViewsRules(e)) {
                      onDismissErrorHandler(['typeOfViews'], e);
                    }
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'typeOfViews', value: e },
                    });
                  }}
                ></CheckBoxUserGroup>
              </FormControl>
              {errorState.mandatory.typeOfViews && (
                <FormHelperText className={commonFormClasses.radioGroupMargin} error={errorState.mandatory.typeOfViews}>
                  {MANDATORY_FIELD_ERROR_TEXT}
                </FormHelperText>
              )}
            </>

            // <Form.Item
            //   name={'typeOfViews'}
            //   label={Translation('recruitment.form.typeOfView')}
            //   rules={DEFAULT_TYPEOFVIEWS_RULES}
            //   labelTop={true}
            //   shouldUpdate
            // >
            //   <CheckBoxUserGroup
            //     disabled={viewMode || disableEdit}
            //     options={typeOfViewOptions}
            //     aria-label="viewType"
            //     moduleType={'EventV2'}
            //   ></CheckBoxUserGroup>
            // </Form.Item>
          )}
        </div>
        {!viewMode && (!disableEdit || allowCancel) && (
          <div className={commonFormClasses.footerContainer}>
            {isPublished && (
              <>
                {allowCancel && (
                  <ButtonDialog
                    dialogTxt={Translation('event.form.cancel_unpublish_reminder')}
                    btnColor="inherit"
                    btnTxt={Translation('app.button.cancel_unpublish')}
                    onOk={() => onSubmit(EventPublishStatusEnum.UNPUBLISHED)}
                  />
                )}
                {!disableEdit && (
                  <Button variant="contained" color="secondary" onClick={() => onSubmit()}>
                    {Translation('app.button.save')}
                  </Button>
                )}
              </>
            )}
            {!isPublished && (
              <>
                <Button variant="contained" color="secondary" onClick={() => onSubmit()}>
                  {Translation('app.button.saveAsDraft')}
                </Button>
                <Button variant="contained" color="secondary" onClick={() => onPublish()}>
                  {Translation('component.formLabel.publish-now')}
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};
