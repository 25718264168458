import React, { FC, useReducer, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
// import { assignSurvingServey } from '../../../network/surveyCrud'
import { useIntl } from 'react-intl';
import { Button, TextField } from '@mui/material';
import useFilterStyles from 'src/app/common/styles/filter-styles';
import { toAbsoluteUrl } from 'src/app/common/utils';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { createBlob, getBlob } from 'src/app/common/network';
import Form, { useForm } from 'src/app/common/components/Form';
import { assignCandidate, updateCandidateAssessmentPV } from '../../../network/candidateCrud';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { makeStyles } from 'tss-react/mui';
import ImageUploaderMultiple from 'src/app/common/components/ImageUploaderMultiple';
import { Translation } from 'src/app/i18n';
type SurveyListingPageProps = {} & ParamsProps;

const useStyles = makeStyles()((theme) => ({
  exampleFile: {
    color: 'gray',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  sectionMargin: {
    width: '100%',
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  fieldContainer: {
    width: 150,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
    width: '150px',
  },
  mandatory: {
    color: 'red',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const UpdateCandidateAssessmentDetailPage: FC<SurveyListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const { classes } = useStyles();
  const filterClasses = useFilterStyles().classes;
  const dispatch = useDispatch();

  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);

  const DEFAULT_REQUIRED_RULES = [{ required: true, message: Translation('component.form-required-text') }];

  const INPUT_REQUIRED_RULES = [
    { required: true, whitespace: true, message: Translation('component.form-required-text') },
  ];

  const [form] = useForm();

  const commonClasses = useCommonStyles().classes;

  const submit = async () => {
    const formData = await form.getFieldsValue(true);

    const isValid = await form.validateFields().catch((res) => {
      setIsDisabledSubmit((prev) => false);
      return false;
    });

    if (!isValid) return;

    setIsDisabledSubmit((prev) => false);

    await updateCandidateAssessmentPV(formData)
      .then((res) => {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: Translation('global.submit.success'),
              content: `Upload successful!`,
            },
          ]),
        ),
          form.resetFields();
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: Translation('global.submit.fail'),
              content: 'Upload failed!',
            },
          ]),
        );
      });
  };

  return (
    <Form form={form}>
      <div style={{ marginBottom: 20 }} className={filterClasses.root}>
        <div style={{ marginBottom: 5 }} className="PruFilter-header-container">
          <div className={classes.sectionMargin}>
            <div className={classes.divideMargin}>
              <div className={commonClasses.header}>Update Candidate Assessment</div>
            </div>
            <div className="tw-pl-5">
              <Form.Item name={'eRef'} label="Số hồ sơ" rules={INPUT_REQUIRED_RULES}>
                <TextField fullWidth={true} margin="dense" variant="outlined" inputProps={{ maxLength: 200 }} />
              </Form.Item>
              <Form.Item name={'identityNumber'} label="Số CCCD/CMND" rules={INPUT_REQUIRED_RULES}>
                <TextField fullWidth={true} margin="dense" variant="outlined" inputProps={{ maxLength: 12 }} />
              </Form.Item>
              <Form.Item name={'fullName'} label="Họ và tên" rules={INPUT_REQUIRED_RULES}>
                <TextField fullWidth={true} margin="dense" variant="outlined" inputProps={{ maxLength: 200 }} />
              </Form.Item>
              <Form.Item
                label="Choose Image Import"
                name={'images'}
                rules={DEFAULT_REQUIRED_RULES}
                getValueFromEvent={(value) => value}
              >
                <ImageUploaderMultiple maxImagesNumber={3} />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className={classes.footerContainer}>
          <Button
            style={{
              minWidth: '136px',
            }}
            variant="contained"
            color="secondary"
            disabled={isDisabledSubmit}
            onClick={(e: any) => {
              e.preventDefault();
              setIsDisabledSubmit((prev) => true);
              submit();
            }}
          >
            {isDisabledSubmit ? (
              <div className="spinner-border text-danger mr-2" role="status"></div>
            ) : (
              <div> SUBMIT </div>
            )}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default UpdateCandidateAssessmentDetailPage;
