import React from 'react';
import { getDisplayDate } from 'src/app/common/utils/time-utils';

interface tableConfigType {
  title: string;
  prop: string;
  align?: any;
  render?: any;
  minWidth?: number;
}

const tableConfig = (Translation: any, i18ConfigData: any): tableConfigType[] => {
  if (i18ConfigData.length === 0) {
    return [];
  }

  const INVITATION_STATUS: any = {
    ALL: Translation('event.publish.status.All.label'),
    EXPIRED: Translation('event.publish.status.Expired.label'),
    PUBLISHED: Translation('event.publish.status.Published.label'),
    UNPUBLISHED: Translation('event.publish.status.Unpublished.label'),
  };

  return [
    {
      title: Translation('event.invitation.table.header.invitationName'),
      prop: 'eventName',
      render: (row: any) => <span>{row.eventName}</span>,
    },
    {
      title: Translation('event.invitation.table.header.location'),
      prop: 'location',
      render: (row: any) => <span>{row.location}</span>,
    },
    {
      title: Translation('event.invitation.table.header.status'),
      prop: 'status',
      render: (row: any) => <span>{INVITATION_STATUS[row.status] || ''}</span>,
    },
    {
      title: Translation('event.invitation.table.header.startDate'),
      prop: 'durationStartDate',
      render: (row: any) => (
        <span>{row.durationStartDate ? getDisplayDate(row.durationStartDate, 'YYYY-MM-DD HH:mm:ss') : ''}</span>
      ),
    },
    {
      title: Translation('event.invitation.table.header.endDate'),
      prop: 'durationEndDate',
      render: (row: any) => (
        <span>{row.durationEndDate ? getDisplayDate(row.durationEndDate, 'YYYY-MM-DD HH:mm:ss') : ''}</span>
      ),
    },
    {
      title: Translation('event.invitation.table.header.createdDate'),
      prop: 'createdDate',
      render: (row: any) => (
        <span>{row.createdDate ? getDisplayDate(row.createdDate, 'YYYY-MM-DD HH:mm:ss') : ''}</span>
      ),
    },
    {
      title: Translation('event.invitation.table.header.publishDate'),
      prop: 'publicTime',
      render: (row: any) => (
        <span>{row?.publicTime?.specificTime ? getDisplayDate(row?.publicTime?.specificTime, 'YYYY-MM-DD HH:mm:ss') : ''}</span>
      ),
    },
  ];
};

export default tableConfig;
