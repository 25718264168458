import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { PaginateList } from 'src/app/common/types/common-types';
import { useDataProvider } from 'src/app/common/utils';
import { getDayStart, getDayEnd } from 'src/app/common/utils/time-utils';
import { fetchAchievementList, AchievementUploadListParams } from '../../../network/achievementUploadCrud';
import { AchievementUploadItem } from '../../../types/achievement-upload-types';
import AchievementUploadList from './components/AchievementUploadList';

type AchievementUploadListingPageProps = {} & ParamsProps;

const initialState: AchievementUploadListParams = {
  agentCode: '',
  lastUpdatedDateFrom: null,
  lastUpdatedDateTo: null,
  page: 0,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): AchievementUploadListParams => {
  return initialParams
    ? {
        agentCode: initialParams.name ?? '',
        lastUpdatedDateFrom: initialParams.lastUpdatedDateFrom
          ? getDayStart(new Date(initialParams.lastUpdatedDateFrom))
          : null,
        lastUpdatedDateTo: initialParams.lastUpdatedDateTo
          ? getDayEnd(new Date(initialParams.lastUpdatedDateTo))
          : null,
        page: 0,
        limit: 5,
      }
    : initialState;
};

export const achievementUploadFilterKeys = ['agentCode', 'lastUpdatedDateFrom', 'lastUpdatedDateTo'];

const AchievementUploadListingPage: FC<AchievementUploadListingPageProps> = ({
  initialParams,
  onChangeQueryParams,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<AchievementUploadListParams>(paramsInitiator(initialParams));
  const [achievementUploadList, setAchievementUploadList] = useState<PaginateList<AchievementUploadItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<AchievementUploadItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchAchievementList(filterState, dispatch);
      } catch (err) {}
    },
    setAchievementUploadList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('mdrtHub.achievementUpload.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('mdrtHub.achievementUpload.agentCode'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'lastUpdatedDateFrom',
            fieldTo: 'lastUpdatedDateTo',
            initialDateFrom: filterState.lastUpdatedDateFrom,
            initialDateTo: filterState.lastUpdatedDateTo,
            displayName: Translation('mdrtHub.achievementUpload.lastUpdatedDate'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            agentCode: data.agentCode,
            lastUpdatedDateFrom: data.lastUpdatedDateFrom,
            lastUpdatedDateTo: data.lastUpdatedDateTo,
          })
        }
        fetchData={refreshData}
      />
      <AchievementUploadList
        isLoading={isLoading}
        onRefresh={refreshData}
        achievementUploadList={achievementUploadList}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default AchievementUploadListingPage;
