import React, { useEffect, useRef } from 'react';
import Form from '../../../../../../../common/components/Form';
import { makeStyles, withStyles } from 'tss-react/mui';
import { TextField, FormControlLabel, RadioGroup, Radio, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import FileUploader from '../../../../../../../common/components/FileUploader';
import AddTags from './AddTag';
import { ResourceType, YesAndNo } from '../../../constants';
import Tinymce from './Tinymce';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';

// PVA
import { Add, Delete } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { isSaleModule as isSaleModuleFn } from '../../../utils/common-utils';
import { isRecruitModule as isRecruitModuleFn } from '../../../utils/common-utils';
import { LinkType, RegisterPageType, linkOptions, quickQuoteOptions, EQuickQuote } from '../../../constants';

const StyledRadioGroup = withStyles(RadioGroup, {
  root: {
    flexDirection: 'row',
    display: 'flex',
    '& .MuiFormControlLabel-root': {
      marginBottom: 0,
      marginLeft: 0,
      marginRight: '15px',
    },
    '& .MuiButtonBase-root': {
      padding: 0,
    },
  },
});

const StyledTooltip = withStyles(Tooltip, {
  tooltip: {
    maxWidth: '450px',
  },
});
// import CheckGroup from './CheckGroup';

const TranslationWithVariable = (key: string, enCount: number | string, cnCount: number | string) =>
  useIntl().formatMessage({ id: key }, { enCount, cnCount });

const useStyles = makeStyles()((theme) => ({
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  container: {
    '& .pru-form-item': {
      margin: '10px 0',
    },
    '& .MuiFormControl-root': {
      margin: 0,
    },
  },
  lineheight: {
    lineHeight: '22px',
    fontSize: '14px',
  },
  tooltip: {
    marginLeft: '5px',
    color: '#CCC',
  },
}));

interface FormListProp {
  disabled: boolean;
  activeLang: string;
  form: any;
  resourceType: string;
  eventInvitation: boolean;
  moduleType: string;
  nameMaxSize?: any;
  descriptMaxSize?: any;
  getFieldValue?: (name: any) => any;
  eventInvitationRegisterPageType?: string;
}

const SubFormList: React.FC<FormListProp> = (props) => {
  const { classes } = useStyles();
  const {
    activeLang,
    disabled,
    resourceType,
    moduleType,
    nameMaxSize,
    descriptMaxSize,
    getFieldValue,

    // PVA
    form,
    eventInvitation,
    eventInvitationRegisterPageType,
  } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });
  const isSaleModule = isSaleModuleFn(moduleType);
  const isRecruitModule = isRecruitModuleFn(moduleType);

  // validate rules
  const DEFAULT_REQUIRED_RULES = {
    required: true,
    message: Translation('component.form-required-text'),
  };

  const DEFAULT_NOT_REQUIRED_RULES = [{ required: false }];

  const LINK_VIDATOR = [
    DEFAULT_REQUIRED_RULES,
    {
      validator(_: any, value: string) {
        if (value && (value.startsWith('https://') || value.startsWith('http://'))) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(Translation('component.form-link-text')));
      },
    },
  ];

  const CONTENT_VALIDATE = {
    validator(_: any, value: string) {
      const canBeShare = getFieldValue && getFieldValue([activeLang, 'canShared']);

      if (canBeShare === 'Y' && value && value.includes('<video preload="metadata"')) {
        return Promise.reject(new Error(Translation('component.conent.can-not-be-shared')));
      }

      return Promise.resolve();
    },
  };

  const TEXT_VALIDATE = {
    validator(_: any, value: string) {
      // const regex = /<\/?!?(source|video|img|(-- pagebreak --))[^>]*>/gi;
      const regex = new RegExp(/<\/?!?(source|video|img|(-- pagebreak --))[^>]*>/, 'gi');
      if (value && regex.test(value)) {
        return Promise.reject(new Error(Translation('component.text.file-can-not-be-shared-as-text')));
      }

      return Promise.resolve();
    },
  };
  const getInputMaxRules = (count: number) => {
    const errMessage = TranslationWithVariable('component.form-max-characters-input-text', count, count / 2);

    return {
      validator: async (_: any, inputString: string) => {
        // const re = /[\u4E00-\u9FA5]/g;
        const re = new RegExp(/[\u4E00-\u9FA5]/, 'g');
        let stringLength = inputString ? inputString?.length : 0;
        const chineseCount = inputString ? (inputString.match(re) || '')?.length : 0;
        stringLength = stringLength + chineseCount;

        if (stringLength <= count) {
          return Promise.resolve();
        } else {
          throw new Error(errMessage);
        }
      },
    };
  };

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        {
          mimeType: fileType,
          accessLevel: 'anonymous',
          module: `material_${moduleType.toLowerCase()}`,
        },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        return {
          id: result.blobId,
          fileName: fileName,
          url: result.url,
          type: fileType,
        };
      }
    } catch (err) {
    } finally {
    }
  };

  const onChangeLinkType = (val: any) => {
    if (val.target.value === LinkType.UrlWithVideo) {
      form.setFieldsValue({
        [activeLang]: {
          link: '',
          title: '',
          information: '',
          body: '',
          videos: [
            {
              title: '',
              link: '',
              videoId: '',
              thumbnail: null,
            },
          ],
        },
      });
    } else {
      form.setFieldsValue({
        [activeLang]: {
          link: '',
          title: '',
          information: '',
          body: '',
          videos: null,
        },
      });
    }
  };

  const fileRef = useRef<any>();
  const fileTypes = ['pdf', 'jpg', 'png', 'jpeg', 'mp4'];
  const imageTypes = ['jpg', 'jpeg', 'png'];
  const fileSize = 20;
  const placeEnter = Translation('app.input.placeholder.please-enter');
  const fileLabel = Translation('component.formSelectItem.file');
  const showAllowText = useIntl().formatMessage(
    { id: 'component.formSelectItem.fielType' },
    {
      formats: (resourceType === ResourceType.file ? fileTypes : imageTypes).join('/').toUpperCase(),
      size: `${fileSize}M`,
    },
  );
  const linkLabel = Translation('component.formSelectItem.link');
  const contentLabel = Translation('component.formSelectItem.content');
  const textLabel = Translation('component.formSelectItem.content');
  // const bottomBarLabel = Translation('component.formLabel.bottom-bar-for-share')
  const titleForShare = Translation('component.formLabel.title-for-share');
  const coverpageForShare = Translation('component.formLabel.coverpage-for-share');
  const landingPageUrl = Translation('recruitment.form.landingPageURL');
  const videoId = Translation('recruitment.form.videoId');
  useEffect(() => {
    form.setFieldsValue({
      [activeLang]: {
        linkType: LinkType.Url,
        link: '',
        title: '',
        information: '',
        body: '',
        videos: null,
      },
    });
  }, [eventInvitation]);
  const introductionForShare = 'Default Message' || Translation('component.formLabel.introduction-for-share');
  const messageRule = getInputMaxRules(500);

  useEffect(() => {
    fileRef?.current?.clear();
  }, [resourceType]);

  return (
    <div className={classes.container}>
      <Form.Item
        name={[activeLang, 'name']}
        label={Translation('component.formLabel.name')}
        rules={[DEFAULT_REQUIRED_RULES, getInputMaxRules(nameMaxSize)]}
      >
        <TextField disabled={disabled} margin="dense" variant="outlined" fullWidth placeholder={placeEnter} />
      </Form.Item>
      <Form.Item
        name={[activeLang, 'coverpage']}
        label={Translation('component.formLabel.coverpage')}
        rules={[DEFAULT_REQUIRED_RULES]}
      >
        <FileUploader
          disabled={disabled}
          upload={handleUploadFile}
          color="inherit"
          showAllowText={Translation('component.resource.create.coverpage')}
          maxFileSize={0.57} // 600kb
          showFileSize={true}
          allowedFileTypes={['jpg', 'png', 'jpeg']}
        />
      </Form.Item>
      <Form.Item
        name={[activeLang, 'introduction']}
        label={Translation('component.formLabel.coverpage-introduction')}
        rules={[DEFAULT_REQUIRED_RULES, getInputMaxRules(descriptMaxSize)]}
      >
        <TextField
          disabled={disabled}
          margin="dense"
          variant="outlined"
          fullWidth
          multiline
          placeholder={placeEnter}
          InputProps={{
            classes: {
              input: classes.textArea,
            },
          }}
        />
      </Form.Item>
      <Form.Item
        required={false}
        name={[activeLang, 'tag']}
        label={Translation('component.formLabel.tags')}
        rules={DEFAULT_NOT_REQUIRED_RULES}
      >
        <AddTags
          disabled={disabled}
          btnTxt={Translation('component.tag-list.add-btn')}
          // sameTag={Translation('components.addtag.sametag')}
        />
      </Form.Item>
      {(resourceType === ResourceType.file || resourceType === ResourceType.picture) && (
        <Form.Item name={[activeLang, 'file']} label={fileLabel} rules={[DEFAULT_REQUIRED_RULES]}>
          <FileUploader
            ref={fileRef}
            color="secondary"
            disabled={disabled}
            showAllowText={showAllowText}
            upload={handleUploadFile}
            allowedFileTypes={resourceType === ResourceType.file ? fileTypes : imageTypes}
            maxFileSize={fileSize}
          />
        </Form.Item>
      )}

      {((resourceType === ResourceType.link && !isRecruitModule) ||
        (resourceType === ResourceType.link &&
          isRecruitModule &&
          (!eventInvitation ||
            (eventInvitation && eventInvitationRegisterPageType === RegisterPageType['insert-url'])))) && (
        <>
          <Form.Item
            name={[activeLang, 'linkType']}
            label={linkLabel}
            rules={[DEFAULT_REQUIRED_RULES]}
            initialValue={LinkType.Url}
            shouldUpdate
          >
            <RadioGroup aria-label="linkType" name="linkType" style={{ display: 'flex', flexDirection: 'row' }}>
              {linkOptions.map((item) => {
                return (
                  item && (
                    <FormControlLabel
                      key={item.value}
                      value={item.value}
                      control={<Radio disabled={disabled || eventInvitation} />}
                      label={Translation(`recruitment.form.${item.label}`)}
                      onChange={(e) => onChangeLinkType(e)}
                    />
                  )
                );
              })}
            </RadioGroup>
          </Form.Item>
          {/* link detail */}
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues[activeLang].linkType !== currentValues[activeLang].linkType
            }
          >
            {(_, meta, { getFieldValue }) => {
              const linkType = getFieldValue([activeLang, 'linkType']);
              if (linkType === LinkType.Url) {
                return (
                  <Form.Item
                    name={[activeLang, 'link']}
                    // label={linkLabel}
                    rules={LINK_VIDATOR}
                    style={{ marginLeft: '160px', width: 'auto' }}
                  >
                    <TextField
                      disabled={disabled}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      placeholder={placeEnter}
                    />
                  </Form.Item>
                );
              }
              if (linkType === LinkType.UrlWithVideo) {
                return (
                  <>
                    <Form.List name={[activeLang, 'videos']}>
                      {(fields, { add, remove }) => {
                        return fields.map((field, i) => (
                          <div key={i}>
                            <div
                              className="shadow-sm p-5 rounded position-relative mb-5"
                              style={{ marginLeft: '160px' }}
                            >
                              {fields?.length > 1 ? (
                                <Tooltip title="Delete">
                                  <div
                                    className="cursor-pointer position-absolute"
                                    style={{ top: '10px', right: '10px', color: '#ee1329', zIndex: 1 }}
                                    onClick={() => remove(i)}
                                  >
                                    <Delete fontSize="large" />
                                  </div>
                                </Tooltip>
                              ) : null}
                              <Form.Item
                                name={[field.name, 'title']}
                                label={Translation('component.formLabel.title')}
                                childStyle={{ display: 'block' }}
                              >
                                <TextField
                                  disabled={disabled}
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  placeholder={placeEnter}
                                />
                              </Form.Item>
                              <Form.Item
                                name={[field.name, 'link']}
                                label={landingPageUrl}
                                rules={LINK_VIDATOR}
                                childStyle={{ display: 'block' }}
                              >
                                <TextField
                                  disabled={disabled}
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  placeholder={placeEnter}
                                />
                              </Form.Item>
                              <div className="video-input">
                                <Form.Item
                                  name={[field.name, 'videoId']}
                                  label={videoId}
                                  rules={[DEFAULT_REQUIRED_RULES]}
                                  childStyle={{ display: 'block' }}
                                >
                                  <TextField
                                    disabled={disabled}
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    placeholder={placeEnter}
                                  />
                                </Form.Item>
                                <div style={{ marginBottom: '20px' }}>
                                  {Translation('recruitment.form.videoIDDescription')}
                                </div>
                                <Form.Item
                                  name={[field.name, 'thumbnail']}
                                  label={Translation('recruitment.form.thumbnail')}
                                  rules={[DEFAULT_REQUIRED_RULES]}
                                >
                                  <FileUploader
                                    disabled={disabled}
                                    upload={handleUploadFile}
                                    showAllowText={Translation('component.form.thumbnail')}
                                    maxFileSize={20}
                                    allowedFileTypes={['jpg', 'png', 'jpeg']}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                            {i === fields?.length - 1 && fields?.length < 10 ? (
                              <div
                                onClick={() => add()}
                                className="mb-5 cursor-pointer"
                                style={{ marginLeft: '160px', color: '#3783e7' }}
                              >
                                <Add fontSize="large" />
                                <span>{Translation('recruitment.form.addMore')}</span>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        ));
                      }}
                    </Form.List>
                  </>
                );
              }
              if (linkType === LinkType.UrlWithInfor) {
                return (
                  <>
                    <Form.Item
                      name={[activeLang, 'information']}
                      label={Translation('recruitment.form.information')}
                      style={{ marginLeft: '160px', width: 'auto' }}
                    >
                      <TextField
                        disabled={disabled}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        placeholder={placeEnter}
                      />
                    </Form.Item>
                    <Form.Item
                      name={[activeLang, 'link']}
                      label={landingPageUrl}
                      rules={LINK_VIDATOR}
                      style={{ marginLeft: '160px', width: 'auto' }}
                    >
                      <TextField
                        disabled={disabled}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        placeholder={placeEnter}
                      />
                    </Form.Item>
                    <Form.Item
                      name={[activeLang, 'body']}
                      label={Translation('recruitment.form.body')}
                      rules={[DEFAULT_REQUIRED_RULES, TEXT_VALIDATE]}
                      style={{ marginLeft: '160px', width: 'auto' }}
                    >
                      <Tinymce disabled={disabled} moduleType={moduleType} lang={activeLang} />
                    </Form.Item>
                  </>
                );
              }
            }}
          </Form.Item>
          {/* end link detail */}
        </>
      )}

      {resourceType === ResourceType.link &&
      eventInvitation &&
      isRecruitModule &&
      eventInvitationRegisterPageType === RegisterPageType.template ? (
        <Form.Item name={[activeLang, 'eventAddress']} label={Translation('recruitment.form.address')}>
          <TextField disabled={disabled} margin="dense" variant="outlined" fullWidth placeholder={placeEnter} />
        </Form.Item>
      ) : null}

      {isSaleModule && !eventInvitation ? (
        <Form.Item
          name={[activeLang, 'quickQuote']}
          label={Translation('recruitment.form.quickquote')}
          initialValue={!eventInvitation ? EQuickQuote.Default : ''}
          required={false}
        >
          <TextField
            disabled={disabled}
            required={false}
            select
            margin="dense"
            variant="outlined"
            fullWidth
            placeholder={Translation('app.input.placeholder.please-select')}
          >
            {quickQuoteOptions.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </Form.Item>
      ) : (
        ''
      )}
      {resourceType === ResourceType.content && (
        <Form.Item
          name={[activeLang, 'content']}
          label={contentLabel}
          rules={[DEFAULT_REQUIRED_RULES, CONTENT_VALIDATE]}
        >
          <Tinymce disabled={disabled} moduleType={moduleType} lang={activeLang} />
        </Form.Item>
      )}
      {resourceType === ResourceType.text && (
        <Form.Item name={[activeLang, 'content']} label={textLabel} rules={[DEFAULT_REQUIRED_RULES, TEXT_VALIDATE]}>
          <Tinymce disabled={disabled} moduleType={moduleType} lang={activeLang} />
        </Form.Item>
      )}

      {
        <Form.Item
          name={[activeLang, 'canShared']}
          label={Translation('component.formLabel.can-be-shared')}
          rules={[DEFAULT_REQUIRED_RULES]}
          shouldUpdate
          initialValue={YesAndNo.no}
        >
          <StyledRadioGroup aria-label="contentType" name="contentType">
            {Object.entries(YesAndNo).map(([label, value]) => (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio disabled={disabled} />}
                label={Translation(`app.select.${label}`)}
              />
            ))}
          </StyledRadioGroup>
        </Form.Item>
      }

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues[activeLang].canShared !== currentValues[activeLang].canShared
        }
      >
        {(_, meta, { getFieldValue }) => {
          const canShared = getFieldValue([activeLang, 'canShared']) === YesAndNo.yes;
          const showDefaultMessage =
            [ResourceType.link, ResourceType.file, ResourceType.content, ResourceType.picture].includes(
              resourceType as ResourceType,
            ) && canShared;

          return (
            <>
              {showDefaultMessage && (
                <Form.Item
                  name={[activeLang, 'sharedIntroduction']}
                  label={introductionForShare}
                  rules={[...DEFAULT_NOT_REQUIRED_RULES, messageRule]}
                  required={false}
                >
                  <TextField
                    disabled={disabled}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    multiline
                    InputProps={{
                      classes: {
                        input: classes.textArea,
                      },
                    }}
                    placeholder={placeEnter}
                  />
                </Form.Item>
              )}
            </>
          );
        }}
      </Form.Item>
    </div>
  );
};

export default SubFormList;
