// COE
import { Dispatch } from 'react';
import { getQueryPath } from 'src/app/common/utils';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { SurveyDetail, SurveyPaginateList, SurveyResult } from '../types/survey-types';

// PVA
import { completeMessage } from '../types/survey-types';
import {
  SurveySummaryResult,
  CustomerSurveyDetail,
  CandidateSurveyDetail,
  AgentLeaderSurveyDetail,
  NonProspectSurveyDetail,
} from '../types/survey-types';
import { forEach } from 'lodash';
import { getDayEnd, getDayStart } from 'src/app/common/utils';

// COE
export type SurveyResultListParam = {
  startDateFrom: Date | null;
  startDateTo: Date | null;
  endDateFrom: Date | null;
  endDateTo: Date | null;
  limit: number;
  page: number;
};

export type SurveyItemBody = {
  startDate: Date | null;
  endDate: Date | null;
  eligibleAgents: string[];
  excludedAgents: string[];
  designations: string[];
  body: Record<string, unknown>;
};

// PVA
export type CustomerSurveyItemBody = {
  startDate: Date | null;
  endDate: Date | null;
  body: Record<string, unknown>;
  consents: string[];
  completeMessage: completeMessage;
  surveyImage: string;
  // banner: string;
  flyerMessage: string;
  bannerId: string;
  status: string;
  enableTargetAgents: boolean;
  targetAgents: Array<{ agentCode: string }>;
};

export type CandidateSurveyItemBody = {
  startDate: Date | null;
  endDate: Date | null;
  body: Record<string, unknown>;
  consents: string[];
  completeMessage: completeMessage;
  // banner: string;
  flyerMessage: string;
  bannerId: string;
  status: string;
  enableTargetAgents: boolean;
  targetAgents: Array<{ agentCode: string }>;
};

export type AgentLeaderSurveyItemBody = {
  startDate: Date | null;
  endDate: Date | null;
  body: Record<string, unknown>;
  consents: string[];
  completeMessage: completeMessage;
  // banner: string;
  flyerMessage: string;
  bannerId: string;
  status: string;
  enableTargetAgents: boolean;
  targetAgents: Array<{ agentCode: string }>;
};

export type NonProspectSurveyItemBody = {
  startDate: Date | null;
  endDate: Date | null;
  body: Record<string, unknown>;
  consents: string[];
  completeMessage: completeMessage;
  surveyImage: string;
  // banner: string;
  flyerMessage: string;
  bannerId: string;
  status: string;
  enableTargetAgents: boolean;
  targetAgents: Array<{ agentCode: string }>;
};

export type CustomerSurveyResultsPaginateParam = {
  page: number;
  limit: number;
};

export const fetchSurveyResultList = async (
  params: SurveyResultListParam,
  dispatch?: Dispatch<any>,
): Promise<SurveyPaginateList> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/survey?`, params);

  return apiClient
    .get<SurveyPaginateList>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

// COE
//--------------------agent survey---------------//
export const fetchSurveyItem = async (id: string, dispatch?: Dispatch<any>): Promise<SurveyDetail> => {
  return apiClient
    .get<SurveyDetail>(`${ADMIN_URL}/survey/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createSurvey = async (body: SurveyItemBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/survey`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifySurvey = async (id: string, body: SurveyItemBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .put<void>(`${ADMIN_URL}/survey/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchSurveyResults = async (id: string, dispatch?: Dispatch<any>): Promise<SurveyResult> => {
  return apiClient
    .get<SurveyResult>(`${ADMIN_URL}/survey/${id}/result`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadSurveyResults = async (id: string, dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${ADMIN_URL}/survey/${id}/result/download`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

// PVA
//--------------------customer survey---------------//
export const fetchCustomerSurveyItem = async (id: string, dispatch?: Dispatch<any>): Promise<CustomerSurveyDetail> => {
  return apiClient
    .get<CustomerSurveyDetail>(`${ADMIN_URL}/customer-surveys/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCustomerSurveyResultList = async (
  params: SurveyResultListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<SurveyPaginateList> => {
  let queryPath = `${ADMIN_URL}/customer-surveys?`;

  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.includes('From')) {
          queryPath += `${key}=${getDayStart(param).toISOString()}&`;
        }
        if (key.includes('To')) {
          queryPath += `${key}=${getDayEnd(param).toISOString()}&`;
        }
      }
    } else {
      queryPath += param ? `${key}=${param}&` : '';
      // queryPath =
      //   "https://57cc42d6-2731-45c2-91d8-f0037d4649c4.mock.pstmn.io/customer-surveys";
    }
  });

  forEach(sortKeys, (sortParams, key) => {
    if (sortParams.value) {
      const sortValue = sortParams.value === 'asc' ? 'ascending' : 'descending';
      if (key === 0) {
        queryPath += `${sortParams.key}=${sortValue}&`;
      } else {
        queryPath += `${sortParams.key}=${sortValue}`;
      }
    }
  });

  if (!queryPath.includes('Sort')) queryPath += `lastUpdateDateSort=descending`;

  return apiClient
    .get<SurveyPaginateList>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createCustomerSurvey = async (body: CustomerSurveyItemBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/customer-surveys`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyCustomerSurvey = async (
  id: string,
  body: CustomerSurveyItemBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/customer-surveys/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCustomerSurveyResults = async (
  id: string,
  params: CustomerSurveyResultsPaginateParam,
  dispatch?: Dispatch<any>,
): Promise<SurveyResult> => {
  let queryPath = `${ADMIN_URL}/customer-surveys/${id}/results?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${param}&`;
    } else {
      queryPath += param ? `${key}=${param}&` : '';
    }
  });

  return apiClient
    .get<SurveyResult>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadCustomerSurveyResults = async (id: string, dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${ADMIN_URL}/customer-surveys/${id}/results/download`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCustomerSurveySummary = async (
  id: string,
  dispatch?: Dispatch<any>,
): Promise<SurveySummaryResult> => {
  return apiClient
    .get<SurveySummaryResult>(`${ADMIN_URL}/customer-surveys/${id}/statistics`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const assignSurvingServey = async (file: any, dispatch?: Dispatch<any>): Promise<any> => {
  let formData = new FormData();

  formData.append('file', file);

  return apiClient
    .post<any>(`${ADMIN_URL}/assign-survey/add-customers-feedbacks`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

//--------------------candidate survey---------------//
export const fetchCandidateSurveyItem = async (
  id: string,
  dispatch?: Dispatch<any>,
): Promise<CandidateSurveyDetail> => {
  return apiClient
    .get<CandidateSurveyDetail>(`${ADMIN_URL}/candidate-surveys/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCandidateSurveyResultList = async (
  params: SurveyResultListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<SurveyPaginateList> => {
  let queryPath = `${ADMIN_URL}/candidate-surveys?`;

  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.includes('From')) {
          queryPath += `${key}=${getDayStart(param).toISOString()}&`;
        }
        if (key.includes('To')) {
          queryPath += `${key}=${getDayEnd(param).toISOString()}&`;
        }
      }
    } else {
      queryPath += param ? `${key}=${param}&` : '';
    }
  });

  forEach(sortKeys, (sortParams, key) => {
    if (sortParams.value) {
      const sortValue = sortParams.value === 'asc' ? 'ascending' : 'descending';
      if (key === 0) {
        queryPath += `${sortParams.key}=${sortValue}&`;
      } else {
        queryPath += `${sortParams.key}=${sortValue}`;
      }
    }
  });

  if (!queryPath.includes('Sort')) queryPath += `lastUpdateDateSort=descending`;

  return apiClient
    .get<SurveyPaginateList>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createCandidateSurvey = async (body: CandidateSurveyItemBody, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/candidate-surveys`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyCandidateSurvey = async (
  id: string,
  body: CandidateSurveyItemBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/candidate-surveys/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCandidateSurveyResults = async (id: string, dispatch?: Dispatch<any>): Promise<SurveyResult> => {
  return apiClient
    .get<SurveyResult>(`${ADMIN_URL}/candidate-surveys/${id}/results`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadCandidateSurveyResults = async (id: string, dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${ADMIN_URL}/candidate-surveys/${id}/result/download`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCandidateSurveySummary = async (
  id: string,
  dispatch?: Dispatch<any>,
): Promise<SurveySummaryResult> => {
  return apiClient
    .get<SurveySummaryResult>(`${ADMIN_URL}/candidate-surveys/${id}/statistics`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

//--------------------agent leader survey---------------//
export const fetchAgentLeaderSurveyItem = async (
  id: string,
  dispatch?: Dispatch<any>,
): Promise<AgentLeaderSurveyDetail> => {
  return apiClient
    .get<AgentLeaderSurveyDetail>(`${ADMIN_URL}/agent-leader-surveys/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchAgentLeaderSurveyResultList = async (
  params: SurveyResultListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<SurveyPaginateList> => {
  let queryPath = `${ADMIN_URL}/agent-leader-surveys?`;

  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.includes('From')) {
          queryPath += `${key}=${getDayStart(param).toISOString()}&`;
        }
        if (key.includes('To')) {
          queryPath += `${key}=${getDayEnd(param).toISOString()}&`;
        }
      }
    } else {
      queryPath += param ? `${key}=${param}&` : '';
    }
  });

  forEach(sortKeys, (sortParams, key) => {
    if (sortParams.value) {
      const sortValue = sortParams.value === 'asc' ? 'ascending' : 'descending';
      if (key === 0) {
        queryPath += `${sortParams.key}=${sortValue}&`;
      } else {
        queryPath += `${sortParams.key}=${sortValue}`;
      }
    }
  });

  if (!queryPath.includes('Sort')) queryPath += `lastUpdateDateSort=descending`;

  return apiClient
    .get<SurveyPaginateList>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createAgentLeaderSurvey = async (
  body: AgentLeaderSurveyItemBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/agent-leader-surveys`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyAgentLeaderSurvey = async (
  id: string,
  body: AgentLeaderSurveyItemBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/agent-leader-surveys/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchAgentLeaderSurveyResults = async (id: string, dispatch?: Dispatch<any>): Promise<SurveyResult> => {
  return apiClient
    .get<SurveyResult>(`${ADMIN_URL}/agent-leader-surveys/${id}/results`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadAgentLeaderSurveyResults = async (id: string, dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${ADMIN_URL}/agent-leader-surveys/${id}/result/download`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchAgentLeaderSurveySummary = async (
  id: string,
  dispatch?: Dispatch<any>,
): Promise<SurveySummaryResult> => {
  return apiClient
    .get<SurveySummaryResult>(`${ADMIN_URL}/agent-leader-surveys/${id}/statistics`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

//--------------------non prospect survey---------------//
export const fetchNonProspectSurveyItem = async (
  id: string,
  dispatch?: Dispatch<any>,
): Promise<NonProspectSurveyDetail> => {
  return apiClient
    .get<NonProspectSurveyDetail>(`${ADMIN_URL}/non-prospect-surveys/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNonProspectSurveyResultList = async (
  params: SurveyResultListParam,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<SurveyPaginateList> => {
  let queryPath = `${ADMIN_URL}/non-prospect-surveys?`;

  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.includes('From')) {
          queryPath += `${key}=${getDayStart(param).toISOString()}&`;
        }
        if (key.includes('To')) {
          queryPath += `${key}=${getDayEnd(param).toISOString()}&`;
        }
      }
    } else {
      queryPath += param ? `${key}=${param}&` : '';
    }
  });

  forEach(sortKeys, (sortParams, key) => {
    if (sortParams.value) {
      const sortValue = sortParams.value === 'asc' ? 'ascending' : 'descending';
      if (key === 0) {
        queryPath += `${sortParams.key}=${sortValue}&`;
      } else {
        queryPath += `${sortParams.key}=${sortValue}`;
      }
    }
  });

  if (!queryPath.includes('Sort')) queryPath += `lastUpdateDateSort=descending`;

  return apiClient
    .get<SurveyPaginateList>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNonProspectSurvey = async (
  body: NonProspectSurveyItemBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/non-prospect-surveys`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyNonProspectSurvey = async (
  id: string,
  body: NonProspectSurveyItemBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .patch<void>(`${ADMIN_URL}/non-prospect-surveys/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNonProspectSurveyResults = async (id: string, dispatch?: Dispatch<any>): Promise<SurveyResult> => {
  return apiClient
    .get<SurveyResult>(`${ADMIN_URL}/non-prospect-surveys/${id}/results`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadNonProspectSurveyResults = async (id: string, dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${ADMIN_URL}/non-prospect-surveys/${id}/result/download`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNonProspectSurveySummary = async (
  id: string,
  dispatch?: Dispatch<any>,
): Promise<SurveySummaryResult> => {
  return apiClient
    .get<SurveySummaryResult>(`${ADMIN_URL}/non-prospect-surveys/${id}/statistics`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
