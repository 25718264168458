import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { useDataProvider } from 'src/app/common/utils';
import LibraryList from './components/LibraryList';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { QRCodeCanvas } from 'qrcode.react';
import { useRef } from 'react';
import {
  LibraryItem,
  LibraryListParam,
  LibraryPaginateList,
} from 'src/app/modules/sales-kit/components/Library/types/library-types';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { fetchLibraryList } from 'src/app/modules/sales-kit/components/Library/network/libraryCrud';

type ResourceProps = {
  filterName: string;
  blockName: string;
  viewPageBasePath: string;
  moduleType: string;
} & RouteComponentProps &
  ModulePermissionProps;

const paramsInitiator = (moduleType: string): LibraryListParam => {
  return {
    page: 1,
    pageSize: 10,
    name: '',
    module: moduleType,
    eventInvitation: 'Y',
    eventForAd: 'Y',
  };
};

const MaterialLibraryListingPage: FC<ResourceProps> = ({
  history,
  filterName,
  moduleType,
  blockName,
  viewPageBasePath,
}) => {
  const locale = useLang();
  const dispatch = useDispatch();
  const defFilter: LibraryListParam = useMemo(() => paramsInitiator(moduleType), [moduleType]);
  const [filterState, setFilterState] = useState<LibraryListParam>(defFilter);
  const [sortKey, setSortKey] = useState<any[]>([]);
  const defaultPageNumber = get(filterState, 'page', 1) - 1;

  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [libraryList, setLibraryList] = useState<LibraryPaginateList>();

  // QR code
  const [qrCodeId, setQrCodeId] = useState('');
  const qrRef = useRef<any>();
  const qrcode = <QRCodeCanvas id="qrCode" value={qrCodeId} size={256} />;

  const filterOptions = {
    status: [
      { displayName: Translation('component.status.all'), value: '' },
      { displayName: Translation('component.status.published'), value: 'Published' },
      { displayName: Translation('component.status.unpublished'), value: 'Unpublished' },
      { displayName: Translation('component.status.draft'), value: 'Draft' },
      { displayName: Translation('component.status.publishedToSetOnly'), value: 'PublishedToSetOnly' },
    ],
  };

  const { isLoading, refreshData } = useDataProvider<LibraryPaginateList>(
    async () => {
      try {
        return await fetchLibraryList(locale, filterState, sortKey, dispatch);
      } catch (err) {}
    },
    setLibraryList,
    false,
    undefined,
    true,
  );

  useEffect(() => {
    refreshData();
  }, [sortKey]);

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue, index, arr) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    setSortKey(sortingKeyArray);
  };

  const openEditPage = (rowData: LibraryItem, mode: string) => {
    if (mode === 'view') {
      history.push({
        pathname: `${viewPageBasePath}/${rowData.id}`,
        state: {},
      });
    }
  };

  const onDownloadQR = (rowData: LibraryItem) => {
    setQrCodeId(
      `${rowData.checkInUrl}?id=${rowData.id}&eventStartDate=${rowData.eventStartDate}&eventEndDate=${rowData.eventEndDate}` ||
        '',
    );

    setTimeout(() => {
      const canvas = qrRef.current.querySelector('canvas');
      const image = canvas.toDataURL('image/png');
      const anchor = document.createElement('a');
      anchor.href = image;
      anchor.download = `qr-invitation-${rowData.id}.png`;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      setQrCodeId('');
    }, 500);
  };

  const onPreviewLibrary = (rowData: LibraryItem) => {
    const templateUrl = get(rowData, 'i18nData.link');
    const url = templateUrl ? `${templateUrl}?id=${rowData.id}` : 'http://google.com.vn';
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <PruFilter
        title={Translation(filterName)}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'name',
            initialValue: filterState.name,
            displayName: Translation('component.formLabel.name'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 150 },
            field: 'status',
            initialValue: filterState.status,
            displayName: Translation('component.formLabel.status'),
            choices: filterOptions.status,
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'createTimeStart',
            fieldTo: 'createTimeEnd',
            initialDateFrom: filterState.createTimeStart,
            initialDateTo: filterState.createTimeEnd,
            displayName: Translation('component.formLabel.created-time'),
          },
        ]}
        onChangeFilter={(data) => {
          setFilterState({
            ...filterState,
            name: data.name,
            status: data.status,
            createTimeStart: data.createTimeStart,
            createTimeEnd: data.createTimeEnd,
          });
        }}
        fetchData={refreshData}
      />
      <LibraryList
        defaultPageNumber={defaultPageNumber}
        isLoading={isLoading}
        libraryList={libraryList}
        libraryTableTitle={blockName}
        onRefresh={refreshData}
        onSort={(dataKey) => updateSortingKey(dataKey)}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            pageSize: rowsPerPage,
          });
          refreshData();
        }}
        onEditLibrary={(rowData, mode) => {
          openEditPage(rowData, mode);
        }}
        onDownloadQR={(rowData) => {
          onDownloadQR(rowData);
        }}
        onPreviewLibrary={(rowData) => {
          onPreviewLibrary(rowData);
        }}
      />
      <div ref={qrRef} style={{ display: 'none' }}>
        {qrcode}
      </div>
    </>
  );
};

export default MaterialLibraryListingPage;
