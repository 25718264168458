import moment from 'moment';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { PaginateListMore } from 'src/app/common/types/common-types';
import { useDataProvider } from 'src/app/common/utils';
import { exportCandidatePerformanceReport, fetchCandidatePerformanceReport } from '../../../network/candidateCrud';
import { ICandidatePerformanceReportItem, IReportPerformanceFilter } from '../../../types/candidate-types';

type CandidatePerformanceReportProps = {} & ParamsProps;

const initialState: IReportPerformanceFilter = {
  toDate: null,
  agentCode: '',
  leaderCode: '',
  unitCode: '',
  officeCode: '',
  branchCode: '',
  activeStatus: '',
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): IReportPerformanceFilter => {
  return initialParams
    ? {
        toDate: initialParams.toDate ? moment(initialParams.toDate).endOf('month').toDate() : null,
        agentCode: '',
        leaderCode: '',
        unitCode: '',
        officeCode: '',
        branchCode: '',
        activeStatus: '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const CandidatePerformanceReport: FC<CandidatePerformanceReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilter = paramsInitiator(initialParams);

  const [filterState, setFilterState] = useState<IReportPerformanceFilter>(defaultFilter);
  const [reportList, setReportList] = useState<PaginateListMore<ICandidatePerformanceReportItem>>();
  const [isDisableExport, setIsDisableExport] = useState<boolean>(false);

  const filterOptions = {
    activeStatus: [
      { displayName: Translation('component.status.all'), value: '' },
      { displayName: Translation('component.status.yes'), value: 'YES' },
      { displayName: Translation('component.status.no'), value: 'NO' },
    ],
  };

  const handleParams = (filters: IReportPerformanceFilter) => {
    return {
      ...filters,
      toDate: moment(filters.toDate).endOf('month').toDate(),
    };
  };

  const { isLoading, refreshData } = useDataProvider<PaginateListMore<ICandidatePerformanceReportItem>>(
    async () => {
      try {
        const params = handleParams(filterState);
        return await fetchCandidatePerformanceReport(params);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsv = async () => {
    try {
      setIsDisableExport(true);
      const params = handleParams(filterState);

      await exportCandidatePerformanceReport(params, dispatch).finally(() => setIsDisableExport(false));
    } catch (err) {
      setIsDisableExport(false);
    }
  };

  const numberSeparator = (num: string) => {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  return (
    <>
      <PruFilter
        title={Translation('report.candidate.performance.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('report.candidate.performance.agentCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'leaderCode',
            initialValue: filterState.leaderCode,
            displayName: Translation('report.candidate.performance.leaderCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'unitCode',
            initialValue: filterState.unitCode,
            displayName: Translation('report.candidate.performance.unitCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'branchCode',
            initialValue: filterState.branchCode,
            displayName: Translation('report.candidate.performance.branchCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'officeCode',
            initialValue: filterState.officeCode,
            displayName: Translation('report.candidate.performance.officeCode'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'activeStatus',
            initialValue: filterState.activeStatus,
            displayName: Translation('component.formLabel.status'),
            choices: filterOptions.activeStatus,
          },
          {
            type: PruFilterItemType.DATE_MONTH,
            field: 'toDate',
            initialValue: filterState.toDate,
            defaultValue: defaultFilter.toDate,
            displayName: Translation('report.candidate.performance.requestPeriod'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            ...data,
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('title.report.candidatePerformance')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsv(),
            disabled: isDisableExport,
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'agentCode',
            displayName: Translation('report.candidate.performance.agCode'),
            align: 'center',
            renderData: (row) => row.agentCode,
          },
          {
            keyIndex: 'joinDate',
            displayName: Translation('report.candidate.performance.joinDate'),
            align: 'center',
            renderData: (row) => moment(row.joinDate).format('DD/MM/YYYY'),
          },
          {
            keyIndex: 'leaderCode',
            displayName: Translation('report.candidate.performance.leaderCode'),
            align: 'center',
            renderData: (row) => row.leaderCode,
          },
          {
            keyIndex: 'unitCode',
            displayName: Translation('report.candidate.performance.unitCode'),
            align: 'center',
            renderData: (row) => row.unitCode,
          },
          {
            keyIndex: 'branchCode',
            displayName: Translation('report.candidate.performance.branchCode'),
            align: 'center',
            rowSpan: 2,
            renderData: (row) => row.branchCode,
          },
          {
            keyIndex: 'officeCode',
            displayName: Translation('report.candidate.performance.officeCode'),
            align: 'center',
            renderData: (row) => row.officeCode,
          },
          {
            keyIndex: 'gadCode',
            displayName: Translation('report.candidate.performance.gadCode'),
            align: 'center',
            renderData: (row) => row.gaCode,
          },
          {
            keyIndex: 'noOfAddedProspect',
            displayName: Translation('report.candidate.performance.noOfAddedProspect'),
            align: 'center',
            renderData: (row) => (row.prospect || 0).toString(),
          },
          {
            keyIndex: 'fyp',
            displayName: Translation('report.candidate.performance.fyp'),
            align: 'center',
            renderData: (row) => numberSeparator((row.fyp || 0).toString()),
          },
          {
            keyIndex: 'ip',
            displayName: Translation('report.candidate.performance.ip'),
            align: 'center',
            renderData: (row) => numberSeparator((row.ip || 0).toString()),
          },
          {
            keyIndex: 'activeStatus',
            displayName: Translation('report.candidate.performance.activeStatus'),
            align: 'center',
            renderData: (row) => row.activeStatus,
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default CandidatePerformanceReport;
