export const CANDIDATE_BASE_PATH = '/candidate';

export const MANDATORY_FIELD_ERROR_TEXT = 'This field is mandatory';
export const DATE_ERROR_TEXT = 'End date must be after Begin date';

//  These constants map to aside menu, header menu to display title
export const CANDIDATE_TITLE = 'title.candidate';

//  These constants map to permission control, identical to permission enum defined below
export const CANDIDATE_NAMESPACE = 'CANDIDATE';
export const CUSTOMER_CANDIDATE_NAMESPACE = 'CUSTOMER-CANDIDATE';

export enum AdminCandidatePermissionType {
  CANDIDATE_READ = 'READ_ANY_CANDIDATE',
  CANDIDATE_CREATE = 'CREATE_ANY_CANDIDATE',
  CANDIDATE_UPDATE = 'UPDATE_ANY_CANDIDATE',
}

export const ASSIGN_CANDIDATE_TITLE = 'title.assignCandidate';
export const ASSIGN_CANDIDATE_PATH = '/assign-candidate';
export const ASSIGN_CANDIDATE_NAMESPACE = 'ASSIGN-CANDIDATE-UPLOAD';

export enum AdminAssignCandidatePermissionType {
  ASSIGN_CANDIDATE_READ = 'READ_ANY_ASSIGN-CANDIDATE-UPLOAD',
  ASSIGN_CANDIDATE_CREATE = 'CREATE_ANY_ASSIGN-CANDIDATE-UPLOAD',
  ASSIGN_CANDIDATE_UPDATE = 'UPDATE_ANY_ASSIGN-CANDIDATE-UPLOAD',
}

export const UPDATE_CANDIDATE_ASSESSMENT_TITLE = 'Update Candidate Assessment';
export const UPDATE_CANDIDATE_ASSESSMENT_PATH = '/update-candidate-assessment';
export const UPDATE_CANDIDATE_ASSESSMENT_NAMESPACE = 'UPDATE-CANDIDATE-ASSESSMENT';

export enum AdminAssignCandidatePermissionType {
  UPDATE_CANDIDATE_ASSESSMENT_READ = 'READ_ANY_UPDATE-CANDIDATE-ASSESSMENT',
  UPDATE_CANDIDATE_ASSESSMENT_CREATE = 'CREATE_ANY_UPDATE-CANDIDATE-ASSESSMENT',
  UPDATE_CANDIDATE_ASSESSMENT_UPDATE = 'UPDATE_ANY_UPDATE-CANDIDATE-ASSESSMENT',
}
