import React, { FC, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PruTable from '../../../../../../../common/components/PruTable/PruTable';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import { PaginateList } from '../../../../../../../common/types/common-types';
import { LeadReassignmentStatusItem } from '../../../../../types/lead-reassignment-types';
import { statusPath } from '../../LeadReassignmentStatusRoutes';

type LeadReassignmentStatusListProps = {
  isLoading: boolean;
  leadReassignmentStatusList?: PaginateList<LeadReassignmentStatusItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

const LeadReassignmentStatusList: FC<LeadReassignmentStatusListProps> = ({
  isLoading,
  leadReassignmentStatusList,
  onRefresh,
  onChangePage,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <Fragment>
      <PruTable
        title={Translation('pulseleads.leadReassignment.list')}
        disableBulkSelect
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'filename',
            displayName: Translation('pulseleads.leadReassignment.common.filename'),
            renderData: (row) => row.filename || '-',
          },
          {
            keyIndex: 'totalRow',
            align: 'center',
            displayName: Translation('pulseleads.leadReassignment.common.totalRow'),
            renderData: (row) => row.totalRow.toString() || '-',
          },
          {
            keyIndex: 'totalDistributed',
            align: 'center',
            displayName: Translation('pulseleads.leadReassignment.common.totalDistributed'),
            renderData: (row) => row.totalDistributed.toString() || '-',
          },
          {
            keyIndex: 'rowFailed',
            align: 'center',
            displayName: Translation('pulseleads.leadReassignment.common.rowFailed'),
            renderData: (row) =>
              row.rowFailed > 0 ? (
                <a style={{ color: 'red' }} onClick={() => history.push(`${statusPath}/${row.resourceId}/failed/list`)}>
                  {row.rowFailed.toString()}
                </a>
              ) : (
                row.rowFailed.toString()
              ),
          },
          {
            keyIndex: 'status',
            align: 'center',
            displayName: Translation('pulseleads.leadReassignment.common.status'),
            renderData: (row) => row.status || '-',
          },
          {
            keyIndex: 'updatedAt',
            align: 'center',
            displayName: Translation('pulseleads.leadReassignment.common.updatedAt'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.updatedAt), 'datetime'),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={leadReassignmentStatusList?.docs}
        totalPages={leadReassignmentStatusList?.totalPages}
        totalRecords={leadReassignmentStatusList?.totalDocs}
        onChangePage={onChangePage}
      />
    </Fragment>
  );
};

export default LeadReassignmentStatusList;
