import moment from 'moment';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import PruTableCustom from 'src/app/common/components/PruTable/PruTableCustom';
import { PaginateListMore } from 'src/app/common/types/common-types';
import { useDataProvider } from 'src/app/common/utils';
import { exportCandidateManagementReport, fetchCandidateManagementReport } from '../../../network/candidateCrud';
import { ICandidateManagementReportItem, IReportFilter } from '../../../types/candidate-types';

type CandidateManagementReportProps = {} & ParamsProps;

const initialState: IReportFilter = {
  fromDate: moment().startOf('month').toDate(),
  toDate: moment().endOf('month').toDate(),
  agentCode: '',
  unitCode: '',
  officeCode: '',
  branchCode: '',
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): IReportFilter => {
  return initialParams
    ? {
        fromDate: initialParams.fromDate
          ? moment(initialParams.fromDate).startOf('day').toDate()
          : moment().startOf('month').toDate(),
        toDate: initialParams.toDate
          ? moment(initialParams.toDate).endOf('day').toDate()
          : moment().endOf('month').toDate(),
        referralAgent: '',
        unitCode: '',
        officeCode: '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const CandidateManagementReport: FC<CandidateManagementReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilter = paramsInitiator(initialParams);

  const [filterState, setFilterState] = useState<IReportFilter>(defaultFilter);
  const [reportList, setReportList] = useState<PaginateListMore<ICandidateManagementReportItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateListMore<ICandidateManagementReportItem>>(
    async () => {
      try {
        const params = handleParams(filterState);
        return await fetchCandidateManagementReport(params);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const handleParams = (filters: IReportFilter) => {
    return {
      ...filters,
      fromDate: moment(filterState.fromDate).startOf('day').toDate(),
      toDate: moment(filterState.toDate).endOf('day').toDate(),
    };
  };

  const fetchCsv = () => {
    try {
      const params = handleParams(filterState);
      exportCandidateManagementReport(params, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('report.candidate.management.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('report.candidate.management.agentCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'unitCode',
            initialValue: filterState.unitCode,
            displayName: Translation('report.candidate.management.unitCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'branchCode',
            initialValue: filterState.branchCode,
            displayName: Translation('report.candidate.management.branchCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'officeCode',
            initialValue: filterState.officeCode,
            displayName: Translation('report.candidate.management.officeCode'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'fromDate',
            fieldTo: 'toDate',
            initialDateFrom: filterState.fromDate,
            initialDateTo: filterState.toDate,
            defaultDateFrom: defaultFilter.fromDate,
            defaultDateTo: defaultFilter.toDate,
            displayName: Translation('report.candidate.management.requestPeriod'),
            periodTime: { month: 3 },
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            ...data,
          })
        }
        fetchData={refreshData}
      />
      <PruTableCustom
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('title.report.candidateManagement')}
        isCombineHeader={true}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsv(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row: any) => '',
          },
          {
            keyIndex: 'agentCode',
            displayName: Translation('report.candidate.management.agCode'),
            align: 'center',
            rowSpan: 2,
            renderData: (row: any) => row.agentCode,
          },
          {
            keyIndex: 'role',
            displayName: Translation('report.candidate.management.role'),
            align: 'center',
            rowSpan: 2,
            renderData: (row: any) => row.role,
          },
          {
            keyIndex: 'unitCode',
            displayName: Translation('report.candidate.management.unitCode'),
            align: 'center',
            rowSpan: 2,
            renderData: (row: any) => row.unitCode,
          },
          {
            keyIndex: 'branchCode',
            displayName: Translation('report.candidate.management.branchCode'),
            align: 'center',
            rowSpan: 2,
            renderData: (row: any) => row.branchCode,
          },
          {
            keyIndex: 'officeCode',
            displayName: Translation('report.candidate.management.officeCode'),
            align: 'center',
            rowSpan: 2,
            renderData: (row: any) => row.officeCode,
          },
          {
            keyIndex: 'gadCode',
            displayName: Translation('report.candidate.management.gadCode'),
            align: 'center',
            rowSpan: 2,
            renderData: (row: any) => row.gadCode,
          },
          {
            keyIndex: 'noOfAddedCandidate',
            displayName: Translation('report.candidate.management.noOfAddedCandidate'),
            align: 'center',
            colspan: 4,
            renderData: (row: any) => '',
          },
          {
            keyIndex: 'landingPage',
            displayName: Translation('report.candidate.management.landingPage'),
            align: 'center',
            isCombine: true,
            renderData: (row: any) => (row.landingPage || 0).toString(),
          },
          {
            keyIndex: 'manual',
            displayName: Translation('report.candidate.management.manual'),
            align: 'center',
            isCombine: true,
            renderData: (row: any) => (row.manual || 0).toString(),
          },
          {
            keyIndex: 'contact',
            displayName: Translation('report.candidate.management.contact'),
            align: 'center',
            isCombine: true,
            renderData: (row: any) => (row.contact || 0).toString(),
          },
          {
            keyIndex: 'adminAssign',
            displayName: Translation('report.candidate.management.adminAssign'),
            align: 'center',
            isCombine: true,
            renderData: (row: any) => (row.adminAssign || 0).toString(),
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default CandidateManagementReport;
