import { PaginateListMore } from '../../../common/types/common-types';
import { apiClient, apiErrorHandler } from 'src/app/common/network';
import { Dispatch } from 'react';
import {
  IAgentLeaderReportFilter,
  IAgentLeaderReportItem,
  ITotalAgentLeaderSurvey,
} from '../types/agent-leader-survey-types';
import moment from 'moment';
import { read, write, utils } from 'xlsx';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

export const fetchAgentLeaderSurveyReport = async (
  params: IAgentLeaderReportFilter,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<IAgentLeaderReportItem>> => {
  const url = `${ADMIN_URL}/report/agent-leader-survey/list`;

  const newParams = { ...params };
  if (newParams.summitedMonth) {
    newParams.fromDate = moment(newParams.summitedMonth).startOf('months').format();
    newParams.toDate = moment(newParams.summitedMonth).endOf('months').format();

    delete newParams.summitedMonth;
  }

  return apiClient
    .post(url, newParams)
    .then((response: any) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchTotalRecordAgentLeaderSurveyReport = async (
  params: IAgentLeaderReportFilter,
  dispatch?: Dispatch<any>,
): Promise<ITotalAgentLeaderSurvey> => {
  const url = `${ADMIN_URL}/report/agent-leader-survey/total`;

  const newParams = { ...params };
  if (newParams.summitedMonth) {
    newParams.fromDate = moment(newParams.summitedMonth).startOf('months').format();
    newParams.toDate = moment(newParams.summitedMonth).endOf('months').format();

    delete newParams.summitedMonth;
  }

  return apiClient
    .post(url, newParams)
    .then((response: any) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchAgentLeaderSurveyReportFile = (params: IAgentLeaderReportFilter, dispatch?: Dispatch<any>): void => {
  const url = `${ADMIN_URL}/report/agent-leader-survey/export`;

  const newParams = { ...params };
  if (newParams.summitedMonth) {
    newParams.fromDate = moment(newParams.summitedMonth).startOf('months').format();
    newParams.toDate = moment(newParams.summitedMonth).endOf('months').format();

    delete newParams.summitedMonth;
  }

  apiClient
    .post(url, newParams, { responseType: 'text' })
    .then((response: any) => {
      const csvBlob = new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' });

      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e?.target?.result;
        const workbook = read(csvData, { type: 'binary' });

        const sheetName = workbook.SheetNames[0];

        workbook.Sheets[sheetName]['!cols'] = [
          { wch: 20 },
          { wch: 10 },
          { wch: 10 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 10 },
          { wch: 20 },
        ];

        const excelData = write(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelData], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(excelBlob);
        downloadLink.download = 'joinfieldwork-report-' + moment().format('DDMMYYYY') + '.xlsx';
        downloadLink.click();
      };
      reader.readAsBinaryString(csvBlob);
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
