import React, { FC, useMemo, useReducer, useState } from 'react';
import {
  MdrtVideoLibraryDetail,
  MdrtVideoLibraryFormMode,
  MdrtVideoLibraryFormState,
  PublishStatus,
} from '../../../../types/mdrt-video-library-types';
import { Button, TextField } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils/form-error-utils';
import { MANDATORY_FIELD_ERROR_TEXT, MDRT_HUB_BASE_PATH, MDRT_VIDEO_LIBRARY_BASE_PATH } from '../../../../constants';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { create, update } from 'src/app/modules/MdrtHub/network/mdrtVideoLibraryCrud';
import { makeStyles } from 'tss-react/mui';

export const LINK_INVALID_ERROR = 'component.form-link-text';
type MdrtVideoLibraryDetailFormProps = {
  itemId?: string;
  formMode: MdrtVideoLibraryFormMode;
  itemDetail?: MdrtVideoLibraryDetail;
  onReload: () => void;
  onRouteTo: (route: string) => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  rowContainerFlexStart: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  fieldContainer: {
    width: 120,
    boxSizing: 'border-box',
  },
  fieldContainer2: {
    paddingLeft: 20,
    width: 240,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  dateDivider: {
    marginLeft: 8,
    marginRight: 8,
  },
  sectionMargin: {
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },

  flexItem: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '33.333333%',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  iconContainer: {
    width: 60,
    height: 60,
    overflow: 'hidden',
    borderRadius: 12,
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  root: {
    '& .MuiFormHelperText-root': {
      fontSize: '0.95rem',
      marginLeft: '0px !important',
    },
  },
}));

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof MdrtVideoLibraryFormState;
    value: any;
  };
};

type MdrtFormAction = ModifyFieldAction;

const initialState: MdrtVideoLibraryFormState = {
  id: undefined,
  title: '',
  content: '',
  link: '',
};

const formReducer = (state: MdrtVideoLibraryFormState, action: MdrtFormAction): MdrtVideoLibraryFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};

const detailToStateConvertor = (detail: MdrtVideoLibraryDetail): MdrtVideoLibraryFormState => {
  return {
    id: detail.id,
    title: detail.title,
    content: detail.content,
    link: detail.link,
  };
};

const MdrtVideoLibraryDetailForm: FC<MdrtVideoLibraryDetailFormProps> = ({
  itemId,
  formMode,
  itemDetail,
  onReload,
  onRouteTo,
}) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [isLoading, setIsLoading] = useState(false);
  const [formState, formDispatch] = useReducer(
    formReducer,
    itemDetail ? detailToStateConvertor(itemDetail) : initialState,
  );
  const isDisabled = useMemo(() => {
    return formMode === MdrtVideoLibraryFormMode.CREATE || formMode === MdrtVideoLibraryFormMode.EDIT ? false : true;
  }, [formMode]);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'title',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'content',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'link',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () =>
        !formState.link || (!formState.link.startsWith('https://') && !formState.link.startsWith('http://')),
    },
  ]);

  const onSubmitForm = async (status: PublishStatus) => {
    setIsLoading(true);
    let { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      const detail: MdrtVideoLibraryDetail = {
        title: formState.title || '',
        content: formState.content || '',
        link: formState.link,
        publishStatus: status,
      };
      try {
        if (formMode === MdrtVideoLibraryFormMode.CREATE) {
          await create(detail, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Saved successfully - ${detail.title}`,
              },
            ]),
          );
        } else {
          detail.id = itemId;
          await update(detail, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Updated successfully - ${detail.title}`,
              },
            ]),
          );
        }
        onRouteTo(`${MDRT_HUB_BASE_PATH}${MDRT_VIDEO_LIBRARY_BASE_PATH}`);
      } catch (err) {}
    }
    setTimeout(() => setIsLoading(false), 2000);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>
              {Translation(
                formMode === MdrtVideoLibraryFormMode.CREATE
                  ? 'app.button.add'
                  : formMode === MdrtVideoLibraryFormMode.EDIT
                  ? 'app.button.edit'
                  : 'app.button.view',
              )}
            </div>
          </div>
          <Button
            variant="contained"
            onClick={() => onRouteTo(`${MDRT_HUB_BASE_PATH}${MDRT_VIDEO_LIBRARY_BASE_PATH}/list`)}
          >
            Back
          </Button>
        </div>

        <div className={classes.sectionMargin}>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('mdrtHub.mdrtVideoLibrary.detail.title')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                style={{ width: '100%' }}
                disabled={isDisabled}
                error={errorState.mandatory.title}
                margin="dense"
                variant="outlined"
                className={classes.root}
                helperText={errorState.mandatory.title && MANDATORY_FIELD_ERROR_TEXT}
                value={formState.title}
                onChange={(e) => {
                  onDismissErrorHandler('title', e.target.value);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'title', value: e.target.value },
                  });
                }}
                inputProps={{ maxLength: 250 }}
              />
            </div>
          </div>
        </div>

        <div className={classes.sectionMargin}>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('mdrtHub.mdrtVideoLibrary.detail.content')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                style={{ width: '100%' }}
                disabled={isDisabled}
                margin="dense"
                variant="outlined"
                multiline
                fullWidth
                className={classes.root}
                value={formState.content}
                error={errorState.mandatory.content}
                InputProps={{
                  classes: {
                    input: classes.textArea,
                  },
                }}
                helperText={errorState.mandatory.content && MANDATORY_FIELD_ERROR_TEXT}
                onChange={(e) => {
                  onDismissErrorHandler('content', e.target.value);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'content', value: e.target.value },
                  });
                }}
                inputProps={{ maxLength: 250 }}
              />
            </div>
          </div>
        </div>

        <div className={classes.sectionMargin}>
          <div className={classes.rowContainerFlexStart}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('mdrtHub.mdrtVideoLibrary.detail.link')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 0.8 }}>
              <TextField
                placeholder={Translation('mdrtHub.mdrtVideoLibrary.detail.link.placeHolder')}
                style={{ width: '100%' }}
                className={classes.root}
                disabled={isDisabled}
                margin="dense"
                variant="outlined"
                value={formState.link}
                error={errorState.mandatory.link}
                helperText={errorState.mandatory.link && Translation(LINK_INVALID_ERROR)}
                onChange={(e: any) => {
                  onDismissErrorHandler('link', e.target.value);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'link', value: e.target.value },
                  });
                }}
                inputProps={{ maxLength: 250 }}
              />
            </div>
            <div className={classes.fieldContainer2}>
              <span className={classes.field}>{Translation('mdrtHub.mdrtVideoLibrary.detail.tipsLink')}</span>
            </div>
          </div>
        </div>

        <div className={classes.footerContainer}>
          {isDisabled ? (
            <></>
          ) : (
            <>
              <Button
                variant="contained"
                color="secondary"
                disabled={isLoading}
                onClick={() => onSubmitForm(PublishStatus.UNPUBLISHED)}
              >
                Save as Draft
              </Button>
              <Button
                style={{ marginLeft: 25 }}
                variant="contained"
                disabled={isLoading}
                color="secondary"
                onClick={() => onSubmitForm(PublishStatus.PUBLISHED)}
              >
                Publish
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MdrtVideoLibraryDetailForm;
