import {
  initRadioModeConfig,
  TargetApplicantRadioModeConfig,
} from 'src/app/common/components/target-applicant-section-v2';
import { ITypeOfView } from 'src/app/common/types/view-types';
import { PublishAgentEnum, AgentForceEnrolEnum } from 'src/app/modules/event-v2/types';

export type PublishFormState = {
  pubAgent?: PublishAgentEnum;
  pubAgentConfig: TargetApplicantRadioModeConfig;
  agentForceEnrol?: AgentForceEnrolEnum;
  agentForceEnrolConfig: TargetApplicantRadioModeConfig;
  typeOfViews?: ITypeOfView[]
}

type SetFormStateAction = {
  type: 'SET_FORM_STATE';
  payload: {
    value: Partial<PublishFormState>;
  };
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof PublishFormState;
    value: any;
  };
};

export type PublishFormAction = SetFormStateAction | ModifyFieldAction;

export const publishFormReducer = (state: PublishFormState, action: PublishFormAction): PublishFormState => {
  switch (action.type) {
    case 'SET_FORM_STATE':
      return {
        ...state,
        ...action.payload.value,
      };
    case 'MODIFY_FIELD':
      const newState = {
        ...state,
        [action.payload.field]: action.payload.value,
      };
      if (action.payload.field === 'pubAgent') {
        newState.pubAgentConfig = initRadioModeConfig;
      }
      if (action.payload.field === 'agentForceEnrol') {
        newState.agentForceEnrolConfig = initRadioModeConfig;
      }
      return newState;
    default:
      return state;
  }
};
