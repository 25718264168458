export type IReportFilter = {
  planDate: Date | string;
  agentCode: string;
  leaderCode?: string;
  unitCode: string;
  officeCode: string;
  status: string;
  approval: string;
  page: number;
  limit: number;
}

export type IPersonalSalePlanningItem = {
	potentialCustomer: number,
  customerAppointment: number,
  productPresentation: number,
  case: number,
  FYP: number,
  ALApproval: string,
  planDate: string,
  agentCode: string,
  agentRole: string,
  leaderCode: string,
  leaderRole: string,
  unitCode: string,
  officeCode: string,
  salesPlanning: boolean,
  segment: string
}

export type ITeamSalePlanningItem = {
	potentialCustomer: number,
  customerAppointment: number,
  productPresentation: number,
  case: number,
  FYP: number,
  approval: string,
  planDate: string,
  leaderCode: string,
  leaderRole: string,
  leaderName: string,
  unitCode: string,
  unitPUMCode: string,
  officeCode: string,
  salesPlanning: boolean,
  activeAgent: number,
  BDMName: string,
  BDMApproval: string
}

export type ITotalSalePlanning = {
  totalRecords: number;
  totalPages: number;
}

export enum YesAndNo {
  YES = 'Y',
  NO = 'N'
}