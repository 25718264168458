import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
import { QuotesAndCertificateDetail, QuotesAndCertificateFormMode } from '../../../types/quotes-and-certificate-types';
import { fetchItem } from '../../../network/quotesAndCertificateCrud';
import QuotesAndCertificateDetailForm from './components/QuotesAndCertificateDetailForm';
import { LayoutSplashScreen } from 'src/app/layout';

interface StateMdrtType {
  type: QuotesAndCertificateFormMode;
}

const QuotesAndCertificateDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode, itemId } = useMemo(() => {
    const state = location.state as StateMdrtType;
    return {
      formMode: state.type,
      itemId: id ? id : undefined,
    };
  }, [id, location]);

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemDetail, setItemDetail] = useState<QuotesAndCertificateDetail>();

  const reloadData = () => {
    if (itemId) {
      setIsLoading(true);
      fetchItem(itemId, dispatch)
        .then((result) => {
          setItemDetail(result);
        })
        .catch(() => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [itemId]);

  return (
    <>
      {formMode === QuotesAndCertificateFormMode.CREATE && (
        <QuotesAndCertificateDetailForm
          formMode={formMode}
          onReload={reloadData}
          onRouteTo={(route) => history.push(route)}
        />
      )}
      {formMode !== QuotesAndCertificateFormMode.CREATE &&
        (isLoading || !itemDetail ? (
          <LayoutSplashScreen />
        ) : (
          <QuotesAndCertificateDetailForm
            itemId={itemId}
            formMode={formMode}
            itemDetail={itemDetail}
            onReload={reloadData}
            onRouteTo={(route) => history.push(route)}
          />
        ))}
    </>
  );
};

export default QuotesAndCertificateDetailPage;
