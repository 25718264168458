import { forEach } from 'lodash';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { LeadReassignmentStatusItem, LeadReassignmentFailedItem } from '../types/lead-reassignment-types';
import { getDayStart, getDayEnd } from 'src/app/common/utils';
import { PaginateList } from '../../../common/types/common-types';
import { Dispatch } from 'react';

const leadReassignmentEndpoint = 'lead-reassignment';

export type LeadReassignmentListParam = {
  filename?: string;
  leadReassignmentType?: string;
  updatedDateFrom?: Date | null;
  updatedDateTo?: Date | null;
  page: number;
  limit: number;
};

export type LeadReassignmentFailedListParam = {
  page: number;
  limit: number;
};

export type CreateLeadReassignmentFileBody = {
  resourceId?: string;
  filename?: string;
};

export const fetchLeadReassignmentStatusList = async (
  params: LeadReassignmentListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<LeadReassignmentStatusItem>> => {
  let queryPath = `${ADMIN_URL}/${leadReassignmentEndpoint}/list?`;
  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.toLowerCase().includes('from')) {
          queryPath += `${key}=${encodeURIComponent(getDayStart(param).toISOString())}&`;
        }
        if (key.toLowerCase().includes('to')) {
          queryPath += `${key}=${encodeURIComponent(getDayEnd(param).toISOString())}&`;
        }
      }
    } else {
      if (key === 'page') {
        queryPath += `${key}=${encodeURIComponent(param as any)}&`;
      } else {
        queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
      }
    }
  });
  return apiClient
    .get<PaginateList<LeadReassignmentStatusItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchLeadReassignmentFailedList = async (
  resourceId: string,
  params: LeadReassignmentFailedListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<LeadReassignmentFailedItem>> => {
  let queryPath = `${ADMIN_URL}/${leadReassignmentEndpoint}/${resourceId}?`;
  forEach(params, (param, key) => {
    if (key === 'page') {
      queryPath += `${key}=${encodeURIComponent(param as any)}&`;
    } else {
      queryPath += param ? `${key}=${encodeURIComponent(param as any)}&` : '';
    }
  });
  return apiClient
    .get<PaginateList<LeadReassignmentFailedItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const isLeadReassignmentFileExist = async (filename: string, dispatch?: Dispatch<any>): Promise<boolean> => {
  return apiClient
    .get<boolean>(`${ADMIN_URL}/${leadReassignmentEndpoint}/upload/${filename}/exist`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createLeadReassignmentFile = async (
  body: CreateLeadReassignmentFileBody,
  dispatch?: Dispatch<any>,
): Promise<void> => {
  return apiClient
    .post<void>(`${ADMIN_URL}/${leadReassignmentEndpoint}/upload`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const downloadLeadReassignmentCsv = async (dispatch?: Dispatch<any>): Promise<string> => {
  return apiClient
    .get<string>(`${ADMIN_URL}/${leadReassignmentEndpoint}/upload/download-template`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
