import { Checkbox, FormControl, FormControlLabel, FormHelperText, MenuItem, Select, TextField } from '@mui/material';
import React, { FC, useState, useEffect, useMemo, useReducer } from 'react';
import { Button } from '@mui/material';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useProfileStyles } from 'src/app/modules/Profile/profileStyle';
import {
  awardTypeEnum,
  AwardTypeListItem,
  FormMode,
  imageUploadType,
  MultiLanguage,
} from 'src/app/modules/Profile/types/agent-profile-types';
import { createBlob, getBlob } from 'src/app/common/network';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { getConfig, I18nGenericData } from 'src/app/i18n';
import { createAwardType, deleteAwardType, modifyAwardType } from 'src/app/modules/Profile/network/agentProfileCrud';
import { awardTypeListPath } from '../AwardTypeListRoute';
import { ErrorFieldType, useErrorHandler, fileUpload, getFileExtendingName } from 'src/app/common/utils';
import ConfirmDialog from 'src/app/modules/PulseLeads/pages/Rule/ActivityPoint/PointRuleSettings/List/component/ComfirnDialog';
import { PruToast } from 'src/app/common/components/pru-toast';
import ImageWithCloseButton from 'src/app/common/components/ImageWithCloseButton';

type UploadFormState = {
  blobId?: string;
  filename?: string;
};
type ManualUpdateFormState = {
  icon: UploadFormState;
};
type UploadIconFormState = {
  image: string;
  name: I18nGenericData<string>;
  awardCode: string;
  iconUrl: string;
  year: string;
  type: string;
};
const initialState: UploadIconFormState = {
  image: '',
  year: '',
  awardCode: '',
  name: { enUs: '', zhHk: '' },
  iconUrl: '',
  type: '',
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof UploadIconFormState;
    value: any;
  };
};
export type ModifyNestedAction = {
  type: 'MODIFY_CONTENT';
  payload: {
    field: keyof UploadIconFormState;
    subField: any;
    value: any;
  };
};
type BannerTierFormAction = ModifyFieldAction | ModifyNestedAction;
const formReducer = (state: UploadIconFormState, action: BannerTierFormAction): UploadIconFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'MODIFY_CONTENT':
      if (action.payload.field === 'name') {
        state.name[action.payload.subField as keyof MultiLanguage] = action.payload.value;
      }

      return {
        ...state,
      };
    default:
      return state;
  }
};
type DialogState = {
  open: boolean;
  id: string;
  method: string;
};
enum ToolTip {
  SUBMIT = 'agentProfile.ad_set_default_message',
  CANCEL = 'agentProfile.ad_reminder_cancel_message',
  DELETE = 'agentProfile.ad_delete_message',
}
type UploadAwardDetailFormProps = {
  formMode: FormMode;
  uploadAwardDetail?: any;
};
const detailToStateConvertor = (awardType?: AwardTypeListItem): UploadIconFormState => {
  return awardType
    ? {
        name: awardType?.name || { enUs: '', zhHk: '' },
        year: awardType?.year || '',
        image: awardType?.image || '',
        awardCode: awardType?.awardCode || '',
        iconUrl: awardType?.image,
        type: awardType?.type,
      }
    : initialState;
};

const UploadAwardTypeForm = ({ formMode, uploadAwardDetail }: UploadAwardDetailFormProps) => {
  const dispatch = useDispatch();
  const { classes } = useProfileStyles();
  const history = useHistory();
  const { classes: commonClasses } = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, val: number | string) => intl.formatMessage({ id: key }, { lang: val });
  const lang = getConfig().selectedLang;
  const [formState, formDispatch] = useReducer(formReducer, detailToStateConvertor(uploadAwardDetail));
  const [uploadFileProgress, setUploadFileProgress] = useState<number>();
  const checkBoxLabel = Translation('local_language');
  const title =
    formMode === FormMode.CREATE ? 'agentProfile.ad_add_new_award_header_title' : 'agentProfile.ad_edit_award_label';
  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
    method: '',
  });
  const [toolTip, setToolTip] = useState<string>();
  const onClose = () => {
    setDialogState({ open: false, id: '', method: '' });
    setToolTip('');
  };
  const cancel = () => {
    setToolTip(Translation(ToolTip.CANCEL));
    setDialogState({ open: true, id: '', method: 'cancel' });
  };
  const onDelete = async (id: string) => {
    try {
      await deleteAwardType(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Award deleted successfully`,
          },
        ]),
      );
      history.push(awardTypeListPath);
    } catch (err) {}
  };
  const onConfirm = () => {
    if (dialogState.method === 'cancel') {
      PruToast({
        message: Translation('agentProfile.ad_cancelled_successfully_label'),
      });
      history.push(awardTypeListPath);
    } else if (dialogState.method === 'submit') {
      onSubmit();
    } else if (dialogState.method === 'onDelete') {
      onDelete(uploadAwardDetail.typeId);
    }
  };
  useEffect(() => {
    const bannerId = formState.image || '';
    const icon = getResourceById(bannerId);
    return () => {
      icon;
    };
  }, []);
  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, immediateErrorValidator } = useErrorHandler(
    formState,
    [
      {
        name: 'awardCode',
        fieldType: ErrorFieldType.MANDATORY,
        condition() {
          return formState.awardCode ? false : true;
        },
      },
      {
        name: 'type',
        fieldType: ErrorFieldType.MANDATORY,
        condition() {
          return formState.type ? false : true;
        },
      },
      {
        name: 'name',
        fieldType: ErrorFieldType.MANDATORY,
        condition() {
          return !formState.name ? false : formState.name.enUs ? false : true;
        },
      },
    ],
  );
  const getResourceById = async (id: string) => {
    try {
      if (!id) return;
      const blobDetail = await getBlob({ resourceIds: id }, dispatch);

      const result = blobDetail[0];

      if (result) {
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'iconUrl', value: result.url } });
      }
    } catch (e) {
      console.log('get resource error -->', e);
    }
  };
  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      try {
        const createBlobRes = await createBlob(
          { mimeType: file.type, accessLevel: 'public', originalFilename: file.name, module: 'agentProfile' },
          dispatch,
        );
        await fileUpload(createBlobRes.url, file, setUploadFileProgress);
        const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
        const result = blobDetail[0];

        if (result) {
          formDispatch({
            type: 'MODIFY_FIELD',
            payload: {
              field: 'image',

              value: result.blobId,
            },
          });
          formDispatch({
            type: 'MODIFY_FIELD',
            payload: {
              field: 'iconUrl',

              value: result.url,
            },
          });

          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Upload file successfully - ${file.name}`,
              },
            ]),
          );
        }
      } finally {
        setUploadFileProgress(undefined);
      }
    }
  };
  const onSubmit = async () => {
    const { hasError, currentErrorState } = onSubmitErrorValidator();

    if (hasError) return;

    if (formState.name.zhHk === '') formState.name.zhHk = formState.name.enUs;

    try {
      const body: any = {
        name: formState.name,
        awardCode: formState.awardCode,
        // year: !!formState.year ? formState.year : null,
        image: formState.image,
        type: formState.type,
      };

      if (body.image.includes('https')) delete body.image;
      if (!body.image) delete body.image;
      if (formMode === FormMode.CREATE) {
        await createAwardType(body, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Agent Profile updated successfully`,
            },
          ]),
        );
        history.push(awardTypeListPath);
      } else if (formMode === FormMode.EDIT) {
        await modifyAwardType(body, uploadAwardDetail.typeId, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Agent Profile updated successfully`,
            },
          ]),
        );
        history.push(awardTypeListPath);
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>{Translation(title)}</div>
          </div>
          <ConfirmDialog
            key={`delete-dialog-${dialogState.open}`}
            open={dialogState.open}
            onClose={() => onClose()}
            onRefresh={() => ''}
            onConfirm={async () => onConfirm()}
            title={Translation('agentProfile.ad_reminder_title')}
            content={toolTip}
          />
        </div>
        {formMode === FormMode.CREATE ? (
          <div className={classes.rowContainer} style={{ marginTop: 8 }}>
            <div className={classes.longFieldContainer}>
              <span className={classes.field}>
                {Translation('agentProfile.ad_award_code_label')} <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div>
              <div style={{ flexGrow: 1 }}>
                <TextField
                  style={{ minWidth: 310 }}
                  margin="dense"
                  variant="outlined"
                  value={formState.awardCode}
                  error={errorState.mandatory.awardCode && !formState.awardCode}
                  helperText={errorState.mandatory.awardCode && !formState.awardCode && 'This field is required'}
                  onChange={(e) => {
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'awardCode', value: e.target.value },
                    });
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className={classes.rowContainer}>
              <div className="col">
                <div className={classes.longFieldContainer}>
                  <span className={classes.field}>
                    {Translation('agentProfile.ad_award_en_label')}
                    <span className={classes.mandatory}>*</span> :
                  </span>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <TextField
                    disabled={true}
                    style={{ width: 320 }}
                    margin="dense"
                    value={formState.name.enUs}
                    inputProps={{ maxLength: 50 }}
                    error={errorState.mandatory.name && !formState.name.enUs}
                    helperText={errorState.mandatory.name && !formState.name.enUs && 'This field is required'}
                    onChange={(e) => {
                      formDispatch({
                        type: 'MODIFY_CONTENT',
                        payload: { field: 'name', subField: 'enUs', value: e.target.value },
                      });
                    }}
                  />
                </div>
              </div>

              <div className="col">
                <div className={classes.longFieldContainer}>
                  <span className={classes.field}>{Translation('agentProfile.ad_award_local_label')}:</span>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <TextField
                    // disabled={true}
                    style={{ width: 320 }}
                    margin="dense"
                    value={formState.name.zhHk}
                    inputProps={{ maxLength: 50 }}
                    // error={errorState.mandatory.name && !formState.name.zhHk}
                    // helperText={errorState.mandatory.name && !formState.name.zhHk && 'This field is required'}
                    onChange={(e) => {
                      formDispatch({
                        type: 'MODIFY_CONTENT',
                        payload: { field: 'name', subField: 'zhHk', value: e.target.value },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {formMode === FormMode.CREATE ? (
          <>
            <div className={classes.rowContainer} style={{ marginTop: 8 }}>
              <div className={classes.longFieldContainer}>
                <span className={classes.field}>{Translation('agentProfile.ad_award_local_label')} :</span>
              </div>
              <div>
                <div style={{ flexGrow: 1 }}>
                  <TextField
                    style={{ minWidth: 310 }}
                    margin="dense"
                    variant="outlined"
                    value={formState.name.zhHk}
                    // error={errorState.mandatory.name && !formState.name.zhHk}
                    // helperText={errorState.mandatory.name && !formState.name.zhHk && 'This field is required'}
                    onChange={(e) => {
                      formDispatch({
                        type: 'MODIFY_CONTENT',
                        payload: { field: 'name', subField: 'zhHk', value: e.target.value },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      formDispatch({
                        type: 'MODIFY_CONTENT',
                        payload: {
                          field: 'name',
                          subField: 'enUs',
                          value: formState.name.zhHk,
                        },
                      });
                    }
                  }}
                  name="copyMainLangVersion"
                  color="secondary"
                />
              }
              label={Translation('agentProfile.ad_use_local_option')}
            />
            <div className={classes.rowContainer} style={{ marginTop: 8 }}>
              <div className={classes.longFieldContainer}>
                <span className={classes.field}>
                  {Translation('agentProfile.ad_award_en_label')} <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <div>
                <div style={{ flexGrow: 1 }}>
                  <TextField
                    // disabled
                    style={{ minWidth: 310 }}
                    // error
                    margin="dense"
                    variant="outlined"
                    value={formState.name.enUs}
                    error={errorState.mandatory.name && !formState.name.enUs}
                    helperText={
                      errorState.mandatory.name &&
                      !formState.name.enUs &&
                      Translation('agentProfile.ad_mandatory_field_reminder')
                    }
                    onChange={(e) => {
                      formDispatch({
                        type: 'MODIFY_CONTENT',
                        payload: { field: 'name', subField: 'enUs', value: e.target.value },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={classes.rowContainer} style={{ marginTop: 8 }}>
              <div className={classes.longFieldContainer}>
                <span className={classes.field}>
                  {Translation('agentProfile.ad_award_type_label')}
                  <span className={classes.mandatory}>*</span>:
                </span>
              </div>
              <div>
                <div style={{ flexGrow: 1 }}>
                  <FormControl margin="dense" variant="outlined" className={classes.selectPadding}>
                    <Select
                      style={{ minWidth: 320 }}
                      value={formState.type || ''}
                      onChange={(e) => {
                        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'type', value: e.target.value } });
                      }}
                    >
                      {Object.entries(awardTypeEnum).map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                          {Translation(`dropdown_label_${value}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errorState?.mandatory.type && !formState.type && (
                    <FormHelperText style={{ color: '#f018a6' }}>
                      {Translation('agentProfile.ad_mandatory_field_reminder')}
                    </FormHelperText>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={classes.rowContainer}>
              <div className="col">
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>
                    {Translation('agentProfile.ad_award_code_label')}
                    <span className={classes.mandatory}>*</span> :
                  </span>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <TextField
                    disabled={!!uploadAwardDetail.awardCode}
                    style={{ width: 320 }}
                    margin="dense"
                    value={formState.awardCode}
                    error={errorState.mandatory.awardCode && !formState.awardCode}
                    helperText={
                      errorState.mandatory.awardCode &&
                      !formState.awardCode &&
                      Translation('agentProfile.ad_mandatory_field_reminder')
                    }
                    inputProps={{ maxLength: 50 }}
                    onChange={(e) => {
                      formDispatch({
                        type: 'MODIFY_FIELD',
                        payload: { field: 'awardCode', value: e.target.value },
                      });
                    }}
                  />
                </div>
              </div>

              <div className="col">
                <div className={classes.fieldContainer}>
                  <span className={classes.field}>
                    {Translation('agentProfile.ad_award_type_label')}
                    <span className={classes.mandatory}>*</span>:
                  </span>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <FormControl margin="dense">
                    <Select
                      variant="standard"
                      disabled
                      style={{ minWidth: 320 }}
                      value={formState.type || ''}
                      onChange={(e) => {
                        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'type', value: e.target.value } });
                      }}
                    >
                      {Object.entries(awardTypeEnum).map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                          {Translation(`dropdown_label_${value}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </>
        )}

        <div className={classes.rowContainer} style={{ marginTop: 8 }}>
          <div className={classes.longFieldContainer}>
            <span className={classes.field}>{Translation('agentProfile.ad_award_icon_label')} :</span>

            <div style={{ display: 'flex' }}>
              {formState?.iconUrl && formState?.iconUrl.includes('https') && (
                <div className={classes.imageContainer} style={{ position: 'relative', width: 60, height: 60 }}>
                  <img
                    style={{ width: undefined, height: undefined, aspectRatio: '2:1' }}
                    src={formState?.iconUrl}
                    alt="icon"
                  />
                </div>
              )}
            </div>
          </div>

          <div>
            <input
              id="upload-icon"
              hidden
              type="file"
              accept="image/jpg, image/jpeg, image/png"
              onClick={(e) => {
                const element = e.target as HTMLInputElement;
                element.value = '';
              }}
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  const file = e.target.files[0];
                  const fileExtend = getFileExtendingName(file.name).toLowerCase();
                  if (imageUploadType.has(fileExtend)) {
                    const fileSize = file.size / 1024 / 1024;
                    const errMsg = Translation('agentProfile.ad_exceed_max_file_size_reminder') || '';
                    if (fileSize > 2) {
                      dispatch(
                        appendAlertItem([
                          {
                            severity: AlertType.ERROR,
                            title: '',
                            content: errMsg,
                          },
                        ]),
                      );
                      return;
                    }
                    handleFile(e);
                  } else {
                    dispatch(
                      appendAlertItem([
                        {
                          severity: AlertType.WARNING,
                          title: Translation('fail_title'),
                          content: Translation('agentProfile.ad_upload_fail_file_format_message'),
                        },
                      ]),
                    );
                  }
                }
              }}
            />
            <Button
              style={{ paddingLeft: 8 }}
              variant="contained"
              color="secondary"
              onClick={() => document.getElementById('upload-icon')!.click()}
            >
              {Translation('app.button.chooseFile')}
            </Button>
            {/* {errorState?.mandatory.image && (
              <FormHelperText style={{ color: '#f018a6' }}>This field is mandatory</FormHelperText>
            )} */}
          </div>
        </div>

        <div className={classes.centerFooterContainer}>
          <div
            style={{
              display: 'flex',
              boxSizing: 'border-box',
              minWidth: 350,
              justifyContent: 'space-around',
            }}
          >
            {uploadAwardDetail && uploadAwardDetail.typeId && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setToolTip(Translation(ToolTip.DELETE));
                  setDialogState({ open: true, id: '', method: 'onDelete' });
                }}
              >
                {Translation('app.button.delete')}
              </Button>
            )}
            <Button variant="contained" color="secondary" onClick={() => cancel()}>
              {Translation('app.button.cancel')}
            </Button>
            <Button variant="contained" color="secondary" onClick={() => onSubmit()}>
              {Translation('app.button.submit')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadAwardTypeForm;
