import React, { FC } from 'react';
import { SALESKIT_BASE_PATH, BDM_EVENT_BASE_PATH, BDM_GP_EVENT_BASE_PATH } from 'src/app/modules/sales-kit/constants';
import MaterialLibraryDetailPage from '../../../component/bdm-event/detail';

export const SystemConfig = {
  blockName: 'component.formTitle.sales-material',
  blockType: 'salesMaterial',
  libraryBasePath: `${SALESKIT_BASE_PATH}${BDM_EVENT_BASE_PATH}${BDM_GP_EVENT_BASE_PATH}`,
  categoryType: 'salesMaterialCategory',
  columnType: 'salesMaterialColumn',
  moduleType: 'Sales',
};

const BdmGPEventDetailPage: FC<any> = (props) => {
  return <MaterialLibraryDetailPage {...props} {...SystemConfig} />;
};

export default BdmGPEventDetailPage;
