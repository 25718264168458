import React, { FC } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import ParamsProvider from '../../../../../common/components/ParamsProvider';
import PruRoute from '../../../../../common/components/PruRoute';
import { reportFilterKeys } from '../../../constants';
import IncentiveOpeningReportListingPageV2 from './List/IncentiveOpeningReportListingPageV2';

const IncentiveOpeningReportPageV2: FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/list`} />
      <PruRoute
        exact
        path={`${match.path}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={reportFilterKeys}>
            <IncentiveOpeningReportListingPageV2 />
          </ParamsProvider>
        )}
      />
    </Switch>
  );
};

export default IncentiveOpeningReportPageV2;
