import React, { FC } from "react";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";
import PruRoute from "../../../../common/components/PruRoute";
import EventListingPage from "./List";
import { ModulePermissionProps } from "src/app/common/module/types";
import InvitationLetterDetail from "./Detail";
import InvitationLetterList from "./List";

const EventListPage: React.FC<RouteComponentProps & ModulePermissionProps> = ({
  history,
  location,
  match,
  enableRead,
  enableCreate,
  enableUpdate,
}) => {
  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/list`} />
      <PruRoute
        exact
        path={`${match.path}/list`}
        render={(props) => (
          <InvitationLetterList
            {...props}
            enableRead={enableRead}
            enableCreate={enableCreate}
            enableUpdate={enableUpdate}
          />
        )}
      />
      <PruRoute
        exact
        path={`${match.path}/create`}
        component={InvitationLetterDetail}
      />

      <PruRoute
        exact
        path={`${match.path}/view/:id`}
        render={(props) => (
          <InvitationLetterDetail {...props} enableUpdate={enableUpdate} />
        )}
      />

      <PruRoute
        exact
        path={`${match.path}/edit/:id`}
        component={InvitationLetterDetail}
      />
    </Switch>
  );
};

export default EventListPage;
