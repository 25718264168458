/* eslint-disable @typescript-eslint/no-unused-expressions */

import React, { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import ProTable from 'src/app/common/components/ProTable';
import columns from './columns';
import { getFieldsList } from '../../api';
import moment from 'moment';
import { I18nData, UpdateEngagementFieldsBody } from '../../util';


const useStyles = makeStyles()((theme) => ({
    colorBox: {
        width: 30,
        height: 30,
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: '50%'
    }
}));


const List: FC = () => {
    const { classes } = useStyles();
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });
    const fetchData = async (newFilterState?: any) => {
        const newFilter = newFilterState || {}
        const requestParams = {
            ...{
                page: 1,
                limit: 20
            },
            ...newFilter,
        }
        const result = await getFieldsList(requestParams);
        const dataList = result.data;
        console.log(result.data)
        dataList.forEach((x: UpdateEngagementFieldsBody) => {
            x.updatedAt = moment(x.updatedAt).format('DD/MM/YYYY HH:MM')
        })
        let formatData = dataList;
        const res = {
            data: formatData || [],
            success: true,
            total: dataList?.length
        };

        return res;
    }


    return (
        <div style={{ width: '100%', alignSelf: 'flex-start' }}>
            <ProTable
                rowKey="key"
                filterTitle={''}
                headerTitle={''}
                columns={columns(Translation, classes)}
                pageSize={20}
                request={(params, sorter, filter) => {
                    return fetchData(params)
                }}
                toolBarRender={() => [

                ]}
            />
        </div>
    )
};

export default List;
