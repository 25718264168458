export const REPORT_BASE_PATH = '/report';

export const REPORT_PERFORMANCE_PATH = '/performance';
export const REPORT_INCENTIVE_PATH = '/incentive';
export const REPORT_INCENTIVE_V2_PATH = '/incentive-v2';
export const REPORT_SALE_PATH = '/sale';
export const REPORT_APP_PERF_TRACKING_PATH = '/app-perf-tracking';
export const REPORT_CUSTOMER_CONTACT_TRACKING_PATH = '/customer-contact-report';
export const REPORT_QR_CODE_PATH = '/qr-code';
export const REPORT_SURVEY_PATH = '/survey';
export const REPORT_SERVING_SURVEY_OPENING_PATH = '/serving-survey-opening';
export const REPORT_CUSTOMER_SURVEY_PATH = '/customer-survey';
export const REPORT_NON_PROSPECT_SURVEY_PATH = '/non-prospect-survey-report';
export const REPORT_AGENT_LEADER_SURVEY_PATH = '/agent-leader-survey-report';
export const REPORT_SALE_PLANNING_PATH = '/sale-planning';
export const REPORT_INCENTIVE_OPENING_PATH = '/incentive-opening';
export const REPORT_CANDIDATE_PATH = '/candidate';
export const REPORT_LIVESTREAM_PATH = '/livestream';
export const REPORT_NOTIFICATION_PATH = '/notification';
export const REPORT_EVENT_PATH = '/event';
export const REPORT_CUSTOMER_CAMPAIGN_PATH = '/customer-campaign';

export const REPORT_INCENTIVE_REGISTRATION_PATH = '/registration';
export const REPORT_RECRUITMENT_PATH = '/recruitment';
export const REPORT_NEWS_PATH = '/news';

export const REPORT_PERFORMANCE_PRODUCTION_TARGET_PATH = '/production-target';
export const REPORT_INCENTIVE_RESULT_PATH = '/result';
export const REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_PATH = '/production-mtd-target';
export const REPORT_PERFORMANCE_ADBU_TARGET_PATH = '/adbu-target';
export const REPORT_RECRUITMENT_PRUDNA_SUMMARY_PATH = '/prudna-summary';
export const REPORT_RECRUITMENT_PRUDNA_DETAIL_PATH = '/prudna-detail';
export const REPORT_RECRUITMENT_NEW_REGISTERED_CANDIDATE_PATH = '/new-registered-candidate';
export const REPORT_SALE_CONVERSION_RATE = '/conversion-rate';
export const REPORT_SALE_MATERIAL_PERFORMANCE_TRACKING = '/sales-material-performance-tracking';
export const REPORT_SALE_CUSTOMER_LEAD_MANAGEMENT = '/customer-lead-management';
export const REPORT_APP_PERF_TRACKING_APP_INSTALLATION = '/installment';
export const REPORT_APP_PERF_TRACKING_ACTIVE_USER = '/active-user';
export const REPORT_QR_CODE_QR_ACTIVITY = '/activity';
export const REPORT_QR_CODE_ACTIVITY_RATING = '/activity-rating';
export const REPORT_TEAM_SALE_PLANNING_PATH = '/team';
export const REPORT_PERSONAL_SALE_PLANNING_PATH = '/individual';
export const REPORT_CANDIDATE_ASSESSMENT = '/candidate-assessment';
export const REPORT_CANDIDATE_DOCUMENT = '/candidate-document';
export const REPORT_REFERRAL_CANDIDATE = '/referral-candidate';
export const REPORT_REGISTRATION_CANDIDATE = '/registration-candidate';
export const REPORT_CANDIDATE_NEW_REGISTERED_CANDIDATE = '/new-registered-candidate';
export const REPORT_CANDIDATE_INFORMATION = '/candidate-information';
export const REPORT_CANDIDATE_INTERACTION = '/candidate-interaction';
export const REPORT_CANDIDATE_MANAGEMENT = '/candidate-management';
export const REPORT_CANDIDATE_SURVEY = '/candidate-survey';
export const REPORT_CANDIDATE_PERFORMANCE = '/candidate-performance';

export const REPORT_LIVESTREAM_DETAILED_COMMENT = '/livestream-detail-comment';
export const REPORT_LIVESTREAM_ACTIVITY = '/livestream-activity';
export const REPORT_LIVESTREAM_PERFORMANCE = '/livestream-performance';

export const REPORT_MDRT_HUB_PATH = '/mdrt-hub';
export const REPORT_MDRT_HUB_SHARING_ACTIVITY_PATH = '/sharing-activity';
export const REPORT_OPENING_NOTIFICATION = '/opening-notification';

export const REPORT_EVENT_CUSTOMER = '/event-customer';
export const REPORT_EVENT_CUSTOMER_AG_VIEW = '/event-customer-ag-view';
export const REPORT_EVENT_CANDIDATE = '/event-candidate';
export const REPORT_RECRUITMENT_PRUDNA_MASS_SUMMARY_PATH = '/prudna-mass-summary';
export const REPORT_RECRUITMENT_PRUDNA_MASS_DETAIL_PATH = '/prudna-mass-detail';
export const REPORT_NEWS_AGENTS = '/agents';
export const REPORT_NEWS_COMMENTS = '/comments';
export const REPORT_NEWS_INFO = '/info';
export const REPORT_NEWS_AGENT_ACTIVITY = '/agentActivity';

export const reportFilterKeys = ['requestedDateFrom', 'requestedDateTo'];

export const MANDATORY_FIELD_ERROR_TEXT = 'This field is mandatory';
export const DATE_ERROR_TEXT = 'End date must be after Begin date';

//  These constants map to aside menu, header menu to display title
export const REPORT_TITLE = 'title.report';
export const REPORT_PERFORMANCE_TITLE = 'title.report.performance';
export const REPORT_PERFORMANCE_PRODUCTION_TARGET_TITLE = 'title.report.performance.productionTarget';
export const REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_TITLE = 'title.report.performance.productionTargetMtd';
export const REPORT_PERFORMANCE_ADBU_TARGET_TITLE = 'title.report.performance.adbuTarget';
export const REPORT_INCENTIVE_TITLE = 'title.report.incentive';
export const REPORT_INCENTIVE_RESULT_TITLE = 'title.report.incentive.result';
export const REPORT_INCENTIVE_REGISTRATION_TITLE = 'title.report.incentive.registration';

export const REPORT_RECRUITMENT_NEW_REGISTERED_CANDIDATE_TITLE = 'title.report.recruitment.newRegisteredCandidate';
export const REPORT_SALE_TITLE = 'title.report.sale';
export const REPORT_SALE_CONVERSION_RATE_TITLE = 'title.report.sale.conversionRate';
export const REPORT_SALE_MATERIAL_PERFORMANCE_TRACKING_TITLE = 'title.report.sale.salesMPerformanceTracking';
export const REPORT_SALE_CUSTOMER_LEAD_MANAGEMENT_TITLE = 'title.report.sale.customerLeadManagement';
export const REPORT_APP_PERF_TRACKING_TITLE = 'title.report.appPerfTracking';
export const REPORT_APP_PERF_TRACKING_INSTALLMENT_TITLE = 'title.report.appPerfTracking.appInstallment';
export const REPORT_APP_PERF_TRACKING_ACTIVE_USER_TITLE = 'title.report.appPerfTracking.activeUser';
export const REPORT_QR_CODE_TITLE = 'title.report.qrCode';
export const REPORT_QR_CODE_QR_ACTIVITY_TITLE = 'title.report.qrCode.qr.activity';
export const REPORT_QR_CODE_ACTIVITY_RATING_TITLE = 'title.report.qrCode.activity.rating';
export const REPORT_CUSTOMER_CONTACT_TRACKING_TITLE = 'title.report.customerContactTracking';
export const REPORT_SURVEY_TITLE = 'title.report.survey';
export const REPORT_SURVING_SURVEY_OPENING_TITLE = 'title.report.servingSurveyOpening';
export const REPORT_CUSTOMER_SURVEY_TITLE = 'title.report.customerSurvey';
export const REPORT_NON_PROSPECT_SURVEY_TITLE = 'title.report.nonProspectSurvey';
export const REPORT_AGENT_LEADER_SURVEY_TITLE = 'title.report.agentLeaderSurvey';
export const REPORT_INCENTIVE_OPENING_TITLE = 'title.report.incentiveOpening';
export const REPORT_SALE_PLANNING_TITLE = 'title.report.salePlanning';
export const REPORT_TEAM_SALE_PLANNING_TITLE = 'title.report.teamSalePlanning';
export const REPORT_PERSONAL_SALE_PLANNING_TITLE = 'title.report.personalSalePlanning';
export const REPORT_CANDIDATE_TITLE = 'title.report.candidate';
export const REPORT_CANDIDATE_ASSESSMENT_TITLE = 'title.report.candidateAssessment';
export const REPORT_CANDIDATE_DOCUMENT_TITLE = 'title.report.candidateDocument';
export const REPORT_REFERRAL_CANDIDATE_TITLE = 'title.report.referralCandidate';
export const REPORT_REGISTRATION_CANDIDATE_TITLE = 'title.report.registrationCandidate';
export const REPORT_NEW_REGISTERED_CANDIDATE_TITLE = 'title.report.candidate.newRegisteredCandidate';
export const REPORT_CANDIDATE_INFORMATION_TITLE = 'title.report.candidate.candidateInformation';
export const REPORT_CANDIDATE_INTERACTION_TITLE = 'title.report.candidate.candidateInteraction';
export const REPORT_CANDIDATE_MANAGEMENT_TITLE = 'title.report.candidateManagement';
export const REPORT_CANDIDATE_SURVEY_TITLE = 'title.report.candidateSurvey';
export const REPORT_CANDIDATE_PERFORMANCE_TITLE = 'title.report.candidatePerformance';
export const REPORT_RECRUITMENT_TITLE = 'title.report.recruitment';
export const REPORT_RECRUITMENT_PRUDNA_SUMMARY_TITLE = 'title.report.recruitment.prudnaSummary';
export const REPORT_RECRUITMENT_PRUDNA_DETAIL_TITLE = 'title.report.recruitment.prudnaDetail';
export const REPORT_RECRUITMENT_MASS_PRUDNA_SUMMARY_TITLE = 'title.report.recruitment.mass.prudnaSummary';
export const REPORT_RECRUITMENT_MASS_PRUDNA_DETAIL_TITLE = 'title.report.recruitment.mass.prudnaDetail';
export const REPORT_NEWS_TITLE = 'title.report.NEWS';
export const REPORT_NEWS_AGENTS_TITLE = 'title.report.NEWS.agents';
export const REPORT_NEWS_COMMENTS_TITLE = 'title.report.NEWS.comments';
export const REPORT_NEWS_INFO_TITLE = 'title.report.NEWS.info';
export const REPORT_NEWS_AGENT_ACTIVITY_TITLE = 'title.report.NEWS.agentActivity';

export const REPORT_LIVESTREAM_TITLE = 'livestream.title';
export const REPORT_LIVESTREAM_MANAGEMENT_TITLE = 'livestream.management.title';
export const REPORT_LIVESTREAM_DETAILED_COMMENT_TITLE = 'livestream.detailedComment.title';
export const REPORT_LIVESTREAM_ACTIVITY_TITLE = 'livestream.activity.title';
export const REPORT_LIVESTREAM_PERFORMANCE_TITLE = 'livestream.performance.title';
export const REPORT_MDRT_HUB_TITLE = 'route.mdrtHub';
export const REPORT_MDRT_HUB_SHARING_ACTIVITY_TITLE = 'mdrtHub.report.sharingActivity.list';
export const REPORT_NOTIFICATION_TITLE = 'title.report.notification';
export const REPORT_EVENT_TITLE = 'title.report.event';
export const REPORT_OPENING_NOTIFICATION_TITLE = 'title.report.openingNotification';
export const REPORT_EVENT_CUSTOMER_TITLE = 'title.report.eventCustomer';
export const REPORT_EVENT_CUSTOMER_TITLE_AG_VIEW = 'title.report.eventCustomerAGView';
export const REPORT_EVENT_CANDIDATE_TITLE = 'title.report.eventCandidate';
export const REPORT_CUSTOMER_CAMPAIGN_TITLE = 'Report Customer Campaign';
//  These constants map to permission control, identical to permission enum defined below
export const REPORT_PERFORMANCE_PRODUCTION_TARGET_NAMESPACE = 'REPORT-PERFORMANCE-PRODUCTION-TARGET';
export const REPORT_INCENTIVE_RESULT_NAMESPACE = 'REPORT-INCENTIVE-RESULT';
export const REPORT_INCENTIVE_REGISTRATION_NAMESPACE = 'REPORT-INCENTIVE-REGISTRATION';
export const REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_NAMESPACE = 'REPORT-PERFORMANCE-PRODUCTION-TARGET-MTD';
export const REPORT_PERFORMANCE_ADBU_TARGET_NAMESPACE = 'REPORT-PERFORMANCE-ADBU-TARGET';
export const REPORT_RECRUITMENT_PRUDNA_SUMMARY_NAMESPACE = 'REPORT-RECRUITMENT-PRUDNA-SUMMARY';
export const REPORT_RECRUITMENT_PRUDNA_DETAIL_NAMESPACE = 'REPORT-RECRUITMENT-PRUDNA-DETAIL';
export const REPORT_RECRUITMENT_NEW_REGISTERED_CANDIDATE_NAMESPACE = 'REPORT-RECRUITMENT-NEW-REGISTERED-CANDIDATE';
export const REPORT_SALE_CONVERSION_RATE_NAMESPACE = 'REPORT-SALE-CONVERSION-RATE';
export const REPORT_SALE_MATERIAL_PERFORMANCE_TRACKING_NAMESPACE = 'REPORT-SALES-MATERIAL-PERFORMANCE-TRACKING';
export const REPORT_SALE_CUSTOMER_LEAD_MANAGEMENT_NAMESPACE = 'REPORT-SALE-CUSTOMER-LEAD-MANAGEMENT';
export const REPORT_APP_PERF_TRACKING_INSTALLMENT_NAMESPACE = 'REPORT-APP-PERF-TRACKING-INSTALLMENT';
export const REPORT_APP_PERF_TRACKING_ACTIVE_USER_NAMESPACE = 'REPORT-APP-PERF-TRACKING-ACTIVE-USER';
export const REPORT_QR_CODE_QR_ACTIVITY_NAMESPACE = 'REPORT-QR-CODE-QR-ACTIVITY';
export const REPORT_QR_CODE_ACTIVITY_RATING_NAMESPACE = 'REPORT-QR-CODE-ACTIVITY-RATING';
export const REPORT_SERVING_SURVEY_OPENING_NAMESPACE = 'REPORT-SERVING-SURVEY-OPENING';
export const REPORT_CUSTOMER_SURVEY_NAMESPACE = 'REPORT-CUSTOMER-SURVEY';
export const REPORT_INCENTIVE_OPENING_NAMESPACE = 'REPORT-INCENTIVE-OPENING';
export const REPORT_NON_PROSPECT_SURVEY_NAMESPACE = 'REPORT-NON-PROSPECT-SURVEY';
export const REPORT_AGENT_LEADER_SURVEY_NAMESPACE = 'REPORT-AGENT-LEADER-SURVEY';
export const REPORT_CUSTOMER_CONTACT_TRACKING_NAMESPACE = 'REPORT-CUSTOMER-CONTACT-TRACKING';
export const REPORT_TEAM_SALE_PLANNING_NAMESPACE = 'REPORT-TEAM-SALE-PLANNING';
export const REPORT_PERSONAL_SALE_PLANNING_NAMESPACE = 'REPORT-PERSONAL-SALE-PLANNING';
export const REPORT_CANDIDATE_ASSESSMENT_NAMESPACE = 'REPORT-CANDIDATE-ASSESSMENT';
export const REPORT_CANDIDATE_DOCUMENT_NAMESPACE = 'REPORT-CANDIDATE-DOCUMENT';
export const REPORT_REFERRAL_CANDIDATE_NAMESPACE = 'REPORT-REFERRAL-CANDIDATE';
export const REPORT_REGISTRATION_CANDIDATE_NAMESPACE = 'REPORT-REGISTRATION-CANDIDATE';
export const REPORT_NEW_REGISTERED_CANDIDATE_NAMESPACE = 'REPORT-NEW-REGISTERED-CANDIDATE';
export const REPORT_CANDIDATE_INFORMATION_NAMESPACE = 'REPORT-CANDIDATE-INFORMATION';
export const REPORT_CANDIDATE_INTERACTION_NAMESPACE = 'REPORT-CANDIDATE-INTERACTION';
export const REPORT_CANDIDATE_SURVEY_NAMESPACE = 'REPORT-CANDIDATE-SURVEY';
export const REPORT_LIVESTREAM_DETAILED_COMMENT_NAMESPACE = 'REPORT-LIVESTREAM-DETAILED-COMMENT';
export const REPORT_LIVESTREAM_ACTIVITY_NAMESPACE = 'REPORT-LIVESTREAM-ACTIVITY';
export const REPORT_LIVESTREAM_PERFORMANCE_NAMESPACE = 'REPORT-LIVESTREAM-PERFORMANCE';
export const REPORT_CANDIDATE_MANAGEMENT_NAMESPACE = 'REPORT-CANDIDATE-MANAGEMENT';
export const REPORT_CANDIDATE_PERFORMANCE_NAMESPACE = 'REPORT-CANDIDATE-PERFORMANCE';
export const REPORT_MDRT_HUB_SHARING_ACTIVITY_NAMESPACE = 'REPORT-MDRTHUB-SHARING-ACTIVITY';
export const REPORT_EVENT_NAMESPACE = 'REPORT-EVENT';
export const REPORT_OPENING_NOTIFICATION_NAMESPACE = 'REPORT-OPENING-NOTIFICATION';
export const REPORT_EVENT_CUSTOMER_NAMESPACE = 'REPORT-EVENT-CUSTOMER';
export const REPORT_EVENT_CUSTOMER_AG_VIEW_NAMESPACE = 'REPORT-EVENT-CUSTOMER-AG-VIEW';
export const REPORT_EVENT_CANDIDATE_NAMESPACE = 'REPORT-EVENT-CANDIDATE';
export const REPORT_CUSTOMER_CAMPAIGN_NAMESPACE = 'REPORT-CUSTOMER-CAMPAIGN';
export const REPORT_INCENTIVE_REGISTRATION_V2_NAMESPACE = 'REPORT-INCENTIVE-REGISTRATION-V2';
export const REPORT_INCENTIVE_OPENING_V2_NAMESPACE = 'REPORT-INCENTIVE-OPENING-V2';
export const REPORT_NEWS_AGENTS_NAMESPACE = 'REPORT-NEWS-AGENTS';
export const REPORT_NEWS_COMMENTS_NAMESPACE = 'REPORT-NEWS-COMMENTS';
export const REPORT_NEWS_INFO_NAMESPACE = 'REPORT-NEWS-INFO';
export const REPORT_NEWS_AGENT_ACTIVITY_NAMESPACE = 'REPORT-NEWS-AGENT-ACTIVITY';
export const PRUdnaHomeEntryKeyPath = 'PRUdnaHome'; // DON'T edit this value on less you are knowing base app route changed

export enum AdminReportType {
  REPORT_PERFORMANCE_PRODUCTION_TARGET_READ = 'READ_ANY_REPORT-PERFORMANCE-PRODUCTION-TARGET',
  REPORT_PERFORMANCE_PRODUCTION_TARGET_CREATE = 'CREATE_ANY_REPORT-PERFORMANCE-PRODUCTION-TARGET',
  REPORT_PERFORMANCE_PRODUCTION_TARGET_UPDATE = 'UPDATE_ANY_REPORT-PERFORMANCE-PRODUCTION-TARGET',

  REPORT_INCENTIVE_RESULT_READ = 'READ_ANY_REPORT-INCENTIVE-RESULT',
  REPORT_INCENTIVE_RESULT_CREATE = 'CREATE_ANY_REPORT-INCENTIVE-RESULT',
  REPORT_INCENTIVE_RESULT_UPDATE = 'UPDATE_ANY_REPORT-INCENTIVE-RESULT',
  REPORT_INCENTIVE_REGISTRATION_READ = 'READ_ANY_REPORT-INCENTIVE-REGISTRATION',
  REPORT_INCENTIVE_REGISTRATION_CREATE = 'CREATE_ANY_REPORT-INCENTIVE-REGISTRATION',
  REPORT_INCENTIVE_REGISTRATION_UPDATE = 'UPDATE_ANY_REPORT-INCENTIVE-REGISTRATION',

  REPORT_INCENTIVE_OPENING_READ_V2 = 'READ_ANY_REPORT-INCENTIVE-OPENING-V2',
  REPORT_INCENTIVE_REGISTRATION_READ_V2 = 'READ_ANY_REPORT-INCENTIVE-REGISTRATION-V2',

  REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_READ = 'READ_ANY_REPORT-PERFORMANCE-PRODUCTION-TARGET-MTD',
  REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_CREATE = 'CREATE_ANY_REPORT-PERFORMANCE-PRODUCTION-TARGET-MTD',
  REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_UPDATE = 'UPDATE_ANY_REPORT-PERFORMANCE-PRODUCTION-TARGET-MTD',
  REPORT_PERFORMANCE_ADBU_TARGET_READ = 'READ_ANY_REPORT-PERFORMANCE-ADBU-TARGET',
  REPORT_PERFORMANCE_ADBU_TARGET_CREATE = 'CREATE_ANY_REPORT-PERFORMANCE-ADBU-TARGET',
  REPORT_PERFORMANCE_ADBU_TARGET_UPDATE = 'UPDATE_ANY_REPORT-PERFORMANCE-ADBU-TARGET',

  REPORT_RECRUITMENT_PRUDNA_SUMMARY_READ = 'READ_ANY_REPORT-RECRUITMENT-PRUDNA-SUMMARY',
  REPORT_RECRUITMENT_PRUDNA_SUMMARY_CREATE = 'CREATE_ANY_REPORT-RECRUITMENT-PRUDNA-SUMMARY',
  REPORT_RECRUITMENT_PRUDNA_SUMMARY_UPDATE = 'UPDATE_ANY_REPORT-RECRUITMENT-PRUDNA-SUMMARY',
  REPORT_RECRUITMENT_PRUDNA_DETAIL_READ = 'READ_ANY_REPORT-RECRUITMENT-PRUDNA-DETAIL',
  REPORT_RECRUITMENT_PRUDNA_DETAIL_CREATE = 'CREATE_ANY_REPORT-RECRUITMENT-PRUDNA-DETAIL',
  REPORT_RECRUITMENT_PRUDNA_DETAIL_UPDATE = 'UPDATE_ANY_REPORT-RECRUITMENT-PRUDNA-DETAIL',
  REPORT_RECRUITMENT_NEW_REGISTERED_CANDIDATE_READ = 'READ_ANY_REPORT-RECRUITMENT-NEW-REGISTERED-CANDIDATE',
  REPORT_RECRUITMENT_NEW_REGISTERED_CANDIDATE_CREATE = 'CREATE_ANY_REPORT-RECRUITMENT-NEW-REGISTERED-CANDIDATE',
  REPORT_RECRUITMENT_NEW_REGISTERED_CANDIDATE_UPDATE = 'UPDATE_ANY_REPORT-RECRUITMENT-NEW-REGISTERED-CANDIDATE',

  REPORT_SALE_CONVERSION_RATE_READ = 'READ_ANY_REPORT-SALE-CONVERSION-RATE',
  REPORT_SALE_CONVERSION_RATE_CREATE = 'CREATE_ANY_REPORT-SALE-CONVERSION-RATE',
  REPORT_SALE_CONVERSION_RATE_UPDATE = 'UPDATE_ANY_REPORT-SALE-CONVERSION-RATE',

  REPORT_SALE_MATERIAL_PERFORMANCE_TRACKING_READ = 'READ_ANY_REPORT-SALES-MATERIAL-PERFORMANCE-TRACKING',

  REPORT_SALE_CUSTOMER_LEAD_MANAGEMENT_READ = 'READ_ANY_REPORT-SALE-CUSTOMER-LEAD-MANAGEMENT',
  REPORT_SALE_CUSTOMER_LEAD_MANAGEMENT_CREATE = 'CREATE_ANY_REPORT-SALE-CUSTOMER-LEAD-MANAGEMENT',
  REPORT_SALE_CUSTOMER_LEAD_MANAGEMENT_UPDATE = 'UPDATE_ANY_REPORT-SALE-CUSTOMER-LEAD-MANAGEMENT',

  REPORT_APP_PERF_TRACKING_ACTIVE_USER_READ = 'READ_ANY_REPORT-APP-PERF-TRACKING-ACTIVE-USER',
  REPORT_APP_PERF_TRACKING_ACTIVE_USER_CREATE = 'CREATE_ANY_REPORT-APP-PERF-TRACKING-ACTIVE-USER',
  REPORT_APP_PERF_TRACKING_ACTIVE_USER_UPDATE = 'UPDATE_ANY_REPORT-APP-PERF-TRACKING-ACTIVE-USER',
  REPORT_APP_PERF_TRACKING_INSTALLMENT_READ = 'READ_ANY_REPORT-APP-PERF-TRACKING-INSTALLMENT',
  REPORT_APP_PERF_TRACKING_INSTALLMENT_CREATE = 'CREATE_ANY_REPORT-APP-PERF-TRACKING-INSTALLMENT',
  REPORT_APP_PERF_TRACKING_INSTALLMENT_UPDATE = 'UPDATE_ANY_REPORT-APP-PERF-TRACKING-INSTALLMENT',

  REPORT_CUSTOMER_CONTACT_TRACKING_READ = 'READ_ANY_REPORT-CUSTOMER-CONTACT-TRACKING',
  REPORT_CUSTOMER_CONTACT_TRACKING_CREATE = 'CREATE_ANY_REPORT-CUSTOMER-CONTACT-TRACKING',
  REPORT_CUSTOMER_CONTACT_TRACKING_UPDATE = 'UPDATE_ANY_REPORT-CUSTOMER-CONTACT-TRACKING',

  REPORT_QR_CODE_READ = 'READ_ANY_REPORT-SALE-CONVERSION-RATE',
  REPORT_QR_CODE_QR_ACTIVITY_READ = 'READ_ANY_REPORT-QR-CODE-QR-ACTIVITY',
  REPORT_QR_CODE_QR_ACTIVITY_CREATED = 'CREATED_ANY_REPORT-QR-CODE-QR-ACTIVITY',
  REPORT_QR_CODE_QR_ACTIVITY_UPDATE = 'UPDATE_ANY_REPORT-QR-CODE-QR-ACTIVITY',
  REPORT_QR_CODE_ACTIVITY_RATING_READ = 'READ_ANY_REPORT-QR-CODE-ACTIVITY-RATING',
  REPORT_QR_CODE_ACTIVITY_RATING_CREATE = 'CREATE_ANY_REPORT-QR-CODE-ACTIVITY-RATING',
  REPORT_QR_CODE_ACTIVITY_RATING_UPDATE = 'UPDATE_ANY_REPORT-QR-CODE-ACTIVITY-RATING',

  REPORT_SURVEY_READ = 'READ_ANY_REPORT-SURVEY',
  REPORT_SERVING_SURVEY_OPENING_READ = 'READ_ANY_REPORT-SERVING-SURVEY-OPENING',
  ASSIGN_SERVING_SURVEY_READ = 'ASSIGN_SERVING_SURVEY_READ',
  REPORT_CUSTOMER_SURVEY_READ = 'READ_ANY_REPORT-CUSTOMER-SURVEY',
  REPORT_NON_PROSPECT_SURVEY_READ = 'READ_ANY_REPORT-NON-PROSPECT-SURVEY',
  REPORT_AGENT_LEADER_SURVEY_READ = 'READ_ANY_REPORT-AGENT-LEADER-SURVEY',
  REPORT_INCENTIVE_OPENING_READ = 'READ_ANY_REPORT-INCENTIVE-OPENING',
  REPORT_INCENTIVE_OPENING_CREATE = 'CREATE_ANY_REPORT-INCENTIVE-OPENING',
  REPORT_INCENTIVE_OPENING_UPDATE = 'UPDATE_ANY_REPORT-INCENTIVE-OPENING',

  REPORT_SALE_PLANNING_READ = 'READ_ANY_REPORT-SALE-PLANNING',
  REPORT_TEAM_SALE_PLANNING_READ = 'READ_ANY_REPORT-TEAM-SALE-PLANNING',
  REPORT_PERSONAL_SALE_PLANNING_READ = 'READ_ANY_REPORT-PERSONAL-SALE-PLANNING',
  REPORT_CANDIDATE_ASSESSMENT_READ = 'READ_ANY_REPORT-CANDIDATE-ASSESSMENT',
  REPORT_CANDIDATE_DOCUMENT_READ = 'READ_ANY_REPORT-CANDIDATE-DOCUMENT',
  REPORT_CANDIDATE_MANAGEMENT_READ = 'READ_ANY_REPORT-CANDIDATE-MANAGEMENT',
  REPORT_CANDIDATE_PERFORMANCE_READ = 'READ_ANY_REPORT-CANDIDATE-PERFORMANCE',
  REPORT_REFERRAL_CANDIDATE_READ = 'READ_ANY_REPORT-REFERRAL-CANDIDATE',
  REPORT_REGISTRATION_CANDIDATE_READ = 'READ_ANY_REPORT-REGISTRATION-CANDIDATE',
  REPORT_CANDIDATE_SURVEY_READ = 'READ_ANY_REPORT-CANDIDATE-SURVEY',

  REPORT_NEW_REGISTERED_CANDIDATE_READ = 'READ_ANY_REPORT-NEW-REGISTERED-CANDIDATE',
  REPORT_CANDIDATE_INFORMATION_READ = 'READ_ANY_REPORT-CANDIDATE-INFORMATION',

  REPORT_NEWS_AGENTS_READ = 'READ_ANY_REPORT-NEWS-AGENTS',
  REPORT_NEWS_AGENTS_CREATE = 'CREATE_ANY_REPORT-NEWS-AGENTS',
  REPORT_NEWS_AGENTS_UPDATE = 'UPDATE_ANY_REPORT-NEWS-AGENTS',
  REPORT_NEWS_COMMENTS_READ = 'READ_ANY_REPORT-NEWS-COMMENTS',
  REPORT_NEWS_COMMENTS_CREATE = 'CREATE_ANY_REPORT-NEWS-COMMENTS',
  REPORT_NEWS_COMMENTS_UPDATE = 'UPDATE_ANY_REPORT-NEWS-COMMENTS',
  REPORT_NEWS_INFO_READ = 'READ_ANY_REPORT-NEWS-INFO',
  REPORT_NEWS_INFO_CREATE = 'CREATE_ANY_REPORT-NEWS-INFO',
  REPORT_NEWS_INFO_UPDATE = 'UPDATE_ANY_REPORT-NEWS-INFO',
  REPORT_NEWS_AGENT_ACTIVITY_READ = 'READ_ANY_REPORT-NEWS-AGENT-ACTIVITY',
  REPORT_NEWS_AGENT_ACTIVITY_CREATE = 'CREATE_ANY_REPORT-NEWS-AGENT-ACTIVITY',
  REPORT_NEWS_AGENT_ACTIVITY_UPDATE = 'UPDATE_ANY_REPORT-NEWS-AGENT-ACTIVITY',

  REPORT_LIVESTREAM_DETAILED_COMMENT_READ = 'READ_ANY_REPORT-LIVESTREAM-DETAILED-COMMENT',
  REPORT_LIVESTREAM_ACTIVITY_READ = 'READ_ANY_REPORT-LIVESTREAM-ACTIVITY',
  REPORT_LIVESTREAM_PERFORMANCE_READ = 'READ_ANY_REPORT-LIVESTREAM-PERFORMANCE',

  REPORT_MDRT_HUB_SHARING_ACTIVITY_READ = 'READ_ANY_REPORT-MDRTHUB-SHARING-ACTIVITY',
  REPORT_OPENING_NOTIFICATION_READ = 'READ_ANY_REPORT-OPENING-NOTIFICATION',

  REPORT_EVENT_READ = 'READ_ANY_REPORT-EVENT',
  REPORT_EVENT_CREATE = 'CREATE_ANY_REPORT-EVENT',
  REPORT_EVENT_UPDATE = 'UPDATE_ANY_REPORT-EVENT',

  REPORT_EVENT_CUSTOMER_READ = 'READ_ANY_REPORT-EVENT-CUSTOMER',
  REPORT_EVENT_CANDIDATE_READ = 'READ_ANY_REPORT-EVENT-CANDIDATE',
  REPORT_EVENT_CUSTOMER_AG_VIEW_READ = 'READ_ANY_REPORT-EVENT-CUSTOMER-AG-VIEW',

  REPORT_CUSTOMER_CAMPAIGN_READ = 'READ_ANY_REPORT-CUSTOMER-CAMPAIGN',
}
