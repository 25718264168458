import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import { getDisplayDate } from '../../../../../common/utils/time-utils';
import { CUSTOMER_CAMPAIGN_BASE_PATH } from '../../../constants';
import { EPublishStatus } from '../../../enum/customer-campaign.enum';
import { editStatusCustomerCampaignById } from '../../../network/customerCampaignCrud';

const CustomerCampaignList: FC<any> = ({ isLoading, customerCampaignList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const editStatus = async (id: any) => {
    try {
      await editStatusCustomerCampaignById(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Published successfully`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  return (
    <>
      <PruTable
        title={Translation('customer-campaign.component.title')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('list.create'),
            onClick: () => history.push(`${CUSTOMER_CAMPAIGN_BASE_PATH}/create`),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('app.button.view'),
            tooltipText: 'View Campaign',
            onClick: (row: any) => {
              history.push(`${CUSTOMER_CAMPAIGN_BASE_PATH}/view/${row._id}`);
            },
          },
          {
            title: Translation('app.button.edit'),
            tooltipText: 'Edit Banner',
            onClick: (row) => {
              history.push(`${CUSTOMER_CAMPAIGN_BASE_PATH}/edit/${row._id}`);
            },
          },
          {
            title: Translation('app.button.publish'),
            tooltipText: 'Publish',
            onClick: (row: any) => editStatus(row._id),
            condition: (row: any) => row?.status !== EPublishStatus.PUBLISHED,
          },
          {
            title: Translation('app.button.unpublish'),
            tooltipText: 'Unpublish',
            onClick: (row: any) => editStatus(row._id),
            condition: (row: any) => row?.status === EPublishStatus.PUBLISHED,
          },
        ]}
        columnDef={[
          {
            keyIndex: '_id',
            displayName: Translation('customer-campaign.component.campaignId'),
            renderData: (row: any) => row?._id,
          },
          {
            keyIndex: 'campaignName',
            align: 'center',
            displayName: Translation('customer-campaign.component.campaignName'),
            renderData: (row: any) => row?.campaignName,
          },
          {
            keyIndex: 'startDate',
            align: 'center',
            displayName: Translation('customer-campaign.component.startDate'),
            renderData: (row: any) => getDisplayDate(new Date(row?.startDate), 'DD-MM-YYYY'),
          },
          {
            keyIndex: 'endDate',
            align: 'center',
            displayName: Translation('customer-campaign.component.endDate'),
            renderData: (row: any) => getDisplayDate(new Date(row?.endDate), 'DD-MM-YYYY'),
          },
          {
            keyIndex: 'lastUpdatedBy',
            align: 'center',
            displayName: Translation('customer-campaign.component.lastUpdatedBy'),
            renderData: (row: any) => row?.lastUpdatedBy,
          },
          {
            keyIndex: 'status',
            align: 'center',
            displayName: Translation('customer-campaign.component.publicStatus'),
            renderData: (row: any) => row?.status,
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={customerCampaignList?.items}
        totalPages={customerCampaignList?.pagination?.totalPages}
        totalRecords={customerCampaignList?.pagination?.totalItems}
        onChangePage={onChangePage}
        defaultRowsPerPage={10}
      />
    </>
  );
};

export default CustomerCampaignList;
