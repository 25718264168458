import React, { FC, useMemo, useReducer } from 'react';
import {
  FileUpload,
  MdrtPassportBackgroundDetail,
  MdrtPassportBackgroundFormMode,
  MdrtPassportBackgroundFormState,
} from '../../../../types/mdrt-passport-background-types';
import { Button } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { MDRT_HUB_BASE_PATH, MDRT_PASSPORT_BACKGROUND_BASE_PATH } from '../../../../constants';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import FileUploader from 'src/app/common/components/FileUploader';
import { create, update } from 'src/app/modules/MdrtHub/network/mdrtPassportBackgroundCrud';
import { makeStyles } from 'tss-react/mui';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import { Close } from '@mui/icons-material';

type MdrtPassportBackgroundDetailFormProps = {
  itemId?: string;
  formMode: MdrtPassportBackgroundFormMode;
  itemDetail?: MdrtPassportBackgroundDetail;
  onReload: () => void;
  onRouteTo: (route: string) => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  fieldContainer: {
    width: 180,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  dateDivider: {
    marginLeft: 8,
    marginRight: 8,
  },
  sectionMargin: {
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },

  flexItem: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '33.333333%',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  backgroundImageContainer: {
    width: 60,
    height: 60,
    overflow: 'hidden',
    borderRadius: 12,
  },
  closeIcon: {
    marginLeft: '5px',
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
}));

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof MdrtPassportBackgroundFormState;
    value: any;
  };
};

type UploadAction = {
  type: 'UPLOAD_IMG';
  payload: {
    type: 'backgroundImage';
    id: string;
    url: string;
    fileName: string;
    fileType: string;
  };
};

type MdrtFormAction = ModifyFieldAction | UploadAction;

const initialState: MdrtPassportBackgroundFormState = {
  id: undefined,
  backgroundImage: undefined,
};

const formReducer = (
  state: MdrtPassportBackgroundFormState,
  action: MdrtFormAction,
): MdrtPassportBackgroundFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'UPLOAD_IMG':
      return {
        ...state,
        backgroundImage: {
          id: action.payload.id,
          fileName: action.payload.fileName,
          url: action.payload.url,
          fileType: action.payload.fileType,
        },
      };
    default:
      return state;
  }
};

const detailToStateConvertor = (detail: MdrtPassportBackgroundDetail): MdrtPassportBackgroundFormState => {
  return {
    id: detail.id,
    backgroundImage: detail.backgroundImage,
  };
};

const MdrtPassportBackgroundDetailForm: FC<MdrtPassportBackgroundDetailFormProps> = ({
  itemId,
  formMode,
  itemDetail,
  onReload,
  onRouteTo,
}) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [formState, formDispatch] = useReducer(
    formReducer,
    itemDetail ? detailToStateConvertor(itemDetail) : initialState,
  );
  const isDisabled = useMemo(() => {
    return formMode === MdrtPassportBackgroundFormMode.CREATE || formMode === MdrtPassportBackgroundFormMode.EDIT
      ? false
      : true;
  }, [formMode]);

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        {
          mimeType: fileType,
          accessLevel: 'anonymous',
          module: 'mdrt-passport-background',
        },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Upload successfully - ${fileName}`,
            },
          ]),
        );
        return {
          id: result.blobId,
          fileName: fileName,
          url: result.url,
          type: fileType,
        };
      }
    } catch (err) {
    } finally {
    }
  };

  const onChangeBackgroundImageFile = (result: any) => {
    formDispatch({
      type: 'UPLOAD_IMG',
      payload: {
        type: 'backgroundImage',
        id: result.id,
        url: result.url,
        fileName: result.fileName,
        fileType: result.type,
      },
    });
  };

  const removeFile = (type: 'backgroundImage') => {
    formDispatch({
      type: 'UPLOAD_IMG',
      payload: {
        type,
        id: '',
        url: '',
        fileName: '',
        fileType: '',
      },
    });
  };

  const onSubmitForm = async () => {
    const detail: MdrtPassportBackgroundDetail = {
      id: formState.id || '',
      backgroundImage: formState.backgroundImage?.id ? formState.backgroundImage : null,
    };
    try {
      if (formMode === MdrtPassportBackgroundFormMode.CREATE) {
        await create(detail, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Created successfully`,
            },
          ]),
        );
      } else {
        detail.id = itemId;
        await update(detail, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Updated successfully`,
            },
          ]),
        );
      }
      onRouteTo(`${MDRT_HUB_BASE_PATH}${MDRT_PASSPORT_BACKGROUND_BASE_PATH}`);
    } catch (err) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Error',
            content: `System error`,
          },
        ]),
      );
    }
  };

  const previewFile = ({ fileName, url, fileType }: FileUpload) => {
    return (
      <>
        <div className={classes.rowContainer} style={{ marginTop: 15, marginLeft: 10 }}>
          <div className={classes.fieldContainer}></div>
          <div style={{ flexGrow: 1 }}>
            <div className={classes.rowContainer} style={{ marginBottom: 8 }}>
              <div>
                <a href={url}>{fileName || ''}</a>
                {!isDisabled && <Close className={classes.closeIcon} onClick={() => removeFile('backgroundImage')} />}
              </div>
            </div>
            <div className={classes.rowContainer}>
              {url && fileType !== 'application/pdf' && (
                <>
                  <a href={url}>
                    <img style={{ width: 'auto', height: '120px' }} src={url} alt="fileName" />
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>{Translation('mdrtHub.mdrtPassportBackground.detail.title')}</div>
          </div>
          <Button
            variant="contained"
            onClick={() => onRouteTo(`${MDRT_HUB_BASE_PATH}${MDRT_PASSPORT_BACKGROUND_BASE_PATH}/list`)}
          >
            Back
          </Button>
        </div>

        <div className={classes.sectionMargin}>
          {/* Background Image */}
          <div className={classes.rowContainer} style={{ marginTop: 15, marginLeft: 10 }}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('mdrtHub.mdrtPassportBackground.detail.mdrtCardBackground')}:
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <FileUploader
                color="primary"
                disabled={isDisabled}
                showAllowText={formState.backgroundImage?.url ? '' : Translation('app.image.maximum')}
                upload={handleUploadFile}
                allowedFileTypes={['jpg', 'png', 'jpeg']}
                maxFileSize={5}
                showAddIcon={false}
                btnTxt={
                  formState.backgroundImage?.url
                    ? Translation('app.button.update')
                    : '+ ' + Translation('app.button.upload')
                }
                onChange={onChangeBackgroundImageFile}
              />
            </div>
          </div>
          {formState.backgroundImage?.url && previewFile(formState.backgroundImage)}
        </div>

        <div className={classes.footerContainer}>
          {isDisabled ? (
            <></>
          ) : (
            <>
              <Button variant="contained" color="secondary" onClick={() => onSubmitForm()}>
                {Translation('app.button.submit')}
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MdrtPassportBackgroundDetailForm;
