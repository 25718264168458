export enum EAnswerOptions {
  All = 'ALL',
  Yes = 'YES',
  No = 'NO'
}

export type QuestionItem = {
  id: string;
  question: string;
  category: QuestionCategory;
  createdAt: string;
  updatedAt: string;
  answers: {
    id?: string;
    answer: string;
    staffName: string;
    staffEmail: string;
    createdAt: string;
  }[];
  agentCode: string;
  agentName: string;
  selected?: boolean;
};

export type QuestionCategory = {
  code: string;
  content: string;
  detail: string;
  readonly: boolean; 
}

export type CategoryItem = {
  readonly: boolean;
  code: string;
  content: string;
  detail: string;
  id: string;
}

export type CategoryResponse = {
  success: boolean;
  data: CategoryItem[]
}

export type ReplyQuestionRequest = {
  answer: string;
  questionIds: string[];
}
