import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { Button, FormControlLabel, Radio, TextField } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Mention, MentionsInput } from 'react-mentions';
import { useDispatch } from 'react-redux';
import CheckBoxGroupBoolean from 'src/app/common/components/CheckBoxGroupBoolean';
import FileUploader from 'src/app/common/components/FileUploader';
import Form from 'src/app/common/components/Form';
import { PruDatePicker } from 'src/app/common/components/PruDatePicker';
import RadioGroupBoolean from 'src/app/common/components/RadioGroupBoolean';
import Tinymce from 'src/app/common/components/Tinymce-storage-blob';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { createBlob, getBlob } from 'src/app/common/network';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { FormMode } from 'src/app/common/types';
import { fileUpload } from 'src/app/common/utils';
import { CUSTOMER_CAMPAIGN_BASE_PATH } from '../../../constants';
import { EPublishStatus, EReceiverTypeAgent, EReceiverTypeCustomer } from '../../../enum/customer-campaign.enum';
import {
  CustomerCampaignFormState,
  MentionList,
  ReceiverTypeAgentOptions,
  ReceiverTypeCustomerOptions,
  ReferralFormOption,
} from '../../../types/customer-campaign-types';
import { stylesCustomerCampaign } from '../../../styles/stylesCustomerCampaign';

const useStyles = stylesCustomerCampaign;

interface FormListProp {
  initialValues: CustomerCampaignFormState;
  onSubmit: any;
  history: any;
  formMode: FormMode;
  formLoading: boolean;
  disabled: boolean;
}

const CustomerCampaignCreateForm: React.FC<FormListProp> = (props) => {
  console.log(props.initialValues);
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;

  const status = useRef(EPublishStatus.PUBLISHED);

  const [form] = Form.useForm();

  const [isAddColumn, setIsAddColumn] = useState<boolean>(false);

  const [isAllowSharing, setIsAllowSharing] = useState<boolean>(true);

  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);

  const dispatch = useDispatch();

  useEffect(() => {
    // ReceiverTypeAgentOptions.forEach((item) => (item.value = false));
    // ReceiverTypeCustomerOptions.forEach((item) => (item.value = false));
    // ReferralFormOption.forEach((item) => (item.value = false));

    form.setFieldsValue({ ...props.initialValues });
  }, []);

  const [errors, SetErrors] = useState<Record<string, boolean>>({});

  const DEFAULT_REQUIRED_RULES = [{ required: true, message: Translation('component.form-required-text') }];

  const START_DATE_REQUIRED_RULES = [
    { required: true, message: MANDATORY_FIELD_ERROR_TEXT },
    () => ({
      async validator(rule: any, value: any) {
        if (value) {
          // let now = new Date();

          if (new Date(value).getTime() > new Date(form.getFieldValue('endDate')).getTime()) {
            if (!errors[rule.field]) {
              SetErrors({ ...errors, [rule.field]: true });
            }
            throw new Error(
              `Start date must be less than End date, user cannot select the date that is less than current date.`,
            );
          } else {
            if (errors[rule.field]) {
              SetErrors({ ...errors, [rule.field]: false });
            }
          }
        } else {
          if (!errors[rule.field]) {
            SetErrors({ ...errors, [rule.field]: true });
          }
          throw new Error(`${rule.field} is required`);
        }
      },
    }),
  ];

  const END_DATE_REQUIRED_RULES = [
    { required: true, message: MANDATORY_FIELD_ERROR_TEXT },
    () => ({
      async validator(rule: any, value: any) {
        // let now = new Date();

        if (value) {
          // let now = new Date();
          if (new Date(value).getTime() < new Date(form.getFieldValue('startDate')).getTime()) {
            throw new Error(
              `Start date must be less than End date, user cannot select the date that is less than current date.`,
            );
          } else {
            if (errors[rule.field]) {
              SetErrors({ ...errors, [rule.field]: false });
            }
          }
        } else {
          if (!errors[rule.field]) {
            SetErrors({ ...errors, [rule.field]: true });
          }
          throw new Error(`${rule.field} is required`);
        }
      },
    }),
  ];

  const RECEIVER_TYPE_CUSTOMER_REQUIRED_RULES = [
    { required: true, message: MANDATORY_FIELD_ERROR_TEXT },
    () => ({
      async validator(rule: any, value: any) {
        if (value) {
          if (!value.type) {
            throw new Error(Translation('component.form-required-text'));
          } else if (value.type == EReceiverTypeCustomer.targetsCustomer && !value.userFile) {
            throw new Error(Translation('component.form-required-text'));
          }
        } else {
          if (!errors[rule.field]) {
            SetErrors({ ...errors, [rule.field]: true });
          }
          throw new Error(Translation('component.form-required-text'));
        }
      },
    }),
  ];

  const RECEIVER_TYPE_AGENT_REQUIRED_RULES = [
    { required: true, message: MANDATORY_FIELD_ERROR_TEXT },
    () => ({
      async validator(rule: any, value: any) {
        if (value) {
          if (
            !value[EReceiverTypeAgent.isAllAgents] &&
            !value[EReceiverTypeAgent.isGroupOfUsers] &&
            !value[EReceiverTypeAgent.isPruventure]
          ) {
            throw new Error(Translation('component.form-required-text'));
          }

          if (value[EReceiverTypeAgent.isGroupOfUsers]) {
            if (!value.userFile) {
              throw new Error(Translation('component.form-required-text'));
            }
          }
        } else {
          if (!errors[rule.field]) {
            SetErrors({ ...errors, [rule.field]: true });
          }
          throw new Error(Translation('component.form-required-text'));
        }
      },
    }),
  ];

  const SMS_DURATION_REQUIRED_RULES = [
    { required: true, message: MANDATORY_FIELD_ERROR_TEXT },
    () => ({
      async validator(rule: any, value: any) {
        if (value) {
          if (form.getFieldValue('isAllowSharingWhenCompleted') && +value <= 0) {
            throw new Error('Value must bigger than 1');
          }
        }
        return;
      },
    }),
  ];

  const onFinish = () => {
    const formData = form.getFieldsValue(true);
    console.log(formData);
    props.onSubmit(formData, status.current);
  };

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        { mimeType: fileType, accessLevel: 'anonymous', module: 'customerCampaign' },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        return { id: result.blobId, fileName: fileName, url: result.url, type: fileType };
      }
    } catch (err) {
    } finally {
    }
  };

  const onChangeIsAllowSharing = (event: any) => {
    const value = event.target.value.toLowerCase() === 'true' ? true : false;

    setIsAllowSharing((prev) => value);

    if (!value) {
      form.setFieldsValue({ smsDuration: 0 });
    } else {
      form.setFieldsValue({ smsDuration: 1 });
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={props.initialValues}
      className={classes.container}
      validateTrigger={props.formLoading ? 'onChange' : 'onSubmit'}
    >
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>
              {props.formMode === FormMode.EDIT
                ? Translation('component.formTitle.edit')
                : props.formMode === FormMode.VIEW
                ? Translation('component.formTitle.detail')
                : Translation('Create')}
            </div>
          </div>
          <Button variant="contained" onClick={() => props.history.push(`${CUSTOMER_CAMPAIGN_BASE_PATH}`)}>
            {Translation('global.back.btnText')}
          </Button>
        </div>
      </div>

      {/* Basic Information */}
      <div className={classes.sectionMargin}>
        <div className={classes.divideMargin}>
          <span className={classes.subHeader}>Basic Information</span>
        </div>
        <Form.Item name={'campaignName'} label="Campaign name" rules={DEFAULT_REQUIRED_RULES}>
          <TextField
            className={classes.borderError}
            error={errors?.campaignName}
            disabled={props.disabled}
            margin="dense"
            variant="outlined"
            fullWidth={true}
            placeholder={Translation('app.input.placeholder.please-enter')}
            inputProps={{ maxLength: 200 }}
          />
        </Form.Item>

        <Form.Item name="startDate" label="Start date" rules={START_DATE_REQUIRED_RULES}>
          <PruDatePicker
            slotProps={{
              textField: {
                error: errors?.startDate,
              },
            }}
            className={classes.borderError}
            disabled={props.disabled}
            format="DD/MM/YYYY"
            value={form.getFieldValue('startDate')}
            onChange={(date) => form.setFieldsValue({ startDate: date })}
            sx={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item name="endDate" label="End date" rules={END_DATE_REQUIRED_RULES}>
          <PruDatePicker
            slotProps={{
              textField: {
                error: errors?.endDate,
              },
            }}
            className={classes.borderError}
            disabled={props.disabled}
            format="DD/MM/YYYY"
            value={form.getFieldValue('endDate')}
            onChange={(date) => form.setFieldsValue({ endDate: date })}
            sx={{ width: '100%' }}
          />
        </Form.Item>
        <br />

        <Form.Item
          name={'bannerImg'}
          label="Banner image"
          // rules={DEFAULT_REQUIRED_RULES}
        >
          <FileUploader
            color="secondary"
            disabled={props.disabled}
            showAllowText="Recommend picture is 3:1 proportion. Format are JGP/JPEG/PNG, 2MB maximum"
            upload={handleUploadFile}
            allowedFileTypes={['jpg', 'png', 'jpeg']}
            maxFileSize={2}
            showPreview
            needCompressImage={false}
          />
        </Form.Item>
        <br />
        <div>
          <Form.Item name={'introduction'} label="Introduction" rules={DEFAULT_REQUIRED_RULES}>
            <MentionsInput
              disabled={props.disabled}
              singleLine={false}
              style={{
                minHeight: '35.7px',
                border: 'solid 2px #C4C4C4',
                borderRadius: '4px',
                width: '50%',
              }}
              className={classes.customMentionInput}
            >
              <Mention
                trigger="@"
                data={(search) => MentionList.filter((el) => el.display.toLowerCase().includes(search.toLowerCase()))}
                displayTransform={(id, label) => `@${label}`}
                markup="@{{__id__}}"
                renderSuggestion={(entry, search, hightlightedDisplay, index, focused) => (
                  <div style={{ padding: '3px 8px' }} key={index} className={focused ? classes.focusedMention : ''}>
                    {hightlightedDisplay}
                  </div>
                )}
              />
            </MentionsInput>
          </Form.Item>

          <div style={{ marginLeft: '160px', color: 'gray' }}>
            Use "@" to select dynamic variable. Available variable @agentcode, @agentname, @customername
          </div>
        </div>
        <br />
        <Form.Item name={'additionalInformation'} label="Additional information" rules={DEFAULT_REQUIRED_RULES}>
          <Tinymce accessLevel="anonymous" disabled={props.disabled} />
        </Form.Item>
      </div>
      <br />

      {/* Information Setting */}
      <div className={classes.sectionMargin}>
        <div className={classes.divideMargin}>
          <span className={classes.subHeader}>Information Setting</span>
        </div>
        <Form.List name="tabs">
          {(fields: any, { add, remove }: any) => {
            return fields.map((field: any, index: any) => (
              <div key={field.key + new Date().toISOString()} className={classes.tabs}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ flex: '70%' }}>
                    <Form.Item name={[field.name, 'tabTitle']} label="Tab title" rules={DEFAULT_REQUIRED_RULES}>
                      <TextField
                        className={classes.borderError}
                        error={errors?.tabTitle}
                        disabled={props.disabled}
                        margin="dense"
                        variant="outlined"
                        fullWidth={true}
                        placeholder={Translation('app.input.placeholder.please-enter')}
                        inputProps={{ maxLength: 200 }}
                      />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, 'tabDescription']}
                      label="Tab description"
                      rules={DEFAULT_REQUIRED_RULES}
                    >
                      <Tinymce accessLevel="anonymous" disabled={props.disabled} />
                    </Form.Item>
                    <br />
                  </div>
                  <div>
                    {!props.disabled ? (
                      <div
                        style={{
                          display: 'flex',
                          width: '100px',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                          marginTop: '-10px',
                        }}
                      >
                        {fields.length <= 1 ? (
                          <AddIcon
                            fontSize="large"
                            onClick={() =>
                              (() => {
                                add();
                                setIsAddColumn(true);
                              })()
                            }
                          />
                        ) : null}
                        {fields.length > 1 ? <RemoveIcon fontSize="large" onClick={() => remove(field.name)} /> : null}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ));
          }}
        </Form.List>

        <Form.Item name={'referralForm'} label="Referral form" labelTop={true} shouldUpdate>
          <CheckBoxGroupBoolean
            disabled={props.disabled}
            options={ReferralFormOption}
            aria-label="referral form"
          ></CheckBoxGroupBoolean>
        </Form.Item>

        <Form.Item name={'consent'} label="Consent" rules={DEFAULT_REQUIRED_RULES}>
          <Tinymce accessLevel="anonymous" disabled={props.disabled} isDisabledPreview={true} />
        </Form.Item>
        <br />

        <Form.Item
          name={'tnc'}
          label="TnC"
          required={false}
          // rules={DEFAULT_REQUIRED_RULES}
        >
          <FileUploader
            disabled={props.disabled}
            maxFileSize={2}
            upload={handleUploadFile}
            showAllowText={'File format is PDF, 2MB maximum'}
            allowedFileTypes={['pdf']}
            allowDuplicateFile={true}
            maxFileNumber={1}
          />
        </Form.Item>
      </div>
      <br />

      {/* Receiver Setting */}
      <div className={classes.sectionMargin}>
        <div className={classes.divideMargin}>
          <span className={classes.subHeader}>Receiver Setting</span>
        </div>

        <Form.Item
          name={'receiverTypeAgent'}
          label={'Segment for Agent'}
          rules={RECEIVER_TYPE_AGENT_REQUIRED_RULES}
          labelTop={true}
          shouldUpdate
        >
          <CheckBoxGroupBoolean
            disabled={props.disabled}
            options={ReceiverTypeAgentOptions}
            value={form.getFieldValue('receiverTypeAgent')}
            aria-label="viewType"
            moduleType={'customerCampaign'}
          ></CheckBoxGroupBoolean>
        </Form.Item>

        <Form.Item
          name={'receiverTypeCustomer'}
          label={'Segment for Customer'}
          rules={RECEIVER_TYPE_CUSTOMER_REQUIRED_RULES}
          labelTop={true}
          shouldUpdate
        >
          <RadioGroupBoolean
            disabled={props.disabled}
            options={ReceiverTypeCustomerOptions}
            value={form.getFieldValue('receiverTypeCustomer')}
            aria-label="viewType"
            moduleType={'customerCampaign'}
          ></RadioGroupBoolean>
        </Form.Item>

        <Form.Item name={'smsDuration'} label="SMS Duration" rules={SMS_DURATION_REQUIRED_RULES}>
          <TextField
            InputProps={{ inputProps: { min: 1 } }}
            className={classes.borderError}
            type="number"
            error={errors?.smsDuration}
            disabled={props.disabled || isAllowSharing === false ? true : false}
            margin="dense"
            variant="outlined"
            placeholder={Translation('app.input.placeholder.please-enter')}
            inputProps={{ maxLength: 200 }}
          />
        </Form.Item>

        <Form.Item
          name={'isAllowSharingWhenCompleted'}
          label="Allow sharing when the status is completed"
          rules={DEFAULT_REQUIRED_RULES}
        >
          <RadioGroup
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
            onChange={onChangeIsAllowSharing}
          >
            <FormControlLabel
              key={'isAllowSharingWhenCompleted-true'}
              control={<Radio disabled={props.disabled} value={true} />}
              label={'Yes'}
            />
            <FormControlLabel
              key={'isAllowSharingWhenCompleted-false'}
              control={<Radio disabled={props.disabled} value={false} />}
              label={'No'}
            />
          </RadioGroup>
        </Form.Item>
      </div>

      {/* Footer */}
      <div className={classes.footerContainer}>
        <>
          {props.formMode === FormMode.VIEW && (
            <>
              <Button variant="contained" onClick={() => props.history.push(`${CUSTOMER_CAMPAIGN_BASE_PATH}`)}>
                {Translation('global.back.btnText')}
              </Button>
              <Button
                // disabled={props.disabled}
                style={{ marginLeft: 20 }}
                variant="contained"
                color="secondary"
                onClick={() => {
                  props.history.push(`${CUSTOMER_CAMPAIGN_BASE_PATH}/edit/${props.initialValues._id}`);
                }}
              >
                {Translation('component.formTitle.edit')}
              </Button>
            </>
          )}

          {props.formMode === FormMode.EDIT && (
            <>
              <Button variant="contained" onClick={() => props.history.push(`${CUSTOMER_CAMPAIGN_BASE_PATH}`)}>
                {Translation('global.back.btnText')}
              </Button>
              <Button
                // disabled={props.disabled}
                style={{ marginLeft: 20 }}
                variant="contained"
                color="secondary"
                onClick={() => {
                  console.log(form.getFieldsValue(true));
                  form.submit();
                }}
              >
                {Translation('app.button.save')}
              </Button>
            </>
          )}

          {props.formMode === FormMode.CREATE && (
            <>
              <Button
                // disabled={props.disabled}
                style={{ marginLeft: 20 }}
                variant="contained"
                color="secondary"
                onClick={() => {
                  console.log(form.getFieldsValue(true));
                  status.current = EPublishStatus.PUBLISHED;
                  form.submit();
                }}
              >
                {Translation('app.button.submit')}
              </Button>

              <Button
                disabled={props.disabled}
                onClick={() => {
                  console.log(form.getFieldsValue(true));
                  status.current = EPublishStatus.UNPUBLISHED;
                  form.submit();
                }}
                style={{ marginLeft: 20 }}
                variant="contained"
                color="secondary"
              >
                {Translation('component.formSelectItem.save-as-draft')}
              </Button>
            </>
          )}
        </>
      </div>
    </Form>
  );
};

export default CustomerCampaignCreateForm;
