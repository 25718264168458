import React, { FC } from 'react';
import { RouteComponentProps, Switch, Redirect } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../common/constants';
import PruRoute from '../../../../common/components/PruRoute';
import { CANDIDATE_BASE_PATH, UPDATE_CANDIDATE_ASSESSMENT_PATH } from '../../constants';
import UpdateCandidateAssessmentDetailPage from './UpdateDetailPage';

const UpdateCandidateAssessmentPage: FC<RouteComponentProps> = ({ history }) => {
  return (
    <Switch>
      <PruRoute
        exact
        path={`${CANDIDATE_BASE_PATH}${UPDATE_CANDIDATE_ASSESSMENT_PATH}`}
        component={UpdateCandidateAssessmentDetailPage}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default UpdateCandidateAssessmentPage;
