import { PaginateList } from 'src/app/common/types/common-types';

export enum PublishStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export enum BdmBackgroundImageFormMode {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
}

export type ImageItem = {
  id: string;
  fileName: string;
  url: string;
  fileType: string;
};

export type BdmBackgroundImageItem = {
  _id: string;
  categoryName: string;
  type: string;
  templateId?: string;
  name: string;
  categoryDescription: string;
  categoryId: string;
  image: ImageItem;
  textColorList?: string[];
  publishAt?: Date | null;
  unpublishAt?: Date | null;
  publishStatus: PublishStatus;
  createdAt: Date | null;
  createdBy: string | null;
  updatedAt: Date | null;
  updatedBy: string | null;
};

export type BdmBackgroundImageDetail = {
  id?: string;
  name: string;
  categoryId: string;
  categoryName: string;
  image: any;
  type: string;
  templateId?: string;
  textColorList?: string[];
};

export type BdmBackgroundImageFormState = {
  id?: string;
  title: string;
  content: string;
  link: string;
};

export type BackgroundImageCategoryItem = {
  _id: string;
  name: string;
};
