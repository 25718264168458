export enum PublishStatus {
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED"
}

export enum MdrtFormMode {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
};

export type MdrtItem = {
  id: string
  name: string
  status: PublishStatus
  createdAt: string
  updatedAt: string
  lastUploadedBy: string
};

export interface FileUpload {
  url?: string;
  id: string;
  fileName: string;
  inclusive?: boolean,
  receiverType?: string,
  contentType?:string
  fileType: string
}

export type MdrtDetail = {
  id?: string
  name: string
  status?: string
  quarter1: number
  quarter2: number
  quarter3: number
  mdrt: number
  cot: number
  tot: number
  banner?: FileUpload
  benefit?: FileUpload
  condition?: FileUpload
}

export type MdrtFormState = {
  mdrtId?: string | undefined
  name: string | undefined
  status: string | undefined
  quarter1: string | undefined
  quarter2: string | undefined
  quarter3: string | undefined
  mdrt: string | undefined
  cot: string | undefined
  tot: string | undefined
  banner: FileUpload | undefined
  benefit: FileUpload | undefined
  condition: FileUpload | undefined
}