import { forEach } from 'lodash';
import { Dispatch } from 'react';
import { PaginateList } from '../../../common/types/common-types';
import { getDayStart, getDayEnd } from 'src/app/common/utils/time-utils';
import { MdrtStampIconDetail, MdrtStampIconItem } from '../types/mdrt-stamp-icon-types';
import { apiClient, apiErrorHandler } from 'src/app/common/network';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_MDRT']}/mdrt-hub`;
// const ADMIN_URL = `http://localhost:4111/mdrt-hub`;

export type MdrtStampIconListParams = {
  page: number;
  limit: number;
};

export const create = async (data: MdrtStampIconDetail, dispatch?: Dispatch<any>): Promise<MdrtStampIconDetail> => {
  return apiClient
    .post<MdrtStampIconDetail>(`${ADMIN_URL}/stamp-icon/create`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const update = async (data: MdrtStampIconDetail, dispatch?: Dispatch<any>): Promise<MdrtStampIconDetail> => {
  return apiClient
    .put<MdrtStampIconDetail>(`${ADMIN_URL}/stamp-icon/update`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchItem = async (id: string, dispatch?: Dispatch<any>): Promise<MdrtStampIconDetail> => {
  return apiClient
    .get<MdrtStampIconDetail>(`${ADMIN_URL}/stamp-icon/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchList = async (
  params: MdrtStampIconListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<MdrtStampIconItem>> => {
  let queryPath = `${ADMIN_URL}/stamp-icon/list?`;

  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.includes('From')) {
          queryPath += `${key}=${getDayStart(param).toISOString()}&`;
        }
        if (key.includes('To')) {
          queryPath += `${key}=${getDayEnd(param).toISOString()}&`;
        }
      }
    } else {
      queryPath += param ? `${key}=${param}&` : '';
    }
  });

  return apiClient
    .get<PaginateList<MdrtStampIconItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const publish = async (id: string, dispatch?: Dispatch<any>): Promise<MdrtStampIconDetail> => {
  return apiClient
    .get<MdrtStampIconDetail>(`${ADMIN_URL}/stamp-icon/publish/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const unpublish = async (id: string, dispatch?: Dispatch<any>): Promise<MdrtStampIconDetail> => {
  return apiClient
    .get<MdrtStampIconDetail>(`${ADMIN_URL}/stamp-icon/unpublish/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
