import { I18nGenericData } from 'src/app/i18n';
import { AttachmentDef } from 'src/app/common/types';
import { TargetApplicantTypeEnum } from 'src/app/common/components/target-applicant-section-v2';
import { EIncentiveTemplateType, ENotificationType } from '../../Incentive/types/incentive-types';
import { InPublishSettingReceiver } from '../../Event/pages/InvitationLetter/Detail/constants';

export enum IncentiveFormMode {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum IncentiveStatusEnum {
  NOT_BEGIN = 'Not Begin',
  IN_PROGRESS = 'In Progress',
  FINISHED = 'Finished',
}

export enum IncentiveTypeEnum {
  INCENTIVE_SET = 'INCENTIVE_SET',
  INCENTIVE = 'INCENTIVE',
}

export enum PublishStatusEnum {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export enum CommonOperatorEnum {
  GREATER_THAN_EQUAL = '>=',
  SMALLER_THAN_EQUAL = '<=',
}

export enum CategoryOperatorEnum {
  IN = 'in',
}

export enum IncentiveSetTypeEnum {
  INCENTIVE = 'INCENTIVE',
  COMPENSATION = 'COMPENSATION',
}

export type IncentiveListItem = {
  _id: string;
  code: string;
  name: I18nGenericData<string>;
  incentiveSetType: IncentiveSetTypeEnum;
  startDate: Date;
  endDate: Date;
  archiveDate: Date;
  publishDate: Date;
  incentiveStatus: PublishStatusEnum;
  setCode?: string;
  createdAt: Date;
  updatedAt: Date;
};

export type IncentiveHighlight = {
  keyId: string;
  rankTopCount?: number;
  rankValueKeyId?: string;
  rankDescription?: I18nGenericData<string>;
};

export type IncentiveAwardCriteria = {
  keyId: string;
  operator: CommonOperatorEnum | CategoryOperatorEnum;
  value: string | string[];
  isShown: boolean;
};

export type IncentiveAwardRequirement = {
  name: string;
  names: I18nGenericData<string>;
  // Logic - AND
  items: IncentiveAwardCriteria[];
};

export type IncentiveAward = {
  tier: number;
  tierCode: string;
  name: string;
  // Logic - OR
  requirements: IncentiveAwardRequirement[];
};

export type IncentiveDetail = {
  _id: string;
  code: string;
  name: I18nGenericData<string>;
  incentiveSetType: IncentiveSetTypeEnum;
  status: PublishStatusEnum;
  startDate: Date;
  endDate: Date;
  detail: I18nGenericData<string>;
  objective?: I18nGenericData<string>;
  bulletin?: I18nGenericData<string>;
  award?: I18nGenericData<string>;

  incentiveImage?: I18nGenericData<AttachmentDef>;
  bulletinFile?: I18nGenericData<AttachmentDef>;

  highlights: IncentiveHighlight[];

  awards: IncentiveAward[];

  stickOnTop?: boolean;
  publishDate: Date;
  archiveDate: Date;

  targetApplicantType: TargetApplicantTypeEnum;
  eligibleDesignations?: string[];
  eligibleSegments?: string[];
  eligibleAgents?: string[];
  excludedAgents?: string[];

  agentListFile?: AttachmentDef | null;

  resultFile?: AttachmentDef;
  createdAt: Date;
  updatedAt: Date;

  // for Incentive
  incentiveSetId?: string;
  setCode?: string;
  subIncentiveIds?: string[];
  subIncentiveItems?: IncentiveListItem[];
  template?: IncentiveTemplate;
  registerLimit?: {
    isLimited: boolean;
    limitNumber: number | null;
  };
  registrationRequired: boolean;
  notification?: {
    type: ENotificationType;
    msgTitle?: string | null;
    msgContent?: string | null;
    pushContent?: string | null;
    pushTitle?: string | null;
    category: string;
  };
  receivers: InPublishSettingReceiver[];
  startEnrollmentDate: Date | null;
  endEnrollmentDate: Date | null;
  receiverUserFile: any;
  includeSA: boolean;
};

export interface IncentiveTemplate {
  type: EIncentiveTemplateType;
  bodyImg?: any;
  headerImg?: any;
  footerImg?: any;
  body?: any;
}
