export enum MdrtPassportBackgroundFormMode {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
};

export type MdrtPassportBackgroundItem = {
  _id: string
  name: string
  type: string | null
  createdAt: Date
  createdBy: Date
  updatedAt: Date
  updatedBy: string
  backgroundImage?: FileUpload | undefined | null
};

export interface FileUpload {
  url?: string;
  id: string;
  fileName: string;
  inclusive?: boolean,
  receiverType?: string,
  contentType?:string
  fileType: string
}

export type MdrtPassportBackgroundDetail = {
  id?: string
  backgroundImage?: FileUpload | undefined | null
}

export type MdrtPassportBackgroundFormState = {
  id?: string | undefined
  backgroundImage: FileUpload | undefined | null
}