import { EVENT_BASE_PATH } from '../../../constants';

export enum InvitationFormMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  COPY = 'COPY',
  VIEW = 'VIEW',
}

export enum InNotificationCategory {
  System = 'System',
  Performance = 'Performance',
  Recruitment = 'Recruitment',
  Training = 'Training',
  Policy = 'Policy',
  PulseLeads = 'PulseLeads',
  Sales = 'Sales',
  Event = 'Event',
}

export enum InPublishSettingReceiver {
  ALL_AGENT = 'ALL_AGENT',
  AGENT_LEADERS = 'AGENT_LEADERS',
  PRUVENTURE_LEADERS = 'PRUVENTURE_LEADERS',
  PRUVENTURE = 'PRUVENTURE',
  GROUP_OF_USERS = 'GROUP_OF_USERS',
  INCLUDE_SA = 'INCLUDE_SA',
}

export enum InPublishSettingTime {
  PublicNow = 'PublicNow',
  PublicSpecificTime = 'PublicSpecificTime',
}

export const INVITATION_LETTER_BASE_PATH = `${EVENT_BASE_PATH}/invitation-letter/list`;
export const EVENT_NAME_LENGTH_ERROR = 'Maximum length 255 characters ';
export const LOCATION_LENGTH_ERROR = 'Maximum length 255 characters ';
export const DURATION_ENDDATE_ERROR = 'End date must be after Start date';
export const DURATION_STARTDATE_ERROR = 'Start date must be after Current date';
export const PUBLICTIME_STARTDATE_ERROR = 'Public date must be after current date';
export const NOTITITILE_LENGTH_ERROR = 'Maximum length 50 characters ';
export const NOTICONTENT_LENGTH_ERROR = 'Maximum length 255 characters ';

export enum InvitationNotificationType {
  INBOX = 'INBOX',
  PUSH = 'PUSH',
  PUSH_INBOX = 'PUSH_INBOX',
}
