const objectsEqualOneLevel = (object1: Record<string, any>, object2: Record<string, any>) =>
  Object.keys(object1).length === Object.keys(object2).length &&
  Object.keys(object1).every((key: string) => object1[key] === object2[key]);

export const arraysEqual = (array1: any[], array2: any[]) =>
  array1.length === array2.length && array1.every((item, index) => objectsEqualOneLevel(item, array2[index]));

export const getTime = (timeStr?: string): Date | null => {
  if (!timeStr) {
    return null;
  }
  const hourMin = timeStr.split(':');
  if (hourMin.length !== 2) {
    return null;
  }
  const date = new Date();
  date.setHours(Number(hourMin[0]));
  date.setMinutes(Number(hourMin[1]));
  return date;
};
