import React, { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { getDisplayDate } from '../../../../../../common/utils/time-utils';
import { PaginateList } from '../../../../../../common/types/common-types';
import { useIntl } from 'react-intl';
import PruTable from '../../../../../../common/components/PruTable/PruTable';
import { useHistory } from 'react-router-dom';
import {
  LINK_TYPE_TRANSLATE_MAP,
  MdrtBannerFormMode,
  MdrtBannerItem,
  PublishStatus,
} from '../../../../types/mdrt-banner-types';
import { publish, unpublish } from '../../../../network/mdrtBannerCrud';
import { MDRT_HUB_BASE_PATH, MDRT_BANNER_BASE_PATH } from 'src/app/modules/MdrtHub/constants';
import { RegionLocale, getConfig } from 'src/app/i18n';

type MdrtBannerListProps = {
  isLoading: boolean;
  itemList?: PaginateList<MdrtBannerItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

const MdrtBannerList: FC<MdrtBannerListProps> = ({ isLoading, itemList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const lang = useMemo(() => {
    let result = getConfig();
    return result && result?.selectedLang ? result?.selectedLang : RegionLocale.VIETNAMESE;
  }, []);

  const linkTypeTransMap = (linkType: string): string => {
    return Translation(LINK_TYPE_TRANSLATE_MAP[linkType as keyof typeof LINK_TYPE_TRANSLATE_MAP] || '-');
  };

  const onPublish = async (item: MdrtBannerItem) => {
    try {
      await publish(item._id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Published successfully`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  const onUnpublish = async (item: MdrtBannerItem) => {
    try {
      await unpublish(item._id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Unpublished successfully`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  return (
    <>
      <PruTable
        title={Translation('mdrtHub.mdrtBanner.listing')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('app.button.add'),
            onClick: () =>
              history.push(`${MDRT_HUB_BASE_PATH}${MDRT_BANNER_BASE_PATH}/create`, { type: MdrtBannerFormMode.CREATE }),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('app.button.view'),
            tooltipText: 'View Banner',
            onClick: (row) =>
              history.push(`${MDRT_HUB_BASE_PATH}${MDRT_BANNER_BASE_PATH}/view/${row._id}`, {
                type: MdrtBannerFormMode.VIEW,
              }),
            condition: () => true,
          },
          {
            title: Translation('app.button.edit'),
            tooltipText: 'Edit Banner',
            onClick: (row) =>
              history.push(`${MDRT_HUB_BASE_PATH}${MDRT_BANNER_BASE_PATH}/edit/${row._id}`, {
                type: MdrtBannerFormMode.EDIT,
              }),
            condition: (row) => row.publishStatus !== PublishStatus.PUBLISHED,
          },
          {
            title: Translation('app.button.publish'),
            tooltipText: 'Publish',
            onClick: (row) => onPublish(row),
            condition: (row) => row.publishStatus !== PublishStatus.PUBLISHED,
          },
          {
            title: Translation('app.button.unpublish'),
            tooltipText: 'Unpublish',
            onClick: (row) => onUnpublish(row),
            condition: (row) => row.publishStatus === PublishStatus.PUBLISHED,
          },
        ]}
        columnDef={[
          {
            keyIndex: 'name',
            align: 'left',
            displayName: Translation('component.formLabel.name'),
            renderData: (row) => (row.i18nData?.[lang]?.name ? row.i18nData?.[lang]?.name : ''),
          },
          {
            keyIndex: 'publishStatus',
            align: 'left',
            displayName: Translation('component.formLabel.publish-status'),
            renderData: (row) =>
              String(
                row.publishStatus === PublishStatus.PUBLISHED
                  ? Translation('component.status.published')
                  : Translation('component.status.unpublished'),
              ),
          },
          {
            keyIndex: 'lastUpdatedDate',
            align: 'center',
            displayName: Translation('mdrtHub.mdrtBanner.list.lastUpdatedDate'),
            renderData: (row) => (row.updatedAt ? getDisplayDate(new Date(row.updatedAt), 'YYYY-MM-DD HH:mm:ss') : ''),
          },
          {
            keyIndex: 'updatedBy',
            align: 'left',
            displayName: Translation('component.formLabel.last-updated-by'),
            renderData: (row) => String(row.updatedBy ? row.updatedBy : ''),
          },
          {
            keyIndex: 'effectiveDate',
            align: 'left',
            displayName: Translation('component.formLabel.effective-date-range-start'),
            renderData: (row) =>
              row.effectiveDateFrom ? getDisplayDate(new Date(row.effectiveDateFrom), 'YYYY-MM-DD HH:mm:ss') : '',
          },
          {
            keyIndex: 'expiredDate',
            align: 'left',
            displayName: Translation('component.formLabel.effective-date-range-end'),
            renderData: (row) =>
              row.effectiveDateTo ? getDisplayDate(new Date(row.effectiveDateTo), 'YYYY-MM-DD HH:mm:ss') : '',
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={itemList?.items}
        totalPages={itemList?.totalPages}
        totalRecords={itemList?.total}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default MdrtBannerList;
