import React from 'react';
import { getDisplayDate } from 'src/app/common/utils/time-utils';

interface tableConfigType {
  title: string;
  prop: string;
  align?: any;
  render?: any;
  minWidth?: number;
  sorter?: boolean | false;
}


const tableConfig = (Translation: any, i18ConfigData: any, currentLang: any): tableConfigType[] => {


  if (i18ConfigData.length === 0) {
    return [];
  }

  return [
    {
      title: Translation('event.greeting_card.table.header.templateImage'),
      prop: 'templateImage',
      render: (row: any) =>
        row?.templateImage?.url ? (
          <>
            <img style={{ height: '60px' }} src={row.templateImage.url} />
            <span style={{ marginLeft: 10 }}>{row.templateImage.fileName}</span>
          </>
        ) : (
          ''
        ),
    },

    {
      title: Translation('event.greeting_card.table.header.specical_day'),
      prop: 'specificDay',
      sorter: true,
      render: (row: any) => <span>{row.specificDay ? currentLang === 'vi' ? row.specificDay?.nameVI :  row.specificDay?.nameEN : ''}</span>,
    },
    {
      title: Translation('event.greeting_card.table.header.publishTime'),
      prop: 'publishTime',
      render: (row: any) => (
        <span>{row.publishTime ? getDisplayDate(row.publishTime, 'YYYY-MM-DD HH:mm:ss') : ''}</span>
      ),
    },
    {
      title: Translation('event.greeting_card.table.header.createTime'),
      prop: 'createTime',
      render: (row: any) => <span>{getDisplayDate(row.createTime, 'YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: Translation('event.greeting_card.table.header.modifyTime'),
      prop: 'modifyTime',
      render: (row: any) => <span>{getDisplayDate(row.modifyTime, 'YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: Translation('event.greeting_card.table.header.modifier'),
      prop: 'modifier',
    },
  ];
};

export default tableConfig;
