import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Routes, SPECIFIC_ROUTES } from 'src/app/common/constants';
import { useSelector } from 'react-redux';
import { AuthenticationState } from '@pruforce/common-adminweb-sdk';
import { RootState } from 'src/redux/store';
import { getCurrentConfig } from 'src/app/common/utils/common-utils';
import { get, isObject, isEqual, isEmpty } from 'lodash';
import { QuestionItem } from '../../../types/qa-income-types';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  listItem: {
    listStyle: 'none',
    padding: 0,
    item: {
      p: {
        margin: 0,
      },
    },
  },
  item: {},
  table1: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '1em',
    th: {
      maxWidth: '150px',
    },
  },
  actions: {
    padding: '20px',
    textAlign: 'right',
  },
}));

interface DetailRecordProps {
  onCancel: () => void;
  initialValues: QuestionItem | null | undefined;
}

const generateDefaultExpandPanel = (regionLocales: string[]) => {
  let expandPanel: any = {};
  regionLocales.map((item) => {
    return (expandPanel[item] = true);
  });
  return expandPanel;
};

const Detail: React.FC<DetailRecordProps> = ({ onCancel, initialValues }) => {
  const { classes } = useStyles();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const intl = useIntl();
  const dispatch = useDispatch();

  const cancel = () => {
    onCancel();
  };

  return (
    <div>
      <hr style={{ marginTop: '0' }} />
      <table className={classes.table1}>
        <tr>
          <th>{Translation('qaIncome.modal.category')}:</th>
          <td>{initialValues?.category.content}</td>
        </tr>
        <tr>
          <th>{Translation('qaIncome.modal.question')}:</th>
          <td>{initialValues?.question}</td>
        </tr>
        <tr>
          <th>{Translation('qaIncome.modal.answer')}:</th>
          <td>
            <div>
              <ul className={classes.listItem}>
                {initialValues && initialValues.answers.length ? (
                  initialValues.answers.map((answer, i) => (
                    <li key={i}>
                      <div style={{ color: '#959595' }}>
                        {Translation('qaIncome.modal.date', { date: moment(answer.createdAt).format('YYYY-MM-DD') })} |{' '}
                        {Translation('qaIncome.modal.user', { name: answer.staffName })}
                      </div>
                      <p style={{ margin: '10px 0' }}>{answer.answer}</p>
                      <hr />
                    </li>
                  ))
                ) : (
                  <li>
                    <p style={{ margin: '10px 0' }}>No result found</p>
                  </li>
                )}
              </ul>
            </div>
          </td>
        </tr>
      </table>
      <div className={classes.actions}>
        <Button variant="contained" onClick={cancel}>
          {Translation('app.button.cancel')}
        </Button>
      </div>
    </div>
  );
};

export default Detail;
