import React, { FC } from "react";
import { RouteComponentProps, Switch, Redirect } from "react-router-dom";
import { NOT_FOUND_ERROR_PAGE_PATH } from "../../../../common/constants";
import PruRoute from "../../../../common/components/PruRoute";
import { ASSIGN_CANDIDATE_PATH, CANDIDATE_BASE_PATH } from "../../constants";
import AssignCandidateUploadPage from "./UploadPage/AssignCandidateUploadPage";

const AssignCandidatePage: FC<RouteComponentProps> = ({ history }) => {
  return (
    <Switch>
      <PruRoute exact path={`${CANDIDATE_BASE_PATH}${ASSIGN_CANDIDATE_PATH}`} component={AssignCandidateUploadPage}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
}

export default AssignCandidatePage;