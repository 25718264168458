import React, { useState, useEffect } from 'react';
import Form from '../../../../../common/components/Form';
import { useIntl } from 'react-intl';
import {
  Button,
  TextField,
  CircularProgress,
  FormLabel,
  FormControlLabel,
  Radio,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TextareaAutosize,
} from '@mui/material';
// import { AppRoutes } from '../../../../../../../common/constants';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Routes, SPECIFIC_ROUTES } from 'src/app/common/constants';
import { useSelector } from 'react-redux';
import { AuthenticationState } from '@pruforce/common-adminweb-sdk';
import { RootState } from 'src/redux/store';
import { getCurrentConfig } from 'src/app/common/utils/common-utils';
import { get, isObject, isEqual, isEmpty } from 'lodash';
import { QuestionItem } from '../../../types/qa-income-types';
import { makeStyles } from 'tss-react/mui';
import { ExpandMore } from '@mui/icons-material';

const useStyles = makeStyles()((theme) => ({
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  container: {
    padding: 20,
  },
  accordHeading: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '15%',
    flexShrink: 0,
  },
  divideMargin: {
    marginBottom: 10,
    paddingTop: 20,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  questionTable: {
    borderCollapse: 'collapse',
    width: '100%',
    '& td': {
      border: '1px solid #ddd',
      padding: '8px',
    },
    '& th': {
      border: '1px solid #ddd',
      padding: '8px',
      paddingTop: '12px',
      paddingBottom: '12px',
      textAlign: 'left',
      backgroundColor: 'black',
      color: 'white',
    },
    '& tr': {
      '$:nth-child': {
        backgroundColor: '#f2f2f2',
      },
    },
  },
}));

interface ReplyModalProps {
  onReply: (data: any) => void;
  onCancel: () => void;
  confirmLoading: boolean;
  question: QuestionItem | undefined | null;
  selectedQuestionList: QuestionItem[];
  isReplySingleQuestion: boolean;
}

const generateDefaultExpandPanel = (regionLocales: string[]) => {
  let expandPanel: any = {};
  regionLocales.map((item) => {
    return (expandPanel[item] = true);
  });
  return expandPanel;
};

const Create: React.FC<ReplyModalProps> = ({
  onReply,
  onCancel,
  confirmLoading,
  question,
  selectedQuestionList,
  isReplySingleQuestion,
}) => {
  const { classes } = useStyles();

  const [form] = Form.useForm();
  const [panelExpand, setPanelExpand] = useState<any>(false);
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const dispatch = useDispatch();

  const onFinish = (values: any) => {
    const allValues = form.getFieldsValue(true);
    const data = {
      answer: allValues.answer,
      questionIds: isReplySingleQuestion ? [question?.id] : selectedQuestionList.map((el) => el.id),
    };
    onReply(data);
  };

  const cancel = () => {
    onCancel();
  };

  const handleChange = () => (event: any) => {
    setPanelExpand(!panelExpand);
  };

  const placeEnter = Translation('app.input.placeholder.please-enter');

  useEffect(() => {}, []);

  return (
    <>
      <Form form={form} onFinish={onFinish} className={classes.container}>
        {isReplySingleQuestion ? (
          <>
            <Form.Item label={Translation('qaIncome.modal.category')} required={false}>
              <div>{question?.category.content}</div>
            </Form.Item>
            <Form.Item label={Translation('qaIncome.modal.question')} required={false}>
              <div>{question?.question}</div>
            </Form.Item>
          </>
        ) : (
          <Accordion
            expanded={panelExpand}
            onChange={handleChange()}
            style={{ marginBottom: 0, marginLeft: -20, marginRight: -20 }}
          >
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1bh-content" id="panel1bh-header">
              <Typography className={classes.accordHeading}>{selectedQuestionList.length} questions</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block' }}>
              <table className={classes.questionTable}>
                <tr>
                  <th>{Translation('qaIncome.component.agentName')}</th>
                  <th>{Translation('qaIncome.component.agentCode')}</th>
                  <th>{Translation('qaIncome.component.questionDate')}</th>
                  <th>{Translation('qaIncome.component.question')}</th>
                </tr>
                {selectedQuestionList.map((item, i) => (
                  <tr key={i}>
                    <td>{item.agentName}</td>
                    <td>{item.agentCode}</td>
                    <td>{moment(item.createdAt).format('YYYY-MM-DD')}</td>
                    <td>{item.question}</td>
                  </tr>
                ))}
              </table>
            </AccordionDetails>
          </Accordion>
        )}
        <Form.Item name="answer" label={Translation('qaIncome.modal.answer')} required={true}>
          <TextField
            multiline
            rows={4}
            margin="dense"
            variant="outlined"
            fullWidth
            placeholder={placeEnter}
            disabled={confirmLoading}
          />
        </Form.Item>
        <div className={classes.footerContainer}>
          <>
            <Button variant="contained" onClick={cancel} disabled={confirmLoading}>
              {Translation('app.button.cancel')}
            </Button>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.isPublish !== currentValues.isPublish}
            >
              <Button
                disabled={confirmLoading}
                type="submit"
                style={{ marginLeft: 20 }}
                variant="contained"
                color="secondary"
                onClick={() => {}}
              >
                {Translation('app.button.submit')}
                {confirmLoading && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
              </Button>
            </Form.Item>
          </>
        </div>
      </Form>
    </>
  );
};

export default Create;
