import React, { FC, useState } from 'react';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { PaginateListMore } from 'src/app/common/types/common-types';
import { IReportFilter, IReportItem, ChannelOption } from '../../../types/non-prospect-survey-types';
import {
  fetchNonProspectSurveyReportFile,
  fetchTotalRecordNonProspectSurveyReport,
  fetchNonProspectSurveyReport,
} from '../../../network/nonProspectSurveyCrud';
import moment from 'moment';
import { map } from 'lodash';

type NonProspectSurveyReportProps = {} & ParamsProps;

const initialState: IReportFilter = {
  agentCode: '',
  leaderCode: '',
  surveyTitle: '',
  channelSurvey: '',
  fromDate: moment().subtract(3, 'months').startOf('day').toDate(),
  toDate: moment().endOf('day').toDate(),
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): IReportFilter => {
  return initialParams
    ? {
        agentCode: '',
        leaderCode: '',
        surveyTitle: '',
        channelSurvey: '',
        fromDate: moment().subtract(3, 'months').startOf('day').toDate(),
        toDate: moment().endOf('day').toDate(),
        page: 1,
        limit: 5,
      }
    : initialState;
};

const NonProspectSurveyReport: FC<NonProspectSurveyReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilter = paramsInitiator(initialParams);

  const [filterState, setFilterState] = useState<IReportFilter>(defaultFilter);
  const [status, setStatus] = useState<string>('');
  const [reportList, setReportList] = useState<PaginateListMore<IReportItem>>();
  const { isLoading, refreshData } = useDataProvider<PaginateListMore<IReportItem>>(
    async () => {
      try {
        const params = handleParams(filterState);
        const result = await Promise.all([
          fetchNonProspectSurveyReport(params, dispatch),
          fetchTotalRecordNonProspectSurveyReport(params, dispatch),
        ]);
        return {
          ...result[0],
          ...result[1],
        };
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsv = () => {
    try {
      const params = handleParams(filterState);
      fetchNonProspectSurveyReportFile(params, dispatch);
    } catch (err) {}
  };

  const handleBeforeChangeFilter = (filters: any) => {
    let fromDate = filters.fromDate ? moment(filters.fromDate).startOf('day').toDate() : filters.fromDate;
    let toDate = filters.toDate ? moment(filters.toDate).endOf('day').toDate() : filters.toDate;

    if (!fromDate && !toDate) {
      fromDate = moment().subtract(3, 'months').startOf('day').toDate();
      toDate = moment().endOf('day').toDate();
    } else if (!fromDate) {
      fromDate = moment(toDate).subtract(3, 'months').startOf('day').toDate();
    } else if (!toDate) {
      toDate = moment(fromDate).add(3, 'months').endOf('day').toDate();
    }

    filters.fromDate = fromDate;
    filters.toDate = toDate;

    return filters;
  };

  const handleParams = (filters: IReportFilter) => {
    return {
      ...filterState,
    };
  };

  return (
    <>
      <PruFilter
        title={Translation('report.survey.nonProspectSurvey.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('report.survey.nonProspectSurvey.agentCode'),
          },

          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'leaderCode',
            initialValue: filterState.leaderCode,
            displayName: Translation('report.survey.nonProspectSurvey.leaderCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'surveyTitle',
            initialValue: filterState.surveyTitle,
            displayName: Translation('report.survey.nonProspectSurvey.surveyTitle'),
          },
          // {
          //   type: PruFilterItemType.FREE_TEXT,
          //   field: "channelSurvey",
          //   initialValue: filterState.channelSurvey,
          //   displayName: Translation("report.survey.nonProspectSurvey.channelSurvey"),
          // },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'channelSurvey',
            initialValue: filterState.channelSurvey,
            displayName: Translation('report.survey.nonProspectSurvey.channel'),
            choices: [
              { displayName: 'All', value: '' },
              ...map(ChannelOption, (option: string, value: string) => ({ displayName: option, value: value })),
            ],
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'fromDate',
            fieldTo: 'toDate',
            initialDateFrom: filterState.fromDate,
            initialDateTo: filterState.toDate,
            defaultDateFrom: defaultFilter.fromDate,
            defaultDateTo: defaultFilter.toDate,
            errorText: status,
            displayName: Translation('report.survey.nonProspectSurvey.surveyDate'),
            required: true,
          },
        ]}
        onChangeFilter={(data) => {
          const dataFormat = handleBeforeChangeFilter(data);
          if (moment(dataFormat.fromDate).isBefore(moment(dataFormat.toDate).subtract(3, 'months').startOf('day'))) {
            -setStatus('Searching period must be less than or equal to 90 days depending on FROM DATE');
            return false;
          } else {
            setStatus('');
            return setFilterState({
              ...filterState,
              ...dataFormat,
            });
          }
        }}
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.survey.nonProspectSurvey.title')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsv(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            keyIndex: 'surveyTitle',
            displayName: Translation('report.survey.nonProspectSurvey.surveyTitle'),
            align: 'left',
            renderData: (row) => row.surveyData.title || '-',
          },
          {
            keyIndex: 'agentCode',
            align: 'left',
            displayName: Translation('report.survey.nonProspectSurvey.agentCode'),
            renderData: (row) => row.agentCode || '-',
          },
          {
            keyIndex: 'agentName',
            align: 'left',
            displayName: Translation('report.survey.nonProspectSurvey.agentName'),
            renderData: (row) => row.agentName || '-',
          },
          {
            keyIndex: 'leaderCode',
            align: 'left',
            displayName: Translation('report.survey.nonProspectSurvey.leaderCode'),
            renderData: (row) => row.leaderCode || '-',
          },
          {
            keyIndex: 'leaderName',
            displayName: Translation('report.survey.nonProspectSurvey.leaderName'),
            align: 'left',
            renderData: (row) => row.leaderName || '-',
          },
          {
            keyIndex: 'region',
            align: 'center',
            displayName: Translation('report.survey.nonProspectSurvey.region'),
            renderData: (row) => row.surveyData.region || '-',
          },
          {
            keyIndex: 'channel',
            align: 'center',
            displayName: Translation('report.survey.nonProspectSurvey.channel'),
            renderData: (row) => row.surveyData.channel || '-',
          },
          {
            keyIndex: 'office',
            align: 'center',
            displayName: Translation('report.survey.nonProspectSurvey.office'),
            renderData: (row) => row.office || '-',
          },
          {
            keyIndex: 'numberOfSent',
            align: 'center',
            displayName: Translation('report.survey.nonProspectSurvey.sent'),
            renderData: (row) => row.numberOfSent || '-',
          },
          {
            keyIndex: 'numberOfOpened',
            align: 'center',
            displayName: Translation('report.survey.nonProspectSurvey.opened'),
            renderData: (row) => row.numberOfOpened || '-',
          },
          {
            keyIndex: 'numberOfReplied',
            align: 'center',
            displayName: Translation('report.survey.nonProspectSurvey.replied'),
            renderData: (row) => row.numberOfReplied || '-',
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default NonProspectSurveyReport;
