import { regionLocale } from 'src/app/i18n';

// COE
export const getCategoryLabel = (row: any) => {
  let label = '';
  regionLocale.forEach((code) => {
    const result = row.i18nDataList.find((item: any) => {
      return item.lang === code;
    });

    // eslint-disable-next-line
    label = label + ' ' + ((result && result.name) || '');
  });

  return label;
};

// PVA
export const getTitleLabel = (row: any) => {
  let label = '';
  regionLocale.forEach((code) => {
    const result = row.i18nDataList.find((item: any) => {
      return item.lang === code;
    });
    // eslint-disable-next-line
    label = label + ', ' + ((result && result.name) || '');
  });
  return label.length > 2 ? label.substring(2) : label;
};

export const getTitleGreetingLabel = (row: any, lang: any) => {
  let label = '';
  const result = row.i18nDataList.find((item: any) => {
    return item.lang === lang;
  });
  label = label + ', ' + ((result && result.name) || '');
  return label.length > 2 ? label.substring(2) : label;
};

export const getSpecialLabel = (row: any, lang: any) => {
  let label = '';
  const result = lang === 'vi' ? row.nameVI : row.nameEN;
  label = result + ' ' + row.date;
  return label;
};
