export enum AgentInfoTypeMode {
  AGENT_CODE = "agentCode",
  OFFICE_CODE = "agentOfficeCode",
  UNIT_CODE = "agentUnitCode",
  BRANCH_CODE = "agentBranchCode",
  ROLE = "agentRole",
};

export type ReportItem = {
  _id?: string,
  fullName: string,
  agentCode: string,
  agentBranchCode: string,
  agentRole: string,
  agentUnitCode: string,
  agentOfficeCode: string,
  materialName: string,
  totalShare: number,
  totalClickThrough: number,
  totalRegister: number,
};

export type ReportListParams = {
  registerDateFrom: Date | null;
  registerDateTo: Date | null;
  agentInfoType: string,
  agentInfoValue: string,
  landingPageName: string,
  page: number;
  limit: number;
};