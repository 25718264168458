import React, { useState } from 'react';
import { ETypeOfView } from 'src/app/common/types/view-types';
import FileUploader, { FileObj } from 'src/app/common/components/FileUploader';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toAbsoluteUrl, fileUpload, preFillZero } from 'src/app/common/utils';
import { createBlob, getBlob } from '../../network';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { RadioButtonChecked, RadioButtonUnchecked, SubdirectoryArrowRight } from '@mui/icons-material';

interface CheckGroupProps {
  value?: any[];
  onChange?: (e: any) => void;
  options: { label: string; value: ETypeOfView }[];
  disabled: boolean;
  moduleType?: string;
  isGetAgentList?: boolean
}

const getInitIncludeSA = function (values: any[]) {
  if (values.length > 0) {
    const item = values.find((x) => x.type === ETypeOfView.AllTraditionalAgents);
    if (item) {
      return item.includeSA || false;
    }
  }
  return false;
};

const getInitUserFile = function (values: any[]) {
  if (values.length > 0) {
    const item = values.find((x) => x.type === ETypeOfView.GroupOfUsers);
    if (item) {
      return item.userFile || undefined;
    }
  }
  return undefined;
};

export default function CheckBoxUserGroup(props: CheckGroupProps) {
  const { value = [], onChange, options, disabled, moduleType } = props;
  const [includeSA, SetIncludeSA] = useState<boolean>(getInitIncludeSA(value));
  const [userFile, SetUserFile] = useState<FileObj | undefined>(getInitUserFile(value));

  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);

  const onChangeIncludeSA = (val: boolean) => {
    SetIncludeSA(val);

    const index = value.findIndex((x) => x.type === ETypeOfView.AllTraditionalAgents);
    if (index !== -1) {
      value[index].includeSA = val;
      if (!onChange) return;
      onChange([...value]);
    }
  };

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        {
          mimeType: fileType,
          accessLevel: 'anonymous',
          module: `material_${(moduleType || '').toLowerCase()}`,
        },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];

      if (result) {
        const fileResult = {
          id: result.blobId,
          fileName: fileName,
          url: result.url,
          type: fileType,
        }
        if (props.isGetAgentList) {
          const csvData = await readFile(file)
          const row = String(csvData).split('\n');
          const agentList: string[] = [];
          row.shift();
          row.forEach((item) => {
            const rowItems = item.split(',');
            console.log(rowItems);

            if (rowItems[0] && rowItems[0].trim()) {
              agentList.push(preFillZero(rowItems[0].trim()));
            }
          });
          return {...fileResult, agentList}
        } else {
          return fileResult
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const readFile = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        resolve(e.target.result)
      }

      reader.onerror = (e: any) => {
        reject(e)
      }

      reader.readAsBinaryString(file);
    })
  }

  const onChangeFileUploader = (item: any) => {
    SetUserFile(item ? item : undefined);

    const index = value.findIndex((x) => x.type === ETypeOfView.GroupOfUsers);
    if (index !== -1) {
      value[index].userFile = item;
      if (!onChange) return;
      onChange([...value]);
    }
  };

  const handleChange = (key: string) => (e: any) => {
    const checked = e.target.checked;
    const index = value.findIndex((item) => item.type === key);

    const newValue = [...value];
    if (!onChange) return;
    let itemChecked: any = { type: key };

    switch (key) {
      case ETypeOfView.AllTraditionalAgents:
        itemChecked.includeSA = includeSA;
        break;
      case ETypeOfView.GroupOfUsers:
        itemChecked.userFile = userFile;
        break;
    }

    if (checked) {
      if (index === -1) {
        newValue.push(itemChecked);
      }
    } else {
      if (index > -1) {
        newValue.splice(index, 1);
      }

      if (key === ETypeOfView.AllTraditionalAgents) {
        SetIncludeSA(false);
      }
    }
    onChange(newValue);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%',
        marginBottom: 10,
      }}
    >
      {options.map(({ label, value: itemValue }, index) => (
        <FormGroup
          key={(itemValue || index) + '_formGroup'}
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingRight: 15,
          }}
        >
          {/* Loop each item */}
          <FormControlLabel
            key={itemValue || index}
            control={
              <Checkbox
                disabled={disabled}
                checked={value.findIndex((item) => item.type === itemValue) > -1}
                onChange={handleChange(itemValue)}
                name={label}
              />
            }
            label={Translation(`component.formSelectItem.viewType.${label}`)}
          />

          {/* IncludeSA */}
          {itemValue === ETypeOfView.AllTraditionalAgents && (
            <FormControlLabel
              style={{ paddingLeft: 20 }}
              control={
                <>
                  <div style={{ paddingLeft: 15 }}>
                    <SubdirectoryArrowRight></SubdirectoryArrowRight>
                  </div>
                  <Checkbox
                    disabled={disabled}
                    checked={includeSA}
                    onChange={(e) => {
                      onChangeIncludeSA(e.target.checked);
                    }}
                    icon={<RadioButtonUnchecked />}
                    checkedIcon={<RadioButtonChecked />}
                  />
                </>
              }
              label={Translation('component.formSelectItem.viewType.include-sa')}
              labelPlacement="end"
            />
          )}

          {/* Upload file for Group of User */}
          {itemValue === ETypeOfView.GroupOfUsers && value.findIndex((item) => item.type === itemValue) > -1 && (
            <>
              <div
                style={{
                  paddingLeft: 20,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div style={{ paddingLeft: 4.2, paddingRight: 15 }}>
                  <SubdirectoryArrowRight></SubdirectoryArrowRight>
                </div>
                <FileUploader
                  disabled={disabled}
                  upload={handleUploadFile}
                  value={userFile}
                  onChange={onChangeFileUploader}
                  color="inherit"
                  showAllowText={Translation('component.form.users-file-description')}
                  maxFileSize={10}
                  allowedFileTypes={[
                    'csv',
                    'xlsx',
                    'xls',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-excel',
                  ]}
                />
              </div>
              {!userFile && (
                <div style={{ paddingLeft: 55, paddingTop: 10 }}>
                  <a href={toAbsoluteUrl('/templates/Template_Group_Of_User.csv')}>
                    {Translation('recruitment.form.downloadTemplate')}
                  </a>
                </div>
              )}
            </>
          )}
        </FormGroup>
      ))}
    </div>
  );
}
