import { forEach } from 'lodash';
import { PaginateListMore } from '../../../common/types/common-types';
import { Dispatch } from 'react';
import { apiClient, apiErrorHandler } from 'src/app/common/network';
import moment from 'moment';
import { CustomerManagementAGViewReportListParams } from '../types/customerManagementAGViewReportTypes';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

export const fetchCustomerManagementAGViewReport = async (
  params: CustomerManagementAGViewReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<any>> => {
  const uri = `${ADMIN_URL}/report/event/customer-report-ag-view`;

  return apiClient
    .post<PaginateListMore<any>>(uri, params)
    .then((response: any) => {
      return response.data;
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCustomerManagementViewAGReportDownload = (
  params: CustomerManagementAGViewReportListParams,
  dispatch?: Dispatch<any>,
): void => {
  params.limit = 999999999;
  const uri = `${ADMIN_URL}/report/event/customer-report-ag-view/export`;

  apiClient
    .post<Blob>(uri, params, { responseType: 'text' })
    .then((response: any) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'Customer-event-report-AGView-' + moment(new Date()).format('DD/MM/YYYY HH:mm:ss') + '.csv',
      );
      document.body.appendChild(link);
      link.click();
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};
