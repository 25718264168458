import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { PaginateList } from 'src/app/common/types/common-types';
import { useDataProvider } from 'src/app/common/utils';
import { fetchList, QuotesAndCertificateListParams } from '../../../network/quotesAndCertificateCrud';
import { QuotesAndCertificateItem } from '../../../types/quotes-and-certificate-types';
import QuotesAndCertificateList from './components/QuotesAndCertificateList';

type MdrtListingPageProps = {} & ParamsProps;

export const filterKeys = ['agentCode'];

const initialState: QuotesAndCertificateListParams = {
  agentCode: '',
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): QuotesAndCertificateListParams => {
  return initialParams
    ? {
        agentCode: initialParams.agentCode ?? '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const QuotesAndCertificateListingPage: FC<MdrtListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<QuotesAndCertificateListParams>(paramsInitiator(initialParams));
  const [itemList, setItemList] = useState<PaginateList<QuotesAndCertificateItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<QuotesAndCertificateItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchList(filterState, dispatch);
      } catch (err) {}
    },
    setItemList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('mdrtHub.quotesAndCertificate.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('app.filter.agentCode'),
            style: { width: 200 },
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            agentCode: data.agentCode,
          })
        }
        fetchData={refreshData}
      />
      <QuotesAndCertificateList
        isLoading={isLoading}
        onRefresh={refreshData}
        itemList={itemList}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default QuotesAndCertificateListingPage;
