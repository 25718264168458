import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { getDayEnd, getFirstDayOfMonth, getDayStart } from '../../../../../common/utils/time-utils';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { PaginateListMore } from 'src/app/common/types/common-types';
import { ReportItem, ReportListParams, ratingModel } from '../../../types/ratingActivityTypes';
import {
  fetchActivityRatingReport,
  fetchActivityRatingReportDownload,
} from '../../../network/activityRatingReportCrud';
import { useLang } from 'src/app/i18n';
import moment from 'moment';
import { useDataProvider } from 'src/app/common/utils';

type ActivityRatingReportProps = {} & ParamsProps;

const initialState: ReportListParams = {
  requestedDateFrom: getFirstDayOfMonth(new Date()),
  requestedDateTo: getDayEnd(new Date()),
  rating: ratingModel.zero,
  content: '',
  page: 1,
  limit: 5,
  lang: '',
};

const paramsInitiator = (initialParams?: Record<string, string>): ReportListParams => {
  return initialParams
    ? {
        requestedDateFrom: initialParams.requestedDateFrom
          ? getDayStart(new Date(initialParams.requestedDateFrom))
          : getFirstDayOfMonth(new Date()),
        requestedDateTo: initialParams.requestedDateTo
          ? getDayEnd(new Date(initialParams.requestedDateTo))
          : getDayEnd(new Date()),
        content: '',
        rating: ratingModel.zero,
        lang: initialState.lang,
        page: 1,
        limit: 5,
      }
    : initialState;
};

const filterOptions = {
  agentTypes: [
    { displayName: 'All', value: ratingModel.zero },
    { displayName: '1', value: ratingModel.one },
    { displayName: '2', value: ratingModel.two },
    { displayName: '3', value: ratingModel.three },
    { displayName: '4', value: ratingModel.four },
    { displayName: '5', value: ratingModel.five },
  ],
};

const ActivityRatingReport: FC<ActivityRatingReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilterValue = paramsInitiator(initialParams);
  const currentLanguage = useLang();

  const [filterState, setFilterState] = useState<ReportListParams>(defaultFilterValue);
  filterState.lang = currentLanguage;
  const [reportList, setReportList] = useState<PaginateListMore<ReportItem>>();
  const { isLoading, refreshData } = useDataProvider<PaginateListMore<ReportItem>>(
    async () => {
      try {
        return await fetchActivityRatingReport(filterState, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvtDownload = () => {
    try {
      fetchActivityRatingReportDownload(filterState, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('report.qrCode.qr.activity.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'content',
            initialValue: filterState.content,
            displayName: Translation('report.qrCode.qr.activityRating.activityName'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'rating',
            initialValue: filterState.rating,
            displayName: Translation('report.qrCode.qr.activityRating.rating'),
            choices: filterOptions.agentTypes,
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'requestedDateFrom',
            fieldTo: 'requestedDateTo',
            initialDateFrom: filterState.requestedDateFrom,
            initialDateTo: filterState.requestedDateTo,
            defaultDateFrom: defaultFilterValue.requestedDateFrom,
            defaultDateTo: defaultFilterValue.requestedDateTo,
            displayName: Translation('report.qrCode.qr.activityRating.filter.ratedDate'),
            required: true,
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            content: data.content,
            rating: data.rating,
            requestedDateFrom: data.requestedDateFrom,
            requestedDateTo: data.requestedDateTo,
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.qrCode.qr.activityRating.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvtDownload(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => row._id || '-',
          },
          {
            keyIndex: 'agentNameScanner',
            align: 'center',
            displayName: 'BDM/AL Name',
            renderData: (row) => row.agentNameScanner || '-',
          },
          {
            keyIndex: 'agentCodeScanner',
            align: 'center',
            displayName: 'BDM/AL Code',
            renderData: (row) => row.agentCodeScanner || '-',
          },
          {
            keyIndex: 'rating',
            align: 'center',
            displayName: 'Rating',
            renderData: (row) => row.rating || '-',
          },
          {
            keyIndex: 'agentName',
            align: 'center',
            displayName: 'Rated by (Name)',
            renderData: (row) => row.agentName || '-',
          },
          {
            keyIndex: 'agentCode',
            align: 'center',
            displayName: 'Rated by (Code)',
            renderData: (row) => row.agentCode || '-',
          },
          {
            keyIndex: 'ratingAt',
            displayName: 'Rated Time',
            align: 'center',
            renderData: (row) => (row.ratingAt !== null ? moment(row.ratingAt).format('DD/MM/YYYY HH:mm') : '-'),
          },
          {
            keyIndex: 'content',
            displayName: 'Activity Name',
            align: 'center',
            renderData: (row) => (currentLanguage === 'en' ? row.content.en : row.content.vi || '-'),
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default ActivityRatingReport;
