import React, { FC, useEffect, useState } from 'react';
import { PaginateList } from '../../../../../../common/types/common-types';
import { ReportAppInstallmentItem } from '../../../../types/report-types';
import { useDataProvider } from 'src/app/common/utils';
import {
  ReportIAppInstallmentParams,
  fetchAppInstallmentReportList,
  fetchAppInstallmentReportDownload,
} from '../../../../network/reportCrud';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../../common/components/ParamsProvider';
import { getDayStart, getDayEnd } from '../../../../../../common/utils/time-utils';
import PruTable from '../../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../../common/components/PruTable/PruFilter';
import moment from 'moment';
import { makeStyles } from 'tss-react/mui';

type AgentsListingPageProps = {} & ParamsProps;

const salesChannelOptions = [
  { displayName: 'All', value: 'ALL' },
  { displayName: 'Pruventure', value: 'GALLERIE' },
  { displayName: 'Traditional Agency', value: 'AGENCY' },
  { displayName: 'Banca', value: 'BANCA' },
];

const initialState: ReportIAppInstallmentParams = {
  requestedDateFrom: moment().startOf('month').subtract(2, 'month').toDate(),
  requestedDateTo: moment().toDate(),
  unitCode: '',
  branchCode: '',
  officeCode: '',
  agentCode: '',
  salesChannel: ['ALL'],
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): ReportIAppInstallmentParams => {
  return initialParams
    ? {
        requestedDateFrom: initialParams.requestedDateFrom
          ? getDayStart(new Date(initialParams.requestedDateFrom))
          : moment().startOf('month').subtract(2, 'month').toDate(),
        requestedDateTo: initialParams.requestedDateTo
          ? getDayEnd(new Date(initialParams.requestedDateTo))
          : moment().toDate(),
        branchCode: initialParams.branchCode ? initialParams.branchCode : '',
        officeCode: initialParams.officeCode ? initialParams.officeCode : '',
        unitCode: initialParams.unitCode ? initialParams.unitCode : '',
        agentCode: initialParams.agentCode ? initialParams.agentCode : '',
        salesChannel: ['ALL'],
        page: 1,
        limit: 5,
      }
    : initialState;
};

const useStyles = makeStyles()(() => ({
  btnContainer: {
    display: 'flex',
    marginBottom: 15,
    'flex-direction': 'row-reverse',
  },
}));

const InstallmentListingPage: FC<AgentsListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const [filterState, setFilterState] = useState<ReportIAppInstallmentParams>(paramsInitiator(initialParams));

  const [reportList, setReportList] = useState<PaginateList<ReportAppInstallmentItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<ReportAppInstallmentItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchAppInstallmentReportList({ ...filterState, eventType: 'SUCCESS' }, sortKey, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvDownload = () => {
    try {
      fetchAppInstallmentReportDownload({ ...filterState, eventType: 'SUCCESS' }, dispatch);
    } catch (err) {}
  };

  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    setSortKey(sortingKeyArray);
  };

  const getInstallationDate = (dates: { date: string; version: string }[]) => {
    let rs;
    for (let index = 0; index < dates.length; index++) {
      const element = dates[index];
      if (
        new Date(element.date) > getDayStart(filterState.requestedDateFrom) &&
        new Date(element.date) < getDayEnd(filterState.requestedDateTo)
      ) {
        rs = element;
        break;
      }
    }
    return rs ? moment(rs.date).format('DD/MM/YYYY HH:mm:ss') : '';
  };

  const getSalesChannel = (agency: string) => {
    const e = salesChannelOptions.find((option) => option.value === agency);
    return e ? e.displayName : '';
  };

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortKey]);

  return (
    <>
      <PruFilter
        title={Translation('report.appPerfTracking.appInstallment.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('report.appPerfTracking.agentCode'),
          },
          {
            type: PruFilterItemType.MULTIPLE_SELECT,
            field: 'salesChannel',
            initialValue: filterState.salesChannel,
            defaultValue: initialState.salesChannel,
            allValue: 'ALL',
            displayName: Translation('report.appPerfTracking.salesChannel'),
            choices: salesChannelOptions,
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'unitCode',
            initialValue: filterState.unitCode,
            displayName: Translation('report.appPerfTracking.unitCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'branchCode',
            initialValue: filterState.branchCode,
            displayName: Translation('report.appPerfTracking.branchCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'officeCode',
            initialValue: filterState.officeCode,
            displayName: Translation('report.appPerfTracking.officeCode'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'requestedDateFrom',
            fieldTo: 'requestedDateTo',
            initialDateFrom: filterState.requestedDateFrom,
            initialDateTo: filterState.requestedDateTo,
            displayName: Translation('report.common.requestDate'),
            defaultDateFrom: initialState.requestedDateFrom,
            defaultDateTo: initialState.requestedDateTo,
            required: true,
            periodTime: { months: 3 },
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            officeCode: data.officeCode,
            unitCode: data.unitCode,
            branchCode: data.branchCode,
            requestedDateFrom: data.requestedDateFrom,
            requestedDateTo: data.requestedDateTo,
            salesChannel: data.salesChannel,
            agentCode: data.agentCode,
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.appPerfTracking.appInstallment.list')}
        operationDef={[]}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvDownload(),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: () => '',
          },
          {
            keyIndex: 'agentCode',
            align: 'center',
            displayName: Translation('report.appPerfTracking.agentCode'),
            sortable: true,
            renderData: (row) => row.agentCode,
            onSort: (sort) => {
              updateSortingKey({ key: 'agentCode', value: sort['agentCode'] });
            },
          },
          {
            keyIndex: 'salesChannel',
            align: 'center',
            displayName: Translation('report.appPerfTracking.salesChannel'),
            sortable: true,
            renderData: (row) => getSalesChannel(row.salesChannel),
            onSort: (sort) => {
              updateSortingKey({ key: 'salesChannel', value: sort['salesChannel'] });
            },
          },
          {
            keyIndex: 'unitCode',
            align: 'center',
            sortable: true,
            displayName: Translation('report.appPerfTracking.unitCode'),
            renderData: (row) => row.unitCode,
            onSort: (sort) => {
              updateSortingKey({ key: 'unitCode', value: sort['unitCode'] });
            },
          },
          {
            keyIndex: 'branchCode',
            align: 'center',
            sortable: true,
            displayName: Translation('report.appPerfTracking.branchCode'),
            renderData: (row) => row.branchCode,
            onSort: (sort) => {
              updateSortingKey({ key: 'branchCode', value: sort['branchCode'] });
            },
          },
          {
            keyIndex: 'officeCode',
            align: 'center',
            sortable: true,
            displayName: Translation('report.appPerfTracking.officeCode'),
            renderData: (row) => row.officeCode,
            onSort: (sort) => {
              updateSortingKey({ key: 'officeCode', value: sort['officeCode'] });
            },
          },
          {
            keyIndex: 'installationDate',
            align: 'center',
            // sortable: true,
            displayName: Translation('report.appPerfTracking.installationDate'),
            renderData: (row) => getInstallationDate(row.installationDates),
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.total}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default InstallmentListingPage;
