import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  AdminLivestreamManagementPermissionType,
  LIVESTREAM_BASE_PATH,
  LIVESTREAM_MANAGEMENT_BASE_PATH,
  LIVESTREAM_MANAGEMENT_NAMESPACE,
  LIVESTREAM_MANAGEMENT_TITLE,
  LIVESTREAM_NAMESPACE,
  LIVESTREAM_TITLE,
} from './constants';
import LivestreamPage from './pages/Livestream-management/LivestreamPage';
import { isPermitted } from 'src/app/common/utils';

export const livestreamModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(LIVESTREAM_TITLE),
    path: LIVESTREAM_BASE_PATH,
    icon: '/media/svg/icons/Communication/Livestream.svg',
    enableRead: isPermitted(
      [
        AdminLivestreamManagementPermissionType.LIVESTREAM_MANAGEMENT_READ,
        AdminLivestreamManagementPermissionType.LIVESTREAM_MANAGEMENT_CREATE,
        AdminLivestreamManagementPermissionType.LIVESTREAM_MANAGEMENT_UPDATE,
      ],
      permissions,
    ),
    child: [
      {
        title: Translation(LIVESTREAM_MANAGEMENT_TITLE),
        path: `${LIVESTREAM_BASE_PATH}${LIVESTREAM_MANAGEMENT_BASE_PATH}`,
        component: LivestreamPage,
        namespace: LIVESTREAM_MANAGEMENT_NAMESPACE,
        enableRead: isPermitted(
          [
            AdminLivestreamManagementPermissionType.LIVESTREAM_MANAGEMENT_READ,
            AdminLivestreamManagementPermissionType.LIVESTREAM_MANAGEMENT_CREATE,
            AdminLivestreamManagementPermissionType.LIVESTREAM_MANAGEMENT_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminLivestreamManagementPermissionType.LIVESTREAM_MANAGEMENT_CREATE], permissions),
        enableUpdate: isPermitted([AdminLivestreamManagementPermissionType.LIVESTREAM_MANAGEMENT_UPDATE], permissions),
      },
    ],
  };
};
