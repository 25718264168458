export enum ETypeOfView {
  AllAgents = "allAgents",
  AllTraditionalAgents = "allTraditionalAgents",
  AgentLeaders = "agentLeaders",
  PruventureLeaders = "pruventureLeaders",
  Pruventure = "pruventure",
  GroupOfUsers = "groupOfUsers",
}

export type ITypeOfView = {
  includeSA?: boolean;
  type: ETypeOfView;
  userFile?: any;
  userList?: string[];
  createdAt?: Date;
  updatedAt?: Date;
  published_at?: Date;
  id?: string;
  importContentId?: string;
};

export const typeOfViewOptions = [
  { value: ETypeOfView.AllTraditionalAgents, label: 'all-traditional-agents' },
  { value: ETypeOfView.AgentLeaders, label: 'agent-leaders' },
  { value: ETypeOfView.PruventureLeaders, label: 'pruventure-leaders' },
  { value: ETypeOfView.Pruventure, label: 'pruventure' },
  { value: ETypeOfView.GroupOfUsers, label: 'group-of-users' },
]