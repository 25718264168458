import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { useDataProvider } from 'src/app/common/utils';
import {
  ReportIncentiveRegistrationItemV2,
  ReportIncentiveRegistrationParamsV2,
} from 'src/app/modules/Report/types/incentive-types-v2';
import { ParamsProps } from '../../../../../../common/components/ParamsProvider';
import { PaginateListMore } from '../../../../../../common/types/common-types';
import {
  fetchIncentiveRegistrationReportDownloadV2,
  fetchIncentiveRegistrationReportListV2,
} from '../../../../network/reportCrud';

type IncentiveResultV2ListingPageProps = {} & ParamsProps;

const initialState: ReportIncentiveRegistrationParamsV2 = {
  registerDateFrom: new Date(),
  registerDateTo: new Date(),
  agentCode: '',
  incentiveName: '',
  incentiveType: '',
  saleChannel: '',
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): ReportIncentiveRegistrationParamsV2 => {
  return initialParams
    ? {
        registerDateFrom: initialParams.requestedDateFrom ? new Date(initialParams.requestedDateFrom) : new Date(),
        registerDateTo: initialParams.requestedDateTo ? new Date(initialParams.requestedDateTo) : new Date(),
        agentCode: '',
        incentiveName: '',
        incentiveType: '',
        saleChannel: '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const IncentiveResultListingPageV2: FC<IncentiveResultV2ListingPageProps> = ({
  initialParams,
  onChangeQueryParams,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<ReportIncentiveRegistrationParamsV2>(paramsInitiator(initialParams));

  const [reportList, setReportList] = useState<PaginateListMore<ReportIncentiveRegistrationItemV2>>();

  const { isLoading, refreshData } = useDataProvider<PaginateListMore<ReportIncentiveRegistrationItemV2>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchIncentiveRegistrationReportListV2(filterState, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvFile = async () => {
    try {
      fetchIncentiveRegistrationReportDownloadV2(filterState, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('incentiveRegistration.filter')}
        itemDef={[
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'registerDateFrom',
            fieldTo: 'registerDateTo',
            initialDateFrom: filterState.registerDateFrom,
            initialDateTo: filterState.registerDateTo,
            displayName: Translation('incentive.common.registerDate'),
            required: true,
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('incentive.common.agentCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'incentiveName',
            initialValue: filterState.incentiveName,
            displayName: Translation('incentive.common.name'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'incentiveType',
            initialValue: filterState.incentiveType,
            displayName: 'Incentive Type',
            choices: [
              { displayName: 'All', value: '' },
              { displayName: 'Incentive', value: 'INCENTIVE' },
              { displayName: 'Compensation', value: 'COMPENSATION' },
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'saleChannel',
            initialValue: filterState.saleChannel,
            displayName: 'Sale Channel',
            choices: [
              { displayName: 'All', value: '' },
              // { displayName: 'Traditional', value: 'Traditional' },
              { displayName: 'Traditional Agent', value: 'Agent' },
              { displayName: 'Pruventure', value: 'Pruventure' },
              { displayName: 'Banca', value: 'Banca' },
              { displayName: 'Other', value: 'Other' },
            ],
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            registerDateFrom: data.registerDateFrom,
            registerDateTo: data.registerDateTo,
            agentCode: data.agentCode,
            incentiveName: data.incentiveName,
            incentiveType: data.incentiveType,
            saleChannel: data.saleChannel,
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('incentiveRegistration.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvFile(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: () => '',
          },
          {
            keyIndex: 'incentiveCode',
            displayName: Translation('incentive.common.code'),
            renderData: (row) => row.incentiveCode,
          },
          {
            keyIndex: 'incentiveName',
            displayName: Translation('incentive.common.name'),
            renderData: (row) => row.incentiveName,
          },
          {
            keyIndex: 'fromDate',
            displayName: Translation('incentive.common.durationFromDate'),
            renderData: (row) => row.fromDate,
          },
          {
            keyIndex: 'toDate',
            displayName: Translation('incentive.common.durationToDate'),
            renderData: (row) => row.toDate,
          },
          {
            keyIndex: 'agentCode',
            displayName: Translation('incentive.common.agentCode'),
            renderData: (row) => row.agentCode,
          },
          {
            keyIndex: 'agentName',
            displayName: Translation('incentive.common.agentFullName'),
            renderData: (row) => row.agentName,
          },
          {
            keyIndex: 'registerDate',
            displayName: Translation('incentive.common.registerDate'),
            renderData: (row) => row.registerDate,
          },
          {
            keyIndex: 'registerTime',
            displayName: Translation('incentive.common.registerTime'),
            renderData: (row) => row.registerTime,
          },
          {
            keyIndex: 'order',
            align: 'center',
            displayName: Translation('incentive.common.order'),
            renderData: (row) => String(row.order),
          },
          {
            keyIndex: 'option',
            align: 'center',
            displayName: Translation('incentive.common.option'),
            renderData: (row) => String(row.option),
          },
          {
            keyIndex: 'incentiveType',
            align: 'center',
            displayName: 'Incentive Type',
            renderData: (row) => String(row.incentiveType),
          },
          {
            keyIndex: 'saleChannel',
            align: 'center',
            displayName: 'Sale Channel',
            renderData: (row) => String(row.saleChannel),
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default IncentiveResultListingPageV2;
