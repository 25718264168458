import { ETypeOfView } from '../types/view-types';

export const typeOfViewOptions = [
  { value: ETypeOfView.AllTraditionalAgents, label: "all-traditional-agents" },
  { value: ETypeOfView.AgentLeaders, label: "agent-leaders" },
  { value: ETypeOfView.PruventureLeaders, label: "pruventure-leaders" },
  { value: ETypeOfView.Pruventure, label: "pruventure" },
  { value: ETypeOfView.GroupOfUsers, label: "group-of-users" },
];

export const typeOfViewLiveStream = [
  { value: ETypeOfView.AllAgents, label: "all-agents" },
  { value: ETypeOfView.AllTraditionalAgents, label: "all-traditional-agents" },
  { value: ETypeOfView.Pruventure, label: "pruventure" },
  { value: ETypeOfView.GroupOfUsers, label: "group-of-users" },
];
