import { Routes } from 'src/app/common/constants';

export const ModuleTypeList: { [key: string]: string } = {
  // COE
  Sales: 'Sales',
  Promotion: 'Promotion',
  CustomerSurvey: 'CustomerSurvey',
  AgentLeadForm: 'AgentLeadForm',
  Recruit: 'Recruit',

  // PVA
  NonProspectSurvey: 'NonProspectSurvey',
  //PVA don't have this type
  // AgentProfile: 'AgentProfile',
  // CustomerSurvey: 'CustomerSurvey',
  // AgentLeadForm: 'AgentLeadForm',
};

export const ModuleTypeDisplay: { [key: string]: string } = {
  AgentProfile: 'Agent Profile',
  Sales: 'Sales Library',
  Promotion: 'Promotion Library',
  NonProspectSurvey: 'Non-Prospect Survey',
  CustomerSurvey: 'Customer Survey',
  AgentLeadForm: 'Agent Lead Form',
  Recruit: 'Recruit',
};

export const ModuleTypeTranslate: { [key: string]: string } = {
  Sales: 'salesMaterial',
  Promotion: 'promotionMaterial',
  NonProspectSurvey: 'nonProspectSurvey',
  CustomerSurvey: 'customerSurvey',
  Recruit: 'recruit',
};

export const ModuleTypeRoute: { [key: string]: string } = {
  // COOE
  AgentProfile: Routes.SALES_KIT_AGENT_PROFILE,
  Sales: Routes.SALES_KIT_RESOURCE_WEBVIEW,
  Promotion: Routes.SALES_KIT_RESOURCE_WEBVIEW,
  Recruit: Routes.SALES_KIT_RESOURCE_WEBVIEW,
  CustomerSurvey: Routes.SPECIFIC_SURVEY_LINK,
  AgentLeadForm: Routes.SALES_KIT_LEAD_FORM,

  // PVA
  NonProspectSurvey: Routes.NON_PROSPECT_SURVEY_LINK,
};
