import React, { Fragment, useState, useMemo, CSSProperties } from 'react';
import { usePaginateHandler } from '../../utils/paginate-utils';
import { useCommonStyles } from '../../styles/common-styles';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  Button,
  TableBody,
  TableFooter,
  TablePagination,
  TableCellProps,
  Toolbar,
  lighten,
  Checkbox,
  Typography,
  TableSortLabel,
} from '@mui/material';
import { PruTableHeader } from '../Table/PruTableHeader';
import PruTableLoading from '../Table/PruTableLoading';
import PruTableEmptyRow from '../Table/PruTableEmptyRow';
import { PruTableRow } from '../Table/PruTableRow';
import PruTablePaginationActions from '../Table/PruTablePaginationActions';
import { useIntl } from 'react-intl';
import PruTab from './PruTab';
import { makeStyles } from 'tss-react/mui';
import {
  AddBoxOutlined,
  CheckBoxOutlineBlankOutlined,
  IndeterminateCheckBox,
  IndeterminateCheckBoxOutlined,
  Refresh,
} from '@mui/icons-material';

export enum PruTableSortType {
  ASC = 'asc',
  DESC = 'desc',
}

export type PruTabItem = {
  displayName: string;
  value: string;
};

export type PruTableButtonDef = {
  color: 'inherit' | 'primary' | 'secondary' | 'default';
  title: string | JSX.Element;
  onClick: () => void;
  condition?: () => boolean;
};

export type PruTableOperationDef<T> = {
  title: string | JSX.Element;
  tooltipText: string;
  condition?: (row: T) => boolean;
  onClick: (row: T) => void;
};

export type PruTableBulkSelectDef<T> = {
  title: string | JSX.Element;
  variant?: 'text' | 'outlined' | 'contained';
  color: 'inherit' | 'primary' | 'secondary' | 'default';
  onClick: (rows: T[]) => void;
  condition?: (rows: T[]) => boolean;
};

export type PruTableColumnDef<T> = {
  isId?: boolean;
  hidden?: boolean;
  keyIndex: keyof T | string;
  displayName: string | JSX.Element;
  sortable?: boolean;
  style?: CSSProperties;
  renderData: (row: T) => string | JSX.Element;
  onSort?: (sort: SortState) => void;
} & TableCellProps;

type PruTableTabProps<T extends Record<string, unknown>> = {
  title?: string;
  subTitle?: string;
  isLoading: boolean;
  disableBulkSelect?: boolean;
  bulkSelectCheckboxDisable?: (rowData: any) => boolean;
  bulkSelectDef?: PruTableBulkSelectDef<T>[];
  currentSelectedRow?: (rowData: any) => void;
  disableRefresh?: boolean;
  disablePagination?: boolean;
  headerBtnDef?: PruTableButtonDef[];
  operationDef: PruTableOperationDef<T>[];
  columnDef: PruTableColumnDef<T>[];
  tabItems?: PruTabItem[];
  tabValue?: number;
  dataSource?: T[];
  totalRecords?: number;
  totalPages?: number;
  renderChildren?: boolean;
  rowsPerPageInit?: number;
  onRefresh: () => void;
  onChangePage?: (page: number, rowsPerPage: number) => void;
  onChangeTab?: (event: any, tab: number) => void;
};

const useStyles = makeStyles()((theme) => ({
  table: {
    minWidth: 700,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tableHeader: {
    width: '100%',
    padding: '20px 0 20px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  bulkActions: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sortIcon: {
    color: `${theme.palette.common.white} !important`,
  },
  headerIcon: {
    color: `${theme.palette.common.white} !important`,
    opacity: 1,
  },
  selectedRow: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  },
  footer: {
    width: '100%',
  },
  operationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  operationBtn: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '0.85rem',
    whiteSpace: 'nowrap',
  },
  disabled: {
    color: '#BBBBBB',
    cursor: 'default',
  },
  tabItem: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    textTransform: 'none',
  },
  tabCell: {
    paddingTop: '10px',
    paddingLeft: '0',
    paddingRight: '0',
    paddingBottom: '35px',
    borderBottomWidth: '0',
    '& > div': {
      paddingLeft: '27px',
      borderBottom: '2px solid rgba(224, 224, 224, 1)',
    },
  },
  noBorderBottom: {
    borderBottomWidth: '0',
    paddingBottom: '0',
  },
}));

type SortState = {
  [id: string]: PruTableSortType | undefined;
};

const PruTableTab = <T extends Record<string, unknown>>({
  title,
  subTitle,
  isLoading,
  disableRefresh,
  disableBulkSelect,
  disablePagination,
  bulkSelectCheckboxDisable,
  currentSelectedRow,
  headerBtnDef,
  bulkSelectDef,
  operationDef,
  columnDef,
  tabItems,
  tabValue,
  dataSource,
  totalRecords,
  totalPages,
  renderChildren,
  onRefresh,
  onChangePage,
  onChangeTab,
  rowsPerPageInit,
}: PruTableTabProps<T>) => {
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, variable: Record<string, string | number>) =>
    intl.formatMessage({ id: key }, variable);

  const colCount = useMemo(() => {
    return (
      columnDef.filter((column) => !column.hidden).length +
      Number(operationDef.length > 0) +
      Number(!!!disableBulkSelect)
    );
  }, [columnDef, operationDef, disableBulkSelect]);

  const idKeyIndex = useMemo(() => {
    const idCol = columnDef.find((column) => column.isId === true);
    return idCol ? idCol.keyIndex : '';
  }, [columnDef]);

  // Column Sort Handling
  const [sortState, setSortState] = useState<SortState>({});

  const toggleDirection = (direction: PruTableSortType | undefined) => {
    if (direction === PruTableSortType.ASC) {
      return PruTableSortType.DESC;
    } else if (direction === PruTableSortType.DESC) {
      return undefined;
    } else {
      return PruTableSortType.ASC;
    }
  };

  const handleSort = (column: PruTableColumnDef<T>) => {
    if (column.sortable) {
      const newSortState: SortState = {
        ...sortState,
        [column.keyIndex]: toggleDirection(sortState[column.keyIndex as string]),
      };
      setSortState(newSortState);
      if (column.onSort) {
        column.onSort(newSortState);
      }
    }
  };

  // Bulk Select Handling
  const [rowSelected, setRowSelected] = useState<T[]>([]);
  const [rowOpen, setRowOpen] = useState<string[]>([]);

  const onSelectAllRow = () => {
    setRowSelected(rowSelected.length === 0 ? dataSource || [] : []);
  };

  const onSelectRow = (row: T) => {
    const foundIndex = rowSelected.findIndex((selectedItem) => selectedItem[idKeyIndex] === row[idKeyIndex]);
    if (foundIndex === -1) {
      setRowSelected([...rowSelected, row]);
      if (currentSelectedRow) {
        currentSelectedRow([...rowSelected, row]);
      }
    } else {
      let newArr = [...rowSelected];
      newArr.splice(foundIndex, 1);
      setRowSelected(newArr);
      if (currentSelectedRow) {
        currentSelectedRow(newArr);
      }
    }
  };

  const onOpenRow = (row: T) => {
    const foundIndex = rowOpen.findIndex((selectedItem) => selectedItem === row.id);
    if (foundIndex === -1) {
      //@ts-ignore
      setRowOpen([...rowOpen, row.id]);
    } else {
      let newArr = [...rowOpen];
      newArr.splice(foundIndex, 1);
      setRowOpen(newArr);
    }
  };

  const _renderTableRow = (isRowSelected: any, row: any, index: any, level: number = 0) => {
    const isRowOpened = !!rowOpen.find((selectedItem) => {
      return selectedItem === row.id;
    });
    return (
      <Fragment>
        <PruTableRow className={isRowSelected ? classes.selectedRow : ''}>
          {!disableBulkSelect && (
            <TableCell padding="checkbox">
              <Checkbox
                onClick={() => onSelectRow(row)}
                checked={isRowSelected}
                disabled={bulkSelectCheckboxDisable ? bulkSelectCheckboxDisable(row) : false}
              />
            </TableCell>
          )}

          {columnDef.map((column: any, columnIndex: any) => {
            return (
              !column.hidden && (
                <TableCell style={column.style} key={`table-row-field-${column.keyIndex}`} align={column.align}>
                  {renderChildren && (
                    <Fragment>
                      {columnIndex === 0 && row.children && row.children.length > 0 && (
                        <span style={{ marginLeft: 18 * (level - 1) }}>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              onOpenRow(row);
                            }}
                          >
                            {!isRowOpened ? <AddBoxOutlined /> : <IndeterminateCheckBoxOutlined />}
                          </IconButton>
                        </span>
                      )}
                      {columnIndex === 0 && (!row.children || row.children.length === 0) && (
                        <span style={{ marginLeft: 18 * level }}></span>
                      )}
                    </Fragment>
                  )}
                  {column.renderData(row)}
                </TableCell>
              )
            );
          })}
          {operationDef.length > 0 && (
            <TableCell align="center">
              <div className={classes.operationContainer}>
                {operationDef.map((operation, index) => (
                  <Fragment key={`operation-${index}`}>
                    {((operation.condition !== undefined && operation.condition(row)) || !operation.condition) && (
                      <Tooltip title={operation.tooltipText}>
                        <div
                          style={{ marginRight: operationDef[index + 1] ? 10 : 0 }}
                          className={`${classes.operationBtn}`}
                          onClick={() => operation.onClick(row)}
                        >
                          {operation.title}
                        </div>
                      </Tooltip>
                    )}
                  </Fragment>
                ))}
              </div>
            </TableCell>
          )}
        </PruTableRow>
        {renderChildren &&
          isRowOpened &&
          row.children &&
          row.children.length > 0 &&
          row.children.map((item: any, childrenIndex: any) =>
            _renderTableRow(isRowSelected, item, childrenIndex, level + 1),
          )}
      </Fragment>
    );
  };

  // Paginate Handling
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePaginateHandler(
    onChangePage,
    totalPages,
    0,
    rowsPerPageInit ?? 5,
  );

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell
              className={classes.noBorderBottom}
              colSpan={Number.isInteger(colCount / 2) ? colCount / 2 : colCount / 2 + 0.5}
            >
              {title && (
                <div className={commonClasses.header}>
                  {title}
                  <span
                    style={{
                      color: '#888888',
                      fontSize: '12px',
                      marginLeft: '5px',
                    }}
                  >
                    {subTitle}
                  </span>
                </div>
              )}
            </TableCell>
            <TableCell
              className={classes.noBorderBottom}
              colSpan={Number.isInteger(colCount / 2) ? colCount / 2 : colCount / 2 - 0.5}
              align="right"
            >
              <div style={{ justifyContent: 'flex-end' }} className={classes.rowContainer}>
                {!disableRefresh && (
                  <Tooltip title="Refresh">
                    <IconButton onClick={onRefresh}>
                      <Refresh />
                    </IconButton>
                  </Tooltip>
                )}
                {headerBtnDef &&
                  headerBtnDef.map(
                    (btn) =>
                      (!btn.condition || (btn.condition !== undefined && btn.condition())) && (
                        <Button
                          key={`header-button-${btn.title}`}
                          style={{ marginLeft: 15 }}
                          variant="contained"
                          color="secondary"
                          onClick={btn.onClick}
                        >
                          {btn.title}
                        </Button>
                      ),
                  )}
              </div>
            </TableCell>
          </TableRow>
          {tabItems && (
            <TableRow>
              <TableCell className={classes.tabCell} colSpan={colCount}>
                <PruTab
                  itemDef={tabItems}
                  value={tabValue}
                  className={`${classes.tabItem}`}
                  onChangeTab={onChangeTab}
                />
              </TableCell>
            </TableRow>
          )}
          {!disableBulkSelect && rowSelected.length > 0 && (
            <TableRow>
              <TableCell padding="none" colSpan={colCount}>
                <Toolbar className={classes.bulkActions}>
                  <Typography color="inherit" variant="subtitle1" component="div">
                    {TranslationWithVariable('prutable.rowSelected', { num: rowSelected.length })}
                  </Typography>
                  <div>
                    {bulkSelectDef &&
                      bulkSelectDef.length > 0 &&
                      bulkSelectDef.map((action) => (
                        <Button
                          variant={action.variant}
                          disabled={
                            !((action.condition !== undefined && action.condition(rowSelected)) || !action.condition)
                          }
                          onClick={() => {
                            action.onClick(rowSelected);
                            setRowSelected([]);
                          }}
                        >
                          {action.title}
                        </Button>
                      ))}
                  </div>
                </Toolbar>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            {!disableBulkSelect && (
              <PruTableHeader padding="checkbox">
                <Checkbox
                  disabled={Number(dataSource?.length) <= 0}
                  icon={<CheckBoxOutlineBlankOutlined style={{ color: '#FFFFFF' }} />}
                  checkedIcon={<IndeterminateCheckBox style={{ color: '#FFFFFF' }} />}
                  checked={rowSelected.length > 0}
                  onChange={onSelectAllRow}
                  inputProps={{ 'aria-label': 'select all rows', style: { backgroundColor: '#FFF' } }}
                />
              </PruTableHeader>
            )}
            {columnDef.map(
              (column: any) =>
                !column.hidden && (
                  <Fragment key={`table-column-${column.keyIndex}`}>
                    <PruTableHeader
                      align={column.align}
                      style={column.sortable ? { cursor: 'pointer', whiteSpace: 'nowrap' } : {}}
                      onClick={() => handleSort(column)}
                    >
                      {column.displayName}
                      {column.sortable && sortState[column.keyIndex as string] !== undefined && (
                        <TableSortLabel
                          classes={{
                            icon: classes.headerIcon,
                          }}
                          className={classes.sortIcon}
                          active={true}
                          direction={sortState[column.keyIndex as string]}
                        />
                      )}
                    </PruTableHeader>
                  </Fragment>
                ),
            )}
            {operationDef.length > 0 && (
              <PruTableHeader align="center">{Translation('section.common.operation')}</PruTableHeader>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <PruTableLoading isLoading={isLoading} />
          <PruTableEmptyRow isEmpty={!!(!dataSource || (dataSource && dataSource.length <= 0))} />
          {dataSource &&
            dataSource.map((row, index) => {
              const isRowSelected = !!rowSelected.find((selectedItem) => selectedItem[idKeyIndex] === row[idKeyIndex]);
              return <Fragment key={index}>{_renderTableRow(isRowSelected, row, index)}</Fragment>;
            })}
        </TableBody>
        {!disablePagination && (
          <TableFooter>
            <TableRow>
              <TablePagination
                align="right"
                rowsPerPageOptions={[5, 10, 20, 50]}
                colSpan={colCount}
                count={totalRecords ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={PruTablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default PruTableTab;
