import { AttachmentDef } from 'src/app/common/types/common-types';
import { I18nDataItem } from 'src/app/i18n';

export enum AgentLeadSourceFormMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export type AgentLeadSource = {
  _id: string;
  sourceName: string;
  i18nData?: I18nDataItem;
  image: AttachmentDef;
  parent?: string | AgentLeadSource;
  isDefault?: boolean;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
};

export type AgentItem = {
  _id: string;
  agentCode: string;
  agentLeaderId: string;
  agentPreference: string;
  agency: string;
  awards: string;
  city: string;
  dateOfHire: string;
  directDivision: string;
  email: string;
  gender: string;
  misconducts: string;
  mobilePhone: string;
  matchMobilePhone: string;
  name: string;
  race: string;
  roleCode: string;
  lastLogin: string;
  dob: string;
  zone: string;
  division: string;
  productTraining: string;
  productLicenses: string;
  pruLeadsAgent: boolean;
  pruLeadsTraining: boolean;
  yearsOfExperience: number;

  isActive: boolean;
  isDeleted: boolean;

  allowCreateProspect: boolean;
  viewAgentsProspects: boolean;
  agentCoachingId: string;
  lastCoachingDate: Date;
  agentGroup: string[];
  officeCode: string;
  allowEngagements: boolean;
  allowLeaderCoaching: string;
};

export type ReassignAgentItem = {
  fromAgent: string;
  toAgent: string;
};

export enum ReassignSourceTypeEnum {
  ALL_MARKETING_LEADS = 'allMarketingLeads',
  SPECIFIC_LEAD_CAMPAIGN = 'specificLeadCampaign',
  SPECIFIC_LEAD_SOURCE = 'specificLeadSource',
}

export enum LeadActivityStatusEnum {
  ALL_LEADS = 'allLeads',
  OPEN_LEADS = 'openLeads',
}

export enum UpdateActivityStatusEnum {
  KEEP_SAME_ACTIVITY_STATUS = 'keepSameActivityStatus',
  UPDATE_TO_PENDING_CONTACT = 'updateToPendingContact',
}

export enum LeadScopingTypeEnum {
  CAMPAIGN = 'campaign',
  LEAD_SOURCE = 'lead source',
}
