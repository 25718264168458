import React, { FC, useEffect } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../../../../common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../../common/constants';
import { SALESKIT_BASE_PATH, BDM_EVENT_BASE_PATH, BDM_GP_EVENT_BASE_PATH } from '../../../../constants';
import { ModulePermissionProps } from '../../../../../../common/module/types';
import { useDispatch } from 'react-redux';
import { resetState } from '../../../../redux/saleskit.slice';
import BdmGPEventListPage from './list/bdm-gp-event-list.page';
import BdmGPEventDetailPage from './detail/bdm-gp-event-detail.page';

const BdmGPEventRoutes: FC<RouteComponentProps & ModulePermissionProps> = ({
  enableRead,
  enableCreate,
  enableUpdate,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      // clear list state
      dispatch(resetState());
    };
  }, []);
  return (
    <Switch>
      <Redirect
        exact
        from={`${SALESKIT_BASE_PATH}${BDM_EVENT_BASE_PATH}${BDM_GP_EVENT_BASE_PATH}`}
        to={`${SALESKIT_BASE_PATH}${BDM_EVENT_BASE_PATH}${BDM_GP_EVENT_BASE_PATH}/list`}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${BDM_EVENT_BASE_PATH}${BDM_GP_EVENT_BASE_PATH}/list`}
        render={(props) => (
          <BdmGPEventListPage
            {...props}
            enableRead={enableRead}
            enableCreate={enableCreate}
            enableUpdate={enableUpdate}
          />
        )}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${BDM_EVENT_BASE_PATH}${BDM_GP_EVENT_BASE_PATH}/view/:id`}
        component={BdmGPEventDetailPage}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default BdmGPEventRoutes;
