import React, { FC, useEffect, useState } from 'react';
import { PaginateListMore } from '../../../../../../common/types/common-types';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../../common/components/ParamsProvider';
import { getDayStart, getDayEnd, getFirstDayOfMonth } from '../../../../../../common/utils/time-utils';
import PruTable from '../../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../../common/components/PruTable/PruFilter';
import { ReportListParams, ReportItem } from 'src/app/modules/Report/types/salesMPerformanceTrackingReportTypes';
import {
  fetchCategoryList,
  fetchDataReport,
  fetchReportDownload,
} from 'src/app/modules/Report/network/salesMPerformanceTrackingReportCrud';

type SalesMPerformanceTrackingListingPageProps = {} & ParamsProps;

const initialState: ReportListParams = {
  trackingDateFrom: getFirstDayOfMonth(new Date()),
  trackingDateTo: getDayEnd(new Date()),
  categoryName: '',
  materialName: '',
  module: 'Sales',
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): ReportListParams => {
  return initialParams
    ? {
        trackingDateFrom: initialParams.trackingDateFrom
          ? getDayStart(new Date(initialParams.trackingDateFrom))
          : getFirstDayOfMonth(new Date()),
        trackingDateTo: initialParams.trackingDateTo
          ? getDayEnd(new Date(initialParams.trackingDateTo))
          : getDayEnd(new Date()),
        categoryName: '',
        materialName: '',
        module: 'Sales',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const SalesMPerformanceTrackingListingPage: FC<SalesMPerformanceTrackingListingPageProps> = ({
  initialParams,
  onChangeQueryParams,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<ReportListParams>(paramsInitiator(initialParams));
  const [reportList, setReportList] = useState<PaginateListMore<ReportItem>>();
  const [categoryOptionList, setCategoryOptionList] = useState<{ displayName: string; value: string }[]>();

  useDataProvider(
    async () => {
      try {
        const allCategoryList = await fetchCategoryList(
          {
            filters: {
              module: { $eq: 'Sales' },
            },
            page: 1,
            pageSize: 1000,
            fields: ['name'],
          },
          dispatch,
        );
        let optionList: { displayName: string; value: string }[] = [
          { displayName: Translation('component.status.all'), value: '' },
        ];
        allCategoryList.categoryList.map((item) => {
          return optionList.push({ displayName: item.name, value: item.name });
        });
        setCategoryOptionList(optionList);
        return allCategoryList;
      } catch (err) {}
    },
    () => {},
    false,
  );

  const { isLoading, refreshData } = useDataProvider<PaginateListMore<ReportItem>>(
    async () => {
      try {
        return await fetchDataReport(filterState, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvtDownload = () => {
    try {
      fetchReportDownload(filterState, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('report.sale.salesMPerformanceTracking.filter')}
        itemDef={[
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 250 },
            field: 'categoryName',
            initialValue: filterState.categoryName,
            displayName: Translation('report.sale.salesMPerformanceTracking.categoryName'),
            choices: categoryOptionList
              ? categoryOptionList
              : [{ displayName: Translation('component.status.all'), value: '' }],
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'materialName',
            initialValue: filterState.materialName,
            displayName: Translation('report.sale.salesMPerformanceTracking.materialName'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'trackingDateFrom',
            fieldTo: 'trackingDateTo',
            initialDateFrom: filterState.trackingDateFrom,
            initialDateTo: filterState.trackingDateTo,
            displayName: Translation('report.sale.salesMPerformanceTracking.trackingDate'),
            required: true,
            periodTime: { month: 3 },
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            trackingDateFrom: data.trackingDateFrom,
            trackingDateTo: data.trackingDateTo,
            categoryName: data.categoryName,
            materialName: data.materialName,
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.sale.salesMPerformanceTracking.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvtDownload(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: () => '',
          },
          {
            keyIndex: 'categoryName',
            displayName: Translation('report.sale.salesMPerformanceTracking.categoryName'),
            renderData: (row) => row.materialCategoryName,
          },
          {
            keyIndex: 'materialName',
            displayName: Translation('report.sale.salesMPerformanceTracking.materialName'),
            renderData: (row) => row.materialName,
          },
          {
            keyIndex: 'noOfShare',
            displayName: Translation('report.sale.salesMPerformanceTracking.noOfShare'),
            renderData: (row) => String(row.totalShareUnique),
          },
          {
            keyIndex: 'noOfShareByAgent',
            displayName: Translation('report.sale.salesMPerformanceTracking.noOfShareByAgent'),
            renderData: (row) => String(row.totalShare),
          },
          {
            keyIndex: 'noOfClickByAgent',
            displayName: Translation('report.sale.salesMPerformanceTracking.noOfClickByAgent'),
            renderData: (row) => String(row.totalClickOnMaterial),
          },
          {
            keyIndex: 'noOfViewByAgent',
            displayName: Translation('report.sale.salesMPerformanceTracking.noOfViewByAgent'),
            renderData: (row) => String(row.totalPreview),
          },
          {
            keyIndex: 'noOfClickThrough',
            displayName: Translation('report.sale.salesMPerformanceTracking.noOfClickThrough'),
            renderData: (row) => String(row.totalClickThrough),
          },
          {
            keyIndex: 'noOfRegister',
            displayName: Translation('report.sale.salesMPerformanceTracking.noOfRegister'),
            renderData: (row) => String(row.totalRegister),
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default SalesMPerformanceTrackingListingPage;
