import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LiveStreamCreateForm from './component/LivestreamCreateForm';
import {
  LivestreamFormState,
  LivestreamInfoState,
  FormMode,
  LivestreamItem,
  StatusLivestream,
  SubmitActionEnum,
  EReceiverPushNoti,
  ENotificationType,
  ENotificationCategory,
  formatEReceiverPushNoti,
  EReceiverMessagePushNoti,
  ELinkType,
  StatusOrder,
} from '../types/livestream-type';
import { ETypeOfView } from 'src/app/common/types/view-types';
import { mainLang, subLang, LIVESTREAM_MANAGEMENT_PATH } from '../../../constants';
import {
  createLivesteam,
  findOneLivestream,
  modifyLivestream,
  updateEndLivestream,
  findBroadcastingLivestream,
  createNotiForLivestream,
  closeParticipationsLivestream,
} from '../../network/livestreamCrud';
import { useLang } from 'src/app/i18n';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { useIntl } from 'react-intl';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { LayoutSplashScreen } from 'src/app/layout';

const DEFAULT_LANG_CONFIG: LivestreamInfoState = {
  name: '',
  description: '',
  avatar: null,
  banner: null,
};

const initialState: LivestreamFormState = {
  _id: null,
  userType: ETypeOfView.AllAgents,
  userIncludeSA: false,
  userFile: null,
  userList: null,
  status: StatusLivestream.COMING_SOON,
  releaseAt: null,
  startAt: null,
  endAt: null,
  link: null,
};

const noNameBroadcastingLivestream = 'NoName';

const LivestreamCreatePage: FC<RouteComponentProps> = ({ history, location }) => {
  const locale = useLang();
  const [loading, setLoading] = useState<boolean>(false);
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);

  const [statusModal, setStatusModal] = useState(false);
  const [messageError, setMessageError] = useState('');
  const handleMessageErrorModal = (status: boolean, messageError = '') => {
    if (status) {
      setMessageError(messageError);
    }
    setStatusModal(status);
  };

  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const { id } = useParams<{ id: string }>();
  const { formMode, code } = useMemo(
    () => ({
      formMode: id ? (location.pathname.includes('edit') ? FormMode.EDIT : FormMode.VIEW) : FormMode.CREATE,
      code: id ? id : undefined,
    }),
    [id, location],
  );

  const dispatch = useDispatch();

  const [initData, setInitData] = useState<LivestreamFormState>({
    ...initialState,
  });

  const initLoad = async () => {
    if (code) {
      try {
        setLoading(true);
        const result = await findOneLivestream(code, dispatch);
        if (result && result._id) {
          setInitData(result);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('initLoad', error);
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      }
    }
  };

  const getGeneralValue = (formData: Record<string, any>, submitAction: SubmitActionEnum) => {
    // reduce region property
    let generalData: Record<string, any> = {};

    generalData['id'] = formData['_id'];

    // set status
    if (formMode === FormMode.CREATE || submitAction === SubmitActionEnum.CREATE) {
      generalData.status = StatusLivestream.COMING_SOON;
      generalData.statusOrder = StatusOrder.COMING_SOON;
    } else if (submitAction === SubmitActionEnum.START) {
      generalData.status = StatusLivestream.BROADCASTING;
      generalData.statusOrder = StatusOrder.BROADCASTING;
      generalData.startAt = new Date();
    }

    return generalData;
  };

  const getBroadcastingLivestream = async () => {
    try {
      const result = await findBroadcastingLivestream(dispatch);
      if (result && result.totalRecords > 0) {
        return result.items && result.items.length > 0 && result.items[0] && result.items[0].name
          ? result.items[0].name
          : noNameBroadcastingLivestream;
      }
      return null;
    } catch (error) {
      console.log('getBroadcastingLivestream', error);
    }
  };

  const submitForm = async (submitAction: SubmitActionEnum, formData: LivestreamFormState) => {
    try {
      let formDataCustom: Record<string, any> = formData;
      setIsCallingApi(true);
      if (
        submitAction === SubmitActionEnum.CREATE ||
        submitAction === SubmitActionEnum.UPDATE ||
        submitAction === SubmitActionEnum.START
      ) {
        if (submitAction === SubmitActionEnum.UPDATE && initData.status !== StatusLivestream.COMING_SOON) {
          return history.push(LIVESTREAM_MANAGEMENT_PATH);
        }

        // handle catch error START
        if (formData._id && submitAction === SubmitActionEnum.START) {
          const nameBroadcastingLivestream = await getBroadcastingLivestream();

          if (nameBroadcastingLivestream) {
            setIsCallingApi(false);
            return handleMessageErrorModal(
              true,
              'There is another live stream currently broadcasting, this one can not be broadcast.',
            );
          } else {
            if (!formDataCustom.link || String(formDataCustom.link).trim() === '') {
              setIsCallingApi(false);
              return handleMessageErrorModal(true, 'There is no link, can not start live stream.');
            }
          }
        }

        const generalData = getGeneralValue(formDataCustom, submitAction) as any;

        // save original region
        let resultOriginal = formData._id
          ? await modifyLivestream(formData._id, { ...formDataCustom, ...generalData }, dispatch)
          : ((await createLivesteam({ ...generalData, ...formDataCustom, isHidden: false }, dispatch)) as any);

        if (!resultOriginal) {
          throw 'System error!!!';
        }

        // send noti
        if (submitAction === SubmitActionEnum.START) {
          const type = formatEReceiverPushNoti(resultOriginal.userType);
          let includes: any = {};
          includes.isSA = resultOriginal.userIncludeSA || false;

          if (resultOriginal.userType === EReceiverPushNoti.allAgents) {
            includes.isPruventure = true;
          }

          const messageData = {
            receiverType: type ? type : '',
            agentCodeList:
              type === EReceiverMessagePushNoti.groupOfUsers && resultOriginal.userList ? resultOriginal.userList : [],

            category: ENotificationCategory.Event,
            msgTitle: 'Livestream',
            msgContent: resultOriginal?.name,
            pushTitle: 'Livestream',
            pushContent: resultOriginal?.name,

            notificationType: ENotificationType.Push_Inbox,
            includes: includes,
            extendValue: {
              link: ELinkType.Notification,
              extendParams: {
                type: 'LIVESTREAM_RELEASE',
                id: resultOriginal?._id,
                name: resultOriginal?.name,
                banner: resultOriginal?.banner?.url,
              },
            },
          };
          createNotiForLivestream(messageData, dispatch);
        }
      } else if (submitAction === SubmitActionEnum.END) {
        if (formData._id) {
          await updateEndLivestream(formData._id, StatusLivestream.BROADCASTED);
          await closeParticipationsLivestream(formData._id);
        } else {
          throw 'Not found livestream id';
        }
      }

      submitSuccess();
    } catch (error) {
      console.log(error);
      setIsCallingApi(false);
      submitFail();
    }
  };

  const submitSuccess = async () => {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: Translation('global.submit.success'),
          content: Translation('global.submit.success'),
        },
      ]),
    );

    history.push(LIVESTREAM_MANAGEMENT_PATH);
    setLoading(false);
  };

  const submitFail = () => {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.ERROR,
          title: Translation('global.submit.fail'),
          content: Translation('global.submit.fail'),
        },
      ]),
    );
    setLoading(false);
  };

  useEffect(() => {
    initLoad();
  }, [code]);

  return (
    <>
      {(formMode === FormMode.EDIT || formMode === FormMode.VIEW) && loading ? (
        <LayoutSplashScreen />
      ) : (
        <LiveStreamCreateForm
          initialValues={{ ...initData }}
          onSubmit={submitForm}
          disabled={!!(formMode === FormMode.VIEW || initData.status !== StatusLivestream.COMING_SOON)}
          history={history}
          formMode={formMode}
          isCallingApi={isCallingApi}
        />
      )}
      <Dialog
        open={statusModal}
        onClose={() => handleMessageErrorModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Message'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ color: 'red' }}>
            {messageError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleMessageErrorModal(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LivestreamCreatePage;
