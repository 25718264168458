import { I18nGenericData } from 'src/app/i18n';
import { AttachmentDef } from 'src/app/common/types';
import { PublishStatusEnum, IncentiveListItem, IncentiveSetTypeEnum } from '../../../../types/incentive-types';

export type IncentiveSetFormState = {
  code?: string;
  name: I18nGenericData<string>;
  incentiveSetType: IncentiveSetTypeEnum;
  status: PublishStatusEnum;
  subIncentiveItems: IncentiveListItem[];
  detail: I18nGenericData<string>;
  award: I18nGenericData<string>;
  incentiveImage: I18nGenericData<AttachmentDef>;
  stickOnTop: boolean;
  // read-only
  startDate: Date | null;
  endDate: Date | null;
  publishDate: Date | null;
  archiveDate: Date | null;
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof IncentiveSetFormState;
    value: any;
  };
};

type MoveSubIncentiveRowToTop = {
  type: 'MOVE_SUB_INCENTIVE_ROW_TO_TOP';
  payload: {
    index: number;
  };
};

type DeleteSubIncentiveRow = {
  type: 'DELETE_SUB_INCENTIVE_ROW';
  payload: {
    index: number;
  };
};

type FillInDetailAction = {
  type: 'FILL_IN_DETAIL';
  payload: {
    fromLocale: string;
    toLocale: string;
  };
};

type IncentiveSetFormAction = ModifyFieldAction | MoveSubIncentiveRowToTop | DeleteSubIncentiveRow | FillInDetailAction;

const getMinField = (arr: IncentiveListItem[], field: keyof IncentiveListItem) =>
  arr.length > 0
    ? arr.reduce((acc, current) => {
        const accField = acc[field];
        const currentField = current[field];
        if (accField && currentField) {
          return accField < currentField ? acc : current;
        } else if (accField) {
          return acc;
        } else {
          return current;
        }
      })[field]
    : undefined;

const getMaxField = (arr: IncentiveListItem[], field: keyof IncentiveListItem) =>
  arr.length > 0
    ? arr.reduce((acc, current) => {
        const accField = acc[field];
        const currentField = current[field];
        if (accField && currentField) {
          return accField > currentField ? acc : current;
        } else if (accField) {
          return acc;
        } else {
          return current;
        }
      })[field]
    : undefined;

export const incentiveSetFormReducer = (
  state: IncentiveSetFormState,
  action: IncentiveSetFormAction,
): IncentiveSetFormState => {
  const newSubIncentiveItems = [...state.subIncentiveItems];
  switch (action.type) {
    case 'MODIFY_FIELD':
      if (action.payload.field === 'subIncentiveItems') {
        return {
          ...state,
          [action.payload.field]: action.payload.value,
          startDate: (getMinField(action.payload.value, 'startDate') as Date) || null,
          endDate: (getMaxField(action.payload.value, 'endDate') as Date) || null,
          publishDate: (getMinField(action.payload.value, 'publishDate') as Date) || null,
          archiveDate: (getMaxField(action.payload.value, 'archiveDate') as Date) || null,
        };
      }
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'MOVE_SUB_INCENTIVE_ROW_TO_TOP':
      newSubIncentiveItems.unshift(newSubIncentiveItems.splice(action.payload.index, 1)[0]);
      return {
        ...state,
        subIncentiveItems: newSubIncentiveItems,
      };
    case 'DELETE_SUB_INCENTIVE_ROW':
      newSubIncentiveItems.splice(action.payload.index, 1);
      return {
        ...state,
        subIncentiveItems: newSubIncentiveItems,
        startDate: (getMinField(newSubIncentiveItems, 'startDate') as Date) || null,
        endDate: (getMaxField(newSubIncentiveItems, 'endDate') as Date) || null,
        publishDate: (getMinField(newSubIncentiveItems, 'publishDate') as Date) || null,
        archiveDate: (getMaxField(newSubIncentiveItems, 'archiveDate') as Date) || null,
      };
    case 'FILL_IN_DETAIL':
      const fromLocale = action.payload.fromLocale;
      const toLocale = action.payload.toLocale;
      return {
        ...state,
        detail: {
          ...state.detail,
          [toLocale]: state.detail[fromLocale],
        },
        award: {
          ...state.award,
          [toLocale]: state.award[fromLocale],
        },
        incentiveImage: {
          ...state.incentiveImage,
          [toLocale]: state.incentiveImage[fromLocale],
        },
      };
    default:
      return state;
  }
};
