import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import moment from 'moment';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { FormMode } from 'src/app/common/types';
import { useLang } from 'src/app/i18n';
import { LayoutSplashScreen } from 'src/app/layout';
import { SubmitActionEnum } from 'src/app/modules/Livestream/pages/Livestream-management/types/livestream-type';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { CUSTOMER_CAMPAIGN_BASE_PATH } from '../../constants';
import { EPublishStatus, EReceiverTypeAgent, EReceiverTypeCustomer } from '../../enum/customer-campaign.enum';
import {
  createCustomerCampaign,
  editCustomerCampaignById,
  getCustomerCampaignById,
} from '../../network/customerCampaignCrud';
import { CustomerCampaignFormState } from '../../types/customer-campaign-types';
import CustomerCampaignCreateForm from './components/CustomerCampaignCreateForm';

const initialState: CustomerCampaignFormState = {
  campaignName: '',
  startDate: moment().toDate(),
  endDate: moment().toDate(),
  bannerImg: null,
  introduction: '',
  additionalInformation: '',
  tabs: [
    {
      tabTitle: '',
      tabDescription: '',
    },
  ],
  referralForm: {
    isName: true,
    isPhone: true,
    isProvinceCity: false,
    isDob: false,
    isEmail: false,
  },
  consent: '',
  tnc: null,
  receiverTypeAgent: {
    isAllAgents: false,
    isGroupOfUsers: false,
    isPruventure: false,
    userFile: null,
    userList: [],
  },
  receiverTypeCustomer: {
    // allCustomer: false,
    // targetsCustomer: false,
    // excludeCustomer: false,
    type: '',
    userFile: null,
    userList: [],
  },
  smsDuration: 1,
  isAllowSharingWhenCompleted: true,
};

const CustomerCampaignCreatePage: FC<RouteComponentProps> = ({ history, location }) => {
  const locale = useLang();
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const [statusModal, setStatusModal] = useState(false);
  const [messageError, setMessageError] = useState('');
  const handleMessageErrorModal = (status: boolean, messageError = '') => {
    if (status) {
      setMessageError(messageError);
    }
    setStatusModal(status);
  };

  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const { id } = useParams<{ id: string }>();
  const { formMode, code } = useMemo(
    () => ({
      formMode: id ? (location.pathname.includes('edit') ? FormMode.EDIT : FormMode.VIEW) : FormMode.CREATE,
      code: id ? id : undefined,
    }),
    [id, location],
  );

  const dispatch = useDispatch();

  const [initData, setInitData] = useState<CustomerCampaignFormState>({
    ...initialState,
  });

  const initLoad = async () => {
    if (code) {
      try {
        setFormLoading(true);
        const result = await getCustomerCampaignById(code, dispatch);
        if (result && result._id) {
          setInitData(result);
        }
        setFormLoading(false);
      } catch (error) {
        setFormLoading(true);
        console.log('initLoad', error);
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
      }
    }
  };

  const submitForm = async (body: any, status: EPublishStatus) => {
    console.log(body, 'body1111111111111111');
    try {
      setFormLoading(true);
      body._id
        ? await editCustomerCampaignById(body._id, body)
        : await createCustomerCampaign(
            {
              ...body,
              status: status,
              startDate: moment(body.startDate).startOf('day'),
              endDate: moment(body.endDate).endOf('day'),
            },
            dispatch,
          );

      alertSuccess();
      history.push(`${CUSTOMER_CAMPAIGN_BASE_PATH}`);
    } catch (error) {
      alertFail();
      console.log('submitForm', error);
    }
  };

  const alertSuccess = async () => {
    setFormLoading(false);
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: Translation('global.submit.success'),
          content: Translation('global.submit.success'),
        },
      ]),
    );
    // history.push(CUSTOMER_CAMPAIGN_BASE_PATH);
  };

  const alertFail = () => {
    setFormLoading(false);
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.ERROR,
          title: Translation('global.submit.fail'),
          content: Translation('global.submit.fail'),
        },
      ]),
    );
  };

  useEffect(() => {
    initLoad();
  }, [code]);

  return (
    <>
      {(formMode === FormMode.EDIT || formMode === FormMode.VIEW) && formLoading ? (
        <LayoutSplashScreen />
      ) : (
        <CustomerCampaignCreateForm
          initialValues={{ ...initData }}
          onSubmit={submitForm}
          history={history}
          formMode={formMode}
          formLoading={formLoading}
          disabled={!!(formMode === FormMode.VIEW)}
        />
      )}
    </>
  );
};

export default CustomerCampaignCreatePage;
