import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  PULSELEADS_TITLE,
  PULSELEADS_BASE_PATH,
  PULSELEADS_LEAD_CAMPAIGN_TITLE,
  PULSELEADS_RULE_PATH,
  PULSELEADS_RULE_TITLE,
  PULSELEADS_LEAD_CAMPAIGN_PATH,
  PULSELEADS_LEAD_CAMPAIGN_NAMESPACE,
  AdminLeadsPermissionType,
  PULSELEADS_CUSTOMER_DEDUPLICATION_RULE_TITLE,
  PULSELEADS_CUSTOMER_DEDUPLICATION_RULE_PATH,
  PULSELEADS_CUSTOMER_DEDUPLICATION_RULE_NAMESPACE,
  PULSELEADS_DISTRIBUTION_RULE_TITLE,
  PULSELEADS_DISTRIBUTION_RULE_PATH,
  PULSELEADS_DISTRIBUTION_RULE_NAMESPACE,
  PULSELEADS_LEAD_TITLE,
  PULSELEADS_LEAD_PATH,
  PULSELEADS_LEAD_SOURCE_TITLE,
  PULSELEADS_LEAD_SOURCE_PATH,
  PULSELEADS_LEAD_SOURCE_NAMESPACE,
  PULSELEADS_SLA_TITLE,
  PULSELEADS_SLA_PATH,
  PULSELEADS_ACCEPT_SLA_PATH,
  PULSELEADS_SLA_NAMESPACE,
  PULSELEADS_ACCEPT_SLA_TITLE,
  PULSELEADS_CONTACT_SLA_TITLE,
  PULSELEADS_CONTACT_SLA_PATH,
  PULSELEADS_MEET_SLA_TITLE,
  PULSELEADS_MEET_SLA_PATH,
  PULSELEADS_PROPOSAL_SLA_TITLE,
  PULSELEADS_PROPOSAL_SLA_PATH,
  PULSELEADS_APPLICATION_SLA_TITLE,
  PULSELEADS_APPLICATION_SLA_PATH,
  PULSELEADS_LEAD_DEDUPLICATION_RULE_PATH,
  PULSELEADS_LEAD_DEDUPLICATION_RULE_NAMESPACE,
  PULSELEADS_LEAD_DEDUPLICATION_RULE_TITLE,
  PULSELEADS_ELIGIBILITY_RULE_TITLE,
  PULSELEADS_ELIGIBILITY_RULE_PATH,
  PULSELEADS_ELIGIBILITY_RULE_NAMESPACE,
  PULSELEADS_EXCLUSION_RULE_TITLE,
  PULSELEADS_EXCLUSION_RULE_NAMESPACE,
  PULSELEADS_EXCLUSION_RULE_PATH,
} from './constants';
import {
  PULSELEADS_RULE_PROSPECT_MASTER_MATCHING_TITLE,
  PULSELEADS_MASTER_MATCHING_RULE_PATH,
  PULSELEADS_PROSPECT_MASTER_MATCHING_RULE_NAMESPACE,
} from './pages/Rule/ProspectMasterMatching/constants';
import {
  PULSELEADS_LEAD_PROSPECT_PATH,
  PULSELEADS_LEAD_PROSPECT_CATEGORY_PATH,
  PULSELEADS_PROSPECT_CATEGORY_NAMESPACE,
  PULSELEADS_PROSPECT_TITLE,
  PULSELEADS_PROSPECT_CATEGORY_TITLE,
} from './pages/Prospect/constants';
import {
  PULSELEADS_BULKUPLOAD_TITLE,
  PULSELEADS_BULKUPLOAD_UPLOAD_TITLE,
  PULSELEADS_BULKUPLOAD_STATUS_TITLE,
  PULSELEADS_BULKUPLOAD_UPLOAD_NAMESPACE,
  PULSELEADS_BULKUPLOAD_STATUS_NAMESPACE,
  PULSELEADS_BULKUPLOAD_PATH,
  PULSELEADS_BULKUPLOAD_UPLOAD_PATH,
  PULSELEADS_BULKUPLOAD_STATUS_PATH,
  PULSELEADS_BULKUPLOAD_STATUS_BULK_UPLOAD_TITLE,
  PULSELEADS_BULKUPLOAD_STATUS_IMPORT_AGENT_TITLE,
  PULSELEADS_BULKUPLOAD_STATUS_PATH_IMPORT_AGENT_LIST,
  PULSELEADS_BULKUPLOAD_STATUS_PATH_STATUS_LIST,
} from './pages/BulkUpload/constants';
import {
  PULSELEADS_LEADS_NOT_DISTRIBUTED_TITLE,
  PULSELEADS_LEADS_NOT_DISTRIBUTED_NAMESPACE,
  PULSELEADS_LEADS_PATH,
  PULSELEADS_LEADS_NOT_DISTRIBUTED_PATH,
} from './pages/LeadNotDistribution/constants';
import {
  PULSELEADS_AGENT_TITLE,
  PULSELEADS_AGENT_LEAD_SOURCE_TITLE,
  PULSELEADS_AGENT_REASSIGN_LEAD_TITLE,
  PULSELEADS_AGENT_REDISTRIBUTE_LEAD_TITLE,
  PULSELEADS_AGENT_LEAD_SOURCE_NAMESPACE,
  PULSELEADS_AGENT_REASSIGN_LEAD_NAMESPACE,
  PULSELEADS_AGENT_REDISTRIBUTE_LEAD_NAMESPACE,
  PULSELEADS_AGENT_PATH,
  PULSELEADS_AGENT_LEAD_SOURCE_PATH,
  PULSELEADS_AGENT_REASSIGN_LEAD_PATH,
  PULSELEADS_AGENT_REDISTRIBUTE_LEAD_PATH,
  PULSELEADS_AGENT_AUTO_DROP_LEAD_TITLE,
  PULSELEADS_AGENT_AUTO_DROP_LEAD_PATH,
  PULSELEADS_AGENT_AUTO_DROP_LEAD_NAMESPACE,
} from './pages/Agent/constants';
import { PULSELEADS_CONFIGURATION_TITLE, PULSELEADS_CONFIGURATION_PATH } from './pages/Configuration/constants';
import {
  PULSELEADS_LEAD_FOLLOW_UP_TITLE,
  PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_TITLE,
  PULSELEADS_LEAD_FOLLOW_UP_PATH,
  PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_PATH,
  PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_NAMESPACE,
} from './pages/Lead/FollowUp/constants';
import {
  PULSELEADS_STRAIGHT_THROUGH_TITLE,
  PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_TITLE,
  PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_TITLE,
  PULSELEADS_STRAIGHT_THROUGH_CHANNEL_CAMPAIGN_TITLE,
  PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_TITLE,
  PULSELEADS_STRAIGHT_THROUGH_PATH,
  PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_PATH,
  PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_PATH,
  PULSELEADS_STRAIGHT_THROUGH_CHANNEL_CAMPAIGN_PATH,
  PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_PATH,
  PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_NAMESPACE,
  PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_NAMESPACE,
  PULSELEADS_STRAIGHT_THROUGH_CHANNEL_CAMPAIGN_NAMESPACE,
  PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_NAMESPACE,
} from './pages/StraightThrough/constants';
import {
  PULSELEADS_CAMPAIGN_FEEDBACK_TITLE,
  PULSELEADS_CAMPAIGN_FEEDBACK_PATH,
  PULSELEADS_CAMPAIGN_FEEDBACK_NAMESPACE,
} from './pages/CampaignFeedback/constants';
import CampaignRoutes from './pages/Lead/Campaign/CampaignRoutes';
import CustomerDeduplicationRuleRoutes from './pages/Rule/Rule/CustomerDeduplicationRuleRoutes';
import DistributionRuleRoutes from './pages/Rule/Distribution/DistributionRuleRoutes';
import LeadSourceRoutes from './pages/Lead/Source/LeadSourceRoutes';
import SLARoutes from './pages/SLA/SLARoutes';
import LeadDeduplicationRuleRoutes from './pages/Rule/Deduplication/LeadDeduplicationRuleRoutes';
import EligibilityRuleRoutes from './pages/Rule/Eligibility/EligibilityRuleRoutes';
import ExclusionRuleRoutes from './pages/Rule/Exclusion/ExclusionRuleRoutes';

import ProspectMasterMatchingPage from './pages/Rule/ProspectMasterMatching/index';
import ProspectCategoryRoutes from './pages/Prospect/ProspectCategoryRoutes';
import LeadNotDistributionPage from './pages/LeadNotDistribution/Routes';

import EventCampaignRoutes from './pages/StraightThrough/EventCampaign/EventCampaignRoutes';
import ProductCampaignRoutes from './pages/StraightThrough/ProductCampaign/ProductCampaignRoutes';
import ChannelCampaignRoutes from './pages/StraightThrough/ChannelCampaign/ChannelCampaignRoutes';
import StagingLeadRoutes from './pages/StraightThrough/StagingLead/StagingLeadRoutes';
import BulkUploadUploadRoutes from './pages/BulkUpload/Upload/BulkUploadUploadRoutes';
import BulkUploadStatusRoutes from './pages/BulkUpload/StatusList/BulkUploadStatusRoutes';

import AgentLeadSourceRoutes from './pages/Agent/LeadSource/AgentLeadSourceRoutes';
import ReassignLeadRoutes from './pages/Agent/ReassignLead/ReassignLeadRoutes';
import RedistributeLeadRoutes from './pages/Agent/RedistributeLead/RedistributeLeadRoutes';
import ManualUpdateRoutes from './pages/Lead/FollowUp/ManualUpdate/ManualUpdateRoutes';
import * as EngagementConstants from './pages/Engagement/constants';
import EngagementFieldsRoutes from './pages/Engagement/EngagementFiledsRoutes';
import EngagementTopicRoutes from './pages/Engagement/EngagementTopic/Routes';
import EngagementTaskRoutes from './pages/Engagement/EngagementTask/Routes';
import { getConfigurations, isPermitted } from 'src/app/common/utils';
import CampaignFeedbackRoutes from './pages/CampaignFeedback/CampaignFeedbackRoutes';
import {
  PULSELEADS_POINT_RULE_LEAD_GOAL_PATH,
  PULSELEADS_POINT_RULE_LEAD_GOAL_TITLE,
  PULSELEADS_POINT_RULE_NAME_NAMESPACE,
  PULSELEADS_POINT_RULE_NAME_PATH,
  PULSELEADS_POINT_RULE_NAME_TITLE,
  PULSELEADS_POINT_RULE_PATH,
  PULSELEADS_POINT_RULE_SETTINGS_NAMESPACE,
  PULSELEADS_POINT_RULE_SETTINGS_PATH,
  PULSELEADS_POINT_RULE_SETTINGS_TITLE,
  PULSELEADS_POINT_TITLE,
} from './pages/Rule/ActivityPoint/constants';
import PointRuleSettingsRoutes from './pages/Rule/ActivityPoint/PointRuleSettings/Routes';
import RuleNameRoutes from './pages/Rule/ActivityPoint/RuleName/Routes';
import AgentAutoDropLeadRoutes from './pages/Agent/auto-drop-rule/auto-drop-routes';
import MarketingLeadLimitPage from './pages/Rule/marketing-lead-limit';
import {
  PULSELEADS_MARKETING_LEAD_LIMIT_RULE_TITLE,
  PULSELEADS_MARKETING_LEAD_LIMIT_RULE_PATH,
  PULSELEADS_MARKETING_LEAD_LIMIT_RULE_NAMESPACE,
} from './pages/Rule/marketing-lead-limit/constants';
import {
  PULSELEADS_LEAD_REASSIGNMENT_PATH,
  PULSELEADS_LEAD_REASSIGNMENT_STATUS_NAMESPACE,
  PULSELEADS_LEAD_REASSIGNMENT_STATUS_PATH,
  PULSELEADS_LEAD_REASSIGNMENT_STATUS_TITLE,
  PULSELEADS_LEAD_REASSIGNMENT_TITLE,
  PULSELEADS_LEAD_REASSIGNMENT_UPLOAD_NAMESPACE,
  PULSELEADS_LEAD_REASSIGNMENT_UPLOAD_PATH,
  PULSELEADS_LEAD_REASSIGNMENT_UPLOAD_TITLE,
} from './pages/LeadReassignment/constants';
import LeadReassignmentUploadRoutes from './pages/LeadReassignment/Upload/LeadReassignmentUploadRoutes';
import LeadReassignmentStatusRoutes from './pages/LeadReassignment/StatusList/LeadReassignmentStatusRoutes';
import LeadGoalRoutes from './pages/Rule/ActivityPoint/lead-goal/lead-goal-routes';

export const pulseLeadsModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });

  return {
    title: Translation(PULSELEADS_TITLE),
    path: PULSELEADS_BASE_PATH,
    icon: '/media/svg/icons/Devices/Diagnostics.svg',
    enableRead: isPermitted(Object.values(AdminLeadsPermissionType), permissions),
    child: [
      {
        title: Translation(PULSELEADS_LEAD_TITLE),
        path: `${PULSELEADS_BASE_PATH}${PULSELEADS_LEAD_PATH}`,
        enableRead: isPermitted(
          [
            AdminLeadsPermissionType.PULSELEADS_LEAD_CAMPAIGN_READ,
            AdminLeadsPermissionType.PULSELEADS_LEAD_CAMPAIGN_CREATE,
            AdminLeadsPermissionType.PULSELEADS_LEAD_CAMPAIGN_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_LEAD_SOURCE_READ,
            AdminLeadsPermissionType.PULSELEADS_LEAD_SOURCE_CREATE,
            AdminLeadsPermissionType.PULSELEADS_LEAD_SOURCE_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_READ,
            AdminLeadsPermissionType.PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_CREATE,
            AdminLeadsPermissionType.PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_UPDATE,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(PULSELEADS_LEAD_CAMPAIGN_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_LEAD_PATH}${PULSELEADS_LEAD_CAMPAIGN_PATH}`,
            namespace: PULSELEADS_LEAD_CAMPAIGN_NAMESPACE,
            component: CampaignRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_LEAD_CAMPAIGN_READ,
                AdminLeadsPermissionType.PULSELEADS_LEAD_CAMPAIGN_CREATE,
                AdminLeadsPermissionType.PULSELEADS_LEAD_CAMPAIGN_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_LEAD_CAMPAIGN_CREATE], permissions),
            enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_LEAD_CAMPAIGN_UPDATE], permissions),
          },
          {
            title: Translation(PULSELEADS_LEAD_SOURCE_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_LEAD_PATH}${PULSELEADS_LEAD_SOURCE_PATH}`,
            namespace: PULSELEADS_LEAD_SOURCE_NAMESPACE,
            component: LeadSourceRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_LEAD_SOURCE_READ,
                AdminLeadsPermissionType.PULSELEADS_LEAD_SOURCE_CREATE,
                AdminLeadsPermissionType.PULSELEADS_LEAD_SOURCE_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_LEAD_SOURCE_CREATE], permissions),
            enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_LEAD_SOURCE_UPDATE], permissions),
          },
          {
            title: Translation(PULSELEADS_LEAD_FOLLOW_UP_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_LEAD_PATH}${PULSELEADS_LEAD_FOLLOW_UP_PATH}`,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_READ,
                AdminLeadsPermissionType.PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_CREATE,
                AdminLeadsPermissionType.PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_UPDATE,
              ],
              permissions,
            ),
            child: [
              {
                title: Translation(PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_TITLE),
                path: `${PULSELEADS_BASE_PATH}${PULSELEADS_LEAD_PATH}${PULSELEADS_LEAD_FOLLOW_UP_PATH}${PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_PATH}`,
                namespace: PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_NAMESPACE,
                component: ManualUpdateRoutes,
                enableRead: isPermitted(
                  [
                    AdminLeadsPermissionType.PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_READ,
                    AdminLeadsPermissionType.PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_CREATE,
                    AdminLeadsPermissionType.PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_UPDATE,
                  ],
                  permissions,
                ),
                enableCreate: isPermitted(
                  [AdminLeadsPermissionType.PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_CREATE],
                  permissions,
                ),
                enableUpdate: isPermitted(
                  [AdminLeadsPermissionType.PULSELEADS_LEAD_FOLLOW_UP_MANUAL_UPDATE_UPDATE],
                  permissions,
                ),
              },
            ],
          },
        ],
      },
      {
        title: Translation(PULSELEADS_SLA_TITLE),
        path: `${PULSELEADS_BASE_PATH}${PULSELEADS_SLA_PATH}`,
        enableRead: isPermitted(
          [
            AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_READ,
            AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_CREATE,
            AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_UPDATE,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(PULSELEADS_ACCEPT_SLA_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_SLA_PATH}${PULSELEADS_ACCEPT_SLA_PATH}`,
            namespace: PULSELEADS_SLA_NAMESPACE,
            component: SLARoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_READ,
                AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_CREATE,
                AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_CREATE], permissions),
            enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_UPDATE], permissions),
          },
          {
            title: Translation(PULSELEADS_CONTACT_SLA_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_SLA_PATH}${PULSELEADS_CONTACT_SLA_PATH}`,
            namespace: PULSELEADS_SLA_NAMESPACE,
            component: SLARoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_READ,
                AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_CREATE,
                AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_CREATE], permissions),
            enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_UPDATE], permissions),
          },
          {
            title: Translation(PULSELEADS_MEET_SLA_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_SLA_PATH}${PULSELEADS_MEET_SLA_PATH}`,
            namespace: PULSELEADS_SLA_NAMESPACE,
            component: SLARoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_READ,
                AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_CREATE,
                AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_CREATE], permissions),
            enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_UPDATE], permissions),
          },
          {
            title: Translation(PULSELEADS_PROPOSAL_SLA_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_SLA_PATH}${PULSELEADS_PROPOSAL_SLA_PATH}`,
            namespace: PULSELEADS_SLA_NAMESPACE,
            component: SLARoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_READ,
                AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_CREATE,
                AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_CREATE], permissions),
            enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_UPDATE], permissions),
          },
          {
            title: Translation(PULSELEADS_APPLICATION_SLA_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_SLA_PATH}${PULSELEADS_APPLICATION_SLA_PATH}`,
            namespace: PULSELEADS_SLA_NAMESPACE,
            component: SLARoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_READ,
                AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_CREATE,
                AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_CREATE], permissions),
            enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_SLA_CONFIG_UPDATE], permissions),
          },
        ],
      },
      {
        title: Translation(PULSELEADS_RULE_TITLE),
        path: `${PULSELEADS_BASE_PATH}${PULSELEADS_RULE_PATH}`,
        enableRead: isPermitted(
          [
            AdminLeadsPermissionType.PULSELEADS_LEAD_DEDUPLICATION_RULE_READ,
            AdminLeadsPermissionType.PULSELEADS_LEAD_DEDUPLICATION_RULE_CREATE,
            AdminLeadsPermissionType.PULSELEADS_LEAD_DEDUPLICATION_RULE_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_CUSTOMER_DEDUPLICATION_RULE_READ,
            AdminLeadsPermissionType.PULSELEADS_CUSTOMER_DEDUPLICATION_RULE_CREATE,
            AdminLeadsPermissionType.PULSELEADS_CUSTOMER_DEDUPLICATION_RULE_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_DISTRIBUTION_RULE_READ,
            AdminLeadsPermissionType.PULSELEADS_DISTRIBUTION_RULE_CREATE,
            AdminLeadsPermissionType.PULSELEADS_DISTRIBUTION_RULE_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_ELIGIBILITY_RULE_READ,
            AdminLeadsPermissionType.PULSELEADS_ELIGIBILITY_RULE_CREATE,
            AdminLeadsPermissionType.PULSELEADS_ELIGIBILITY_RULE_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_EXCLUSION_RULE_READ,
            AdminLeadsPermissionType.PULSELEADS_EXCLUSION_RULE_CREATE,
            AdminLeadsPermissionType.PULSELEADS_EXCLUSION_RULE_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_PROSPECT_MASTER_MATCHING_RULE_READ,
            AdminLeadsPermissionType.PULSELEADS_PROSPECT_MASTER_MATCHING_RULE_CREATE,
            AdminLeadsPermissionType.PULSELEADS_PROSPECT_MASTER_MATCHING_RULE_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_POINT_RULE_NAME_CREATE,
            AdminLeadsPermissionType.PULSELEADS_POINT_RULE_NAME_READ,
            AdminLeadsPermissionType.PULSELEADS_POINT_RULE_NAME_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_POINT_RULE_SETTINGS_CREATE,
            AdminLeadsPermissionType.PULSELEADS_POINT_RULE_SETTINGS_READ,
            AdminLeadsPermissionType.PULSELEADS_POINT_RULE_SETTINGS_UPDATE,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(PULSELEADS_LEAD_DEDUPLICATION_RULE_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_RULE_PATH}${PULSELEADS_LEAD_DEDUPLICATION_RULE_PATH}`,
            namespace: PULSELEADS_LEAD_DEDUPLICATION_RULE_NAMESPACE,
            component: LeadDeduplicationRuleRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_LEAD_DEDUPLICATION_RULE_READ,
                AdminLeadsPermissionType.PULSELEADS_LEAD_DEDUPLICATION_RULE_CREATE,
                AdminLeadsPermissionType.PULSELEADS_LEAD_DEDUPLICATION_RULE_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_LEAD_DEDUPLICATION_RULE_CREATE],
              permissions,
            ),
            enableUpdate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_LEAD_DEDUPLICATION_RULE_UPDATE],
              permissions,
            ),
          },
          {
            title: Translation(PULSELEADS_CUSTOMER_DEDUPLICATION_RULE_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_RULE_PATH}${PULSELEADS_CUSTOMER_DEDUPLICATION_RULE_PATH}`,
            namespace: PULSELEADS_CUSTOMER_DEDUPLICATION_RULE_NAMESPACE,
            component: CustomerDeduplicationRuleRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_CUSTOMER_DEDUPLICATION_RULE_READ,
                AdminLeadsPermissionType.PULSELEADS_CUSTOMER_DEDUPLICATION_RULE_CREATE,
                AdminLeadsPermissionType.PULSELEADS_CUSTOMER_DEDUPLICATION_RULE_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_CUSTOMER_DEDUPLICATION_RULE_CREATE],
              permissions,
            ),
            enableUpdate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_CUSTOMER_DEDUPLICATION_RULE_UPDATE],
              permissions,
            ),
          },
          {
            title: Translation(PULSELEADS_DISTRIBUTION_RULE_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_RULE_PATH}${PULSELEADS_DISTRIBUTION_RULE_PATH}`,
            namespace: PULSELEADS_DISTRIBUTION_RULE_NAMESPACE,
            component: DistributionRuleRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_DISTRIBUTION_RULE_READ,
                AdminLeadsPermissionType.PULSELEADS_DISTRIBUTION_RULE_CREATE,
                AdminLeadsPermissionType.PULSELEADS_DISTRIBUTION_RULE_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_DISTRIBUTION_RULE_CREATE], permissions),
            enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_DISTRIBUTION_RULE_UPDATE], permissions),
          },
          {
            title: Translation(PULSELEADS_ELIGIBILITY_RULE_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_RULE_PATH}${PULSELEADS_ELIGIBILITY_RULE_PATH}`,
            namespace: PULSELEADS_ELIGIBILITY_RULE_NAMESPACE,
            component: EligibilityRuleRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_ELIGIBILITY_RULE_READ,
                AdminLeadsPermissionType.PULSELEADS_ELIGIBILITY_RULE_CREATE,
                AdminLeadsPermissionType.PULSELEADS_ELIGIBILITY_RULE_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_ELIGIBILITY_RULE_CREATE], permissions),
            enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_ELIGIBILITY_RULE_UPDATE], permissions),
          },
          {
            title: Translation(PULSELEADS_EXCLUSION_RULE_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_RULE_PATH}${PULSELEADS_EXCLUSION_RULE_PATH}`,
            namespace: PULSELEADS_EXCLUSION_RULE_NAMESPACE,
            component: ExclusionRuleRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_EXCLUSION_RULE_READ,
                AdminLeadsPermissionType.PULSELEADS_EXCLUSION_RULE_CREATE,
                AdminLeadsPermissionType.PULSELEADS_EXCLUSION_RULE_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_EXCLUSION_RULE_CREATE], permissions),
            enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_EXCLUSION_RULE_UPDATE], permissions),
          },
          {
            title: Translation(PULSELEADS_RULE_PROSPECT_MASTER_MATCHING_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_RULE_PATH}${PULSELEADS_MASTER_MATCHING_RULE_PATH}`,
            namespace: PULSELEADS_PROSPECT_MASTER_MATCHING_RULE_NAMESPACE,
            component: ProspectMasterMatchingPage,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_PROSPECT_MASTER_MATCHING_RULE_READ,
                AdminLeadsPermissionType.PULSELEADS_PROSPECT_MASTER_MATCHING_RULE_CREATE,
                AdminLeadsPermissionType.PULSELEADS_PROSPECT_MASTER_MATCHING_RULE_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_PROSPECT_MASTER_MATCHING_RULE_CREATE],
              permissions,
            ),
            enableUpdate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_PROSPECT_MASTER_MATCHING_RULE_UPDATE],
              permissions,
            ),
          },
          {
            title: Translation(PULSELEADS_POINT_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_RULE_PATH}${PULSELEADS_POINT_RULE_PATH}`,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_POINT_RULE_NAME_CREATE,
                AdminLeadsPermissionType.PULSELEADS_POINT_RULE_NAME_READ,
                AdminLeadsPermissionType.PULSELEADS_POINT_RULE_NAME_UPDATE,
                AdminLeadsPermissionType.PULSELEADS_POINT_RULE_SETTINGS_CREATE,
                AdminLeadsPermissionType.PULSELEADS_POINT_RULE_SETTINGS_READ,
                AdminLeadsPermissionType.PULSELEADS_POINT_RULE_SETTINGS_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_POINT_RULE_SETTINGS_CREATE,
                AdminLeadsPermissionType.PULSELEADS_POINT_RULE_NAME_CREATE,
              ],
              permissions,
            ),
            enableUpdate: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_POINT_RULE_SETTINGS_UPDATE,
                AdminLeadsPermissionType.PULSELEADS_POINT_RULE_NAME_UPDATE,
              ],
              permissions,
            ),
            child: [
              {
                title: Translation(PULSELEADS_POINT_RULE_SETTINGS_TITLE),
                path: `${PULSELEADS_BASE_PATH}${PULSELEADS_RULE_PATH}${PULSELEADS_POINT_RULE_PATH}${PULSELEADS_POINT_RULE_SETTINGS_PATH}`,
                namespace: PULSELEADS_POINT_RULE_SETTINGS_NAMESPACE,
                component: PointRuleSettingsRoutes,
                enableRead: isPermitted(
                  [
                    AdminLeadsPermissionType.PULSELEADS_POINT_RULE_SETTINGS_CREATE,
                    AdminLeadsPermissionType.PULSELEADS_POINT_RULE_SETTINGS_READ,
                    AdminLeadsPermissionType.PULSELEADS_POINT_RULE_SETTINGS_UPDATE,
                  ],
                  permissions,
                ),
                enableCreate: isPermitted(
                  [AdminLeadsPermissionType.PULSELEADS_POINT_RULE_SETTINGS_CREATE],
                  permissions,
                ),
                enableUpdate: isPermitted(
                  [AdminLeadsPermissionType.PULSELEADS_POINT_RULE_SETTINGS_UPDATE],
                  permissions,
                ),
              },
              {
                title: Translation(PULSELEADS_POINT_RULE_NAME_TITLE),
                path: `${PULSELEADS_BASE_PATH}${PULSELEADS_RULE_PATH}${PULSELEADS_POINT_RULE_PATH}${PULSELEADS_POINT_RULE_NAME_PATH}`,
                namespace: PULSELEADS_POINT_RULE_NAME_NAMESPACE,
                component: RuleNameRoutes,
                enableRead: isPermitted(
                  [
                    AdminLeadsPermissionType.PULSELEADS_POINT_RULE_NAME_CREATE,
                    AdminLeadsPermissionType.PULSELEADS_POINT_RULE_NAME_READ,
                    AdminLeadsPermissionType.PULSELEADS_POINT_RULE_NAME_UPDATE,
                  ],
                  permissions,
                ),
                enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_POINT_RULE_NAME_CREATE], permissions),
                enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_POINT_RULE_NAME_UPDATE], permissions),
              },
              {
                title: Translation(PULSELEADS_POINT_RULE_LEAD_GOAL_TITLE),
                path: `${PULSELEADS_BASE_PATH}${PULSELEADS_RULE_PATH}${PULSELEADS_POINT_RULE_PATH}${PULSELEADS_POINT_RULE_LEAD_GOAL_PATH}`,
                namespace: PULSELEADS_POINT_RULE_SETTINGS_NAMESPACE,
                component: LeadGoalRoutes,
                enableRead: isPermitted(
                  [
                    AdminLeadsPermissionType.PULSELEADS_POINT_RULE_SETTINGS_CREATE,
                    AdminLeadsPermissionType.PULSELEADS_POINT_RULE_SETTINGS_READ,
                    AdminLeadsPermissionType.PULSELEADS_POINT_RULE_SETTINGS_UPDATE,
                  ],
                  permissions,
                ),
                enableCreate: isPermitted(
                  [AdminLeadsPermissionType.PULSELEADS_POINT_RULE_SETTINGS_CREATE],
                  permissions,
                ),
                enableUpdate: isPermitted(
                  [AdminLeadsPermissionType.PULSELEADS_POINT_RULE_SETTINGS_UPDATE],
                  permissions,
                ),
              },
            ],
          },
          {
            title: Translation(PULSELEADS_MARKETING_LEAD_LIMIT_RULE_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_RULE_PATH}${PULSELEADS_MARKETING_LEAD_LIMIT_RULE_PATH}`,
            namespace: PULSELEADS_MARKETING_LEAD_LIMIT_RULE_NAMESPACE,
            component: MarketingLeadLimitPage,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_PROSPECT_MASTER_MATCHING_RULE_READ,
                AdminLeadsPermissionType.PULSELEADS_PROSPECT_MASTER_MATCHING_RULE_CREATE,
                AdminLeadsPermissionType.PULSELEADS_PROSPECT_MASTER_MATCHING_RULE_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_PROSPECT_MASTER_MATCHING_RULE_CREATE],
              permissions,
            ),
            enableUpdate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_PROSPECT_MASTER_MATCHING_RULE_UPDATE],
              permissions,
            ),
          },
        ],
      },
      {
        title: Translation(PULSELEADS_PROSPECT_TITLE),
        path: `${PULSELEADS_BASE_PATH}${PULSELEADS_LEAD_PROSPECT_PATH}`,
        enableRead: isPermitted(
          [
            AdminLeadsPermissionType.PULSELEADS_PROSPECT_CATEGORY_READ,
            AdminLeadsPermissionType.PULSELEADS_PROSPECT_CATEGORY_CREATE,
            AdminLeadsPermissionType.PULSELEADS_PROSPECT_CATEGORY_UPDATE,
          ],
          permissions,
        ),

        child: [
          {
            title: Translation(PULSELEADS_PROSPECT_CATEGORY_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_LEAD_PROSPECT_PATH}${PULSELEADS_LEAD_PROSPECT_CATEGORY_PATH}`,
            namespace: PULSELEADS_PROSPECT_CATEGORY_NAMESPACE,
            component: ProspectCategoryRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_PROSPECT_CATEGORY_READ,
                AdminLeadsPermissionType.PULSELEADS_PROSPECT_CATEGORY_CREATE,
                AdminLeadsPermissionType.PULSELEADS_PROSPECT_CATEGORY_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_PROSPECT_CATEGORY_CREATE], permissions),
            enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_PROSPECT_CATEGORY_UPDATE], permissions),
          },
        ],
      },
      {
        title: Translation(EngagementConstants.PULSELEADS_ENGAGEMENT_TITLE),
        path: `${EngagementConstants.PULSELEADS_LEAD_ENGAGEMENT_PATH}`,
        enableRead: isPermitted(
          [
            AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_FIELDS_READ,
            AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_FIELDS_CREATE,
            AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_TASK_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_TASK_CREATE,
            AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_TASK_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_TASK_READ,
            AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_TOPIC_READ,
            AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_TOPIC_CREATE,
            AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_TOPIC_UPDATE,
          ],
          permissions,
        ),
        enableModule: !getConfigurations()?.Sales?.prospect?.disableEngagement,
        child: [
          {
            title: Translation(EngagementConstants.PULSELEADS_ENGAGEMENT_FIELDS_TITLE),
            path: `${EngagementConstants.PULSELEADS_LEAD_ENGAGEMENT_PATH}${EngagementConstants.PULSELEADS_LEAD_ENGAGEMENT_FIELDS_PATH}`,
            namespace: EngagementConstants.PULSELEADS_ENGAGEMENT_FIELDS_NAMESPACE,
            component: EngagementFieldsRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_FIELDS_READ,
                AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_FIELDS_CREATE,
                AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_FIELDS_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_FIELDS_CREATE], permissions),
            enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_FIELDS_UPDATE], permissions),
            enableModule: !getConfigurations()?.Sales?.prospect?.disableEngagement,
          },
          {
            title: Translation(EngagementConstants.PULSELEADS_ENGAGEMENT_TOPIC_TITLE),
            path: `${EngagementConstants.PULSELEADS_LEAD_ENGAGEMENT_PATH}${EngagementConstants.PULSELEADS_LEAD_ENGAGEMENT_TOPIC_PATH}`,
            namespace: EngagementConstants.PULSELEADS_ENGAGEMENT_TOPIC_NAMESPACE,
            component: EngagementTopicRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_TOPIC_READ,
                AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_TOPIC_CREATE,
                AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_TOPIC_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_TOPIC_CREATE], permissions),
            enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_TOPIC_UPDATE], permissions),
            enableModule: !getConfigurations()?.Sales?.prospect?.disableEngagement,
          },
          {
            title: Translation(EngagementConstants.PULSELEADS_ENGAGEMENT_TASK_TITLE),
            path: `${EngagementConstants.PULSELEADS_LEAD_ENGAGEMENT_PATH}${EngagementConstants.PULSELEADS_LEAD_ENGAGEMENT_TASK_PATH}`,
            namespace: EngagementConstants.PULSELEADS_ENGAGEMENT_TASK_NAMESPACE,
            component: EngagementTaskRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_TASK_CREATE,
                AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_TASK_UPDATE,
                AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_TASK_READ,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_TASK_CREATE], permissions),
            enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_ENGAGEMENT_TASK_UPDATE], permissions),
            enableModule: !getConfigurations()?.Sales?.prospect?.disableEngagement,
          },
        ],
      },
      {
        title: Translation(PULSELEADS_BULKUPLOAD_TITLE),
        path: `${PULSELEADS_BASE_PATH}${PULSELEADS_BULKUPLOAD_PATH}`,
        enableRead: isPermitted(
          [
            AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_UPLOAD_READ,
            AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_UPLOAD_CREATE,
            AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_UPLOAD_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_STATUS_READ,
            AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_STATUS_CREATE,
            AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_STATUS_UPDATE,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(PULSELEADS_BULKUPLOAD_UPLOAD_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_BULKUPLOAD_PATH}${PULSELEADS_BULKUPLOAD_UPLOAD_PATH}`,
            namespace: PULSELEADS_BULKUPLOAD_UPLOAD_NAMESPACE,
            component: BulkUploadUploadRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_UPLOAD_READ,
                AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_UPLOAD_CREATE,
                AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_UPLOAD_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_UPLOAD_CREATE], permissions),
            enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_UPLOAD_UPDATE], permissions),
          },
          {
            title: Translation(PULSELEADS_BULKUPLOAD_STATUS_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_BULKUPLOAD_PATH}${PULSELEADS_BULKUPLOAD_STATUS_PATH}`,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_STATUS_READ,
                AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_STATUS_CREATE,
                AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_STATUS_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_STATUS_CREATE], permissions),
            enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_STATUS_UPDATE], permissions),
            child: [
              {
                title: Translation(PULSELEADS_BULKUPLOAD_STATUS_BULK_UPLOAD_TITLE),
                path: `${PULSELEADS_BASE_PATH}${PULSELEADS_BULKUPLOAD_PATH}${PULSELEADS_BULKUPLOAD_STATUS_PATH}${PULSELEADS_BULKUPLOAD_STATUS_PATH_STATUS_LIST}`,
                namespace: PULSELEADS_BULKUPLOAD_STATUS_NAMESPACE,
                component: BulkUploadStatusRoutes,
                enableRead: isPermitted(
                  [
                    AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_STATUS_READ,
                    AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_STATUS_CREATE,
                    AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_STATUS_UPDATE,
                  ],
                  permissions,
                ),
                enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_STATUS_CREATE], permissions),
                enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_STATUS_UPDATE], permissions),
              },
              // {title: Translation(PULSELEADS_BULKUPLOAD_STATUS_IMPORT_AGENT_TITLE),
              //   path: `${PULSELEADS_BASE_PATH}${PULSELEADS_BULKUPLOAD_PATH}${PULSELEADS_BULKUPLOAD_STATUS_PATH}${PULSELEADS_BULKUPLOAD_STATUS_PATH_IMPORT_AGENT_LIST}`,
              //   namespace: PULSELEADS_BULKUPLOAD_STATUS_NAMESPACE,
              //   component: BulkUploadImportAgentRoutes,
              //   enableRead: isPermitted([
              //     AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_STATUS_READ,
              //     AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_STATUS_CREATE,
              //     AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_STATUS_UPDATE
              //   ], permissions),
              //   enableCreate: isPermitted([
              //     AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_STATUS_CREATE
              //   ], permissions),
              //   enableUpdate: isPermitted([
              //     AdminLeadsPermissionType.PULSELEADS_BULKUPLOAD_STATUS_UPDATE
              //   ], permissions)}
            ],
          },
        ],
      },
      {
        title: Translation(PULSELEADS_CAMPAIGN_FEEDBACK_TITLE),
        path: `${PULSELEADS_BASE_PATH}${PULSELEADS_CAMPAIGN_FEEDBACK_PATH}`,
        namespace: PULSELEADS_CAMPAIGN_FEEDBACK_NAMESPACE,
        component: CampaignFeedbackRoutes,
        enableRead: isPermitted(
          [
            AdminLeadsPermissionType.PULSELEADS_CAMPAIGN_FEEDBACK_READ,
            AdminLeadsPermissionType.PULSELEADS_CAMPAIGN_FEEDBACK_CREATE,
            AdminLeadsPermissionType.PULSELEADS_CAMPAIGN_FEEDBACK_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_CAMPAIGN_FEEDBACK_CREATE], permissions),
        enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_CAMPAIGN_FEEDBACK_UPDATE], permissions),
      },
      {
        title: Translation(PULSELEADS_LEADS_NOT_DISTRIBUTED_TITLE),
        path: `${PULSELEADS_BASE_PATH}${PULSELEADS_LEADS_PATH}${PULSELEADS_LEADS_NOT_DISTRIBUTED_PATH}`,
        namespace: PULSELEADS_LEADS_NOT_DISTRIBUTED_NAMESPACE,
        component: LeadNotDistributionPage,
        enableRead: isPermitted(
          [
            AdminLeadsPermissionType.PULSELEADS_LEADS_NOT_DISTRIBUTED_READ,
            AdminLeadsPermissionType.PULSELEADS_LEADS_NOT_DISTRIBUTED_CREATE,
            AdminLeadsPermissionType.PULSELEADS_LEADS_NOT_DISTRIBUTED_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_PROSPECT_CATEGORY_CREATE], permissions),
        enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_PROSPECT_CATEGORY_UPDATE], permissions),
      },
      {
        title: Translation(PULSELEADS_STRAIGHT_THROUGH_TITLE),
        path: `${PULSELEADS_BASE_PATH}${PULSELEADS_STRAIGHT_THROUGH_PATH}`,
        enableRead: isPermitted(
          [
            AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_READ,
            AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_CREATE,
            AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_READ,
            AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_CREATE,
            AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_CHANNEL_CAMPAIGN_READ,
            AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_CHANNEL_CAMPAIGN_CREATE,
            AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_CHANNEL_CAMPAIGN_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_READ,
            AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_CREATE,
            AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_UPDATE,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_STRAIGHT_THROUGH_PATH}${PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_PATH}`,
            namespace: PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_NAMESPACE,
            component: EventCampaignRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_READ,
                AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_CREATE,
                AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_CREATE],
              permissions,
            ),
            enableUpdate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_EVENT_CAMPAIGN_UPDATE],
              permissions,
            ),
          },
          {
            title: Translation(PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_STRAIGHT_THROUGH_PATH}${PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_PATH}`,
            namespace: PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_NAMESPACE,
            component: ProductCampaignRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_READ,
                AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_CREATE,
                AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_CREATE],
              permissions,
            ),
            enableUpdate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_PRODUCT_CAMPAIGN_UPDATE],
              permissions,
            ),
          },
          {
            title: Translation(PULSELEADS_STRAIGHT_THROUGH_CHANNEL_CAMPAIGN_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_STRAIGHT_THROUGH_PATH}${PULSELEADS_STRAIGHT_THROUGH_CHANNEL_CAMPAIGN_PATH}`,
            namespace: PULSELEADS_STRAIGHT_THROUGH_CHANNEL_CAMPAIGN_NAMESPACE,
            component: ChannelCampaignRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_CHANNEL_CAMPAIGN_READ,
                AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_CHANNEL_CAMPAIGN_CREATE,
                AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_CHANNEL_CAMPAIGN_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_CHANNEL_CAMPAIGN_CREATE],
              permissions,
            ),
            enableUpdate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_CHANNEL_CAMPAIGN_UPDATE],
              permissions,
            ),
          },
          {
            title: Translation(PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_STRAIGHT_THROUGH_PATH}${PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_PATH}`,
            namespace: PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_NAMESPACE,
            component: StagingLeadRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_READ,
                AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_CREATE,
                AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_CREATE],
              permissions,
            ),
            enableUpdate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_STRAIGHT_THROUGH_STAGING_LEAD_UPDATE],
              permissions,
            ),
          },
        ],
      },
      {
        title: Translation(PULSELEADS_AGENT_TITLE),
        path: `${PULSELEADS_BASE_PATH}${PULSELEADS_AGENT_PATH}`,
        enableRead: isPermitted(
          [
            AdminLeadsPermissionType.PULSELEADS_AGENT_LEAD_SOURCE_READ,
            AdminLeadsPermissionType.PULSELEADS_AGENT_LEAD_SOURCE_CREATE,
            AdminLeadsPermissionType.PULSELEADS_AGENT_LEAD_SOURCE_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_AGENT_REASSIGN_LEAD_READ,
            AdminLeadsPermissionType.PULSELEADS_AGENT_REASSIGN_LEAD_CREATE,
            AdminLeadsPermissionType.PULSELEADS_AGENT_REASSIGN_LEAD_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_AGENT_REDISTRIBUTE_LEAD_READ,
            AdminLeadsPermissionType.PULSELEADS_AGENT_REDISTRIBUTE_LEAD_CREATE,
            AdminLeadsPermissionType.PULSELEADS_AGENT_REDISTRIBUTE_LEAD_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_AGENT_AUTO_DROP_LEAD_READ,
            AdminLeadsPermissionType.PULSELEADS_AGENT_AUTO_DROP_LEAD_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_AGENT_AUTO_DROP_LEAD_CREATE,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(PULSELEADS_AGENT_LEAD_SOURCE_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_AGENT_PATH}${PULSELEADS_AGENT_LEAD_SOURCE_PATH}`,
            namespace: PULSELEADS_AGENT_LEAD_SOURCE_NAMESPACE,
            component: AgentLeadSourceRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_AGENT_LEAD_SOURCE_READ,
                AdminLeadsPermissionType.PULSELEADS_AGENT_LEAD_SOURCE_CREATE,
                AdminLeadsPermissionType.PULSELEADS_AGENT_LEAD_SOURCE_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_AGENT_LEAD_SOURCE_CREATE], permissions),
            enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_AGENT_LEAD_SOURCE_UPDATE], permissions),
          },
          {
            title: Translation(PULSELEADS_AGENT_REASSIGN_LEAD_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_AGENT_PATH}${PULSELEADS_AGENT_REASSIGN_LEAD_PATH}`,
            namespace: PULSELEADS_AGENT_REASSIGN_LEAD_NAMESPACE,
            component: ReassignLeadRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_AGENT_REASSIGN_LEAD_READ,
                AdminLeadsPermissionType.PULSELEADS_AGENT_REASSIGN_LEAD_CREATE,
                AdminLeadsPermissionType.PULSELEADS_AGENT_REASSIGN_LEAD_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_AGENT_REASSIGN_LEAD_CREATE], permissions),
            enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_AGENT_REASSIGN_LEAD_UPDATE], permissions),
          },
          {
            title: Translation(PULSELEADS_AGENT_REDISTRIBUTE_LEAD_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_AGENT_PATH}${PULSELEADS_AGENT_REDISTRIBUTE_LEAD_PATH}`,
            namespace: PULSELEADS_AGENT_REDISTRIBUTE_LEAD_NAMESPACE,
            component: RedistributeLeadRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_AGENT_REDISTRIBUTE_LEAD_READ,
                AdminLeadsPermissionType.PULSELEADS_AGENT_REDISTRIBUTE_LEAD_CREATE,
                AdminLeadsPermissionType.PULSELEADS_AGENT_REDISTRIBUTE_LEAD_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_AGENT_REDISTRIBUTE_LEAD_CREATE],
              permissions,
            ),
            enableUpdate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_AGENT_REDISTRIBUTE_LEAD_UPDATE],
              permissions,
            ),
          },
          {
            title: Translation(PULSELEADS_AGENT_AUTO_DROP_LEAD_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_AGENT_PATH}${PULSELEADS_AGENT_AUTO_DROP_LEAD_PATH}`,
            namespace: PULSELEADS_AGENT_AUTO_DROP_LEAD_NAMESPACE,
            component: AgentAutoDropLeadRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_AGENT_AUTO_DROP_LEAD_READ,
                AdminLeadsPermissionType.PULSELEADS_AGENT_AUTO_DROP_LEAD_CREATE,
                AdminLeadsPermissionType.PULSELEADS_AGENT_AUTO_DROP_LEAD_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminLeadsPermissionType.PULSELEADS_AGENT_AUTO_DROP_LEAD_CREATE], permissions),
            enableUpdate: isPermitted([AdminLeadsPermissionType.PULSELEADS_AGENT_AUTO_DROP_LEAD_UPDATE], permissions),
          },
        ],
      },
      {
        title: Translation(PULSELEADS_CONFIGURATION_TITLE),
        path: `${PULSELEADS_BASE_PATH}${PULSELEADS_CONFIGURATION_PATH}`,
        enableRead: isPermitted(
          [
            AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_LOCATION_READ,
            AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_LOCATION_CREATE,
            AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_LOCATION_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_LANGUAGE_READ,
            AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_LANGUAGE_CREATE,
            AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_LANGUAGE_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_PRODUCT_READ,
            AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_PRODUCT_CREATE,
            AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_PRODUCT_UPDATE,
          ],
          permissions,
        ),
        child: [
          // {
          //   title: Translation(PULSELEADS_CONFIGURATION_LOCATION_TITLE),
          //   path: `${PULSELEADS_BASE_PATH}${PULSELEADS_CONFIGURATION_PATH}${PULSELEADS_CONFIGURATION_LOCATION_PATH}`,
          //   namespace: PULSELEADS_CONFIGURATION_LOCATION_NAMESPACE,
          //   component: ConfigurationLocationRoutes,
          //   enableRead: isPermitted([
          //     AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_LOCATION_READ,
          //     AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_LOCATION_CREATE,
          //     AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_LOCATION_UPDATE
          //   ], permissions),
          //   enableCreate: isPermitted([
          //     AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_LOCATION_CREATE
          //   ], permissions),
          //   enableUpdate: isPermitted([
          //     AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_LOCATION_UPDATE
          //   ], permissions)
          // },
          // {
          //   title: Translation(PULSELEADS_CONFIGURATION_LANGUAGE_TITLE),
          //   path: `${PULSELEADS_BASE_PATH}${PULSELEADS_CONFIGURATION_PATH}${PULSELEADS_CONFIGURATION_LANGUAGE_PATH}`,
          //   namespace: PULSELEADS_CONFIGURATION_LANGUAGE_NAMESPACE,
          //   component: ConfigurationLanguagePage,
          //   enableRead: isPermitted([
          //     AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_LANGUAGE_READ,
          //     AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_LANGUAGE_CREATE,
          //     AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_LANGUAGE_UPDATE
          //   ], permissions),
          //   enableCreate: isPermitted([
          //     AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_LANGUAGE_CREATE
          //   ], permissions),
          //   enableUpdate: isPermitted([
          //     AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_LANGUAGE_UPDATE
          //   ], permissions)
          // },
          // {
          //   title: Translation(PULSELEADS_CONFIGURATION_PRODUCT_TITLE),
          //   path: `${PULSELEADS_BASE_PATH}${PULSELEADS_CONFIGURATION_PATH}${PULSELEADS_CONFIGURATION_PRODUCT_PATH}`,
          //   namespace: PULSELEADS_CONFIGURATION_PRODUCT_NAMESPACE,
          //   component: ConfigurationProductRoutes,
          //   enableRead: isPermitted([
          //     AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_PRODUCT_READ,
          //     AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_PRODUCT_CREATE,
          //     AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_PRODUCT_UPDATE
          //   ], permissions),
          //   enableCreate: isPermitted([
          //     AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_PRODUCT_CREATE
          //   ], permissions),
          //   enableUpdate: isPermitted([
          //     AdminLeadsPermissionType.PULSELEADS_CONFIGURATION_PRODUCT_UPDATE
          //   ], permissions)
          // },
        ],
      },
      {
        title: Translation(PULSELEADS_LEAD_REASSIGNMENT_TITLE),
        path: `${PULSELEADS_BASE_PATH}${PULSELEADS_LEAD_REASSIGNMENT_PATH}`,
        enableRead: isPermitted(
          [
            AdminLeadsPermissionType.PULSELEADS_LEAD_REASSIGNMENT_UPLOAD_READ,
            AdminLeadsPermissionType.PULSELEADS_LEAD_REASSIGNMENT_UPLOAD_CREATE,
            AdminLeadsPermissionType.PULSELEADS_LEAD_REASSIGNMENT_UPLOAD_UPDATE,
            AdminLeadsPermissionType.PULSELEADS_LEAD_REASSIGNMENT_STATUS_READ,
            AdminLeadsPermissionType.PULSELEADS_LEAD_REASSIGNMENT_STATUS_CREATE,
            AdminLeadsPermissionType.PULSELEADS_LEAD_REASSIGNMENT_STATUS_UPDATE,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(PULSELEADS_LEAD_REASSIGNMENT_UPLOAD_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_LEAD_REASSIGNMENT_PATH}${PULSELEADS_LEAD_REASSIGNMENT_UPLOAD_PATH}`,
            namespace: PULSELEADS_LEAD_REASSIGNMENT_UPLOAD_NAMESPACE,
            component: LeadReassignmentUploadRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_LEAD_REASSIGNMENT_UPLOAD_READ,
                AdminLeadsPermissionType.PULSELEADS_LEAD_REASSIGNMENT_UPLOAD_CREATE,
                AdminLeadsPermissionType.PULSELEADS_LEAD_REASSIGNMENT_UPLOAD_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_LEAD_REASSIGNMENT_UPLOAD_CREATE],
              permissions,
            ),
            enableUpdate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_LEAD_REASSIGNMENT_UPLOAD_UPDATE],
              permissions,
            ),
          },
          {
            title: Translation(PULSELEADS_LEAD_REASSIGNMENT_STATUS_TITLE),
            path: `${PULSELEADS_BASE_PATH}${PULSELEADS_LEAD_REASSIGNMENT_PATH}${PULSELEADS_LEAD_REASSIGNMENT_STATUS_PATH}`,
            namespace: PULSELEADS_LEAD_REASSIGNMENT_STATUS_NAMESPACE,
            component: LeadReassignmentStatusRoutes,
            enableRead: isPermitted(
              [
                AdminLeadsPermissionType.PULSELEADS_LEAD_REASSIGNMENT_STATUS_READ,
                AdminLeadsPermissionType.PULSELEADS_LEAD_REASSIGNMENT_STATUS_CREATE,
                AdminLeadsPermissionType.PULSELEADS_LEAD_REASSIGNMENT_STATUS_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_LEAD_REASSIGNMENT_STATUS_CREATE],
              permissions,
            ),
            enableUpdate: isPermitted(
              [AdminLeadsPermissionType.PULSELEADS_LEAD_REASSIGNMENT_STATUS_UPDATE],
              permissions,
            ),
          },
        ],
      },
    ],
  };
};
