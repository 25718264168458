import { forEach } from 'lodash';
import { Dispatch } from 'react';
import { PaginateList } from '../../../common/types/common-types';
import { getDayStart, getDayEnd } from 'src/app/common/utils/time-utils';
import { MdrtBannerDetail, MdrtBannerItem } from '../types/mdrt-banner-types';
import { apiClient, apiErrorHandler } from 'src/app/common/network';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_MDRT']}`;

export type MdrtBannerListParams = {
  name: string;
  publishStatus: string;
  updatedAtFrom: Date | null;
  updatedAtTo: Date | null;
  isValid: string;
  lang: string;
  page: number;
  limit: number;
};

export const create = async (data: MdrtBannerDetail, dispatch?: Dispatch<any>): Promise<MdrtBannerDetail> => {
  return apiClient
    .post<MdrtBannerDetail>(`${ADMIN_URL}/mdrt-hub/banner`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const update = async (data: MdrtBannerDetail, dispatch?: Dispatch<any>): Promise<MdrtBannerDetail> => {
  return apiClient
    .put<MdrtBannerDetail>(`${ADMIN_URL}/mdrt-hub/banner/${data.id}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchItem = async (id: string, dispatch?: Dispatch<any>): Promise<MdrtBannerDetail> => {
  return apiClient
    .get<MdrtBannerDetail>(`${ADMIN_URL}/mdrt-hub/banner/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchList = async (
  params: MdrtBannerListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<MdrtBannerItem>> => {
  let queryPath = `${ADMIN_URL}/mdrt-hub/banner/list?`;
  debugger;
  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.includes('From')) {
          queryPath += `${key}=${getDayStart(param).toISOString()}&`;
        }
        if (key.includes('To')) {
          queryPath += `${key}=${getDayEnd(param).toISOString()}&`;
        }
      }
    } else {
      if (key !== 'name' && param === 'ALL') {
      } else {
        queryPath += param ? `${key}=${param}&` : '';
      }
    }
  });

  return apiClient
    .get<PaginateList<MdrtBannerItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const publish = async (id: string, dispatch?: Dispatch<any>): Promise<MdrtBannerDetail> => {
  return apiClient
    .patch<MdrtBannerDetail>(`${ADMIN_URL}/mdrt-hub/banner/${id}/publish`, {
      id,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const unpublish = async (id: string, dispatch?: Dispatch<any>): Promise<MdrtBannerDetail> => {
  return apiClient
    .patch<MdrtBannerDetail>(`${ADMIN_URL}/mdrt-hub/banner/${id}/unpublish`, {
      id,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
