import { apiClient, apiErrorHandler } from 'src/app/common/network';
import { InvitationDetailType } from '../types/invitation-letter-type';
import { Dispatch } from '@reduxjs/toolkit';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

export const getInvitationDetail = async (invitationId?: any) => {
  return apiClient.get(`${ADMIN_URL}/events/invitation/detail/${invitationId}`);
};

export const createNewInvitation = async (
  data: InvitationDetailType,
  dispatch?: Dispatch<any>,
): Promise<InvitationDetailType> => {
  return apiClient
    .post<InvitationDetailType>(`${ADMIN_URL}/events/invitation/create`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
