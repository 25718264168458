import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { AchievementUploadItem, AchievementUploadFormMode } from '../../../types/achievement-upload-types';
import { fetchAchievementItem } from '../../../network/achievementUploadCrud';
import AchievementDetailForm from './components/AchievementDetailForm';

interface StateAchievementUploadType {
  type: AchievementUploadFormMode;
}

const AchievementDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode, achievementUploadId } = useMemo(() => {
    const state = location.state as StateAchievementUploadType;
    return {
      formMode: state.type,
      achievementUploadId: id ? id : undefined,
    };
  }, [id, location]);

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [achievementUpload, setAchievementUpload] = useState<AchievementUploadItem>();

  const reloadData = () => {
    if (achievementUploadId) {
      setIsLoading(true);
      fetchAchievementItem(achievementUploadId, dispatch)
        .then((result) => {
          setAchievementUpload(result);
        })
        .catch(() => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [achievementUploadId]);

  return (
    <>
      {formMode === AchievementUploadFormMode.CREATE && (
        <AchievementDetailForm formMode={formMode} onReload={reloadData} onRouteTo={(route) => history.push(route)} />
      )}
      {formMode !== AchievementUploadFormMode.CREATE &&
        (isLoading || !achievementUpload ? (
          <LayoutSplashScreen />
        ) : (
          <AchievementDetailForm
            id={achievementUploadId}
            formMode={formMode}
            achievement={achievementUpload}
            onReload={reloadData}
            onRouteTo={(route) => history.push(route)}
          />
        ))}
    </>
  );
};

export default AchievementDetailPage;
