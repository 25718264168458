import { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
// import { INCENTIVE_BASE_PATH, INCENTIVE_UPLOAD_STATUS_PATH } from '../../constants';
import { INCENTIVE_BASE_PATH_V2, INCENTIVE_UPLOAD_STATUS_PATH } from '../../constants';
import UploadStatusListingPage, { uploadStatusFilterKeys } from './List/UploadStatusListingPage';
import UploadFailedList from './FailedList/UploadFailedList';

// export const uploadStatusPath = `${INCENTIVE_BASE_PATH}${INCENTIVE_UPLOAD_STATUS_PATH}`;
export const uploadStatusPath = `${INCENTIVE_BASE_PATH_V2}${INCENTIVE_UPLOAD_STATUS_PATH}`;

const UploadStatusRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={`${uploadStatusPath}`} to={`${uploadStatusPath}/list`} />
      <PruRoute
        exact
        path={`${uploadStatusPath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={uploadStatusFilterKeys}>
            <UploadStatusListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute exact path={`${uploadStatusPath}/:id/failed/list`} component={UploadFailedList} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default UploadStatusRoutes;
