import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useDataProvider } from 'src/app/common/utils';
import { ParamsProps } from '../../../../../../common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from '../../../../../../common/components/PruTable/PruFilter';
import PruTable from '../../../../../../common/components/PruTable/PruTable';
import { PaginateListMore } from '../../../../../../common/types/common-types';
import { getDayEnd, getDayStart } from '../../../../../../common/utils/time-utils';
import {
  ReportIncentiveOpenV2Item,
  ReportItemIncentiveOpenParamsV2,
} from 'src/app/modules/Report/types/incentive-opening-types-v2';
import {
  fetchIncentiveOpeningReportDownloadV2,
  fetchIncentiveOpeningReportV2,
} from 'src/app/modules/Report/network/incentiveOpeningReportCrudV2';

type IncentiveOpeningReportV2ListingPageProps = {} & ParamsProps;

const initialState: ReportItemIncentiveOpenParamsV2 = {
  requestedDateFrom: getDayStart(new Date()),
  requestedDateTo: getDayEnd(new Date()),
  agentCode: '',
  unitCode: '',
  branchCode: '',
  officeCode: '',
  incentiveType: '',
  saleChannel: '',
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): ReportItemIncentiveOpenParamsV2 => {
  return initialParams
    ? {
        requestedDateFrom: initialParams.requestedDateFrom
          ? getDayStart(new Date(initialParams.requestedDateFrom))
          : getDayStart(new Date()),
        requestedDateTo: initialParams.requestedDateTo
          ? getDayEnd(new Date(initialParams.requestedDateTo))
          : getDayEnd(new Date()),
        agentCode: '',
        unitCode: '',
        branchCode: '',
        officeCode: '',
        incentiveType: '',
        saleChannel: '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const IncentiveOpeningReportListingPageV2: FC<IncentiveOpeningReportV2ListingPageProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilterValue = paramsInitiator(initialParams);

  const [filterState, setFilterState] = useState<ReportItemIncentiveOpenParamsV2>(defaultFilterValue);
  const [reportList, setReportList] = useState<PaginateListMore<ReportIncentiveOpenV2Item>>();

  const { isLoading, refreshData } = useDataProvider<PaginateListMore<ReportIncentiveOpenV2Item>>(
    async () => {
      try {
        return await fetchIncentiveOpeningReportV2(filterState, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvDownload = () => {
    try {
      fetchIncentiveOpeningReportDownloadV2(filterState, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('report.IncentiveOpening.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('report.IncentiveOpening.agentCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'unitCode',
            initialValue: filterState.unitCode,
            displayName: Translation('report.IncentiveOpening.unitCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'branchCode',
            initialValue: filterState.branchCode,
            displayName: Translation('report.IncentiveOpening.branchCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'officeCode',
            initialValue: filterState.officeCode,
            displayName: Translation('report.IncentiveOpening.officeCode'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'requestedDateFrom',
            fieldTo: 'requestedDateTo',
            initialDateFrom: filterState.requestedDateFrom,
            initialDateTo: filterState.requestedDateTo,
            defaultDateFrom: defaultFilterValue.requestedDateFrom,
            defaultDateTo: defaultFilterValue.requestedDateTo,
            displayName: Translation('report.common.requestDate'),
            required: true,
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'incentiveType',
            initialValue: filterState.incentiveType,
            displayName: 'Incentive Type',
            choices: [
              { displayName: 'All', value: '' },
              { displayName: 'Incentive', value: 'INCENTIVE' },
              { displayName: 'Compensation', value: 'COMPENSATION' },
            ],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'saleChannel',
            initialValue: filterState.saleChannel,
            displayName: 'Sale Channel',
            choices: [
              { displayName: 'All', value: '' },
              // { displayName: 'Traditional', value: 'Traditional' },
              { displayName: 'Traditional Agent', value: 'Agent' },
              { displayName: 'Pruventure', value: 'Pruventure' },
              { displayName: 'Banca', value: 'Banca' },
              { displayName: 'Other', value: 'Other' },
            ],
          },
        ]}
        onChangeFilter={(data) => {
          console.log(data);
          return setFilterState({
            ...filterState,
            requestedDateFrom: data.requestedDateFrom,
            requestedDateTo: data.requestedDateTo,
            agentCode: data.agentCode,
            unitCode: data.unitCode,
            branchCode: data.branchCode,
            officeCode: data.officeCode,
            incentiveType: data.incentiveType,
            saleChannel: data.saleChannel,
          });
        }}
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.IncentiveOpening.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvDownload(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: () => '',
          },
          {
            keyIndex: 'agentCode',
            displayName: Translation('report.IncentiveOpening.agentAdCode'),
            renderData: (row) => row.agentCode || '-',
          },
          {
            keyIndex: 'unitCode',
            displayName: Translation('report.IncentiveOpening.unitCode'),
            renderData: (row) => row.unitCode || '-',
          },
          {
            keyIndex: 'branchCode',
            displayName: Translation('report.IncentiveOpening.branchCode'),
            renderData: (row) => row.branchCode || '-',
          },
          {
            keyIndex: 'officeCode',
            displayName: Translation('report.IncentiveOpening.officeCode'),
            renderData: (row) => row.officeCode || '-',
          },
          {
            keyIndex: 'incentiveName',
            displayName: Translation('report.IncentiveOpening.incentiveOpened'),
            renderData: (row) => row.incentiveName,
          },
          {
            keyIndex: 'totalOpen',
            displayName: Translation('report.IncentiveOpening.numberOfOpenings'),
            renderData: (row) => row.totalOpen,
          },
          {
            keyIndex: 'firstOpen',
            displayName: Translation('report.IncentiveOpening.firstOpeningDate'),
            renderData: (row) => row.firstOpen,
          },
          {
            keyIndex: 'incentiveType',
            align: 'center',
            displayName: 'Incentive Type',
            renderData: (row) => String(row.incentiveType),
          },
          {
            keyIndex: 'saleChannel',
            align: 'center',
            displayName: 'Sale Channel',
            renderData: (row) => String(row.saleChannel),
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default IncentiveOpeningReportListingPageV2;
