import React, { FC } from "react";
import { Switch, Redirect, RouteComponentProps } from "react-router-dom";
import ParamsProvider from "src/app/common/components/ParamsProvider";
import PruRoute from "src/app/common/components/PruRoute";
import { NOT_FOUND_ERROR_PAGE_PATH } from "src/app/common/constants";
import { MDRT_HUB_BASE_PATH, MDRT_VIDEO_LIBRARY_BASE_PATH } from "../../constants";
import MdrtVideoLibraryListingPage, { filterKeys } from "./List/MdrtVideoLibraryListingPage";
import MdrtVideoLibraryDetailPage from "./Create/MdrtVideoLibraryDetailPage";

const MdrtVideoLibraryRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={`${MDRT_HUB_BASE_PATH}${MDRT_VIDEO_LIBRARY_BASE_PATH}`}
        to={`${MDRT_HUB_BASE_PATH}${MDRT_VIDEO_LIBRARY_BASE_PATH}/list`}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${MDRT_VIDEO_LIBRARY_BASE_PATH}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={filterKeys}>
            <MdrtVideoLibraryListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${MDRT_VIDEO_LIBRARY_BASE_PATH}/view/:id`}
        component={MdrtVideoLibraryDetailPage}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${MDRT_VIDEO_LIBRARY_BASE_PATH}/edit/:id`}
        component={MdrtVideoLibraryDetailPage}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${MDRT_VIDEO_LIBRARY_BASE_PATH}/create`}
        component={MdrtVideoLibraryDetailPage}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default MdrtVideoLibraryRoutes;
