import { EReceiverTypeAgent, EReceiverTypeCustomer, EReferralForm } from '../enum/customer-campaign.enum';

export type CustomerCampaignParams = {
  startFromDate: Date | null;
  startToDate: Date | null;
  endFromDate: Date | null;
  endToDate: Date | null;
  status: string;
  campaignName: string;
  campaignId: string;
  page: number;
  limit: number;
};

export type CustomerCampaignFormState = {
  _id?: string | null;
  campaignName: string;
  startDate: Date;
  endDate: Date;
  bannerImg: ImageItem | null;
  introduction: string;
  additionalInformation: string;
  tabs: [
    {
      tabTitle: string;
      tabDescription: string;
    },
  ];
  referralForm: {
    isName: boolean;
    isPhone: boolean;
    isProvinceCity: boolean;
    isDob: boolean;
    isEmail: boolean;
  };
  consent: string;
  tnc: FileType | null;
  receiverTypeAgent: ReceiverTypeAgent;
  receiverTypeCustomer: ReceiverTypeCustomer;
  smsDuration: number;
  isAllowSharingWhenCompleted: boolean;
};

export type ImageItem = {
  id: string;
  fileName: string;
  url: string;
  type: string;
  importId: string;
};

export type FileType = {
  blobId: string;
  fileName: string;
  url: string;
  type: string;
};

export type ReceiverTypeAgent = {
  isAllAgents: boolean;
  isPruventure: boolean;
  isGroupOfUsers: boolean;
  userFile: FileType | null;
  userList: [];
};

export type ReceiverTypeCustomer = {
  // allCustomer: boolean;
  // targetsCustomer: boolean;
  // excludeCustomer: boolean;
  type: string;
  userFile: FileType | null;
  userList: [];
};

export const MentionList = [
  { id: 'agentCode', display: 'agentcode' },
  { id: 'agentName', display: 'agentname' },
  { id: 'customerName', display: 'customername' },
];

export const ReferralFormOption = [
  { value: false, key: EReferralForm.isName, isRequired: true, label: 'Họ Tên' },
  { value: false, key: EReferralForm.isPhone, isRequired: true, label: 'Số Điện Thoại' },
  { value: false, key: EReferralForm.isProvinceCity, isRequired: false, label: 'Tỉnh/Thành Phố Đang Sinh Sống' },
  { value: false, key: EReferralForm.isDob, isRequired: false, label: 'Ngày Sinh' },
  { value: false, key: EReferralForm.isEmail, isRequired: false, label: 'Email' },
];

export const ReceiverTypeAgentOptions = [
  { value: false, key: EReceiverTypeAgent.isAllAgents, label: 'All Traditional Agents' },
  { value: false, key: EReceiverTypeAgent.isPruventure, label: 'PRUVenture' },
  { value: false, key: EReceiverTypeAgent.isGroupOfUsers, label: 'Group Of Users', isUpload: true },
];

export const ReceiverTypeCustomerOptions = [
  { value: EReceiverTypeCustomer.allCustomer, label: 'All Customer' },
  {
    value: EReceiverTypeCustomer.targetsCustomer,
    label: 'Target Customers',
    isUpload: true,
  },
  {
    value: EReceiverTypeCustomer.excludeCustomer,
    label: 'Exclude Customer Complaint Handling',
  },
];
