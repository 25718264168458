import React, { FC } from "react";
import { RouteComponentProps, Switch, Redirect } from "react-router-dom";
import {
  SURVEY_BASE_PATH,
  SURVEY_CANDIDATE_PATH,
} from "src/app/modules/Survey/constants";
import { NOT_FOUND_ERROR_PAGE_PATH } from "src/app/common/constants";
import PruRoute from "src/app/common/components/PruRoute";
import ParamsProvider from "src/app/common/components/ParamsProvider";
import { surveyFilterKeys } from "src/app/modules/Survey/pages/Management/List/SurveyListingPage";
import CandidateSurveyListingPage from "./List/CandidateSurveyListingPage";
import CandidateSurveyDetailPage from "./Create/CandidateSurveyDetailPage";
import CandidateSurveyAnalyticPage from "./Analytics/CandidateSurveyAnalyticPage";

const CandidateSurveyManagementPage: FC<RouteComponentProps> = ({ history }) => {
  return (
    <Switch>
      <Redirect
        exact
        from={`${SURVEY_BASE_PATH}${SURVEY_CANDIDATE_PATH}`}
        to={`${SURVEY_BASE_PATH}${SURVEY_CANDIDATE_PATH}/list`}
      />
      <PruRoute
        exact
        path={`${SURVEY_BASE_PATH}${SURVEY_CANDIDATE_PATH}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={surveyFilterKeys}>
            <CandidateSurveyListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute
        exact
        path={`${SURVEY_BASE_PATH}${SURVEY_CANDIDATE_PATH}/edit/:id`}
        component={CandidateSurveyDetailPage}
      />
      <PruRoute
        exact
        path={`${SURVEY_BASE_PATH}${SURVEY_CANDIDATE_PATH}/create`}
        component={CandidateSurveyDetailPage}
      />
      <PruRoute
        exact
        path={`${SURVEY_BASE_PATH}${SURVEY_CANDIDATE_PATH}/:id/results`}
        component={CandidateSurveyAnalyticPage}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default CandidateSurveyManagementPage;
