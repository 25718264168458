import { forEach } from 'lodash';
import { PaginateList, PaginateListMore } from '../../../common/types/common-types';
import { Dispatch } from 'react';
import { apiClient, apiErrorHandler } from 'src/app/common/network';
import { ReportListParams, ReportItem } from '../types/salesMPerformanceTrackingReportTypes';
import qs from 'qs';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

const formatDataFilters = (params: ReportListParams) => {
  let data: any = {
    dataFilters: [
      {
        name: 'module',
        value: 'Sales',
        type: 'String',
        operator: 'eq',
      },
    ],
    page: params.page,
    limit: params.limit,
  };

  forEach(params, (param, key) => {
    if (param) {
      switch (key) {
        case 'trackingDateFrom':
          data.dataFilters.push({
            name: 'createdAt',
            value: param,
            type: 'Date',
            operator: 'gte',
          });
          break;
        case 'trackingDateTo':
          data.dataFilters.push({
            name: 'createdAt',
            value: param,
            type: 'Date',
            operator: 'lte',
          });
          break;
        case 'materialName':
          data.dataFilters.push({
            name: 'materialName',
            value: param,
            type: 'String',
            operator: 'search',
          });
          break;
        case 'categoryName':
          data.dataFilters.push({
            name: 'materialCategoryName',
            value: param,
            type: 'String',
            operator: 'search',
          });
          break;
      }
    }
  });

  return data;
};

export const fetchReportDownload = (params: ReportListParams, dispatch?: Dispatch<any>): void => {
  const uri = `${ADMIN_URL}/report/sales-material-performance-tracking/export`;
  const data = formatDataFilters(params);

  apiClient
    .post<Blob>(uri, data, { responseType: 'text' })
    .then((response: any) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Sales_material_performance_tracking_' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchDataReport = async (
  params: ReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<ReportItem>> => {
  const uri = `${ADMIN_URL}/report/sales-material-performance-tracking`;
  const data = formatDataFilters(params);

  return apiClient
    .post<PaginateListMore<ReportItem>>(uri, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export type CategoryPaginateList = PaginateList<any> & { categoryList: any[]; totalRecords: number };

export const fetchCategoryList = async (
  params: Record<string, any>,
  dispatch?: Dispatch<any>,
): Promise<CategoryPaginateList> => {
  let queryObj: any = {
    sort: ['name:ASC'],
  };

  queryObj = { queryObj, ...params };

  let queryPath = `${ADMIN_URL}/content-manager/collection-types/api::category.category?${qs.stringify(queryObj, {
    encodeValuesOnly: true,
  })}`;

  return apiClient
    .get(queryPath)
    .then((response: any) => {
      return {
        categoryList: response.data.results,
        total: response.data.pagination.total,
        totalPages: response.data.pagination.pageCount,
        limit: response.data.pagination.pageSize,
        items: response.data.results,
        totalRecords: response.data.pagination.total,
        docs: response.data.results,
        hasNextPage: true,
        hasPrevPage: false,
        totalDocs: response.data.pagination.total,
      };
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
