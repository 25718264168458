import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  FEEDBACK_TITLE,
  FEEDBACK_BASE_PATH,
  AdminFeedbackPermissionType,
  FEEDBACK_CATEGORY_TITLE,
  FEEDBACK_CATEGORY_PATH,
  FEEDBACK_CATEGORIES_NAMESPACE,
  FEEDBACK_RESULT_TITLE,
  FEEDBACK_RESULT_PATH,
  FEEDBACK_RESULT_NAMESPACE,
} from './constants';
import { isPermitted } from 'src/app/common/utils';
import FeedbackCategoryPage from './pages/Category/FeedbackCategoryPage';
import FeedbackResultPage from './pages/Result/FeedbackResultPage';

export const feedbackModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(FEEDBACK_TITLE),
    path: FEEDBACK_BASE_PATH,
    icon: '/media/svg/icons/Communication/Sending mail.svg',
    enableRead: isPermitted(Object.values(AdminFeedbackPermissionType), permissions),
    child: [
      {
        title: Translation(FEEDBACK_CATEGORY_TITLE),
        path: `${FEEDBACK_BASE_PATH}${FEEDBACK_CATEGORY_PATH}`,
        namespace: FEEDBACK_CATEGORIES_NAMESPACE,
        component: FeedbackCategoryPage,
        enableRead: isPermitted(
          [
            AdminFeedbackPermissionType.FEEDBACK_CATEGORIES_READ,
            AdminFeedbackPermissionType.FEEDBACK_CATEGORIES_CREATE,
            AdminFeedbackPermissionType.FEEDBACK_CATEGORIES_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminFeedbackPermissionType.FEEDBACK_CATEGORIES_CREATE], permissions),
        enableUpdate: isPermitted([AdminFeedbackPermissionType.FEEDBACK_CATEGORIES_UPDATE], permissions),
      },
      {
        title: Translation(FEEDBACK_RESULT_TITLE),
        path: `${FEEDBACK_BASE_PATH}${FEEDBACK_RESULT_PATH}`,
        namespace: FEEDBACK_RESULT_NAMESPACE,
        component: FeedbackResultPage,
        enableRead: isPermitted(
          [
            AdminFeedbackPermissionType.FEEDBACK_RESULT_READ,
            AdminFeedbackPermissionType.FEEDBACK_RESULT_CREATE,
            AdminFeedbackPermissionType.FEEDBACK_RESULT_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminFeedbackPermissionType.FEEDBACK_RESULT_CREATE], permissions),
        enableUpdate: isPermitted([AdminFeedbackPermissionType.FEEDBACK_RESULT_UPDATE], permissions),
      },
    ],
  };
};
