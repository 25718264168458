import React, { FC, useState } from 'react';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { PaginateListMore } from 'src/app/common/types/common-types';
import { IReportFilter, IReportItem } from '../../../types/customer-feedback-types';
import { fetchReport, fetchExportFileReport } from '../../../network/customerFeedbackCrud';
import moment from 'moment';

type ServingSurveyOpeningReportProps = {} & ParamsProps;

const initialState: IReportFilter = {
  fromDate: moment().startOf('month').toDate(),
  toDate: moment().toDate(),
  agentCode: '',
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): IReportFilter => {
  return initialParams
    ? {
        fromDate: initialParams.fromDate
          ? moment(initialParams.fromDate).startOf('day').toDate()
          : moment().startOf('month').toDate(),
        toDate: initialParams.toDate
          ? moment(initialParams.toDate).endOf('day').toDate()
          : moment().endOf('day').toDate(),
        agentCode: '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const ServingSurveyOpeningReport: FC<ServingSurveyOpeningReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilter = paramsInitiator(initialParams);

  const [filterState, setFilterState] = useState<IReportFilter>(defaultFilter);
  const [reportList, setReportList] = useState<PaginateListMore<IReportItem>>();
  const { isLoading, refreshData } = useDataProvider<PaginateListMore<IReportItem>>(
    async () => {
      try {
        const params = handleParams(filterState);
        return await fetchReport(params, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsv = () => {
    try {
      const params = handleParams(filterState);
      fetchExportFileReport(params, dispatch);
    } catch (err) {}
  };

  const handleParams = (filters: IReportFilter) => {
    return {
      ...filterState,
      fromDate: moment(filterState.fromDate).startOf('day').toDate(),
      toDate: moment(filterState.toDate).endOf('day').toDate(),
    };
  };

  return (
    <>
      <PruFilter
        title={Translation('report.survey.servingSurveyOpening.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('report.survey.servingSurveyOpening.agentCode'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'fromDate',
            fieldTo: 'toDate',
            initialDateFrom: filterState.fromDate,
            initialDateTo: filterState.toDate,
            defaultDateFrom: defaultFilter.fromDate,
            defaultDateTo: defaultFilter.toDate,
            displayName: Translation('report.survey.servingSurveyOpening.surveyDate'),
            required: true,
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            agentCode: data.agentCode,
            fromDate: data.fromDate,
            toDate: data.toDate,
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.survey.servingSurveyOpening.title')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsv(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            keyIndex: 'agentCode',
            displayName: Translation('report.survey.servingSurveyOpening.agentCode'),
            align: 'center',
            renderData: (row) => row.agentCode || '-',
          },
          {
            keyIndex: 'agentName',
            align: 'center',
            displayName: Translation('report.survey.servingSurveyOpening.agentName'),
            renderData: (row) => row.agentName || '-',
          },
          {
            keyIndex: 'customerName',
            align: 'center',
            displayName: Translation('report.survey.servingSurveyOpening.customerName'),
            renderData: (row) => row.clientNumber || '-',
          },
          {
            keyIndex: 'detailsSeen',
            align: 'center',
            displayName: Translation('report.survey.servingSurveyOpening.detailsSeen'),
            renderData: (row) => row.reviewStatus || '-',
          },
          {
            keyIndex: 'surveyDate',
            displayName: Translation('report.survey.servingSurveyOpening.surveyDate'),
            align: 'center',
            renderData: (row) => moment(row.completedDate).format('DD/MM/YYYY') || '-',
          },
          {
            keyIndex: 'openDate',
            align: 'center',
            displayName: Translation('report.survey.servingSurveyOpening.openDate'),
            renderData: (row) => (row.isRead ? moment(row.updatedAt).format('DD/MM/YYYY HH:mm') : '-'),
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default ServingSurveyOpeningReport;
