import { useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import FileUploader from '../FileUploader';
import { FileObj } from 'src/app/modules/Notification/types/notification-types';
import { createBlob, getBlob } from '../../network';
import { fileUpload, toAbsoluteUrl } from '../../utils';
import { useDispatch } from 'react-redux';
import { Translation } from 'src/app/i18n';

interface CheckGroupProps {
  value?: any;
  onChange?: any;
  options: { key: string; label: string; value: boolean; isRequired?: boolean; isUpload?: boolean }[];
  disabled: boolean;
  moduleType?: string;
}

export default function CheckBoxGroupBoolean(props: CheckGroupProps) {
  const [selectedValue, setSelectedValue] = useState(props.value);

  const dispatch = useDispatch();

  const handleChange = (key: string, index: number) => (e: any) => {
    const checked = e.target.checked;

    setSelectedValue((prev: any) => {
      prev[key] = checked;
      props.onChange(prev);

      return prev;
    });
  };

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        {
          mimeType: fileType,
          accessLevel: 'anonymous',
          // module: `material_${(moduleType || '').toLowerCase()}`,
        },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        return {
          id: result.blobId,
          fileName: fileName,
          url: result.url,
          type: fileType,
        };
      }

      // return {
      //   id: '6671230664a883b8700638ca',
      //   fileName: 'aaa',
      //   url: 'https://cidqddpruforce.azureedge.net/public/6671230664a883b8700638ca.csv',
      //   type: fileType,
      // };
    } catch (err) {
    } finally {
    }
  };

  const onChangeFileUploader = (item: any) => {
    setSelectedValue((prev: any) => {
      prev.userFile = item;

      props.onChange(prev);

      return prev;
    });

    return item ? item : undefined;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        width: '100%',
        marginBottom: 10,
      }}
    >
      <FormGroup key={'formGroup'} style={{ display: 'flex', flexDirection: 'row' }}>
        {/* Loop each item */}
        {props.options.map((item, index) => (
          <>
            <FormControlLabel
              key={item.key}
              control={
                <Checkbox
                  disabled={props.disabled || item?.isRequired}
                  checked={item?.isRequired || selectedValue[item.key]}
                  onChange={handleChange(item.key, index)}
                  name={item.label}
                  key={item.key}
                />
              }
              label={item.label}
            />
          </>
        ))}
        {props.options.map((item, index) => (
          <>
            {item.isUpload && selectedValue.isGroupOfUsers == true && (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FileUploader
                    disabled={props.disabled}
                    upload={handleUploadFile}
                    value={props.value.userFile}
                    onChange={onChangeFileUploader}
                    showAllowText={'Upload the excel file list of agent (max =10MB)'}
                    maxFileSize={10}
                    allowedFileTypes={[
                      'csv',
                      'xlsx',
                      'xls',
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                      'application/vnd.ms-excel',
                    ]}
                  />
                </div>
                {!props.value.userFile && (
                  <div
                    style={{
                      flexBasis: '100%',
                      height: 'auto',
                    }}
                  >
                    <a href={toAbsoluteUrl('/templates/Template_Group_Of_User.csv')}>
                      {Translation('recruitment.form.downloadTemplate')}
                    </a>
                  </div>
                )}
              </>
            )}
          </>
        ))}
      </FormGroup>
    </div>
  );
}
