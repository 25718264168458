import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import PruRoute from 'src/app/common/components/PruRoute';
import { PULSELEADS_BASE_PATH } from '../../../constants';
import { PULSELEADS_LEAD_REASSIGNMENT_PATH, PULSELEADS_LEAD_REASSIGNMENT_STATUS_PATH } from '../constants';
import LeadReassignmentStatusListingPage from './List/LeadReassignmentStatusListingPage';
import LeadReassignmentStatusFailedListingPage from './FailedList/LeadReassignmentFailedListingPage';

export const statusPath = `${PULSELEADS_BASE_PATH}${PULSELEADS_LEAD_REASSIGNMENT_PATH}${PULSELEADS_LEAD_REASSIGNMENT_STATUS_PATH}`;

const LeadReassignmentStatusRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={statusPath} to={`${statusPath}/list`} />
      <PruRoute exact path={`${statusPath}/list`} component={LeadReassignmentStatusListingPage} />
      <PruRoute exact path={`${statusPath}/:id/failed/list`} component={LeadReassignmentStatusFailedListingPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default LeadReassignmentStatusRoutes;
