
export enum PublishStatus {
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED",
}

export enum MdrtStoryFormMode {
  CREATE = "create",
  EDIT = "edit",
  VIEW = "view",
}

export type MdrtStoryItem = {
  _id: string;
  agentCode: string;
  title: string;
  templateHeaderUrl?: string | null;
  templateHeaderImg?: FileUpload | null;
  templateBody: string;
  publishAt?: Date | null;
  unpublishAt?: Date | null;
  publishStatus: PublishStatus;
  createdAt?: Date | null;
  createdBy?: string | null;
  updatedAt?: Date | null;
  updatedBy?: string | null;
};

export interface FileUpload {
  url?: string;
  id: string;
  fileName: string;
  inclusive?: boolean;
  receiverType?: string;
  contentType?: string;
  fileType: string;
}

export type MdrtStoryDetail = {
  id?: string;
  agentCode: string;
  title: string;
  templateHeaderUrl?: string | null;
  templateHeaderImg?: FileUpload | null;
  templateBody: string;
  templateBodyText: string;
  publishStatus: PublishStatus;
};

export type MdrtStoryFormState = {
  id?: string;
  agentCode: string;
  title: string;
  templateHeaderUrl?: string | null;
  templateHeaderImg?: FileUpload | null;
  templateBody: string;
  templateBodyText: string;
};
