import React, { FC, useEffect, useReducer, useMemo, CSSProperties, Fragment } from 'react';
import { ParamsProps } from '../ParamsProvider';
import { useCommonStyles } from '../../styles/common-styles';
import useFilterStyles from '../../styles/filter-styles';
import { useIntl } from 'react-intl';
import { Button, TextField, FormControl, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { useErrorHandler, ErrorFieldType, ErrorFieldDef } from '../../utils/form-error-utils';
import moment from 'moment';
import { DATE_ERROR_TEXT, MANDATORY_FIELD_ERROR_TEXT, OVER_PERIOD_TIME } from '../../constants';
import AsyncAutocomplete from '../AsyncAutocomplete';
import { get, reduce } from 'lodash';
import { PruDatePicker, PruDateTimePicker } from '../PruDatePicker';
import { DURATION_STARTDATE_ERROR } from 'src/app/modules/Event/pages/InvitationLetter/Detail/constants';
import { useUpdateEffect } from '../../utils';

export enum PruFilterItemType {
  FREE_TEXT = 'FREE_TEXT',
  DATE_RANGE = 'DATE_RANGE',
  DATE_TIME_RANGE = 'DATE_TIME_RANGE',
  DROPDOWN = 'DROPDOWN',
  MULTIPLE_SELECT = 'MULTIPLE_SELECT',
  ASYNC_DROPDOWN = 'ASYNC_DROPDOWN',
  DATE_MONTH = 'DATE_MONTH',
}

const ITEM_LENGTH = {
  [PruFilterItemType.FREE_TEXT]: 1,
  [PruFilterItemType.DATE_RANGE]: 2,
  [PruFilterItemType.DATE_TIME_RANGE]: 2,
  [PruFilterItemType.DROPDOWN]: 1,
  [PruFilterItemType.ASYNC_DROPDOWN]: 1,
  [PruFilterItemType.DATE_MONTH]: 1,
  [PruFilterItemType.MULTIPLE_SELECT]: 1,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
};

type PruFilterProps = {
  title: string;
  disableReset?: boolean;
  itemDef: PruFilterItemDef[];
  onChangeFilter: (filterState: PruFilterState) => void;
  fetchData: () => void;
} & ParamsProps;

export type PruFilterFreeTextDef = {
  type: PruFilterItemType.FREE_TEXT;
  style?: CSSProperties;
  field: string;
  className?: any;
  displayName: string;
  initialValue?: string;
  defaultValue?: string;
  disabled?: boolean;
  required?: boolean;
  maxLength?: number;
};

export type PruFilterDateRangePeriodTime = {
  minute?: number;
  minutes?: number;
  hour?: number;
  hours?: number;
  day?: number;
  days?: number;
  month?: number;
  months?: number;
};

export type PruFilterDateRangeDef = {
  type: PruFilterItemType.DATE_RANGE;
  fieldFrom: string;
  fieldTo: string;
  displayName: string;
  initialDateFrom?: Date | null;
  initialDateTo?: Date | null;
  defaultDateFrom?: Date | null;
  defaultDateTo?: Date | null;
  required?: boolean;
  disabled?: boolean;
  isRequired?: boolean;
  periodTime?: PruFilterDateRangePeriodTime;
};

export type PruFilterDateTimeRangeDef = {
  type: PruFilterItemType.DATE_TIME_RANGE;
  fieldFrom: string;
  fieldTo: string;
  displayName: string;
  initialDateFrom?: Date | null;
  initialDateTo?: Date | null;
  defaultDateFrom?: Date | null;
  defaultDateTo?: Date | null;
  required?: boolean;
  disabled?: boolean;
  isRequired?: boolean;
  periodTime?: PruFilterDateRangePeriodTime;
};

export type PruFilterDropdownItem = {
  displayName: string;
  value: string;
};

export type PruFilterDropdownDef = {
  type: PruFilterItemType.DROPDOWN;
  style?: CSSProperties;
  field: string;
  displayName: string;
  initialValue?: string;
  defaultValue?: string;
  choices: PruFilterDropdownItem[];
  disabled?: boolean;
  required?: boolean;
};

export type PruFilterMultipleSelectDef = {
  type: PruFilterItemType.MULTIPLE_SELECT;
  style?: CSSProperties;
  field: string;
  className?: any;
  displayName: string;
  initialValue?: string[];
  defaultValue?: string[];
  allValue?: string;
  choices: PruFilterDropdownItem[];
  disabled?: boolean;
  required?: boolean;
};

export type PruFilterAsyncDropdownDef = {
  type: PruFilterItemType.ASYNC_DROPDOWN;
  style?: CSSProperties;
  field: string;
  displayName: string;
  initialValue?: string;
  defaultValue?: string;
  fetchList: () => Promise<PruFilterDropdownItem[]>;
  disabled?: boolean;
  required?: boolean;
};

export type PruFilterDateMonthDef = {
  type: PruFilterItemType.DATE_MONTH;
  field: string;
  displayName: string;
  initialValue?: Date | null | string;
  defaultValue?: Date | null | string;
  disabled?: boolean;
  required?: boolean;
  style?: CSSProperties;
};

type PruFilterItemDef =
  | PruFilterFreeTextDef
  | PruFilterDateRangeDef
  | PruFilterDateTimeRangeDef
  | PruFilterDropdownDef
  | PruFilterAsyncDropdownDef
  | PruFilterMultipleSelectDef
  | PruFilterDateMonthDef;

type PruFilterState = {
  [id: string]: any;
};

type ModifyFilterAction = {
  type: 'CHANGE_FILTER';
  payload: {
    field: keyof PruFilterState;
    value: string | Date | string[] | null;
  };
};

type ResetFilterAction = {
  type: 'RESET_FILTER';
  payload: {
    itemDef: PruFilterItemDef[];
  };
};

type PruFilterAction = ModifyFilterAction | ResetFilterAction;

const paramsInitiator = (mode: 'INIT' | 'DEFAULT', itemDef: PruFilterItemDef[]): PruFilterState => {
  let state: PruFilterState = {};
  itemDef.forEach((item) => {
    switch (item.type) {
      case PruFilterItemType.FREE_TEXT:
        state[item.field] = (mode === 'INIT' ? item.initialValue || item.defaultValue : item.defaultValue) || '';
        break;
      case PruFilterItemType.DATE_RANGE:
        state[item.fieldFrom] =
          (mode === 'INIT' ? item.initialDateFrom || item.defaultDateFrom : item.defaultDateFrom) || null;
        state[item.fieldTo] = (mode === 'INIT' ? item.initialDateTo || item.defaultDateTo : item.defaultDateTo) || null;
        break;
      case PruFilterItemType.DATE_TIME_RANGE:
        state[item.fieldFrom] =
          (mode === 'INIT' ? item.initialDateFrom || item.defaultDateFrom : item.defaultDateFrom) || null;
        state[item.fieldTo] = (mode === 'INIT' ? item.initialDateTo || item.defaultDateTo : item.defaultDateTo) || null;
        break;
      case PruFilterItemType.DROPDOWN:
        state[item.field] = (mode === 'INIT' ? item.initialValue || item.defaultValue : item.defaultValue) || '';
        break;
      case PruFilterItemType.MULTIPLE_SELECT:
        state[item.field] = (mode === 'INIT' ? item.initialValue || item.defaultValue : item.defaultValue) || [];
        break;
      case PruFilterItemType.ASYNC_DROPDOWN:
        state[item.field] = (mode === 'INIT' ? item.initialValue || item.defaultValue : item.defaultValue) || '';
        break;
      case PruFilterItemType.DATE_MONTH:
        state[item.field] = (mode === 'INIT' ? item.initialValue || item.defaultValue : item.defaultValue) || null;
        break;
    }
  });
  return state;
};

const filterReducer = (state: PruFilterState, action: PruFilterAction) => {
  switch (action.type) {
    case 'CHANGE_FILTER': {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
    case 'RESET_FILTER': {
      return paramsInitiator('DEFAULT', action.payload.itemDef);
    }
  }
};

type ErrorState = {
  mandatory: Record<string, boolean>;
  immediate: Record<string, boolean>;
};

const PruFilter: FC<PruFilterProps> = ({ title, itemDef, disableReset, onChangeFilter, fetchData }) => {
  const commonClasses = useCommonStyles().classes;
  const filterClasses = useFilterStyles().classes;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [filterState, filterDispatch] = useReducer(filterReducer, paramsInitiator('INIT', itemDef));

  const errorDefinition: ErrorFieldDef[] = useMemo(() => {
    let errorDef: ErrorFieldDef[] = [];
    itemDef.forEach((item) => {
      if (item.type === PruFilterItemType.DATE_RANGE || item.type === PruFilterItemType.DATE_TIME_RANGE) {
        errorDef.push({
          name: `${item.fieldTo}Before${item.fieldFrom}`,
          fieldType: ErrorFieldType.IMMEDIATE,
          condition: () => {
            const startDate = filterState[item.fieldFrom];
            const endDate = filterState[item.fieldTo];
            if (startDate && endDate) {
              return !!moment(new Date(startDate)).isAfter(moment(new Date(endDate)));
            } else {
              return false;
            }
          },
        });

        if (item.required) {
          errorDef.push({
            name: `${item.fieldTo}${item.fieldFrom}Required`,
            fieldType: ErrorFieldType.IMMEDIATE,
            condition: () => {
              return !filterState[item.fieldFrom] || !filterState[item.fieldTo];
            },
          });
        }

        if (item.periodTime) {
          errorDef.push({
            name: `${item.fieldFrom}${item.fieldTo}OverPeriodTime`,
            fieldType: ErrorFieldType.IMMEDIATE,
            condition: () => {
              const startDate = filterState[item.fieldFrom] ? moment(filterState[item.fieldFrom]) : null;
              const endDate = filterState[item.fieldTo] ? moment(filterState[item.fieldTo]) : null;
              if (item.periodTime && startDate && endDate) {
                Object.keys(item.periodTime).forEach((key) => {
                  startDate.add(key, get(item.periodTime, key));
                });
              }
              return !!startDate && !!endDate && startDate.valueOf() < endDate.valueOf();
            },
          });
        }
      }
      if (
        item.type === PruFilterItemType.FREE_TEXT ||
        item.type === PruFilterItemType.DROPDOWN ||
        item.type === PruFilterItemType.MULTIPLE_SELECT ||
        item.type === PruFilterItemType.ASYNC_DROPDOWN ||
        item.type === PruFilterItemType.DATE_MONTH
      ) {
        if (item.required) {
          errorDef.push({
            name: `${item.displayName}Required`,
            fieldType: ErrorFieldType.IMMEDIATE,
            condition: () => !filterState[item.field],
          });
        }
      }
    });
    return errorDef;
  }, [itemDef, filterState]);

  const filterItemRender = (item: PruFilterItemDef) => {
    switch (item.type) {
      case PruFilterItemType.FREE_TEXT:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <TextField
              disabled={item.disabled}
              style={{
                ...item.style,
                marginRight: 20,
              }}
              className={item.className}
              margin="dense"
              variant="outlined"
              value={filterState[item.field]}
              inputProps={{ maxLength: item.maxLength ? item.maxLength : 5000 }}
              onChange={(e) =>
                filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.field, value: e.target.value } })
              }
              required={item.required}
            />
          </>
        );
      case PruFilterItemType.DATE_RANGE:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <PruDatePicker
              slotProps={{
                textField: {
                  required: item.required,
                  error:
                    errorState.immediate[`${item.fieldFrom}${item.fieldTo}OverPeriodTime`] ||
                    errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`] ||
                    errorState.immediate[`${item.fieldTo}${item.fieldFrom}Required`],
                  helperText: errorState.immediate[`${item.fieldFrom}${item.fieldTo}OverPeriodTime`]
                    ? OVER_PERIOD_TIME.replace(
                        '[TIME]',
                        reduce(
                          Object.keys(get(item, 'periodTime', {})),
                          (result: string, key: string) => {
                            return result
                              ? `${result} ${get(item.periodTime, key)} ${key}`
                              : `${get(item.periodTime, key)} ${key}`;
                          },
                          '',
                        ),
                      )
                    : errorState.immediate[`${item.fieldTo}${item.fieldFrom}Required`] &&
                      !filterState[item.fieldFrom] &&
                      MANDATORY_FIELD_ERROR_TEXT,
                },
              }}
              disabled={item.disabled}
              format="DD/MM/YYYY"
              value={filterState[item.fieldFrom]}
              onChange={(date) =>
                filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldFrom, value: date } })
              }
            />
            <div className="PruFilter-date-divider" />
            <PruDatePicker
              slotProps={{
                textField: {
                  required: item.required,
                  error:
                    errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`] ||
                    errorState.immediate[`${item.fieldTo}${item.fieldFrom}Required`],
                  helperText: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`]
                    ? DATE_ERROR_TEXT
                    : errorState.immediate[`${item.fieldTo}${item.fieldFrom}Required`] &&
                      !filterState[item.fieldTo] &&
                      MANDATORY_FIELD_ERROR_TEXT,
                  style: { marginRight: 20 },
                },
              }}
              format="DD/MM/YYYY"
              value={filterState[item.fieldTo]}
              onChange={(date) =>
                filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldTo, value: date } })
              }
            />
          </>
        );
      case PruFilterItemType.DATE_TIME_RANGE:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <PruDateTimePicker
              slotProps={{
                textField: {
                  required: item.required,
                  disabled: item.disabled,
                  error:
                    errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`] ||
                    errorState.immediate[`${item.fieldTo}${item.fieldFrom}Required`],
                  helperText: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`]
                    ? DATE_ERROR_TEXT
                    : errorState.immediate[`${item.fieldTo}${item.fieldFrom}Required`] &&
                      !filterState[item.fieldFrom] &&
                      MANDATORY_FIELD_ERROR_TEXT,
                },
              }}
              format="DD/MM/YYYY HH:mm"
              value={filterState[item.fieldFrom]}
              onChange={(date) =>
                filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldFrom, value: date } })
              }
            />
            <div className="PruFilter-date-divider" />
            <PruDateTimePicker
              slotProps={{
                textField: {
                  required: item.required,
                  disabled: item.disabled,
                  error:
                    errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`] ||
                    errorState.immediate[`${item.fieldTo}${item.fieldFrom}Required`],
                  helperText: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`]
                    ? DATE_ERROR_TEXT
                    : errorState.immediate[`${item.fieldTo}${item.fieldFrom}Required`] &&
                      !filterState[item.fieldTo] &&
                      MANDATORY_FIELD_ERROR_TEXT,
                  style: { marginRight: 20 },
                },
              }}
              format="DD/MM/YYYY HH:mm"
              value={filterState[item.fieldTo]}
              onChange={(date) =>
                filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldTo, value: date } })
              }
            />
          </>
        );
      case PruFilterItemType.DROPDOWN:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <FormControl
              style={{
                ...item.style,
                marginRight: 20,
              }}
              margin="dense"
              variant="outlined"
            >
              <Select
                native
                disabled={item.disabled}
                value={filterState[item.field]}
                onChange={(e) =>
                  filterDispatch({
                    type: 'CHANGE_FILTER',
                    payload: { field: item.field, value: e.target.value as string },
                  })
                }
                required={item.required}
              >
                {item.choices.map((choice) => (
                  <option key={`dropdown-choice-${choice.value}`} value={choice.value}>
                    {choice.displayName}
                  </option>
                ))}
              </Select>
            </FormControl>
          </>
        );
      case PruFilterItemType.MULTIPLE_SELECT:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <FormControl
              style={{
                ...item.style,
                marginRight: 20,
                maxWidth: 250,
                width: 200,
              }}
              margin="dense"
              variant="outlined"
            >
              <Select
                disabled={item.disabled}
                className={item.className}
                multiple
                value={filterState[item.field]}
                required={item.required}
                onChange={(e) => {
                  let value = Array.isArray(e.target.value) ? e.target.value : [];
                  if (item.allValue && value.length > 0) {
                    let indexAll = value.findIndex((x) => x === item.allValue);
                    if (value.length - 1 === indexAll || item.choices.length - 1 === value.length) {
                      value = [item.allValue];
                    } else {
                      if (indexAll !== -1) {
                        value = item.choices.reduce((previousValue: string[], currentValue) => {
                          if (value.indexOf(currentValue.value) === -1) {
                            previousValue.push(currentValue.value);
                          }
                          return previousValue;
                        }, []);
                      }
                    }
                  }

                  filterDispatch({
                    type: 'CHANGE_FILTER',
                    payload: {
                      field: item.field,
                      value: value,
                    },
                  });
                }}
                renderValue={(selected) => {
                  if (selected && Array.isArray(selected)) {
                    return selected
                      .reduce((previousValue, currentValue) => {
                        let itemFound = item.choices.find((x) => x.value === currentValue);
                        if (itemFound) {
                          previousValue.push(itemFound.displayName);
                        }
                        return previousValue;
                      }, [])
                      .join(', ');
                  } else {
                    return '';
                  }
                }}
                MenuProps={MenuProps}
              >
                {item.choices.map((choice) => (
                  <MenuItem key={`dropdown-choice-${choice.value}`} value={choice.value}>
                    <Checkbox
                      checked={
                        filterState[item.field] &&
                        ((item.allValue && filterState[item.field].indexOf(item.allValue) !== -1) ||
                          filterState[item.field].indexOf(choice.value) !== -1)
                      }
                    />
                    <ListItemText primary={choice.displayName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        );
      case PruFilterItemType.ASYNC_DROPDOWN:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <AsyncAutocomplete
              style={{
                ...item.style,
                marginRight: 20,
              }}
              disabled={item.disabled}
              value={String(filterState[item.field])}
              onChange={(value) =>
                filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.field, value: value } })
              }
              getData={item.fetchList}
              required={item.required}
            />
          </>
        );
      case PruFilterItemType.DATE_MONTH:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <PruDateTimePicker
              slotProps={{
                textField: {
                  disabled: item.disabled,
                  required: item.required,
                  error:
                    errorState.mandatory.durationStartDate || errorState.immediate.currentDateBeforeDurationStartDate,
                  style: {
                    ...item.style,
                    marginRight: 20,
                  },
                  inputProps: { readOnly: true },
                },
              }}
              format="MM/yyyy"
              views={['year', 'month']}
              value={filterState[item.field]}
              onChange={(date) =>
                filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.field, value: date } })
              }
            />
          </>
        );
      default:
        return <></>;
    }
  };

  const renderFilter = () => {
    let filterRows: React.ReactNode[] = [];
    let tempArr: React.ReactNode[] = [];
    let lengthCount = 0;
    itemDef.forEach((item, index) => {
      if (lengthCount + ITEM_LENGTH[item.type] <= 4) {
        lengthCount += ITEM_LENGTH[item.type];
        tempArr.push(<Fragment key={`filter-item-${index}`}>{filterItemRender(item)}</Fragment>);
        if (index >= itemDef.length - 1) {
          filterRows.push([...tempArr]);
        }
      } else {
        filterRows.push([...tempArr]);
        lengthCount = 0;
        tempArr = [];

        lengthCount += ITEM_LENGTH[item.type];
        tempArr.push(<Fragment key={`filter-item-${index}`}>{filterItemRender(item)}</Fragment>);
        if (!itemDef[index + 1]) {
          filterRows.push([...tempArr]);
        }
      }
    });
    return filterRows.map((filterRow, index) => (
      <div className="PruFilter-row" key={`PruFilter-row-${index}`}>
        {filterRow}
      </div>
    ));
  };

  const { errorState, onSubmitErrorValidator, immediateErrorValidator } = useErrorHandler<ErrorState>(
    filterState,
    errorDefinition,
  );

  useUpdateEffect(() => {
    onChangeFilter(filterState);
    immediateErrorValidator();
  }, [filterState]);

  const filterList = () => {
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      fetchData();
    }
  };

  const keyPressSearch = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      filterList();
    }
  };

  useEffect(() => {
    window.addEventListener('keypress', keyPressSearch);
    return () => {
      window.removeEventListener('keypress', keyPressSearch);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ marginBottom: 20 }} className={filterClasses.root}>
      <div style={{ marginBottom: 5 }} className="PruFilter-header-container">
        <div className={commonClasses.header}>{title}</div>
        <div className="PruFilter-row">
          {!disableReset && (
            <Button
              style={{ marginRight: 20 }}
              variant="contained"
              onClick={() => filterDispatch({ type: 'RESET_FILTER', payload: { itemDef } })}
            >
              {Translation('app.button.reset')}
            </Button>
          )}
          <Button variant="contained" color="secondary" onClick={filterList}>
            {Translation('app.button.search')}
          </Button>
        </div>
      </div>
      {renderFilter()}
    </div>
  );
};

export default PruFilter;
