export type IReportFilter = {
  fromDate: Date | null;
  toDate: Date | null;
  agentCode?: string;
  leaderCode?: string;
  surveyTitle?: string;
  channelSurvey : string;
  page: number;
  limit: number;
}

export type IReportItem = {
	agentCode: string;
	surveyData: any;
	agentName: string;
	leaderName: string;
	leaderCode: string;
	region?: string;
	channel?: string;
	office?: string;
	numberOfSent: number;
	numberOfOpened: number;
	numberOfReplied: number;
}

export type ITotalNonProspectSurvey = {
  totalRecords: number;
  totalPages: number;
}


export enum ChannelOption {
  AGENCY = "AD",
  GALLERIE = 'Pruventure',
}