import QueryString from 'qs';
import { Dispatch } from 'react';
import { PaginateResult } from 'src/app/common/types/common-types';
import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { PaginateList } from '../../insurance-summary/type/insurance-summary-types';
import { CompanyPickDetail, CompanyPickListItem, CompanyPickListResponse } from '../type/types';
import qs from 'qs';
import { getDayStart } from 'src/app/common/utils/time-utils';

const companypickEndPoint = '/content-manager/collection-types/api::company-pick.company-pick';

export const fetchCompanyPickList = async (
  params: any,
  sortKeys: { key: string; value?: string }[],
  locale: string,
  dispatch?: Dispatch<any>,
): Promise<PaginateResult<CompanyPickListItem>> => {
  let queryPath = `${ADMIN_URL}${companypickEndPoint}?`;
  const newParams: any = { locale: locale, page: params.page, pageSize: params.pageSize, filters: [] };

  const addFilter = (key: string, operator: string, value: string) => {
    if (params[key]) {
      newParams.filters[key] = { [operator]: value };
    }
  };

  const { name, status, effectiveDate, expiredDate, excludeType } = params;
  addFilter('name', '$containsi', name);
  addFilter('status', '$eq', status);
  addFilter('effectiveDate', '$gte', effectiveDate);
  addFilter('expiredDate', '$lte', expiredDate);
  addFilter('type', '$ne', excludeType);

  const sortArray: any[] = [];
  sortKeys.forEach((item) => {
    if (item.value) {
      sortArray.push(`${encodeURIComponent(item.key)}:${encodeURIComponent(item.value)}`);
    }
  });
  newParams['sort'] = sortArray;
  const queryString = qs.stringify(newParams);
  queryPath = queryPath + queryString;

  const rawData: CompanyPickListResponse = await apiClient
    .get(queryPath, {})
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });

  const convertData: any = {};
  convertData.docs = rawData.results;
  convertData.totalDocs = rawData.pagination.total;
  convertData.limit = rawData.pagination.pageSize;
  convertData.totalPages = rawData.pagination.pageCount;
  convertData.pagingCounter = (rawData.pagination.page - 1) * rawData.pagination.pageSize + 1;
  convertData.hasPrevPage = rawData.pagination.page > 1;
  convertData.hasNextPage = rawData.pagination.page < rawData.pagination.pageCount;
  return convertData;
};

export const fetchCompanyPickWeights = async (dispatch?: Dispatch<any>) => {
  const queryPath = `${ADMIN_URL}/content-manager/api/company-picks?${qs.stringify({
    locale: 'all',
    fields: ['weight'],
    pagination: {
      limit: -1,
    },
  })}`;
  return apiClient
    .get<any>(queryPath, {
      headers: {
        'raw-path': true,
      },
    })
    .then((response) => response.data.data?.map((item: any) => item?.attributes?.weight))
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCompanyPickDetail = async (id: string, dispatch?: Dispatch<any>): Promise<any> => {
  const queryPath = `${ADMIN_URL}${companypickEndPoint}/${id}`;

  const raw = await apiClient
    .get<PaginateList<CompanyPickDetail>>(queryPath, {})
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });

  const convertData = (data: any) => {
    const localizations = data?.localizations?.[0];
    let result: any = {};
    if (localizations) {
      result.id = data.id;
      result.name = { [data.locale]: data.name, [localizations.locale]: localizations.name };
      result.coverpage = {
        [data.locale]: data.coverpage,
        [localizations.locale]: localizations.coverpage,
      };
      result.linkTo = data.linkTo;
      result.linkType = data.linkType;
      result.linkParams = data?.linkParams;
      result.status = data.status;
      result.effectiveRange = [data.effectiveDate, data.expiredDate];
      result.weight = data.weight;
      result.type = data.type;
      result.resourceId = data?.resourceId;
      result.resourceType = data.type;
      result.uniqueId = data?.uniqueId;
      result.localizations = localizations;
    } else {
      result = data;
    }
    return result;
  };

  return convertData(raw);
};

export const updateCompanyPick = async (id: string, body: any, dispatch?: Dispatch<any>): Promise<any> => {
  const queryPath = `${ADMIN_URL}${companypickEndPoint}/${id}`;
  return apiClient
    .put(queryPath, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createCompanyPick = async (
  body: any,
  locale: string,
  relatedId?: string,
  dispatch?: Dispatch<any>,
): Promise<any> => {
  let createURL = `${ADMIN_URL}${companypickEndPoint}?plugins[i18n][locale]=${locale}`;
  if (relatedId) {
    createURL += `&plugins[i18n][relatedEntityId]=${relatedId}`;
  }
  return apiClient
    .post(createURL, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getCustomerSurveyList = async (params: any, locale: string, dispatch?: Dispatch<any>) => {
  return apiClient
    .get(`${ADMIN_URL}/customer-surveys`, { params })
    .then((response) => {
      return {
        libraryList: response.data.items.map((item: any) => {
          return {
            ...item,
            _id: item.id,
            effectiveDate: item.startDate,
            expiredDate: item.endDate,
            module: 'CustomerSurvey',
          };
        }),
        total: response.data.totalRecords,
      };
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getNonProspectSurveyResultList = async (params: any, dispatch?: Dispatch<any>): Promise<any> => {
  let queryPath = `${ADMIN_URL}/non-prospect-surveys?page=${params?.page}&limit=${params?.limit}&`;
  if (params?.name) {
    queryPath += `name=${params?.name}&`;
  }

  if (params?.effectiveDate) {
    queryPath += `endDateFrom=${getDayStart(params?.effectiveDate).toISOString()}&`;
  }

  if (params?.status) {
    queryPath += `status=${params?.status}&`;
  }

  queryPath += `lastUpdateDateSort=descending`;

  return apiClient
    .get(queryPath)
    .then((response) => {
      return {
        libraryList: response.data.items.map((item: any) => {
          return {
            ...item,
            _id: item.id,
            effectiveDate: item.startDate,
            expiredDate: item.endDate,
            module: 'NonProspectSurvey',
          };
        }),
        page: Number(response?.data?.page),
        total: response.data.totalRecords,
      };
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getMaterialById = async (id: string, dispatch?: Dispatch<any>) => {
  const path = `${ADMIN_URL}/content-manager/collection-types/api::material.material/${id}`;
  const raw = await apiClient
    .get(path, {})
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
  return raw;
};

export const getCustomerSurveyById = async (id: string, dispatch?: Dispatch<any>) => {
  const path = `${ADMIN_URL}/customer-surveys/${id}`;
  const raw = await apiClient
    .get(path, {})
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
  return raw;
};

export const getNonProspectSurveyById = async (id: string, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get<any>(`${ADMIN_URL}/non-prospect-surveys/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
