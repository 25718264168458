import React, { FC, useMemo, useReducer } from 'react';
import {
  FileUpload,
  MdrtStampIconDetail,
  MdrtStampIconFormMode,
  MdrtStampIconFormState,
  PublishStatus,
} from '../../../../types/mdrt-stamp-icon-types';
import { Button, TextField } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils/form-error-utils';
import { MANDATORY_FIELD_ERROR_TEXT, MDRT_HUB_BASE_PATH, MDRT_STAMP_ICON_BASE_PATH } from '../../../../constants';
import { create, update } from 'src/app/modules/MdrtHub/network/mdrtStampIconCrud';
import { makeStyles } from 'tss-react/mui';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import FileUploader from 'src/app/common/components/FileUploader';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import { Close } from '@mui/icons-material';

type MdrtStampIconDetailFormProps = {
  itemId?: string;
  formMode: MdrtStampIconFormMode;
  itemDetail?: MdrtStampIconDetail;
  onReload: () => void;
  onRouteTo: (route: string) => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  fieldContainer: {
    width: 120,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 20,
  },
  flexItem: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '33.333333%',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  closeIcon: {
    marginLeft: '5px',
    verticalAlign: 'middle',
  },
}));

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof MdrtStampIconFormState;
    value: any;
  };
};

type UploadAction = {
  type: 'UPLOAD_IMG';
  payload: {
    type: 'icon' | 'benefit' | 'condition';
    id: string;
    url: string;
    fileName: string;
    fileType: string;
  };
};

type MdrtFormAction = ModifyFieldAction | UploadAction;

const initialState: MdrtStampIconFormState = {
  itemId: undefined,
  stampCode: undefined,
  stampName: undefined,
  icon: undefined,
};

const formReducer = (state: MdrtStampIconFormState, action: MdrtFormAction): MdrtStampIconFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'UPLOAD_IMG':
      return {
        ...state,
        icon: {
          id: action.payload.id,
          fileName: action.payload.fileName,
          url: action.payload.url,
          fileType: action.payload.fileType,
        },
      };
    default:
      return state;
  }
};

const detailToStateConvertor = (detail: MdrtStampIconDetail): MdrtStampIconFormState => {
  return {
    itemId: detail.id,
    stampCode: detail.stampCode,
    stampName: detail.stampName,
    icon: detail.icon,
  };
};

const MdrtStampIconDetailForm: FC<MdrtStampIconDetailFormProps> = ({
  itemId,
  formMode,
  itemDetail,
  onReload,
  onRouteTo,
}) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [formState, formDispatch] = useReducer(
    formReducer,
    itemDetail ? detailToStateConvertor(itemDetail) : initialState,
  );
  const isDisabled = useMemo(() => {
    return formMode === MdrtStampIconFormMode.CREATE || formMode === MdrtStampIconFormMode.EDIT ? false : true;
  }, [formMode]);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'stampCode',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'stampName',
      fieldType: ErrorFieldType.MANDATORY,
    },
  ]);

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        { mimeType: fileType, accessLevel: 'anonymous', module: 'mdrt-stamp-icon' },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Upload successfully - ${fileName}`,
            },
          ]),
        );
        return {
          id: result.blobId,
          fileName: fileName,
          url: result.url,
          type: fileType,
        };
      }
    } catch (err) {
    } finally {
    }
  };

  const onChangeBannerFile = (result: any) => {
    formDispatch({
      type: 'UPLOAD_IMG',
      payload: {
        type: 'icon',
        id: result.id,
        url: result.url,
        fileName: result.fileName,
        fileType: result.type,
      },
    });
  };

  const removeFile = (type: 'icon') => {
    formDispatch({
      type: 'UPLOAD_IMG',
      payload: {
        type,
        id: '',
        url: '',
        fileName: '',
        fileType: '',
      },
    });
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: `Remove ${type} file successfully`,
        },
      ]),
    );
  };

  const onSubmitForm = async (status: string) => {
    let { hasError } = onSubmitErrorValidator();

    if (!hasError) {
      const detail: MdrtStampIconDetail = {
        stampCode: formState.stampCode || '',
        stampName: formState.stampName || '',
        icon: formState.icon,
        status,
      };
      try {
        if (formMode === MdrtStampIconFormMode.CREATE) {
          await create(detail, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Saved successfully - ${detail.stampName}`,
              },
            ]),
          );
        } else {
          detail.id = itemId;
          await update(detail, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Updated successfully - ${detail.stampName}`,
              },
            ]),
          );
        }
        onRouteTo(`${MDRT_HUB_BASE_PATH}${MDRT_STAMP_ICON_BASE_PATH}`);
      } catch (err) {}
    }
  };

  const previewFile = ({ fileName, url }: FileUpload) => {
    return (
      <>
        <div className={classes.rowContainer} style={{ marginTop: 15 }}>
          <div className={classes.fieldContainer}></div>
          <div style={{ flexGrow: 1 }}>
            <div className={classes.rowContainer}>
              <div>
                <a href={url}>{fileName || ''}</a>
                {!isDisabled ? <Close className={classes.closeIcon} onClick={() => removeFile('icon')} /> : <></>}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>
              {Translation(formMode === MdrtStampIconFormMode.CREATE ? 'app.button.add' : 'app.button.edit')}
            </div>
          </div>
          <Button
            variant="contained"
            onClick={() => onRouteTo(`${MDRT_HUB_BASE_PATH}${MDRT_STAMP_ICON_BASE_PATH}/list`)}
          >
            Back
          </Button>
        </div>

        <div className={classes.sectionMargin}>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('mdrtHub.mdrtStampIcon.detail.code')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                style={{ width: '100%' }}
                disabled={true}
                error={errorState.mandatory.name}
                margin="dense"
                variant="outlined"
                value={formState.stampCode}
                inputProps={{ maxLength: 16 }}
              />
            </div>
          </div>
        </div>

        <div className={classes.sectionMargin}>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('mdrtHub.mdrtStampIcon.detail.name')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                style={{ width: '100%' }}
                disabled={isDisabled}
                margin="dense"
                variant="outlined"
                helperText={errorState.mandatory.stampName && MANDATORY_FIELD_ERROR_TEXT}
                value={formState.stampName}
                onChange={(e) => {
                  onDismissErrorHandler('stampName', e.target.value);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'stampName', value: e.target.value },
                  });
                }}
                inputProps={{ maxLength: 64 }}
              />
            </div>
          </div>
        </div>

        <div className={classes.sectionMargin}>
          {/* Icon Image */}
          <div className={classes.rowContainer} style={{ marginTop: 15 }}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('mdrtHub.mdrtStampIcon.detail.icon')}:</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <FileUploader
                color="secondary"
                disabled={isDisabled}
                showAllowText={formState.icon?.url ? '' : Translation('mdrtHub.common.imageMaximum')}
                upload={handleUploadFile}
                allowedFileTypes={['jpg', 'png', 'jpeg']}
                maxFileSize={5}
                btnTxt={formState.icon?.url ? Translation('app.button.update') : Translation('app.button.upload')}
                onChange={onChangeBannerFile}
              />
            </div>
          </div>
          {formState.icon?.url && previewFile(formState.icon)}
        </div>

        <div className={classes.footerContainer}>
          {isDisabled ? (
            <></>
          ) : (
            <>
              <Button variant="contained" color="secondary" onClick={() => onSubmitForm(PublishStatus.UNPUBLISHED)}>
                Save as Draft
              </Button>
              <Button
                style={{ marginLeft: 25 }}
                variant="contained"
                color="secondary"
                onClick={() => onSubmitForm(PublishStatus.PUBLISHED)}
              >
                Publish
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MdrtStampIconDetailForm;
