import React from 'react';
import { getDisplayDate } from 'src/app/common/utils/time-utils';
import { regionLocale } from 'src/app/i18n';

interface tableConfigType {
  title: string;
  prop: string;
  align?: any;
  render?: any;
  minWidth?: number;
}

const getNameFromI18nData = (code: string, row: any) => {
  const data = row.i18nDataList.find((item: any) => {
    return item.lang === code;
  });
  return data?.name;
};

const getNameColumns = (Translation: any) => {
  let namesColumns = regionLocale.map((code) => {
    const nameColumn = {
      title: `${Translation('event.ebirthday_card.table.header.title')} (${code})`,
      prop: `i18n_name_${code}`,
      render: (row: any) => <span>{getNameFromI18nData(code, row)}</span>,
    };
    return nameColumn;
  });
  return namesColumns;
};

const tableConfig = (Translation: any, i18ConfigData: any): tableConfigType[] => {
  if (i18ConfigData.length === 0) {
    return [];
  }
  const namesColumns = getNameColumns(Translation);
  return [
    ...namesColumns,
    {
      title: Translation('event.ebirthday_card.table.header.publishTime'),
      prop: 'publishTime',
      render: (row: any) => (
        <span>{row.publishTime ? getDisplayDate(row.publishTime, 'YYYY-MM-DD HH:mm:ss') : ''}</span>
      ),
    },
    {
      title: Translation('event.ebirthday_card.table.header.createTime'),
      prop: 'createTime',
      render: (row: any) => <span>{getDisplayDate(row.createTime, 'YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: Translation('event.ebirthday_card.table.header.modifyTime'),
      prop: 'modifyTime',
      render: (row: any) => <span>{getDisplayDate(row.modifyTime, 'YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: Translation('event.ebirthday_card.table.header.modifier'),
      prop: 'modifier',
    },
  ];
};

export default tableConfig;
