import React, { FC, useReducer, useState } from 'react';
import { useCommonStyles } from '../../../../../../common/styles/common-styles';
import { NEWS_BASE_PATH, NEWS_CATEGORY_PATH } from '../../../../constants';
import { makeStyles } from 'tss-react/mui';
import { Button, TextField, Dialog } from '@mui/material';
import { useErrorHandler, ErrorFieldType } from 'src/app/common/utils';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  NewsCategoryEditItem,
  NewsCategoryEmailItem,
  NewsCategoryFormMode,
  NewsCategoryItem,
} from '../../../../types/news-type';
import { MuiColorInput } from 'mui-color-input';
import { modifyNewsCategory } from '../../../../network/newsCrud';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';

type NewsCategoryDetailFormProps = {
  formMode: NewsCategoryFormMode;
  newsCategoryDetail?: NewsCategoryItem;
  code: string;
  onRouteTo: (route: string) => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    //marginTop: 30
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  header: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  fieldContainer: {
    width: 300,
    boxSizing: 'border-box',
  },
  noPaddingFieldContainer: {
    flexBasis: '20%',
    minWidth: 120,
    boxSizing: 'border-box',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  settingTable: {
    width: '50%',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 20,
  },
  numberInput: {
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

export type NewsCategoryFormState = {
  emails: NewsCategoryEmailItem[];
  color?: string;
  maxNoOfHeadlines: string;
  maxArchiveDays: string;
};

const initialState: NewsCategoryFormState = {
  emails: [],
  color: '',
  maxNoOfHeadlines: '1',
  maxArchiveDays: '90',
};

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof NewsCategoryFormState;
    value: any;
  };
};

type AddEmailsRowAction = {
  type: 'ADD_EMAIL_ROW';
};

type ModifyEmailsRowAction = {
  type: 'MODIFY_EMAIL_ROW';
  payload: {
    index: number;
    field: keyof NewsCategoryEmailItem;
    value: string;
  };
};

type DeleteEmailsRowAction = {
  type: 'DELETE_EMAIL_ROW';
  payload: {
    index: number;
  };
};

type NewsCategoryFormAction = ModifyFieldAction | ModifyEmailsRowAction | AddEmailsRowAction | DeleteEmailsRowAction;

const newsCategoryReducer = (state: NewsCategoryFormState, action: NewsCategoryFormAction) => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'ADD_EMAIL_ROW':
      return {
        ...state,
        emails: state.emails ? [...state.emails, { name: '', address: '' }] : [{ name: '', address: '' }],
      };
    case 'MODIFY_EMAIL_ROW':
      if (state.emails && state.emails[action.payload.index]) {
        let item = state.emails[action.payload.index];
        item[action.payload.field] = action.payload.value;
      }
      return { ...state };
    case 'DELETE_EMAIL_ROW':
      if (state.emails && state.emails[action.payload.index]) {
        let emailArr = state.emails;
        emailArr.splice(action.payload.index, 1);
      }
      return { ...state };
    default:
      return initialState;
  }
};

const detailToStateConvertor = (newsCategoryDetail?: NewsCategoryItem): NewsCategoryFormState => {
  return newsCategoryDetail
    ? {
        color: newsCategoryDetail.color,
        emails: newsCategoryDetail.emails,
        maxNoOfHeadlines:
          typeof newsCategoryDetail.maxNoOfHeadlines === 'number' ? newsCategoryDetail.maxNoOfHeadlines.toString() : '',
        maxArchiveDays:
          typeof newsCategoryDetail.maxNoOfHeadlines === 'number' ? newsCategoryDetail.maxArchiveDays.toString() : '',
      }
    : initialState;
};

export type NewsCategoryErrorState = {
  mandatory: {
    email: boolean;
    color: boolean;
    maxNoOfHeadlines: boolean;
    maxArchiveDays: boolean;
  };
  immediate: {
    emailFormat: boolean;
  };
};

function validatePositiveInteger(input: string) {
  const trimmedInput = String(input).trim();
  const regex = /^[1-9]\d*$/;
  const isValid = regex.test(trimmedInput);

  return isValid;
}

const NewsCategoryDetailForm: FC<NewsCategoryDetailFormProps> = ({ formMode, newsCategoryDetail, code, onRouteTo }) => {
  const dispatch = useDispatch();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);

  const [errorDialog, setErrorDialog] = useState({
    show: false,
    message: '',
  });
  const [formState, formDispatch] = useReducer(newsCategoryReducer, detailToStateConvertor(newsCategoryDetail));

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler<NewsCategoryErrorState>(
    formState,
    [
      {
        name: 'color',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'maxNoOfHeadlines',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => {
          return !validatePositiveInteger(formState.maxNoOfHeadlines);
        },
      },
      {
        name: 'maxArchiveDays',
        fieldType: ErrorFieldType.MANDATORY,
        condition: () => {
          return !validatePositiveInteger(formState.maxArchiveDays);
        },
      },
    ],
  );

  const onSubmit = async () => {
    let { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      // Call Api and submit
      const details: NewsCategoryEditItem = {
        color: formState.color,
        emails: formState.emails,
        maxNoOfHeadlines: parseInt(formState.maxNoOfHeadlines, 10),
        maxArchiveDays: parseInt(formState.maxArchiveDays, 10),
      };
      try {
        await modifyNewsCategory(code, details);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: Translation('newsCategory.success.update', {
                name: newsCategoryDetail?.name.enUs || '',
              }),
            },
          ]),
        );

        onRouteTo(`${NEWS_BASE_PATH}${NEWS_CATEGORY_PATH}`);
      } catch (err: any) {
        setErrorDialog((prev) => ({
          ...prev,
          show: true,
          title: '',
          message: err.errors.message,
          button: 'center',
        }));
      }
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>
              {`${Translation(`newsCategory.editMain`)} -  ${newsCategoryDetail?.name.enUs} (${
                newsCategoryDetail?.name.vi
              })`}
            </div>
          </div>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => {
              onRouteTo(`${NEWS_BASE_PATH}${NEWS_CATEGORY_PATH}`);
            }}
          >
            {Translation('app.button.back')}
          </Button>
        </div>
        <div className={classes.rowContainer} style={{ marginBottom: 15 }}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('newsCategory.common.color')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <MuiColorInput
            isAlphaHidden
            style={{ width: 200 }}
            name="color"
            margin="dense"
            variant="outlined"
            format="hex"
            error={errorState.mandatory.color}
            helperText={errorState.mandatory.color && Translation('field_mandatory_helper_label')}
            value={formState.color || ''}
            onChange={(color) => {
              onDismissErrorHandler('color', color);
              formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'color', value: color } });
            }}
          />
        </div>

        <div className={classes.rowContainer} style={{ marginBottom: 15 }}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('newsCategory.common.maxHeadLines')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <TextField
            style={{ width: 200 }}
            error={errorState.mandatory.maxNoOfHeadlines}
            margin="dense"
            variant="outlined"
            helperText={
              errorState.mandatory.maxNoOfHeadlines && Translation('newsCategory.error.mandatory.maxHeadLines')
            }
            inputProps={{
              className: classes.numberInput,
            }}
            type="number"
            value={formState.maxNoOfHeadlines}
            onChange={(e) => {
              onDismissErrorHandler('maxNoOfHeadlines', true);
              formDispatch({
                type: 'MODIFY_FIELD',
                payload: { field: 'maxNoOfHeadlines', value: e.target.value },
              });
            }}
          />
        </div>

        <div className={classes.rowContainer} style={{ marginBottom: 15 }}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {Translation('newsCategory.common.maxArchivedDays')}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <TextField
            style={{ width: 200 }}
            error={errorState.mandatory.maxArchiveDays}
            margin="dense"
            variant="outlined"
            helperText={
              errorState.mandatory.maxArchiveDays && Translation('newsCategory.error.mandatory.maxArchivedDays')
            }
            inputProps={{
              className: classes.numberInput,
            }}
            type="number"
            value={formState.maxArchiveDays}
            onChange={(e) => {
              onDismissErrorHandler('maxArchiveDays', true);
              formDispatch({
                type: 'MODIFY_FIELD',
                payload: { field: 'maxArchiveDays', value: e.target.value },
              });
            }}
          />
        </div>

        <div className={classes.footerContainer}>
          <Button variant="contained" color="secondary" onClick={onSubmit}>
            {Translation('app.button.submit')}
          </Button>
        </div>
      </div>

      {/** Error Dialog */}
      <Dialog
        onClose={() => {
          setErrorDialog((prev) => ({
            ...prev,
            show: false,
          }));
        }}
        open={errorDialog.show}
      >
        <div className={classes.container}>
          <div>{errorDialog.message}</div>
          <div className={classes.footer}>
            <Button
              variant="contained"
              color="inherit"
              onClick={() => {
                setErrorDialog((prev) => ({
                  ...prev,
                  show: false,
                }));
              }}
            >
              {Translation('global.text.okay')}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default NewsCategoryDetailForm;
