export const INCENTIVE_BASE_PATH = '/incentive';
export const INCENTIVE_BASE_PATH_V2 = '/incentive2';
export const INCENTIVE_INCENTIVE_PATH = '/incentive';
export const INCENTIVE_INCENTIVE_SET_PATH = '/set';
export const INCENTIVE_KPI_PATH = '/kpi';
export const INCENTIVE_UPLOAD_STATUS_PATH = '/upload-status';

export const NET_CASE_TEXT = 'Net Case';
export const NET_AFYP_TEXT = 'Total Net AFYP';

//  These constants map to aside menu, header menu to display title
export const INCENTIVE_TITLE = 'title.incentive';
export const INCENTIVE_SET_TITLE = 'title.incentive.set';
export const INCENTIVE_KPI_TITLE = 'title.incentive.kpi';
export const INCENTIVE_UPLOAD_STATUS_TITLE = 'title.incentive.upload_status';

//  These constants map to permission control, identical to permission enum defined below
export const INCENTIVE_NAMESPACE = 'INCENTIVE';
export const INCENTIVE_KPI_NAMESPACE = 'INCENTIVE-CRITERIA-CATEGORY';

export enum AdminIncentivePermissionType {
  INCENTIVE_READ = 'READ_ANY_INCENTIVE',
  INCENTIVE_CREATE = 'CREATE_ANY_INCENTIVE',
  INCENTIVE_UPDATE = 'UPDATE_ANY_INCENTIVE',
  INCENTIVE_SET_READ = 'READ_ANY_INCENTIVE-SET',
  INCENTIVE_SET_CREATE = 'CREATE_ANY_INCENTIVE-SET',
  INCENTIVE_SET_UPDATE = 'UPDATE_ANY_INCENTIVE-SET',
  INCENTIVE_KPI_READ = 'READ_ANY_INCENTIVE-CRITERIA-CATEGORY',
  INCENTIVE_KPI_CREATE = 'CREATE_ANY_INCENTIVE-CRITERIA-CATEGORY',
  INCENTIVE_KPI_UPDATE = 'UPDATE_ANY_INCENTIVE-CRITERIA-CATEGORY',
}
