import { apiClient, apiErrorHandler, ADMIN_URL } from 'src/app/common/network';
import { getQueryPath } from 'src/app/common/utils';
import { PaginateList } from '../../../common/types/common-types';
import { ReportDetail, ReportDownloadDetail, ReportItem } from '../types/report-types';
import { Dispatch } from 'react';

// PVA
import { ReportIncentiveRegistrationItem, ReportIncentiveRegistrationParams } from '../types/incentive-types';
import { PaginateListMore } from '../../../common/types/common-types';
import { forEach } from 'lodash';
import { getDayStart, getDayEnd } from 'src/app/common/utils';
import { ReportActiveUserItem, ReportAppInstallmentItem } from '../types/report-types';
import moment from 'moment';
import { ReportIncentiveRegistrationItemV2, ReportIncentiveRegistrationParamsV2 } from '../types/incentive-types-v2';

export type ReportActiveUserParams = {
  requestedDateFrom: Date | null;
  requestedDateTo: Date | null;
  agentCode?: string;
  branchCode?: string;
  officeCode?: string;
  role?: string;
  segment?: string;
  unitCode?: string;
  salesChannel?: string[];
  pagination?: boolean;
  page: number;
  limit: number;
};

export type ReportIAppInstallmentParams = {
  requestedDateFrom: Date;
  requestedDateTo: Date;
  unitCode?: string;
  branchCode?: string;
  officeCode?: string;
  agentCode?: string;
  salesChannel?: string[];
  eventType?: string;
  pagination?: boolean;
  page: number;
  limit: number;
};

export type ReportListParams = {
  requestedDateFrom: Date | null;
  requestedDateTo: Date | null;
  page: number;
  limit: number;
};

export const fetchProductionTargetReportList = async (
  params: ReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ReportItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/report/production-target?`, params);

  return apiClient
    .get<PaginateList<ReportItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchIncentiveResultReportList = async (
  params: ReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ReportItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/report/incentive-result?`, params);
  return apiClient
    .get<PaginateList<ReportItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchProductionTargetMTDReportList = async (
  params: ReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ReportItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/report/production-target/mtd?`, params);
  return apiClient
    .get<PaginateList<ReportItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchAdbuTargetReportList = async (
  params: ReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ReportItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/report/adbu-target?`, params);
  return apiClient
    .get<PaginateList<ReportItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchReportDownloadLink = async (
  code: string,
  dispatch?: Dispatch<any>,
): Promise<ReportDownloadDetail> => {
  return apiClient
    .get<ReportDownloadDetail>(`${ADMIN_URL}/report/${code}/download`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewProductionTargetReport = async (
  params: Record<string, unknown>,
  dispatch?: Dispatch<any>,
): Promise<ReportDetail> => {
  return apiClient
    .post<ReportDetail>(
      `${ADMIN_URL}/report/production-target`,
      {
        parameters: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewIncentiveResultReport = async (
  params: Record<string, unknown>,
  dispatch?: Dispatch<any>,
): Promise<ReportDetail> => {
  return apiClient
    .post<ReportDetail>(
      `${ADMIN_URL}/report/incentive-result`,
      {
        parameters: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewProductionTargetMTDReport = async (
  params: Record<string, unknown>,
  dispatch?: Dispatch<any>,
): Promise<ReportDetail> => {
  return apiClient
    .post<ReportDetail>(
      `${ADMIN_URL}/report/production-target/mtd`,
      {
        parameters: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewAdbuTargetReport = async (
  params: Record<string, unknown>,
  dispatch?: Dispatch<any>,
): Promise<ReportDetail> => {
  return apiClient
    .post<ReportDetail>(
      `${ADMIN_URL}/report/adbu-target`,
      {
        parameters: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewAgentsReport = async (
  params: Record<string, unknown>,
  dispatch?: Dispatch<any>,
): Promise<ReportDetail> => {
  return apiClient
    .post<ReportDetail>(
      `${ADMIN_URL}/news-report/news-agents`,
      {
        parameters: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNewsAgentReportList = async (
  params: ReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ReportItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/news-report/news-agents?`, params);
  return apiClient
    .get<PaginateList<ReportItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewCommentsReport = async (
  params: Record<string, unknown>,
  dispatch?: Dispatch<any>,
): Promise<ReportDetail> => {
  return apiClient
    .post<ReportDetail>(
      `${ADMIN_URL}/news-report/news-comments`,
      {
        parameters: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNewsCommentsReportList = async (
  params: ReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ReportItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/news-report/news-comments?`, params);
  return apiClient
    .get<PaginateList<ReportItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewInfoReport = async (
  params: Record<string, unknown>,
  dispatch?: Dispatch<any>,
): Promise<ReportDetail> => {
  return apiClient
    .post<ReportDetail>(
      `${ADMIN_URL}/news-report/news-info`,
      {
        parameters: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNewsInfoReportList = async (
  params: ReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ReportItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/news-report/news-info?`, params);
  return apiClient
    .get<PaginateList<ReportItem>>(queryPath)
    .then((response) => {
      debugger;
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createNewAgentActivityReport = async (
  params: Record<string, unknown>,
  dispatch?: Dispatch<any>,
): Promise<ReportDetail> => {
  return apiClient
    .post<ReportDetail>(
      `${ADMIN_URL}/news-report/news-activity`,
      {
        parameters: params,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNewsAgentActivityReportList = async (
  params: ReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ReportItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/news-report/news-activity?`, params);
  return apiClient
    .get<PaginateList<ReportItem>>(queryPath)
    .then((response) => {
      debugger;
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

//PVA
export const fetchIncentiveRegistrationReportList = async (
  params: ReportIncentiveRegistrationParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<ReportIncentiveRegistrationItem>> => {
  let uri = `${ADMIN_URL}/report/incentive-registration`;
  let data: any = {};
  const queryPath = getQueryPath(`${ADMIN_URL}/news-report/news-info?`, params);
  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.includes('From')) {
          data[key] = getDayStart(param).toISOString();
        }
        if (key.includes('To')) {
          data[key] = getDayEnd(param).toISOString();
        }
      }
    } else {
      param && (data[key] = param);
    }
  });

  return apiClient
    .post<PaginateListMore<ReportIncentiveRegistrationItem>>(uri, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchIncentiveRegistrationReportDownload = (
  params: ReportIncentiveRegistrationParams,
  dispatch?: Dispatch<any>,
): void => {
  let uri = `${ADMIN_URL}/report/incentive-registration/export`;
  let data: any = {};

  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.includes('From')) {
          data[key] = getDayStart(param).toISOString();
        }
        if (key.includes('To')) {
          data[key] = getDayEnd(param).toISOString();
        }
      }
    } else {
      param && (data[key] = param);
    }
  });

  apiClient
    .post<Blob>(uri, data, { responseType: 'text' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Incentive Registration Report_' + moment(new Date()).format('YYYYMMDD') + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchActiveUserReportList = async (
  params: ReportActiveUserParams,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ReportActiveUserItem>> => {
  let queryPath = `${ADMIN_URL}/report/perf-tracking/active-user?`;
  forEach(params, (param, key) => {
    if (param && typeof param === 'object') {
      if (key.includes('From')) {
        queryPath += `${key}=${moment(param).startOf('month').toDate().toISOString()}&`;
      }

      if (key.includes('To')) {
        queryPath += `${key}=${moment(param).endOf('month').toDate().toISOString()}&`;
      }

      if (Array.isArray(param)) {
        queryPath += param ? `${key}=${param.join(',')}&` : '';
      }
    } else {
      queryPath += param ? `${key}=${param}&` : '';
    }
  });

  // Query for sorting
  let sortingCount = 0;
  queryPath += `_sort=`;
  forEach(sortKeys, (sortParam) => {
    if (sortParam.value) {
      queryPath += `${sortingCount === 0 ? `` : `,`}${sortParam.key}:${sortParam.value}`;
      sortingCount++;
    }
  });
  if (sortingCount === 0) {
    queryPath += `createdAt:asc`;
  }

  return apiClient
    .get<PaginateList<ReportActiveUserItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchActiveUserReporttDownload = (params: ReportActiveUserParams, dispatch?: Dispatch<any>): void => {
  let queryPath = `${ADMIN_URL}/report/perf-tracking/active-user/export?`;
  forEach(params, (param, key) => {
    if (param && typeof param === 'object') {
      if (key.includes('From')) {
        queryPath += `${key}=${moment(param).startOf('month').toDate().toISOString()}&`;
      }

      if (key.includes('To')) {
        queryPath += `${key}=${moment(param).endOf('month').toDate().toISOString()}&`;
      }

      if (Array.isArray(param)) {
        queryPath += param ? `${key}=${param.join(',')}&` : '';
      }
    } else {
      queryPath += param ? `${key}=${param}&` : '';
    }
  });

  apiClient
    .get<Blob>(queryPath, { responseType: 'text' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'active-user-report' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchAppInstallmentReportList = async (
  params: ReportIAppInstallmentParams,
  sortKeys: { key: string; value?: string }[],
  dispatch?: Dispatch<any>,
): Promise<PaginateList<ReportAppInstallmentItem>> => {
  let queryPath = `${ADMIN_URL}/report/perf-tracking/app-installment?`;

  forEach(params, (param, key) => {
    if (typeof param === 'object' && param) {
      if (!Array.isArray(param)) {
        if (key.includes('From')) {
          queryPath += `${key}=${getDayStart(param).toISOString()}&`;
        }
        if (key.includes('To')) {
          queryPath += `${key}=${getDayEnd(param).toISOString()}&`;
        }
      }
      if (Array.isArray(param) && param.join('') !== 'ALL') {
        queryPath += param ? `${key}=${param.join(',')}&` : '';
      }
    } else {
      queryPath += param ? `${key}=${param}&` : '';
    }
  });

  // Query for sorting
  let sortingCount = 0;
  queryPath += `_sort=`;
  forEach(sortKeys, (sortParam) => {
    if (sortParam.value) {
      queryPath += `${sortingCount === 0 ? `` : `,`}${sortParam.key}:${sortParam.value}`;
      sortingCount++;
    }
  });
  if (sortingCount === 0) {
    queryPath += `updatedAt:desc`;
  }

  return apiClient
    .get<PaginateList<ReportAppInstallmentItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchAppInstallmentReportDownload = (
  params: ReportIAppInstallmentParams,
  dispatch?: Dispatch<any>,
): void => {
  let queryPath = `${ADMIN_URL}/report/perf-tracking/app-installment/export?`;
  forEach(params, (param, key) => {
    if (typeof param === 'object' && param) {
      if (!Array.isArray(param)) {
        if (key.includes('From')) {
          queryPath += `${key}=${getDayStart(param).toISOString()}&`;
        }
        if (key.includes('To')) {
          queryPath += `${key}=${getDayEnd(param).toISOString()}&`;
        }
      }
      if (Array.isArray(param) && param.join('') !== 'ALL') {
        queryPath += param ? `${key}=${param.join(',')}&` : '';
      }
    } else {
      queryPath += param ? `${key}=${param}&` : '';
    }
  });
  apiClient
    .get<Blob>(queryPath, { responseType: 'text' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'app-installment' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchIncentiveRegistrationReportListV2 = async (
  params: ReportIncentiveRegistrationParamsV2,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<ReportIncentiveRegistrationItemV2>> => {
  let uri = `${ADMIN_URL}/report/incentive-registration-v2`;
  let data: any = {};
  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.includes('From')) {
          data[key] = getDayStart(param).toISOString();
        }
        if (key.includes('To')) {
          data[key] = getDayEnd(param).toISOString();
        }
      }
    } else {
      param && (data[key] = param);
    }
  });

  return apiClient
    .post<PaginateListMore<ReportIncentiveRegistrationItemV2>>(uri, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchIncentiveRegistrationReportDownloadV2 = (
  params: ReportIncentiveRegistrationParamsV2,
  dispatch?: Dispatch<any>,
): void => {
  let uri = `${ADMIN_URL}/report/incentive-registration-v2/export`;
  let data: any = {};

  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.includes('From')) {
          data[key] = getDayStart(param).toISOString();
        }
        if (key.includes('To')) {
          data[key] = getDayEnd(param).toISOString();
        }
      }
    } else {
      param && (data[key] = param);
    }
  });

  apiClient
    .post<Blob>(uri, data, { responseType: 'text' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Incentive Registration Report_' + moment(new Date()).format('YYYYMMDD') + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
// End PVA
