import { forEach } from 'lodash';
import { PaginateListMore } from '../../../common/types/common-types';
import { Dispatch } from 'react';
import { apiClient, apiErrorHandler } from 'src/app/common/network';
import { CandidateAssessmentReportListParams } from '../types/candidateAssessmentReportTypes';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

const formatDataFilters = (params: CandidateAssessmentReportListParams) => {
  let data: any = {
    dataFilters: [],
    page: params.page,
    limit: params.limit,
  };
  forEach(params, (param, key) => {
    switch (key) {
      case 'requestedDateFrom':
        data.dataFilters.push({
          name: 'createdAt',
          value: param,
          type: 'Date',
          operator: 'gt',
        });
        break;
      case 'requestedDateTo':
        data.dataFilters.push({
          name: 'createdAt',
          value: param,
          type: 'Date',
          operator: 'lt',
        });
        break;
    }
  });
  return data;
};

export const fetchCandidateAssessmentReportDownload = (
  params: CandidateAssessmentReportListParams,
  dispatch?: Dispatch<any>,
): void => {
  params.limit = 999999999;
  const uri = `${ADMIN_URL}/report/candidate/onboarding/candidate-assessment/export`;
  const data = formatDataFilters(params);

  apiClient
    .post<Blob>(uri, data, { responseType: 'text' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report-candidate-assessment' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCandidateAssessmentReport = async (
  params: CandidateAssessmentReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<any>> => {
  const uri = `${ADMIN_URL}/report/candidate/onboarding/candidate-assessment`;
  const data = formatDataFilters(params);

  return apiClient
    .post<PaginateListMore<any>>(uri, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
