import React, { FC, Fragment } from 'react';
import { useIntl } from 'react-intl';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import './LibraryList.css';
import { LibraryItem, LibraryPaginateList } from 'src/app/modules/sales-kit/components/Library/types/library-types';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { renderDisplayStatusField } from 'src/app/modules/sales-kit/components/Library/utils/common-utils';

type LibraryListProps = {
  isLoading: boolean;
  libraryList?: LibraryPaginateList;
  libraryTableTitle: string;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (sortingState: { key: string; value?: string }) => void;
  defaultPageNumber?: number;
  onDownloadQR: (rowData: LibraryItem) => void;
  onEditLibrary: (rowData: LibraryItem, mode: string) => void;
  onPreviewLibrary: (rowData: LibraryItem) => void;
};

const LibraryList: FC<LibraryListProps> = ({
  isLoading,
  libraryList,
  libraryTableTitle,
  onRefresh,
  onChangePage,
  onSort,
  defaultPageNumber,
  onDownloadQR,
  onEditLibrary,
  onPreviewLibrary,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <Fragment>
      <PruTable
        title={Translation(libraryTableTitle)}
        defaultPageNumber={defaultPageNumber}
        operationDef={[
          {
            title: Translation('app.button.view'),
            tooltipText: Translation('app.button.view'),
            onClick: (row) => {
              onEditLibrary(row, 'view');
            },
          },
          {
            title: Translation('app.button.downloadQR'),
            tooltipText: Translation('app.button.downloadQR'),
            onClick: (row) => onDownloadQR(row),
            condition: (row) => !!row.eventInvitation,
          },
          {
            title: Translation('app.button.preview'),
            tooltipText: Translation('app.button.preview'),
            onClick: (row) => {
              onPreviewLibrary(row);
            },
          },
        ]}
        columnDef={[
          {
            isId: true,
            keyIndex: 'id',
            hidden: true,
            displayName: '',
            renderData: () => '',
          },
          {
            keyIndex: 'name',
            align: 'left',
            displayName: Translation('component.formLabel.name'),
            renderData: (row) => String(row.name ? row.name : ''),
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'name', value: sort['name'] });
            },
          },
          {
            keyIndex: 'status',
            align: 'left',
            displayName: Translation('component.formLabel.status'),
            renderData: (row) => (row ? Translation(renderDisplayStatusField(row)) : ''),
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'status', value: sort['status'] });
            },
          },
          {
            keyIndex: 'createdAt',
            align: 'left',
            displayName: Translation('component.formLabel.created-time'),
            renderData: (row) => (row.updatedAt ? getDefaultDisplayDate(new Date(row.createdAt), 'datetime') : ''),
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'createdAt', value: sort['createdAt'] });
            },
          },
          {
            keyIndex: 'updatedAt',
            align: 'left',
            displayName: Translation('component.formLabel.last-updated-time'),
            renderData: (row) => (row.updatedAt ? getDefaultDisplayDate(new Date(row.updatedAt), 'datetime') : ''),
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'updatedAt', value: sort['updatedAt'] });
            },
          },
          {
            keyIndex: 'lastUpdatedBy',
            align: 'left',
            displayName: Translation('component.formLabel.last-updated-by'),
            renderData: (row) => String(row.lastUpdatedBy ? row.lastUpdatedBy : ''),
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'lastUpdatedBy', value: sort['lastUpdatedBy'] });
            },
          },
          {
            keyIndex: 'effectiveDate',
            align: 'left',
            displayName: Translation('component.formLabel.effective-date-range-start'),
            sortable: true,
            onSort: (sort) => onSort({ key: 'effectiveDate', value: sort['effectiveDate'] }),
            renderData: (row) =>
              row.effectiveDate ? getDefaultDisplayDate(new Date(row.effectiveDate), 'datetime') : '',
          },
          {
            keyIndex: 'expiredDate',
            align: 'left',
            displayName: Translation('component.formLabel.effective-date-range-end'),
            sortable: true,
            onSort: (sort) => onSort({ key: 'expiredDate', value: sort['expiredDate'] }),
            renderData: (row) =>
              String(row.expiredDate ? getDefaultDisplayDate(new Date(row.expiredDate), 'datetime') : ''),
          },
        ]}
        defaultRowsPerPage={10}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={libraryList?.libraryList}
        totalPages={libraryList?.totalPages}
        totalRecords={libraryList?.totalRecords}
        onChangePage={onChangePage}
        disableBulkSelect
        hideBulkSelectHeader
      />
    </Fragment>
  );
};

export default LibraryList;
