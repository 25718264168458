import React, { FC, useCallback, useState, useEffect, useMemo, useRef, memo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import { get } from 'lodash';
import './weight-column.css';
import { LibraryColumnRefItem } from '../../../../types/library-types';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';
import { UpdateWeightModal } from './update-weight.modal';

/** table column: weight or column in the PruTable */
export const WeightColumn: FC<{
  rawData: any; // original table row data
  currentLanguage: string;
  columnName: 'weight' | 'column';
}> = memo(({ currentLanguage = 'en', columnName, rawData }) => {
  const [data, setData] = useState(rawData);
  const dataList: { arrColumn: string[]; arrWeight: number[] } = formatData(rawData.columnReferences, currentLanguage);

  const onSave = useCallback((data?: LibraryColumnRefItem[]) => {
    if (data) {
      setData({ columnReferences: data });
    }
  }, []);

  const onEdit = useCallback(async () => {
    ModalService.open({
      customHeader: <div />,
      children: (
        <UpdateWeightModal
          rawData={rawData}
          arrColumn={dataList.arrColumn}
          arrWeight={dataList.arrWeight}
          onSave={onSave}
        />
      ),
    });
  }, [rawData, dataList]);

  if (columnName === 'column') {
    return (
      <div className="tw-flex tw-justify-between">
        <span>{dataList.arrColumn.join(', ')}</span>
      </div>
    );
  }
  return (
    <div className="tw-flex tw-justify-between">
      <div className="tw-flex tw-justify-between tw-mr-1">
        <span>{dataList.arrWeight.join(', ')}</span>
      </div>

      {/* PVA not use */}
      {/* {dataList.arrWeight.length > 0 && <EditIcon fontSize="small" className="tw-cursor-pointer" onClick={onEdit} />} */}
    </div>
  );
});

function formatData(columnList: LibraryColumnRefItem[], currentLanguage: string) {
  let arrWeight: number[] = [];
  let arrColumn: string[] = [];
  if (!columnList || columnList.length < 1) {
    return { arrColumn, arrWeight };
  }
  let arrResult: { weight: number; column: string }[] = [];
  columnList.forEach((item: LibraryColumnRefItem) => {
    if (item && item.column) {
      arrResult.push({ column: get(item.column, `name.${currentLanguage}`, ''), weight: get(item, 'weight') });
    }
  });
  arrResult.sort((a, b) => b.weight - a.weight);

  arrResult.forEach((item) => {
    arrWeight.push(item.weight);
    arrColumn.push(item.column);
  });
  const res = { arrColumn, arrWeight };
  return res;
}
