import React, { FC, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { Button, TextField, CircularProgress } from '@mui/material';
import Form from 'src/app/common/components/Form';
import { ErrorFieldType, useErrorHandler } from '../../../../../../../common/utils/form-error-utils';
import { PublishStatus } from '../../../../../types/ebirthday-card-type';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  fieldContainer: {
    flex: '0 0 auto',
    width: 160,
    boxSizing: 'border-box',
    alignSelf: 'flex-start',
    paddingTop: 10,
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  errorText: {
    fontSize: 10,
    color: '#f018a6',
  },
}));

export type TitleItem = {
  name1?: string;
  name2?: string;
};

export type TitleFormState = {
  name1?: string;
  name2?: string;
};

type TitleFormAction = ModifyFieldAction;

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof TitleFormState;
    value: any;
  };
};

const initialState: TitleFormState = {
  name1: undefined,
  name2: undefined,
};

const titleFormReducer = (state: TitleFormState, action: TitleFormAction): TitleFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
  }
};

const detailToStateConvertor = (detail: TitleItem): TitleFormState => {
  return {
    name1: detail.name1,
    name2: detail.name2,
  };
};

const PopUp: FC<any> = (props) => {
  // eslint-disable-next-line
  const { disabled, visible, isView, titleId, initialValues, onCancel, onSave, i18ConfigData, confirmLoading } = props;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();
  const [form] = Form.useForm();
  const [formState, formDispatch] = useReducer(
    titleFormReducer,
    initialValues ? detailToStateConvertor(initialValues) : initialState,
  );

  const emptyValue = Translation('component.hint.empty-value-not-allowed');

  const isDisabled = false;

  const onSubmitForm = (mode: PublishStatus) => {
    let { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      onSave({
        name1: formState.name1,
        name2: formState.name2,
        status: mode,
      });
    }
  };

  const cancel = async () => {
    onCancel();
  };

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'name1',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'name2',
      fieldType: ErrorFieldType.MANDATORY,
    },
  ]);

  return (
    <>
      <Form form={form} initialValues={initialValues} className={classes.container}>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {`${Translation('event.ebirthday_card.table.header.title')} (${i18ConfigData[0]})`}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              disabled={isDisabled}
              error={errorState.mandatory.name1}
              margin="dense"
              variant="outlined"
              fullWidth
              value={formState.name1}
              onChange={(e) => {
                onDismissErrorHandler('name', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'name1', value: e.target.value } });
              }}
            />
            {errorState.mandatory.name1 && <div className={classes.errorText}>{emptyValue}</div>}
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {`${Translation('event.ebirthday_card.table.header.title')} (${i18ConfigData[1]})`}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              disabled={isDisabled}
              error={errorState.mandatory.name2}
              margin="dense"
              variant="outlined"
              fullWidth
              value={formState.name2}
              onChange={(e) => {
                onDismissErrorHandler('name', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'name2', value: e.target.value } });
              }}
            />
            {errorState.mandatory.name2 && <div className={classes.errorText}>{emptyValue}</div>}
          </div>
        </div>
        <div className={classes.footerContainer}>
          {!disabled ? (
            <>
              {/* todo: cancel warling */}
              <Button variant="contained" onClick={cancel}>
                {Translation('app.button.cancel')}
              </Button>
              <Button
                disabled={confirmLoading}
                type="submit"
                style={{ marginLeft: 20 }}
                variant="contained"
                color="secondary"
                onClick={() => onSubmitForm(PublishStatus.UNPUBLISHED)}
              >
                {Translation('app.button.save')}
                {confirmLoading && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
              </Button>
              <Button
                disabled={confirmLoading}
                type="submit"
                style={{ marginLeft: 20 }}
                variant="contained"
                color="secondary"
                onClick={() => onSubmitForm(PublishStatus.PUBLISHED)}
              >
                {Translation('component.formSelectItem.save-publish')}
                {confirmLoading && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
              </Button>
            </>
          ) : null}
        </div>
      </Form>
    </>
  );
};

export default PopUp;
