import { forEach } from 'lodash';
import { PaginateListMore } from '../../../common/types/common-types';
import { apiClient, apiErrorHandler } from 'src/app/common/network';
import { Dispatch } from 'react';
import { ReportListParams, ReportItem } from '../types/conversion-rate-report-types';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

const formatDataFilters = (params: ReportListParams) => {
  let data: any = {
    dataFilters: [],
    page: params.page,
    limit: params.limit,
  };
  let agentInfo = {
    name: 'agentCode',
    value: '',
    type: 'String',
    operator: 'eq',
  };
  forEach(params, (param, key) => {
    if (param) {
      switch (key) {
        case 'registerDateFrom':
          data.dataFilters.push({
            name: 'createdAt',
            value: param,
            type: 'Date',
            operator: 'gt',
          });
          break;
        case 'registerDateTo':
          data.dataFilters.push({
            name: 'createdAt',
            value: param,
            type: 'Date',
            operator: 'lt',
          });
          break;
        case 'agentInfoType':
          agentInfo.name = String(param);
          break;
        case 'agentInfoValue':
          agentInfo.value = String(param);
          break;
        case 'landingPageName':
          data.dataFilters.push({
            name: 'materialName',
            value: param,
            type: 'String',
            operator: 'search',
          });
          break;
      }
    }
  });
  if (agentInfo.value.trim() !== '') {
    data.dataFilters.push(agentInfo);
  }
  return data;
};

export const fetchReportDownload = (params: ReportListParams, dispatch?: Dispatch<any>): void => {
  const uri = `${ADMIN_URL}/report/conversion-rate/export`;
  const data = formatDataFilters(params);

  apiClient
    .post<Blob>(uri, data, { responseType: 'text' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report-conversion-rate' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchConversionRateReport = async (
  params: ReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<ReportItem>> => {
  const uri = `${ADMIN_URL}/report/conversion-rate`;
  const data = formatDataFilters(params);

  return apiClient
    .post<PaginateListMore<ReportItem>>(uri, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
