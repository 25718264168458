import React, { FC, useState, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { map } from 'lodash';
import { Button, TextField, FormControlLabel, Checkbox, CircularProgress } from '@mui/material';
import Form from 'src/app/common/components/Form';
import { ErrorFieldType, useErrorHandler } from '../../../../../../../common/utils/form-error-utils';
import { PublishStatus } from '../../../../../types/ebirthday-card-type';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  container: {
    padding: 20,
  },
  fieldContainer: {
    flex: '0 0 auto',
    width: 160,
    boxSizing: 'border-box',
    alignSelf: 'flex-start',
    paddingTop: 10,
  },
  field: {
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  errorText: {
    fontSize: 10,
    color: '#f018a6',
  },
  note: {
    fontSize: 12,
    color: '#777',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 80,
  },
}));

export type ContentItem = {
  content1?: string;
  content2?: string;
};

export type ContentFormState = {
  content1?: string;
  content2?: string;
};

type ContentFormAction = ModifyFieldAction;

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof ContentFormState;
    value: any;
  };
};

const initialState: ContentFormState = {
  content1: undefined,
  content2: undefined,
};

const contentFormReducer = (state: ContentFormState, action: ContentFormAction): ContentFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
  }
};

const detailToStateConvertor = (detail: ContentItem): ContentFormState => {
  return {
    content1: detail.content1,
    content2: detail.content2,
  };
};

const PopUp: FC<any> = (props) => {
  // eslint-disable-next-line
  const {
    disabled,
    visible,
    isView,
    contentId,
    initialValues,
    onCancel,
    onSave,
    i18ConfigData,
    titleList,
    confirmLoading,
  } = props;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();
  const [form] = Form.useForm();
  const [formState, formDispatch] = useReducer(
    contentFormReducer,
    initialValues ? detailToStateConvertor(initialValues) : initialState,
  );
  const [isEarly, setIsEarly] = useState<any>(initialValues.isEarly);
  const [isBelated, setIsBelated] = useState<any>(initialValues.isBelated);
  const [titleSelected, setTitleSelected] = useState<any>(initialValues.titleList || []);
  const [isTitleError, setTitleError] = useState<any>(false);
  const [isBirthdaywishedError, setBirthdaywishedError] = useState<any>(false);

  const emptyValue = Translation('component.hint.empty-value-not-allowed');

  const isDisabled = false;

  const onSubmitForm = (mode: PublishStatus) => {
    let hasTitleError = false;
    let hasBirthdaywishedError = false;

    if (!titleSelected || titleSelected.length == 0) {
      hasTitleError = true;
    }
    if (!isEarly && !isBelated) {
      hasBirthdaywishedError = true;
    }

    setBirthdaywishedError(hasBirthdaywishedError);
    setTitleError(hasTitleError);

    let { hasError } = onSubmitErrorValidator();

    if (!hasError && !hasTitleError && !hasBirthdaywishedError) {
      onSave({
        content1: formState.content1,
        content2: formState.content2,
        isEarly: isEarly,
        isBelated: isBelated,
        titleList: titleSelected,
        status: mode,
      });
    }
  };

  const cancel = async () => {
    onCancel();
  };

  const toggleEarly = (isSelected: boolean) => {
    setIsEarly(isSelected);
  };

  const toggleBelated = (isSelected: boolean) => {
    setIsBelated(isSelected);
  };

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'content1',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'content2',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'content2',
      fieldType: ErrorFieldType.MANDATORY,
    },
  ]);

  return (
    <>
      <Form form={form} initialValues={initialValues} className={classes.container}>
        <div className={`${classes.rowContainer}`}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>{Translation('event.ebirthday_card.table.header.title')}:</span>
          </div>
          <div>
            {map(titleList, (option: any, i: number) => {
              if (option.value != '') {
                return (
                  <FormControlLabel
                    key={`title-list-${i}`}
                    style={{ flexBasis: '33.3333%' }}
                    control={
                      <Checkbox
                        key={i}
                        checked={titleSelected.includes(option.value)}
                        onChange={(e) => {
                          let titleSelectedList = [...titleSelected];
                          if (!e.target.checked) {
                            titleSelectedList = titleSelectedList.filter((k) => k !== option.value);
                          } else {
                            titleSelectedList.push(option.value);
                          }
                          form.setFieldsValue({ titleList: titleSelectedList });
                          setTitleSelected(titleSelectedList);
                        }}
                      />
                    }
                    value={option.value}
                    label={option.label}
                    labelPlacement="end"
                  />
                );
              }
            })}
            {isTitleError && <div className={classes.errorText}>{emptyValue}</div>}
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}></span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <div className={classes.note}>{Translation('event.ebirthday_card.content.detail.note.format')}</div>
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {`${Translation('event.ebirthday_card.table.header.content')} (${i18ConfigData[0]})`}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              disabled={isDisabled}
              error={errorState.mandatory.content1}
              margin="dense"
              variant="outlined"
              InputProps={{
                classes: {
                  input: classes.textArea,
                },
              }}
              fullWidth
              multiline
              value={formState.content1}
              onChange={(e) => {
                onDismissErrorHandler('name', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'content1', value: e.target.value } });
              }}
              inputProps={{ maxLength: 320 }}
            />
            {errorState.mandatory.content1 && <div className={classes.errorText}>{emptyValue}</div>}
          </div>
        </div>
        <div className={classes.rowContainer}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>
              {`${Translation('event.ebirthday_card.table.header.content')} (${i18ConfigData[1]})`}
              <span className={classes.mandatory}>*</span> :
            </span>
          </div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              disabled={isDisabled}
              error={errorState.mandatory.content2}
              margin="dense"
              variant="outlined"
              InputProps={{
                classes: {
                  input: classes.textArea,
                },
              }}
              fullWidth
              multiline
              value={formState.content2}
              onChange={(e) => {
                onDismissErrorHandler('name', e.target.value);
                formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'content2', value: e.target.value } });
              }}
              inputProps={{ maxLength: 320 }}
            />
            {errorState.mandatory.content2 && <div className={classes.errorText}>{emptyValue}</div>}
          </div>
        </div>
        <div className={`${classes.rowContainer}`}>
          <div className={classes.fieldContainer}>
            <span className={classes.field}>{Translation('event.ebirthday_card.content.detail.birthday_wishes')}:</span>
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isEarly}
                  onChange={(e) => {
                    form.setFieldsValue({ isEarly: e.target.checked });
                    toggleEarly(e.target.checked);
                  }}
                />
              }
              label={Translation('event.ebirthday_card.content.detail.early')}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isBelated}
                  onChange={(e) => {
                    form.setFieldsValue({ isBelated: e.target.checked });
                    toggleBelated(e.target.checked);
                  }}
                />
              }
              label={Translation('event.ebirthday_card.content.detail.balated')}
              labelPlacement="end"
            />
            {isBirthdaywishedError && <div className={classes.errorText}>{emptyValue}</div>}
          </div>
        </div>
        <div className={classes.footerContainer}>
          {!disabled ? (
            <>
              {/* todo: cancel warling */}
              <Button variant="contained" onClick={cancel}>
                {Translation('app.button.cancel')}
              </Button>
              <Button
                disabled={confirmLoading}
                type="submit"
                style={{ marginLeft: 20 }}
                variant="contained"
                color="secondary"
                onClick={() => onSubmitForm(PublishStatus.UNPUBLISHED)}
              >
                {Translation('app.button.save')}
                {confirmLoading && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
              </Button>
              <Button
                disabled={confirmLoading}
                type="submit"
                style={{ marginLeft: 20 }}
                variant="contained"
                color="secondary"
                onClick={() => onSubmitForm(PublishStatus.PUBLISHED)}
              >
                {Translation('component.formSelectItem.save-publish')}
                {confirmLoading && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
              </Button>
            </>
          ) : null}
        </div>
      </Form>
    </>
  );
};

export default PopUp;
