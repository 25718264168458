import { forEach } from 'lodash';
import { Dispatch } from 'react';
import { PaginateList } from '../../../common/types/common-types';
import { getDayStart, getDayEnd } from 'src/app/common/utils/time-utils';
import { QuotesAndCertificateDetail, QuotesAndCertificateItem } from '../types/quotes-and-certificate-types';
import { apiClient, apiErrorHandler } from 'src/app/common/network';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_MDRT']}/mdrt-hub`;
// const ADMIN_URL = `http://localhost:4111/mdrt-hub`;

export type QuotesAndCertificateListParams = {
  agentCode: string;
  page: number;
  limit: number;
};

export const create = async (
  data: QuotesAndCertificateDetail,
  dispatch?: Dispatch<any>,
): Promise<QuotesAndCertificateDetail> => {
  return apiClient
    .post<QuotesAndCertificateDetail>(`${ADMIN_URL}/quotes-certificate/add`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const update = async (
  data: QuotesAndCertificateDetail,
  dispatch?: Dispatch<any>,
): Promise<QuotesAndCertificateDetail> => {
  return apiClient
    .post<QuotesAndCertificateDetail>(`${ADMIN_URL}/quotes-certificate/add`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchItem = async (id: string, dispatch?: Dispatch<any>): Promise<QuotesAndCertificateDetail> => {
  return apiClient
    .get<QuotesAndCertificateDetail>(`${ADMIN_URL}/quotes-certificate/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchList = async (
  params: QuotesAndCertificateListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<QuotesAndCertificateItem>> => {
  let queryPath = `${ADMIN_URL}/quotes-certificate/list?`;

  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.includes('From')) {
          queryPath += `${key}=${getDayStart(param).toISOString()}&`;
        }
        if (key.includes('To')) {
          queryPath += `${key}=${getDayEnd(param).toISOString()}&`;
        }
      }
    } else {
      queryPath += param ? `${key}=${param}&` : '';
    }
  });

  return apiClient
    .get<PaginateList<QuotesAndCertificateItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const publish = async (id: string, dispatch?: Dispatch<any>): Promise<QuotesAndCertificateDetail> => {
  return apiClient
    .get<QuotesAndCertificateDetail>(`${ADMIN_URL}/quotes-certificate/publish/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const unpublish = async (id: string, dispatch?: Dispatch<any>): Promise<QuotesAndCertificateDetail> => {
  return apiClient
    .get<QuotesAndCertificateDetail>(`${ADMIN_URL}/quotes-certificate/unpublish/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteQuotesCertificate = async (
  id: string,
  dispatch?: Dispatch<any>,
): Promise<QuotesAndCertificateDetail> => {
  return apiClient
    .delete<QuotesAndCertificateDetail>(`${ADMIN_URL}/quotes-certificate/delete/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getCertificateByAgentCode = async (agentCode: string, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get<QuotesAndCertificateDetail>(`${ADMIN_URL}/quotes-certificate/detail`, { params: { agentCode } })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
