import React, { useEffect } from 'react';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import AddTags from '../Tag';
import { makeStyles } from 'tss-react/mui';
import { LinkType } from 'src/app/modules/sales-kit/components/Library/constants';
import Form from 'src/app/common/components/Form';

const TranslationWithVariable = (key: string, count: number | string) =>
  useIntl().formatMessage({ id: key }, { count });

const useStyles = makeStyles()((theme) => ({
  textArea: {
    lineHeight: 1.5,
  },
  container: {
    '& .pru-form-item': {
      margin: '10px 0',
    },
    '& .MuiFormControl-root': {
      margin: 0,
    },
  },
}));

interface FormListProp {
  disabled: boolean;
  activeLang: string;
  form: any;
  eventInvitation: boolean;
  nameMaxSize?: any;
  descriptMaxSize?: any;
  addressMaxSize?: any;
}

const SubFormList: React.FC<FormListProp> = (props) => {
  const { classes } = useStyles();
  const { activeLang, disabled, nameMaxSize, descriptMaxSize, form, eventInvitation, addressMaxSize } = props;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // validate rules
  const DEFAULT_REQUIRED_RULES = {
    required: true,
    message: Translation('component.form-required-text'),
  };

  const DEFAULT_NOT_REQUIRED_RULES = [{ required: false }];

  const getInputMaxRules = (count: number) => {
    const errMessage = TranslationWithVariable('resource.form-max-characters-input-text', count);

    return {
      validator: async (_: any, inputString: string) => {
        // const re = /[\u4E00-\u9FA5]/g;
        const re = new RegExp(/[\u4E00-\u9FA5]/, 'g');
        let stringLength = inputString.length;
        const chineseCount = (inputString.match(re) || '').length;
        stringLength = stringLength + chineseCount;

        if (stringLength <= count) {
          return Promise.resolve();
        } else {
          throw new Error(errMessage);
        }
      },
    };
  };

  const placeEnter = Translation('app.input.placeholder.please-enter');

  useEffect(() => {
    form.setFieldsValue({
      [activeLang]: {
        linkType: LinkType.Url,
        link: '',
        title: '',
        information: '',
        body: '',
        videos: null,
      },
    });
  }, [eventInvitation]);

  return (
    <div className={classes.container}>
      <Form.Item
        childStyle={{ marginTop: 16, height: 32 }}
        name={[activeLang, 'name']}
        label={Translation('component.formLabel.name')}
        rules={[DEFAULT_REQUIRED_RULES, getInputMaxRules(nameMaxSize)]}
      >
        <TextField disabled={disabled} margin="dense" variant="outlined" fullWidth placeholder={placeEnter} />
      </Form.Item>
      <Form.Item
        childStyle={{ marginTop: 16 }}
        name={[activeLang, 'introduction']}
        label={Translation('component.formLabel.coverpage-introduction')}
        rules={[DEFAULT_REQUIRED_RULES, getInputMaxRules(descriptMaxSize)]}
      >
        <TextField
          disabled={disabled}
          margin="dense"
          variant="outlined"
          fullWidth
          multiline
          placeholder={placeEnter}
          InputProps={{
            classes: {
              input: classes.textArea,
            },
          }}
        />
      </Form.Item>
      <Form.Item
        childStyle={{ marginTop: 16, height: 32 }}
        required={false}
        name={[activeLang, 'tag']}
        label={Translation('component.formLabel.tags')}
        rules={DEFAULT_NOT_REQUIRED_RULES}
      >
        <AddTags disabled={disabled} btnTxt={Translation('component.tag-list.add-btn')} />
      </Form.Item>

      <Form.Item
        rules={[DEFAULT_REQUIRED_RULES, getInputMaxRules(addressMaxSize)]}
        childStyle={{ marginTop: 16, height: 32 }}
        name={[activeLang, 'eventLocation']}
        label={Translation('component.formLabel.location')}
      >
        <TextField
          disabled={disabled}
          margin="dense"
          variant="outlined"
          fullWidth
          placeholder={placeEnter}
          inputProps={{ maxLength: 100 }}
        />
      </Form.Item>

      <Form.Item
        rules={[DEFAULT_REQUIRED_RULES, getInputMaxRules(addressMaxSize)]}
        childStyle={{ marginTop: 16, height: 32 }}
        name={[activeLang, 'eventAddress']}
        label={Translation('component.formLabel.address')}
      >
        <TextField disabled={disabled} margin="dense" variant="outlined" fullWidth placeholder={placeEnter} />
      </Form.Item>
    </div>
  );
};

export default SubFormList;
