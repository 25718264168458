import { localeMapping } from 'src/app/common/components/survey-v2/survey-utils';
import { nameElements, emailElement, phoneElement } from 'src/app/common/components/event-survey-creator/constants';
import { updateContactElement } from 'src/app/common/components/event-survey-creator/util/creator.util';
import { RegistrationNotificationMethodEnum, EventItem } from 'src/app/modules/event-v2/types';

export const getDefaultWalkInFormBody = (eventItem: EventItem) => {
  if (!eventItem.walkInFormBody) {
    const config = {
      title: localeMapping(eventItem.name),
      description: {
        default: 'Walk-in Form',
        en: 'Walk-in Form',
      },
      completedHtml: {
        default: '<h3>Thank you! </h3> We have sent the registration confirmation to the contact you provided.',
        en: '<h3>Thank you! </h3> We have sent the registration confirmation to the contact you provided.',
      },
      showQuestionNumbers: 'off',
      questionErrorLocation: 'bottom',
    };
    const pages = [
      {
        name: 'page1',
        elements: [
          {
            type: 'boolean',
            name: 'registrant_role',
            title: {
              default: 'Are you a Prudential agent?',
              en: 'Are you a Prudential agent?',
            },
            isRequired: true,
            requiredErrorText: {
              default: 'Field is required',
              en: 'Field is required',
            },
          },
          {
            type: 'text',
            name: 'registrant_agentCode',
            visibleIf: '{registrant_role} = true',
            title: {
              default: 'Agent Code',
              en: 'Agent Code',
            },
            isRequired: true,
            requiredErrorText: {
              default: 'Field is required',
              en: 'Field is required',
            },
          },
          {
            type: 'text',
            name: 'registrant_referredAgentCode',
            visibleIf: '{registrant_role} = false',
            title: {
              default: 'Referred Agent Code (if have)',
              en: 'Referred Agent Code (if have)',
            },
          },
          ...nameElements.map((element) => ({
            ...element,
            visibleIf: '{registrant_role} = false',
          })),
          eventItem.regNotificationMethod === RegistrationNotificationMethodEnum.SMS ? phoneElement : emailElement,
        ],
      },
    ];
    const walkInFormBody = { ...config, pages };
    return walkInFormBody;
  } else {
    return {
      ...eventItem.walkInFormBody,
      pages: eventItem.walkInFormBody.pages.map((page: any) => {
        if (page.name === 'page1') {
          return updateContactElement(page, eventItem);
        }
        return page;
      }),
    };
  }
};
