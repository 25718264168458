import { forEach } from 'lodash';
import { Dispatch } from 'react';
import { PaginateList } from '../../../common/types/common-types';
import { getDayStart, getDayEnd } from 'src/app/common/utils/time-utils';
import { QuestionItem, EAnswerOptions, CategoryResponse, ReplyQuestionRequest } from '../types/qa-income-types';
import { apiClient, apiErrorHandler } from 'src/app/common/network';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;
// const ADMIN_URL = `http://localhost:1111`;

export type QuestionListParams = {
  agentCode: string;
  category: string;
  fromDate: Date | null;
  toDate: Date | null;
  page: number;
  limit: number;
  answer: EAnswerOptions;
};

export const replyQuestion = async (data: ReplyQuestionRequest, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .post<any>(`${ADMIN_URL}/faq/question/reply`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response: any) => response.data)
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchQuestionList = async (
  params: QuestionListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<QuestionItem>> => {
  let queryPath = `${ADMIN_URL}/faq/questions?`;

  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.includes('from')) {
          queryPath += `${key}=${getDayStart(param).toISOString()}&`;
        }
        if (key.includes('to')) {
          queryPath += `${key}=${getDayEnd(param).toISOString()}&`;
        }
      }
    } else {
      queryPath += param ? `${key}=${param}&` : '';
    }
  });

  return apiClient
    .get<PaginateList<QuestionItem>>(queryPath)
    .then((response: any) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchQuestionListDownload = (params: any, dispatch?: Dispatch<any>): void => {
  const uri = `${ADMIN_URL}/faq/questions/export`;

  apiClient
    .get<Blob>(uri, { responseType: 'text', params: params })
    .then((response: any) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'agent-income-Q&A-DCOM_' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCategoryList = async (dispatch?: Dispatch<any>): Promise<CategoryResponse> => {
  return apiClient
    .get<CategoryResponse>(`${ADMIN_URL}/faq/categories`)
    .then((response) => response.data)
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchQuestionItem = async (id: string, dispatch?: Dispatch<any>): Promise<QuestionItem> => {
  return apiClient
    .get<QuestionItem>(`${ADMIN_URL}/mdrt/${id}`)
    .then((response) => response.data)
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};
