import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { PaginateList } from 'src/app/common/types/common-types';
import { useDataProvider } from 'src/app/common/utils';
import { fetchList, MdrtStampIconListParams } from '../../../network/mdrtStampIconCrud';
import { MdrtStampIconItem } from '../../../types/mdrt-stamp-icon-types';
import MdrtStampIconList from './components/MdrtStampIconList';

type MdrtListingPageProps = {} & ParamsProps;

const initialState: MdrtStampIconListParams = {
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): MdrtStampIconListParams => {
  return initialParams
    ? {
        page: 1,
        limit: 5,
      }
    : initialState;
};

const MdrtStampIconListingPage: FC<MdrtListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<MdrtStampIconListParams>(paramsInitiator(initialParams));
  const [itemList, setItemList] = useState<PaginateList<MdrtStampIconItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<MdrtStampIconItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchList(filterState, dispatch);
      } catch (err) {}
    },
    setItemList,
    false,
  );

  return (
    <>
      <MdrtStampIconList
        isLoading={isLoading}
        onRefresh={refreshData}
        itemList={itemList}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default MdrtStampIconListingPage;
