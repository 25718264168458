import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { getDisplayDate } from '../../../../../../common/utils/time-utils';
import { PaginateList } from '../../../../../../common/types/common-types';
import { useIntl } from 'react-intl';
import PruTable from '../../../../../../common/components/PruTable/PruTable';
import { useHistory } from 'react-router-dom';
import {
  QuotesAndCertificateFormMode,
  QuotesAndCertificateItem,
  PublishStatus,
} from '../../../../types/quotes-and-certificate-types';
import { deleteQuotesCertificate, publish, unpublish } from '../../../../network/quotesAndCertificateCrud';
import { MDRT_HUB_BASE_PATH, QUOTES_AND_CERTIFICATE_BASE_PATH } from 'src/app/modules/MdrtHub/constants';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';

type QuotesAndCertificateListProps = {
  isLoading: boolean;
  itemList?: PaginateList<QuotesAndCertificateItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const QuotesAndCertificateList: FC<QuotesAndCertificateListProps> = ({
  isLoading,
  itemList,
  onRefresh,
  onChangePage,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onDelete = async (id: string) => {
    try {
      await deleteQuotesCertificate(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Deleted successfully - ${id}`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  const onPublish = async (item: QuotesAndCertificateItem) => {
    try {
      await publish(item.id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Published successfully - ${item.id}`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  const onUnpublish = async (item: QuotesAndCertificateItem) => {
    try {
      await unpublish(item.id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Unoublished successfully - ${item.id}`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  return (
    <>
      <ItemDeleteDialog
        key={`delete-sla-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
      />
      <PruTable
        title={Translation('mdrtHub.quotesAndCertificate.listing')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('mdrtHub.quotesAndCertificate.newConfig'),
            onClick: () =>
              history.push(`${MDRT_HUB_BASE_PATH}${QUOTES_AND_CERTIFICATE_BASE_PATH}/create`, {
                type: QuotesAndCertificateFormMode.CREATE,
              }),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('app.button.view'),
            tooltipText: 'View Quotes and certificate',
            onClick: (row) =>
              history.push(`${MDRT_HUB_BASE_PATH}${QUOTES_AND_CERTIFICATE_BASE_PATH}/edit/${row.id}`, {
                type: QuotesAndCertificateFormMode.VIEW,
              }),
          },
          {
            title: Translation('app.button.edit'),
            tooltipText: 'Edit Quotes and certificate',
            onClick: (row) =>
              history.push(`${MDRT_HUB_BASE_PATH}${QUOTES_AND_CERTIFICATE_BASE_PATH}/edit/${row.id}`, {
                type: QuotesAndCertificateFormMode.EDIT,
              }),
            condition: (row) => row.status !== PublishStatus.PUBLISHED,
          },
          {
            title: Translation('app.button.publish'),
            tooltipText: 'Publish',
            onClick: (row) => onPublish(row),
            condition: (row) => row.status !== PublishStatus.PUBLISHED,
          },
          {
            title: Translation('app.button.unpublish'),
            tooltipText: 'Unpublish',
            onClick: (row) => onUnpublish(row),
            condition: (row) => row.status === PublishStatus.PUBLISHED,
          },
          {
            title: Translation('app.button.delete'),
            tooltipText: 'Delete',
            condition: (row) => row.status !== PublishStatus.PUBLISHED,
            onClick: (row) => setDialogState({ open: true, id: row.id }),
          },
        ]}
        columnDef={[
          {
            keyIndex: 'agentCode',
            displayName: Translation('mdrtHub.quotesAndCertificate.list.agentCode'),
            renderData: (row) => row.agentCode || '',
          },
          {
            keyIndex: 'lastUpdatedBy',
            align: 'center',
            displayName: Translation('mdrtHub.quotesAndCertificate.list.lastUpdatedBy'),
            renderData: (row) => row.lastUpdatedBy || '',
          },
          {
            keyIndex: 'lastUpdatedDate',
            align: 'center',
            displayName: Translation('mdrtHub.quotesAndCertificate.list.lastUpdatedDate'),
            renderData: (row) => getDisplayDate(new Date(row.updatedAt), 'YYYY-MM-DD HH:mm:ss'),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={itemList?.items}
        totalPages={itemList?.totalPages}
        totalRecords={itemList?.total}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default QuotesAndCertificateList;
