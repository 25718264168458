import React from 'react';
import { Button, IconButton, Tooltip } from '@mui/material';
import { Add, Refresh } from '@mui/icons-material';

const tableHeader = (Translation: any, openPopUp: any, refreshData: any) => [
  [
    { type: 'header', label: Translation('title.event.greeting_card.title.list'), sm: true },
    {
      type: 'custom',
      sm: false,
      render: () => (
        <Tooltip title="Refresh">
          <IconButton style={{ marginRight: 15 }} onClick={refreshData}>
            <Refresh />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      type: 'custom',
      sm: false,
      render: () => (
        <Button variant="contained" color="secondary" onClick={openPopUp}>
          <Add />
          {Translation('app.button.add')}
        </Button>
      ),
    },
  ],
];

export default tableHeader;
