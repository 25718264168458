import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
// import AsyncCsvLink from '../../../../../common/components/AsyncCsvLink';
import { getDisplayDate } from '../../../../../common/utils/time-utils';
import { PaginateList } from '../../../../../common/types/common-types';
import { useIntl } from 'react-intl';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import { useHistory } from 'react-router-dom';
import { QuestionItem } from '../../../types/qa-income-types';
import { QA_INCOME_BASE_PATH } from '../../../constants';
import { Checkbox, Button } from '@mui/material';
import { last } from 'lodash';
import { fetchQuestionListDownload } from '../../../network/qaIncomeCrud';

type QuestionListProps = {
  isLoading: boolean;
  questionList?: PaginateList<QuestionItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSelectAllQuestion: () => void;
  onSelectQuestion: (item: QuestionItem) => void;
  viewQuestion: (item: QuestionItem) => void;
  isSelectAll: boolean;
  isDisplayReplySlectedQuestionButton: boolean;
  onReplySelectedQuestion: () => void;
  onReplyQuestion: (item: QuestionItem) => void;
  filterState: any;
};

const QAIncomeList: FC<QuestionListProps> = ({
  isLoading,
  questionList,
  onRefresh,
  onChangePage,
  onSelectAllQuestion,
  onSelectQuestion,
  viewQuestion,
  onReplySelectedQuestion,
  onReplyQuestion,
  isSelectAll,
  isDisplayReplySlectedQuestionButton,
  filterState,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const replySelectedQuestion = () => {
    onReplySelectedQuestion();
  };

  const exportQuestionList = () => {
    try {
      fetchQuestionListDownload(filterState, dispatch);
    } catch (err) {}
  };

  return (
    <>
      {isDisplayReplySlectedQuestionButton ? (
        <Button
          type="submit"
          style={{ marginLeft: 20, float: 'right', marginBottom: '10px' }}
          variant="contained"
          color="secondary"
          onClick={() => {
            replySelectedQuestion();
          }}
        >
          Reply For Selected Question
        </Button>
      ) : null}
      <PruTable
        title={Translation('qaIncome.component.title')}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('app.button.view'),
            tooltipText: 'View Question',
            onClick: (row) => {
              viewQuestion(row);
            },
          },
          {
            title: Translation('app.button.reply'),
            tooltipText: 'Reply Question',
            onClick: (row) => {
              onReplyQuestion(row);
            },
          },
        ]}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => exportQuestionList(),
          },
        ]}
        columnDef={[
          {
            keyIndex: 'selectAll',
            displayName: (
              <Checkbox
                onClick={() => {
                  onSelectAllQuestion();
                }}
                checked={isSelectAll}
              />
            ),
            renderData: (row) => (
              <Checkbox
                onClick={() => {
                  onSelectQuestion(row);
                }}
                checked={!!row.selected}
              />
            ),
          },
          {
            keyIndex: 'agentCode',
            displayName: Translation('qaIncome.component.agentCode'),
            renderData: (row) => row.agentCode,
          },
          {
            keyIndex: 'agentName',
            align: 'center',
            displayName: Translation('qaIncome.component.agentName'),
            renderData: (row) => row.agentName,
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: Translation('qaIncome.component.questionDate'),
            renderData: (row) => getDisplayDate(new Date(row.createdAt), 'DD-MM-YYYY HH:mm:ss'),
          },
          {
            keyIndex: 'category',
            align: 'center',
            displayName: Translation('qaIncome.component.category'),
            renderData: (row) => row.category.content,
          },
          {
            keyIndex: 'question',
            align: 'center',
            displayName: Translation('qaIncome.component.question'),
            renderData: (row) => row.question,
          },
          {
            keyIndex: 'answerDate',
            align: 'center',
            displayName: Translation('qaIncome.component.answerDate'),
            renderData: (row) =>
              row.answers.length
                ? getDisplayDate(new Date(row.answers[row.answers.length - 1].createdAt), 'DD-MM-YYYY HH:mm:ss')
                : '-',
          },
          {
            keyIndex: 'answer',
            align: 'center',
            displayName: Translation('qaIncome.component.answer'),
            renderData: (row) => (row.answers.length ? row.answers[row.answers.length - 1].answer : '-'),
          },
          {
            keyIndex: 'userName',
            align: 'center',
            displayName: Translation('qaIncome.component.userName'),
            renderData: (row) => (row.answers.length ? row.answers[row.answers.length - 1].staffName : ''),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={questionList?.items}
        totalPages={questionList?.totalPages}
        totalRecords={questionList?.total}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default QAIncomeList;
