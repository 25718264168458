import { Dispatch } from 'react';
import { apiClient, apiErrorHandler } from 'src/app/common/network';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

export const assignCandidate = async (
  body: {
    url: string;
  },
  dispatch?: Dispatch<any>,
): Promise<any> => {
  return apiClient
    .post<any>(`${ADMIN_URL}/candidate/assign-candidate`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateCandidateAssessmentPV = async (
  body: {
    eRef: string;
    identityNumber: string;
    fullName: string;
    images: [];
  },
  dispatch?: Dispatch<any>,
): Promise<any> => {
  return apiClient
    .post<any>(`${ADMIN_URL}/candidate/update-candidate-assessment-pv`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
