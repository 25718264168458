export enum PublishStatus {
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED"
}

export enum MdrtPassportFormMode {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
};

export enum StatusModel {
  Active = "Active",
  Terminated = "Terminated",
  NotFound = "NotFound"
}

export type MdrtPassportItem = {
  id: string
  agentCode: string
  status: PublishStatus
  createdAt: string
  updatedAt: string
  lastUpdatedBy: string
};

export interface FileUpload {
  url?: string;
  id: string;
  fileName: string;
  inclusive?: boolean,
  receiverType?: string,
  contentType?:string
  fileType: string
}

export type MdrtPassportDetail = {
  id?: string
  agentCode: string
  passports: Passport[]
  status: PublishStatus
}

export type Passport = {
  id?: string
  year: string
  stampCodes: string[]
  isDuplicate?: boolean
}

export type MdrtPassportFormState = {
  id: string | undefined
  agentCode: string | undefined
  passports: Passport[] | undefined
}

export type ErrorUploadFile = {
  duplicateAgentAndYear?: string[]
  notFoundAgent?: string[]
  termAgent?: string[]
}

export type AgentStatus = {
  agentCode: string,
  status: StatusModel
}

