import { forEach } from 'lodash';
import { PaginateListMore } from '../../../common/types/common-types';
import { Dispatch } from 'react';
import { apiClient, apiErrorHandler } from 'src/app/common/network';
import { IReportItem, IReportFilter, ITotalNonProspectSurvey } from '../types/non-prospect-survey-types';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;
export const fetchNonProspectSurveyReport = async (
  params: IReportFilter,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<IReportItem>> => {
  const url = `${ADMIN_URL}/report/non-prospect-survey/list`;

  return apiClient
    .post(url, params)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchTotalRecordNonProspectSurveyReport = async (
  params: IReportFilter,
  dispatch?: Dispatch<any>,
): Promise<ITotalNonProspectSurvey> => {
  const url = `${ADMIN_URL}/report/non-prospect-survey/total`;

  return apiClient
    .post(url, params)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNonProspectSurveyReportFile = (params: IReportFilter, dispatch?: Dispatch<any>): void => {
  const url = `${ADMIN_URL}/report/non-prospect-survey/export`;

  apiClient
    .post(url, params, { responseType: 'text' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'non-prospect-survey-report-' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
