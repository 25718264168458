import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ModulePermissionProps } from 'src/app/common/module/types';
import { BDM_EVENT_BASE_PATH, BDM_BOP_EVENT_BASE_PATH, SALESKIT_BASE_PATH } from 'src/app/modules/sales-kit/constants';
import MaterialLibraryListingPage from '../../../component/bdm-event/list';

export const SystemConfig = {
  filterName: 'component.filterTitle.recruit-table',
  blockName: 'component.formTitle.recruit-table',
  blockType: 'resource',
  moduleType: 'Recruit',
  viewPageBasePath: `${SALESKIT_BASE_PATH}${BDM_EVENT_BASE_PATH}${BDM_BOP_EVENT_BASE_PATH}/view`,
};

const BdmBOPEventListPage: FC<RouteComponentProps & ModulePermissionProps> = (props) => {
  return <MaterialLibraryListingPage {...props} {...SystemConfig} />;
};

export default BdmBOPEventListPage;
