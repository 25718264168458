import React, { FC } from 'react';
import { SALESKIT_BASE_PATH, BDM_EVENT_BASE_PATH, BDM_BOP_EVENT_BASE_PATH } from 'src/app/modules/sales-kit/constants';
import MaterialLibraryDetailPage from '../../../component/bdm-event/detail';

export const SystemConfig = {
  blockName: 'component.formTitle.recruit-table',
  blockType: 'resource',
  libraryBasePath: `${SALESKIT_BASE_PATH}${BDM_EVENT_BASE_PATH}${BDM_BOP_EVENT_BASE_PATH}`,
  categoryType: 'resourceCategory',
  columnType: 'resourceColumn',
  moduleType: 'Recruit',
};

const BdmBOPEventDetailPage: FC<any> = (props) => {
  return <MaterialLibraryDetailPage {...props} {...SystemConfig} />;
};

export default BdmBOPEventDetailPage;
