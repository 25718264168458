import React, { FC } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import PruRoute from 'src/app/common/components/PruRoute';
import { reportFilterKeys } from '../../../constants';
import ActivityListingPage from './List/ActivityListingPage';

const ActivityReportPage: FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/list`}/>
      <PruRoute
        exact
        path={`${match.path}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={reportFilterKeys}>
            <ActivityListingPage />
          </ParamsProvider>
        )}
      />
    </Switch>
  )
};

export default ActivityReportPage;
