import { Add, Refresh } from '@mui/icons-material';
import { Button, IconButton, Tooltip } from '@mui/material';
import React from 'react';

const tableHeader = (Translation: any, fetchCsvtDownload: any, refreshData: any) => [
  [
    { type: 'header', label: Translation('title.report.event.template.list'), sm: true },
    {
      type: 'custom',
      sm: false,
      render: () => (
        <Tooltip title="Refresh">
          <IconButton style={{ marginRight: 15 }} onClick={refreshData}>
            <Refresh />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      type: 'custom',
      sm: false,
      render: () => (
        <Button variant="contained" color="secondary" onClick={fetchCsvtDownload}>
          <Add />
          {Translation('app.button.export')}
        </Button>
      ),
    },
  ],
];

export default tableHeader;
