import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
// import AsyncCsvLink from '../../../../../common/components/AsyncCsvLink';
import { getDisplayDate } from '../../../../../common/utils/time-utils';
import { PaginateList } from '../../../../../common/types/common-types';
import { useIntl } from 'react-intl';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import { useHistory } from 'react-router-dom';
import { MdrtFormMode, MdrtItem, PublishStatus } from '../../../types/mdrt-types';
import { MDRT_BASE_PATH } from '../../../constants';
import { publishMdrt, unpublishMdrt } from '../../../network/mdrtCrud';

type MdrtListProps = {
  isLoading: boolean;
  mdrtList?: PaginateList<MdrtItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

const MdrtList: FC<MdrtListProps> = ({ isLoading, mdrtList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const onPublish = async (mdrt: MdrtItem) => {
    try {
      await publishMdrt(mdrt.id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Published successfully - ${mdrt.name}`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  const onUnpublish = async (mdrt: MdrtItem) => {
    try {
      await unpublishMdrt(mdrt.id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Successful, please publish new config to replace this one`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  return (
    <>
      <PruTable
        title={Translation('mdrt.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('mdrt.list.create'),
            onClick: () => history.push(`${MDRT_BASE_PATH}/create`, { type: MdrtFormMode.CREATE }),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('mdrt.list.view'),
            tooltipText: 'View MDRT',
            onClick: (row) => history.push(`${MDRT_BASE_PATH}/edit/${row.id}`, { type: MdrtFormMode.VIEW }),
          },
          {
            title: Translation('mdrt.list.edit'),
            tooltipText: 'Edit MDRT',
            onClick: (row) => history.push(`${MDRT_BASE_PATH}/edit/${row.id}`, { type: MdrtFormMode.EDIT }),
            condition: (row) => row.status !== PublishStatus.PUBLISHED,
          },
          {
            title: Translation('mdrt.list.publish'),
            tooltipText: 'Publish MDRT',
            onClick: (row) => onPublish(row),
            condition: (row) => row.status !== PublishStatus.PUBLISHED,
          },
          {
            title: Translation('mdrt.list.unpublish'),
            tooltipText: 'Unpublish MDRT',
            onClick: (row) => onUnpublish(row),
            condition: (row) => row.status === PublishStatus.PUBLISHED,
          },
        ]}
        columnDef={[
          {
            keyIndex: 'name',
            displayName: Translation('mdrt.common.name'),
            renderData: (row) => row.name,
          },
          {
            keyIndex: 'status',
            align: 'center',
            displayName: Translation('mdrt.common.status'),
            renderData: (row) => row.status,
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: Translation('mdrt.list.createdTime'),
            renderData: (row) => getDisplayDate(new Date(row.createdAt), 'YYYY-MM-DD HH:mm:ss'),
          },
          {
            keyIndex: 'updatedAt',
            align: 'center',
            displayName: Translation('mdrt.list.lastUpdatedTime'),
            renderData: (row) => getDisplayDate(new Date(row.updatedAt), 'YYYY-MM-DD HH:mm:ss'),
          },
          {
            keyIndex: 'lastUploadedBy',
            align: 'center',
            displayName: Translation('mdrt.list.lastUpdatedBy'),
            renderData: (row) => row.lastUploadedBy,
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={mdrtList?.items}
        totalPages={mdrtList?.totalPages}
        totalRecords={mdrtList?.total}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default MdrtList;
