import React, { FC, useState } from 'react';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { getFirstDayOfMonth, getDayStart, getLastDayOfMonth } from '../../../../../common/utils/time-utils';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { PaginateListMore } from 'src/app/common/types/common-types';
import { useLang } from 'src/app/i18n';
import moment from 'moment';
import { CandidateInteractionReportListParams } from '../../../types/CandidateReportInteractionTypes';
import {
  fetchCandidateInteractionReport,
  fetchCandidateInteractionReportDownload,
} from '../../../network/candidateInteractionReportCrud';
import useModal from '../../../common/modal/useModal';
import Modal from '../../../common/modal/modal.component';
import { Table } from 'react-bootstrap';

type CandidateInteractionReportProps = {} & ParamsProps;

enum candidateTypeEnum {
  CANDIDATE = 'Candidate',
  OBCANDIDATE = 'ObCandidate',
}

const candidateDropdown = {
  candidateType: [
    { displayName: 'All', value: '' },
    { displayName: 'Candidate', value: candidateTypeEnum.CANDIDATE },
    { displayName: 'Candidate OB', value: candidateTypeEnum.OBCANDIDATE },
  ],
};

const initialState: CandidateInteractionReportListParams = {
  requestedDateFrom: getFirstDayOfMonth(new Date()),
  requestedDateTo: getLastDayOfMonth(new Date()),
  agentCode: '',
  unitCode: '',
  branchCode: '',
  officeCode: '',
  candidateType: '',
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): CandidateInteractionReportListParams => {
  return initialParams
    ? {
        requestedDateFrom: initialParams.requestedDateFrom
          ? getDayStart(new Date(initialParams.requestedDateFrom))
          : getFirstDayOfMonth(new Date()),
        requestedDateTo: initialParams.requestedDateTo
          ? getLastDayOfMonth(new Date(initialParams.requestedDateTo))
          : getLastDayOfMonth(new Date()),
        agentCode: '',
        unitCode: '',
        branchCode: '',
        officeCode: '',
        candidateType: '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const CandidateInteractionReport: FC<CandidateInteractionReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilterValue = paramsInitiator(initialParams);
  const currentLanguage = useLang();

  const { isShowing, toggle } = useModal();
  const [elementModal, setElementModal] = useState<any>();

  const [filterState, setFilterState] = useState<CandidateInteractionReportListParams>(defaultFilterValue);
  const [reportList, setReportList] = useState<PaginateListMore<any>>();
  const { isLoading, refreshData } = useDataProvider<PaginateListMore<any>>(
    async () => {
      try {
        return await fetchCandidateInteractionReport(filterState, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvtDownload = () => {
    try {
      fetchCandidateInteractionReportDownload(filterState, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <Modal isShowing={isShowing} hide={toggle} element={elementModal} />
      <PruFilter
        title={Translation('report.candidate.interaction.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: 'Agent Code',
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'unitCode',
            initialValue: filterState.unitCode,
            displayName: 'Unit Code',
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'branchCode',
            initialValue: filterState.branchCode,
            displayName: 'Branch Code',
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'officeCode',
            initialValue: filterState.officeCode,
            displayName: 'Office Code',
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'candidateType',
            initialValue: filterState.candidateType,
            displayName: 'Candidate Type',
            choices: candidateDropdown.candidateType,
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'requestedDateFrom',
            fieldTo: 'requestedDateTo',
            initialDateFrom: filterState.requestedDateFrom,
            initialDateTo: filterState.requestedDateTo,
            defaultDateFrom: defaultFilterValue.requestedDateFrom,
            defaultDateTo: defaultFilterValue.requestedDateTo,
            displayName: Translation('report.candidate.interaction.filter.period'),
            isRequired: true,
            periodTime: {
              months: 3,
            },
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            agentCode: data.agentCode.trim(),
            unitCode: data.unitCode.trim(),
            branchCode: data.branchCode.trim(),
            officeCode: data.officeCode.trim(),
            candidateType: data.candidateType,
            requestedDateFrom: data.requestedDateFrom,
            requestedDateTo: data.requestedDateTo,
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.candidate.interaction.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvtDownload(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            keyIndex: 'Agent Code',
            displayName: 'Agent Code',
            align: 'center',
            renderData: (row) => row?.agentCode,
          },
          {
            keyIndex: 'Role',
            displayName: 'Role',
            align: 'center',
            renderData: (row) => row?.role as string,
          },
          {
            keyIndex: 'Unit Code',
            displayName: 'Unit Code',
            align: 'center',
            renderData: (row) => row?.unitCode,
          },
          {
            keyIndex: 'Branch Code',
            displayName: 'Branch Code',
            align: 'center',
            renderData: (row) => row?.branchCode,
          },
          {
            keyIndex: 'Office Code',
            displayName: 'Office Code',
            align: 'center',
            renderData: (row) => row?.officeCode,
          },
          {
            keyIndex: 'GAD Code',
            displayName: 'GAD Code',
            align: 'center',
            renderData: (row) => row?.gaCode,
          },
          {
            keyIndex: 'Candidate Name',
            displayName: 'Candidate Name',
            align: 'center',
            renderData: (row) => (row?.name ? row?.name : '-'),
          },
          {
            keyIndex: 'Candidate Type',
            displayName: 'Candidate Type',
            align: 'center',
            renderData: (row) => (row?.candidateType === candidateTypeEnum.CANDIDATE ? 'Ứng Viên' : 'Ứng Viên lớp OB'),
          },
          {
            keyIndex: 'Call Status',
            displayName: 'Call Status',
            align: 'center',
            renderData: (row) => (row?.isCalled ? 'YES' : 'NO'),
          },
          {
            keyIndex: 'PRUDNA Status',
            displayName: 'PRUDNA Status',
            align: 'center',
            renderData: (row) => (row?.isSendedAssessment ? 'YES' : 'NO'),
          },
          {
            keyIndex: 'Note Detail',
            displayName: 'Note Detail',
            align: 'center',
            renderData: (row) => {
              return row?.noteDetails && row?.noteDetails?.length > 0 ? (
                <div
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setElementModal((prev: any) => {
                      toggle();
                      return (
                        <>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Note</th>
                                <th>Time</th>
                              </tr>
                            </thead>
                            <tbody>
                              {row.noteDetails
                                .slice(0)
                                .reverse()
                                .map((item: any) => (
                                  <tr>
                                    <td>{item.note}</td>
                                    <td>{moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                          <ul
                            style={{
                              listStyle: 'auto',
                            }}
                          ></ul>
                        </>
                      );
                    });
                  }}
                >
                  {row.noteDetails[row.noteDetails.length - 1].note} <span style={{ color: 'blue' }}>(See more)</span>
                </div>
              ) : (
                '-'
              );
            },
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default CandidateInteractionReport;
