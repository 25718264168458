import React, { FC } from 'react'
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom'
import PruRoute from 'src/app/common/components/PruRoute'
import OpeningNotificationReport from './Opening/OpeningNotificationReport'

const NotificationOpeningReportPage: FC<RouteComponentProps> = ({ history, location, match }) => {
  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/list`} />
       <PruRoute 
        exact
        path={`${match.path}/list`}
        component={OpeningNotificationReport}
      />
    </Switch>
  )
}

export default NotificationOpeningReportPage