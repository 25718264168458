import React, { FC, useState } from 'react';
import { map } from 'lodash';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { getDayEnd, getFirstDayOfMonth, getDayStart, getLastDayOfMonth } from '../../../../../common/utils/time-utils';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { PaginateListMore } from 'src/app/common/types/common-types';
import moment from 'moment';
import { ChannelEnum } from '../../../types/candidate-types';
import { RegistrationCandidateReportListParams } from '../../../types/registrationCandidateReportTypes';
import {
  fetchRegistrationCandidateReport,
  fetchRegistrationCandidateReportDownload,
} from '../../../network/registrationCandidateReportCrud';
import { useDataProvider } from 'src/app/common/utils';
import { useLang } from 'src/app/i18n';

type RegistrationCandidateReportProps = {} & ParamsProps;

const initialState: RegistrationCandidateReportListParams = {
  requestedDateFrom: getDayStart(new Date()),
  requestedDateTo: getDayEnd(new Date()),
  channel: '',
  unitCode: '',
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): RegistrationCandidateReportListParams => {
  return initialParams
    ? {
        requestedDateFrom: initialParams.requestedDateFrom
          ? getDayStart(new Date(initialParams.requestedDateFrom))
          : getFirstDayOfMonth(new Date()),
        requestedDateTo: initialParams.requestedDateTo
          ? getLastDayOfMonth(new Date(initialParams.requestedDateTo))
          : getLastDayOfMonth(new Date()),
        channel: initialParams.channel || '',
        unitCode: initialParams.unitCode ? initialParams.unitCode : '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const RegistrationCandidateReport: FC<RegistrationCandidateReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilterValue = paramsInitiator(initialParams);
  const currentLanguage = useLang();
  const [filterState, setFilterState] = useState<RegistrationCandidateReportListParams>(defaultFilterValue);
  const [reportList, setReportList] = useState<PaginateListMore<any>>();
  const { isLoading, refreshData } = useDataProvider<PaginateListMore<any>>(
    async () => {
      try {
        return await fetchRegistrationCandidateReport(filterState, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvtDownload = () => {
    try {
      fetchRegistrationCandidateReportDownload(filterState, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('report.registration.candidate.filter')}
        itemDef={[
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'channel',
            initialValue: filterState.channel,
            displayName: Translation('report.registration.candidate.filter.channel'),
            choices: [
              { displayName: 'All', value: '' },
              ...map(ChannelEnum, (option: string, key: string) => ({ displayName: option, value: key })),
            ],
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'unitCode',
            initialValue: filterState.unitCode,
            displayName: Translation('report.registration.candidate.filter.unitCode'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'requestedDateFrom',
            fieldTo: 'requestedDateTo',
            initialDateFrom: filterState.requestedDateFrom,
            initialDateTo: filterState.requestedDateTo,
            defaultDateFrom: defaultFilterValue.requestedDateFrom,
            defaultDateTo: defaultFilterValue.requestedDateTo,
            displayName: Translation('report.registration.candidate.filter.assessDate'),
            isRequired: true,
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            channel: data.channel,
            unitCode: data.unitCode,
            requestedDateFrom: data.requestedDateFrom,
            requestedDateTo: data.requestedDateTo,
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.registration.candidate.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvtDownload(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            keyIndex: 'Channel',
            align: 'center',
            displayName: 'Channel',
            renderData: (row) => (row.channel ? row.channel : '-'),
          },
          {
            keyIndex: 'CandidateName',
            align: 'center',
            displayName: 'Candidate Name',
            renderData: (row) => row.firstName + ' ' + row.lastName,
          },
          {
            keyIndex: 'Phone',
            align: 'center',
            displayName: 'Phone No.',
            renderData: (row) => (row.phone ? row.phone : '-'),
          },
          {
            keyIndex: 'IdentifyPassport',
            align: 'center',
            displayName: 'Identity/Passport No.	',
            renderData: (row) => (row.identityNumber ? row.identityNumber : '-'),
          },
          {
            keyIndex: 'UnitCode',
            align: 'center',
            displayName: 'Unit Code',
            renderData: (row) => (row.leader ? (row.leader.unitPUM ? row.leader.unitPUM : row.leader.unitCode) : '-'),
          },
          {
            keyIndex: 'RegisteredDate',
            align: 'center',
            displayName: 'Registered Date',
            renderData: (row) => (row.createdAt ? moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss') : '-'),
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default RegistrationCandidateReport;

function formatClassification(value: string | null) {
  switch (value) {
    case 'KHONG DAT':
      return 'KHÔNG ĐẠT';
    case 'RAT TOT':
      return 'RẤT TỐT';
    case 'TOT':
      return 'TỐT';
    case 'CHAP NHAN':
      return 'CHẤP NHẬN';
    case 'TU CHOI':
      return 'TỪ CHỐI';
    default:
      return value;
  }
}
