import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ProTable from 'src/app/common/components/ProTable';
import column from './column';
import { commonStyles } from './style';

const pageData: { pageNum: number; pageSize: number } = {
  pageNum: 0,
  pageSize: 20,
};

const AgentsTable: FC<any> = ({ data, setSeletAgent, actionRef }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const formatData = async (newFilterState: any) => {
    const { page, pageSize } = newFilterState;
    const list = data.slice(page * pageSize, page * pageSize + pageSize);

    const res = {
      data: list || [],
      success: true,
      total: data?.length,
    };

    return res;
  };

  const fetchData = (newFilterState: any): Promise<any> => {
    return formatData(newFilterState);
  };

  return (
    <div style={commonStyles.tableBg}>
      <ProTable
        rowKey="key"
        filterTitle={''}
        headerTitle={''}
        columns={column(Translation, data, setSeletAgent, actionRef)}
        pageSize={pageData.pageSize}
        request={(params) => {
          return fetchData(params);
        }}
        toolBarRender={() => []}
        rowSelection={{
          onChange: (_: any, selectedRows: any) => {
            const selectCourse = selectedRows && selectedRows[0];
            if (selectCourse) {
              setSeletAgent(selectedRows);
            }
          },
          getCheckboxProps: () => {
            return {
              // disabled: !!data.find((item: any) => item.agentCode === record.agentCode)
            };
          },
        }}
        // onLoad={data}
        singleSelect={false}
        hideToolBar={true}
        hideAllSelect={false}
        actionRef={actionRef}
        titleStyle={{ backgroundColor: '#FAFAFA' }}
        titleFontStyle={{ color: 'black' }}
      />
    </div>
  );
};

export default AgentsTable;
