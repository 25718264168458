import React, { FC, useReducer, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
// import { assignSurvingServey } from '../../../network/surveyCrud'
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import useFilterStyles from 'src/app/common/styles/filter-styles';
import { toAbsoluteUrl } from 'src/app/common/utils';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import Form, { useForm } from 'src/app/common/components/Form';
import FileUploader from 'src/app/common/components/FileUploader';
import { assignCandidate } from '../../../network/candidateCrud';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { makeStyles } from 'tss-react/mui';
type SurveyListingPageProps = {} & ParamsProps;

const useStyles = makeStyles()((theme) => ({
  exampleFile: {
    color: 'gray',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  sectionMargin: {
    width: '100%',
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  fieldContainer: {
    width: 150,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
    width: '150px',
  },
  mandatory: {
    color: 'red',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const AssignCandidateUploadPage: FC<SurveyListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const { classes } = useStyles();
  const filterClasses = useFilterStyles().classes;
  const dispatch = useDispatch();

  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);

  const Translation = (id: string) => intl.formatMessage({ id });

  const [form] = useForm();

  const DEFAULT_REQUIRED_RULES = {
    required: true,
    message: Translation('component.form-required-text'),
  };

  const commonClasses = useCommonStyles().classes;

  const submitAssignServingSurvey = async () => {
    const formData = await form.getFieldsValue(true);

    await form.validateFields();

    await assignCandidate({
      url: formData?.userFile?.url,
    })
      .then((res) => {
        if (res?.tag === 'error') {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: Translation('global.submit.fail'),
                content: res.message,
              },
            ]),
          );
        } else {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: Translation('global.submit.success'),
                content: `Record saved successfully.`,
              },
            ]),
          );
        }

        form.resetFields();
        setIsDisabledSubmit((prev) => false);
      })
      .catch((err) => {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: Translation('global.submit.fail'),
              content: 'System error!',
            },
          ]),
        );
        setIsDisabledSubmit((prev) => false);
      });
  };

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        {
          mimeType: fileType,
          accessLevel: 'anonymous',
          module: `material_assignCandidate}`,
        },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        return {
          id: result.blobId,
          fileName: fileName,
          url: result.url,
          type: fileType,
        };
      }
    } catch (err) {
    } finally {
    }
  };

  return (
    <Form form={form}>
      <div style={{ marginBottom: 20 }} className={filterClasses.root}>
        <div style={{ marginBottom: 5 }} className="PruFilter-header-container">
          <div className={classes.sectionMargin}>
            <div className={classes.divideMargin}>
              <div className={commonClasses.header}>{Translation('title.assignCandidate')}</div>
            </div>
            <div
              style={{
                alignItems: 'center',
              }}
              className="row"
            >
              <div className="col-md-2 text-center">
                <span className={classes.field}>
                  {Translation('field.assignCandidate.import')}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <div
                style={{
                  padding: 0,
                }}
                className="col-md-2"
              >
                <Form.Item
                  style={{
                    alignSeft: 'end',
                  }}
                  name="userFile"
                  rules={[DEFAULT_REQUIRED_RULES]}
                >
                  <FileUploader
                    // disabled={disabled}
                    upload={handleUploadFile}
                    color="secondary"
                    maxFileSize={2}
                    allowedFileTypes={[
                      'csv',
                      'xlsx',
                      'xls',
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                      'application/vnd.ms-excel',
                    ]}
                    customCss={{
                      minWidth: '280px',
                    }}
                  />
                </Form.Item>

                <div>
                  <a href={toAbsoluteUrl('/templates/Template_Assign_Candidate_to_Leader.xlsx')}>
                    {Translation('button.assignCandidate.downloadTemplate')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.footerContainer}>
          <Button
            style={{
              minWidth: '136px',
            }}
            variant="contained"
            color="secondary"
            disabled={isDisabledSubmit}
            onClick={(e: any) => {
              e.preventDefault();
              setIsDisabledSubmit((prev) => true);
              submitAssignServingSurvey();
            }}
          >
            {isDisabledSubmit ? (
              <div className="spinner-border text-danger mr-2" role="status"></div>
            ) : (
              <div>{Translation('button.assignCandidate.assign')} </div>
            )}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default AssignCandidateUploadPage;
