import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../common/components/PruRoute';
import { MDRT_BASE_PATH } from '../constants';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../common/constants';
import MdrtListingPage, { mdrtFilterKeys } from './List/MdrtListingPage';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import MdrtDetailPage from './Create/MdrtDetailPage';

const MdrtRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={`${MDRT_BASE_PATH}`} to={`${MDRT_BASE_PATH}/list`} />
      <PruRoute exact path={`${MDRT_BASE_PATH}/list`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={mdrtFilterKeys}>
          <MdrtListingPage />
        </ParamsProvider>
      )}/>
       <PruRoute exact path={`${MDRT_BASE_PATH}/edit/:id`} component={MdrtDetailPage}/>
      <PruRoute exact path={`${MDRT_BASE_PATH}/create`} component={MdrtDetailPage}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
};

export default MdrtRoutes;