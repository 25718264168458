import React, { FC, useEffect, useMemo, useReducer, useState } from 'react';
import {
  FileUpload,
  MdrtStoryDetail,
  MdrtStoryFormMode,
  MdrtStoryFormState,
  PublishStatus,
} from '../../../../types/mdrt-story-types';
import { Button, TextField } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils/form-error-utils';
import { MANDATORY_FIELD_ERROR_TEXT, MDRT_HUB_BASE_PATH, MDRT_STORY_BASE_PATH } from '../../../../constants';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import FileUploader from 'src/app/common/components/FileUploader';
import { checkAgentCode, create, update } from 'src/app/modules/MdrtHub/network/mdrtStoryCrud';
import { useDebounce } from 'src/app/common/utils/common-utils';
import Tinymce from './Tinymce';
import { makeStyles } from 'tss-react/mui';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import { Close } from '@mui/icons-material';

type MdrtStoryDetailFormProps = {
  itemId?: string;
  formMode: MdrtStoryFormMode;
  itemDetail?: MdrtStoryDetail;
  onReload: () => void;
  onRouteTo: (route: string) => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  rowContainer2: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  fieldContainer: {
    width: 120,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  dateDivider: {
    marginLeft: 8,
    marginRight: 8,
  },
  sectionMargin: {
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },
  flexItem: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '33.333333%',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  iconContainer: {
    width: 60,
    height: 60,
    overflow: 'hidden',
    borderRadius: 12,
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  disableColor: {
    backgroundColor: '#e0e0e0',
  },
  root: {
    '& .MuiFormHelperText-root': {
      fontSize: '0.95rem',
      marginLeft: '0px !important',
    },
  },
  errorCustom: {
    color: '#f018a6',
    marginLeft: 0,
    marginRight: 14,
    marginTop: 4,
    margin: 0,
    fontSize: '0.95rem',
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  closeIcon: {
    marginLeft: '5px',
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
}));

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof MdrtStoryFormState;
    value: any;
  };
};

enum UploadType {
  header = 'header',
}

type UploadAction = {
  type: 'UPLOAD_IMG';
  payload: {
    type: UploadType;
    id: string;
    url: string;
    fileName: string;
    fileType: string;
  };
};

type MdrtFormAction = ModifyFieldAction | UploadAction;

const initialState: MdrtStoryFormState = {
  id: undefined,
  agentCode: '',
  title: '',
  templateHeaderUrl: '',
  templateHeaderImg: undefined,
  templateBody: '',
  templateBodyText: '',
};

const formReducer = (state: MdrtStoryFormState, action: MdrtFormAction): MdrtStoryFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'UPLOAD_IMG':
      return {
        ...state,
        templateHeaderImg: {
          id: action.payload.id,
          fileName: action.payload.fileName,
          url: action.payload.url,
          fileType: action.payload.fileType,
        },
      };
    default:
      return state;
  }
};

const detailToStateConvertor = (detail: MdrtStoryDetail): MdrtStoryFormState => {
  return {
    id: detail.id,
    agentCode: detail.agentCode,
    title: detail.title,
    templateHeaderUrl: detail.templateHeaderUrl,
    templateHeaderImg: detail.templateHeaderImg,
    templateBody: detail.templateBody,
    templateBodyText: detail.templateBodyText,
  };
};

const checkAspectRatio = (url: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = function () {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      const aspectRatio = width / height;
      const targetAspectRatio = 118 / 79;
      if (Math.abs(aspectRatio - targetAspectRatio) < 0.01) {
        resolve(true); // Tỉ lệ hình ảnh khớp với 118:79
      } else {
        resolve(false); // Tỉ lệ hình ảnh không khớp với 118:79
      }
    };
    img.onerror = function () {
      reject(new Error('Không thể tải hình ảnh'));
    };
  });
};

const MdrtStoryDetailForm: FC<MdrtStoryDetailFormProps> = ({ itemId, formMode, itemDetail, onReload, onRouteTo }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [isLoading, setIsLoading] = useState(false);
  const [agentCondition, setAgentCondition] = useState<{ isError: boolean; message?: string | null }>({
    isError: false,
    message: '',
  });

  const checkAgentCodeFn = async (agentCode: string) => {
    try {
      if (agentCode === '') {
        if (agentCondition.isError === true) {
          setAgentCondition({
            isError: false,
            message: '',
          });
        }
        return true;
      }

      const result: any = await checkAgentCode(agentCode, dispatch);
      if (result && result.status) {
        setAgentCondition({
          isError: !(result.status === 'success'),
          message: result.message || '',
        });
      } else {
        if (agentCondition.isError === false) {
          setAgentCondition({
            isError: true,
            message: 'The system cannot validate the agent code right now',
          });
        }
      }
    } catch (error) {
      if (agentCondition.isError === false) {
        setAgentCondition({
          isError: true,
          message: 'The system cannot validate the agent code right now',
        });
      }
    }
  };

  const debounceCheckAgentStatus = useDebounce(async (agentCode: string) => {
    await checkAgentCodeFn(agentCode);
  }, 1000);

  useEffect(() => {
    checkAgentCodeFn(itemDetail?.agentCode || '');
  }, []);

  const [formState, formDispatch] = useReducer(
    formReducer,
    itemDetail ? detailToStateConvertor(itemDetail) : initialState,
  );

  const isDisabled = useMemo(() => {
    return formMode === MdrtStoryFormMode.CREATE || formMode === MdrtStoryFormMode.EDIT ? false : true;
  }, [formMode]);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'agentCode',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'title',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'templateBody',
      fieldType: ErrorFieldType.MANDATORY,
    },
  ]);

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const dimensions = await checkAspectRatio(URL.createObjectURL(file));
      if (!dimensions) {
        throw new Error(Translation('mdrtStory.headerImage.max.file.resolution.allow') || '');
      }

      const createBlobRes = await createBlob(
        { mimeType: fileType, accessLevel: 'anonymous', module: 'mdrt-story' },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Upload successfully - ${fileName}`,
            },
          ]),
        );
        return {
          id: result.blobId,
          fileName: fileName,
          url: result.url,
          type: fileType,
        };
      }
    } catch (error: any) {
      throw new Error(error?.message || '');
    }
  };

  const onChangeUploadFile = (type: UploadType, result: any) => {
    if (type && result) {
      formDispatch({
        type: 'UPLOAD_IMG',
        payload: {
          type,
          id: result.id,
          url: result.url,
          fileName: result.fileName,
          fileType: result.type,
        },
      });
      onDismissErrorHandler(type, result);
    }
  };

  const removeFile = (type: UploadType) => {
    formDispatch({
      type: 'UPLOAD_IMG',
      payload: {
        type,
        id: '',
        url: '',
        fileName: '',
        fileType: '',
      },
    });
  };

  const onSubmitForm = async (status: PublishStatus) => {
    setIsLoading(true);
    const { hasError } = onSubmitErrorValidator();

    if (!hasError && !agentCondition.isError) {
      const detail: MdrtStoryDetail = {
        id: formState.id,
        agentCode: formState.agentCode || '',
        title: formState.title || '',
        templateHeaderUrl: formState.templateHeaderUrl || null,
        templateHeaderImg: formState.templateHeaderImg?.id ? formState.templateHeaderImg : null,
        templateBody: formState.templateBody || '',
        templateBodyText: formState.templateBodyText || '',
        publishStatus: status,
      };
      try {
        if (formMode === MdrtStoryFormMode.CREATE) {
          await create(detail, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Created successfully - ${detail.title}`,
              },
            ]),
          );
        } else {
          detail.id = itemId;
          await update(detail, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Updated successfully - ${detail.title}`,
              },
            ]),
          );
        }
        onRouteTo(`${MDRT_HUB_BASE_PATH}${MDRT_STORY_BASE_PATH}`);
      } catch (err) {}
    }
    setTimeout(() => setIsLoading(false), 2000);
  };

  const previewFile = ({ fileName, url, fileType }: FileUpload) => {
    return (
      <>
        <div className={classes.rowContainer}>
          <div style={{ flexGrow: 1 }}>
            <div className={classes.rowContainer}>
              <div style={{ marginBottom: 7 }}>
                <a href={url}>{fileName || ''}</a>
                {!isDisabled && <Close className={classes.closeIcon} onClick={() => removeFile(UploadType.header)} />}
              </div>
            </div>
            <div className={classes.rowContainer2}>
              {url && fileType !== 'application/pdf' && (
                <a href={url}>
                  <img style={{ width: 'auto', height: '80px' }} src={url} alt="fileName" />
                </a>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={classes.container}>
        {/* Top */}
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>
              {Translation(
                formMode === MdrtStoryFormMode.CREATE
                  ? 'app.button.add'
                  : formMode === MdrtStoryFormMode.EDIT
                  ? 'app.button.edit'
                  : 'app.button.view',
              )}
            </div>
          </div>
          <Button variant="contained" onClick={() => onRouteTo(`${MDRT_HUB_BASE_PATH}${MDRT_STORY_BASE_PATH}/list`)}>
            Back
          </Button>
        </div>

        <div className={classes.sectionMargin}>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('mdrtHub.mdrtStory.detail.agentCode')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                className={classes.root}
                style={{ width: '100%' }}
                disabled={isDisabled}
                error={errorState.mandatory.agentCode || agentCondition.isError}
                margin="dense"
                variant="outlined"
                helperText={
                  (agentCondition.isError && agentCondition.message) ||
                  (errorState.mandatory.agentCode && MANDATORY_FIELD_ERROR_TEXT)
                }
                value={formState.agentCode}
                onChange={(e) => {
                  onDismissErrorHandler('agentCode', e.target.value);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'agentCode', value: e.target.value },
                  });
                  debounceCheckAgentStatus(e.target.value);
                }}
                inputProps={{ maxLength: 100 }}
              />
            </div>
          </div>
        </div>

        <div className={classes.sectionMargin}>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('mdrtHub.mdrtStory.detail.title')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                style={{ width: '100%' }}
                disabled={isDisabled}
                error={errorState.mandatory.title}
                className={classes.root}
                margin="dense"
                variant="outlined"
                helperText={errorState.mandatory.title && MANDATORY_FIELD_ERROR_TEXT}
                value={formState.title}
                onChange={(e) => {
                  onDismissErrorHandler('title', e.target.value);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'title', value: e.target.value },
                  });
                }}
                inputProps={{ maxLength: 250 }}
              />
            </div>
          </div>
        </div>

        {/* Header */}
        <div className={classes.sectionMargin}>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('mdrtHub.mdrtStory.detail.header')}:</span>
            </div>
            {/* <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={isDisabled || !!formState.templateHeaderImg?.url}
                label={Translation(
                  "notification.form.formField.label.templateHeaderUrl"
                )}
                className={
                  formState.templateHeaderImg?.url ? classes.disableColor : ""
                }
                margin="dense"
                variant="outlined"
                fullWidth
                value={formState.templateHeaderUrl}
                onChange={(e) => {
                  formDispatch({
                    type: "MODIFY_FIELD",
                    payload: {
                      field: "templateHeaderUrl",
                      value: e.target.value,
                    },
                  });
                }}
                inputProps={{ maxLength: 200 }}
              />
            </div> */}
            {/* <div style={{ margin: "15px" }}>OR</div> */}
            <div style={{ flexGrow: 1 }}>
              {!formState.templateHeaderImg?.url && (
                <FileUploader
                  color="secondary"
                  disabled={
                    isDisabled || (formState.templateHeaderUrl != undefined && formState.templateHeaderUrl.length > 0)
                  }
                  showAllowText={
                    formState.templateHeaderImg?.url
                      ? ''
                      : Translation('app.image.maximum') + ` | ${Translation('app.image.aspectRatio')}`
                  }
                  upload={handleUploadFile}
                  allowedFileTypes={['jpg', 'png', 'jpeg']}
                  maxFileSize={5}
                  showAddIcon={false}
                  btnTxt={
                    formState.templateHeaderImg?.url
                      ? Translation('app.button.update')
                      : '+ ' + Translation('app.button.upload')
                  }
                  onChange={(e) => onChangeUploadFile(UploadType.header, e)}
                />
              )}
              {formState.templateHeaderImg?.url && previewFile(formState.templateHeaderImg)}
            </div>
          </div>
        </div>

        {/*  Body */}
        <div className={classes.sectionMargin}>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('mdrtHub.mdrtStory.detail.body')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              {
                <Tinymce
                  disabled={isDisabled}
                  value={formState.templateBody}
                  accessLevel={'anonymous'}
                  onChange={(value: string) => {
                    onDismissErrorHandler('templateBody', value);
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'templateBody', value: value },
                    });
                  }}
                  onTextChange={(value: string) => {
                    if (value) {
                      value = value.replace(/[\r\n\t]/g, '');
                    }
                    formDispatch({
                      type: 'MODIFY_FIELD',
                      payload: { field: 'templateBodyText', value: value },
                    });
                  }}
                />
              }
              <p className={classes.errorCustom}>{errorState.mandatory.templateBody && MANDATORY_FIELD_ERROR_TEXT}</p>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className={classes.footerContainer}>
          {isDisabled ? (
            <></>
          ) : (
            <>
              <Button
                variant="contained"
                color="secondary"
                disabled={isLoading}
                onClick={() => onSubmitForm(PublishStatus.UNPUBLISHED)}
              >
                Save as Draft
              </Button>
              <Button
                style={{ marginLeft: 25 }}
                variant="contained"
                color="secondary"
                disabled={isLoading}
                onClick={() => onSubmitForm(PublishStatus.PUBLISHED)}
              >
                Publish
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MdrtStoryDetailForm;
