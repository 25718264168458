import {
  TableContainer,
  TableFooter,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Tooltip,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { PruForm } from 'src/app/common/components/PruForm';
import filterConfig from './filterConfig';
import tableHeader from './tableHeader';
import { styles } from '../style';
import { regionLocale } from 'src/app/i18n';
import tableConfig from './tableConfig';
import ProTableCell from 'src/app/common/components/ProTable/ProTableCell';
import StickyTableCell from 'src/app/common/components/ProTable/StickyTableCell';
import PruTableLoading from 'src/app/common/components/Table/PruTableLoading';
import PruTableEmptyRow from 'src/app/common/components/Table/PruTableEmptyRow';
import PruTablePaginationActions from 'src/app/common/components/Table/PruTablePaginationActions';
import { getEventInvitationList } from './api';
import moment from 'moment';
import { getDisplayDate } from 'src/app/common/utils/time-utils';
import { Link } from 'react-router-dom';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { useDispatch } from 'react-redux';
import { createNewInvitation } from '../Detail/api';

// PVA
import { EVENT_BASE_PATH } from '../../../constants';

type DeleteDialogState = {
  open: boolean;
  id: string;
};

const InvitationLetterList: FC<any> = (props) => {
  const intl = useIntl();
  const { classes } = styles();
  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });
  const filterInitData = {
    createdDate: [],
    duration: [],
  };
  const [filterData, setFilterData] = useState<{ [key: string]: any }>(filterInitData);
  const [filterSavedData, setFilterSavedData] = useState<{
    [key: string]: any;
  }>(filterInitData);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<{ [key: string]: any }[]>([]);
  const [pageData, setPageData] = useState<{ [key: string]: any }>({
    pageNum: 1,
    pageSize: 10,
  });

  const addEvent = () => {
    props.history.push(`${EVENT_BASE_PATH}/invitation-letter/create`);
  };

  const keyPressSearch = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  const onChangePage = (pageNum: number) => {
    const newPageData = {
      ...pageData,
      pageNum,
    };
    setPageData(newPageData);
    fetchData({ ...filterSavedData, ...newPageData });
  };

  const onSearch = () => {
    // setFilterData(filterData)
    const newPageData = {
      ...pageData,
      pageNum: 1,
    };
    setPageData(newPageData);
    setFilterSavedData(filterData);
    fetchData({ ...filterData, ...newPageData });
  };

  const onChangePageRows = (rows: any) => {
    const newPageData = {
      ...pageData,
      pageNum: 1,
      pageSize: rows,
    };

    setPageData(newPageData);
    fetchData({ ...filterSavedData, ...newPageData });
  };

  const refreshData = async () => {
    const newPageData = {
      ...pageData,
      pageNum: 1,
    };
    setPageData(newPageData);
    fetchData({ ...filterSavedData, ...newPageData });
  };

  const onReset = () => {
    const newPageData = {
      pageSize: pageData?.pageSize,
      pageNum: 1,
    };
    fetchData({
      ...{
        invitationName: '',
        createdDate: [],
        duration: [],
        location: '',
        status: '',
      },
      ...newPageData,
    });
    setPageData(newPageData);
    setFilterData(filterInitData);
    setFilterSavedData(filterInitData);
  };

  useEffect(() => {
    const fetchAPI = async () => {
      setFormLoading(true);
      await fetchData();
      setFormLoading(false);
    };
    fetchAPI();

    return;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener('keypress', keyPressSearch);
    return () => {
      window.removeEventListener('keypress', keyPressSearch);
    };
    // eslint-disable-next-line
  }, [filterData]);

  const fetchData = async (newFilterState?: any) => {
    setFormLoading(true);
    const newFilter = newFilterState || {};
    const requestParams = {
      ...filterData,
      ...pageData,
      ...newFilter,
    };

    requestParams.createdDateFrom = requestParams.createdDate?.[0]
      ? moment(requestParams.createdDate?.[0]).format('DD/MM/YYYY')
      : '';
    requestParams.createdDateTo = requestParams.createdDate?.[1]
      ? moment(requestParams.createdDate?.[1]).format('DD/MM/YYYY')
      : '';

    delete requestParams.createdDate;

    requestParams.startDateFrom = requestParams.duration?.[0]
      ? moment(requestParams.duration?.[0]).format('DD/MM/YYYY')
      : '';
    requestParams.startDateTo = requestParams.duration?.[1]
      ? moment(requestParams.duration?.[1]).format('DD/MM/YYYY')
      : '';
    requestParams.sortItem = 'createdDate';
    requestParams.sortOrder = 'DESC';
    delete requestParams.duration;

    try {
      const invitationRes = await getEventInvitationList(requestParams);
      if (invitationRes) {
        const invitationList = invitationRes?.data?.values || [];
        for (let i = 0; i < invitationList?.length; i++) {
          if (
            invitationList?.[i]?.status === 'UNPUBLISHED' &&
            moment(new Date()).isSameOrAfter(moment(new Date(invitationList?.[i]?.publicTime?.specificTime || '')))
          ) {
            const updateStatusRes = await createNewInvitation({ ...invitationList?.[i], status: 'PUBLISHED' });
            if (updateStatusRes) {
              invitationList[i].status = 'PUBLISHED';
            }
          }
        }
        setTableData(invitationList);
        setPageData({
          pageNum: invitationRes?.data?.page || 1,
          pageSize: invitationRes?.data?.size || 0,
          total: invitationRes?.data?.total || 0,
        });
        setFormLoading(false);
      }
    } catch (error: any) {
      console.log(error?.message);
      setFormLoading(false);
    }
  };

  return (
    <>
      <div className={classes?.eventInvitationFilter}>
        <PruForm
          config={filterConfig(Translation, onSearch, onReset)}
          data={filterData}
          onChange={(filter) => {
            if (filter?.invitationName?.trim()?.length > 255) {
              return dispatch(
                appendAlertItem([
                  {
                    severity: AlertType.ERROR,
                    title: 'Error',
                    content: `Event Name no more than 255 characters`,
                  },
                ]),
              );
            }

            if (filter?.location?.trim()?.length > 255) {
              return dispatch(
                appendAlertItem([
                  {
                    severity: AlertType.ERROR,
                    title: 'Error',
                    content: `Location no more than 255 characters`,
                  },
                ]),
              );
            }
            setFilterData(filter);
          }}
          space={20}
          style={{ padding: 20 }}
        ></PruForm>
      </div>
      <PruForm
        config={tableHeader(Translation, addEvent, refreshData)}
        data={[]}
        onChange={() => {}}
        space={20}
        style={{
          padding: '15px 20px',
          marginTop: 20,
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        }}
      ></PruForm>

      <TableContainer component={Paper} className="table-container">
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableConfig(Translation, regionLocale).map((item) => (
                <ProTableCell key={item.prop} dataIndex={item.prop}>
                  {item.title}
                </ProTableCell>
              ))}
              <StickyTableCell className={`sticky-cell`}>
                {Translation('component.formLabel.operation')}
              </StickyTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <PruTableLoading isLoading={formLoading} />
            <PruTableEmptyRow isEmpty={tableData.length === 0} />
            {tableData.length > 0 &&
              tableData.map((row) => (
                <TableRow>
                  {tableConfig(Translation, regionLocale).map((config) => (
                    <TableCell>{config.render ? config.render(row) : row[config.prop]}</TableCell>
                  ))}
                  <StickyTableCell className={`sticky-cell`}>
                    <div className={classes.operationContainer}>
                      <Link to={`${EVENT_BASE_PATH}/invitation-letter/view/${row.id}`}>
                        <div className={`${classes.operationBtn}`} style={{ marginRight: 10 }}>
                          {Translation('section.common.operation.view')}
                        </div>
                      </Link>

                      {row?.status === 'UNPUBLISHED' && (
                        <Link to={`${EVENT_BASE_PATH}/invitation-letter/edit/${row.id}`}>
                          <div className={`${classes.operationBtn}`} style={{ marginRight: 10 }}>
                            {Translation('section.common.operation.edit')}
                          </div>
                        </Link>
                      )}
                    </div>
                  </StickyTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter component="div" className={`table-footer-css`}>
        <TableRow component="div">
          <TablePagination
            align="right"
            rowsPerPageOptions={[5, 10, 20, 50]}
            colSpan={12}
            component="div"
            count={pageData.total || 0}
            rowsPerPage={pageData.pageSize}
            page={pageData.pageNum - 1 || 0}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={(e, page) => onChangePage(page + 1)}
            onRowsPerPageChange={(e) => onChangePageRows(e.target.value)}
            ActionsComponent={PruTablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </>
  );
};

export default InvitationLetterList;
