import { Button, CircularProgress, TextField } from '@mui/material';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import Form from 'src/app/common/components/Form';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  container: {
    padding: 20,
  },
  errorText: {
    fontSize: 12,
    marginLeft: -14,
    color: 'red',
  },
}));

const PopUp: FC<any> = (props) => {
  // eslint-disable-next-line
  const {
    disabled,
    visible,
    isView,
    categoryId,
    categoryIntialValues,
    onCancel,
    onSave,
    isCategoryError,
    confirmLoading,
    updateCategoryError,
  } = props;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();
  const [form] = Form.useForm();

  const placeEnter = Translation('app.input.placeholder.please-enter');
  const emptyValue = 'This category already existed';

  const onFinish = (values: any) => {
    onSave(values);
  };

  const cancel = async () => {
    onCancel();
  };

  return (
    <>
      <Form form={form} onFinish={onFinish} initialValues={categoryIntialValues} className={classes.container}>
        <Form.Item
          name="name"
          label={`${Translation('component.formLabel.image-category')}`}
          rules={[
            {
              required: true,
              pattern: new RegExp(
                '^[a-zA-Z0-9ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ_. ]*$',
              ),
              message: 'Maximum 30 character and not include special character',
            },
          ]}
        >
          <TextField
            margin="dense"
            variant="outlined"
            fullWidth
            type="text"
            onChange={updateCategoryError}
            // helperText={isCategoryError  && <div className={classes.errorText}>{emptyValue}</div>}
            placeholder={placeEnter}
            disabled={disabled}
            inputProps={{ maxLength: 30 }}
          />
        </Form.Item>

        <Form.Item
          name="description"
          label={Translation('component.formLabel.description')}
          required={false}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <TextField
            margin="dense"
            variant="outlined"
            fullWidth
            placeholder={placeEnter}
            disabled={disabled}
            inputProps={{ maxLength: 200 }}
          />
        </Form.Item>

        <div className={classes.footerContainer}>
          {!disabled ? (
            <>
              {/* todo: cancel warling */}
              <Button variant="contained" onClick={cancel}>
                {Translation('app.button.cancel')}
              </Button>
              <Button
                disabled={confirmLoading}
                type="submit"
                style={{ marginLeft: 20 }}
                variant="contained"
                color="secondary"
                onClick={() => {}}
              >
                {Translation('app.button.submit')}
                {confirmLoading && <CircularProgress style={{ marginLeft: 8 }} size={15} />}
              </Button>
            </>
          ) : null}
        </div>
      </Form>
    </>
  );
};

export default PopUp;
