import React, { FC, useState } from 'react';
import { fetchRecruitmentSummaryReport, fetchReportDownload } from '../../../network/recruitmentSummaryReportCrud';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { getDayEnd, getDisplayDate, getFirstDayOfMonth, getDayStart } from '../../../../../common/utils/time-utils';
import PruTableTab from '../../../../../common/components/PruTableTab/PruTableTab';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTableTab/PruFilter';
import { PaginateListMore } from 'src/app/common/types/common-types';
import { ReportListParams, ReportItem } from '../../../types/recruitment-summary-types';
import { makeStyles } from 'tss-react/mui';
import { useDataProvider } from 'src/app/common/utils';
import { Align } from 'src/app/common/components/ProTable';

type PRUDNASummaryReportProps = {} & ParamsProps;

enum AgentRoleEnum {
  All = 'all',
  Agent = 'AG',
  BM = 'BM',
  UM = 'UM',
  PUM = 'PM',
  Other = 'Other',
}

enum SalesChannelEnum {
  All = 'all',
  Traditional = 'AGENCY',
  Banca = 'BANCA',
  Pruventure = 'GALLERIE',
  Other = 'Other',
}

const initialState: ReportListParams = {
  registerDateFrom: getFirstDayOfMonth(new Date()),
  registerDateTo: getDayEnd(new Date()),
  agentCode: '',
  agentName: '',
  leaderCode: '',
  leaderName: '',
  salesChannel: [SalesChannelEnum.All],
  agentRole: [AgentRoleEnum.All],
  isDisableAL: false,
  isDisableAG: false,
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): ReportListParams => {
  return initialParams
    ? {
        registerDateFrom: initialParams.registerDateFrom
          ? getDayStart(new Date(initialParams.registerDateFrom))
          : getFirstDayOfMonth(new Date()),
        registerDateTo: initialParams.registerDateTo
          ? getDayEnd(new Date(initialParams.registerDateTo))
          : getDayEnd(new Date()),
        agentCode: '',
        agentName: '',
        leaderCode: '',
        leaderName: '',
        salesChannel: [SalesChannelEnum.All],
        agentRole: [AgentRoleEnum.All],
        isDisableAL: false,
        isDisableAG: false,
        page: 1,
        limit: 5,
      }
    : initialState;
};

const salesChannelOptions = [
  { displayName: 'All', value: SalesChannelEnum.All },
  { displayName: 'Traditional Agent', value: SalesChannelEnum.Traditional },
  { displayName: 'Pruventure', value: SalesChannelEnum.Pruventure },
  { displayName: 'Banca', value: SalesChannelEnum.Banca },
  { displayName: 'Other', value: SalesChannelEnum.Other },
];

const agentRoleOptions = [
  { displayName: 'All', value: AgentRoleEnum.All },
  { displayName: 'Agent', value: AgentRoleEnum.Agent },
  { displayName: 'BM', value: AgentRoleEnum.BM },
  { displayName: 'UM', value: AgentRoleEnum.UM },
  { displayName: 'PUM', value: AgentRoleEnum.PUM },
  { displayName: 'Other', value: AgentRoleEnum.Other },
];

const formatSalesChannel = (channel: string) => {
  switch (channel) {
    case SalesChannelEnum.Traditional:
      return 'Agency';
    case SalesChannelEnum.Banca:
      return 'Banca';
    case SalesChannelEnum.Pruventure:
      return 'Pruventure';
    default:
      return 'Other';
  }
};

const formatAgentRole = (role: string) => {
  switch (role) {
    case AgentRoleEnum.All:
      return 'All';
    case AgentRoleEnum.Agent:
      return 'Agent';
    case AgentRoleEnum.BM:
      return 'BM';
    case AgentRoleEnum.UM:
      return 'UM';
    case AgentRoleEnum.PUM:
      return 'PUM';
    default:
      return 'Other';
  }
};

const useStyles = makeStyles()((theme) => ({
  disableInput: {
    '& fieldset': {
      background: '#DDD',
    },
  },
}));

const PRUDNASummaryReport: FC<PRUDNASummaryReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const defaultFilterValue = paramsInitiator(initialParams);
  const tabOptions = [
    { displayName: Translation('report.recruitment.summary.tab.potential'), value: AgentRoleEnum.All },
    { displayName: Translation('report.recruitment.summary.tab.obclass'), value: AgentRoleEnum.Agent },
    { displayName: Translation('report.recruitment.summary.tab.agent'), value: AgentRoleEnum.Agent },
  ];
  const [filterState, setFilterState] = useState<ReportListParams>(defaultFilterValue);
  const [reportList, setReportList] = useState<PaginateListMore<ReportItem>>();
  const [currentTab, setTabValue] = React.useState<number>(0);
  const onChangeTab = (event: any, tab: number) => {
    setTabValue(tab);
    refreshData();
  };
  const { isLoading, refreshData } = useDataProvider<PaginateListMore<ReportItem>>(
    async () => {
      try {
        return await fetchRecruitmentSummaryReport(filterState, currentTab, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvtDownload = () => {
    try {
      fetchReportDownload(filterState, currentTab, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('report.recruitment.summary.reportFilter')}
        itemDef={[
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'registerDateFrom',
            fieldTo: 'registerDateTo',
            initialDateFrom: filterState.registerDateFrom,
            initialDateTo: filterState.registerDateTo,
            defaultDateFrom: defaultFilterValue.registerDateFrom,
            defaultDateTo: defaultFilterValue.registerDateTo,
            displayName: Translation('report.recruitment.summary.timeRange'),
            required: true,
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            disabled: filterState.isDisableAG,
            className: filterState.isDisableAG ? classes.disableInput : null,
            displayName: Translation('report.recruitment.summary.agentCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentName',
            initialValue: filterState.agentName,
            disabled: filterState.isDisableAG,
            className: filterState.isDisableAG ? classes.disableInput : null,
            displayName: Translation('report.recruitment.summary.agentName'),
          },
          {
            type: PruFilterItemType.MULTIPLE_SELECT,
            field: 'agentRole',
            initialValue: filterState.agentRole,
            defaultValue: initialState.agentRole,
            allValue: AgentRoleEnum.All,
            displayName: Translation('report.recruitment.summary.agentRole'),
            choices: agentRoleOptions,
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'leaderCode',
            initialValue: filterState.leaderCode,
            disabled: filterState.isDisableAL,
            className: filterState.isDisableAL ? classes.disableInput : null,
            displayName: Translation('report.recruitment.summary.leaderCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'leaderName',
            initialValue: filterState.leaderName,
            disabled: filterState.isDisableAL,
            className: filterState.isDisableAL ? classes.disableInput : null,
            displayName: Translation('report.recruitment.summary.leaderName'),
          },
          {
            type: PruFilterItemType.MULTIPLE_SELECT,
            field: 'salesChannel',
            initialValue: filterState.salesChannel,
            defaultValue: initialState.salesChannel,
            allValue: SalesChannelEnum.All,
            displayName: Translation('report.recruitment.summary.salesChannel'),
            choices: salesChannelOptions,
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            agentCode: data.agentCode,
            agentName: data.agentName,
            leaderCode: data.leaderCode,
            leaderName: data.leaderName,
            registerDateFrom: data.registerDateFrom,
            registerDateTo: data.registerDateTo,
            salesChannel: data.salesChannel,
            agentRole: data.agentRole,
            isDisableAL: data.agentCode || data.agentName ? true : false,
            isDisableAG: data.leaderCode || data.leaderName ? true : false,
          })
        }
        fetchData={refreshData}
      />
      <PruTableTab
        disableBulkSelect
        dataSource={reportList?.items}
        tabItems={tabOptions}
        tabValue={currentTab}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.recruitment.summary.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvtDownload(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row: any) => row._id || '-',
          },
          {
            keyIndex: 'agentCode',
            align: 'center' as Align,
            displayName: Translation('report.recruitment.summary.agentCode'),
            renderData: (row: any) => row.agentCode || '-',
          },
          {
            keyIndex: 'agentName',
            displayName: Translation('report.recruitment.summary.agentName'),
            renderData: (row: any) => row.agentName || '-',
          },
          {
            keyIndex: 'agentRole',
            align: 'center' as Align,
            displayName: Translation('report.recruitment.summary.agentRole'),
            renderData: (row: any) => formatAgentRole(row.agentRole),
          },
          {
            keyIndex: 'leaderCode',
            align: 'center' as Align,
            displayName: Translation('report.recruitment.summary.leaderCode'),
            renderData: (row: any) => row.leaderCode || '-',
          },
          {
            keyIndex: 'leaderName',
            displayName: Translation('report.recruitment.summary.leaderName'),
            renderData: (row: any) => row.leaderName || '-',
          },
          {
            keyIndex: 'unitCode',
            align: 'center' as Align,
            displayName: Translation('report.recruitment.summary.unitCode'),
            renderData: (row: any) => row.unitCode || '-',
          },
          {
            keyIndex: 'officeCode',
            align: 'center' as Align,
            displayName: Translation('report.recruitment.summary.officeCode'),
            renderData: (row: any) => row.officeCode || '-',
          },
          ...(currentTab === 2
            ? [
                {
                  keyIndex: 'region',
                  align: 'center' as Align,
                  displayName: Translation('report.recruitment.summary.region'),
                  renderData: (row: any) => row.region,
                },
                {
                  keyIndex: 'territory',
                  align: 'center' as Align,
                  displayName: Translation('report.recruitment.summary.territory'),
                  renderData: (row: any) => row.territory,
                },
              ]
            : []),
          {
            keyIndex: 'salesChannel',
            align: 'center' as Align,
            displayName: Translation('report.recruitment.summary.salesChannel'),
            renderData: (row: any) => formatSalesChannel(row.saleChannel),
          },
          {
            keyIndex: 'noInvitations',
            align: 'center' as Align,
            displayName: Translation(
              `report.recruitment.summary.${currentTab === 2 ? 'noSelfAssessed' : 'noInvitations'}`,
            ),
            renderData: (row: any) => row[currentTab === 2 ? 'totalCompleted' : 'noInvitations'] || '-',
          },
          {
            keyIndex: 'noCompletes',
            align: 'center' as Align,
            displayName: Translation(
              `report.recruitment.summary.${currentTab === 2 ? 'noCompletedByDownlineAG' : 'noCompletes'}`,
            ),
            renderData: (row: any) => row[currentTab === 2 ? 'totalDownLineAgent' : 'noCompletes'] || '-',
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        onChangeTab={onChangeTab}
      />
    </>
  );
};

export default PRUDNASummaryReport;
