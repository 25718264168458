import React from 'react';
import { getDisplayDate } from 'src/app/common/utils/time-utils';

interface tableConfigType {
  title:string
  prop: string
  align?:any
  render?:any
  minWidth?:number,
}

const tableConfig = (Translation: any, i18ConfigData: any):tableConfigType[] => {

  if (i18ConfigData.length === 0 ) {
    return [];
  }

  return [
    { 
      title: Translation('event.ebirthday_card.table.header.templateImage'), 
      prop:'templateImage',
      render: (row: any) => row?.templateImage?.url ? (<><img 
        style={{height: "60px"}}
        src={row.templateImage.url}
        />
        <span style={{ marginLeft: 10 }}>{row.templateImage.fileName}</span>
        </>
        ) : ""
    },
    {
      title: Translation('event.ebirthday_card.table.header.publishTime'),
      prop: 'publishTime',
      render: (row: any) => (
        <span>{row.publishTime ? getDisplayDate(row.publishTime, 'YYYY-MM-DD HH:mm:ss') : ''}</span>
      )
    },
    {
      title: Translation('event.ebirthday_card.table.header.createTime'),
      prop: 'createTime',
      render: (row: any) => (
        <span>{getDisplayDate(row.createTime, 'YYYY-MM-DD HH:mm:ss')}</span>
      )
    },
    {
      title: Translation('event.ebirthday_card.table.header.modifyTime'),
      prop: 'modifyTime',
      render: (row: any) => (
        <span>{getDisplayDate(row.modifyTime, 'YYYY-MM-DD HH:mm:ss')}</span>
      )
    },
    {
      title: Translation('event.ebirthday_card.table.header.modifier'),
      prop: 'modifier'
    },
  ];
};

export default tableConfig;