import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, RadioGroup, FormControlLabel, Radio, Checkbox, TextField } from '@mui/material';
import SubFormList from './SubFormLiveStreamCreate';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { toAbsoluteUrl } from 'src/app/common/utils';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import { LivestreamFormState, FormMode, SubmitActionEnum, StatusLivestream } from '../../types/livestream-type';
import Form from 'src/app/common/components/Form';
import { ETypeOfView } from 'src/app/common/types/view-types';
import FileUploader from 'src/app/common/components/FileUploader';
import { typeOfViewLiveStream } from 'src/app/common/constants/typeOfView';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import ButtonDialog from 'src/app/common/components/ButtonDialog';
import { MANDATORY_FIELD_ERROR_TEXT } from 'src/app/common/constants';
import { LIVESTREAM_MANAGEMENT_PATH } from 'src/app/modules/Livestream/constants';
import { debounce } from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { PruDateTimePicker } from 'src/app/common/components/PruDatePicker';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  accordContainer: {
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  divideMargin: {
    // marginBottom: 10
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  accordHeading: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '15%',
    flexShrink: 0,
  },
  accordSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  addNewMaterialSetBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBg: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'scroll',
  },
  modalButtons: {
    marginTop: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  customMentionInput: {
    '& textarea': {
      borderRadius: '4px',
    },
    '& ul': {
      border: '1px solid #dedede',
    },
  },
  focusedMention: {
    backgroundColor: '#dedede',
  },
  borderError: {
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
}));

interface FormListProp {
  disabled: boolean;
  onSubmit: (submitAction: SubmitActionEnum, formData: LivestreamFormState) => void;
  history: any;
  initialValues: LivestreamFormState;
  formMode: FormMode;
  isCallingApi?: boolean;
}

let submitAction: SubmitActionEnum = SubmitActionEnum.CREATE;

const LiveStreamCreateForm: React.FC<FormListProp> = (props) => {
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const { disabled, onSubmit, history, initialValues, formMode, isCallingApi } = props;

  const [form] = Form.useForm();
  const [isSubmited, setIsSubmited] = useState<boolean>(false);

  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const [includeSA, setIncludeSA] = useState<boolean>(get(initialValues, 'userIncludeSA', false));
  const dispatch = useDispatch();

  const listStatus: Record<string, any> = {
    [StatusLivestream.COMING_SOON]: Translation('livestrean.filter.status.display.comingSoon'),
    [StatusLivestream.BROADCASTING]: Translation('livestrean.filter.status.display.broadcasting'),
    [StatusLivestream.BROADCASTED]: Translation('livestrean.filter.status.display.broadcasted'),
  };

  const [errors, SetErrors] = useState<Record<string, boolean>>({});

  const DEFAULT_REQUIRED_RULES = [
    { required: true, message: MANDATORY_FIELD_ERROR_TEXT },
    () => ({
      async validator(rule: any, value: any) {
        if (value) {
          if (errors[rule.field]) {
            SetErrors({ ...errors, [rule.field]: false });
          }
        } else {
          if (!errors[rule.field]) {
            SetErrors({ ...errors, [rule.field]: true });
          }
          throw new Error(`${rule.field} is required`);
        }
      },
    }),
  ];

  const DEFAULT_REQUIRED_RELEASE_DATE_RULES = [
    { required: true, message: MANDATORY_FIELD_ERROR_TEXT },
    () => ({
      async validator(rule: any, value: any) {
        if (value) {
          if (initialValues.status === StatusLivestream.COMING_SOON) {
            let now = new Date();
            now.setMinutes(now.getMinutes() - 1);
            if (new Date(value).getTime() <= now.getTime()) {
              if (!errors[rule.field]) {
                SetErrors({ ...errors, [rule.field]: true });
              }
              throw new Error(`Release date-time must be greater than or equal to current date-time.`);
            } else {
              if (errors[rule.field]) {
                SetErrors({ ...errors, [rule.field]: false });
              }
            }
          }
        } else {
          if (!errors[rule.field]) {
            SetErrors({ ...errors, [rule.field]: true });
          }
          throw new Error(`${rule.field} is required`);
        }
      },
    }),
  ];

  const LINK_VIDATOR = [
    () => ({
      async validator(rule: any, value: string) {
        if (value) {
          if (value.toLocaleLowerCase().startsWith('https://') || value.toLocaleLowerCase().startsWith('http://')) {
            if (errors[rule.field]) {
              SetErrors({ ...errors, [rule.field]: false });
            }
          } else {
            if (!errors[rule.field]) {
              SetErrors({ ...errors, [rule.field]: true });
            }
            throw new Error(Translation('component.form-link-text'));
          }
        } else {
          if (errors[rule.field]) {
            SetErrors({ ...errors, [rule.field]: false });
          }
          throw new Error(`${rule.field} is required`);
        }
      },
    }),
  ];

  const onFinish = () => {
    const formData = form.getFieldsValue(true);
    onSubmit(submitAction, { ...formData, startAt: initialValues.startAt, endAt: initialValues.endAt });
  };

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        { mimeType: fileType, accessLevel: 'anonymous', module: 'livestream' },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        return { id: result.blobId, fileName: fileName, url: result.url, type: fileType };
      }
    } catch (err) {
    } finally {
    }
  };

  const onChangeTypeOfView = () => {
    if (includeSA) {
      setIncludeSA(false);
      form.setFieldsValue({ userIncludeSA: false });
    }
  };

  const onChangeIncludeSA = (val: boolean) => {
    if (includeSA !== val) {
      setIncludeSA(val);
    }
    form.setFieldsValue({ userIncludeSA: val });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={initialValues}
      className={classes.container}
      validateTrigger={isSubmited ? 'onChange' : 'onSubmit'}
    >
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>
              {formMode === FormMode.EDIT
                ? Translation('component.formTitle.edit')
                : formMode === FormMode.VIEW
                ? Translation('component.formTitle.detail')
                : Translation('component.formTitle.add')}
            </div>
          </div>
          <Button variant="contained" onClick={() => history.push(`${LIVESTREAM_MANAGEMENT_PATH}`)}>
            {Translation('global.back.btnText')}
          </Button>
        </div>
        {initialValues.status && formMode !== FormMode.CREATE && (
          <div className={classes.headerContainer} style={{ marginLeft: '-2px' }}>
            <div className={classes.rowContainer}>
              <b>Status: {listStatus[initialValues.status || '']}</b>
            </div>
          </div>
        )}
        <div className={classes.divideMargin} style={{ marginLeft: '-2px', height: '32px', lineHeight: '32px' }}>
          <span className={classes.subHeader}>{Translation('component.formTitle.information')}</span>
        </div>
        {<SubFormList form={form} disabled={disabled} classes={classes} />}
      </div>
      <div className={classes.container}>
        <div className={classes.divideMargin}>
          <span className={classes.subHeader}>{Translation('component.formLabel.publish-settings')}</span>
        </div>
        <Form.Item name={'userType'} label={Translation('banner.form.typeOfView')} rules={DEFAULT_REQUIRED_RULES}>
          <RadioGroup style={{ display: 'flex', flexDirection: 'row' }} aria-label="viewType" name="viewType">
            {typeOfViewLiveStream.map((item, i) => {
              return (
                <FormControlLabel
                  key={i}
                  value={item.value}
                  control={<Radio disabled={disabled} onChange={() => onChangeTypeOfView()} />}
                  label={Translation(`component.formSelectItem.viewType.${item.label}`)}
                />
              );
            })}
          </RadioGroup>
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.userType !== currentValues.userType}>
          {(_, meta, { getFieldValue }) => {
            const userType = getFieldValue('userType');
            const userFile = getFieldValue('userFile');
            if (userType && (userType === ETypeOfView.AllTraditionalAgents || userType === ETypeOfView.AllAgents)) {
              return (
                <Form.Item name={'userIncludeSA'} style={{ marginLeft: '160px', width: 'auto' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={disabled}
                        checked={includeSA}
                        onChange={(e) => {
                          onChangeIncludeSA(e.target.checked);
                        }}
                      />
                    }
                    label={Translation('component.formSelectItem.viewType.include-sa')}
                    labelPlacement="end"
                  />
                </Form.Item>
              );
            }
            if (userType && userType === ETypeOfView.GroupOfUsers) {
              return (
                <div style={{ marginLeft: '160px' }}>
                  <Form.Item name={'userFile'} rules={DEFAULT_REQUIRED_RULES}>
                    <FileUploader
                      color="secondary"
                      disabled={disabled}
                      showAllowText={Translation('component.hint.recommend-excel-format-upload')}
                      upload={handleUploadFile}
                      allowedFileTypes={[
                        'csv',
                        'xlsx',
                        'xls',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'application/vnd.ms-excel',
                      ]}
                      maxFileSize={10}
                    />
                  </Form.Item>
                  {(!userFile || !userFile.url) && (
                    <div>
                      <a href={toAbsoluteUrl('/templates/Template_Group_Of_User.csv')}>
                        {Translation('recruitment.form.downloadTemplate')}
                      </a>
                    </div>
                  )}
                </div>
              );
            }
          }}
        </Form.Item>
        <Form.Item
          name="releaseAt"
          label={Translation('component.formLabel.release-date')}
          rules={DEFAULT_REQUIRED_RELEASE_DATE_RULES}
        >
          <PruDateTimePicker
            slotProps={{
              textField: {
                error: errors?.releaseAt,
              },
            }}
            className={classes.borderError}
            disabled={disabled}
            format="DD/MM/YYYY HH:mm"
            value={form.getFieldValue('releaseAt')} // unused
            onChange={(date) => form.setFieldsValue({ releaseAt: date })} // unused
            // KeyboardButtonProps={{ 'aria-label': 'change date' }}
          />
        </Form.Item>
        {(formMode === FormMode.VIEW || formMode === FormMode.EDIT) && (
          <Form.Item name="link" label={Translation('livestream.management.formLabel.link')} rules={LINK_VIDATOR}>
            <TextField
              className={classes.borderError}
              error={errors?.link}
              disabled={disabled}
              placeholder={Translation('app.input.placeholder.please-enter')}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </Form.Item>
        )}
        <div className={classes.footerContainer}>
          {formMode === FormMode.VIEW ? (
            <Button variant="contained" onClick={() => history.push(`${LIVESTREAM_MANAGEMENT_PATH}`)}>
              {Translation('app.button.cancel')}
            </Button>
          ) : (
            <ButtonDialog
              onOk={() => history.push(`${LIVESTREAM_MANAGEMENT_PATH}`)}
              dialogTxt={Translation('component.hint.cancel-warning')}
              btnTxt={Translation('app.button.cancel')}
            />
          )}
          {formMode === FormMode.VIEW ? (
            <>
              {initialValues.status !== StatusLivestream.BROADCASTED && (
                <Button
                  style={{ marginLeft: 20 }}
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    if (initialValues?._id) {
                      return history.push(`${LIVESTREAM_MANAGEMENT_PATH}/edit/${initialValues._id}`);
                    } else {
                      return history.push(`${LIVESTREAM_MANAGEMENT_PATH}`);
                    }
                  }}
                >
                  {Translation('app.button.edit')}
                </Button>
              )}
            </>
          ) : (
            <>
              <Button
                style={{ marginLeft: 20 }}
                variant="contained"
                color="secondary"
                disabled={isCallingApi || initialValues.status !== StatusLivestream.COMING_SOON}
                onClick={debounce(() => {
                  submitAction = initialValues?._id ? SubmitActionEnum.UPDATE : SubmitActionEnum.CREATE;
                  if (!isSubmited) {
                    setIsSubmited(true);
                  }
                  form.submit();
                }, 400)}
              >
                {formMode === FormMode.CREATE ? Translation('app.button.submit') : Translation('app.button.save')}
              </Button>

              {formMode === FormMode.EDIT && initialValues.status === StatusLivestream.COMING_SOON && (
                <Button
                  style={{ marginLeft: 20 }}
                  variant="contained"
                  color="secondary"
                  disabled={isCallingApi}
                  onClick={debounce(async () => {
                    submitAction = SubmitActionEnum.START;
                    if (!isSubmited) {
                      setIsSubmited(true);
                    }
                    form.submit();
                  }, 400)}
                >
                  {Translation('app.button.startStream')}
                </Button>
              )}
              {initialValues.status === StatusLivestream.BROADCASTING && (
                <Button
                  style={{ marginLeft: 20 }}
                  variant="contained"
                  color="secondary"
                  disabled={isCallingApi}
                  onClick={debounce(() => {
                    submitAction = SubmitActionEnum.END;
                    form.submit();
                  }, 400)}
                >
                  {Translation('app.button.endStream')}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </Form>
  );
};

export default LiveStreamCreateForm;
