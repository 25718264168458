import React from 'react';
import { Button } from '@mui/material';

const filterConfig = (Translation: any, onSearch: any, onReset: any) => {
  return [
    [
      {
        type: 'header',
        label: Translation('title.event.invitation_letter.filter'),
        sm: true,
      },
      {
        type: 'custom',
        sm: false,
        render: () => (
          <Button variant="contained" onClick={onReset}>
            {Translation('golbal.filters.reset')}
          </Button>
        ),
      },
      {
        type: 'custom',
        sm: false,
        render: () => (
          <Button variant="contained" color="secondary" onClick={onSearch}>
            {Translation('golbal.filters.search')}
          </Button>
        ),
      },
    ],
    [
      {
        type: 'input',
        label: Translation('title.event.invitation_letter.name'),
        prop: 'invitationName',
        // width: 300,
      },
      {
        type: 'dateRange',
        label: Translation('title.event.invitation_letter.created_date'),
        styles: {},
        prop: 'createdDate',
        format: 'dd/MM/yyyy HH:mm:ss',
      },
    ],
    [
      {
        type: 'dateRange',
        label: Translation('title.event.invitation_letter.duration'),
        styles: {},
        prop: 'duration',
        format: 'dd/MM/yyyy HH:mm:ss',
      },
      {
        type: 'input',
        label: Translation('title.event.invitation_letter.location'),
        prop: 'location',
        // width: 300,
      },
    ],
    [
      {
        type: 'select',
        label: Translation('title.event.invitation_letter.status'),
        prop: 'status',
        opts: [
          {
            label: Translation('event.publish.status.All.label'),
            value: '',
          },
          // {
          //   label: Translation("event.publish.status.Expired.label"),
          //   value: "EXPIRED",
          // },
          {
            label: Translation('event.publish.status.Published.label'),
            value: 'PUBLISHED',
          },
          {
            label: Translation('event.publish.status.Unpublished.label'),
            value: 'UNPUBLISHED',
          },
        ],
        // width: 300
      },
    ],
  ];
};

export default filterConfig;
