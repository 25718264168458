import React, { useState, useEffect, useMemo, useRef } from 'react';
import FormList from './components/MainForm';
import { LibraryPublishStatus, ProductType, ResourceType } from '../../constants';
import { makeStyles, withStyles } from 'tss-react/mui';
import { Backdrop, CircularProgress } from '@mui/material';
import { LibraryEditItem, LibraryItem, ResourceLibraryFormState } from '../../types/library-types';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { pathExtractor, FormMode, resetReadLog } from '../../utils/common-utils';
// import Loading from './components/Loading';
import {
  createNewLibrary,
  deleteLibrary,
  fetchLibraryItem,
  modifyLibrary,
  publishLibrary,
  unpublishLibrary,
} from '../../network/libraryCrud';
import { useLang } from 'src/app/i18n';
import { regionLocale } from 'src/app/i18n';
import { forEach } from 'lodash';
import { getEnabledCustomWeight } from 'src/app/common/utils';

// PVA
import { ETypeOfView } from 'src/app/common/types/view-types';
import { YesAndNo, LinkType, RegisterPageType } from '../../constants';
import { isSaleModule as isSaleModuleFn, isRecruitModule as isRecruitModuleFn } from '../../utils/common-utils';
import { set, cloneDeep } from 'lodash';
import { createNewTypeOfView, modifyTypeOfView } from 'src/app/common/network/typeOfViewCrud';

interface ResourceCreateProps
  extends RouteComponentProps<any, any, { selectedRows: any; type: string; allWeights: {} }> {
  blockName: string;
  blockType: string;
  addSuccessText: string;
  columnModuleType: string;
  categoryType: string;
  columnType: string;
  libraryBasePath: string;
  moduleType: string;
}

const useStyles = makeStyles()((theme) => ({
  formContainer: {
    position: 'relative',
    minHeight: '100%',
    backgroundColor: theme.palette.common.white,
  },
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  backdrop: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
    backgroundColor: `${theme.palette.common.white} !important`,
    opacity: '0.5 !important',
  },
  backdropIcon: {
    // top: '30vh',
    // position: 'absolute'
  },
}));

const DEFAULT_CONFIG = {
  coverpage: null,
  name: '',
  introduction: '',
  tag: [],
  file: null,
  link: '',
  canShared: 'N',
  sharedTitle: '',
  sharedCoverpage: null,
  sharedIntroduction: '',
  allowedForEProfile: 'N',
  prodcutType: '',
  agentType: 'all',
};

const initialState: ResourceLibraryFormState = {
  rType: 'file',
  category: '',
  productType: ProductType.Conventional,
  lastUpdatedBy: '', // todo: need to update
  status: 'Draft',
  materialSet: [],
  agent: '',
  agentType: 'all',
  moduleColumn: [
    {
      column: '',
      weight: null,
    },
  ],

  // PVA
  typeOfViews: [
    {
      type: ETypeOfView.AllTraditionalAgents,
    },
  ],
  eventInvitation: 'N',
};

// PVA
const env = window.envConfig['REACT_APP_ENV'];
const EventTemplateLink = {
  TEMPLATE1_WHITEBACKGROUND:
    env === 'prod'
      ? 'https://prudential-event-invitation-vbop.webflow.io/register-template/white-background'
      : 'https://prudential-event-invitation-vbop-dev.webflow.io/register-template/white-background',
  TEMPLATE2_BLACKBACKGROUND:
    env === 'prod'
      ? 'https://prudential-event-invitation-vbop.webflow.io/register-template/black-background'
      : 'https://prudential-event-invitation-vbop-dev.webflow.io/register-template/black-background',
  TEMPLATE3_BLUEBACKGROUND:
    env === 'prod'
      ? 'https://prudential-event-invitation-vbop.webflow.io/register-template/blue-background'
      : 'https://prudential-event-invitation-vbop-dev.webflow.io/register-template/blue-background',
};

const Create = ({
  history,
  location,
  blockName,
  blockType,
  addSuccessText,
  columnModuleType,
  categoryType,
  columnType,
  libraryBasePath,
  moduleType,
}: ResourceCreateProps) => {
  const { classes } = useStyles();
  const locale = useLang();
  const refEnabledCustomWeight = useRef<boolean>(getEnabledCustomWeight());
  const refFormSaving = useRef(false);
  const [loading, setLoading] = useState<boolean>(false);

  // PVA
  const isSaleModule = isSaleModuleFn(moduleType);
  const isRecruitModule = isRecruitModuleFn(moduleType);

  const regionLocales: any[] = regionLocale;
  const [initData, setInitData] = useState<ResourceLibraryFormState>(
    regionLocales.reduce((initData, current) => {
      initData[current] = { ...DEFAULT_CONFIG };
      return initData;
    }, initialState),
  );
  const [isInitialDataSeted, setIsInitialDataSeted] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { formMode, code } = pathExtractor(location.pathname, libraryBasePath);
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { selectedRows, type } = location.state || {};
  const [submitLocalizationsArray, setsubmitLocalizationsArray] = useState<string[]>([]);
  // const jwt = useJWT();
  const disabled = useMemo(() => {
    return formMode === FormMode.VIEW;
  }, [formMode]);

  const loadingFun = () => {
    setLoading(true);
  };

  const queryData = async (resourceId: string) => {
    setLoading(true);
    const libraryDetail = await fetchLibraryItem(resourceId);
    if (libraryDetail) {
      const param = await convertEditParams(libraryDetail, locale);
      //@ts-ignore
      setInitData(param);
      setLoading(false);
      setIsInitialDataSeted(true);
    }
  };

  const resetReadLogHandler = async (currentId: string) => {
    const data = {
      contentIds: [currentId],
    };
    await resetReadLog(data);
  };

  const convertEditParams = async (rowData: LibraryItem, lang: string) => {
    let result: ResourceLibraryFormState = { ...initialState };
    const effectiveRange: [string, string] = [
      rowData.effectiveDate ? new Date(rowData.effectiveDate).toISOString() : '',
      rowData.expiredDate ? new Date(rowData.expiredDate).toISOString() : '',
    ];
    if (refEnabledCustomWeight.current === false && rowData.publishedAt && !effectiveRange[0]) {
      // PCAAEB-39423: For those material w/o effective start time, use its last publish time
      effectiveRange[0] = new Date(rowData.publishedAt).toISOString();
    }

    // PVA
    const eventDate: [string, string] = [
      rowData.eventStartDate ? new Date(rowData.eventStartDate).toISOString() : '',
      rowData.eventEndDate ? new Date(rowData.eventEndDate).toISOString() : '',
    ];

    const columnRef: any[] = (rowData.columnReferences as any[]).map((item, index) => {
      return {
        id: item?.column?.id,
        value: item?.column?.id,
        column: item?.column?.id,
        weight: item?.weight as number,
      };
    });

    let childItemsArray: any[] = [];
    //Reorder the childItems following the data.childItemList
    if (rowData?.childItems && rowData?.childItems?.length > 0) {
      if (rowData?.materialOrder && rowData?.materialOrder?.length > 0) {
        forEach(rowData.materialOrder, (item) => {
          const childItemDetail = (rowData.childItems as LibraryItem[]).find(
            (childItem: LibraryItem) => childItem.id === item || childItem?.uuid === item,
          );
          // const childItemDetail = (rowData.childItems as LibraryItem[]).find((childItem: LibraryItem) => (childItem.id === item ));
          if (childItemDetail) {
            childItemsArray.push(childItemDetail);
          }
        });
      } else {
        childItemsArray = rowData.childItems;
      }
    }
    result = {
      ...initialState,
      effectiveRange: effectiveRange,
      rType: rowData.type ? rowData.type : '',
      category: rowData.category ? rowData.category.id : '',
      lastUpdatedBy: rowData.lastUpdatedBy ? rowData.lastUpdatedBy : '', // todo: need to update
      status: rowData.status || '',
      materialSet: childItemsArray ? childItemsArray : [],
      agent: '',
      moduleColumn: columnRef,
      designations: rowData.designations,
      zones: rowData.zones,
      parentItems: rowData.parentItems ? rowData.parentItems : [],
      agentType: rowData.agentType || '',
      targetAgentType: rowData.targetAgentType || '',
      targetAgentList: rowData.targetAgentList || [],
      agents: rowData.agents || [],
      productType: rowData?.productType || '',
      includedAgents: rowData.includedAgents || [],
      excludedAgents: rowData.excludedAgents || [],

      // PVA
      eventDate,
      typeOfViews: rowData.typeOfViews || [],
      notificationMessage: rowData?.notificationMessage || '',
      eventInvitation: rowData.eventInvitation ? YesAndNo.yes : YesAndNo.no,
      checkInUrl: rowData.checkInUrl || '',
      eventInvitationTemplate: rowData?.eventInvitationTemplate || '',
      eventInvitationRegisterPageType: rowData?.eventInvitationRegisterPageType || '',
    };

    //@ts-ignore
    result[rowData.locale] = {
      // PVA
      ...DEFAULT_CONFIG,
      ...rowData.i18nData,
      name: rowData.name,
      canShared: rowData.canShare ? 'Y' : 'N',
      allowedForEProfile: rowData.canShare && rowData.allowedForEProfile ? 'Y' : 'N',
      coverpage: rowData.coverpage || undefined,
      introduction: rowData.introduction || '',
      tag: rowData.tag || [],

      //PVA
      eventAddress: rowData?.eventAddress || '',
    };

    if (rowData?.localizations && rowData?.localizations?.length >= 1) {
      for (var index = 0; index < rowData?.localizations?.length; index++) {
        //@ts-ignore
        const itemResult = await fetchLibraryItem(rowData.localizations[index].id, dispatch);
        //@ts-ignore
        result[itemResult.locale] = {
          // PVA
          ...DEFAULT_CONFIG,

          ...itemResult.i18nData,
          name: itemResult.name,
          canShared: itemResult.canShare ? 'Y' : 'N',
          allowedForEProfile: itemResult.canShare && itemResult.allowedForEProfile ? 'Y' : 'N',
          coverpage: itemResult.coverpage || undefined,
          introduction: itemResult.introduction || '',
          tag: itemResult.tag || [],

          // PVA
          eventAddress: itemResult?.eventAddress || '',
        };
        if (index === rowData.localizations?.length - 1) {
          return result;
        }
      }
    } else {
      return result;
    }
  };

  // PVA
  const convertI18nData = (rType: string, localeData: any) => {
    let temp = {};
    if (rType === ResourceType.file || rType === ResourceType.picture) {
      temp = localeData.file ? { file: localeData.file, sharedIntroduction: localeData?.sharedIntroduction || '' } : {};
    } else if (rType === ResourceType.link) {
      let linkDetail;
      switch (localeData.linkType) {
        case LinkType.UrlWithVideo:
          linkDetail = { linkType: localeData.linkType, videos: localeData.videos };
          break;
        case LinkType.UrlWithInfor:
          linkDetail = {
            linkType: localeData.linkType,
            link: localeData.link,
            information: localeData.information,
            body: localeData.body,
          };
          break;
        case LinkType.Url:
        default:
          linkDetail = { linkType: localeData.linkType, link: localeData.link };
          break;
      }
      temp = {
        sharedIntroduction: localeData.sharedIntroduction,
        sharedTitle: localeData.sharedTitle,
        ...linkDetail,
      };
    } else if (rType === ResourceType.content || rType === ResourceType.text) {
      temp = { content: localeData.content, sharedIntroduction: localeData?.sharedIntroduction || '' };
    }
    if (isSaleModule) {
      set(temp, 'quickQuote', localeData.quickQuote);
    }
    return temp;
  };

  const submitForm = async (formData: ResourceLibraryFormState) => {
    // PVA
    setLoading(true);

    if (refFormSaving.current) {
      return; // avoid trigger multiple times
    }
    // @ts-ignore
    const localeData = formData[locale];
    const columnArray: string[] = [];
    const columnRef: any[] = [];
    formData.moduleColumn.map((item, index) => {
      if (item.column) {
        columnArray.push(item.column);
        return columnRef.push({
          column: item.column,
          module: moduleType,
          weight: item.weight as number,
        });
      } else {
        return null;
      }
    });
    // COE
    //const hasFile = formData.rType === ResourceType.file || formData.rType === ResourceType.picture;

    // PVA Not Use
    // const hasFile = formData.rType === ResourceType.file || formData.rType === ResourceType.picture;

    // COE
    //  const localeI18nData = {
    //   ...(hasFile && localeData.file
    //     ? {
    //         file: localeData.file,
    //       }
    //     : {}),
    //   ...(formData.rType === ResourceType.link
    //     ? {
    //         link: localeData.link,
    //       }
    //     : {}),
    //   ...(formData.rType === ResourceType.content || formData.rType === ResourceType.text
    //     ? {
    //         content: localeData.content,
    //       }
    //     : {}),
    //   sharedIntroduction: localeData.sharedIntroduction || '',
    // };

    // PVA
    let localeI18nData = {};
    if (
      formData.eventInvitationRegisterPageType === RegisterPageType.template &&
      formData.eventInvitation === 'Y' &&
      formData.rType === ResourceType.link &&
      isRecruitModule
    ) {
      if (formData.eventInvitationTemplate === 'TEMPLATE1_WHITEBACKGROUND') {
        localeI18nData = {
          link: EventTemplateLink.TEMPLATE1_WHITEBACKGROUND,
        };
      } else if (formData.eventInvitationTemplate === 'TEMPLATE2_BLACKBACKGROUND') {
        localeI18nData = {
          link: EventTemplateLink.TEMPLATE2_BLACKBACKGROUND,
          sharedIntroduction: localeData.sharedIntroduction || '',
        };
      } else if (formData.eventInvitationTemplate === 'TEMPLATE3_BLUEBACKGROUND') {
        localeI18nData = {
          link: EventTemplateLink.TEMPLATE3_BLUEBACKGROUND,
        };
      }
    } else {
      localeI18nData = convertI18nData(formData.rType, localeData);
    }

    let childItemList = [];
    let childItemUUIDList = [];
    if (formData.rType === ResourceType.materialSet) {
      childItemList = formData.materialSet.map((item) => {
        return item.id;
      });

      childItemUUIDList = formData.materialSet.map((item) => {
        return item.uuid; // Todo: need to handle for LBU ,(use uuid for HK only)
      });
    }
    const currentLocaleSubmitData: LibraryEditItem = {
      canShare: localeData.canShared === 'Y',
      allowedForEProfile: localeData.canShared === 'Y' && localeData.allowedForEProfile === 'Y',
      category: formData.category ? formData.category : null,
      columnReferences: columnRef.length > 0 ? columnRef : [],
      coverpage: localeData.coverpage,
      designations: formData.designations || [],
      effectiveDate:
        formData.effectiveRange && formData.effectiveRange[0] ? new Date(formData.effectiveRange[0]) : null,
      expiredDate: formData.effectiveRange && formData.effectiveRange[1] ? new Date(formData.effectiveRange[1]) : null,
      i18nData: localeI18nData,
      introduction: localeData.introduction,
      module: moduleType,
      name: localeData.name,
      publishToSetOnly: formData.status === LibraryPublishStatus.PublishToSetOnly,
      tag: localeData.tag || [],
      type: formData.rType,
      zones: formData.zones || [],
      columnIds: columnArray,
      childItems: childItemList ? childItemList : [],
      materialOrder: childItemUUIDList ? childItemUUIDList : [],
      status: formData.status,
      productType: formData.productType || ProductType.Conventional,
      agentType: formData.agentType || '',
      targetAgentType: formData.targetAgentType || '',
      targetAgentList: formData.targetAgentList || [],
      agents: formData.agents || [],
      includedAgents: formData.includedAgents || [],
      excludedAgents: formData.excludedAgents || [],

      // PVA
      eventInvitation: formData.eventInvitation === 'Y',
      eventStartDate: formData.eventDate && formData.eventDate[0] ? new Date(formData.eventDate[0]) : null,
      eventEndDate: formData.eventDate && formData.eventDate[1] ? new Date(formData.eventDate[1]) : null,
      checkInUrl: formData.checkInUrl || '',
      eventInvitationTemplate: formData?.eventInvitationTemplate || '',
      eventAddress: localeData?.eventAddress || '',
      eventInvitationRegisterPageType: formData?.eventInvitationRegisterPageType || '',
      typeOfViews: [],
    };

    // PVA
    if (isSaleModule) {
      currentLocaleSubmitData.notificationMessage = formData.notificationMessage || '';
    }

    //Remove from material set if published
    if (formData.status === LibraryPublishStatus.Unpublished) {
      currentLocaleSubmitData[`parentItems`] = [];
    }

    // PVA
    // check change typeOfView
    if (!code) {
      for (let i = 0; i < formData?.typeOfViews?.length; i++) {
        const newViewType = await createNewTypeOfView(formData?.typeOfViews?.[i], dispatch);
        if (newViewType && newViewType.id) {
          currentLocaleSubmitData.typeOfViews?.push(newViewType.id);
        }
      }
    }

    refFormSaving.current = true;
    if (code) {
      //PVA
      for (let i = 0; i < formData?.typeOfViews?.length; i++) {
        let itemTypeOfView = initData?.typeOfViews?.find((x) => x.type === formData?.typeOfViews[i]?.type);
        if (itemTypeOfView && itemTypeOfView.id) {
          if (
            formData?.typeOfViews[i]?.type === ETypeOfView.GroupOfUsers &&
            (!itemTypeOfView?.userFile?.id || formData?.typeOfViews[i]?.userFile?.id !== itemTypeOfView?.userFile?.id)
          ) {
            const viewType = await modifyTypeOfView(itemTypeOfView.id, formData.typeOfViews[i], dispatch);
            if (viewType.id) {
              currentLocaleSubmitData.typeOfViews?.push(viewType.id);
            }
          } else if (
            formData?.typeOfViews[i]?.type === ETypeOfView.AllTraditionalAgents &&
            formData?.typeOfViews[i]?.includeSA !== itemTypeOfView?.includeSA
          ) {
            const newViewType = await createNewTypeOfView(formData?.typeOfViews[i], dispatch);
            if (newViewType?.id) {
              currentLocaleSubmitData.typeOfViews?.push(newViewType.id);
            }
          } else {
            currentLocaleSubmitData.typeOfViews?.push(itemTypeOfView.id);
          }
        } else {
          const newViewType = await createNewTypeOfView(formData?.typeOfViews[i], dispatch);
          if (newViewType && newViewType.id) {
            currentLocaleSubmitData.typeOfViews?.push(newViewType.id);
          }
        }
      }

      //Edit
      await callSubmitEditAction(code, currentLocaleSubmitData, formData, true).finally(
        () => (refFormSaving.current = false),
      );
    } else {
      // Create
      await callSubmitCreateAction(currentLocaleSubmitData, formData, locale, true).finally(
        () => (refFormSaving.current = false),
      );
    }
  };

  const callSubmitEditAction = async (
    selectedId: string,
    sumbitData: LibraryEditItem,
    formData: ResourceLibraryFormState,
    isOriginalLocale: boolean = false,
  ) => {
    try {
      const resultData = await modifyLibrary(selectedId, sumbitData, dispatch);
      const localizationArray = submitLocalizationsArray;
      localizationArray.push(resultData.id as string);
      setsubmitLocalizationsArray(localizationArray);

      // COE
      // console.log("resultData",resultData);
      // if (formData.status === LibraryPublishStatus.Unpublished && resultData.publishedAt) { //If existing is in Publish mode, and user want to save as draft
      //   await unpublishLibrary(resultData.id);
      // }
      // if ((formData.status === LibraryPublishStatus.Publish || formData.status === LibraryPublishStatus.PublishToSetOnly) && !resultData.publishedAt) { //If existing is in draft mode, and user want to save as publish
      //   await publishLibrary(resultData.id);
      // }

      //PVA
      if (formData.status === LibraryPublishStatus.Unpublished && resultData.publishedAt) {
        //If existing is in Publish mode, and user want to save as draft
        await unpublishLibrary(resultData.id);
      }
      if (
        (formData.status === LibraryPublishStatus.Publish ||
          formData.status === LibraryPublishStatus.PublishToSetOnly) &&
        !resultData.publishedAt
      ) {
        //If existing is in draft mode, and user want to save as publish
        await publishLibrary(resultData.id);
      }

      if (isOriginalLocale) {
        //Call edit action for other language
        for (let i = 0; i < regionLocales.length; i++) {
          const regionLocaleItem = regionLocales[i];
          if (regionLocaleItem !== resultData.locale) {
            const localeItem = resultData.localizations?.filter((item) => regionLocaleItem === item.locale)[0];
            // @ts-ignore
            const localeData = formData[regionLocaleItem];
            const hasFile = formData.rType === ResourceType.file || formData.rType === ResourceType.picture;

            // COE
            // const localeI18nData = {
            //      ...(hasFile && localeData.file
            //        ? {
            //             file: localeData.file,
            //          }
            //        : {}),
            //     ...(formData.rType === ResourceType.link
            //         ? {
            //            link: localeData.link,
            //           }
            //         : {}),
            //     ...(formData.rType === ResourceType.content || formData.rType === ResourceType.text
            //         ? {
            //             content: localeData.content,
            //           }
            //         : {}),

            // PVA
            let localeI18nData = {};
            if (
              formData.eventInvitationRegisterPageType === RegisterPageType.template &&
              formData.eventInvitation === 'Y' &&
              formData.rType === ResourceType.link &&
              isRecruitModule
            ) {
              if (formData.eventInvitationTemplate === 'TEMPLATE1_WHITEBACKGROUND') {
                localeI18nData = {
                  link: EventTemplateLink.TEMPLATE1_WHITEBACKGROUND,
                };
              } else if (formData.eventInvitationTemplate === 'TEMPLATE2_BLACKBACKGROUND') {
                localeI18nData = {
                  link: EventTemplateLink.TEMPLATE2_BLACKBACKGROUND,
                };
              } else if (formData.eventInvitationTemplate === 'TEMPLATE3_BLUEBACKGROUND') {
                localeI18nData = {
                  link: EventTemplateLink.TEMPLATE3_BLUEBACKGROUND,
                };
              }
            } else {
              localeI18nData = convertI18nData(formData.rType, localeData);
            }

            let childItemList: string[] = [];
            let childItemUUIDList: string[] = [];
            if (formData.rType === ResourceType.materialSet) {
              for (let i = 0; i < formData.materialSet?.length; i++) {
                for (let j = 0; j < formData?.materialSet[i]?.localizations?.length; j++) {
                  const id = formData.materialSet[i].localizations[j].id;
                  const localizationsItemDetail = await fetchLibraryItem(id);

                  if (localizationsItemDetail.locale === regionLocaleItem) {
                    childItemList.push(id);
                    childItemUUIDList.push(localizationsItemDetail.uuid ? localizationsItemDetail.uuid : id);
                    // Todo: need to handle for LBU ,(use uuid for HK only)
                  }
                }
              }
            }

            const newLocalSubmitData = {
              ...sumbitData,
              name: localeData.name,
              i18nData: localeI18nData,
              canShare: localeData.canShared === 'Y',
              allowedForEProfile: localeData.canShared === 'Y' && localeData.allowedForEProfile === 'Y',
              introduction: localeData.introduction,
              tag: localeData.tag,
              coverpage: localeData.coverpage,
              localizations: localizationArray,
              childItems: childItemList ? childItemList : [],
              materialOrder: childItemUUIDList ? childItemUUIDList : [],

              // PVA
              eventAddress: localeData?.eventAddress || '',
            };

            //Remove from material set if published
            if (formData.status === LibraryPublishStatus.Unpublished) {
              newLocalSubmitData[`parentItems`] = [];
            }

            if (localeItem) {
              await callSubmitEditAction(localeItem.id, newLocalSubmitData, formData, false);
            } else {
              await callSubmitCreateAction(newLocalSubmitData, formData, regionLocaleItem, false, resultData);
            }
          }
        }
        submitSuccess();
      }
    } catch (err) {
      console.log(err);
      submitFail();
    }
  };

  const callSubmitCreateAction = async (
    sumbitData: LibraryEditItem,
    formData: ResourceLibraryFormState,
    locale: string,
    isOriginalLocale: boolean = false,
    prevResultData?: LibraryItem,
  ) => {
    try {
      const resultData = await createNewLibrary(sumbitData, locale, prevResultData ? prevResultData.id : '', dispatch);
      const localizationArray = submitLocalizationsArray;
      localizationArray.push(resultData.id as string);
      setsubmitLocalizationsArray(localizationArray);

      // COE
      // if (formData.status === LibraryPublishStatus.Publish || formData.status === LibraryPublishStatus.PublishToSetOnly) { // call publish again
      // await publishLibrary(resultData.id, dispatch);
      // }

      // PVA
      // call publish again
      if (
        formData.status === LibraryPublishStatus.Publish ||
        formData.status === LibraryPublishStatus.PublishToSetOnly
      ) {
        // call publish again
        await publishLibrary(resultData.id, dispatch);
      }

      if (isOriginalLocale) {
        //Call create action for other language
        for (let i = 0; i < regionLocales.length; i++) {
          const localItem = regionLocales[i];
          if (localItem !== locale) {
            // @ts-ignore
            const localeData = formData[localItem];

            // COE
            // const hasFile = formData.rType === ResourceType.file || formData.rType === ResourceType.picture;

            // PVA not use
            // const hasFile = formData.rType === ResourceType.file || formData.rType === ResourceType.picture;

            // COE
            // const localeI18nData = {
            //   ...(hasFile && localeData.file
            //     ? {
            //         file: localeData.file,
            //       }
            //     : {}),
            //   ...(formData.rType === ResourceType.link
            //     ? {
            //         link: localeData.link,
            //       }
            //     : {}),
            //   ...(formData.rType === ResourceType.content || formData.rType === ResourceType.text
            //     ? {
            //         content: localeData.content,
            //       }
            //     : {}),
            //   sharedIntroduction: localeData.sharedIntroduction || '',
            // };

            // PVA
            let localeI18nData = {};
            if (
              formData.eventInvitationRegisterPageType === RegisterPageType.template &&
              formData.eventInvitation === 'Y' &&
              formData.rType === ResourceType.link &&
              isRecruitModule
            ) {
              if (formData.eventInvitationTemplate === 'TEMPLATE1_WHITEBACKGROUND') {
                localeI18nData = {
                  link: EventTemplateLink.TEMPLATE1_WHITEBACKGROUND,
                  sharedIntroduction: localeData.sharedIntroduction || '',
                };
              } else if (formData.eventInvitationTemplate === 'TEMPLATE2_BLACKBACKGROUND') {
                localeI18nData = {
                  link: EventTemplateLink.TEMPLATE2_BLACKBACKGROUND,
                  sharedIntroduction: localeData.sharedIntroduction || '',
                };
              } else if (formData.eventInvitationTemplate === 'TEMPLATE3_BLUEBACKGROUND') {
                localeI18nData = {
                  link: EventTemplateLink.TEMPLATE3_BLUEBACKGROUND,
                  sharedIntroduction: localeData.sharedIntroduction || '',
                };
              }
            } else {
              localeI18nData = convertI18nData(formData.rType, localeData);
            }

            let childItemList: string[] = [];
            let childItemUUIDList: string[] = [];
            if (formData.rType === ResourceType.materialSet) {
              for (let i = 0; i < formData?.materialSet?.length; i++) {
                for (let j = 0; j < formData?.materialSet[i]?.localizations?.length; j++) {
                  const id = formData.materialSet[i].localizations[j].id;
                  const localizationsItemDetail = await fetchLibraryItem(id);
                  if (localizationsItemDetail.locale === localItem) {
                    childItemList.push(id);
                    childItemUUIDList.push(localizationsItemDetail.uuid ? localizationsItemDetail.uuid : id);
                    // Todo: need to handle for LBU ,(use uuid for HK only)
                  }
                }
              }
            }

            const newLocalSubmitData = {
              ...sumbitData,
              name: localeData.name,
              i18nData: localeI18nData,
              canShare: localeData.canShared === 'Y',
              allowedForEProfile: localeData.canShared === 'Y' && localeData.allowedForEProfile === 'Y',
              introduction: localeData.introduction,
              tag: localeData.tag,
              localizations: localizationArray,
              coverpage: localeData.coverpage,
              childItems: childItemList ? childItemList : [],
              materialOrder: childItemUUIDList ? childItemUUIDList : [],

              // PVA
              eventAddress: localeData?.eventAddress || '',
            };

            //Remove from material set if published
            if (formData.status === LibraryPublishStatus.Unpublished) {
              newLocalSubmitData[`parentItems`] = [];
            }

            // COE
            //await callSubmitCreateAction(newLocalSubmitData, formData, localItem, false, resultData);
            // PVA
            try {
              await callSubmitCreateAction(newLocalSubmitData, formData, localItem, false, resultData);
            } catch (err) {
              await deleteLibrary(resultData.id);
              console.log(err);
              submitFail();
            }
          }
        }
        submitSuccess();
      }
    } catch (err) {
      console.log(err);
      submitFail();
    }
  };

  const submitSuccess = async () => {
    if (blockType === 'promotionMaterial' && code) {
      await resetReadLogHandler(code);
    }
    setsubmitLocalizationsArray([]);
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: Translation('global.submit.success'),
          content: Translation(addSuccessText),
        },
      ]),
    );

    history.push(libraryBasePath);
    setLoading(false);
  };

  const submitFail = () => {
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.ERROR,
          title: Translation('global.submit.fail'),
          content: Translation('global.submit.fail'),
        },
      ]),
    );
    setLoading(false);
  };

  useEffect(() => {
    if (code) {
      queryData(code);
    } else if (type) {
      setInitData({ ...initData, rType: 'materialSet', materialSet: [...selectedRows] });
      setIsInitialDataSeted(true);
    } else {
      setIsInitialDataSeted(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.formContainer}>
      {isInitialDataSeted ? (
        <FormList
          // COE
          //initialValues={{ ...initData }}

          // PVA
          initialValues={cloneDeep(initData)}
          onSave={submitForm}
          disabled={disabled}
          blockName={blockName}
          categoryType={categoryType}
          blockType={blockType}
          columnType={columnType}
          isEdit={formMode === FormMode.EDIT}
          history={history}
          loadingFun={loadingFun}
          moduleType={moduleType}
          currentId={code || ''}
        />
      ) : null}

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="secondary" className={classes.backdropIcon} />
      </Backdrop>
    </div>
    // </div>
  );
};

export default Create;
