import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { PruDatePicker } from 'src/app/common/components/PruDatePicker';
import { useIntl } from 'react-intl';

interface RangePickerProps {
  value?: any[];
  errors?: boolean[];
  helperTexts?: string[];
  onChange?: (e: any[]) => void;
  label?: string;
  format?: string;
  disabled?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
  },
  divider: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dividerLabel: {
    display: 'flex',
    minWidth: 100,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const isDateFormat = (date: Date | null) => {
  return date instanceof Date && !isNaN(date.getTime());
};

export default function RangePicker(props: RangePickerProps) {
  const { classes } = useStyles();
  const { value = [], onChange } = props;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const handleChange = (key: number) => (date: Date | null) => {
    if (!isDateFormat(date)) {
      date = null;
    }
    if (!onChange) return;
    const newValue = [...(value || [])];
    newValue[key] = date;
    onChange(newValue);
  };

  const placeSelect = Translation('app.input.placeholder.please-select');

  return (
    <div className={classes.container}>
      <PruDatePicker
        maxDate={(value && value[1]) || null}
        slotProps={{
          textField: {
            error: props.errors && !!props.errors[0],
            helperText: props.helperTexts && props.helperTexts[0],
          },
        }}
        format="DD/MM/YYYY"
        value={(value && value[0]) || null}
        disabled={props.disabled}
        onChange={handleChange(0)}
        label={placeSelect}
      />

      <div className={props.label ? classes.dividerLabel : classes.divider}>{props.label ? props.label : '-'}</div>
      <PruDatePicker
        minDate={(value && value[0]) || null}
        slotProps={{
          textField: {
            error: props.errors && !!props.errors[1],
            helperText: props.helperTexts && props.helperTexts[1],
          },
        }}
        format="DD/MM/YYYY"
        value={(value && value[1]) || null}
        onChange={handleChange(1)}
        disabled={props.disabled}
        label={placeSelect}
      />
    </div>
  );
}
