import { forEach } from 'lodash';
import { PaginateListMore } from '../../../common/types/common-types';
import { Dispatch } from 'react';
import { RegistrationCandidateReportListParams } from '../types/registrationCandidateReportTypes';
import { apiClient, apiErrorHandler } from 'src/app/common/network';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

const formatDataFilters = (params: RegistrationCandidateReportListParams) => {
  let data: any = {
    dataFilters: [],
    page: params.page,
    limit: params.limit,
  };
  forEach(params, (param, key) => {
    switch (key) {
      case 'requestedDateFrom':
        data.dataFilters.push({
          name: 'createdAt',
          value: param,
          type: 'Date',
          operator: 'gt',
        });
        break;
      case 'requestedDateTo':
        data.dataFilters.push({
          name: 'createdAt',
          value: param,
          type: 'Date',
          operator: 'lt',
        });
        break;
      case 'unitCode':
        if (param != '') {
          data.dataFilters.push({
            name: 'leader.unitCode',
            value: param,
            type: 'string',
            operator: 'search',
          });
        }
        break;
      case 'channel':
        if (param != '') {
          data.dataFilters.push({
            name: 'channel',
            value: param,
            type: 'string',
            operator: 'eq',
          });
        }
        break;
    }
  });
  return data;
};

export const fetchRegistrationCandidateReportDownload = (
  params: RegistrationCandidateReportListParams,
  dispatch?: Dispatch<any>,
): void => {
  params.limit = 999999999;
  const uri = `${ADMIN_URL}/report/candidate/pre-onboarding/registration-candidate/export`;
  const data = formatDataFilters(params);

  apiClient
    .post<Blob>(uri, data, { responseType: 'text' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report-registration-candidate' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchRegistrationCandidateReport = async (
  params: RegistrationCandidateReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<any>> => {
  const uri = `${ADMIN_URL}/report/candidate/pre-onboarding/registration-candidate`;
  const data = formatDataFilters(params);
  return apiClient
    .post<PaginateListMore<any>>(uri, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
