import React, { FC, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { PaginateList } from 'src/app/common/types/common-types';
import { useDataProvider } from 'src/app/common/utils';
import { fetchList, MdrtBannerListParams } from '../../../network/mdrtBannerCrud';
import { BannerPublishedMode, BannerValidMode, MdrtBannerItem, PublishStatus } from '../../../types/mdrt-banner-types';
import MdrtBannerList from './components/MdrtBannerList';
import { RegionLocale, getConfig } from 'src/app/i18n';

type MdrtListingPageProps = {} & ParamsProps;

export const filterKeys = ['name', 'publishStatus', 'updatedAtFrom', 'updatedAtTo', 'isValid'];

const initialState: MdrtBannerListParams = {
  name: '',
  publishStatus: '',
  updatedAtFrom: null,
  updatedAtTo: null,
  isValid: '',
  lang: '',
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, any>): MdrtBannerListParams => {
  return initialParams
    ? {
        name: initialParams.name || '',
        publishStatus: initialParams.publishStatus || '',
        updatedAtFrom: initialParams.updatedAtFrom || null,
        updatedAtTo: initialParams.updatedAtTo || null,
        isValid: initialParams.isValid || '',
        lang: initialParams.lang || '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const MdrtBannerListingPage: FC<MdrtListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const lang = useMemo(() => {
    let result = getConfig();
    return result && result?.selectedLang ? result?.selectedLang : RegionLocale.VIETNAMESE;
  }, []);

  const filterOptions = {
    publishStatus: [
      {
        displayName: Translation('component.status.all'),
        value: BannerPublishedMode.ALL,
      },
      {
        displayName: Translation('component.status.published'),
        value: PublishStatus.PUBLISHED,
      },
      {
        displayName: Translation('component.status.unpublished'),
        value: PublishStatus.UNPUBLISHED,
      },
    ],
    validStatus: [
      {
        displayName: Translation('component.status.all'),
        value: BannerValidMode.ALL,
      },
      {
        displayName: Translation('component.status.valid'),
        value: BannerValidMode.VALID,
      },
      {
        displayName: Translation('component.status.invalid'),
        value: BannerValidMode.INVALID,
      },
    ],
  };

  const [filterState, setFilterState] = useState<MdrtBannerListParams>(paramsInitiator({ ...initialParams, lang }));
  const [itemList, setItemList] = useState<PaginateList<MdrtBannerItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<MdrtBannerItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchList(filterState, dispatch);
      } catch (err) {}
    },
    setItemList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('mdrtHub.mdrtBanner.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'name',
            initialValue: filterState.name,
            displayName: Translation('component.formLabel.name'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 150 },
            field: 'publishStatus',
            initialValue: filterState.publishStatus,
            displayName: Translation('component.formLabel.publish-status'),
            choices: filterOptions.publishStatus,
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'updatedAtFrom',
            fieldTo: 'updatedAtTo',
            initialDateFrom: filterState.updatedAtFrom,
            initialDateTo: filterState.updatedAtTo,
            displayName: Translation('mdrtHub.mdrtBanner.list.lastUpdatedDate'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 150 },
            field: 'isValid',
            initialValue: filterState.isValid,
            displayName: Translation('component.formLabel.valid-status'),
            choices: filterOptions.validStatus,
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            name: data.name,
            publishStatus: data.publishStatus,
            updatedAtFrom: data.updatedAtFrom,
            updatedAtTo: data.updatedAtTo,
            isValid: data.isValid,
          })
        }
        fetchData={refreshData}
      />
      <MdrtBannerList
        isLoading={isLoading}
        onRefresh={refreshData}
        itemList={itemList}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default MdrtBannerListingPage;
