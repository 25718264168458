import React, { FC, useState } from 'react';
import { PaginateList } from '../../../../../../common/types/common-types';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../../common/components/ParamsProvider';
import { getDayStart, getDayEnd, getDisplayDate } from '../../../../../../common/utils/time-utils';
import PruTable from '../../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../../common/components/PruTable/PruFilter';
import { SharingActivityItem, SharingActivityParams } from 'src/app/modules/Report/types/mdrt-hub-types';
import {
  fetchSharingActivityDownload,
  fetchSharingActivityList,
} from 'src/app/modules/Report/network/mdrtHubReportCrud';
import { makeStyles } from 'tss-react/mui';

type SharingActivityListingPageProps = {} & ParamsProps;

const featureOptions = [
  { displayName: 'All', value: '' },
  { displayName: 'MDRT Achievement', value: 'ACHIEVEMENT' },
  { displayName: 'MDRT Certificate', value: 'CERTIFICATE' },
  { displayName: 'MDRT Story', value: 'STORY' },
  { displayName: 'MDRT Passport ', value: 'PASSPORT' },
  { displayName: 'MDRT Video', value: 'VIDEO' },
];

const initialState: SharingActivityParams = {
  lastUpdatedDateFrom: null,
  lastUpdatedDateTo: null,
  agentCode: '',
  sharedAgentCode: '',
  feature: '',
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): SharingActivityParams => {
  return initialParams
    ? {
        lastUpdatedDateFrom: initialParams.lastUpdatedDateFrom
          ? getDayStart(new Date(initialParams.lastUpdatedDateFrom))
          : null,
        lastUpdatedDateTo: initialParams.lastUpdatedDateTo
          ? getDayEnd(new Date(initialParams.lastUpdatedDateTo))
          : null,
        agentCode: initialParams.agentCode ? initialParams.agentCode : '',
        sharedAgentCode: initialParams.sharedAgentCode ? initialParams.sharedAgentCode : '',
        feature: initialParams.feature ? initialParams.feature : '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const useStyles = makeStyles()(() => ({
  btnContainer: {
    display: 'flex',
    marginBottom: 15,
    'flex-direction': 'row-reverse',
  },
}));

const SharingActivityListingPage: FC<SharingActivityListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const [filterState, setFilterState] = useState<SharingActivityParams>(paramsInitiator(initialParams));

  const [reportList, setReportList] = useState<PaginateList<SharingActivityItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<SharingActivityItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchSharingActivityList(filterState, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvDownload = () => {
    try {
      fetchSharingActivityDownload(filterState, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('mdrtHub.report.sharingActivity.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('mdrtHub.report.sharingActivity.agentCode'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'feature',
            initialValue: filterState.feature,
            displayName: Translation('mdrtHub.report.sharingActivity.feature'),
            choices: featureOptions,
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'lastUpdatedDateFrom',
            fieldTo: 'lastUpdatedDateTo',
            initialDateFrom: filterState.lastUpdatedDateFrom,
            initialDateTo: filterState.lastUpdatedDateTo,
            displayName: Translation('mdrtHub.report.sharingActivity.lastUpdatedDate'),
            defaultDateFrom: initialState.lastUpdatedDateFrom,
            defaultDateTo: initialState.lastUpdatedDateTo,
            periodTime: { months: 3 },
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'sharedAgentCode',
            initialValue: filterState.sharedAgentCode,
            displayName: Translation('mdrtHub.report.sharingActivity.sharedAgentCode'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            lastUpdatedDateFrom: data.lastUpdatedDateFrom,
            lastUpdatedDateTo: data.lastUpdatedDateTo,
            agentCode: data.agentCode,
            feature: data.feature,
            sharedAgentCode: data.sharedAgentCode,
          })
        }
        fetchData={refreshData}
        isClearDateRange={true}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('mdrtHub.report.sharingActivity.list')}
        operationDef={[]}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvDownload(),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: () => '',
          },
          {
            keyIndex: 'agentCode',
            align: 'center',
            displayName: Translation('mdrtHub.report.sharingActivity.agentCode'),
            sortable: true,
            renderData: (row) => row.agentCode,
          },
          {
            keyIndex: 'Feature',
            align: 'center',
            displayName: Translation('mdrtHub.report.sharingActivity.feature'),
            sortable: true,
            renderData: (row) => row.featureName,
          },
          {
            keyIndex: 'sharedAgentCode',
            align: 'center',
            sortable: true,
            displayName: Translation('mdrtHub.report.sharingActivity.sharedAgentCode'),
            renderData: (row) => row.sharedAgentCode,
          },
          {
            keyIndex: 'numberOfShares',
            align: 'center',
            sortable: true,
            displayName: Translation('mdrtHub.report.sharingActivity.numberOfShares'),
            renderData: (row) => (row.totalShare || 0).toString(),
          },
          {
            keyIndex: 'lastUpdatedDate',
            align: 'center',
            sortable: true,
            displayName: Translation('mdrtHub.report.sharingActivity.lastUpdatedDate'),
            renderData: (row) => (row.updatedAt ? getDisplayDate(row.updatedAt, 'YYYY/MM/DD HH:mm') : ''),
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.total}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default SharingActivityListingPage;
