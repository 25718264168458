import { forEach } from 'lodash';
import { PaginateListMore } from '../../../common/types/common-types';
import { Dispatch } from 'react';
import { apiClient, apiErrorHandler } from 'src/app/common/network';
import {
  IReportFilter,
  IPersonalSalePlanningItem,
  ITeamSalePlanningItem,
  ITotalSalePlanning,
} from '../types/sale-planning-types';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

export const fetchPersonalSalePlanningReportFile = (params: IReportFilter, dispatch?: Dispatch<any>): void => {
  // const paramsUrl = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
  const url = `${ADMIN_URL}/report/personal-sale-planning/export`;

  apiClient
    .post(url, params, { responseType: 'text' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'personal-sale-planning-report-' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchPersonalSalePlanningReport = async (
  params: IReportFilter,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<IPersonalSalePlanningItem>> => {
  // const paramsUrl = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
  const url = `${ADMIN_URL}/report/personal-sale-planning`;

  return apiClient
    .post(url, params)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchTotalRecordPersonalSalePlanningReport = async (
  params: IReportFilter,
  dispatch?: Dispatch<any>,
): Promise<ITotalSalePlanning> => {
  // const paramsUrl = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
  const url = `${ADMIN_URL}/report/personal-sale-planning/total`;

  return apiClient
    .post(url, params)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchTeamSalePlanningReportFile = (params: IReportFilter, dispatch?: Dispatch<any>): void => {
  // const paramsUrl = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
  const url = `${ADMIN_URL}/report/team-sale-planning/export`;

  apiClient
    .post(url, params, { responseType: 'text' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'team-sale-planning-report-' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchTotalRecordTeamSalePlanningReport = async (
  params: IReportFilter,
  dispatch?: Dispatch<any>,
): Promise<ITotalSalePlanning> => {
  // const paramsUrl = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
  const url = `${ADMIN_URL}/report/team-sale-planning/total`;

  return apiClient
    .post(url, params)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchTeamSalePlanningReport = async (
  params: IReportFilter,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<ITeamSalePlanningItem>> => {
  // const paramsUrl = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
  const url = `${ADMIN_URL}/report/team-sale-planning`;

  return apiClient
    .post(url, params)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
