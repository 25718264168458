import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import { PruDateTimePicker } from 'src/app/common/components/PruDatePicker';

interface RangePickerProps {
  value?: any[];
  onChange?: (e: any[]) => void;
  disabled?: boolean;
  minFromDate?: Date;
  maxFromDate?: Date;
  minToDate?: Date;
  maxToDate?: Date;
}

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  divider: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default function RangePicker(props: RangePickerProps) {
  const { classes } = useStyles();
  const { value = [], onChange, disabled = false, minFromDate, maxFromDate, minToDate, maxToDate } = props;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const handleChange = (key: number) => (date: Date | null) => {
    if (!onChange) return;
    const newValue = [...(value || [])];
    newValue[key] = date;
    onChange(newValue);
  };

  const placeSelect = Translation('app.input.placeholder.please-select');

  return (
    <div className={classes.container}>
      <PruDateTimePicker
        maxDate={maxFromDate || (value && value[1]) || null}
        minDate={minFromDate || null}
        value={(value && value[0]) || null}
        onChange={handleChange(0)}
        label={placeSelect}
        disabled={disabled}
        ampm={false}
        format="DD/MM/YYYY HH:mm"
        slotProps={{
          textField: {
            fullWidth: true,
            margin: 'dense',
            variant: 'outlined',
          },
        }}
      />
      <div className={classes.divider}>-</div>
      <PruDateTimePicker
        minDate={minToDate || (value && value[0]) || null}
        maxDate={maxToDate || null}
        value={(value && value[1]) || null}
        onChange={handleChange(1)}
        label={placeSelect}
        disabled={disabled}
        format="DD/MM/YYYY HH:mm"
        slotProps={{
          textField: {
            fullWidth: true,
            margin: 'dense',
            variant: 'outlined',
            style: { marginRight: 20 },
          },
        }}
      />
    </div>
  );
}
