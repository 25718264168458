import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { PaginateList } from 'src/app/common/types/common-types';
import { useDataProvider } from 'src/app/common/utils';
import { fetchList, MdrtVideoLibraryListParams } from '../../../network/mdrtVideoLibraryCrud';
import { MdrtVideoLibraryItem, PublishStatus } from '../../../types/mdrt-video-library-types';
import MdrtVideoLibraryList from './components/MdrtVideoLibraryList';
import { getDayStart, getDayEnd } from 'src/app/common/utils/time-utils';

type MdrtListingPageProps = {} & ParamsProps;

export const filterKeys = ['title', 'updatedBy'];

const initialState: MdrtVideoLibraryListParams = {
  title: '',
  lastUpdatedDateFrom: null,
  lastUpdatedDateTo: null,
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): MdrtVideoLibraryListParams => {
  return initialParams
    ? {
        title: initialParams.title ?? '',
        lastUpdatedDateFrom: initialParams.lastUpdatedDateFrom
          ? getDayStart(new Date(initialParams.lastUpdatedDateFrom))
          : null,
        lastUpdatedDateTo: initialParams.lastUpdatedDateTo
          ? getDayEnd(new Date(initialParams.lastUpdatedDateTo))
          : null,
        page: 1,
        limit: 5,
      }
    : initialState;
};

const MdrtVideoLibraryListingPage: FC<MdrtListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<MdrtVideoLibraryListParams>(paramsInitiator(initialParams));
  const [itemList, setItemList] = useState<PaginateList<MdrtVideoLibraryItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<MdrtVideoLibraryItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchList(filterState, dispatch);
      } catch (err) {}
    },
    setItemList,
    false,
  );

  return (
    <>
      <PruFilter
        title={Translation('mdrtHub.mdrtVideoLibrary.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'title',
            initialValue: filterState.title,
            displayName: Translation('mdrtHub.mdrtVideoLibrary.filter.title'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'lastUpdatedDateFrom',
            fieldTo: 'lastUpdatedDateTo',
            initialDateFrom: filterState.lastUpdatedDateFrom,
            initialDateTo: filterState.lastUpdatedDateTo,
            displayName: Translation('mdrtHub.mdrtVideoLibrary.filter.lastUpdateDate'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            title: data.title,
            lastUpdatedDateFrom: data.lastUpdatedDateFrom,
            lastUpdatedDateTo: data.lastUpdatedDateTo,
          })
        }
        fetchData={refreshData}
      />
      <MdrtVideoLibraryList
        isLoading={isLoading}
        onRefresh={refreshData}
        itemList={itemList}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default MdrtVideoLibraryListingPage;
