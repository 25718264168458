import { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
// import { INCENTIVE_BASE_PATH, INCENTIVE_INCENTIVE_PATH } from '../../constants';
import { INCENTIVE_BASE_PATH, INCENTIVE_BASE_PATH_V2, INCENTIVE_INCENTIVE_PATH } from '../../constants';
import IncentiveListingPage, { incentiveFilterKeys } from './List/IncentiveListingPage';
import IncentiveDetailPage from './Form/IncentiveDetailPage';

// export const incentivePath = `${INCENTIVE_BASE_PATH}${INCENTIVE_INCENTIVE_PATH}`;
export const incentivePath = `${INCENTIVE_BASE_PATH_V2}${INCENTIVE_INCENTIVE_PATH}`;

const IncentiveRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={incentivePath} to={`${incentivePath}/list`} />
      <PruRoute
        exact
        path={`${incentivePath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={incentiveFilterKeys}>
            <IncentiveListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute exact path={`${incentivePath}/edit/:id`} component={IncentiveDetailPage} />
      <PruRoute exact path={`${incentivePath}/create`} component={IncentiveDetailPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default IncentiveRoutes;
