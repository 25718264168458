export enum PublishStatus {
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED",
}

export enum MdrtVideoLibraryFormMode {
  CREATE = "create",
  EDIT = "edit",
  VIEW = "view",
}

export type MdrtVideoLibraryItem = {
  _id: string;
  title: string;
  content: string;
  link: string;
  publishAt?: Date | null;
  unpublishAt?: Date | null;
  publishStatus: PublishStatus;
  createdAt: Date | null;
  createdBy: string | null;
  updatedAt: Date | null;
  updatedBy: string | null;
};

export type MdrtVideoLibraryDetail = {
  id?: string;
  title: string;
  content: string;
  link: string;
  publishStatus: PublishStatus;
};

export type MdrtVideoLibraryFormState = {
  id?: string;
  title: string;
  content: string;
  link: string;
};
