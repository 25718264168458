import React from 'react';
import { useIntl } from 'react-intl';

import { TextField } from '@mui/material';
import { NonProspectSurveyErrorState, NonProspectSurveyFormState } from '../../redux/NonProspectRedux';
import { FLYERMESSAGELIMIT } from 'src/app/modules/Survey/constants';
import { useStyles } from './style';

type FlyerProps = {
  onChangeFlyer: (flyer: string) => void;
  errorState: NonProspectSurveyErrorState;
  formState: NonProspectSurveyFormState;
};

function Flyer({ onChangeFlyer, formState, errorState }: FlyerProps) {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const TranslationWithVariable = (key: string, enCount: number | string, cnCount: number | string) =>
    useIntl().formatMessage({ id: key }, { enCount, cnCount });

  const errMessage = TranslationWithVariable(
    'component.form-max-characters-input-text',
    FLYERMESSAGELIMIT,
    FLYERMESSAGELIMIT / 2,
  );

  return (
    <>
      <div style={{ marginBottom: 20, paddingTop: 10 }} className={classes.rowContainer}>
        <div className={classes.fieldContainer}>
          <span className={classes.field}>{Translation('survey.common.flyer')}</span>
        </div>
        <div className={classes.textfieldContainer}>
          <div style={{ flex: 1 }}>
            <TextField
              variant="outlined"
              fullWidth
              value={formState.flyerMessage}
              onChange={(e) => {
                const flyerMessage = e.target.value;
                onChangeFlyer(flyerMessage);
              }}
              InputProps={{ className: classes.input }}
            />
            {errorState.immediate.flyerMessage && (
              <div
                style={{
                  color: '#f018a6',
                  fontFamily: 'Poppins',
                  fontWeight: 400,
                  fontSize: '0.75rem',
                  paddingTop: 4,
                }}
              >
                {errMessage}
              </div>
            )}
          </div>
          <div style={{ minWidth: 18 }} />
        </div>
      </div>
      <div
        style={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-end',
        }}
      ></div>
    </>
  );
}

export default Flyer;
