import React from 'react';
import { getDisplayDate } from 'src/app/common/utils/time-utils';
import { regionLocale } from 'src/app/i18n';

interface tableConfigType {
  title: string;
  prop: string;
  align?: any;
  render?: any;
  minWidth?: number;
  sorter?: boolean | false;
}

const getContentFromI18nData = (code: string, row: any) => {
  const data = row.i18nDataList.find((item: any) => {
    return item.lang === code;
  });
  return data?.content;
};

const getTitleFromI18nData = (code: string, row: any) => {
  if (!row.i18nTitleList) {
    return '';
  }
  const data = row.i18nTitleList.find((item: any) => {
    return item.lang === code;
  });
  return data?.name;
};

const getContentColumns = (Translation: any) => {
  let contentsColumns = regionLocale.map((code) => {
    const contentColumn = {
      title: `${Translation('event.ebirthday_card.table.header.content')} (${code})`,
      prop: `i18n_content_${code}`,
      sorter: true,
      render: (row: any) => <span>{getContentFromI18nData(code, row)}</span>,
    };
    return contentColumn;
  });
  return contentsColumns;
};

const getTitleColumns = (Translation: any) => {
  let titlesColumns = regionLocale.map((code) => {
    const titleColumn = {
      title: `${Translation('event.ebirthday_card.table.header.title')} (${code})`,
      prop: `i18n_title_${code}`,
      sorter: true,
      render: (row: any) => <span>{getTitleFromI18nData(code, row)}</span>,
    };
    return titleColumn;
  });
  return titlesColumns;
};

const tableConfig = (Translation: any, i18ConfigData: any): tableConfigType[] => {
  if (i18ConfigData.length === 0) {
    return [];
  }

  const contentsColumns = getContentColumns(Translation);
  const titlessColumns = getTitleColumns(Translation);

  return [
    ...contentsColumns,
    ...titlessColumns,
    {
      title: Translation('event.ebirthday_card.table.header.publishTime'),
      prop: 'publishTime',
      render: (row: any) => (
        <span>{row.publishTime ? getDisplayDate(row.publishTime, 'YYYY-MM-DD HH:mm:ss') : ''}</span>
      ),
    },
    {
      title: Translation('event.ebirthday_card.table.header.createTime'),
      prop: 'createTime',
      render: (row: any) => <span>{getDisplayDate(row.createTime, 'YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: Translation('event.ebirthday_card.table.header.modifyTime'),
      prop: 'modifyTime',
      render: (row: any) => <span>{getDisplayDate(row.modifyTime, 'YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: Translation('event.ebirthday_card.table.header.modifier'),
      prop: 'modifier',
    },
  ];
};

export default tableConfig;
