export const PULSELEADS_LEAD_REASSIGNMENT_TITLE = 'title.pulseleads.leadReassignment';
export const PULSELEADS_LEAD_REASSIGNMENT_UPLOAD_TITLE = 'title.pulseleads.leadReassignment.upload';
export const PULSELEADS_LEAD_REASSIGNMENT_STATUS_TITLE = 'title.pulseleads.leadReassignment.status';

export const PULSELEADS_LEAD_REASSIGNMENT_UPLOAD_NAMESPACE = 'PULSELEADS-LEAD-REASSIGNMENT-UPLOAD';
export const PULSELEADS_LEAD_REASSIGNMENT_STATUS_NAMESPACE = 'PULSELEADS-LEAD-REASSIGNMENT-STATUS';

export const PULSELEADS_LEAD_REASSIGNMENT_PATH = '/lead-reassignment';
export const PULSELEADS_LEAD_REASSIGNMENT_UPLOAD_PATH = '/upload';
export const PULSELEADS_LEAD_REASSIGNMENT_STATUS_PATH = '/status';
