import { AttachmentDef } from 'src/app/common/types/common-types';
import { ProspectItem, ProspectMasterItem } from './prospect-types';
import { LeadSource } from './source-types';

export enum CampaignFormMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export enum CampaignSortEnum {
  HOT = 'hot',
  WARM = 'warm',
  COLD = 'cold',
}

export enum CampaignDropdownType {
  CAMPAIGN = 'campaign',
  LEAD_SOURCE = 'lead-source',
  AGENT_LEAD_SOURCE = 'agent-lead-source',
  ACCEPT_SLA = 'accept-sla',
  CONTACT_SLA = 'contact-sla',
  MEET_SLA = 'meet-sla',
  PROPOSAL_SLA = 'proposal-sla',
  APPLICATION_SLA = 'application-sla',
  LEAD_DEDUPLICATION_RULE = 'lead-deduplication-rule',
  CUSTOMER_DEDUPLICATION_RULE = 'customer-deduplication-rule',
  DISTRIBUTION_RULE = 'distribution-rule',
  ELIGIBILITY_RULE = 'eligibility-rule',
  EXCLUSION_RULE = 'exclusion-rule',
  FEEDBACK = 'feedback',
  FEEDBACK_GROUP = 'feedback-group',
}

export enum CampaignTypeEnum {
  NORMAL = 'normal',
  MASS = 'mass',
  SERVICING = 'servicing',
}

export enum CampaignMandatoryDataEnum {
  COUNTRY_NATIONAL_ID = 'countryNationalId',
  PASSPORT_NUMBER = 'passportNumber',
  CHINA_ID = 'chinaId',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  BIRTHDAY = 'birthday',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  GENDER = 'gender',
  LANGUAGE = 'language',
  RACE = 'race',
  CITY = 'city',
  STATE = 'state',
  ZIP_CODE = 'zipCode',
  MATCH_PRODUCT = 'matchProduct',
  CURRENT_EXPENSES = 'currentExpenses',
  PREFERRED_CONTACT_METHOD = 'preferredContactMethod',
}

export enum CampaignCustomerDedupEnum {
  LIFE_MATCH_FIRST = 'LIFE MATCH FIRST',
  DEFAULT_CUSTOMER_MATCH = 'DEFAULT CUSTOMER MATCH',
  ONEPULSE_STP = 'ONEPULSE STP',
  GENERAL_MATCH_FIRST = 'GENERAL MATCH FISRT',
  DEFAULT_CUSTOMER_MATCH_INCL_STAFF = 'DEFAULT CUSTOMER MATCH INCL STAFF',
}

export enum CampaignStatusEnum {
  // PENDING_APPROVAL = 'pendingApproval',
  // APPROVED = 'approved',
  // REJECTED = 'rejected',
  ACTIVE = 'active',
  COMPLETED = 'completed',
  PAUSED = 'paused',
  CANCELED = 'canceled',
  DRAFT = 'draft',
}

export enum CampaignPriorityEnum {
  REFERRAL_AGENT = 'referralAgent',
  LEAD_DEDUP = 'leadDedup',
  CUSTOMER_DEDUP = 'customerDedup',
}

export enum RoundRobinModeEnum {
  ROUND_ROBIN = 'roundRobin',
  RANDOM = 'random',
}

export enum FollowUpStatusEnum {
  CONTACT_SUCCESS = 'contactSuccess',
  MET = 'met',
  QUOTE = 'quote',
  PROPOSAL = 'proposal',
  POLICY = 'policy',
  CONTACT_FAILURE = 'contactFailure',
  DEAD = 'dead',
}

export enum LeadStatusEnum {
  WAITING_FOR_ACCEPT = 'waitingForAccept',
  PENDING_FOR_CONTACT = 'pendingForContact',
  CONTACT_SUCCESS = 'contactSuccess',
  MET = 'met',
  QUOTE = 'quote',
  PROPOSAL = 'proposal',
  POLICY = 'policy',
  ACCEPT_EXPIRED = 'acceptExpired',
  CONTACT_EXPIRED = 'contactExpired',
  MEET_MISSED = 'meetMissed',
  PROPOSAL_MISSED = 'proposalMissed',
  APPLICATION_MISSED = 'applicationMissed',
  DEAD = 'dead',
  REJECTED = 'rejected',
  PENDING_FOR_SERVICE = 'pendingForService',
}

export enum LeadAgentStatusEnum {
  accepted = 'accepted',
  rejected = 'rejected',
  pending = 'pending',
  pendingServicing = 'pendingServicing',
}

export enum DistributionChannelEnum {
  AGENCY = 'agency',
  SCB = 'scb',
  OTHERS = 'others',
}

export enum TargetProductTypeEnum {
  INVESTMENT = 'investment',
  MEDICAL = 'medical',
  PROTECTION = 'protection',
  SAVINGS = 'savings',
}

export enum DestinationStatusEnum {
  ACCEPTED = 'accepted',
  CONTACTED = 'contactSuccess',
  MET = 'met',
  PROPOSAL_GENERATED = 'quote',
  APPLICATION_SUBMITTED = 'proposal',
}

export enum LeadWithPartialConditionEnum {
  COUNTRY_NATIONAL_ID = 'Country National ID',
  PHONE_NUMBER = 'Phone Number',
  EMAIL_ADDRESS = 'Email Address',
  LOCATION = 'Location',
  COUNTRY_NATIONAL_ID_AND_PHONE_NUMBER_AND_EMAIL_ADDRESS_AND_LOCATION = 'Country National ID and Phone Number and Email Address and Location',
  COUNTRY_NATIONAL_ID_OR_PHONE_NUMBER_OR_EMAIL_ADDRESS_OR_LOCATION = 'Country National ID or Phone Number or Email Address or Location',
}

export type CampaignDropdownList = {
  [id: string]: CampaignDropdownItem[];
};

export type CampaignDropdownItem = {
  key: string;
  value: string;
};

export type CampaignFaq = {
  question: string;
  answer: string;
};

export type CampaignLink = {
  title: string;
  link: string;
};

export type CampaignPriority = {
  step: string;
};

export type CampaignFlyer = {
  title: string;
  attachments?: AttachmentDef[];
};

export type CampaignMessage = {
  title: string;
  body: string;
};

export type CampaignItem = {
  _id: string;
  campaignId: string;
  name: string;
  campaignType?: string;
  description: string;
  regionalCampaignName?: string;
  type: string;
  sort?: string;
  startDate: Date;
  endDate: Date;
  launchDate?: Date;
  emailMessage?: CampaignMessage;
  notificationMessage?: CampaignMessage;
  mandatoryData: string[];
  image: AttachmentDef;
  maxLeadPerAgent?: number;
  marketingOwnerName: string;
  marketingOwnerEmail: string;
  status: string;
  isNewLeadNotificationEnabled: boolean;
  callScripts?: string;
  saleTipsInsight?: string;
  faq: CampaignFaq[];
  links: CampaignLink[];
  priorities: CampaignPriority[];
  flyers: CampaignFlyer[];
  source: string;
  subSource?: string[];
  acceptSLA?: any;
  contactSLA?: any;
  meetSLA?: any;
  proposalSLA?: any;
  applicationSLA?: any;
  customerIncentive?: string;
  agentIncentive?: string;
  isSelfCreateLeadEnabled: boolean;
  maximumMarketingLeads?: MaximumLeadsItem;
  maximumLeadSourceLeads?: MaximumLeadsItem;
  isAutoAssignLeadIfAgentInactive: boolean;
  isLeadWithPartialInfo: boolean;
  leadWithPartialInfoWarning?: LeadWarningMessageItem;
  leadWithPartialCondition?: LeadWithPartialConditionItem;
  distributionRule?: any;
  eligibilityRule?: any;
  enableLeadLimit: boolean;
  maxLeadLimit?: number;
  leadDedupRule?: any;
  referralAgentCheck?: any;
  customerDedup?: string;
  customerDedupRule?: any | any[];
  exclusionRule?: any;
  isEngagementRequired: boolean;
  isStaging: boolean;
  leadAction: string;
  prospectCategory: string;
  distributionChannel?: string;
  targetProductType?: string[];
  feedbacks?: string[];
  feedbackGroup?: string;
  roundRobinMode: string;
  enableAutoDropLead?: boolean;
  autoDropRule: DropLeadRuleInterface;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  talkbotRule?: TalkbotRuleInterface;

  // PVA
  skipAcceptLead?: SkipAcceptLeadItem;
  allowLeaderToReassign?: AllowLeaderToReassignItem;
};

export type TalkbotRuleInterface = {
  enabled?: boolean;
  flowId?: number;
};

export type DropLeadRuleInterface = {
  enabled: boolean;
  type?: string;
  day?: number;
};

export type MaximumLeadsItem = {
  isMaximum: boolean;
  limit?: number;
  isEngage: boolean;
};

export type SkipAcceptLeadItem = {
  isSkip: boolean;
  status?: string;
  isAddPointForSkippedStatus?: boolean;
};

export type AllowLeaderToReassignItem = {
  isActive: boolean;
  pendingTime?: number;
};

export type CampaignLeadItem = {
  _id: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  agentCode?: string;
  agentName?: any;
  agentStatus?: string;
  lastFollowUpStatus?: string;
  isActive?: boolean;
  expiredAt?: Date;
  contactExpiredAt?: Date;
  referredAgentId?: string;
  referredAgentPhoneNumber?: string;

  prospect?: ProspectItem;
  prospectMaster?: ProspectMasterItem;
  campaign?: CampaignItem;
  agentData?: any;
  sourceData?: LeadSource;

  leadSource: string;
  leadDate: Date;
  leadRaw?: any;

  feedback?: FeedbackItem;

  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;

  followUp?: [{ expiredAt: Date }];
  isLeadReassign?: boolean;
};

export type FeedbackItem = {
  _id: string;
  name: string;
};

export type LeadWarningMessageItem = {
  title?: string;
  body?: string;
  tnc?: string;
};

export type LeadWithPartialConditionItem = {
  name: string;
  items: {
    type: string;
    operator: string;
    value?: string | string[];
  };
};

export enum DropLeadTypeEnum {
  BY_LEAD_UPDATE_TIME = 'by_lead_update_time',
  BY_CAMPAIGN_END_DATE = 'by_campaign_end_date',
  BY_LEAD_CREATE_DATE = 'by_lead_create_date',
}

export enum LeadActionTypeEnum {
  DIRECT_DISTRIBUTE = 'straight',
  STAGING = 'staging',
}

export type ProspectCategoryItem = {
  _id: string;
  name: string;
  description: string;
  isDefault: boolean;
  colorLabel: string;
  backgroundColorLabel: string;
  targetNumber?: number;
  targetFrequency?: number;
  type: string;
  isActive: boolean;
  updatedBy?: string;
  color?: any;
};

export enum ReferralAgentCheckTypeEnum {
  'ENABLE' = 'Enable',
  'DISABLE' = 'Disable',
}
