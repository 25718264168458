import React, { FC, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { SurveyResult, SurveySummaryResult, SurveyResultItem } from 'src/app/modules/Survey/types/survey-types';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { makeStyles } from 'tss-react/mui';
import { Button, CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import AsyncCsvLinkPaginate from 'src/app/common/components/AsyncCsvLinkPaginate';
import SurveyAnalytics from 'src/app/common/components/Survey/SurveyAnalytics';
import SurveySummary from 'src/app/common/components/Survey/SurveySummary';
// import { SALESKIT_BASE_PATH, SURVEY_CUSTOMER_PATH } from 'src/app/modules/sales-kit/constants';
import { downloadCustomerSurveyResultsPaginate } from '../../network/api';
import { SURVEY_BASE_PATH, SURVEY_CUSTOMER_PATH } from 'src/app/modules/Survey/constants';

type SurveyAnalyticSectionProps = {
  id: string;
  isLoadingFullDashboard: boolean;
  showDashboard: boolean;
  results?: SurveyResult;
  summaries: SurveySummaryResult;
  setIsLoadingFullDashboard: (value: boolean) => void;
  getFullDashboardData: (page: number, data?: SurveyResultItem[]) => Promise<void>;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  btnContainer: {
    display: 'flex',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  loadingContainer: {
    width: '100%',
    padding: '1em',
    textAlign: 'center',
  },
  loadingBackground: {
    backgroundColor: '#f7f7f7',
    padding: '255px 0',
  },
  loadingText: {
    color: '#333333',
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 500,
    paddingTop: 14,
  },
}));

const limit = 1000;

const CustomerSurveyAnalyticSection: FC<SurveyAnalyticSectionProps> = ({
  id,
  isLoadingFullDashboard,
  showDashboard,
  results,
  summaries,
  setIsLoadingFullDashboard,
  getFullDashboardData,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const result: Record<string, unknown>[] = results
    ? results.surveyResults.reduce((res: Record<string, unknown>[], item: SurveyResultItem) => {
        if (item.body) res.push(item.body);
        return res;
      }, [])
    : [];

  const handleClickView = async () => {
    setIsLoadingFullDashboard(true);
    await getFullDashboardData(1);
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>{Translation('survey.viewResults')}</div>
        </div>
        <div className={classes.btnContainer}>
          <AsyncCsvLinkPaginate
            isDisabled={false}
            filename={`${results?.surveyBody?.title ?? ''}_${moment().format('YYYYMMDDHHmm')}.csv`}
            asyncCall={(page) => downloadCustomerSurveyResultsPaginate(id, { page, limit }, dispatch)}
            renderButton={(isLoading, onClick) => (
              <Button
                disabled={isLoading}
                style={{ marginRight: 25 }}
                variant="contained"
                color="secondary"
                onClick={onClick}
              >
                {Translation('app.button.exportCsv')}
              </Button>
            )}
          />
          <Button
            variant="contained"
            color="inherit"
            // COE
            // onClick={() => history.push(`${SALESKIT_BASE_PATH}${SURVEY_CUSTOMER_PATH}`)}

            //PVA use this path
            onClick={() => history.push(`${SURVEY_BASE_PATH}${SURVEY_CUSTOMER_PATH}`)}
          >
            {Translation('app.button.back')}
          </Button>
        </div>
      </div>
      <div style={{}}>
        <SurveySummary summary={summaries} />
      </div>

      {!isLoadingFullDashboard ? (
        <>
          {showDashboard && results ? (
            <SurveyAnalytics
              questions={results.surveyBody}
              results={result}
              options={{
                //  Enable Commercial License
                haveCommercialLicense: true,
              }}
            />
          ) : (
            <Button variant="contained" color="secondary" disabled={isLoadingFullDashboard} onClick={handleClickView}>
              {Translation('survey.view_dashboard')}
            </Button>
          )}
        </>
      ) : (
        <div className={classes.loadingContainer}>
          <div className={classes.loadingBackground}>
            <CircularProgress color="secondary" size={32} thickness={5} />
            <div className={classes.loadingText}>{Translation('survey.loading_dashboard_text')}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerSurveyAnalyticSection;
