// COE
import React, { FC, useReducer, useState, useCallback, useEffect } from 'react';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';

import { useErrorHandler, ErrorFieldType, getDayStart, getDayEnd } from 'src/app/common/utils';
import moment from 'moment';
import { PublishStatus } from 'src/app/modules/PulseLeads/pages/Engagement/EngagementTopic/Detail/utils';
import SurveyCreatorSection from 'src/app/modules/Survey/pages/Management/Create/components/SurveyCreatorSection';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';

import CustomerSurveyConfigSection from './CustomerSurveyConfigSection';
import { FLYERMESSAGELIMIT } from 'src/app/modules/Survey/constants';
import { useStyles } from './style';
import { SURVEY_CUSTOMER_PATH, SALESKIT_BASE_PATH } from 'src/app/modules/sales-kit/constants';
import {
  ModifyFieldAction,
  ModifyCompleteMessageAction,
  CustomerSurveyFormState,
  completeMessage,
  CustomerinitialState,
  CustomerSurveyErrorState,
} from '../../redux/CustomerRedux';
import { CustomerSurveyItemBody, createCustomerSurvey, modifyCustomerSurvey } from '../../network/api';
import { SurveyFormMode, CustomerSurveyDetail } from '../../network/types';

// PVA
import { SURVEY_BASE_PATH } from 'src/app/modules/Survey/constants';

type CustomerSurveyDetailFormProps = {
  formMode: SurveyFormMode;
  surveyDetail?: CustomerSurveyDetail;
};

type SurveyFormAction = ModifyFieldAction | ModifyCompleteMessageAction;

const surveyReducer = (
  state: CustomerSurveyFormState,
  // state: CustomerSurveyFormState,
  action: SurveyFormAction,
) => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'MODIFY_COMPLETE_MESSAGE':
      state.completeMessage[action.payload.subField as keyof completeMessage] = action.payload.value;

      return {
        ...state,
      };

    default:
      return CustomerinitialState;
  }
};

const detailToStateConvertor = (surveyDetail?: CustomerSurveyDetail): CustomerSurveyFormState => {
  return surveyDetail
    ? {
        startDate: surveyDetail.startDate,
        endDate: surveyDetail.endDate,
        consents: surveyDetail.consents ? surveyDetail.consents : CustomerinitialState.consents,
        body: surveyDetail.body,
        numberofTnc: surveyDetail.consents ? surveyDetail.consents?.length : CustomerinitialState.numberofTnc,
        surveyImage: surveyDetail.surveyImage,
        bannerId: surveyDetail.bannerId ? surveyDetail.bannerId : CustomerinitialState.bannerId,
        completeMessage: surveyDetail.completeMessage
          ? {
              title: surveyDetail.completeMessage.title,
              content: surveyDetail.completeMessage.content,
            }
          : { title: '', content: '' },
        status: surveyDetail.status || PublishStatus.Draft.key,
        flyerMessage: surveyDetail.flyerMessage,
        enableTargetAgents: surveyDetail.enableTargetAgents,
        targetAgents: surveyDetail.targetAgents,
      }
    : CustomerinitialState;
};

function CustomerSurveyDetailForm({ formMode, surveyDetail }: CustomerSurveyDetailFormProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { classes: commonClasses } = useCommonStyles();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  let apiData = !!surveyDetail;
  const [formState, formDispatch] = useReducer(surveyReducer, detailToStateConvertor(surveyDetail));
  const [tabState, setTabState] = useState<'CONFIG' | 'SURVEY_BODY'>('CONFIG');

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler, immediateErrorValidator } =
    useErrorHandler<CustomerSurveyErrorState>(formState, [
      {
        name: 'startDate',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'endDate',
        fieldType: ErrorFieldType.MANDATORY,
      },
      {
        name: 'body',
        fieldType: ErrorFieldType.MANDATORY,
      },

      {
        name: 'endDateBeforeStartDate',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          if (formState.startDate && formState.endDate) {
            return !!moment(new Date(formState.startDate)).isAfter(moment(new Date(formState.endDate)));
          } else {
            return false;
          }
        },
      },

      {
        name: 'flyerMessage',
        fieldType: ErrorFieldType.IMMEDIATE,
        condition: () => {
          // const re = /[\u4E00-\u9FA5]/g;
          const re = new RegExp(/[\u4E00-\u9FA5]/, 'g');
          let len = formState.flyerMessage.length;
          const chineseCount = (formState.flyerMessage.match(re) || '').length;
          len = len + chineseCount;
          if (formState.flyerMessage && len > FLYERMESSAGELIMIT) {
            return true;
          } else {
            return false;
          }
        },
      },
    ]);

  const onNextPage = () => {
    const { currentErrorState } = onSubmitErrorValidator();
    if (
      !currentErrorState.mandatory.startDate &&
      !currentErrorState.mandatory.endDate &&
      !currentErrorState.immediate.endDateBeforeStartDate &&
      !currentErrorState.immediate.flyerMessage
    ) {
      setTabState('SURVEY_BODY');
    }
  };
  const [status, setStatus] = useState<string>('');
  const onStatusChange = useCallback((it: string) => {
    setStatus(it);
  }, []);

  const onSubmitSurvey = async () => {
    const { hasError } = onSubmitErrorValidator();

    if (!formState.body?.title) {
      let errMsg = Translation('survey.form.title.required');
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: '',
            content: errMsg,
          },
        ]),
      );
      return;
    }

    let completdMessage: completeMessage = {
      title: Translation('survey.form.thankyou.title'),
      content: Translation('survey.form.thankyou.content'),
    };
    let defaultFlyerMessage = Translation('survey.form.flyerDefault');
    if (!formState.completeMessage.title) formState.completeMessage.title = completdMessage.title;
    if (!formState.completeMessage.content) formState.completeMessage.content = completdMessage.content;

    if (!hasError) {
      const surveyBody: CustomerSurveyItemBody = {
        startDate: formState.startDate ? getDayStart(formState.startDate) : null,
        endDate: formState.endDate ? getDayEnd(formState.endDate) : null,
        consents: formState.consents,
        body: formState.body || {},
        completeMessage: formState.completeMessage || {},
        surveyImage: formState.surveyImage || '',
        bannerId: formState.bannerId || '',
        status: status,
        flyerMessage: formState.flyerMessage ? formState.flyerMessage : defaultFlyerMessage,
        enableTargetAgents: formState.enableTargetAgents || false,
        targetAgents: formState.targetAgents || [],
      };

      if (formMode === SurveyFormMode.CREATE) {
        await createCustomerSurvey(surveyBody, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Survey saved successfully`,
            },
          ]),
        );
      } else if (surveyDetail && formMode === SurveyFormMode.EDIT) {
        await modifyCustomerSurvey(surveyDetail._id, surveyBody, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Survey updated and published successfully - ${surveyDetail._id}`,
            },
          ]),
        );
      }
      formDispatch({
        type: 'MODIFY_COMPLETE_MESSAGE',
        payload: {
          field: 'completeMessage',
          subField: 'title',
          value: '',
        },
      });
      formDispatch({
        type: 'MODIFY_COMPLETE_MESSAGE',
        payload: {
          field: 'completeMessage',
          subField: 'content',
          value: '',
        },
      });
      // COE
      // history.push(`${SALESKIT_BASE_PATH}${SURVEY_CUSTOMER_PATH}`);

      //PVA use this path
      history.push(`${SURVEY_BASE_PATH}${SURVEY_CUSTOMER_PATH}`);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className={classes.rowContainer}>
          <div className={commonClasses.header}>
            {Translation(`survey.form.${formMode === SurveyFormMode.CREATE ? 'add' : 'edit'}`)}
          </div>
        </div>
        <Button
          variant="contained"
          color="inherit"
          // COE
          // onClick={() => history.push(`${SALESKIT_BASE_PATH}${SURVEY_CUSTOMER_PATH}`)}
          // PVA
          onClick={() => history.push(`${SURVEY_BASE_PATH}${SURVEY_CUSTOMER_PATH}`)}
        >
          {Translation('app.button.back')}
        </Button>
      </div>
      {tabState === 'CONFIG' && (
        <CustomerSurveyConfigSection
          formState={formState}
          errorState={errorState}
          apiData={apiData}
          onChangeStartDate={(startDate) =>
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: { field: 'startDate', value: startDate },
            })
          }
          onChangeEndDate={(endDate) =>
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: { field: 'endDate', value: endDate },
            })
          }
          onChangeCustomerConsent={(consents) => {
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: { field: 'consents', value: consents },
            });
          }}
          addTnc={(tnc) => {
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: {
                field: 'numberofTnc',
                value: tnc,
              },
            });
          }}
          onChangeThankyouPageTitle={(title) => {
            formDispatch({
              type: 'MODIFY_COMPLETE_MESSAGE',
              payload: {
                field: 'completeMessage',
                subField: 'title',
                value: title,
              },
            });
          }}
          onChangeThankyouPageContent={(content) => {
            formDispatch({
              type: 'MODIFY_COMPLETE_MESSAGE',
              payload: {
                field: 'completeMessage',
                subField: 'content',
                value: content,
              },
            });
          }}
          onChangeImage={(image) => {
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: {
                field: 'surveyImage',
                value: image,
              },
            });
          }}
          onChangeImageId={(bannerId) => {
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: {
                field: 'bannerId',
                value: bannerId,
              },
            });
          }}
          onChangeStatus={(status) => {
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: {
                field: 'status',
                value: status,
              },
            });
          }}
          onChangeFlyer={(flyerMessage) => {
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: {
                field: 'flyerMessage',
                value: flyerMessage,
              },
            });
          }}
          onChangeEnableAgentType={(enableTargetAgents) => {
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: {
                field: 'enableTargetAgents',
                value: enableTargetAgents,
              },
            });
          }}
          onChangeTargetAgents={(targetAgents) => {
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: {
                field: 'targetAgents',
                value: targetAgents,
              },
            });
          }}
          onStatusChange={onStatusChange}
          onDismissErrorHandler={onDismissErrorHandler}
          immediateErrorValidator={immediateErrorValidator}
          onNextPage={onNextPage}
        />
      )}

      {tabState === 'SURVEY_BODY' && (
        <SurveyCreatorSection
          surveyContent={formState.body}
          onChangeSurveyContent={(content) =>
            formDispatch({
              type: 'MODIFY_FIELD',
              payload: { field: 'body', value: content },
            })
          }
          onPreviousPage={() => setTabState('CONFIG')}
          onSubmit={onSubmitSurvey}
        />
      )}
    </div>
  );
}

export default CustomerSurveyDetailForm;
