import qs from 'qs';
import { ADMIN_URL, apiClient } from 'src/app/common/network';
import { defaultTextColorOptions } from '../type/bdm-event.type';

export const fetchAllBgImageRequest = async ({ name = '', page = 1, limit = 20, type, templateId }: any) => {
  const query = {
    name,
    page,
    limit,
    type,
    templateId,
  };

  const queryPath = `${ADMIN_URL}/resource/ad/background-images?${qs.stringify(query)}`;

  return apiClient
    .get(queryPath, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response: any) => {
      if (response?.data?.items?.length > 0) {
        response.data.items = convertToBgImageOption(response.data.items, (page - 1) * limit);
      }
      return response.data ? { ...response.data, page, limit } : {};
    });
};

const convertToBgImageOption = (data: any[], position: number) => {
  return data.map((item: any, index: number) => {
    return {
      index: position + index + 1,
      id: item._id,
      value: item._id,
      label: item.name,
      imgUrl: item.image.url,
      textColorList: item.textColorList
        ? item.textColorList.map((color: string) => ({
            value: color,
            label: color,
          }))
        : defaultTextColorOptions,
    };
  });
};

export const getBgImageById = async (id: string): Promise<any> => {
  const queryPath = `${ADMIN_URL}/resource/ad/background-images/${id}`;

  return apiClient
    .get(queryPath, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response: any) => {
      return parseImgDetailToOption(response.data);
    });
};

const parseImgDetailToOption = (data: any) => {
  return data
    ? {
        index: 1,
        id: data._id,
        value: data._id,
        label: data.name,
        imgUrl: data.image.url,
        textColorList: data.textColorList
          ? data.textColorList.map((color: any) => ({ value: color, label: color }))
          : defaultTextColorOptions,
      }
    : undefined;
};
