import React, { FC, useState } from 'react';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { getDayEnd, getFirstDayOfMonth, getDayStart } from '../../../../../common/utils/time-utils';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { PaginateListMore } from 'src/app/common/types/common-types';
import moment from 'moment';
import {
  fetchOpeningNotificationReport,
  fetchOpeningNotificationReportDownload,
} from '../../../network/openingNotificationReportCrud';
import { getCurrentConfig } from 'src/app/common/utils/common-utils';
import { RootState } from 'src/redux/store';
import { AuthenticationState } from '@pruforce/common-adminweb-sdk';
import { OPENING_NOTIFICATION_FILTER_ERROR_TEXT } from 'src/app/modules/Incentive/constants';
import { useLang } from 'src/app/i18n';

type OpeningNotificationReportProps = {} & ParamsProps;

const initialState = {
  requestedDateFrom: getFirstDayOfMonth(new Date()),
  requestedDateTo: getDayEnd(new Date()),
  agentCodeScanner: '',
  agentCode: '',
  unitCode: '',
  branchCode: '',
  officeCode: '',
  category: null,
  notificationTitle: '',
  page: 1,
  limit: 5,
  lang: '',
};

const paramsInitiator = (initialParams?: Record<string, string>): any => {
  return initialParams
    ? {
        requestedDateFrom: initialParams.requestedDateFrom
          ? getDayStart(new Date(initialParams.requestedDateFrom))
          : getFirstDayOfMonth(new Date()),
        requestedDateTo: initialParams.requestedDateTo
          ? getDayEnd(new Date(initialParams.requestedDateTo))
          : getDayEnd(new Date()),
        agentCode: '',
        unitCode: '',
        branchCode: '',
        officeCode: '',
        category: null,
        notificationTitle: '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const OpeningNotificationReport: FC<OpeningNotificationReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilterValue = paramsInitiator(initialParams);
  const currentLanguage = useLang();

  const [filterState, setFilterState] = useState<any>(defaultFilterValue);
  filterState.lang = currentLanguage;
  const [reportList, setReportList] = useState<PaginateListMore<any>>();
  const { isLoading, refreshData } = useDataProvider<PaginateListMore<any>>(
    async () => {
      try {
        return await fetchOpeningNotificationReport(filterState, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);
  const currentConfig = getCurrentConfig(user);
  const CategoryType = currentConfig?.notificationCategoryType as {
    [key: string]: string;
  };
  const arrCategoryType = Object.entries(CategoryType).map(([key, value]) => ({
    displayName: key,
    value,
  }));

  const fetchCsvtDownload = () => {
    try {
      fetchOpeningNotificationReportDownload(filterState, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('report.openingNotification.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('report.openingNotification.agentCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'notificationTitle',
            initialValue: filterState.no,
            displayName: Translation('report.openingNotification.notificationTitle'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'requestedDateFrom',
            fieldTo: 'requestedDateTo',
            initialDateFrom: filterState.requestedDateFrom,
            initialDateTo: filterState.requestedDateTo,
            defaultDateFrom: defaultFilterValue.requestedDateFrom,
            defaultDateTo: defaultFilterValue.requestedDateTo,
            displayName: Translation('report.openingNotification.filter.activityDate'),
            required: true,
          },
          {
            type: PruFilterItemType.MULTIPLE_SELECT,
            field: 'category',
            initialValue: filterState.category,
            displayName: Translation('report.openingNotification.category'),
            choices: arrCategoryType,
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'unitCode',
            initialValue: filterState.unitCode,
            displayName: Translation('report.openingNotification.unitCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'branchCode',
            initialValue: filterState.branchCode,
            displayName: Translation('report.openingNotification.branchCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'officeCode',
            initialValue: filterState.officeCode,
            displayName: Translation('report.openingNotification.officeCode'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            agentCode: data.agentCode,
            unitCode: data.unitCode,
            branchCode: data.branchCode,
            officeCode: data.officeCode,
            category: data.category,
            notificationTitle: data.notificationTitle,
            requestedDateFrom: data.requestedDateFrom,
            requestedDateTo: data.requestedDateTo,
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.openingNotification.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvtDownload(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => row._id || '-',
          },
          {
            keyIndex: 'agentCode',
            align: 'center',
            displayName: 'Agent Code/AD Code',
            renderData: (row) => row.agentCode || '-',
          },
          {
            keyIndex: 'unitCode',
            align: 'center',
            displayName: 'Unit Code',
            renderData: (row) => row.unitCode || '-',
          },
          {
            keyIndex: 'branchCode',
            align: 'center',
            displayName: 'Branch Code',
            renderData: (row) => row.branchCode || '-',
          },
          {
            keyIndex: 'officeCode',
            align: 'center',
            displayName: 'Office Code',
            renderData: (row) => row.officeCode || '-',
          },
          {
            keyIndex: 'category',
            align: 'center',
            displayName: 'Category',
            renderData: (row) =>
              row.category
                ? arrCategoryType.find((item) => item.value === row.category)
                  ? arrCategoryType.find((item) => item.value === row.category)?.displayName
                  : row.category
                : '-' || '-',
          },
          {
            keyIndex: 'notificationTitle',
            align: 'center',
            displayName: 'Notification Title',
            renderData: (row) => row.notificationTitle || '-',
          },
          {
            keyIndex: 'totalOpening',
            align: 'center',
            displayName: 'Number of Openings',
            renderData: (row) => row.totalOpening || '-',
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: 'First Opening Date',
            renderData: (row) => moment(row.createdAt).format('DD/MM/YYYY HH:mm') || '-',
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default OpeningNotificationReport;
