import {
  Accordion as MuiAccordion,
  Typography,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  styled,
  AccordionProps,
  AccordionSummaryProps,
  FormControlLabel,
  Checkbox,
  Divider,
} from '@mui/material';
import React, { useState } from 'react';
import { useStyles } from './styles';
import { RegionLocale, regionLocale, regionLocaleMap } from 'src/app/i18n';
import { useLang } from 'src/app/i18n';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useIntl } from 'react-intl';
import { FormInstance } from 'rc-field-form';
import SubFormList from './SharedInfoForm';
import { YesAndNo } from '../../../../../type/bdm-event.type';
import Form from 'src/app/common/components/Form';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
  '&.Mui-expanded': {
    height: 48,
    minHeight: 48,
  },
  '&.MuiAccordionSummary-root': {
    height: 48,
    minHeight: 48,
  },
  '&.MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
  padding: '0px 20px 0px 30px',
  '&.MuiAccordion-root.Mui-expanded': {
    margin: 0,
  },
}));

const Accordion = styled((props: AccordionProps) => <MuiAccordion elevation={0} {...props} />)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:last-child': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&.MuiAccordion-root.Mui-expanded': {
    margin: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  display: 'block',
  paddingLeft: 30,
  paddingRight: 20,
}));

const generateDefaultExpandPanel = (regionLocales: string[]) => {
  let expandPanel: any = {};
  regionLocales.map((item) => {
    return (expandPanel[item] = true);
  });
  return expandPanel;
};

type IRegionSettings = {
  disabled: boolean;
  form: FormInstance<any>;
  moduleType: string;
};

const RegionSettings = ({ disabled, form, moduleType }: IRegionSettings) => {
  const { classes } = useStyles();
  const regionLocales = regionLocale;
  const mainLocale = useLang() === RegionLocale.ENGLISH ? RegionLocale.ENGLISH : RegionLocale.VIETNAMESE;
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);

  const [panelExpand, setPanelExpand] = useState<any>(generateDefaultExpandPanel(regionLocales));

  const handleChange = (locale: string) => (event: any, isExpanded: boolean) => {
    setPanelExpand({ ...panelExpand, [locale]: isExpanded });
  };

  const copyMainLangVersion = (e: React.ChangeEvent<HTMLInputElement>, locale: string) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.checked) {
      const allValues = form.getFieldsValue(true);
      const to = locale;
      const from = mainLocale;
      const fromValue = allValues[from];
      const result = { ...allValues, [to]: { ...fromValue } };
      form.setFieldsValue(result);
    }
  };

  return (
    <React.Fragment>
      <div className={classes.accordContainer}>
        {regionLocales
          .sort((cur, next) => (cur === mainLocale ? -1 : 1))
          .map((locale, index) => {
            return (
              <Accordion
                className={classes.mt_0}
                key={index}
                expanded={panelExpand[locale as string]}
                onChange={handleChange(locale)}
                elevation={0}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
                  <Typography className={classes.accordHeading}>{Translation(regionLocaleMap[locale].name)}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'block' }}>
                  {index === 0 || disabled ? null : (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            copyMainLangVersion(e, locale);
                          }}
                          name="copyMainLangVersion"
                          color="secondary"
                        />
                      }
                      label={Translation('resource.common.useMainLangVersionToFillIn', {
                        lang: Translation(regionLocaleMap[mainLocale].name),
                      })}
                    />
                  )}
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.rType !== currentValues.rType ||
                      prevValues.eventInvitation !== currentValues.eventInvitation ||
                      prevValues.eventInvitationRegisterPageType !== currentValues.eventInvitationRegisterPageType
                    }
                  >
                    {(_, meta, { getFieldValue }) => {
                      const eventInvitation = getFieldValue('eventInvitation');
                      return (
                        <SubFormList
                          activeLang={locale}
                          form={form}
                          disabled={disabled}
                          eventInvitation={eventInvitation === YesAndNo.yes}
                          nameMaxSize={120}
                          descriptMaxSize={160}
                          addressMaxSize={200}
                        />
                      );
                    }}
                  </Form.Item>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
      <Divider className={classes.sectionDivider} />
    </React.Fragment>
  );
};

export default React.memo(RegionSettings);
