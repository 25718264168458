import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { regionLocale } from 'src/app/i18n';
import InformationSection from './Information';
import PublishSettings from './PublishSettings';
import RegionSettingsSection from './RegionSettings';
import { useStyles } from './styles';
import { LibraryPublishStatus, PublishStatus } from 'src/app/modules/sales-kit/components/Library/constants';
import { fetchAllColumns } from 'src/app/modules/sales-kit/components/Column/network/columnCrud';
import Form, { useForm } from 'src/app/common/components/Form';
import { useCommonStyles } from 'src/app/common/styles/common-styles';

export const renderDisplayStatusField = (rowData: any): string => {
  switch (rowData.status) {
    case LibraryPublishStatus.PublishToSetOnly:
      return 'component.status.publishedToSetOnly';
    case LibraryPublishStatus.Publish:
      return 'component.status.published';
    case LibraryPublishStatus.Unpublished:
      return 'component.status.unpublished';
    case LibraryPublishStatus.Draft:
    default:
      return 'component.status.draft';
  }
};

interface FormListProp {
  disabled: boolean;
  blockName: string;
  categoryType: string;
  moduleType: string;
  blockType: string;
  columnType: string;
  initialValues: any;
  isEdit: boolean;
  history: any;
  location?: any;
  match?: any;
  currentId?: string;

  loadingFun?: () => void;
}

export type ColumnOptions = {
  label: string;
  value: string;
  id: string;
}[];

const FormList: React.FC<FormListProp> = (props) => {
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const { disabled, initialValues, isEdit, history, moduleType, currentId } = props;
  const [columnOptions, setColumnOptions] = useState<ColumnOptions>([]);
  const regionLocales = regionLocale;
  const [publishItems, setPublishItems] = useState<any[]>([]);

  // eslint-disable-next-line
  const [form] = useForm();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);

  const isAdd = isEdit || disabled ? false : true;

  useEffect(() => {
    getDisplayStatus();
  }, [initialValues?.status]);

  useEffect(() => {
    getColumnOptionList();
    if (initialValues?.moduleColumn?.length === 0) {
      initialValues.moduleColumn = [
        {
          column: '',
          weight: null,
        },
      ];
    }

    if (!initialValues?.designations) {
      initialValues.designations = [];
    }

    if (!initialValues?.zones) {
      initialValues.zones = [];
    }

    if (initialValues?.agendaList?.length === 0) {
      initialValues.agendaList = [
        {
          time: null,
          content: '',
        },
      ];
    }

    form.setFieldsValue({ ...initialValues });
    getDisplayStatus();
    // eslint-disable-next-line
  }, []);

  const getColumnOptionList = async () => {
    let options: any[] = [];
    let resultList = await fetchAllColumns(moduleType);
    resultList.map((item: any) => {
      const displayLabel = regionLocales.map((localItem: any, index: any) =>
        item.name[localItem] ? `${index > 0 ? ` (` : ``}${item.name[localItem]}${index > 0 ? `)` : ``}` : ``,
      );
      return options.push({ label: displayLabel, value: item.id, id: item.id });
    });
    setColumnOptions(options as ColumnOptions);
  };

  const onCancel = () => {
    history.goBack();
  };

  const getDisplayStatus = (selectedType: string = '') => {
    const isDraft = initialValues.status === LibraryPublishStatus.Draft;
    let displayStatus: any = [];

    if (isAdd || isDraft) {
      displayStatus = displayStatus.concat([PublishStatus.Draft, PublishStatus.Published]);
    } else {
      displayStatus = displayStatus.concat([PublishStatus.Published, PublishStatus.Unpublished]);
    }

    setPublishItems(displayStatus);
  };

  return (
    <div>
      <Form form={form} initialValues={{ ...initialValues }}>
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <div className={classes.rowContainer}>
              <div className={commonClasses.header}>
                {isEdit
                  ? Translation('component.formTitle.edit')
                  : disabled
                  ? Translation('component.formTitle.detail')
                  : Translation('component.formTitle.add')}
              </div>
            </div>
            <Button className={classes.btnBack} variant="contained" onClick={() => history.goBack()}>
              {Translation('app.button.back')}
            </Button>
          </div>

          <InformationSection disabled={disabled} form={form} moduleType={moduleType} />
          <RegionSettingsSection form={form} moduleType={moduleType} disabled={disabled} />

          <PublishSettings
            disabled={disabled}
            columnOptions={columnOptions}
            publishItems={publishItems}
            onCancel={onCancel}
            form={form}
            moduleType={moduleType}
          />
        </div>
      </Form>
    </div>
  );
};

export default FormList;
