import { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
// import { INCENTIVE_BASE_PATH, INCENTIVE_INCENTIVE_SET_PATH } from '../../constants';
import { INCENTIVE_BASE_PATH_V2, INCENTIVE_INCENTIVE_SET_PATH } from '../../constants';
import IncentiveSetListingPage, { incentiveSetFilterKeys } from './List/IncentiveSetListingPage';
import IncentiveSetDetailPage from './Form/IncentiveSetDetailPage';

// export const incentiveSetPath = `${INCENTIVE_BASE_PATH}${INCENTIVE_INCENTIVE_SET_PATH}`;
export const incentiveSetPath = `${INCENTIVE_BASE_PATH_V2}${INCENTIVE_INCENTIVE_SET_PATH}`;

const IncentiveSetRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={incentiveSetPath} to={`${incentiveSetPath}/list`} />
      <PruRoute
        exact
        path={`${incentiveSetPath}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={incentiveSetFilterKeys}>
            <IncentiveSetListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute exact path={`${incentiveSetPath}/edit/:code`} component={IncentiveSetDetailPage} />
      <PruRoute exact path={`${incentiveSetPath}/create`} component={IncentiveSetDetailPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default IncentiveSetRoutes;
