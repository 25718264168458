import React, { FC, useState } from 'react';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { PaginateListMore } from 'src/app/common/types/common-types';
import moment from 'moment';
import { IAgentLeaderReportFilter, IAgentLeaderReportItem } from '../../../types/agent-leader-survey-types';
import {
  fetchAgentLeaderSurveyReport,
  fetchAgentLeaderSurveyReportFile,
  fetchTotalRecordAgentLeaderSurveyReport,
} from '../../../network/agentLeaderSurveyCrud';
import { getDisplayDate } from 'src/app/common/utils/time-utils';

type NonProspectSurveyReportProps = {} & ParamsProps;

const initialState: IAgentLeaderReportFilter = {
  agentCode: '',
  leaderCode: '',
  surveyTitle: '',
  summitedMonth: moment().endOf('month'),
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): IAgentLeaderReportFilter => {
  return initialParams
    ? {
        agentCode: '',
        leaderCode: '',
        surveyTitle: '',
        summitedMonth: initialParams.summitedMonth ? moment(initialParams.summitedMonth).endOf('month') : null,
        page: 1,
        limit: 5,
      }
    : initialState;
};

const AgentLeaderSurveyReport: FC<NonProspectSurveyReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilter = paramsInitiator(initialParams);

  const [filterState, setFilterState] = useState<IAgentLeaderReportFilter>(defaultFilter);
  const [status, setStatus] = useState<string>('');
  const [reportList, setReportList] = useState<PaginateListMore<IAgentLeaderReportItem>>();
  const { isLoading, refreshData } = useDataProvider<PaginateListMore<IAgentLeaderReportItem>>(
    async () => {
      try {
        const params = filterState;
        const result = await Promise.all([
          fetchAgentLeaderSurveyReport(params, dispatch),
          fetchTotalRecordAgentLeaderSurveyReport(params, dispatch),
        ]);
        return {
          ...result[0],
          ...result[1],
        };
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsv = () => {
    try {
      const params = filterState;
      fetchAgentLeaderSurveyReportFile(params, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('report.survey.agentLeaderSurvey.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('report.survey.agentLeaderSurvey.agentCode'),
          },

          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'leaderCode',
            initialValue: filterState.leaderCode,
            displayName: Translation('report.survey.agentLeaderSurvey.leaderCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'surveyTitle',
            initialValue: filterState.surveyTitle,
            displayName: Translation('report.survey.agentLeaderSurvey.surveyTitle'),
          },
          {
            type: PruFilterItemType.DATE_MONTH,
            field: 'summitedMonth',
            initialValue: filterState.summitedMonth,
            defaultValue: defaultFilter.summitedMonth,
            displayName: Translation('report.survey.agentLeaderSurvey.submmitedMonth'),
          },
        ]}
        onChangeFilter={(data) => {
          setStatus('');
          return setFilterState({
            ...filterState,
            ...data,
          });
        }}
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.survey.agentLeaderSurvey.title')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsv(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            keyIndex: 'surveyTitle',
            align: 'left',
            displayName: Translation('report.survey.agentLeaderSurvey.surveyTitle'),
            renderData: (row) => row.surveyTitle || '-',
          },
          {
            keyIndex: 'agentCode',
            align: 'left',
            displayName: Translation('report.survey.agentLeaderSurvey.agentCode'),
            renderData: (row) => row.agentCode || '-',
          },
          {
            keyIndex: 'agentName',
            align: 'left',
            displayName: Translation('report.survey.agentLeaderSurvey.agentName'),
            renderData: (row) => row.agentName || '-',
          },
          {
            keyIndex: 'leaderCode',
            align: 'left',
            displayName: Translation('report.survey.agentLeaderSurvey.leaderCode'),
            renderData: (row) => row.leaderCode || '-',
          },
          {
            keyIndex: 'leaderName',
            displayName: Translation('report.survey.agentLeaderSurvey.leaderName'),
            align: 'left',
            renderData: (row) => row.leaderName || '-',
          },
          {
            keyIndex: 'nrProgram',
            displayName: Translation('report.survey.agentLeaderSurvey.nrProgram'),
            align: 'left',
            renderData: (row) => row.nrProgram || '-',
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: Translation('report.survey.agentLeaderSurvey.createdAt'),
            renderData: (row) => (row.createdAt ? getDisplayDate(new Date(row.createdAt), 'YYYY-MM-DD HH:mm:ss') : '-'),
          },
          {
            keyIndex: 'agRequestJFWDate',
            align: 'center',
            displayName: Translation('report.survey.agentLeaderSurvey.agRequestJFWDate'),
            renderData: (row) =>
              row.agRequestJFWDate ? getDisplayDate(new Date(row.agRequestJFWDate), 'YYYY-MM-DD HH:mm:ss') : '-',
          },
          {
            keyIndex: 'updatedAt',
            align: 'center',
            displayName: Translation('report.survey.agentLeaderSurvey.updatedAt'),
            renderData: (row) => (row.updatedAt ? getDisplayDate(new Date(row.updatedAt), 'YYYY-MM-DD HH:mm:ss') : '-'),
          },
          {
            keyIndex: 'submittedAt',
            align: 'center',
            displayName: Translation('report.survey.agentLeaderSurvey.submittedAt'),
            renderData: (row) => getDisplayDate(new Date(row.submittedAt), 'YYYY-MM-DD HH:mm:ss') || '-',
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default AgentLeaderSurveyReport;
