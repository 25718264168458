import React, { FC, useState, useEffect, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
import { LayoutSplashScreen } from 'src/app/layout';
import { MdrtPassportDetail, MdrtPassportFormMode } from '../../../types/mdrt-passport-types';
import { fetchMdrtPassportItem } from '../../../network/mdrtPassportCrud';
import MdrtPassportDetailForm from './components/MdrtPassportDetailForm';

interface StateMdrtType {
  type: MdrtPassportFormMode;
}

const MdrtPassportDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const { id } = useParams<{ id: string }>();
  const { formMode, mdrtId } = useMemo(() => {
    const state = location.state as StateMdrtType;
    return {
      formMode: state.type,
      mdrtId: id ? id : undefined,
    };
  }, [id, location]);

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mdrtPassport, setMdrtPassport] = useState<MdrtPassportDetail>();

  const reloadData = () => {
    if (mdrtId) {
      setIsLoading(true);
      fetchMdrtPassportItem(mdrtId, dispatch)
        .then((result) => {
          setMdrtPassport(result);
        })
        .catch(() => {
          history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [mdrtId]);

  return (
    <>
      {formMode === MdrtPassportFormMode.CREATE && (
        <MdrtPassportDetailForm formMode={formMode} onReload={reloadData} onRouteTo={(route) => history.push(route)} />
      )}
      {formMode !== MdrtPassportFormMode.CREATE &&
        (isLoading || !mdrtPassport ? (
          <LayoutSplashScreen />
        ) : (
          <MdrtPassportDetailForm
            id={id}
            formMode={formMode}
            mdrtPassport={mdrtPassport}
            onReload={reloadData}
            onRouteTo={(route) => history.push(route)}
          />
        ))}
    </>
  );
};

export default MdrtPassportDetailPage;
