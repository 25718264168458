import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import PruTableCustom from 'src/app/common/components/PruTable/PruTableCustom';
import { useDataProvider } from 'src/app/common/utils';
import { getDayStart, getDayEnd, getDisplayDate } from 'src/app/common/utils/time-utils';
import {
  fetchDetailedCommentList,
  fetchDetailedCommentDownload,
  getBlob,
} from 'src/app/modules/Report/network/livestreamReportCrud';
import { DetailedCommentListParams, DetailedCommentPaginateList } from '../types/detail-comment-type';

type DetailedCommentListingPageProps = {} & ParamsProps;

const initialState: DetailedCommentListParams = {
  requestedDateFrom: getDayStart(new Date()),
  requestedDateTo: getDayEnd(new Date()),
  agentCode: '',
  livestreamName: '',
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): DetailedCommentListParams => {
  return initialParams
    ? {
        requestedDateFrom: initialParams.requestedDateFrom
          ? getDayStart(new Date(initialParams.requestedDateFrom))
          : getDayStart(new Date()),
        requestedDateTo: initialParams.requestedDateTo
          ? getDayEnd(new Date(initialParams.requestedDateTo))
          : getDayEnd(new Date()),
        agentCode: '',
        livestreamName: '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const DetailedCommentListingPage: FC<DetailedCommentListingPageProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<DetailedCommentListParams>(paramsInitiator(initialParams));
  const [reportList, setReportList] = useState<DetailedCommentPaginateList>();

  const { isLoading, refreshData } = useDataProvider<DetailedCommentPaginateList>(
    async () => {
      try {
        return await fetchDetailedCommentList(filterState, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvDownload = () => {
    try {
      fetchDetailedCommentDownload(filterState, dispatch);
    } catch (err) {}
  };

  const getBlobUrl = async (blobId: string) => {
    const blobDetail = await getBlob({ resourceIds: blobId }, dispatch);
    console.log(blobDetail[0]);
    return blobDetail;
  };

  return (
    <>
      <PruFilter
        title={Translation('livestream.report.common.filter')}
        itemDef={[
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'requestedDateFrom',
            fieldTo: 'requestedDateTo',
            initialDateFrom: filterState.requestedDateFrom,
            initialDateTo: filterState.requestedDateTo,
            displayName: Translation('report.common.requestDate'),
            defaultDateFrom: initialState.requestedDateFrom,
            defaultDateTo: initialState.requestedDateTo,
            periodTime: { months: 3 },
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('livestream.report.common.agentCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'livestreamName',
            initialValue: filterState.livestreamName,
            displayName: Translation('livestream.report.common.livestreamName'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            requestedDateFrom: data.requestedDateFrom,
            requestedDateTo: data.requestedDateTo,
            agentCode: data.agentCode,
            livestreamName: data.livestreamName,
          })
        }
        fetchData={refreshData}
      />
      <PruTableCustom
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('livestream.detailedComment.title')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvDownload(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: () => '',
          },
          {
            keyIndex: 'agentCode',
            displayName: Translation('livestream.report.common.agentCode'),
            renderData: (row: any) => row.agentCode,
          },
          {
            keyIndex: 'agentName',
            displayName: Translation('livestream.report.common.agentName'),
            renderData: (row: any) => row.agentName,
          },
          {
            keyIndex: 'officeCode',
            displayName: Translation('livestream.report.common.officeCode'),
            renderData: (row: any) => row.officeCode,
          },
          {
            keyIndex: 'livestreamName',
            displayName: Translation('livestream.report.common.livestreamName'),
            renderData: (row: any) => row.livestreamName,
          },
          {
            keyIndex: 'livestreamStartAt',
            displayName: Translation('livestream.report.common.livestreamStartAt'),
            renderData: (row: any) => getDisplayDate(row.livestreamStartAt, 'YYYY-MM-DD'),
          },
          {
            keyIndex: 'comment',
            displayName: Translation('livestream.detailedComment.formLabel.comment'),
            renderData: (row: any) => row.comment,
          },
          {
            keyIndex: 'commentTime',
            displayName: Translation('livestream.detailedComment.formLabel.commentTime'),
            renderData: (row: any) => getDisplayDate(row.commentTime, 'HH:mm:ss'),
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default DetailedCommentListingPage;
