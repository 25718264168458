import React, { FC } from "react";
import { Switch, Redirect, RouteComponentProps } from "react-router-dom";
import ParamsProvider from "src/app/common/components/ParamsProvider";
import PruRoute from "src/app/common/components/PruRoute";
import { NOT_FOUND_ERROR_PAGE_PATH } from "src/app/common/constants";
import { MDRT_HUB_BASE_PATH, QUOTES_AND_CERTIFICATE_BASE_PATH } from "../../constants";
import QuotesAndCertificateListingPage from "./List/QuotesAndCertificateListingPage";
import QuotesAndCertificateDetailPage from "./Create/QuotesAndCertificateDetailPage";
import { filterKeys } from "src/app/modules/QAIncome/pages/List/QuestionListingPage";

const QuotesAndCertificateRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={`${MDRT_HUB_BASE_PATH}${QUOTES_AND_CERTIFICATE_BASE_PATH}`}
        to={`${MDRT_HUB_BASE_PATH}${QUOTES_AND_CERTIFICATE_BASE_PATH}/list`}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${QUOTES_AND_CERTIFICATE_BASE_PATH}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={filterKeys}>
            <QuotesAndCertificateListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${QUOTES_AND_CERTIFICATE_BASE_PATH}/edit/:id`}
        component={QuotesAndCertificateDetailPage}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${QUOTES_AND_CERTIFICATE_BASE_PATH}/create`}
        component={QuotesAndCertificateDetailPage}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default QuotesAndCertificateRoutes;
