import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  AdminAssignCandidatePermissionType,
  AdminCandidatePermissionType,
  ASSIGN_CANDIDATE_NAMESPACE,
  ASSIGN_CANDIDATE_PATH,
  ASSIGN_CANDIDATE_TITLE,
  CANDIDATE_BASE_PATH,
  CANDIDATE_NAMESPACE,
  CANDIDATE_TITLE,
  UPDATE_CANDIDATE_ASSESSMENT_NAMESPACE,
  UPDATE_CANDIDATE_ASSESSMENT_PATH,
  UPDATE_CANDIDATE_ASSESSMENT_TITLE,
} from './constants';
import AssignCandidatePage from './pages/Assign-Candidate/AssignCandidatePage';
import { isPermitted } from 'src/app/common/utils';
import UpdateCandidateAssessmentPage from './pages/Upload-Candidate-Assessment-PV/UpdateCandidateAssessmentPVPage';

export const candidateModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(CANDIDATE_TITLE),
    path: CANDIDATE_BASE_PATH,
    icon: '/media/svg/menu/candidate.svg',
    namespace: CANDIDATE_NAMESPACE,
    enableRead: isPermitted(Object.values(AdminAssignCandidatePermissionType), permissions),
    child: [
      {
        title: Translation(ASSIGN_CANDIDATE_TITLE),
        path: `${CANDIDATE_BASE_PATH}${ASSIGN_CANDIDATE_PATH}`,
        namespace: ASSIGN_CANDIDATE_NAMESPACE,
        component: AssignCandidatePage,
        enableRead: isPermitted([AdminAssignCandidatePermissionType.ASSIGN_CANDIDATE_READ], permissions),
        enableCreate: isPermitted([AdminAssignCandidatePermissionType.ASSIGN_CANDIDATE_CREATE], permissions),
        enableUpdate: isPermitted([AdminAssignCandidatePermissionType.ASSIGN_CANDIDATE_UPDATE], permissions),
      },
      {
        title: Translation(UPDATE_CANDIDATE_ASSESSMENT_TITLE),
        path: `${CANDIDATE_BASE_PATH}${UPDATE_CANDIDATE_ASSESSMENT_PATH}`,
        namespace: UPDATE_CANDIDATE_ASSESSMENT_NAMESPACE,
        component: UpdateCandidateAssessmentPage,
        enableRead: isPermitted([AdminAssignCandidatePermissionType.ASSIGN_CANDIDATE_READ], permissions),
        enableCreate: isPermitted([AdminAssignCandidatePermissionType.ASSIGN_CANDIDATE_CREATE], permissions),
        enableUpdate: isPermitted([AdminAssignCandidatePermissionType.ASSIGN_CANDIDATE_UPDATE], permissions),
      },
    ],
  };
};
