import React, { FC } from "react";
import { RouteComponentProps, Switch, Redirect } from "react-router-dom";
import {
  SURVEY_BASE_PATH,
  SURVEY_NON_PROSPECT_PATH,
} from "src/app/modules/Survey/constants";
import { NOT_FOUND_ERROR_PAGE_PATH } from "src/app/common/constants";
import PruRoute from "src/app/common/components/PruRoute";
import ParamsProvider from "src/app/common/components/ParamsProvider";
import { surveyFilterKeys } from "src/app/modules/Survey/pages/Management/List/SurveyListingPage";
import NonProspectSurveyListingPage from "./List/NonProspectSurveyListingPage";
import NonProspectSurveyDetailPage from "./Create/NonProspectSurveyDetailPage";
import NonProspectSurveyAnalyticPage from "./Analytics/NonProspectSurveyAnalyticPage";

const NonProspectSurveyManagementPage: FC<RouteComponentProps> = ({ history }) => {
  return (
    <Switch>
      <Redirect
        exact
        from={`${SURVEY_BASE_PATH}${SURVEY_NON_PROSPECT_PATH}`}
        to={`${SURVEY_BASE_PATH}${SURVEY_NON_PROSPECT_PATH}/list`}
      />
      <PruRoute
        exact
        path={`${SURVEY_BASE_PATH}${SURVEY_NON_PROSPECT_PATH}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={surveyFilterKeys}>
            <NonProspectSurveyListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute
        exact
        path={`${SURVEY_BASE_PATH}${SURVEY_NON_PROSPECT_PATH}/edit/:id`}
        component={NonProspectSurveyDetailPage}
      />
      <PruRoute
        exact
        path={`${SURVEY_BASE_PATH}${SURVEY_NON_PROSPECT_PATH}/create`}
        component={NonProspectSurveyDetailPage}
      />
      <PruRoute
        exact
        path={`${SURVEY_BASE_PATH}${SURVEY_NON_PROSPECT_PATH}/:id/results`}
        component={NonProspectSurveyAnalyticPage}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default NonProspectSurveyManagementPage;
