import React, { FC } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import PruRoute from '../../../../common/components/PruRoute';
import CustomerContactTrackingListingPage from './List/CustomerContactTrackingListingPage';
import ParamsProvider from '../../../../common/components/ParamsProvider';
import { reportFilterKeys } from '../../constants';

const CustomerContactTrackingPage: FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/list`} />
      <PruRoute
        exact
        path={`${match.path}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={reportFilterKeys}>
            <CustomerContactTrackingListingPage />
          </ParamsProvider>
        )}
      />
    </Switch>
  )
};

export default CustomerContactTrackingPage;
