import React, { FC, useState } from 'react';
import { map } from 'lodash';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { getDayEnd, getFirstDayOfMonth, getDayStart, getLastDayOfMonth } from '../../../../../common/utils/time-utils';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { PaginateListMore } from 'src/app/common/types/common-types';
import { IReportFilter, ICandidateInformationReportItem, ChannelEnum } from '../../../types/candidate-types';
import { fetchCandidateInformationReport, fetchCandidateInformationReportCSV } from '../../../network/candidateCrud';
import { useDataProvider } from 'src/app/common/utils';

enum EIncentiveType {
  Incentive = 'Incentive',
  Compensation = 'Compensation',
}

export interface QrCodeData {
  identifyNumber: string | null;
  oldIdentifyNumber: string | null;
  fullName: string | null;
  dateOfBirth: string | null;
  gender: string | null;
  address: string | null;
  issuedDate: string | null;
}

type CandidateInformationReportProps = {} & ParamsProps;

const initialState: IReportFilter = {
  fromDate: getDayStart(new Date()),
  toDate: getDayEnd(new Date()),
  channelCandidate: '',
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): IReportFilter => {
  return initialParams
    ? {
        fromDate: initialParams.fromDate
          ? getDayStart(new Date(initialParams.fromDate))
          : getFirstDayOfMonth(new Date()),
        toDate: initialParams.toDate
          ? getLastDayOfMonth(new Date(initialParams.toDate))
          : getLastDayOfMonth(new Date()),
        channelCandidate: initialParams.channelCandidate || '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const CandidateInformationReport: FC<CandidateInformationReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilter = paramsInitiator(initialParams);
  const [filterState, setFilterState] = useState<IReportFilter>(defaultFilter);
  const [reportList, setReportList] = useState<PaginateListMore<ICandidateInformationReportItem>>();
  const { isLoading, refreshData } = useDataProvider<PaginateListMore<ICandidateInformationReportItem>>(
    async () => {
      try {
        return await fetchCandidateInformationReport(filterState, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsv = () => {
    try {
      fetchCandidateInformationReportCSV(filterState, dispatch);
    } catch (err) {}
  };

  const getInfoOcrByQrCode = (idCard: any): QrCodeData => {
    let qrCodeData = {
      identifyNumber: null,
      oldIdentifyNumber: null,
      fullName: null,
      dateOfBirth: null,
      gender: null,
      address: null,
      issuedDate: null,
    };

    if (!idCard.QRcode) return qrCodeData;

    if (idCard.QRcode === 'N/A') return qrCodeData;

    const sp = idCard.QRcode.split('|');

    if (sp.length < 5) return qrCodeData;

    qrCodeData.identifyNumber = sp[0];
    qrCodeData.oldIdentifyNumber = sp[1];
    qrCodeData.fullName = sp[2];
    qrCodeData.dateOfBirth = sp[3];
    qrCodeData.gender = sp[4];
    qrCodeData.address = sp[5];
    qrCodeData.issuedDate = sp[6];

    return qrCodeData;
  };

  return (
    <>
      <PruFilter
        title={Translation('report.candidate.candidateInformation.filter')}
        itemDef={[
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'channelCandidate',
            initialValue: filterState.channelCandidate,
            displayName: Translation('report.candidate.candidateInformation.filter.channel'),
            choices: [
              { displayName: 'All', value: '' },
              ...map(ChannelEnum, (option: string, key: string) => ({ displayName: option, value: key })),
            ],
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'fromDate',
            fieldTo: 'toDate',
            initialDateFrom: filterState.fromDate,
            initialDateTo: filterState.toDate,
            defaultDateFrom: defaultFilter.fromDate,
            defaultDateTo: defaultFilter.toDate,
            displayName: Translation('report.candidate.candidateInformation.filter.dateRange'),
            isRequired: true,
            periodTime: { month: 1 },
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            ...data,
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.candidate.candidateInformation.title')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsv(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            keyIndex: 'stt',
            displayName: Translation('report.candidate.candidateInformation.stt'),
            align: 'center',
            renderData: (row) => row.stt.toString(),
          },
          {
            keyIndex: 'channel',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.channel'),
            renderData: (row) => row.channel,
          },
          {
            keyIndex: 'eRef',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.eRef'),
            renderData: (row) => row.eRef,
          },
          {
            keyIndex: 'submissionDate',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.submissionDate'),
            renderData: (row) => row.submissionDate,
          },
          {
            keyIndex: 'lastName',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.lastName'),
            renderData: (row) => row.lastName,
          },
          {
            keyIndex: 'firstName',
            displayName: Translation('report.candidate.candidateInformation.firstName'),
            align: 'center',
            renderData: (row) => row.firstName,
          },
          {
            keyIndex: 'dob',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.dob'),
            renderData: (row) => row.dob,
          },
          {
            keyIndex: 'genderDesc',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.genderDesc'),
            renderData: (row) => row.genderDesc,
          },
          {
            keyIndex: 'gender',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.gender'),
            renderData: (row) => row.gender,
          },
          {
            keyIndex: 'citizenId',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.citizenId'),
            renderData: (row) => row.citizenId,
          },
          {
            keyIndex: 'idCard',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.idCard'),
            renderData: (row) => `${row.oldIdentifyNumber ? "'" + row.oldIdentifyNumber : 'NULL'}`,
          },
          {
            keyIndex: 'idDate',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.idDate'),
            renderData: (row) => row.idDate,
          },
          {
            keyIndex: 'idPlaceDesc',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.idPlaceDesc'),
            renderData: (row) => row.idPlaceDesc,
          },
          {
            keyIndex: 'identityExpirationDate',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.identityExpirationDate'),
            renderData: (row) => row.identityExpirationDate,
          },
          {
            keyIndex: 'modified',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.modified'),
            renderData: (row) =>
              `${
                row.hasOwnProperty('isEditOcr')
                  ? row.isEditOcr === null
                    ? 'NULL'
                    : row.isEditOcr
                    ? 'YES'
                    : 'NO'
                  : 'NULL'
              }`,
          },
          {
            keyIndex: 'citizenIdQrCode',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.citizenIdQrCode'),
            renderData: (row) =>
              `${
                row.idCard
                  ? row.idCard.QRcode
                    ? getInfoOcrByQrCode(row.idCard)?.identifyNumber ?? 'NULL'
                    : 'NULL'
                  : 'NULL'
              }`,
          },
          {
            keyIndex: 'oldCitizenIdQrCode',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.oldCitizenIdQrCode'),
            renderData: (row) =>
              `${
                row.idCard
                  ? row.idCard.QRcode
                    ? getInfoOcrByQrCode(row.idCard)?.oldIdentifyNumber ?? 'NULL'
                    : 'NULL'
                  : 'NULL'
              }`,
          },
          {
            keyIndex: 'nameQrCode',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.nameQrCode'),
            renderData: (row) =>
              `${
                row.idCard ? (row.idCard.QRcode ? getInfoOcrByQrCode(row.idCard)?.fullName ?? 'NULL' : 'NULL') : 'NULL'
              }`,
          },
          {
            keyIndex: 'dobQrCode',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.dobQrCode'),
            renderData: (row) =>
              `${
                row.idCard
                  ? row.idCard.QRcode
                    ? getInfoOcrByQrCode(row.idCard)?.dateOfBirth ?? 'NULL'
                    : 'NULL'
                  : 'NULL'
              }`,
          },
          {
            keyIndex: 'genderQrCode',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.genderQrCode'),
            renderData: (row) =>
              `${
                row.idCard ? (row.idCard.QRcode ? getInfoOcrByQrCode(row.idCard)?.gender ?? 'NULL' : 'NULL') : 'NULL'
              }`,
          },
          {
            keyIndex: 'residentAddressQrCode',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.residentAddressQrCode'),
            renderData: (row) =>
              `${
                row.idCard ? (row.idCard.QRcode ? getInfoOcrByQrCode(row.idCard)?.address ?? 'NULL' : 'NULL') : 'NULL'
              }`,
          },
          {
            keyIndex: 'idDateQrCode',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.idDateQrCode'),
            renderData: (row) =>
              `${
                row.idCard
                  ? row.idCard.QRcode
                    ? getInfoOcrByQrCode(row.idCard)?.issuedDate ?? 'NULL'
                    : 'NULL'
                  : 'NULL'
              }`,
          },
          {
            keyIndex: 'residentAddress',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.residentAddress'),
            renderData: (row) => row.residentAddress,
          },
          {
            keyIndex: 'residentAddressCode',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.residentAddressCode'),
            renderData: (row) => row.residentAddressCode,
          },
          {
            keyIndex: 'residentAddressDesc',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.residentAddressDesc'),
            renderData: (row) => row.residentAddressDesc,
          },
          {
            keyIndex: 'contactAddress',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.contactAddress'),
            renderData: (row) => row.contactAddress,
          },
          {
            keyIndex: 'contactAddressCode',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.contactAddressCode'),
            renderData: (row) => row.contactAddressCode,
          },
          {
            keyIndex: 'contactAddressDesc',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.contactAddressDesc'),
            renderData: (row) => row.contactAddressDesc,
          },
          {
            keyIndex: 'despatchAddress',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.despatchAddress'),
            renderData: (row) => row.despatchAddress,
          },
          {
            keyIndex: 'despatchAddressCode',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.despatchAddressCode'),
            renderData: (row) => row.despatchAddressCode,
          },
          {
            keyIndex: 'despatchAddressDesc',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.despatchAddressDesc'),
            renderData: (row) => row.despatchAddressDesc,
          },
          {
            keyIndex: 'email',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.email'),
            renderData: (row) => row.email,
          },
          {
            keyIndex: 'phone',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.phone'),
            renderData: (row) => row.phone,
          },
          {
            keyIndex: 'birthPlaceDesc',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.birthPlaceDesc'),
            renderData: (row) => row.birthPlaceDesc,
          },
          {
            keyIndex: 'zalo',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.zalo'),
            renderData: (row) => row.zalo,
          },
          {
            keyIndex: 'occupationDesc',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.occupationDesc'),
            renderData: (row) => row.occupationDesc,
          },
          {
            keyIndex: 'occupation',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.occupation'),
            renderData: (row) => row.occupation,
          },
          {
            keyIndex: 'educationDesc',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.educationDesc'),
            renderData: (row) => row.educationDesc,
          },
          {
            keyIndex: 'education',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.education'),
            renderData: (row) => row.education,
          },
          {
            keyIndex: 'marriedStatusDesc',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.marriedStatusDesc'),
            renderData: (row) => row.marriedStatusDesc,
          },
          {
            keyIndex: 'marriedStatus',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.marriedStatus'),
            renderData: (row) => row.marriedStatus,
          },
          {
            keyIndex: 'incomeDesc',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.incomeDesc'),
            renderData: (row) => row.incomeDesc,
          },
          {
            keyIndex: 'leaderCode',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.leaderCode'),
            renderData: (row) => row.leaderCode,
          },
          {
            keyIndex: 'leaderCodeDesc',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.leaderCodeDesc'),
            renderData: (row) => row.leaderCodeDesc,
          },
          {
            keyIndex: 'bankCode',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.bankCode'),
            renderData: (row) => row.bankCode,
          },
          {
            keyIndex: 'bankNameDesc',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.bankNameDesc'),
            renderData: (row) => row.bankNameDesc,
          },
          {
            keyIndex: 'branchNameDesc',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.branchNameDesc'),
            renderData: (row) => row.branchNameDesc,
          },
          {
            keyIndex: 'otherBankNameDesc',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.otherBankNameDesc'),
            renderData: (row) => row.otherBankNameDesc,
          },
          {
            keyIndex: 'otherBranchNameDesc',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.otherBranchNameDesc'),
            renderData: (row) => row.otherBranchNameDesc,
          },
          {
            keyIndex: 'bankAccount',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.bankAccount'),
            renderData: (row) => row.bankAccount,
          },
          {
            keyIndex: 'taxNo',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.taxNo'),
            renderData: (row) => row.taxNo,
          },
          {
            keyIndex: 'refAgentCode',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.refAgentCode'),
            renderData: (row) => row.refAgentCode,
          },
          {
            keyIndex: 'refAgentName',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.refAgentName'),
            renderData: (row) => row.refAgentName,
          },
          {
            keyIndex: 'ethnicity',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.ethnicity'),
            renderData: (row) => row.ethnicity,
          },
          {
            keyIndex: 'major',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.major'),
            renderData: (row) => row.major,
          },
          {
            keyIndex: 'childrenNo',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.childrenNo'),
            renderData: (row) => row.childrenNo.toString(),
          },
          {
            keyIndex: 'saleZone',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.saleZone'),
            renderData: (row) => row.saleZone,
          },
          {
            keyIndex: 'officeCode',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.officeCode'),
            renderData: (row) => row.officeCode,
          },
          {
            keyIndex: 'agCat',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.agCat'),
            renderData: (row) => row.agCat,
          },
          {
            keyIndex: 'note',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.note'),
            renderData: (row) => row.note,
          },
          {
            keyIndex: 'bsm',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.bsm'),
            renderData: (row) => row.bsm,
          },
          {
            keyIndex: 'millenial',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.millenial'),
            renderData: (row) => row.millenial,
          },
          {
            keyIndex: 'subChannel',
            align: 'center',
            displayName: Translation('report.candidate.candidateInformation.subChannel'),
            renderData: (row) => row.subChannel,
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default CandidateInformationReport;
