import { IntlShape } from 'react-intl';
import { isPermitted } from 'src/app/common/utils';
import { ModuleHierachy } from '../../common/module/types';
import {
  RECRUIT_TITLE,
  RECRUIT_AMD_EMAIL_TITLE,
  RECRUIT_PERSONAL_PARTICULAR_TITLE,
  RECRUIT_GPS_LOCATION_TITLE,
  RECRUIT_FOLLOW_UP_CASE_TITLE,
  RECRUIT_BASE_PATH,
  RECRUIT_AMD_EMAIL_PATH,
  RECRUIT_PERSONAL_PARTICULAR_PATH,
  RECRUIT_GPS_LOCATION_PATH,
  RECRUIT_FOLLOW_UP_CASE_PATH,
  RECRUIT_AMD_EMAIL_NAMESPACE,
  RECRUIT_PERSONAL_PARTICULAR_NAMESPACE,
  RECRUIT_GPS_LOCATION_NAMESPACE,
  RECRUIT_FOLLOW_UP_CASE_NAMESPACE,
  AdminRecruitPermissionType,
} from './constants';
import AmdEmailRoutes from './pages/AmdEmail/AmdEmailRoutes';
import PersonalParticularRoutes from './pages/PersonalParticular/PersonalParticularRoutes';
import GpsLocationRoutes from './pages/GpsLocation/GpsLocationRoutes';
import FollowUpCaseRoutes from './pages/FollowUpCase/FollowUpCaseRoutes';

export const recruitModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });

  return {
    title: Translation(RECRUIT_TITLE),
    path: RECRUIT_BASE_PATH,
    icon: '/media/svg/icons/General/Clipboard.svg',
    enableRead: isPermitted(Object.values(AdminRecruitPermissionType), permissions),
    child: [
      {
        title: Translation(RECRUIT_AMD_EMAIL_TITLE),
        path: `${RECRUIT_BASE_PATH}${RECRUIT_AMD_EMAIL_PATH}`,
        namespace: RECRUIT_AMD_EMAIL_NAMESPACE,
        component: AmdEmailRoutes,
        enableRead: isPermitted(
          [
            AdminRecruitPermissionType.RECRUIT_AMD_EMAIL_READ,
            AdminRecruitPermissionType.RECRUIT_AMD_EMAIL_CREATE,
            AdminRecruitPermissionType.RECRUIT_AMD_EMAIL_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminRecruitPermissionType.RECRUIT_AMD_EMAIL_CREATE], permissions),
        enableUpdate: isPermitted([AdminRecruitPermissionType.RECRUIT_AMD_EMAIL_UPDATE], permissions),
      },
      {
        title: Translation(RECRUIT_PERSONAL_PARTICULAR_TITLE),
        path: `${RECRUIT_BASE_PATH}${RECRUIT_PERSONAL_PARTICULAR_PATH}`,
        namespace: RECRUIT_PERSONAL_PARTICULAR_NAMESPACE,
        component: PersonalParticularRoutes,
        enableRead: isPermitted(
          [
            AdminRecruitPermissionType.RECRUIT_PERSONAL_PARTICULAR_READ,
            AdminRecruitPermissionType.RECRUIT_PERSONAL_PARTICULAR_CREATE,
            AdminRecruitPermissionType.RECRUIT_PERSONAL_PARTICULAR_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminRecruitPermissionType.RECRUIT_PERSONAL_PARTICULAR_CREATE], permissions),
        enableUpdate: isPermitted([AdminRecruitPermissionType.RECRUIT_PERSONAL_PARTICULAR_UPDATE], permissions),
      },
      {
        title: Translation(RECRUIT_GPS_LOCATION_TITLE),
        path: `${RECRUIT_BASE_PATH}${RECRUIT_GPS_LOCATION_PATH}`,
        namespace: RECRUIT_GPS_LOCATION_NAMESPACE,
        component: GpsLocationRoutes,
        enableRead: isPermitted(
          [
            AdminRecruitPermissionType.RECRUIT_GPS_LOCATION_READ,
            AdminRecruitPermissionType.RECRUIT_GPS_LOCATION_CREATE,
            AdminRecruitPermissionType.RECRUIT_GPS_LOCATION_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminRecruitPermissionType.RECRUIT_GPS_LOCATION_CREATE], permissions),
        enableUpdate: isPermitted([AdminRecruitPermissionType.RECRUIT_GPS_LOCATION_UPDATE], permissions),
      },
      {
        title: Translation(RECRUIT_FOLLOW_UP_CASE_TITLE),
        path: `${RECRUIT_BASE_PATH}${RECRUIT_FOLLOW_UP_CASE_PATH}`,
        namespace: RECRUIT_FOLLOW_UP_CASE_NAMESPACE,
        component: FollowUpCaseRoutes,
        enableRead: isPermitted(
          [
            AdminRecruitPermissionType.RECRUIT_FOLLOW_UP_CASE_READ,
            AdminRecruitPermissionType.RECRUIT_FOLLOW_UP_CASE_CREATE,
            AdminRecruitPermissionType.RECRUIT_FOLLOW_UP_CASE_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminRecruitPermissionType.RECRUIT_FOLLOW_UP_CASE_CREATE], permissions),
        enableUpdate: isPermitted([AdminRecruitPermissionType.RECRUIT_FOLLOW_UP_CASE_UPDATE], permissions),
      },
    ],
  };
};
