import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import { AdminQAIncomePermissionType, QA_INCOME_BASE_PATH, QA_INCOME_NAMESPACE, QA_INCOME_TITLE } from './constants';
import QAIncomeRoutes from './pages/QAIncomeRoutes';
import { isPermitted } from 'src/app/common/utils';

export const qaIncomeModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(QA_INCOME_TITLE),
    path: QA_INCOME_BASE_PATH,
    icon: '/media/svg/icons/Communication/Chat5.svg',
    component: QAIncomeRoutes,
    namespace: QA_INCOME_NAMESPACE,
    enableRead: isPermitted(
      [
        AdminQAIncomePermissionType.QA_INCOME_READ,
        AdminQAIncomePermissionType.QA_INCOME_CREATE,
        AdminQAIncomePermissionType.QA_INCOME_UPDATE,
      ],
      permissions,
    ),
  };
};
