import React, { FC } from "react";
import { RouteComponentProps, Switch, Redirect } from "react-router-dom";
import { ASSIGN_SERVING_SERVEY_PATH, SURVEY_BASE_PATH, SURVEY_MANAGEMENT_PATH } from "../../constants";
import { NOT_FOUND_ERROR_PAGE_PATH } from "../../../../common/constants";
import PruRoute from "../../../../common/components/PruRoute";
import ParamsProvider from "../../../../common/components/ParamsProvider";
import AssignServingSuveyUploadPage from "./UploadPage/AssignServingSurveyUploadPage";

const AssignServingSurveyPage: FC<RouteComponentProps> = ({ history }) => {
  return (
    <Switch>
      {/* <Redirect exact from={`${SURVEY_BASE_PATH}${SURVEY_MANAGEMENT_PATH}`} to={`${SURVEY_BASE_PATH}${SURVEY_MANAGEMENT_PATH}/list`} /> */}
      
      <PruRoute exact path={`${SURVEY_BASE_PATH}${ASSIGN_SERVING_SERVEY_PATH}`} component={AssignServingSuveyUploadPage}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
      
    </Switch>
  )
}

export default AssignServingSurveyPage;