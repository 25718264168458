import React, { Fragment, useState, useEffect, useMemo, CSSProperties } from 'react';
import { usePaginateHandler } from '../../utils/paginate-utils';
import { useCommonStyles } from '../../styles/common-styles';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  Button,
  TableBody,
  TableFooter,
  TableCellProps,
  Toolbar,
  lighten,
  Checkbox,
  Typography,
  TableSortLabel,
  Pagination,
  TablePagination,
} from '@mui/material';
import { PruTableHeader } from '../Table/PruTableHeader';
import PruTableLoading from '../Table/PruTableLoading';
import PruTableEmptyRow from '../Table/PruTableEmptyRow';
import ToolBarContainer from '../ProTable/ToolBarContainer';
import StickyTableCell from '../ProTable/StickyTableCell';
import { PruTableRow } from '../Table/PruTableRow';
import PruTablePaginationActions from '../Table/PruTablePaginationActions';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import {
  AddBoxOutlined,
  CheckBoxOutlineBlankOutlined,
  IndeterminateCheckBox,
  IndeterminateCheckBoxOutlined,
  Refresh,
} from '@mui/icons-material';

export enum PruTableSortType {
  ASC = 'asc',
  DESC = 'desc',
}

export type PruTableButtonDef = {
  color: 'inherit' | 'primary' | 'secondary' | 'default';
  title: string | JSX.Element;
  onClick: () => void;
  condition?: () => boolean;
};

export type PruTableOperationDef<T> = {
  title: string | JSX.Element;
  tooltipText: string;
  condition?: (row: T) => boolean;
  onClick: (row: T) => void;
};

export type PruTableBulkSelectDef<T> = {
  title: string | JSX.Element;
  variant?: 'text' | 'outlined' | 'contained';
  color: 'inherit' | 'primary' | 'secondary' | 'default';
  onClick: (rows: T[]) => void;
  condition?: (rows: T[]) => boolean;
};

export type PruTableColumnDef<T> = {
  isId?: boolean;
  hidden?: boolean;
  keyIndex: keyof T | string;
  displayName: string | JSX.Element;
  sortable?: boolean;
  style?: CSSProperties;
  colspan?: number;
  rowspan?: number;
  isCombine?: boolean;
  renderData: (row: T) => string | JSX.Element | Promise<string>;
  onSort?: (sort: SortState) => void;
} & TableCellProps;

type PruTableProps<T extends Record<string, unknown>> = {
  title?: string;
  subTitle?: string;
  isLoading: boolean;
  disableBulkSelect?: boolean;
  bulkSelectCheckboxDisable?: (rowData: any) => boolean;
  bulkSelectDef?: PruTableBulkSelectDef<T>[];
  currentSelectedRow?: (rowData: any) => void;
  disableRefresh?: boolean;
  disablePagination?: boolean;
  disableToolbar?: boolean;
  headerBtnDef?: PruTableButtonDef[];
  operationDef: PruTableOperationDef<T>[];
  columnDef: PruTableColumnDef<T>[];
  dataSource?: T[];
  totalRecords?: number;
  totalPages?: number;
  renderChildren?: boolean;
  defaultRowsPerPage?: number;
  defaultPageNumber?: number;
  operationSticky?: boolean;
  hideBulkSelectHeader?: boolean;
  updateSelectedRow?: any[];
  onRefresh?: () => void;
  onChangePage?: (page: number, rowsPerPage: number) => void;
  rowOnClicked?: (rowData: any) => void;
  type?: string;
  rowsPerPageInit?: number;
  isCombineHeader?: boolean;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
    overflowY: 'hidden',
  },
  table: {
    minWidth: 700,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tableHeader: {
    width: '100%',
    padding: '20px 0 20px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  bulkActions: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sortIcon: {
    color: `${theme.palette.common.white} !important`,
  },
  headerIcon: {
    color: `${theme.palette.common.white} !important`,
    opacity: 1,
  },
  selectedRow: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  },
  footer: {
    width: '100%',
  },
  operationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  operationBtn: {
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '0.85rem',
    whiteSpace: 'nowrap',
  },
  disabled: {
    color: '#BBBBBB',
    cursor: 'default',
  },
}));

type SortState = {
  [id: string]: PruTableSortType | undefined;
};

const PruTableCustom = <T extends Record<string, unknown>>({
  title,
  subTitle,
  isLoading,
  isCombineHeader,
  disableRefresh,
  disableBulkSelect,
  disablePagination,
  disableToolbar,
  bulkSelectCheckboxDisable,
  currentSelectedRow,
  headerBtnDef,
  bulkSelectDef,
  operationDef,
  columnDef,
  dataSource,
  totalRecords,
  totalPages,
  renderChildren,
  operationSticky,
  defaultRowsPerPage,
  defaultPageNumber,
  hideBulkSelectHeader,
  updateSelectedRow,
  onRefresh,
  onChangePage,
  rowOnClicked,
  type,
}: PruTableProps<T>) => {
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, variable: Record<string, string | number>) =>
    intl.formatMessage({ id: key }, variable);

  const colCount = useMemo(() => {
    return (
      columnDef.filter((column) => !column.hidden).length +
      Number(operationDef.length > 0) +
      Number(!!!disableBulkSelect)
    );
  }, [columnDef, operationDef, disableBulkSelect]);

  const idKeyIndex = useMemo(() => {
    const idCol = columnDef.find((column) => column.isId === true);
    return idCol ? idCol.keyIndex : '';
  }, [columnDef]);

  const onUpdateSelectedRow = useEffect(() => {
    if (updateSelectedRow != rowSelected) {
      setRowSelected(updateSelectedRow ?? []);
    }
  }, [updateSelectedRow]);

  // Column Sort Handling
  const [sortState, setSortState] = useState<SortState>({});

  const toggleDirection = (direction: PruTableSortType | undefined) => {
    if (direction === PruTableSortType.ASC) {
      return PruTableSortType.DESC;
    } else if (direction === PruTableSortType.DESC) {
      return undefined;
    } else {
      return PruTableSortType.ASC;
    }
  };

  const handleSort = (column: PruTableColumnDef<T>) => {
    if (column.sortable) {
      const newSortState: SortState = {
        ...sortState,
        [column.keyIndex]: toggleDirection(sortState[column.keyIndex as string]),
      };
      setSortState(newSortState);
      if (column.onSort) {
        column.onSort(newSortState);
      }
    }
  };

  // Bulk Select Handling
  const [rowSelected, setRowSelected] = useState<T[]>([]);
  const [rowOpen, setRowOpen] = useState<string[]>([]);

  const onSelectAllRow = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newSelectedRow: any[] = [];
    if (event.target.checked) {
      newSelectedRow = dataSource || [];
      if (bulkSelectCheckboxDisable) {
        newSelectedRow = newSelectedRow.filter((item) => !bulkSelectCheckboxDisable(item));
      }
    }
    setRowSelected(newSelectedRow);
    if (currentSelectedRow) {
      currentSelectedRow(newSelectedRow);
    }
  };

  const onSelectRow = (row: T) => {
    const foundIndex = rowSelected.findIndex((selectedItem) => selectedItem[idKeyIndex] === row[idKeyIndex]);
    if (foundIndex === -1) {
      setRowSelected([...rowSelected, row]);
      if (currentSelectedRow) {
        currentSelectedRow([...rowSelected, row]);
      }
    } else {
      let newArr = [...rowSelected];
      newArr.splice(foundIndex, 1);
      setRowSelected(newArr);
      if (currentSelectedRow) {
        currentSelectedRow(newArr);
      }
    }
  };

  const onOpenRow = (row: T) => {
    const foundIndex = rowOpen.findIndex((selectedItem) => selectedItem === row.id);
    if (foundIndex === -1) {
      //@ts-ignore
      setRowOpen([...rowOpen, row.id]);
    } else {
      let newArr = [...rowOpen];
      newArr.splice(foundIndex, 1);
      setRowOpen(newArr);
    }
  };

  const _renderOperationRow = (row: any) => {
    return (
      <div className={classes.operationContainer}>
        {operationDef.map((operation, index) => (
          <Fragment key={`operation-${index}`}>
            {((operation.condition !== undefined && operation.condition(row)) || !operation.condition) && (
              <Tooltip title={operation.tooltipText}>
                <div
                  style={{ marginRight: operationDef[index + 1] ? 10 : 0 }}
                  className={`${classes.operationBtn}`}
                  onClick={() => operation.onClick(row)}
                >
                  {operation.title}
                </div>
              </Tooltip>
            )}
          </Fragment>
        ))}
      </div>
    );
  };

  const _renderTableRow = (isRowSelected: any, row: any, index: any, level: number = 0) => {
    const isRowOpened = !!rowOpen.find((selectedItem) => {
      return selectedItem === row.id;
    });
    return (
      <Fragment key={index}>
        <PruTableRow
          className={isRowSelected ? classes.selectedRow : ''}
          onClick={() => {
            if (rowOnClicked) {
              rowOnClicked(row);
            }
          }}
        >
          {!disableBulkSelect && (
            <TableCell padding="checkbox">
              <Checkbox
                onClick={() => onSelectRow(row)}
                checked={isRowSelected}
                disabled={bulkSelectCheckboxDisable ? bulkSelectCheckboxDisable(row) : false}
              />
            </TableCell>
          )}

          {columnDef.map((column, columnIndex) => {
            return (
              !column.hidden &&
              !column.colspan && (
                <TableCell style={column.style} key={`table-row-field-${String(column.keyIndex)}`} align={column.align}>
                  {renderChildren && (
                    <Fragment>
                      {columnIndex === 0 && row.children && row.children.length > 0 && (
                        <span style={{ marginLeft: 18 * (level - 1) }}>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              onOpenRow(row);
                            }}
                          >
                            {!isRowOpened ? <AddBoxOutlined /> : <IndeterminateCheckBoxOutlined />}
                          </IconButton>
                        </span>
                      )}
                      {columnIndex === 0 && (!row.children || row.children.length === 0) && (
                        <span style={{ marginLeft: 18 * level }}></span>
                      )}
                    </Fragment>
                  )}
                  {column.renderData(row) as any}
                </TableCell>
              )
            );
          })}
          {operationDef.length > 0 && !operationSticky && (
            <TableCell align="center">{_renderOperationRow(row)}</TableCell>
          )}

          {operationDef.length > 0 && operationSticky && (
            <StickyTableCell align="center">{_renderOperationRow(row)}</StickyTableCell>
          )}
        </PruTableRow>
        {renderChildren &&
          isRowOpened &&
          row.children &&
          row.children.length > 0 &&
          row.children.map((item: any, childrenIndex: any) =>
            _renderTableRow(isRowSelected, item, childrenIndex, level + 1),
          )}
      </Fragment>
    );
  };

  // Paginate Handling
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePaginateHandler(
    onChangePage,
    totalPages,
    defaultPageNumber ? defaultPageNumber : 0,
    defaultRowsPerPage ? defaultRowsPerPage : undefined,
  );

  return (
    <div className={`${classes.root} table-body`}>
      {!disableToolbar && (
        <ToolBarContainer>
          <div>
            {title && (
              <div className={commonClasses.header}>
                {title}
                <span
                  style={{
                    color: '#888888',
                    fontSize: '12px',
                    marginLeft: '5px',
                  }}
                >
                  {subTitle}
                </span>
              </div>
            )}
          </div>
          <div style={{ justifyContent: 'flex-end', flex: '1', marginRight: 15 }} className={classes.rowContainer}>
            <div>
              {!disableRefresh && (
                <Tooltip title="Refresh">
                  <IconButton onClick={onRefresh}>
                    <Refresh />
                  </IconButton>
                </Tooltip>
              )}
              {headerBtnDef &&
                headerBtnDef.map(
                  (btn) =>
                    (!btn.condition || (btn.condition !== undefined && btn.condition())) && (
                      <Button
                        key={`header-button-${btn.title}`}
                        style={{ marginLeft: 15 }}
                        variant="contained"
                        color="secondary"
                        onClick={btn.onClick}
                      >
                        {btn.title}
                      </Button>
                    ),
                )}
            </div>
          </div>
        </ToolBarContainer>
      )}
      <TableContainer component={Paper} className={!disablePagination && operationSticky ? `table-container` : ''}>
        <Table className={classes.table}>
          <TableHead>
            {!hideBulkSelectHeader && !disableBulkSelect && rowSelected.length > 0 && (
              <TableRow>
                <TableCell padding="none" colSpan={colCount}>
                  <Toolbar className={classes.bulkActions}>
                    <Typography color="inherit" variant="subtitle1" component="div">
                      {TranslationWithVariable('prutable.rowSelected', { num: rowSelected.length })}
                    </Typography>
                    <div>
                      {bulkSelectDef &&
                        bulkSelectDef.length > 0 &&
                        bulkSelectDef.map((action) => (
                          <Button
                            key={`bulk-select-btn-${action.title || ''}`}
                            variant={action.variant}
                            disabled={
                              !((action.condition !== undefined && action.condition(rowSelected)) || !action.condition)
                            }
                            onClick={() => {
                              action.onClick(rowSelected);
                              setRowSelected([]);
                            }}
                          >
                            {action.title}
                          </Button>
                        ))}
                    </div>
                  </Toolbar>
                </TableCell>
              </TableRow>
            )}
            {/* <TableRow>
              {!disableBulkSelect && (
                <PruTableHeader padding="checkbox">
                  <Checkbox
                    disabled={Number(dataSource?.length) <= 0}
                    icon={<CheckBoxOutlineBlankOutlinedIcon style={{ color: "#FFFFFF" }} />}
                    checkedIcon={<IndeterminateCheckBoxIcon style={{ color: "#FFFFFF" }} />}
                    checked={rowSelected.length > 0}
                    onChange={onSelectAllRow}
                    inputProps={{ 'aria-label': 'select all rows', style: { backgroundColor: '#FFF' } }}
                  />
                </PruTableHeader>
              )}
              {columnDef.map(column => !column.hidden && (
                <Fragment key={`table-column-${column.keyIndex}`}>
                  <PruTableHeader
                    align={column.align}
                    style={column.sortable ? { cursor: "pointer", whiteSpace: "nowrap" } : {}}
                    onClick={() => handleSort(column)}
                  >
                    {column.displayName}
                    {column.sortable && sortState[column.keyIndex as string] !== undefined && (
                      <TableSortLabel
                        classes={{
                          icon: classes.headerIcon
                        }}
                        className={classes.sortIcon}
                        active={true}
                        direction={sortState[column.keyIndex as string]}
                      />
                    )}
                  </PruTableHeader>
                </Fragment>
              ))} */}
            {/* {operationDef.length > 0 && <PruTableHeader align="center">{Translation("section.common.operation")}</PruTableHeader>} */}
            {/* {operationDef.length > 0 && !operationSticky && <PruTableHeader align="center">{Translation("section.common.operation")}</PruTableHeader>}
              {operationDef.length > 0 && operationSticky && <StickyTableCell align="center">{Translation("section.common.operation")}</StickyTableCell>}
            </TableRow> */}

            <TableRow>
              {!disableBulkSelect && (
                <PruTableHeader padding="checkbox">
                  <Checkbox
                    disabled={Number(dataSource?.length) <= 0}
                    icon={<CheckBoxOutlineBlankOutlined style={{ color: '#FFFFFF' }} />}
                    checkedIcon={<IndeterminateCheckBox style={{ color: '#FFFFFF' }} />}
                    checked={rowSelected.length > 0}
                    onChange={onSelectAllRow}
                    inputProps={{ 'aria-label': 'select all rows', style: { backgroundColor: '#FFF' } }}
                  />
                </PruTableHeader>
              )}
              {columnDef.map(
                (column) =>
                  !column.hidden &&
                  !column.isCombine && (
                    <Fragment key={`table-column-${String(column.keyIndex)}`}>
                      <PruTableHeader
                        align={column.align}
                        style={column.sortable ? { cursor: 'pointer', whiteSpace: 'nowrap' } : {}}
                        onClick={() => handleSort(column)}
                        colSpan={column.colspan}
                        rowSpan={column.rowSpan}
                      >
                        {column.displayName}
                        {column.sortable && sortState[column.keyIndex as string] !== undefined && (
                          <TableSortLabel
                            classes={{
                              icon: classes.headerIcon,
                            }}
                            className={classes.sortIcon}
                            active={true}
                            direction={sortState[column.keyIndex as string]}
                          />
                        )}
                      </PruTableHeader>
                    </Fragment>
                  ),
              )}

              {operationDef.length > 0 && (
                <PruTableHeader align="center">{Translation('section.common.operation')}</PruTableHeader>
              )}
            </TableRow>

            {isCombineHeader && (
              <TableRow>
                {columnDef.map(
                  (column) =>
                    !column.hidden &&
                    column.isCombine && (
                      <Fragment key={`table-column-${String(column.keyIndex)}`}>
                        <PruTableHeader
                          align={column.align}
                          style={column.sortable ? { cursor: 'pointer', whiteSpace: 'nowrap' } : {}}
                          onClick={() => handleSort(column)}
                        >
                          {column.displayName}
                          {column.sortable && sortState[column.keyIndex as string] !== undefined && (
                            <TableSortLabel
                              classes={{
                                icon: classes.headerIcon,
                              }}
                              className={classes.sortIcon}
                              active={true}
                              direction={sortState[column.keyIndex as string]}
                            />
                          )}
                        </PruTableHeader>
                      </Fragment>
                    ),
                )}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            <PruTableLoading isLoading={isLoading} />
            <PruTableEmptyRow isEmpty={!!(!dataSource || (dataSource && dataSource.length <= 0))} type={type} />
            {dataSource &&
              dataSource.map((row, index) => {
                const isRowSelected = !!rowSelected.find(
                  (selectedItem) => selectedItem[idKeyIndex] === row[idKeyIndex],
                );
                return <Fragment key={index}>{_renderTableRow(isRowSelected, row, index)}</Fragment>;
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {!disablePagination && (
        <TableFooter component="div" className={`table-footer-css`}>
          <TableRow component="div">
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 50]}
              colSpan={colCount}
              count={totalRecords ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={PruTablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      )}
    </div>
  );
};

export default PruTableCustom;
