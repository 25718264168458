import React, { FC, useMemo, useReducer, useState } from 'react';
import { Button, Checkbox, FormControlLabel, FormHelperText, Select, TextField } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils/form-error-utils';
import {
  ACHIEVEMENT_UPLOAD_BASE_PATH,
  AGENT_CODE_EXISTS_ERROR_TEXT,
  AGENT_CODE_NOT_ACTIVE_ERROR_TEXT,
  AGENT_CODE_NOT_FOUND_ERROR_TEXT,
  MANDATORY_FIELD_ERROR_TEXT,
  MDRT_HUB_BASE_PATH,
  YEAR_FIELD_ERROR_TEXT,
} from '../../../../constants';
import {
  Achievement,
  AchievementFormState,
  AchievementUploadDetail,
  AchievementUploadFormMode,
  AchievementUploadItem,
  AgentStatus,
  PublishStatus,
  StatusModel,
} from 'src/app/modules/MdrtHub/types/achievement-upload-types';
import { map } from 'lodash';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import {
  addAchievement,
  getAchievementByAgentCode,
  getAgentStatus,
} from 'src/app/modules/MdrtHub/network/achievementUploadCrud';
import { useDebounce } from 'src/app/common/utils/common-utils';
import { makeStyles } from 'tss-react/mui';

type AchievementDetailFormProps = {
  id?: string;
  formMode: AchievementUploadFormMode;
  achievement?: AchievementUploadItem;
  onReload: () => void;
  onRouteTo: (route: string) => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  fieldContainer: {
    width: 120,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
    fontWeight: 'bold',
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 20,
  },
  flexItem: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '33.333333%',
    marginTop: 15,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  removeBtn: {
    display: 'flex',
    marginLeft: 'auto',
  },
}));

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof AchievementFormState;
    value: any;
  };
};

type AddAchievementAction = {
  type: 'ADD_ACHIEVEMENT';
  payload: {
    field: keyof AchievementFormState;
    value: Achievement;
  };
};

type RempveAchievementAction = {
  type: 'REMOVE_ACHIEVEMENT';
  payload: {
    field: keyof AchievementFormState;
    value: any;
  };
};

type SelectAchievementAction = {
  type: 'SELECT_YEAR_ACHIEVEMENT';
  payload: {
    field: keyof AchievementFormState;
    value: any;
  };
};

type CheckboxAchievementAction = {
  type: 'CHECKBOX_ACHIEVEMENT';
  payload: {
    field: keyof AchievementFormState;
    value: any;
  };
};

const generateKey = () => {
  return `${Math.floor(Math.random() * 1000)}_${new Date().getTime()}`;
};

const initialAchievement = {
  id: generateKey(),
  year: new Date().getFullYear().toString(),
  title: [],
  isDuplicate: false,
};

const initialState: AchievementFormState = {
  id: undefined,
  agentCode: undefined,
  achievements: [initialAchievement],
};

const formReducer = (
  state: AchievementFormState,
  action:
    | ModifyFieldAction
    | AddAchievementAction
    | SelectAchievementAction
    | CheckboxAchievementAction
    | RempveAchievementAction,
): AchievementFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'ADD_ACHIEVEMENT':
      return {
        ...state,
        [action.payload.field]: [...(state.achievements || []), action.payload.value],
      };
    case 'SELECT_YEAR_ACHIEVEMENT':
      const newAchievement = state.achievements?.map((achievement) => {
        if (achievement.id === action.payload.value.id) {
          achievement.year = action.payload.value.year;
        }
        return achievement;
      });

      const lookup = (newAchievement || []).reduce((a: { [key: string]: number }, e) => {
        a[e.year] = ++a[e.year] || 0;
        return a;
      }, {});
      const duplicateYear = [
        ...new Set((newAchievement || []).filter((e) => lookup[e.year] && lookup[e.year] > 0).map(({ year }) => year)),
      ];

      newAchievement?.forEach((achievement) => {
        duplicateYear.includes(achievement.year) ? (achievement.isDuplicate = true) : (achievement.isDuplicate = false);
      });

      return {
        ...state,
        achievements: newAchievement,
      };
    case 'CHECKBOX_ACHIEVEMENT':
      const { id, option, checked } = action.payload.value;
      const checkboxAchievement = state.achievements?.map((achievement) => {
        if (achievement.id === id) {
          if (checked) {
            achievement.title = [...achievement.title, option];
          } else {
            const index = achievement.title.indexOf(option);
            achievement.title.splice(index, 1);
          }
        }

        return achievement;
      });

      return {
        ...state,
        achievements: checkboxAchievement,
      };
    case 'REMOVE_ACHIEVEMENT':
      const { achievementId } = action.payload.value;

      return {
        ...state,
        achievements: state.achievements?.filter((achievement) => achievement.id !== achievementId),
      };
    default:
      return state;
  }
};

const detailToStateConvertor = (detail: any): AchievementFormState => {
  return {
    id: detail.id,
    agentCode: detail.agentCode,
    achievements: detail.achievements.map((achievement: Achievement) => ({
      ...achievement,
      id: generateKey(),
    })),
  };
};

const AchievementYear = () => {
  const currentYear = new Date().getFullYear();
  const minYear = 2000;
  const yearSelectList = [];
  for (let i = currentYear + 1; i >= minYear; i--) {
    yearSelectList.push(i.toString());
  }

  return yearSelectList;
};

const AchievementTitleGroup = [
  'MDRT',
  'COT',
  'TOT',
  'Star Club',
  'President Club',
  'Prudential Queen',
  'Prudential King',
];

const AchievementDetailForm: FC<AchievementDetailFormProps> = ({ id, formMode, achievement, onReload, onRouteTo }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const yearSelectList = AchievementYear();
  const [isLoading, setIsLoading] = useState(false);
  const [formState, formDispatch] = useReducer(
    formReducer,
    achievement
      ? detailToStateConvertor({
          achievements: achievement.achievements,
          agentCode: achievement.agentCode,
          id,
        })
      : JSON.parse(JSON.stringify(initialState)),
  );
  const isDisabled = useMemo(() => {
    return formMode === AchievementUploadFormMode.CREATE || formMode === AchievementUploadFormMode.EDIT ? false : true;
  }, [formMode]);
  const [agentStatus, setAgentStatus] = useState<AgentStatus>();
  const [existsAgent, setExistsAgent] = useState<Boolean>(false);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'agentCode',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'year',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        const years = formState.achievements?.map(({ year }) => year) || [];
        if (years.some((year) => year === '')) return true;

        return new Set(years).size !== years.length;
      },
    },
    {
      name: 'title',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        return formState.achievements?.some(({ title }) => title.length === 0) || false;
      },
    },
  ]);

  const onSubmitForm = async (status: PublishStatus) => {
    setIsLoading(true);
    let { hasError } = onSubmitErrorValidator();

    if (
      (agentStatus && (agentStatus.status === StatusModel.NotFound || agentStatus.status !== StatusModel.Active)) ||
      existsAgent
    ) {
      return;
    }

    if (!hasError) {
      const achievementDetail: AchievementUploadDetail = {
        id: formState.id || id || '',
        agentCode: formState.agentCode || '',
        achievements: (formState.achievements || []).map(({ title, year }) => ({ title, year })),
        status,
      };
      if (!achievementDetail.id) delete achievementDetail.id;

      try {
        await addAchievement(achievementDetail, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Updated successfully - ${achievementDetail.agentCode}`,
            },
          ]),
        );

        onRouteTo(`${MDRT_HUB_BASE_PATH}${ACHIEVEMENT_UPLOAD_BASE_PATH}`);
      } catch (err) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: 'Error',
              content: `Saved error - ${JSON.stringify(err)}`,
            },
          ]),
        );
      }
    }
    setTimeout(() => setIsLoading(false), 2000);
  };

  const addMore = () => {
    formDispatch({
      type: 'ADD_ACHIEVEMENT',
      payload: {
        field: 'achievements',
        value: {
          id: generateKey(),
          year: '',
          title: [],
          isDuplicate: false,
        },
      },
    });
  };

  const remove = (achievementId: string | undefined) => {
    if (!achievementId) return;

    formDispatch({
      type: 'REMOVE_ACHIEVEMENT',
      payload: {
        field: 'achievements',
        value: {
          achievementId,
        },
      },
    });
  };

  const checkAgentStatus = async (agentCode: string) => {
    setAgentStatus(undefined);
    if (!agentCode) return;
    try {
      const res = await getAgentStatus(agentCode, dispatch);
      if (res && res.agents && res.agents[0]) {
        const agentStatus = res.agents[0];
        setAgentStatus(agentStatus);
      } else {
        setAgentStatus({
          agentCode,
          status: StatusModel.NotFound,
        });
      }
    } catch (error) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'ERROR',
            content: `Get Agent Status ERROR - ${error}`,
          },
        ]),
      );
      setAgentStatus({
        agentCode,
        status: StatusModel.NotFound,
      });
    }
  };

  const checkExistsAgent = async (agentCode: string) => {
    setExistsAgent(false);
    if (!agentCode) return;
    try {
      const res = await getAchievementByAgentCode(agentCode, dispatch);
      if (res) {
        setExistsAgent(true);
      } else {
        setExistsAgent(false);
      }
    } catch (error) {}
  };

  const debounceCheckAgentStatus = useDebounce((agentCode: string) => {
    checkAgentStatus(agentCode);
    checkExistsAgent(agentCode);
  }, 1000);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>
              {Translation(
                formMode === AchievementUploadFormMode.CREATE
                  ? 'mdrtHub.achievementUpload.add'
                  : 'mdrtHub.achievementUpload.edit',
              )}
            </div>
          </div>
          <Button variant="contained" onClick={() => onRouteTo(`${MDRT_HUB_BASE_PATH}${ACHIEVEMENT_UPLOAD_BASE_PATH}`)}>
            Back
          </Button>
        </div>

        <div className={classes.sectionMargin}>
          <div className={classes.flexItem}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>Agent Code:</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              {
                <TextField
                  style={{ width: '100%' }}
                  error={
                    errorState.mandatory.agentCode ||
                    (agentStatus && agentStatus.status !== StatusModel.Active) ||
                    (agentStatus && agentStatus.status === StatusModel.NotFound) ||
                    !!existsAgent
                  }
                  margin="dense"
                  variant="outlined"
                  helperText={
                    (errorState.mandatory.agentCode && MANDATORY_FIELD_ERROR_TEXT) ||
                    (agentStatus && agentStatus.status === StatusModel.NotFound && AGENT_CODE_NOT_FOUND_ERROR_TEXT) ||
                    (agentStatus && agentStatus.status !== StatusModel.Active && AGENT_CODE_NOT_ACTIVE_ERROR_TEXT) ||
                    (existsAgent && AGENT_CODE_EXISTS_ERROR_TEXT)
                  }
                  value={formState.agentCode}
                  disabled={isDisabled}
                  onChange={(e) => {
                    onDismissErrorHandler('name', e.target.value);
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'agentCode', value: e.target.value } });
                    debounceCheckAgentStatus(e.target.value);
                  }}
                  inputProps={{ maxLength: 100 }}
                />
              }
            </div>
          </div>

          <hr />

          {formState.achievements?.map((achieve) => {
            return (
              <div key={achieve.id}>
                <div className={classes.flexItem}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}>Year:</span>
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <Select
                      disabled={isDisabled}
                      style={{ width: '100%' }}
                      native
                      margin="dense"
                      variant="outlined"
                      value={achieve.year}
                      error={achieve.isDuplicate || (errorState.mandatory.year && achieve.year === '')}
                      onChange={(e) => {
                        const year = {
                          id: achieve.id,
                          year: e.target.value,
                        };
                        onDismissErrorHandler('year', year);
                        formDispatch({
                          type: 'SELECT_YEAR_ACHIEVEMENT',
                          payload: { field: 'achievements', value: year },
                        });
                      }}
                    >
                      <option aria-label="None" value="" />
                      {map(yearSelectList, (option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                    {achieve.isDuplicate && (
                      <FormHelperText className={classes.errorText}>{YEAR_FIELD_ERROR_TEXT}</FormHelperText>
                    )}
                    {errorState.mandatory.year && achieve.year === '' && (
                      <FormHelperText className={classes.errorText}>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                    )}
                  </div>
                </div>

                <div className={classes.flexItem}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}>Title:</span>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    {map(AchievementTitleGroup, (option) => (
                      <FormControlLabel
                        key={`achievement-title-group-${option}`}
                        style={{ flexBasis: '33.3333%', margin: '5px 0' }}
                        control={
                          <Checkbox
                            checked={achieve.title.includes(option)}
                            disabled={isDisabled}
                            onChange={(e) => {
                              const checkbox = {
                                id: achieve.id,
                                option,
                                checked: e.target.checked,
                              };
                              formDispatch({
                                type: 'CHECKBOX_ACHIEVEMENT',
                                payload: { field: 'achievements', value: checkbox },
                              });
                            }}
                          />
                        }
                        label={option}
                        labelPlacement="end"
                      />
                    ))}
                    {errorState.mandatory.title && achieve.title.length === 0 && (
                      <FormHelperText style={{ flexBasis: '100%' }} className={classes.errorText}>
                        {MANDATORY_FIELD_ERROR_TEXT}
                      </FormHelperText>
                    )}
                  </div>
                </div>
                {formState.achievements && formState.achievements.length > 1 && !isDisabled ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.removeBtn}
                    onClick={() => remove(achieve.id)}
                  >
                    Remove
                  </Button>
                ) : null}
                <hr />
              </div>
            );
          })}

          {!isDisabled ? (
            <Button variant="contained" color="secondary" onClick={() => addMore()}>
              Add More
            </Button>
          ) : (
            <></>
          )}
        </div>

        <div className={classes.footerContainer}>
          {isDisabled ? (
            <></>
          ) : (
            <>
              <Button
                variant="contained"
                color="secondary"
                disabled={isLoading}
                onClick={() => onSubmitForm(PublishStatus.UNPUBLISHED)}
              >
                Save as Draft
              </Button>
              <Button
                style={{ marginLeft: 25 }}
                variant="contained"
                disabled={isLoading}
                color="secondary"
                onClick={() => onSubmitForm(PublishStatus.PUBLISHED)}
              >
                Publish
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AchievementDetailForm;
