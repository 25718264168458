import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  // COE
  AdminEventsPermissionType,
  EVENT_BASE_PATH,
  EVENT_TITLE,
  EVENT_CATEGORY_TITLE,
  EVENT_LIST_TITLE,
  EVENT_CATEGORY_PATH,
  EVENT_LIST_PATH,
  EVENT_CATEGORIES_NAMESPACE,
  EVENT_LIST_NAMESPACE,

  // PVA
  EBIRTHDAY_CARD_TITLE,
  EBIRTHDAY_CARD_TEMPLATE_LIST_TITLE,
  EBIRTHDAY_CARD_CONTENT_LIST_TITLE,
  EBIRTHDAY_CARD_TITLE_LIST_TITLE,
  EBIRTHDAY_CARD_PATH,
  EBIRTHDAY_CARD_TEMPLATE_LIST_PATH,
  EBIRTHDAY_CARD_CONTENT_LIST_PATH,
  EBIRTHDAY_CARD_TITLE_LIST_PATH,
  EBIRTHDAY_CARD_TEMPLATE_NAMESPACE,
  EBIRTHDAY_CARD_CONTENT_NAMESPACE,
  EBIRTHDAY_CARD_TITLE_NAMESPACE,
  EVENT_INVITATION_LETTER_TITLE,
  EVENT_INVITATION_LETTER_PATH,
  EVENT_INVITATION_LETTER_NAMESPACE,
  GREETING_CARD_PATH,
  GREETING_CARD_TITLE,
  GREETING_CARD_TEMPLATE_LIST_TITLE,
  GREETING_CARD_CONTENT_LIST_TITLE,
  GREETING_CARD_TITLE_LIST_TITLE,
  GREETING_CARD_TEMPLATE_LIST_PATH,
  GREETING_CARD_CONTENT_LIST_PATH,
  GREETING_CARD_TITLE_LIST_PATH,
  GREETING_CARD_TEMPLATE_NAMESPACE,
  GREETING_CARD_CONTENT_NAMESPACE,
  GREETING_CARD_TITLE_NAMESPACE,
} from './constants';

// COE
import { isPermitted } from 'src/app/common/utils';
import EventCategoryPage from './pages/Category/EventCategoryPage';
import EventArticlePage from './pages/EventList/EventListPage';

// PVA
import TemplatePage from './pages/EBirthdayCard/Template/TemplatePage';
import TitlePage from './pages/EBirthdayCard/Title/TitlePage';
import ContentPage from './pages/EBirthdayCard/Content/ContentPage';
import EventInvitionLetter from './pages/InvitationLetter';
import TemplateGreetingPage from './pages/GreetingCard/Template/TemplatePage';
import TitleGreetingPage from './pages/GreetingCard/Title/TitlePage';
import ContentGreetingPage from './pages/GreetingCard/Content/ContentPage';

export const eventsModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(EVENT_TITLE),
    path: EVENT_BASE_PATH,
    icon: '/media/svg/icons/Home/Book-open.svg',
    enableRead:  isPermitted(Object.values(AdminEventsPermissionType), permissions),
    child: [
      {
        title: Translation(EBIRTHDAY_CARD_TITLE),
        path: `${EVENT_BASE_PATH}${EBIRTHDAY_CARD_PATH}`,
        enableRead: isPermitted(
          [
            AdminEventsPermissionType.EBIRTHDAY_CARD_TEMPLATE_LIST_READ,
            AdminEventsPermissionType.EBIRTHDAY_CARD_TEMPLATE_LIST_CREATE,
            AdminEventsPermissionType.EBIRTHDAY_CARD_TEMPLATE_LIST_UPDATE,
            AdminEventsPermissionType.EBIRTHDAY_CARD_CONTENT_LIST_READ,
            AdminEventsPermissionType.EBIRTHDAY_CARD_CONTENT_LIST_CREATE,
            AdminEventsPermissionType.EBIRTHDAY_CARD_CONTENT_LIST_UPDATE,
            AdminEventsPermissionType.EBIRTHDAY_CARD_TITLE_LIST_READ,
            AdminEventsPermissionType.EBIRTHDAY_CARD_TITLE_LIST_CREATE,
            AdminEventsPermissionType.EBIRTHDAY_CARD_TITLE_LIST_UPDATE,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(EBIRTHDAY_CARD_TEMPLATE_LIST_TITLE),
            path: `${EVENT_BASE_PATH}${EBIRTHDAY_CARD_PATH}${EBIRTHDAY_CARD_TEMPLATE_LIST_PATH}`,
            namespace: EBIRTHDAY_CARD_TEMPLATE_NAMESPACE,
            component: TemplatePage,
            enableRead: isPermitted(
              [
                AdminEventsPermissionType.EBIRTHDAY_CARD_TEMPLATE_LIST_READ,
                AdminEventsPermissionType.EBIRTHDAY_CARD_TEMPLATE_LIST_CREATE,
                AdminEventsPermissionType.EBIRTHDAY_CARD_TEMPLATE_LIST_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminEventsPermissionType.EBIRTHDAY_CARD_TEMPLATE_LIST_CREATE], permissions),
            enableUpdate: isPermitted([AdminEventsPermissionType.EBIRTHDAY_CARD_TEMPLATE_LIST_UPDATE], permissions),
          },
          {
            title: Translation(EBIRTHDAY_CARD_CONTENT_LIST_TITLE),
            path: `${EVENT_BASE_PATH}${EBIRTHDAY_CARD_PATH}${EBIRTHDAY_CARD_CONTENT_LIST_PATH}`,
            namespace: EBIRTHDAY_CARD_CONTENT_NAMESPACE,
            component: ContentPage,
            enableRead: isPermitted(
              [
                AdminEventsPermissionType.EBIRTHDAY_CARD_CONTENT_LIST_READ,
                AdminEventsPermissionType.EBIRTHDAY_CARD_CONTENT_LIST_CREATE,
                AdminEventsPermissionType.EBIRTHDAY_CARD_CONTENT_LIST_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminEventsPermissionType.EBIRTHDAY_CARD_CONTENT_LIST_CREATE], permissions),
            enableUpdate: isPermitted([AdminEventsPermissionType.EBIRTHDAY_CARD_CONTENT_LIST_UPDATE], permissions),
          },
          {
            title: Translation(EBIRTHDAY_CARD_TITLE_LIST_TITLE),
            path: `${EVENT_BASE_PATH}${EBIRTHDAY_CARD_PATH}${EBIRTHDAY_CARD_TITLE_LIST_PATH}`,
            namespace: EBIRTHDAY_CARD_TITLE_NAMESPACE,
            component: TitlePage,
            enableRead: isPermitted(
              [
                AdminEventsPermissionType.EBIRTHDAY_CARD_TITLE_LIST_READ,
                AdminEventsPermissionType.EBIRTHDAY_CARD_TITLE_LIST_CREATE,
                AdminEventsPermissionType.EBIRTHDAY_CARD_TITLE_LIST_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminEventsPermissionType.EBIRTHDAY_CARD_TITLE_LIST_CREATE], permissions),
            enableUpdate: isPermitted([AdminEventsPermissionType.EBIRTHDAY_CARD_TITLE_LIST_UPDATE], permissions),
          },
        ],
      },
      {
        title: Translation(GREETING_CARD_TITLE),
        path: `${EVENT_BASE_PATH}${GREETING_CARD_PATH}`,
        enableRead: isPermitted(
          [
            AdminEventsPermissionType.GREETING_CARD_TEMPLATE_LIST_READ,
            AdminEventsPermissionType.GREETING_CARD_TEMPLATE_LIST_CREATE,
            AdminEventsPermissionType.GREETING_CARD_TEMPLATE_LIST_UPDATE,
            AdminEventsPermissionType.GREETING_CARD_CONTENT_LIST_READ,
            AdminEventsPermissionType.GREETING_CARD_CONTENT_LIST_CREATE,
            AdminEventsPermissionType.GREETING_CARD_CONTENT_LIST_UPDATE,
            AdminEventsPermissionType.GREETING_CARD_TITLE_LIST_READ,
            AdminEventsPermissionType.GREETING_CARD_TITLE_LIST_CREATE,
            AdminEventsPermissionType.GREETING_CARD_TITLE_LIST_UPDATE,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(GREETING_CARD_TEMPLATE_LIST_TITLE),
            path: `${EVENT_BASE_PATH}${GREETING_CARD_PATH}${GREETING_CARD_TEMPLATE_LIST_PATH}`,
            namespace: GREETING_CARD_TEMPLATE_NAMESPACE,
            component: TemplateGreetingPage,
            enableRead: isPermitted(
              [
                AdminEventsPermissionType.GREETING_CARD_TEMPLATE_LIST_READ,
                AdminEventsPermissionType.GREETING_CARD_TEMPLATE_LIST_CREATE,
                AdminEventsPermissionType.GREETING_CARD_TEMPLATE_LIST_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminEventsPermissionType.GREETING_CARD_TEMPLATE_LIST_CREATE], permissions),
            enableUpdate: isPermitted([AdminEventsPermissionType.GREETING_CARD_TEMPLATE_LIST_UPDATE], permissions),
          },
          {
            title: Translation(GREETING_CARD_CONTENT_LIST_TITLE),
            path: `${EVENT_BASE_PATH}${GREETING_CARD_PATH}${GREETING_CARD_CONTENT_LIST_PATH}`,
            namespace: GREETING_CARD_CONTENT_NAMESPACE,
            component: ContentGreetingPage,
            enableRead: isPermitted(
              [
                AdminEventsPermissionType.GREETING_CARD_CONTENT_LIST_READ,
                AdminEventsPermissionType.GREETING_CARD_CONTENT_LIST_CREATE,
                AdminEventsPermissionType.GREETING_CARD_CONTENT_LIST_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminEventsPermissionType.GREETING_CARD_CONTENT_LIST_CREATE], permissions),
            enableUpdate: isPermitted([AdminEventsPermissionType.GREETING_CARD_CONTENT_LIST_UPDATE], permissions),
          },
          {
            title: Translation(GREETING_CARD_TITLE_LIST_TITLE),
            path: `${EVENT_BASE_PATH}${GREETING_CARD_PATH}${GREETING_CARD_TITLE_LIST_PATH}`,
            namespace: GREETING_CARD_TITLE_NAMESPACE,
            component: TitleGreetingPage,
            enableRead: isPermitted(
              [
                AdminEventsPermissionType.GREETING_CARD_TITLE_LIST_READ,
                AdminEventsPermissionType.GREETING_CARD_TITLE_LIST_CREATE,
                AdminEventsPermissionType.GREETING_CARD_TITLE_LIST_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminEventsPermissionType.GREETING_CARD_TITLE_LIST_CREATE], permissions),
            enableUpdate: isPermitted([AdminEventsPermissionType.GREETING_CARD_TITLE_LIST_UPDATE], permissions),
          },
        ],
      },
      // Comment event v1 to use v2
      // {
      //   title: Translation(EVENT_CATEGORY_TITLE),
      //   path: `${EVENT_BASE_PATH}${EVENT_CATEGORY_PATH}`,
      //   namespace: EVENT_CATEGORIES_NAMESPACE,
      //   component: EventCategoryPage,
      //   enableRead: isPermitted(
      //     [
      //       AdminEventsPermissionType.EVENT_CATEGORY_READ,
      //       AdminEventsPermissionType.EVENT_CATEGORY_CREATE,
      //       AdminEventsPermissionType.EVENT_CATEGORY_UPDATE,
      //     ],
      //     permissions,
      //   ),
      //   enableCreate: isPermitted([AdminEventsPermissionType.EVENT_CATEGORY_CREATE], permissions),
      //   enableUpdate: isPermitted([AdminEventsPermissionType.EVENT_CATEGORY_UPDATE], permissions),
      // },
      // {
      //   title: Translation(EVENT_LIST_TITLE),
      //   path: `${EVENT_BASE_PATH}${EVENT_LIST_PATH}`,
      //   namespace: EVENT_LIST_NAMESPACE,
      //   component: EventArticlePage,
      //   enableRead: isPermitted(
      //     [
      //       AdminEventsPermissionType.EVENT_LIST_READ,
      //       AdminEventsPermissionType.EVENT_LIST_CREATE,
      //       AdminEventsPermissionType.EVENT_LIST_UPDATE,
      //     ],
      //     permissions,
      //   ),
      //   enableCreate: isPermitted([AdminEventsPermissionType.EVENT_LIST_CREATE], permissions),
      //   enableUpdate: isPermitted([AdminEventsPermissionType.EVENT_LIST_UPDATE], permissions),
      // },
      {
        title: Translation(EVENT_INVITATION_LETTER_TITLE),
        path: `${EVENT_BASE_PATH}${EVENT_INVITATION_LETTER_PATH}`,
        namespace: EVENT_INVITATION_LETTER_NAMESPACE,
        component: EventInvitionLetter,
        enableRead: isPermitted(
          [
            AdminEventsPermissionType.EVENT_INVITATION_READ,
            AdminEventsPermissionType.EVENT_INVITATION_CREATE,
            AdminEventsPermissionType.EVENT_INVITATION_UPDATE,
          ],
          permissions,
        ),
        enableCreate: isPermitted([AdminEventsPermissionType.EVENT_INVITATION_CREATE], permissions),
        enableUpdate: isPermitted([AdminEventsPermissionType.EVENT_INVITATION_UPDATE], permissions),
      },
    ],
  };
};
