import { forEach } from 'lodash';
import { Dispatch } from 'react';
import { PaginateList } from '../../../common/types/common-types';
import { getDayStart, getDayEnd } from 'src/app/common/utils/time-utils';
import { MdrtDetail, MdrtItem } from '../types/mdrt-types';
import { apiClient, apiErrorHandler } from 'src/app/common/network';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;
// const ADMIN_URL = `http://localhost:1111`;

export type MdrtListParams = {
  name: string;
  status: string;
  createdDateFrom: Date | null;
  createdDateTo: Date | null;
  page: number;
  limit: number;
};

export const createNewMdrt = async (data: MdrtDetail, dispatch?: Dispatch<any>): Promise<MdrtDetail> => {
  return apiClient
    .post<MdrtDetail>(`${ADMIN_URL}/mdrt/create`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const updateMdrt = async (data: MdrtDetail, dispatch?: Dispatch<any>): Promise<MdrtDetail> => {
  return apiClient
    .put<MdrtDetail>(`${ADMIN_URL}/mdrt/update`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchMdrtItem = async (id: string, dispatch?: Dispatch<any>): Promise<MdrtDetail> => {
  return apiClient
    .get<MdrtDetail>(`${ADMIN_URL}/mdrt/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchMdrtList = async (
  params: MdrtListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<MdrtItem>> => {
  let queryPath = `${ADMIN_URL}/mdrt/list?`;

  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.includes('From')) {
          queryPath += `${key}=${getDayStart(param).toISOString()}&`;
        }
        if (key.includes('To')) {
          queryPath += `${key}=${getDayEnd(param).toISOString()}&`;
        }
      }
    } else {
      queryPath += param ? `${key}=${param}&` : '';
    }
  });

  return apiClient
    .get<PaginateList<MdrtItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const publishMdrt = async (id: string, dispatch?: Dispatch<any>): Promise<MdrtDetail> => {
  return apiClient
    .get<MdrtDetail>(`${ADMIN_URL}/mdrt/publish/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const unpublishMdrt = async (id: string, dispatch?: Dispatch<any>): Promise<MdrtDetail> => {
  return apiClient
    .get<MdrtDetail>(`${ADMIN_URL}/mdrt/unpublish/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
