export const CUSTOMER_CAMPAIGN_BASE_PATH = '/customer-campaign';
export const CUSTOMER_CAMPAIGN_MANAGEMENT_PATH = '/management';
export const CUSTOMER_CAMPAIGN_NAMESPACE = 'CUSTOMER_CAMPAIGN';

export const DATE_ERROR_TEXT = 'End date must be after Begin date';

export const MANDATORY_FIELD_ERROR_TEXT = 'This field is mandatory';
export const CHOOSE_ONE_ERROR_TEXT = 'One of the fields must be filled';

export const DATE_FORMAT_ERROR_TEXT = 'Invalid date format';

//  These constants map to aside menu, header menu to display title
export const CUSTOMER_CAMPAIGN_TITLE = 'title.customer-campaign';

export enum AdminCustomerCampaignPermissionType {
  CUSTOMER_CAMPAIGN_READ = 'READ_ANY_CUSTOMER_CAMPAIGN',
  CUSTOMER_CAMPAIGN_CREATE = 'CREATE_ANY_CUSTOMER_CAMPAIGN',
  CUSTOMER_CAMPAIGN_UPDATE = 'UPDATE_ANY_CUSTOMER_CAMPAIGN',
}

export const supportLangList = ['vi', 'en'];

export const mainLang = 'vi';
export const subLang = 'en';
