import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { getDisplayDate } from '../../../../../../common/utils/time-utils';
import { PaginateList } from '../../../../../../common/types/common-types';
import { useIntl } from 'react-intl';
import PruTable from '../../../../../../common/components/PruTable/PruTable';
import { useHistory } from 'react-router-dom';
import {
  MdrtVideoLibraryFormMode,
  MdrtVideoLibraryItem,
  PublishStatus,
} from '../../../../types/mdrt-video-library-types';
import { deleteItem, publish, unpublish } from '../../../../network/mdrtVideoLibraryCrud';
import { MDRT_HUB_BASE_PATH, MDRT_VIDEO_LIBRARY_BASE_PATH } from 'src/app/modules/MdrtHub/constants';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type MdrtVideoLibraryListProps = {
  isLoading: boolean;
  itemList?: PaginateList<MdrtVideoLibraryItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

let idDelete: string | null = null;

const MdrtVideoLibraryList: FC<MdrtVideoLibraryListProps> = ({ isLoading, itemList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  // confirm delete modal
  const [statusModal, setStatusModal] = useState(false);
  const handleModal = (status: boolean) => {
    setStatusModal(status);
  };

  const onDelete = async () => {
    if (!idDelete) {
      return true;
    }

    try {
      await deleteItem(idDelete, dispatch);
      handleModal(false);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Deleted successfully`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  const onPublish = async (item: MdrtVideoLibraryItem) => {
    try {
      await publish(item._id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Published successfully - ${item.title}`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  const onUnpublish = async (item: MdrtVideoLibraryItem) => {
    try {
      await unpublish(item._id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Unpublished successfully - ${item.title}`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  return (
    <>
      <PruTable
        title={Translation('mdrtHub.mdrtVideoLibrary.listing')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('app.button.add'),
            onClick: () =>
              history.push(`${MDRT_HUB_BASE_PATH}${MDRT_VIDEO_LIBRARY_BASE_PATH}/create`, {
                type: MdrtVideoLibraryFormMode.CREATE,
              }),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('app.button.view'),
            tooltipText: 'View Video',
            onClick: (row) =>
              history.push(`${MDRT_HUB_BASE_PATH}${MDRT_VIDEO_LIBRARY_BASE_PATH}/view/${row._id}`, {
                type: MdrtVideoLibraryFormMode.VIEW,
              }),
            condition: () => true,
          },
          {
            title: Translation('app.button.edit'),
            tooltipText: 'Edit Video',
            onClick: (row) =>
              history.push(`${MDRT_HUB_BASE_PATH}${MDRT_VIDEO_LIBRARY_BASE_PATH}/edit/${row._id}`, {
                type: MdrtVideoLibraryFormMode.EDIT,
              }),
            condition: (row) => row.publishStatus !== PublishStatus.PUBLISHED,
          },
          {
            title: Translation('app.button.publish'),
            tooltipText: 'Publish',
            onClick: (row) => onPublish(row),
            condition: (row) => row.publishStatus !== PublishStatus.PUBLISHED,
          },
          {
            title: Translation('app.button.unpublish'),
            tooltipText: 'Unpublish',
            onClick: (row) => onUnpublish(row),
            condition: (row) => row.publishStatus === PublishStatus.PUBLISHED,
          },
          {
            title: Translation('app.button.delete'),
            tooltipText: 'Delete',
            onClick: (row) => {
              idDelete = row._id;
              handleModal(true);
            },
            condition: () => true,
          },
        ]}
        columnDef={[
          {
            keyIndex: 'title',
            displayName: Translation('mdrtHub.mdrtVideoLibrary.list.title'),
            renderData: (row) => row.title || '',
          },
          {
            keyIndex: 'lastUpdatedDate',
            align: 'center',
            displayName: Translation('mdrtHub.mdrtVideoLibrary.list.lastUpdatedDate'),
            renderData: (row) => (row.updatedAt ? getDisplayDate(new Date(row.updatedAt), 'YYYY-MM-DD HH:mm') : ''),
          },
          {
            keyIndex: 'lastUpdatedBy',
            align: 'center',
            displayName: Translation('mdrtHub.mdrtVideoLibrary.list.lastUpdatedBy'),
            renderData: (row) => row.updatedBy || '',
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={itemList?.items}
        totalPages={itemList?.totalPages}
        totalRecords={itemList?.total}
        onChangePage={onChangePage}
      />

      {/* Confirm Delete Modal */}
      <Dialog
        open={statusModal}
        onClose={() => handleModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Warning'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ color: 'red' }}>
            {Translation('app.confirm.text.delete')}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ marginBottom: 10 }}>
          <Button style={{ marginRight: 12 }} variant="contained" onClick={() => handleModal(false)}>
            Close
          </Button>
          <Button style={{ marginRight: 10 }} variant="contained" color="secondary" onClick={() => onDelete()}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Confirm Delete Modal */}
    </>
  );
};

export default MdrtVideoLibraryList;
