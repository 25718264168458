import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { PruForm } from 'src/app/common/components/PruForm';
import filterConfig from './filterConfig';
import tableHeader from './tableHeader';
import { styles } from './style';
import { regionLocale } from 'src/app/i18n';
import tableConfig from './tableConfig';
import ProTableCell from 'src/app/common/components/ProTable/ProTableCell';
import StickyTableCell from 'src/app/common/components/ProTable/StickyTableCell';
import PruTableLoading from 'src/app/common/components/Table/PruTableLoading';
import PruTableEmptyRow from 'src/app/common/components/Table/PruTableEmptyRow';
import PruTablePaginationActions from 'src/app/common/components/Table/PruTablePaginationActions';
import { fetchEventReportDownload, getReportEventInvitation } from './api';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

type DeleteDialogState = {
  open: boolean;
  id: string;
};

const ReportEvent: FC<any> = (props) => {
  const intl = useIntl();
  const { classes } = styles();
  const Translation = (id: string) => intl.formatMessage({ id });
  const filterInitData = {
    duration: [],
  };
  const dispatch = useDispatch();
  const [listData, setListData] = useState<{ [key: string]: any }>({});
  const [filterData, setFilterData] = useState<{ [key: string]: any }>(filterInitData);
  const [filterSavedData, setFilterSavedData] = useState<{
    [key: string]: any;
  }>(filterInitData);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<{ [key: string]: any }[]>([]);
  const [pageData, setPageData] = useState<{ [key: string]: any }>({
    pageNum: 1,
    pageSize: 10,
  });

  const keyPressSearch = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  const onChangePage = (pageNum: number) => {
    const newPageData = {
      ...pageData,
      pageNum,
    };
    setPageData(newPageData);
    fetchData({ ...filterSavedData, ...newPageData });
  };

  const onSearch = () => {
    // setFilterData(filterData)
    const newPageData = {
      ...pageData,
      pageNum: 1,
    };
    setPageData(newPageData);
    setFilterSavedData(filterData);
    fetchData({ ...filterData, ...newPageData });
  };

  const onChangePageRows = (rows: any) => {
    const newPageData = {
      ...pageData,
      pageNum: 1,
      pageSize: rows,
    };

    setPageData(newPageData);
    fetchData({ ...filterSavedData, ...newPageData });
  };

  const refreshData = async () => {
    const newPageData = {
      ...pageData,
      pageNum: 1,
    };
    setPageData(newPageData);
    fetchData({ ...filterSavedData, ...newPageData });
  };

  const onReset = () => {
    const newPageData = {
      pageSize: pageData?.pageSize,
      pageNum: 1,
    };
    fetchData({
      ...{
        eventName: '',
        duration: [],
        location: '',
      },
      ...newPageData,
    });
    setPageData(newPageData);
    setFilterData(filterInitData);
    setFilterSavedData(filterInitData);
  };

  useEffect(() => {
    const fetchAPI = async () => {
      setFormLoading(true);
      await fetchData();
      setFormLoading(false);
    };
    fetchAPI();

    return;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener('keypress', keyPressSearch);
    return () => {
      window.removeEventListener('keypress', keyPressSearch);
    };
    // eslint-disable-next-line
  }, [filterData]);

  const fetchCsvtDownload = () => {
    try {
      const requestParams = {
        ...filterSavedData,
      };

      if (requestParams.duration?.[0]) {
        requestParams.durationDateFrom = requestParams.duration?.[0]
          ? moment(requestParams.duration?.[0]).utc().startOf('day').toDate()
          : '';
      }

      if (requestParams.duration?.[1]) {
        requestParams.durationDateTo = requestParams.duration?.[1]
          ? moment(requestParams.duration?.[1]).utc().endOf('day').toDate()
          : '';
      }

      delete requestParams.duration;

      fetchEventReportDownload(requestParams, dispatch);
    } catch (err) {}
  };

  const fetchData = (newFilterState?: any) => {
    setFormLoading(true);
    const newFilter = newFilterState || {};
    const requestParams = {
      ...filterData,
      ...pageData,
      ...newFilter,
    };

    if (requestParams.duration?.[0]) {
      requestParams.durationDateFrom = requestParams.duration?.[0]
        ? moment(requestParams.duration?.[0]).utc().startOf('day').toDate()
        : '';
    }

    if (requestParams.duration?.[1]) {
      requestParams.durationDateTo = requestParams.duration?.[1]
        ? moment(requestParams.duration?.[1]).utc().endOf('day').toDate()
        : '';
    }

    delete requestParams.duration;

    getReportEventInvitation(requestParams)
      .then((res: any) => {
        setTableData(res?.data?.items || []);
        setPageData({
          pageNum: res?.data?.offset || 1,
          pageSize: res?.data?.limit || 0,
          total: res?.data?.total || 0,
        });
        setFormLoading(false);
      })
      .catch((err: any) => {
        console.log(err.message);
        setFormLoading(false);
      });
  };

  return (
    <>
      <PruForm
        config={filterConfig(Translation, onSearch, onReset, listData)}
        data={filterData}
        onChange={setFilterData}
        space={20}
        style={{ padding: 20 }}
      ></PruForm>

      <PruForm
        config={tableHeader(Translation, fetchCsvtDownload, refreshData)}
        data={[]}
        onChange={() => {}}
        space={20}
        style={{
          padding: '15px 20px',
          marginTop: 20,
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
        }}
      ></PruForm>

      <TableContainer component={Paper} className="table-container">
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableConfig(Translation, regionLocale).map((item) => (
                <ProTableCell key={item.prop} dataIndex={item.prop}>
                  {item.title}
                </ProTableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            <PruTableLoading isLoading={formLoading} />
            <PruTableEmptyRow isEmpty={tableData.length === 0} />
            {tableData.length > 0 &&
              tableData.map((row) => (
                <TableRow>
                  {tableConfig(Translation, regionLocale).map((config) => (
                    <TableCell>{config.render ? config.render(row) : row[config.prop]}</TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter component="div" className={`table-footer-css`}>
        <TableRow component="div">
          <TablePagination
            align="right"
            rowsPerPageOptions={[5, 10, 20, 50]}
            colSpan={12}
            component="div"
            count={pageData.total || 0}
            rowsPerPage={pageData.pageSize}
            page={pageData.pageNum - 1 || 0}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={(e, page) => onChangePage(page + 1)}
            onRowsPerPageChange={(e) => onChangePageRows(e.target.value)}
            ActionsComponent={PruTablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </>
  );
};

export default ReportEvent;
