import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import ParamsProvider from 'src/app/common/components/ParamsProvider';
import PruRoute from 'src/app/common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { MDRT_HUB_BASE_PATH, MDRT_BANNER_BASE_PATH } from '../../constants';
import MdrtBannerListingPage, { filterKeys } from './List/MdrtBannerListingPage';
import MdrtBannerDetailPage from './Create/MdrtBannerDetailPage';

const MdrtBannerRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={`${MDRT_HUB_BASE_PATH}${MDRT_BANNER_BASE_PATH}`}
        to={`${MDRT_HUB_BASE_PATH}${MDRT_BANNER_BASE_PATH}/list`}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${MDRT_BANNER_BASE_PATH}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={filterKeys}>
            <MdrtBannerListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${MDRT_BANNER_BASE_PATH}/view/:id`}
        component={MdrtBannerDetailPage}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${MDRT_BANNER_BASE_PATH}/edit/:id`}
        component={MdrtBannerDetailPage}
      />
      <PruRoute exact path={`${MDRT_HUB_BASE_PATH}${MDRT_BANNER_BASE_PATH}/create`} component={MdrtBannerDetailPage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default MdrtBannerRoutes;
