import React, { FC, useState } from 'react';
import { Dialog, Button } from '@mui/material';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { useDispatch } from 'react-redux';
import { deleteTemplate } from '../../../../../network/greetingCardCrud';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

type DeleteDialogProps = {
  open: boolean;
  templateId: string;
  onClose: () => void;
  onRefresh: () => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    fontSize: '1.2rem',
    lineHeight: 1.2,
    marginBottom: 15,
  },
  fieldContainer: {
    paddingTop: 5,
    flexBasis: '20%',
    minWidth: 120,
    boxSizing: 'border-box',
  },
  noPaddingFieldContainer: {
    flexBasis: '20%',
    minWidth: 120,
    boxSizing: 'border-box',
  },
  footer: {
    marginTop: 20,
  },
  rowContainer: {
    display: 'flex',
  },
}));

const DeleteDialog: FC<DeleteDialogProps> = ({ open, templateId, onClose, onRefresh }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const onDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteTemplate(templateId, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: Translation('event.greeting_card.template.dialog.title.success'),
            content: Translation('event.greeting_card.template.dialog.content.success'),
          },
        ]),
      );
      onRefresh();
      onClose();
    } catch (err) {
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Dialog onClose={() => !isDeleting && onClose()} open={open}>
      <div className={classes.container}>
        <div className={classes.header}>{Translation('event.greeting_card.template.dialog.form')}</div>
        <div>{Translation('event.greeting_card.template.dialog.content')}</div>
        <div className={classes.footer}>
          <Button disabled={isDeleting} variant="contained" onClick={onClose}>
            {Translation('app.button.cancel')}
          </Button>
          <Button
            disabled={isDeleting}
            style={{ marginLeft: 25 }}
            variant="contained"
            color="secondary"
            onClick={() => onDelete()}
          >
            {Translation('app.button.submit')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteDialog;
