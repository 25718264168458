import React, { FC, useState } from 'react';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { PaginateListMore } from 'src/app/common/types/common-types';
import { IReportFilter, IPersonalSalePlanningItem, YesAndNo } from '../../../types/sale-planning-types';
import {
  fetchPersonalSalePlanningReport,
  fetchPersonalSalePlanningReportFile,
  fetchTotalRecordPersonalSalePlanningReport,
} from '../../../network/salePlanningCrud';
import moment from 'moment';
import { numberSeparator } from '../../../../../common/utils/common-utils';

type PersonalSalePlanningReportProps = {} & ParamsProps;

const initialState: IReportFilter = {
  planDate: moment().startOf('month').toDate(),
  agentCode: '',
  leaderCode: '',
  unitCode: '',
  officeCode: '',
  approval: YesAndNo.YES,
  status: YesAndNo.YES,
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): IReportFilter => {
  return initialParams
    ? {
        planDate: initialParams.planDate
          ? moment(initialParams.planDate).startOf('day').toDate()
          : moment().startOf('month').toDate(),
        agentCode: '',
        leaderCode: '',
        unitCode: '',
        officeCode: '',
        approval: YesAndNo.YES,
        status: YesAndNo.YES,
        page: 1,
        limit: 5,
      }
    : initialState;
};

const YesNoOptions = [
  { displayName: 'Yes', value: YesAndNo.YES },
  { displayName: 'No', value: YesAndNo.NO },
];

const PersonalSalePlanningReport: FC<PersonalSalePlanningReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilter = paramsInitiator(initialParams);

  const [filterState, setFilterState] = useState<IReportFilter>(defaultFilter);
  const [reportList, setReportList] = useState<PaginateListMore<IPersonalSalePlanningItem>>();
  const { isLoading, refreshData } = useDataProvider<PaginateListMore<IPersonalSalePlanningItem>>(
    async () => {
      try {
        const params = handleParams(filterState);
        const result = await Promise.all([
          fetchPersonalSalePlanningReport(params, dispatch),
          fetchTotalRecordPersonalSalePlanningReport(params, dispatch),
        ]);
        return {
          ...result[0],
          ...result[1],
        };
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsv = () => {
    try {
      const params = handleParams(filterState);
      fetchPersonalSalePlanningReportFile(params, dispatch);
    } catch (err) {}
  };

  const handleParams = (filters: IReportFilter) => {
    return {
      ...filterState,
      planDate: moment(filterState.planDate).format('MM/YYYY'),
    };
  };

  return (
    <>
      <PruFilter
        title={Translation('report.salePlanning.personalSalePlanning.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('report.salePlanning.personalSalePlanning.filter.agentCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'leaderCode',
            initialValue: filterState.leaderCode,
            displayName: Translation('report.salePlanning.personalSalePlanning.filter.leaderCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'unitCode',
            initialValue: filterState.unitCode,
            displayName: Translation('report.salePlanning.personalSalePlanning.filter.unitCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'officeCode',
            initialValue: filterState.officeCode,
            displayName: Translation('report.salePlanning.personalSalePlanning.filter.officeCode'),
          },
          {
            type: PruFilterItemType.DATE_MONTH,
            field: 'planDate',
            initialValue: filterState.planDate,
            defaultValue: defaultFilter.planDate,
            displayName: Translation('report.salePlanning.personalSalePlanning.filter.planningMonth'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'status',
            initialValue: filterState.status,
            defaultValue: filterState.status,
            displayName: Translation('report.salePlanning.personalSalePlanning.filter.salesPlanningStatus'),
            choices: YesNoOptions,
            disabled: true,
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'approval',
            initialValue: filterState.approval,
            defaultValue: filterState.approval,
            displayName: Translation('report.salePlanning.personalSalePlanning.filter.ALApproval'),
            choices: YesNoOptions,
            disabled: filterState.status === YesAndNo.NO,
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            ...data,
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.salePlanning.personalSalePlanning.title')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsv(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            keyIndex: 'agentCode',
            displayName: Translation('report.salePlanning.personalSalePlanning.AGCode'),
            align: 'center',
            renderData: (row) => row.agentCode || '-',
          },
          {
            keyIndex: 'segment',
            align: 'center',
            displayName: Translation('report.salePlanning.personalSalePlanning.segment'),
            renderData: (row) => row.segment || '-',
          },
          {
            keyIndex: 'agentRole',
            align: 'center',
            displayName: Translation('report.salePlanning.personalSalePlanning.AGRole'),
            renderData: (row) => row.agentRole || '-',
          },
          {
            keyIndex: 'leaderCode',
            align: 'center',
            displayName: Translation('report.salePlanning.personalSalePlanning.ALCode'),
            renderData: (row) => row.leaderCode || '-',
          },
          {
            keyIndex: 'leaderRole',
            displayName: Translation('report.salePlanning.personalSalePlanning.ALRole'),
            align: 'center',
            renderData: (row) => row.leaderRole || '-',
          },
          {
            keyIndex: 'unitCode',
            align: 'center',
            displayName: Translation('report.salePlanning.personalSalePlanning.unitCode'),
            renderData: (row) => row.unitCode || '-',
          },
          {
            keyIndex: 'officeCode',
            align: 'center',
            displayName: Translation('report.salePlanning.personalSalePlanning.officeCode'),
            renderData: (row) => row.officeCode || '-',
          },
          {
            keyIndex: 'salesPlanning',
            align: 'center',
            displayName: Translation('report.salePlanning.personalSalePlanning.salesPlanning'),
            renderData: (row) => (row.salesPlanning ? 'Yes' : 'No'),
          },
          {
            keyIndex: 'planningMonth',
            align: 'center',
            displayName: Translation('report.salePlanning.personalSalePlanning.planningMonth'),
            renderData: (row) => row.planDate || '-',
          },
          {
            keyIndex: 'potentialCustomer',
            align: 'center',
            displayName: Translation('report.salePlanning.personalSalePlanning.potentialCustomer'),
            renderData: (row) => (row.potentialCustomer || 0).toString(),
          },
          {
            keyIndex: 'customerAppointment',
            align: 'center',
            displayName: Translation('report.salePlanning.personalSalePlanning.customerAppointment'),
            renderData: (row) => (row.customerAppointment || 0).toString(),
          },
          {
            keyIndex: 'productPresentation',
            align: 'center',
            displayName: Translation('report.salePlanning.personalSalePlanning.productPresentation'),
            renderData: (row) => numberSeparator((row.productPresentation || 0).toString()),
          },
          {
            keyIndex: 'case',
            align: 'center',
            displayName: Translation('report.salePlanning.personalSalePlanning.case'),
            renderData: (row) => (row.case || 0).toString(),
          },
          {
            keyIndex: 'FYP',
            align: 'center',
            displayName: Translation('report.salePlanning.personalSalePlanning.FYP'),
            renderData: (row) => numberSeparator((row.FYP || 0).toString()),
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default PersonalSalePlanningReport;
