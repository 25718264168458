import React, { FC, useState } from 'react';
import { EnrolmentType } from '../../../../types/news-type';
import { FormControlLabel, Checkbox, Button, ClickAwayListener, TextField } from '@mui/material';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { makeStyles } from 'tss-react/mui';
import { useCommonStyles } from '../../../../../../common/styles/common-styles';
import { map } from 'lodash';
import { useUpdateEffect } from 'src/app/common/utils';
import { DesignationType, DesignationValueMap } from '../../../../../../common/types/common-types';

type TargetApplicantSectionProps = {
  isDisabled: boolean;
  enrolmentType: EnrolmentType;
  eligibleDesignations: string[];
  eligibleAgents: string[];
  excludedAgents: string[];
  agentListFileName?: string;
  onToggleDesignation: (value: string) => void;
  onModifyTarget: (
    option: 'enrolmentType' | 'eligibleAgents' | 'eligibleDesignations' | 'excludedAgents' | 'agentListFileName',
    value: any,
  ) => void;
};

const useStyles = makeStyles()(() => ({
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  fieldContainer: {
    minWidth: 100,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  popupWrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  cardPos: {
    position: 'absolute',
    left: '100%',
    transform: 'translate(5px, -50%)',
    zIndex: 1,
  },
}));

enum TargetApplicantGroup {
  RM_AND_UP = 'RM & Up',
  // ALL_AGENT_EXCLUDE_LA = 'All Agent exclude LA',
  ALL_AGENT_INCLUDE_LA = 'All Agent include LA',
}

const targetApplicantDesignationMap = {
  [TargetApplicantGroup.RM_AND_UP]: ['SRD', 'RD', 'SRM', 'RM'] as DesignationType[],
  // [TargetApplicantGroup.ALL_AGENT_EXCLUDE_LA]: ['BM', 'SUM', 'UM', 'SBSM', 'BSM', 'SFC', 'FC'] as DesignationType[],
  // [TargetApplicantGroup.ALL_AGENT_INCLUDE_LA]: ['LA'] as DesignationType[]
  [TargetApplicantGroup.ALL_AGENT_INCLUDE_LA]: [
    'BM',
    'SUM',
    'UM',
    'SBSM',
    'BSM',
    'SFC',
    'FC',
    'LA',
  ] as DesignationType[],
};

const TargetApplicationSection: FC<TargetApplicantSectionProps> = ({
  isDisabled,
  enrolmentType,
  eligibleDesignations,
  eligibleAgents,
  excludedAgents,
  agentListFileName,
  onToggleDesignation,
  onModifyTarget,
}) => {
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [targetApplicantGroup, setTargetApplicantGroup] = useState<TargetApplicantGroup | undefined>(undefined);

  useUpdateEffect(() => {
    onModifyTarget('agentListFileName', '');
    onModifyTarget('eligibleAgents', []);
    onModifyTarget('eligibleDesignations', []);
    onModifyTarget('excludedAgents', []);
    setTargetApplicantGroup(undefined);
  }, [enrolmentType]);

  useUpdateEffect(() => {
    let newDesignations: DesignationType[] = [];
    if (targetApplicantGroup === TargetApplicantGroup.RM_AND_UP) {
      newDesignations = newDesignations.concat(targetApplicantDesignationMap[TargetApplicantGroup.RM_AND_UP]);
      newDesignations.map((designation) => DesignationValueMap[designation]);
      onModifyTarget(
        'eligibleDesignations',
        newDesignations.map((designation) => DesignationValueMap[designation]),
      );
    }
    // else if (targetApplicantGroup === TargetApplicantGroup.ALL_AGENT_EXCLUDE_LA) {
    //   newDesignations = newDesignations.concat(targetApplicantDesignationMap[TargetApplicantGroup.RM_AND_UP]);
    //   newDesignations = newDesignations.concat(targetApplicantDesignationMap[TargetApplicantGroup.ALL_AGENT_EXCLUDE_LA]);
    //   onModifyTarget('eligibleDesignations', newDesignations.map(designation => DesignationValueMap[designation]));
    // }
    else if (targetApplicantGroup === TargetApplicantGroup.ALL_AGENT_INCLUDE_LA) {
      newDesignations = newDesignations.concat(targetApplicantDesignationMap[TargetApplicantGroup.RM_AND_UP]);
      // newDesignations = newDesignations.concat(targetApplicantDesignationMap[TargetApplicantGroup.ALL_AGENT_EXCLUDE_LA]);
      newDesignations = newDesignations.concat(
        targetApplicantDesignationMap[TargetApplicantGroup.ALL_AGENT_INCLUDE_LA],
      );
      onModifyTarget(
        'eligibleDesignations',
        newDesignations.map((designation) => DesignationValueMap[designation]),
      );
    }
  }, [targetApplicantGroup]);

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = () => {
        let csvData = String(reader.result);
        let row = csvData.split('\n');
        let agentList: string[] = [];
        row.shift();
        row.forEach((item) => {
          let rowItems = item.split(',');
          if (rowItems[0] && rowItems[0].trim()) {
            agentList.push(rowItems[0].trim());
          }
        });
        onModifyTarget('agentListFileName', file.name);
        onModifyTarget('eligibleAgents', agentList);
      };
      if (e.target.files) {
        reader.readAsBinaryString(e.target.files[0]);
      }
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <FormControlLabel
        control={
          <Checkbox
            disabled={isDisabled}
            icon={<CircleUnchecked />}
            checkedIcon={<CircleCheckedFilled />}
            checked={enrolmentType === EnrolmentType.BY_SEGMENT}
            onChange={(e) => {
              if (e.target.checked) {
                onModifyTarget('enrolmentType', EnrolmentType.BY_SEGMENT);
              }
            }}
          />
        }
        label="By Segment"
        labelPlacement="end"
      />
      <FormControlLabel
        control={
          <Checkbox
            disabled={isDisabled}
            icon={<CircleUnchecked />}
            checkedIcon={<CircleCheckedFilled />}
            checked={enrolmentType === EnrolmentType.SPECIFIC}
            onChange={(e) => {
              if (e.target.checked) {
                onModifyTarget('enrolmentType', EnrolmentType.SPECIFIC);
              }
            }}
          />
        }
        label="Upload Agent List"
        labelPlacement="end"
      />
      {enrolmentType === EnrolmentType.BY_SEGMENT && (
        <div>
          {/* <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>Designation : </span>
            </div>
            <div className={classes.popupWrapper}>
              <Button disabled={isDisabled} variant="contained" color="secondary" onClick={() => setIsOpen(true)}>
                Select
              </Button>
              {isOpen && (
                <div className={classes.cardPos}>
                  <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                    <div style={{ width: 480 }} className={commonClasses.popupCard}>
                      <div
                        style={{ borderBottom: '1px solid #DDDDDD', paddingLeft: 10 }}
                        className={classes.rowContainer}
                      >
                        {map(TargetApplicantGroup, (option) => (
                          <FormControlLabel
                            key={`target-applicant-group-${option}`}
                            control={
                              <Checkbox
                                checked={targetApplicantGroup === option}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setTargetApplicantGroup(option);
                                  }
                                }}
                              />
                            }
                            label={option}
                            labelPlacement="end"
                          />
                        ))}
                      </div>
                      <div>
                        {map(targetApplicantDesignationMap, (section) => (
                          <div key={`target-applicant-row-${section}`} className={classes.rowContainer}>
                            {section.map((designation) => (
                              <FormControlLabel
                                key={`target-applicant-designation-${designation}`}
                                style={{ flexBasis: '20%', margin: 0 }}
                                control={
                                  <Checkbox
                                    checked={
                                      !!eligibleDesignations.find((item) => item === DesignationValueMap[designation])
                                    }
                                    onChange={(e) => {
                                      onToggleDesignation(DesignationValueMap[designation]);
                                      if (targetApplicantGroup) {
                                        setTargetApplicantGroup(undefined);
                                      }
                                    }}
                                  />
                                }
                                label={designation}
                                labelPlacement="end"
                              />
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </ClickAwayListener>
                </div>
              )}
            </div>
          </div> */}
          {/* <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>Extra Include : </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={isDisabled}
                margin="dense"
                variant="outlined"
                fullWidth
                placeholder={'Input agent code separate by comma. e.g. 10001, 10002'}
                value={eligibleAgents.join(',')}
                onChange={(e) => {
                  const agentCode = e.target.value.replace(/[^0-9,]/g, '');
                  onModifyTarget('eligibleAgents', agentCode ? agentCode.split(',') : []);
                }}
              />
            </div>
          </div> */}
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>Extra Exclude : </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                disabled={isDisabled}
                margin="dense"
                variant="outlined"
                fullWidth
                value={excludedAgents.join(',')}
                placeholder={'Input agent code separate by comma. e.g. 10001, 10002'}
                onChange={(e) => {
                  const agentCode = e.target.value.replace(/[^0-9,]/g, '');
                  onModifyTarget('excludedAgents', agentCode ? agentCode.split(',') : []);
                }}
              />
            </div>
          </div>
        </div>
      )}
      {enrolmentType === EnrolmentType.SPECIFIC && (
        <div>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>Upload a .csv File : </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <input
                id="upload-agent-list"
                hidden
                type="file"
                onClick={(e) => {
                  const element = e.target as HTMLInputElement;
                  element.value = '';
                }}
                onChange={handleFile}
              />
              <div className={classes.rowContainer}>
                <Button
                  disabled={isDisabled}
                  variant="contained"
                  color="secondary"
                  onClick={() => document.getElementById('upload-agent-list')!.click()}
                >
                  Choose File
                </Button>
                <div style={{ marginLeft: 10 }}>{agentListFileName || 'No file chosen'}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TargetApplicationSection;
