import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../common/components/PruRoute';
import { QA_INCOME_BASE_PATH } from '../constants';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../common/constants';
import QuestionListingPage, { filterKeys } from './List/QuestionListingPage';
import ParamsProvider from 'src/app/common/components/ParamsProvider';

const QAIncomeRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect exact from={`${QA_INCOME_BASE_PATH}`} to={`${QA_INCOME_BASE_PATH}/list`} />
      <PruRoute exact path={`${QA_INCOME_BASE_PATH}/list`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={filterKeys}>
          <QuestionListingPage />
        </ParamsProvider>
      )}/>
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
};

export default QAIncomeRoutes;