// refactor for moving alert functions to @pruforce/common-adminweb-sdk, keeping PVA's own loading function
import { createSlice } from '@reduxjs/toolkit';

export type CommonState = {
  loading: boolean;
};

const commonState: CommonState = {
  loading: false,
};

type ShowLoading = {
  payload: boolean;
};

export const commonSlice = createSlice({
  name: 'common',
  initialState: commonState,
  reducers: {
    showLoading: (state: CommonState, action: ShowLoading) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
  },
});

export const { showLoading } = commonSlice.actions;
