import React, { FC, useEffect } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../../../../common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../../common/constants';
import { SALESKIT_BASE_PATH, BDM_EVENT_BASE_PATH, BDM_BOP_EVENT_BASE_PATH } from '../../../../constants';
import { ModulePermissionProps } from '../../../../../../common/module/types';
import { useDispatch } from 'react-redux';
import { resetState } from '../../../../redux/saleskit.slice';
import BdmBOPEventListPage from './list/bdm-bop-event-list.page';
import BdmBOPEventDetailPage from './detail/bdm-bop-event-detail.page';

const BdmBOPEventRoutes: FC<RouteComponentProps & ModulePermissionProps> = ({
  enableRead,
  enableCreate,
  enableUpdate,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      // clear list state
      dispatch(resetState());
    };
  }, []);
  return (
    <Switch>
      <Redirect
        exact
        from={`${SALESKIT_BASE_PATH}${BDM_EVENT_BASE_PATH}${BDM_BOP_EVENT_BASE_PATH}`}
        to={`${SALESKIT_BASE_PATH}${BDM_EVENT_BASE_PATH}${BDM_BOP_EVENT_BASE_PATH}/list`}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${BDM_EVENT_BASE_PATH}${BDM_BOP_EVENT_BASE_PATH}/list`}
        render={(props) => (
          <BdmBOPEventListPage
            {...props}
            enableRead={enableRead}
            enableCreate={enableCreate}
            enableUpdate={enableUpdate}
          />
        )}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${BDM_EVENT_BASE_PATH}${BDM_BOP_EVENT_BASE_PATH}/view/:id`}
        component={BdmBOPEventDetailPage}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default BdmBOPEventRoutes;
