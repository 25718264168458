import { Dispatch } from 'react';
import { EVENT_URL, apiClient, apiErrorHandler } from 'src/app/common/network';

export const getTitles = async (params?: any) => {
  return apiClient.get(`${EVENT_URL}/events/greeting-card/titles`, { params });
};

export const addTitle = async (data?: any) => {
  return apiClient.post(`${EVENT_URL}/events/greeting-card/titles`, data);
};

export const editTitle = async (data?: any) => {
  return apiClient.put(`${EVENT_URL}/events/greeting-card/titles`, data);
};

export const getTemplates = async (params?: any) => {
  return apiClient.get(`${EVENT_URL}/events/greeting-card/templates`, { params });
};

export const addTemplate = async (data?: any) => {
  return apiClient.post(`${EVENT_URL}/events/greeting-card/templates`, data);
};

export const editTemplate = async (data?: any) => {
  return apiClient.put(`${EVENT_URL}/events/greeting-card/templates`, data);
};

export const deleteTemplate = async (id?: String, dispatch?: Dispatch<any>): Promise<void> => {
  return apiClient
    .delete(`${EVENT_URL}/events/greeting-card/templates/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getContents = async (params?: any) => {
  return apiClient.get(`${EVENT_URL}/events/greeting-card/contents`, { params });
};

export const addContent = async (data?: any) => {
  return apiClient.post(`${EVENT_URL}/events/greeting-card/contents`, data);
};

export const editContent = async (data?: any) => {
  return apiClient.put(`${EVENT_URL}/events/greeting-card/contents`, data);
};

export const getSpeicalDay = async (params?: any) => {
  return apiClient.get(`${EVENT_URL}/events/special-days`, { params });
};
