import React, { FC } from "react";
import { Switch, Redirect, RouteComponentProps } from "react-router-dom";
import ParamsProvider from "src/app/common/components/ParamsProvider";
import PruRoute from "src/app/common/components/PruRoute";
import { NOT_FOUND_ERROR_PAGE_PATH } from "src/app/common/constants";
import { BDM_TEMPLETE_BASE_PATH, BDM_BACKGROUND_BASE_PATH, SALESKIT_BASE_PATH } from "../../constants";
import BdmBackgroundImageListingPage, { filterKeys } from "./List/BdmBackgroundImageListingPage";
import BdmBackgroundImageDetailPage from "./Create/BdmBackgroundImageDetailPage";

const BdmBackgroundImageRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={`${SALESKIT_BASE_PATH}${BDM_TEMPLETE_BASE_PATH}${BDM_BACKGROUND_BASE_PATH}`}
        to={`${SALESKIT_BASE_PATH}${BDM_TEMPLETE_BASE_PATH}${BDM_BACKGROUND_BASE_PATH}/list`}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${BDM_TEMPLETE_BASE_PATH}${BDM_BACKGROUND_BASE_PATH}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={filterKeys}>
            <BdmBackgroundImageListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${BDM_TEMPLETE_BASE_PATH}${BDM_BACKGROUND_BASE_PATH}/view/:id`}
        component={BdmBackgroundImageDetailPage}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${BDM_TEMPLETE_BASE_PATH}${BDM_BACKGROUND_BASE_PATH}/edit/:id`}
        component={BdmBackgroundImageDetailPage}
      />
      <PruRoute
        exact
        path={`${SALESKIT_BASE_PATH}${BDM_TEMPLETE_BASE_PATH}${BDM_BACKGROUND_BASE_PATH}/create`}
        component={BdmBackgroundImageDetailPage}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default BdmBackgroundImageRoutes;
