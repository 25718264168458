import { Dispatch } from 'react';
import { PaginateList } from '../../../common/types/common-types';
import { MdrtPassportBackgroundDetail, MdrtPassportBackgroundItem } from '../types/mdrt-passport-background-types';
import { apiClient, apiErrorHandler } from 'src/app/common/network';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_MDRT']}`;

export type MdrtPassportBackgroundListParams = {
  page: number;
  limit: number;
};

export const create = async (
  data: MdrtPassportBackgroundDetail,
  dispatch?: Dispatch<any>,
): Promise<MdrtPassportBackgroundDetail> => {
  return apiClient
    .post<MdrtPassportBackgroundDetail>(`${ADMIN_URL}/mdrt-hub/passport-background`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const update = async (
  data: MdrtPassportBackgroundDetail,
  dispatch?: Dispatch<any>,
): Promise<MdrtPassportBackgroundDetail> => {
  return apiClient
    .put<MdrtPassportBackgroundDetail>(`${ADMIN_URL}/mdrt-hub/passport-background/${data.id}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchItem = async (id: string, dispatch?: Dispatch<any>): Promise<MdrtPassportBackgroundDetail> => {
  return apiClient
    .get<MdrtPassportBackgroundDetail>(`${ADMIN_URL}/mdrt-hub/passport-background/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchList = async (
  params: MdrtPassportBackgroundListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<MdrtPassportBackgroundItem>> => {
  let queryPath = `${ADMIN_URL}/mdrt-hub/passport-background/list?page=1&limit=10`;

  return apiClient
    .get<PaginateList<MdrtPassportBackgroundItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
