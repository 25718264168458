import { forEach } from 'lodash';
import { Dispatch } from 'react';
import { PaginateList } from '../../../common/types/common-types';
import { getDayStart, getDayEnd } from 'src/app/common/utils/time-utils';
import { MdrtPassportDetail, MdrtPassportItem } from '../types/mdrt-passport-types';
import { apiClient, apiErrorHandler } from 'src/app/common/network';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_MDRT']}/mdrt-hub`;
// const ADMIN_URL = `http://localhost:4111/mdrt-hub`;

export type MdrtPassportListParams = {
  agentCode: string;
  lastUpdatedDateFrom: Date | null;
  lastUpdatedDateTo: Date | null;
  page: number;
  limit: number;
};

export const addMdrtPassport = async (
  data: MdrtPassportDetail,
  dispatch?: Dispatch<any>,
): Promise<MdrtPassportDetail> => {
  return apiClient
    .post<MdrtPassportDetail>(`${ADMIN_URL}/passport-upload/add`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchMdrtPassportItem = async (id: string, dispatch?: Dispatch<any>): Promise<MdrtPassportDetail> => {
  return apiClient
    .get<MdrtPassportDetail>(`${ADMIN_URL}/passport-upload/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchMdrtPassportList = async (
  params: MdrtPassportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<MdrtPassportItem>> => {
  let queryPath = `${ADMIN_URL}/passport-upload/list?`;

  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.includes('From')) {
          queryPath += `${key}=${getDayStart(param).toISOString()}&`;
        }
        if (key.includes('To')) {
          queryPath += `${key}=${getDayEnd(param).toISOString()}&`;
        }
      }
    } else {
      queryPath += param ? `${key}=${param}&` : '';
    }
  });

  return apiClient
    .get<PaginateList<MdrtPassportItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const publishMdrtPassport = async (id: string, dispatch?: Dispatch<any>): Promise<MdrtPassportDetail> => {
  return apiClient
    .get<MdrtPassportDetail>(`${ADMIN_URL}/passport-upload/publish/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const unpublishMdrtPassport = async (id: string, dispatch?: Dispatch<any>): Promise<MdrtPassportDetail> => {
  return apiClient
    .get<MdrtPassportDetail>(`${ADMIN_URL}/passport-upload/unpublish/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteMdrtPassport = async (id: string, dispatch?: Dispatch<any>): Promise<MdrtPassportDetail> => {
  return apiClient
    .delete<MdrtPassportDetail>(`${ADMIN_URL}/passport-upload/delete/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getPassportByAgentCode = async (agentCode: string, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get<MdrtPassportDetail>(`${ADMIN_URL}/passport-upload/detail`, { params: { agentCode } })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const mdrtPassportUploadFile = async (file: any, dispatch?: Dispatch<any>): Promise<any> => {
  const formData = new FormData();
  formData.append('file', file);

  return apiClient
    .post<any>(`${ADMIN_URL}/passport-upload/file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
