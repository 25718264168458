import React, { FC, useCallback, useEffect, useReducer, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import {
  summaryType,
  SummaryStatistic,
} from 'src/app/modules/sales-kit/pages/insurance-summary/type/insurance-summary-types';

import { useIntl } from 'react-intl';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: '#F7F7F7',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 350,
    },
    [theme.breakpoints.between('sm', 'xl')]: {
      flex: 0.4,
    },
    '@media (min-width: 1280px)': {
      flex: 0.3,
    },
  },

  headerContainer: {
    display: 'flex',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  summaryItem: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 70,
    },
    [theme.breakpoints.between('sm', 'xl')]: {
      minWidth: 100,
    },
    '@media (min-width: 1280px)': {
      minWidth: 100,
    },
  },
  summarySubtext: {
    fontFamily: '-apple-system, BlinkMacSystemFont, system-ui,sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#999999',
    fontWeight: 500,
  },

  summaryText: {
    fontWeight: 600,
    fontSize: '24px',
    fontFamily: 'Montserrat',
    lineHeight: '30px',
    color: '#333333',
  },
  summaryHeader: {
    fontFamily: 'SF Pro Text',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#333333',
  },
}));
type InsightSummaryProps = {
  summary: SummaryStatistic;
};

const InsightSummary = ({ summary }: InsightSummaryProps) => {
  const { classes } = useStyles();

  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  let InsuranceSummaryInsightResult: summaryType = {
    totalOpened: 'protection.review.viewResults.formOpened',

    totalSaved: 'protection.review.viewResults.dataSaved',

    totalSubmitted: 'protection.review.viewResults.totalSubmitted',
  };

  let orderArray = Object.keys(InsuranceSummaryInsightResult);

  const SummaryForm = (data: SummaryStatistic) => {
    let result: React.ReactNode[] = [];
    let item: string | boolean;

    for (let [key, value] of Object.entries(data)) {
      let index = orderArray.indexOf(key);

      item =
        !!InsuranceSummaryInsightResult[key as keyof summaryType] &&
        InsuranceSummaryInsightResult[key as keyof summaryType];

      item &&
        result.insert(
          index,
          <div key={key} className={classes.summaryItem}>
            <div className={classes.summaryText}>{value && value}</div>
            <div className={classes.summarySubtext}>{item && Translation(item)}</div>
          </div>,
        );
    }

    return result;
  };
  return (
    <>
      <div className={classes.headerContainer}>
        <div className={classes.container}>
          <div className={classes.summaryHeader}>{Translation('protection.review.viewResults.title')}</div>
          <div className={classes.rowContainer}>{SummaryForm(summary)}</div>
        </div>
      </div>
    </>
  );
};

export default InsightSummary;
