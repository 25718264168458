import { map } from 'lodash';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterDropdownItem, PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { PaginateList } from 'src/app/common/types/common-types';
import { useDataProvider } from 'src/app/common/utils';
import { getDayStart, getDayEnd, getFirstDayOfMonth, getLastDayOfMonth } from 'src/app/common/utils/time-utils';
import {
  fetchQuestionList,
  QuestionListParams,
  fetchCategoryList,
  replyQuestion as replyQuestionApi,
} from '../../network/qaIncomeCrud';
import { EAnswerOptions, QuestionItem, CategoryResponse, ReplyQuestionRequest } from '../../types/qa-income-types';
import QAIncomeList from './components/QuestionList';
import DetailForm from '../Create/components/DetailForm';
import ReplyForm from '../Create/components/ReplyForm';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import moment from 'moment';

type QuestionListingPageProps = {} & ParamsProps;

const initialState: QuestionListParams = {
  agentCode: '',
  category: '',
  answer: EAnswerOptions.All,
  fromDate: getFirstDayOfMonth(new Date()),
  toDate: getLastDayOfMonth(new Date()),
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, any>): QuestionListParams => {
  return initialParams
    ? {
        agentCode: initialParams.agentCode || '',
        category: initialParams.status || '',
        answer: initialParams.answer || EAnswerOptions.All,
        fromDate: initialParams.fromDate
          ? getDayStart(new Date(initialParams.fromDate))
          : getFirstDayOfMonth(new Date()),
        toDate: initialParams.toDate ? getDayEnd(new Date(initialParams.toDate)) : getLastDayOfMonth(new Date()),
        page: 1,
        limit: 5,
      }
    : initialState;
};

export const filterKeys = ['agentCode', 'category', 'fromDate', 'toDate'];

const QuestionListingPage: FC<QuestionListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [statusModal, setStatusModal] = useState(false);
  const [messageError, setMessageError] = useState('');
  const handleModal = (status: boolean) => {
    setStatusModal(status);
  };

  const [filterState, setFilterState] = useState<QuestionListParams>(paramsInitiator(initialParams));
  const [questionList, setQuestionList] = useState<PaginateList<QuestionItem>>();
  const [selectedQuestionList, setSelectedQuestionList] = useState<QuestionItem[]>([]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [visibleDetailModal, setVisibleDetailModal] = useState<boolean>(false);
  const [visibleReplyModal, setVisibleReplyModal] = useState<boolean>(false);
  const [isReplySingleQuestion, setIsReplySingleQuestion] = useState<boolean>(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [question, setQuestion] = useState<QuestionItem>();

  const [allCategoryList, setCategoryOptionList] = useState<PruFilterDropdownItem[]>([]);
  const { isLoading, refreshData } = useDataProvider<PaginateList<QuestionItem>>(
    async () => {
      const submittedDays = moment(filterState.toDate).diff(moment(filterState.fromDate)) / 86400000;
      if (submittedDays > 90) {
        setMessageError(' The maximum of searching period is 3 months');
        handleModal(true);
        return;
      }

      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchQuestionList(filterState, dispatch);
      } catch (err) {}
    },
    setQuestionList,
    false,
  );

  useDataProvider<CategoryResponse>(
    async () => {
      try {
        const allCategoryList = await fetchCategoryList(dispatch);
        let optionList: PruFilterDropdownItem[] = [{ displayName: Translation('component.status.all'), value: '' }];
        allCategoryList.data.map((item) => {
          return optionList.push({
            displayName: item.content,
            value: item.code,
          });
        });
        setCategoryOptionList(optionList);
        return allCategoryList;
      } catch (err) {}
    },
    () => {},
    false,
  );

  const onSelectAllQuestion = () => {
    if (questionList && questionList.items) {
      questionList.items.map((item) => {
        item.selected = !isSelectAll;
        return item;
      });
      setQuestionList({ ...questionList });
    }
    setIsSelectAll(!isSelectAll);
  };

  const onSelectQuestion = (item: QuestionItem) => {
    const index = selectedQuestionList.findIndex((el) => el.id === item.id);
    //handle selected items
    if (index > -1) {
      //if item exist in selected question list, remove item from list
      selectedQuestionList.splice(index, 1);
      setSelectedQuestionList([...selectedQuestionList]);
    } else {
      //if item doest not exist in list, push it
      selectedQuestionList.push(item);
      setSelectedQuestionList([...selectedQuestionList]);
    }
    //handle question list
    const index2 = questionList?.items.findIndex((el) => el.id === item.id);
    if (index2 !== undefined && index2 > -1 && questionList && questionList.items[index2]) {
      questionList.items[index2].selected = !questionList.items[index2].selected;
      setQuestionList({ ...questionList });
    }
    //handle select all
    const i = (questionList?.items || []).filter((el) => !el.selected);
    if (i.length) {
      setIsSelectAll(false);
    } else {
      setIsSelectAll(true);
    }
  };

  const viewQuestion = (item: QuestionItem) => {
    setQuestion(item);
    setVisibleDetailModal(true);
  };

  const onHiddenDetailModal = () => {
    setVisibleDetailModal(false);
  };

  const onReplyQuestion = (item: QuestionItem) => {
    setQuestion(item);
    setVisibleReplyModal(true);
    setIsReplySingleQuestion(true);
  };

  const onHiddenReplyModal = () => {
    setVisibleReplyModal(false);
  };

  const onReplySelectedQuestion = () => {
    if (selectedQuestionList.length === 1) {
      setIsReplySingleQuestion(true);
    } else {
      setIsReplySingleQuestion(false);
    }
    setVisibleReplyModal(true);
  };

  const replyQuestion = async (data: ReplyQuestionRequest) => {
    setFormLoading(true);
    try {
      await replyQuestionApi(data, dispatch);
      setFormLoading(false);
      setVisibleReplyModal(false);
      if (isReplySingleQuestion) {
        setQuestion(undefined);
      } else {
        setSelectedQuestionList([]);
      }
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: Translation('global.submit.success'),
            content: Translation('global.submit.success'),
          },
        ]),
      );
      refreshData();
    } catch (e) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: Translation('global.submit.fail'),
            content: Translation('global.submit.fail'),
          },
        ]),
      );
      setFormLoading(false);
    }
  };

  return (
    <>
      <PruFilter
        title={Translation('qaIncome.filter.title')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('qaIncome.filter.agentCode'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'category',
            initialValue: filterState.category,
            displayName: Translation('qaIncome.filter.category'),
            choices: allCategoryList,
            style: {
              width: '250px',
            },
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'answer',
            initialValue: filterState.answer,
            displayName: Translation('qaIncome.filter.answer'),
            choices: map(EAnswerOptions, (option: string) => ({
              displayName: option,
              value: option,
            })),
            style: {
              width: '250px',
            },
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'fromDate',
            fieldTo: 'toDate',
            initialDateFrom: filterState.fromDate,
            initialDateTo: filterState.toDate,
            displayName: Translation('qaIncome.filter.questionDate'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            agentCode: data.agentCode,
            category: data.category || '',
            answer: data.answer || EAnswerOptions.All,
            fromDate: data.fromDate,
            toDate: data.toDate,
          })
        }
        fetchData={refreshData}
      />
      <QAIncomeList
        isLoading={isLoading}
        onRefresh={refreshData}
        questionList={questionList}
        onSelectAllQuestion={onSelectAllQuestion}
        onSelectQuestion={onSelectQuestion}
        viewQuestion={viewQuestion}
        isSelectAll={isSelectAll}
        isDisplayReplySlectedQuestionButton={!!selectedQuestionList.length}
        onReplySelectedQuestion={onReplySelectedQuestion}
        onReplyQuestion={onReplyQuestion}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        filterState={filterState}
      />
      <Dialog open={visibleDetailModal} onClose={onHiddenDetailModal} fullWidth maxWidth="sm">
        <DialogTitle>{Translation('qaIncome.modal.detailTitle')}</DialogTitle>
        {visibleDetailModal ? <DetailForm onCancel={onHiddenDetailModal} initialValues={question} /> : null}
      </Dialog>
      <Dialog open={visibleReplyModal} onClose={onHiddenReplyModal} fullWidth maxWidth="sm">
        <DialogTitle>{Translation('qaIncome.modal.replyTitle')}</DialogTitle>
        {visibleReplyModal ? (
          <ReplyForm
            onCancel={onHiddenReplyModal}
            onReply={replyQuestion}
            question={question}
            selectedQuestionList={selectedQuestionList}
            confirmLoading={formLoading}
            isReplySingleQuestion={isReplySingleQuestion}
          />
        ) : null}
      </Dialog>
      <Dialog
        open={statusModal}
        onClose={() => handleModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Message'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ color: 'red' }}>
            {messageError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleModal(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QuestionListingPage;
