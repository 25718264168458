import React, { FC, useState, useEffect } from 'react';
import {
  TableContainer,
  TableFooter,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Dialog,
  DialogTitle,
} from '@mui/material';
import { PruForm } from 'src/app/common/components/PruForm';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import ProTableCell from 'src/app/common/components/ProTable/ProTableCell';
import StickyTableCell from 'src/app/common/components/ProTable/StickyTableCell';
import PruTableLoading from 'src/app/common/components/Table/PruTableLoading';
import PruTableEmptyRow from 'src/app/common/components/Table/PruTableEmptyRow';
import PruTablePaginationActions from 'src/app/common/components/Table/PruTablePaginationActions';
import filterConfig from './filterConfig';
import tableHeader from './tableHeader';
import tableConfig from './tableConfig';
import { styles } from '../styles';
import { getTitles, getContents, addContent, editContent } from '../../../../network/eBirthdayCardCrud';
import PopUp from './components/popUp';
import { useSelector } from 'react-redux';
import { AuthenticationState } from '@pruforce/common-adminweb-sdk';
import { RootState } from 'src/redux/store';
import { regionLocale } from 'src/app/i18n';
import { getTitleLabel } from '../../../../network/utils';
import { PublishStatus } from '../../../../types/ebirthday-card-type';

const ContentList: FC<any> = (props) => {
  const intl = useIntl();
  const { classes } = styles();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const filterInitData = {
    titleId: undefined,
    content: '',
    modifier: '',
  };

  const [filterData, setFilterData] = useState<{ [key: string]: any }>(filterInitData);
  const [filterSavedData, setFilterSavedData] = useState<{ [key: string]: any }>(filterInitData);
  const [tableData, setTableData] = useState<{ [key: string]: any }[]>([]);
  const [pageData, setPageData] = useState<{ [key: string]: any }>({
    pageNum: 1,
    pageSize: 10,
  });
  const [listData, setListData] = useState<{ [key: string]: any }>({});
  const [popUpVisible, setPopUpVisible] = useState<boolean>(false);
  const initialDefaultValue = {
    titleId: undefined,
    isEarly: false,
    isBelated: false,
  };

  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>(initialDefaultValue);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [isView, setIsView] = useState<boolean>(false);
  const [contentId, setContentId] = useState<string>('');
  const { user } = useSelector<RootState, AuthenticationState>((state) => state.auth);

  useEffect(() => {
    const fetchAPI = async () => {
      setFormLoading(true);
      await fetchData();
      await initialListData();
      setFormLoading(false);
    };
    fetchAPI();
    return;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener('keypress', keyPressSearch);
    return () => {
      window.removeEventListener('keypress', keyPressSearch);
    };
    // eslint-disable-next-line
  }, [filterData]);

  const initialListData = async () => {
    const data = {
      status: PublishStatus.PUBLISHED,
      pageSize: 2000,
    };
    const titles = await getTitles(data);
    const newListData: any = {};
    let titleList: any = [
      {
        label: Translation('component.status.all'),
        value: '',
      },
    ];
    // eslint-disable-next-line
    titles.data &&
      titles.data.values.map((item: any) => {
        titleList.push({
          label: getTitleLabel(item),
          value: item.titleId,
        });
      });
    newListData.titleList = titleList;
    setListData(newListData);
  };

  const fetchData = (newFilterState?: any) => {
    setFormLoading(true);
    const newFilter = newFilterState || {};
    const requestParams = {
      ...filterData,
      ...pageData,
      ...newFilter,
    };

    if (!requestParams.titleId) {
      delete requestParams.titleId;
    }

    if (!requestParams.content || requestParams.content == '') {
      delete requestParams.content;
    }

    if (!requestParams.modifier || requestParams.modifier == '') {
      delete requestParams.modifier;
    }

    getContents(requestParams)
      .then((res: any) => {
        setTableData(res.data ? res.data.values : []);
        setPageData({
          pageNum: res.data?.page || 1,
          pageSize: res.data?.size || 0,
          total: res.data?.total || 0,
        });
        setFormLoading(false);
      })
      .catch((err: any) => {
        console.log(err.message);
        setFormLoading(false);
      });
  };

  const onSearch = () => {
    // setFilterData(filterData)
    const newPageData = {
      ...pageData,
      pageNum: 1,
    };
    setPageData(newPageData);
    setFilterSavedData(filterData);
    fetchData({ ...filterData, ...newPageData });
  };

  const keyPressSearch = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  const onChangePage = (pageNum: number) => {
    const newPageData = {
      ...pageData,
      pageNum,
    };
    setPageData(newPageData);
    fetchData({ ...filterSavedData, ...newPageData });
  };

  const onChangePageRows = (rows: any) => {
    const newPageData = {
      ...pageData,
      pageNum: 1,
      pageSize: rows,
    };

    setPageData(newPageData);
    fetchData({ ...filterSavedData, ...newPageData });
  };

  const handleSort = (sorter: any) => {
    let sortItem;
    let sortOrder;
    Object.entries(sorter).forEach(([item, order]) => {
      sortItem = item;
      sortOrder = order;
    });
    const data = {
      ...filterSavedData,
      sortItem,
      sortOrder: sortOrder ? (sortOrder as string).toUpperCase() : sortOrder,
    };

    const newPageData = {
      ...pageData,
      pageNum: 1,
    };

    setFilterSavedData(data);
    setPageData(newPageData);
    fetchData({ ...data, ...newPageData });
  };

  const openPopUp = () => {
    setPopUpVisible(true);
  };

  const onSave = async (values: any) => {
    setFormLoading(true);
    const { content1, content2 } = values;
    const i18nDataList = [
      {
        lang: regionLocale.length > 0 && regionLocale[0],
        content: content1,
      },
      {
        lang: regionLocale.length > 0 && regionLocale[1],
        content: content2,
      },
    ];

    const data = {
      ...values,
      i18nDataList,
      contentId,
      modifier: user && user.username,
    };
    try {
      if (contentId) {
        await editContent(data);
      } else {
        await addContent(data);
      }

      setContentId('');
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: Translation('global.submit.success'),
            content: Translation('global.submit.success'),
          },
        ]),
      );
      await refreshData();
      setFormLoading(false);
      setPopUpVisible(false);
    } catch (error) {
      setFormLoading(false);
      setPopUpVisible(false);
      setContentId('');
    }
  };

  const refreshData = async () => {
    const newPageData = {
      ...pageData,
      pageNum: 1,
    };
    setPageData(newPageData);
    fetchData({ ...filterSavedData, ...newPageData });
  };

  const onEdit = (row: any) => {
    setContentId(row.contentId);
    const i18nDataList = row.i18nDataList && row.i18nDataList;
    const content1 = i18nDataList && i18nDataList[0].content;
    const content2 = i18nDataList && i18nDataList[1].content;
    setPopUpVisible(true);
    setInitialValues({ ...row, ...{ content1, content2, contentId: row.contentId } });
  };

  const onChangeStatus = (row: any) => {
    editContent({
      contentId: row.contentId,
      titleList: row.titleList,
      status: row.status == PublishStatus.PUBLISHED ? PublishStatus.UNPUBLISHED : PublishStatus.PUBLISHED,
      modifier: user && user.username,
    })
      .then((res: any) => {
        refreshData();
        setFormLoading(false);
      })
      .catch((err: any) => {
        setFormLoading(false);
      });
  };

  const onCancel = () => {
    setPopUpVisible(false);
    setInitialValues(initialDefaultValue);
    setContentId('');
    setTimeout(() => {
      setIsView(false);
    }, 200);
  };

  const onReset = () => {
    setFilterData(filterInitData);
  };

  return (
    <>
      <PruForm
        config={filterConfig(Translation, onSearch, onReset, listData)}
        data={filterData}
        onChange={setFilterData}
        space={20}
        style={{ padding: 20 }}
        opts={{ labelWidth: 120 }}
      ></PruForm>

      <PruForm
        config={tableHeader(Translation, openPopUp, refreshData)}
        data={[]}
        onChange={() => {}}
        space={20}
        style={{ padding: '15px 20px', marginTop: 20, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
      ></PruForm>

      <TableContainer component={Paper} className="event-category-container">
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableConfig(Translation, regionLocale).map((item) => (
                <ProTableCell key={item.prop} dataIndex={item.prop} sorter={item.sorter} handleSort={handleSort}>
                  {item.title}
                </ProTableCell>
              ))}
              <StickyTableCell className={`sticky-cell`}>
                {Translation('component.formLabel.operation')}
              </StickyTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <PruTableLoading isLoading={formLoading} />
            <PruTableEmptyRow isEmpty={tableData.length === 0} />
            {tableData.length > 0 &&
              tableData.map((row) => (
                <TableRow>
                  {tableConfig(Translation, regionLocale).map((config) => (
                    <TableCell>{config.render ? config.render(row) : row[config.prop]}</TableCell>
                  ))}
                  <StickyTableCell className={`sticky-cell`}>
                    <div className={classes.operationContainer}>
                      {
                        <div
                          style={{ marginRight: 10 }}
                          className={`${classes.operationBtn}`}
                          onClick={() => onEdit(row)}
                        >
                          {Translation('component.formTitle.edit')}
                        </div>
                      }
                      {
                        <div
                          style={{ marginRight: 10 }}
                          className={`${classes.operationBtn}`}
                          onClick={() => onChangeStatus(row)}
                        >
                          {row.status == PublishStatus.PUBLISHED
                            ? Translation('app.button.unpublish')
                            : Translation('app.button.publish')}
                        </div>
                      }
                    </div>
                  </StickyTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter component="div" className={`table-footer-css`}>
        <TableRow component="div">
          <TablePagination
            align="right"
            rowsPerPageOptions={[5, 10, 20, 50]}
            colSpan={12}
            component="div"
            count={pageData.total || 0}
            rowsPerPage={pageData.pageSize}
            page={pageData.pageNum - 1 || 0}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={(e, page) => onChangePage(page + 1)}
            onRowsPerPageChange={(e) => onChangePageRows(e.target.value)}
            ActionsComponent={PruTablePaginationActions}
          />
        </TableRow>
      </TableFooter>

      <Dialog open={popUpVisible} onClose={isView ? onCancel : () => {}} fullWidth>
        <DialogTitle>
          {isView
            ? Translation('global.view.text')
            : contentId
            ? Translation('global.edit.text')
            : Translation('app.button.addNew')}{' '}
          {`${Translation('event.ebirthday_card.content.form')}`}
        </DialogTitle>
        <PopUp
          visible={popUpVisible}
          onCancel={onCancel}
          onSave={onSave}
          i18ConfigData={regionLocale}
          titleList={listData.titleList}
          initialValues={initialValues}
          confirmLoading={formLoading}
          disabled={isView}
          contentId={contentId}
        />
      </Dialog>
    </>
  );
};

export default ContentList;
