// @ts-nocheck
import React, { FC, useMemo, useEffect, useState } from 'react';
import {
  BackgroundImageCategoryItem,
  BdmBackgroundImageFormMode,
  BdmBackgroundImageFormState,
} from '../../../../components/BdmBackgroundImage/types/bdm-background-image-types';
import { Button, TextField, MenuItem } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { BDM_TEMPLETE_BASE_PATH, BDM_BACKGROUND_BASE_PATH, SALESKIT_BASE_PATH } from '../../../../constants';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { useDataProvider } from 'src/app/common/utils';
import {
  create,
  update,
  getAllCategory,
} from '../../../../components/BdmBackgroundImage/network/bdmBackgroundImageCrud';
import { Formik, Form, getIn, FieldArray, useFormikContext } from 'formik';
import * as Yup from 'yup';
export const LINK_INVALID_ERROR = 'component.form-link-text';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';
import FileUploader, { FileObj } from 'src/app/common/components/FileUploader';
import TextColorList from './TextColorList';
import { makeStyles } from 'tss-react/mui';
import { Add } from '@mui/icons-material';
import { EVENT_GP_TEMPLATE_CONFIG, EVENT_BOP_TEMPLATE_CONFIG } from '../../../bdm-event/type/bdm-event.type';

type BdmBackgroundImageDetailFormProps = {
  itemId?: string;
  formMode: BdmBackgroundImageFormMode;
  itemDetail?: any;
  onReload: () => void;
  onRouteTo: (route: string) => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  rowContainerFlexStart: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  fieldContainer: {
    width: 120,
    boxSizing: 'border-box',
  },
  fieldContainer2: {
    paddingLeft: 20,
    width: 240,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  dateDivider: {
    marginLeft: 8,
    marginRight: 8,
  },
  sectionMargin: {
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },

  flexItem: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '33.333333%',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteButton: {
    color: 'red',
    cursor: 'pointer',
    fontWeight: 500,
  },
  errorText: {
    marginTop: 5,
    fontSize: 14,
    color: '#F018A6',
  },
  iconContainer: {
    width: 60,
    height: 60,
    overflow: 'hidden',
    borderRadius: 12,
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  root: {
    '& .MuiFormHelperText-root': {
      fontSize: '0.95rem',
      marginLeft: '0px !important',
    },
  },
}));

const defaultColor = '#FFFFFF';

const BdmBackgroundImageDetailForm: FC<BdmBackgroundImageDetailFormProps> = ({
  itemId,
  formMode,
  itemDetail,
  onReload,
  onRouteTo,
}) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const commonClasses = useCommonStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [isLoading, setIsLoading] = useState(false);
  const [checkDuplicate, setCheckDuplicate] = useState([]);
  const isDisabled = useMemo(() => {
    return formMode === BdmBackgroundImageFormMode.CREATE || formMode === BdmBackgroundImageFormMode.EDIT
      ? false
      : true;
  }, [formMode]);
  const formikRef = React.useRef(null);
  const [categoryList, setCategoryList] = useState<BackgroundImageCategoryItem[]>();

  useDataProvider<any>(
    async () => {
      try {
        return await getAllCategory(dispatch);
      } catch (err) {}
    },
    setCategoryList,
    false,
  );

  const onSubmitForm = async (values: any) => {
    setIsLoading(true);
    const detail = values;
    try {
      if (formMode === BdmBackgroundImageFormMode.CREATE) {
        const createData = values.items.map((x) => ({
          ...x,
          name: x.name.trim(),
        }));
        const createResponse = await create(createData, dispatch);
        if (createResponse.tag === 'FAIL') {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.WARNING,
                title: Translation('global.submit.fail'),
                content: createResponse.message,
              },
            ]),
          );
          setCheckDuplicate(createResponse?.duplicateKeys || []);
          formikRef.current.validateForm();
        } else {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Saved successfully - ${detail.title}`,
              },
            ]),
          );
          onRouteTo(`${SALESKIT_BASE_PATH}${BDM_TEMPLETE_BASE_PATH}${BDM_BACKGROUND_BASE_PATH}`);
        }
      } else {
        detail.id = itemId;
        detail.name = values.name.trim();

        const updateResponse = await update(detail, dispatch);
        if (updateResponse?.tag === 'FAIL') {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.WARNING,
                title: Translation('global.submit.fail'),
                content: updateResponse?.message,
              },
            ]),
          );
          setCheckDuplicate([detail?.name]);
          formikRef.current.validateForm();
        } else {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
              },
            ]),
          );
          onRouteTo(`${SALESKIT_BASE_PATH}${BDM_TEMPLETE_BASE_PATH}${BDM_BACKGROUND_BASE_PATH}`);
        }
      }
    } catch (err) {}

    setTimeout(() => setIsLoading(false), 2000);
  };

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        {
          mimeType: fileType,
          accessLevel: 'anonymous',
          module: 'bdm-background',
        },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);

      const result = blobDetail[0];
      if (result) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Upload successfully - ${fileName}`,
            },
          ]),
        );
        return {
          id: result.blobId,
          fileName: fileName,
          url: result.url,
          type: fileType,
        };
      }
    } catch (err) {
    } finally {
    }
  };

  const _getInitialValues = () => {
    if (itemDetail) {
      if (!itemDetail.textColorList) {
        itemDetail.textColorList = [defaultColor];
      }
      return itemDetail;
    } else {
      return {
        items: [
          {
            name: '',
            type: '',
            templateId: '',
            categoryId: '',
            image: undefined,
            textColorList: [defaultColor],
          },
        ],
      };
    }
  };

  const dropdownOptions = {
    rType: [
      { displayName: 'GP', value: 'GP' },
      { displayName: 'BOP', value: 'BOP' },
      // { displayName: 'Picture', value: 'picture' }
    ],
  };

  const duplicateNameCheck = (list, value) => {
    if (list.includes(value?.trim())) {
      return false;
    } else {
      return true;
    }
  };

  Yup.addMethod(Yup.array, 'uniqueName', function (field, message) {
    return this.test('uniqueName', message, function (array) {
      const uniqueData = Array.from(new Set(array.map((row) => row[field]?.toLowerCase())));
      const isUnique = array.length === uniqueData.length;
      if (isUnique) {
        return true;
      }
      const index = array.findIndex((row, i) => row[field]?.toLowerCase() !== uniqueData[i]);

      if (index === -1 || array[index][field] === '') {
        return true;
      }
      return this.createError({
        path: `${this.path}.${index}.${field}`,
        message,
      });
    });
  });

  const validationSchema = !itemDetail
    ? Yup.object().shape({
        items: Yup.array()
          .of(
            Yup.object().shape({
              name: Yup.string()
                .required('Maximum 30 character and not include special character')
                .matches(
                  /^[a-zA-Z0-9ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ_. ]*$/,
                  'Maximum 30 character and not include special character ',
                )
                .test(
                  'unique',
                  'Maximum 30 character, not include special character and must not have the same name as a previously uploaded image or an image awaiting upload',
                  (values) => {
                    return duplicateNameCheck(checkDuplicate, values);
                  },
                ),
              type: Yup.string().required('Type is required'),
              templateId: Yup.string().required('Template is required'),
              categoryId: Yup.string().required('Category is required'),
              image: Yup.object().nullable().required('Image is Required'),
            }),
          )
          .uniqueName(
            'name',
            'Maximum 30 character, not include special character and must not have the same name as a previously uploaded image or an image awaiting upload',
          ),
      })
    : Yup.object().shape({
        name: Yup.string()
          .required('Maximum 30 character and not include special character')
          .matches(
            /^[a-zA-Z0-9ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ_. ]*$/,
            'Maximum 30 character and not include special character',
          )
          .test(
            'unique',
            'Maximum 30 character, not include special character and must not have the same name as a previously uploaded image or an image awaiting upload',
            (values) => {
              return duplicateNameCheck(checkDuplicate, values);
            },
          ),
        type: Yup.string().required('Type is required'),
        templateId: Yup.string().required('Template is required'),
        categoryId: Yup.string().required('Category is required'),
        image: Yup.object().nullable().required('Image is Required'),
      });

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>
              {Translation(
                formMode === BdmBackgroundImageFormMode.CREATE
                  ? 'app.button.add'
                  : formMode === BdmBackgroundImageFormMode.EDIT
                  ? 'app.button.edit'
                  : 'app.button.view',
              )}
            </div>
          </div>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => onRouteTo(`${SALESKIT_BASE_PATH}${BDM_TEMPLETE_BASE_PATH}${BDM_BACKGROUND_BASE_PATH}/list`)}
          >
            Back
          </Button>
        </div>
        <div className={classes.container}>
          {itemDetail ? (
            <Formik
              initialValues={_getInitialValues()}
              enableReinitialize
              innerRef={formikRef}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                onSubmitForm(values);
              }}
            >
              {({ values, touched, errors, handleChange, handleBlur, isValid, setFieldValue }) => (
                <Form noValidate autoComplete="off">
                  <div className={classes.sectionMargin}>
                    <div className={classes.rowContainer}>
                      <div className={classes.fieldContainer}>
                        <span className={classes.field}>
                          {Translation('component.formLabel.name')}
                          <span className={classes.mandatory}>*</span> :
                        </span>
                      </div>
                      <div style={{ flexGrow: 1 }}>
                        <TextField
                          style={{ width: '100%' }}
                          disabled={isDisabled}
                          // error={errorState.mandatory.title}
                          margin="dense"
                          name={'name'}
                          variant="outlined"
                          className={classes.root}
                          helperText={touched.name && errors.name ? errors.name : ''}
                          value={values.name}
                          error={Boolean(touched.name && errors.name)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={Translation('app.input.placeholder.please-enter')}
                          inputProps={{ maxLength: 30 }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.sectionMargin}>
                    <div className={classes.rowContainer}>
                      <div className={classes.fieldContainer}>
                        <span className={classes.field}>
                          {Translation('component.formLabel.type')}
                          <span className={classes.mandatory}>*</span> :
                        </span>
                      </div>
                      <div style={{ flexGrow: 1 }}>
                        <TextField
                          select
                          margin="dense"
                          variant="outlined"
                          name={'type'}
                          value={values.type}
                          className={classes.root}
                          disabled={isDisabled}
                          required
                          helperText={touched.type && errors.type ? errors.type : ''}
                          error={Boolean(touched.type && errors.type)}
                          onChange={(e) => {
                            setFieldValue(`templateId`, '');
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          fullWidth
                          placeholder={Translation('app.input.placeholder.please-select')}
                        >
                          {dropdownOptions['rType'].map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.displayName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                  </div>
                  <div className={classes.sectionMargin}>
                    <div className={classes.rowContainer}>
                      <div className={classes.fieldContainer}>
                        <span className={classes.field}>
                          {Translation('component.formLabel.template')}
                          <span className={classes.mandatory}>*</span> :
                        </span>
                      </div>
                      <div style={{ flexGrow: 1 }}>
                        <TextField
                          select
                          margin="dense"
                          variant="outlined"
                          name={'templateId'}
                          value={values.templateId}
                          className={classes.root}
                          disabled={isDisabled}
                          required
                          helperText={errors.templateId ? errors.templateId : ''}
                          error={Boolean(errors.templateId)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          placeholder={Translation('app.input.placeholder.please-select')}
                        >
                          {values.type === 'GP' ? (
                            Object.keys(EVENT_GP_TEMPLATE_CONFIG).map((key) => (
                              <MenuItem key={key} value={EVENT_GP_TEMPLATE_CONFIG[key]?.id || ''} onClick={() => {}}>
                                {EVENT_GP_TEMPLATE_CONFIG[key]?.label || ''}
                              </MenuItem>
                            ))
                          ) : values.type === 'BOP' ? (
                            Object.keys(EVENT_BOP_TEMPLATE_CONFIG).map((key) => (
                              <MenuItem key={key} value={EVENT_BOP_TEMPLATE_CONFIG[key]?.id || ''} onClick={() => {}}>
                                {EVENT_BOP_TEMPLATE_CONFIG[key]?.label || ''}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value={''} key={''}></MenuItem>
                          )}
                        </TextField>
                      </div>
                    </div>
                  </div>
                  <div className={classes.sectionMargin}>
                    <div className={classes.rowContainer}>
                      <div className={classes.fieldContainer}>
                        <span className={classes.field}>
                          {Translation('component.formLabel.category')}
                          <span className={classes.mandatory}>*</span> :
                        </span>
                      </div>
                      <div style={{ flexGrow: 1 }}>
                        <TextField
                          select
                          margin="dense"
                          variant="outlined"
                          name={'category'}
                          className={classes.root}
                          value={values.categoryId}
                          required
                          helperText={touched.category && errors.category ? errors.category : ''}
                          disabled={isDisabled}
                          error={Boolean(touched.category && errors.category)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          placeholder={Translation('app.input.placeholder.please-select')}
                        >
                          {categoryList?.map((item) => (
                            <MenuItem key={item._id} value={item._id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                  </div>
                  <div className={classes.sectionMargin}>
                    <div className={classes.rowContainer} style={{ marginBottom: 5 }}>
                      <div className={classes.fieldContainer}>
                        <span className={classes.field}>
                          {Translation('component.formLabel.image')}
                          <span className={classes.mandatory}>*</span> :
                        </span>
                      </div>
                      <div style={{ flexGrow: 1 }}>
                        <FileUploader
                          value={values.image}
                          color="secondary"
                          disabled={isDisabled}
                          showAllowText={Translation('component.resource.bdm-background-image-hint')}
                          upload={handleUploadFile}
                          allowedFileTypes={['jpg', 'png', 'jpeg']}
                          maxFileSize={20}
                          showPreview
                          // needCompressImage={true}
                          onChange={(result) => setFieldValue(`image`, result)}
                        />
                        {touched.image && errors.image && <div className={classes.errorText}>{errors.image}</div>}
                      </div>
                    </div>
                  </div>

                  <div className={classes.sectionMargin}>
                    <TextColorList
                      isDisabled={isDisabled}
                      setValue={setFieldValue}
                      initValue={values.textColorList}
                      prefixName={`textColorList`}
                      actionType={BdmBackgroundImageFormMode.EDIT}
                    ></TextColorList>
                  </div>

                  <div className={classes.footerContainer}>
                    {isDisabled ? (
                      <></>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="inherit"
                          onClick={() =>
                            onRouteTo(`${SALESKIT_BASE_PATH}${BDM_TEMPLETE_BASE_PATH}${BDM_BACKGROUND_BASE_PATH}/list`)
                          }
                        >
                          Cancer
                        </Button>
                        <Button
                          style={{ marginLeft: 25 }}
                          variant="contained"
                          disabled={isLoading}
                          type="submit"
                          color="secondary"
                          // onClick={() => onSubmitForm(PublishStatus.PUBLISHED)}
                        >
                          Submit
                        </Button>
                      </>
                    )}
                  </div>

                  {/* <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  // disabled={!isValid || values.items.length === 0}
                >
                  submit
                </Button> */}
                </Form>
              )}
            </Formik>
          ) : (
            <Formik
              initialValues={_getInitialValues()}
              enableReinitialize
              innerRef={formikRef}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                onSubmitForm(values);
              }}
            >
              {({ values, touched, errors, handleChange, handleBlur, isValid, setFieldValue }) => (
                <Form noValidate autoComplete="off">
                  <FieldArray name="items">
                    {({ push, remove }) => (
                      <div>
                        {values.items.map(
                          (
                            p: { name: unknown; image: FileObj | undefined; textColorList: string[] },
                            index: React.Key | null | undefined,
                          ) => {
                            const name = `items[${index}].name`;
                            const touchedName = getIn(touched, name);
                            const errorName = getIn(errors, name);

                            const type = `items[${index}].type`;
                            const touchedType = getIn(touched, type);
                            const errorType = getIn(errors, type);

                            const templateId = `items[${index}].templateId`;
                            const touchedTemplateId = getIn(touched, templateId);
                            const errorTemplateId = getIn(errors, templateId);

                            const category = `items[${index}].categoryId`;
                            const touchedCategory = getIn(touched, category);
                            const errorCategory = getIn(errors, category);

                            const backgroundImage = `items[${index}].image`;
                            const touchedBackgroundImage = getIn(touched, backgroundImage);
                            const errorBackgroundImage = getIn(errors, backgroundImage);

                            return (
                              <div key={index}>
                                <div className={classes.sectionMargin}>
                                  <div className={classes.rowContainer}>
                                    <div className={classes.fieldContainer}>
                                      <span className={classes.field}>
                                        {Translation('component.formLabel.name')}
                                        <span className={classes.mandatory}>*</span> :
                                      </span>
                                    </div>
                                    <div style={{ flexGrow: 1 }}>
                                      <TextField
                                        style={{ width: '100%' }}
                                        disabled={isDisabled}
                                        // error={errorState.mandatory.title}
                                        margin="dense"
                                        name={name}
                                        variant="outlined"
                                        className={classes.root}
                                        helperText={touchedName && errorName ? errorName : ''}
                                        value={p.name}
                                        error={Boolean(touchedName && errorName)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        inputProps={{ maxLength: 30 }}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className={classes.sectionMargin}>
                                  <div className={classes.rowContainer}>
                                    <div className={classes.fieldContainer}>
                                      <span className={classes.field}>
                                        {Translation('component.formLabel.type')}
                                        <span className={classes.mandatory}>*</span> :
                                      </span>
                                    </div>
                                    <div style={{ flexGrow: 1 }}>
                                      <TextField
                                        select
                                        margin="dense"
                                        variant="outlined"
                                        name={type}
                                        className={classes.root}
                                        required
                                        helperText={touchedType && errorType ? errorType : ''}
                                        error={Boolean(touchedType && errorType)}
                                        onChange={(e) => {
                                          setFieldValue(`items[${index}].templateId`, '');
                                          handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        fullWidth
                                        placeholder={Translation('app.input.placeholder.please-select')}
                                      >
                                        {dropdownOptions['rType'].map((item) => (
                                          <MenuItem key={item.value} value={item.value}>
                                            {item.displayName}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </div>
                                  </div>
                                </div>

                                <div className={classes.sectionMargin}>
                                  <div className={classes.rowContainer}>
                                    <div className={classes.fieldContainer}>
                                      <span className={classes.field}>
                                        {Translation('component.formLabel.template')}
                                        <span className={classes.mandatory}>*</span> :
                                      </span>
                                    </div>
                                    <div style={{ flexGrow: 1 }}>
                                      <TextField
                                        select
                                        margin="dense"
                                        variant="outlined"
                                        name={templateId}
                                        className={classes.root}
                                        required
                                        helperText={touchedTemplateId && errorTemplateId ? errorTemplateId : ''}
                                        error={Boolean(touchedTemplateId && errorTemplateId)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        placeholder={Translation('app.input.placeholder.please-select')}
                                        disabled={!p.type}
                                        value={p.templateId}
                                      >
                                        {p.type === 'GP' ? (
                                          Object.keys(EVENT_GP_TEMPLATE_CONFIG).map((key) => (
                                            <MenuItem
                                              key={key}
                                              value={EVENT_GP_TEMPLATE_CONFIG[key]?.id || ''}
                                              onClick={() => {}}
                                            >
                                              {EVENT_GP_TEMPLATE_CONFIG[key]?.label || ''}
                                            </MenuItem>
                                          ))
                                        ) : p.type === 'BOP' ? (
                                          Object.keys(EVENT_BOP_TEMPLATE_CONFIG).map((key) => (
                                            <MenuItem
                                              key={key}
                                              value={EVENT_BOP_TEMPLATE_CONFIG[key]?.id || ''}
                                              onClick={() => {}}
                                            >
                                              {EVENT_BOP_TEMPLATE_CONFIG[key]?.label || ''}
                                            </MenuItem>
                                          ))
                                        ) : (
                                          <MenuItem value={''} key={''}></MenuItem>
                                        )}
                                      </TextField>
                                    </div>
                                  </div>
                                </div>

                                <div className={classes.sectionMargin}>
                                  <div className={classes.rowContainer}>
                                    <div className={classes.fieldContainer}>
                                      <span className={classes.field}>
                                        {Translation('component.formLabel.category')}
                                        <span className={classes.mandatory}>*</span> :
                                      </span>
                                    </div>
                                    <div style={{ flexGrow: 1 }}>
                                      <TextField
                                        select
                                        margin="dense"
                                        variant="outlined"
                                        name={category}
                                        className={classes.root}
                                        required
                                        helperText={touchedCategory && errorCategory ? errorCategory : ''}
                                        error={Boolean(touchedCategory && errorCategory)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        fullWidth
                                        placeholder={Translation('app.input.placeholder.please-select')}
                                      >
                                        {categoryList?.map((item) => (
                                          <MenuItem key={item._id} value={item._id}>
                                            {item.name}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </div>
                                  </div>
                                </div>
                                <div className={classes.sectionMargin}>
                                  <div className={classes.rowContainer} style={{ marginBottom: 5 }}>
                                    <div className={classes.fieldContainer}>
                                      <span className={classes.field}>
                                        {Translation('component.formLabel.image')}
                                        <span className={classes.mandatory}>*</span> :
                                      </span>
                                    </div>
                                    <div style={{ flexGrow: 1 }}>
                                      <FileUploader
                                        value={p.image}
                                        color="secondary"
                                        disabled={isDisabled}
                                        showAllowText={Translation('component.resource.bdm-background-image-hint')}
                                        upload={handleUploadFile}
                                        allowedFileTypes={['jpg', 'png', 'jpeg']}
                                        maxFileSize={20}
                                        showPreview
                                        // needCompressImage={true}
                                        onChange={(result) => setFieldValue(`items[${index}].image`, result)}
                                      />
                                      {touchedBackgroundImage && errorBackgroundImage && (
                                        <div className={classes.errorText}>{errorBackgroundImage}</div>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className={classes.sectionMargin}>
                                  <TextColorList
                                    isDisabled={isDisabled}
                                    setValue={setFieldValue}
                                    initValue={p.textColorList}
                                    prefixName={`items[${index}]`}
                                    actionType={BdmBackgroundImageFormMode.CREATE}
                                  ></TextColorList>
                                </div>

                                {index > 0 && (
                                  <div className={classes.sectionMargin}>
                                    <div className={classes.rowContainer}>
                                      <div className={classes.fieldContainer}></div>
                                      <div style={{ flexGrow: 1 }}>
                                        <span className={classes.deleteButton} onClick={() => remove(index)}>
                                          Delete record
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          },
                        )}
                        <div className={classes.sectionMargin}>
                          <div className={classes.rowContainer}>
                            <div className={classes.fieldContainer}></div>
                            <div style={{ flexGrow: 1 }}>
                              <Button
                                type="button"
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                  push({
                                    name: '',
                                    type: '',
                                    templateId: '',
                                    categoryId: '',
                                    image: undefined,
                                    textColorList: [defaultColor],
                                  })
                                }
                              >
                                <Add />
                                Add New Record
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </FieldArray>

                  <div className={classes.footerContainer}>
                    {isDisabled ? (
                      <></>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="inherit"
                          onClick={() =>
                            onRouteTo(`${SALESKIT_BASE_PATH}${BDM_TEMPLETE_BASE_PATH}${BDM_BACKGROUND_BASE_PATH}/list`)
                          }
                        >
                          Cancel
                        </Button>
                        <Button
                          style={{ marginLeft: 25 }}
                          variant="contained"
                          disabled={isLoading}
                          type="submit"
                          color="secondary"
                          // onClick={() => onSubmitForm(PublishStatus.PUBLISHED)}
                        >
                          Submit
                        </Button>
                      </>
                    )}
                  </div>

                  {/* <Button
                 type="submit"
                 color="primary"
                 variant="contained"
                 // disabled={!isValid || values.items.length === 0}
               >
                 submit
               </Button> */}
                </Form>
              )}
            </Formik>
          )}
        </div>

        {/* <div className={classes.footerContainer}>
          {isDisabled ? (
            <></>
          ) : (
            <>
                 <Button
            variant="contained"
            color="inherit"
            onClick={() =>
              onRouteTo(
                `${SALESKIT_BASE_PATH}${BDM_TEMPLETE_BASE_PATH}${BDM_BACKGROUND_BASE_PATH}/list`
              )
            }
          >
            Cancer
          </Button>
              <Button
                style={{ marginLeft: 25 }}
                variant="contained"
                disabled={isLoading}
                color="secondary"
                onClick={() => onSubmitForm(PublishStatus.PUBLISHED)}
              >
                Submit
              </Button>
            </>
          )}
        </div> */}
      </div>
    </>
  );
};

export default BdmBackgroundImageDetailForm;
