import { forEach } from 'lodash';
import { PaginateListMore } from '../../../common/types/common-types';
import { apiClient, apiErrorHandler } from 'src/app/common/network';
import { Dispatch } from 'react';
import { ReportListParams, ReportItem } from '../types/incentive-opening-report-types';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

const formatDataFilters = (params: ReportListParams) => {
  let data: any = {
    page: params.page,
    limit: params.limit,
  };
  forEach(params, (param, key) => {
    if (param) {
      switch (key) {
        case 'requestedDateFrom':
          data.requestedDateFrom = param;
          break;
        case 'requestedDateTo':
          data.requestedDateTo = param;
          break;
        case 'agentCode':
          data.agentCode = param;
          break;
        case 'unitCode':
          data.unitCode = param;
          break;
        case 'branchCode':
          data.branchCode = param;
          break;
        case 'officeCode':
          data.officeCode = param;
          break;
      }
    }
  });
  return data;
};

export const fetchReportDownload = (params: ReportListParams, dispatch?: Dispatch<any>): void => {
  const uri = `${ADMIN_URL}/report/incentive-opening/export`;
  const data = formatDataFilters(params);

  apiClient
    .post<Blob>(uri, data, { responseType: 'text' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report-incentive-opening-' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchIncentiveOpeningReport = async (
  params: ReportListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<ReportItem>> => {
  const uri = `${ADMIN_URL}/report/incentive-opening`;
  const data = formatDataFilters(params);
  return apiClient
    .post<PaginateListMore<ReportItem>>(uri, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
