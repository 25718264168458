import React, { FC } from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import PruRoute from '../../../../../common/components/PruRoute';
import ActiveUserListingPage from './List/ActiveUserListingPage';
// import AgentsReportPage from './Create/AgentsReportPage';
import ParamsProvider from '../../../../../common/components/ParamsProvider';
import { reportFilterKeys } from '../../../constants';

const ActiveUserPage: FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Redirect exact from={match.path} to={`${match.path}/list`}/>
      <PruRoute
        exact
        path={`${match.path}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={reportFilterKeys}>
            <ActiveUserListingPage />
          </ParamsProvider>
        )}
      />
    </Switch>
  )
};

export default ActiveUserPage;
