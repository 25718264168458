import React, { FC, useState, useEffect } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  fetchAgentLeaderSurveyResults,
  fetchAgentLeaderSurveySummary,
} from 'src/app/modules/Survey/network/surveyCrud';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { SurveyResult, SurveySummaryResult } from 'src/app/modules/Survey/types/survey-types';
import { LayoutSplashScreen } from 'src/app/layout';
import AgentLeaderSurveyAnalyticSection from './component/AgentLeaderSurveyAnalyticSection';

const AgentLeaderSurveyAnalyticPage: FC<RouteComponentProps> = ({ history }) => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [surveyResults, setSurveyResults] = useState<SurveyResult>();
  const [surveySummaries, setSurveySummary] = useState<SurveySummaryResult>();

  const reloadData = async () => {
    try {
      if (id) {
        setIsLoading(true);
        const surveyResult = await fetchAgentLeaderSurveyResults(id, dispatch);
        const surveySummary = await fetchAgentLeaderSurveySummary(id, dispatch);

        setSurveyResults(surveyResult);
        setSurveySummary(surveySummary);
      }
    } catch (e) {
      history.push(NOT_FOUND_ERROR_PAGE_PATH);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {isLoading || !surveyResults || !surveySummaries ? (
        <LayoutSplashScreen />
      ) : (
        <AgentLeaderSurveyAnalyticSection results={surveyResults} summaries={surveySummaries} />
      )}
    </>
  );
};

export default AgentLeaderSurveyAnalyticPage;
