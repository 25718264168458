import React, { FC, useState } from 'react';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { getDayStartUTC, getDayEndUTC, getDayEnd } from '../../../../../common/utils/time-utils';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { PaginateListMore } from 'src/app/common/types/common-types';
import { useLang } from 'src/app/i18n';
import moment from 'moment';
import { CandidateManagementReportListParams } from '../../../types/candidateManagementReportTypes';
import {
  fetchCandidateManagementReport,
  fetchCandidateManagementReportDownload,
} from '../../../network/candidateManagementReportCrud';

type CandidateManagementReportProps = {} & ParamsProps;

const initialState: CandidateManagementReportListParams = {
  requestedDateFrom: moment(getDayStartUTC(new Date()).setMonth(getDayStartUTC(new Date()).getMonth() - 6)).toDate(),
  requestedDateTo: getDayEnd(new Date()),
  eventName: '',
  type: 'candidate',
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): CandidateManagementReportListParams => {
  return initialParams
    ? {
        requestedDateFrom: initialParams.requestedDateFrom
          ? moment(getDayStartUTC(new Date()).setMonth(getDayStartUTC(new Date()).getMonth() - 6)).toDate()
          : null,
        requestedDateTo: initialParams.requestedDateTo ? getDayEndUTC(new Date()) : null,
        eventName: initialParams.eventName ? initialParams.eventName : '',
        page: 1,
        type: 'candidate',
        limit: 5,
      }
    : initialState;
};

const EventManagementCandidate: FC<CandidateManagementReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilterValue = paramsInitiator(initialParams);

  const currentLanguage = useLang();

  const [filterState, setFilterState] = useState<CandidateManagementReportListParams>(defaultFilterValue);
  const [reportList, setReportList] = useState<PaginateListMore<any>>();
  const { isLoading, refreshData } = useDataProvider<PaginateListMore<any>>(
    async () => {
      try {
        return fetchCandidateManagementReport(filterState);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvtDownload = () => {
    try {
      fetchCandidateManagementReportDownload(filterState, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('report.candidate.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'eventName',
            initialValue: filterState.eventName,
            displayName: Translation('report.candidate.filter.event'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'requestedDateFrom',
            fieldTo: 'requestedDateTo',
            initialDateFrom: filterState.requestedDateFrom,
            initialDateTo: filterState.requestedDateTo,
            defaultDateFrom: defaultFilterValue.requestedDateFrom,
            defaultDateTo: defaultFilterValue.requestedDateTo,
            displayName: Translation('report.candidate.filter.eventDate'),
          },
        ]}
        onChangeFilter={(data) => {
          setFilterState({
            ...filterState,
            eventName: data.eventName,
            requestedDateFrom:
              !data.requestedDateFrom && !data.requestedDateTo
                ? moment(getDayEndUTC(new Date()).setMonth(getDayStartUTC(new Date()).getMonth() - 6)).toDate()
                : !data.requestedDateFrom && data.requestedDateTo
                ? moment(
                    getDayStartUTC(new Date(data.requestedDateTo)).setMonth(
                      getDayStartUTC(new Date(data.requestedDateTo)).getMonth() - 6,
                    ),
                  ).toDate()
                : data.requestedDateFrom,
            requestedDateTo:
              !data.requestedDateFrom && !data.requestedDateTo
                ? getDayEndUTC(new Date())
                : data.requestedDateFrom && !data.requestedDateTo
                ? moment(
                    getDayEndUTC(new Date(data.requestedDateFrom)).setMonth(
                      getDayEndUTC(new Date(data.requestedDateFrom)).getMonth() + 6,
                    ),
                  ).toDate()
                : data.requestedDateTo,
          });
        }}
        customDateRangeMonth={true}
        fetchData={refreshData}
      />

      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.candidate.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvtDownload(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            keyIndex: 'EventName',
            align: 'center',
            displayName: 'Event Name',
            renderData: (row) => row.materialName,
          },
          {
            keyIndex: 'CandidateName',
            align: 'center',
            displayName: 'Candidate Name',
            renderData: (row) => row.fullName,
          },
          {
            keyIndex: 'PhoneNumber',
            align: 'center',
            displayName: 'Phone number',
            renderData: (row) => (row.phone ? row.phone : '-'),
          },
          {
            keyIndex: 'AgentCode',
            align: 'center',
            displayName: 'Agent Code',
            renderData: (row) => (row.agentCode ? row.agentCode : '-'),
          },
          {
            keyIndex: 'AgentName',
            align: 'center',
            displayName: 'Agent Name',
            renderData: (row) => (row.agentName ? row.agentName : '-'),
          },
          {
            keyIndex: 'OfficeCode',
            align: 'center',
            displayName: 'Office Code',
            renderData: (row) => (row.agentOfficeCode ? row.agentOfficeCode : '-'),
          },
          {
            keyIndex: 'UnitCode',
            align: 'center',
            displayName: 'Unit Code',
            renderData: (row) => (row.agentUnitCode ? row.agentUnitCode : '-'),
          },
          {
            keyIndex: 'EventDateStart',
            align: 'center',
            displayName: 'Event Date Start',
            renderData: (row) =>
              row.materialEventStartDate ? moment(row.materialEventStartDate).format('DD/MM/YYYY') : '-',
          },
          {
            keyIndex: 'EventTimeStart',
            align: 'center',
            displayName: 'Event Time Start',
            renderData: (row) =>
              row.materialEventStartDate ? moment(row.materialEventStartDate).format('HH:mm:ss') : '-',
          },
          {
            keyIndex: 'EventDateEnd',
            align: 'center',
            displayName: 'Event Date End',
            renderData: (row) =>
              row.materialEventEndDate ? moment(row.materialEventEndDate).format('DD/MM/YYYY') : '-',
          },
          {
            keyIndex: 'EventTimeEnd',
            align: 'center',
            displayName: 'Event Time End',
            renderData: (row) => (row.materialEventEndDate ? moment(row.materialEventEndDate).format('HH:mm:ss') : '-'),
          },
          {
            keyIndex: 'RegisteredDate',
            align: 'center',
            displayName: 'Registered Date',
            renderData: (row) => (row.registeredDate ? moment(row.registeredDate).format('DD/MM/YYYY') : '-'),
          },
          {
            keyIndex: 'RegisteredTime',
            align: 'center',
            displayName: 'Registered Time',
            renderData: (row) => (row.registeredDate ? moment(row.registeredDate).format('HH:mm:ss') : '-'),
          },
          {
            keyIndex: 'CheckinDate',
            align: 'center',
            displayName: 'Check-in Date',
            renderData: (row) => (row.checkInDate ? moment(row.checkInDate).format('DD/MM/YYYY') : '-'),
          },
          {
            keyIndex: 'CheckinTime',
            align: 'center',
            displayName: 'Check-in Time',
            renderData: (row) => (row.checkInDate ? moment(row.checkInDate).format('HH:mm:ss') : '-'),
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.total}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default EventManagementCandidate;
