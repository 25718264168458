import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { getDisplayDate } from '../../../../../../common/utils/time-utils';
import { PaginateList } from '../../../../../../common/types/common-types';
import { useIntl } from 'react-intl';
import PruTable from '../../../../../../common/components/PruTable/PruTable';
import { useHistory } from 'react-router-dom';
import { MDRT_PASSPORT_BASE_PATH, MDRT_HUB_BASE_PATH } from 'src/app/modules/MdrtHub/constants';
import { MdrtPassportFormMode, MdrtPassportItem, PublishStatus } from '../../../../types/mdrt-passport-types';
import {
  deleteMdrtPassport,
  publishMdrtPassport,
  unpublishMdrtPassport,
} from 'src/app/modules/MdrtHub/network/mdrtPassportCrud';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';

type MdrtPassportListProps = {
  isLoading: boolean;
  mdrtPassportList?: PaginateList<MdrtPassportItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const MdrtPassportList: FC<MdrtPassportListProps> = ({ isLoading, mdrtPassportList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onPublish = async (item: MdrtPassportItem) => {
    try {
      await publishMdrtPassport(item.id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Published successfully - ${item.id}`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  const onUnpublish = async (item: MdrtPassportItem) => {
    try {
      await unpublishMdrtPassport(item.id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Unpublished successfully - ${item.id}`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  const onDelete = async (id: string) => {
    try {
      await deleteMdrtPassport(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Deleted successfully - ${id}`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  return (
    <>
      <ItemDeleteDialog
        key={`delete-sla-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
      />
      <PruTable
        title={Translation('mdrtHub.mdrtPassport.listing')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('mdrtHub.common.upload'),
            onClick: () => history.push(`${MDRT_HUB_BASE_PATH}${MDRT_PASSPORT_BASE_PATH}/upload`),
          },
          {
            color: 'primary',
            title: Translation('mdrtHub.common.add'),
            onClick: () =>
              history.push(`${MDRT_HUB_BASE_PATH}${MDRT_PASSPORT_BASE_PATH}/add`, {
                type: MdrtPassportFormMode.CREATE,
              }),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('mdrtHub.common.view'),
            tooltipText: 'View MdrtPassport Upload',
            onClick: (row) =>
              history.push(`${MDRT_HUB_BASE_PATH}${MDRT_PASSPORT_BASE_PATH}/edit/${row.id}`, {
                type: MdrtPassportFormMode.VIEW,
              }),
          },
          {
            title: Translation('mdrtHub.common.edit'),
            tooltipText: 'Edit MdrtPassport Upload',
            onClick: (row) =>
              history.push(`${MDRT_HUB_BASE_PATH}${MDRT_PASSPORT_BASE_PATH}/edit/${row.id}`, {
                type: MdrtPassportFormMode.EDIT,
              }),
            condition: (row) => row.status !== PublishStatus.PUBLISHED,
          },
          {
            title: Translation('mdrtHub.common.publish'),
            tooltipText: 'Publish MdrtPassport Upload',
            onClick: (row) => onPublish(row),
            condition: (row) => row.status !== PublishStatus.PUBLISHED,
          },
          {
            title: Translation('mdrtHub.common.unpublish'),
            tooltipText: 'Unpublish MdrtPassport Upload',
            onClick: (row) => onUnpublish(row),
            condition: (row) => row.status === PublishStatus.PUBLISHED,
          },
          {
            title: Translation('mdrtHub.common.delete'),
            tooltipText: 'Delete MdrtPassport Upload',
            onClick: (row) => setDialogState({ open: true, id: row.id }),
            condition: (row) => row.status !== PublishStatus.PUBLISHED,
          },
        ]}
        columnDef={[
          {
            keyIndex: 'agentCode',
            displayName: Translation('mdrtHub.mdrtPassport.list.agentCode'),
            renderData: (row) => row.agentCode,
          },
          {
            keyIndex: 'lastUpdatedBy',
            align: 'center',
            displayName: Translation('mdrtHub.mdrtPassport.list.lastUpdatedBy'),
            renderData: (row) => row.lastUpdatedBy?.toString() || '',
          },
          {
            keyIndex: 'lastUpdatedDate',
            align: 'center',
            displayName: Translation('mdrtHub.achievementUpload.lastUpdatedDate'),
            renderData: (row) => getDisplayDate(new Date(row.updatedAt), 'YYYY-MM-DD HH:mm'),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={mdrtPassportList?.items}
        totalPages={mdrtPassportList?.totalPages}
        totalRecords={mdrtPassportList?.total}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default MdrtPassportList;
