export enum PublishStatus {
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED"
}

export enum QuotesAndCertificateFormMode {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
};

export type QuotesAndCertificateItem = {
  id: string
  agentCode: string
  quotes: string
  status: PublishStatus
  createdAt: string
  updatedAt: string
  lastUpdatedBy: string
  certificate: FileUpload[]
};

export interface FileUpload {
  url?: string;
  id: string;
  fileName: string;
  inclusive?: boolean,
  receiverType?: string,
  contentType?:string
  fileType: string
}

export type QuotesAndCertificateDetail = {
  id?: string
  agentCode: string
  quotes: string
  certificate?: FileUpload[]
  status?: string
}

export type QuotesAndCertificateFormState = {
  id?: string | undefined
  agentCode: string | undefined
  quotes: string | undefined
  certificate: FileUpload[]
}