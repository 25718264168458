import { Dispatch } from 'react';
import { I18nGenericData } from 'src/app/i18n';
import { getQueryPath } from 'src/app/common/utils';
import { AttachmentDef, PaginateList } from 'src/app/common/types';
import { ADMIN_URL, apiClient, apiErrorHandler } from 'src/app/common/network';
import { TargetApplicantRadioModeConfig } from 'src/app/common/components/target-applicant-section-v2';
import {
  EventTypeEnum,
  SessionEnrollmentEnum,
  EventPublishStatusEnum,
  RegistrationMethodEnum,
  RegistrationNotificationMethodEnum,
  RegistrationAttendanceRuleEnum,
  PublishAgentEnum,
  AgentForceEnrolEnum,
  EventItem,
  EventTagItem,
  EventSessionItem,
  EventConfirmationBodyItem,
  EventShortenCodeUrlItem,
} from '../types';
import { eventEndpoint, eventTagEndpoint } from './constants';
import { ITypeOfView } from 'src/app/common/types/view-types';

export type EventListParam = {
  search: string;
  category: string;
  accessibility: string;
  onlyHost?: string;
  eventDate: Date | null;
  createdBy?: string;
  lastUpdatedBy?: string;
  eventStatus: string;
  publishStatus?: string;
  page: number;
  limit: number;
  sort?: string;
};

export type EventTagListParam = {
  locale: string;
  pagination: boolean;
};

export type CreateUpdateEventBody = {
  /* Basics starts */
  category?: string;
  shareToPublic?: boolean | null;
  agentRequired?: boolean | null;
  type?: EventTypeEnum;
  eventDate?: Date | null;
  startTime?: Date | null;
  endTime?: Date | null;
  organizer?: I18nGenericData<string>;
  name?: I18nGenericData<string>;
  venue?: I18nGenericData<string>;
  tags?: I18nGenericData<string[]>;
  multiSession?: boolean;
  sessions?: EventSessionItem[];
  sessionEnrollment?: SessionEnrollmentEnum;
  quota?: string;
  publishStatus?: EventPublishStatusEnum;
  /* Basics ends */

  /* Details starts */
  eventImage?: I18nGenericData<AttachmentDef>;
  description?: I18nGenericData<string>;
  attachments?: I18nGenericData<AttachmentDef[]>;
  /* Details ends */

  /* Registration & Attendance starts */
  regQuotaPerAgent?: number | null;
  regStartDate?: Date | null;
  regEndDate?: Date | null;
  regMethod?: RegistrationMethodEnum;
  regLink?: string;
  regNotificationMethod?: RegistrationNotificationMethodEnum;
  regConfirmationBody?: EventConfirmationBodyItem;
  regAttendanceRule?: RegistrationAttendanceRuleEnum;
  regAllowWalkIn?: boolean | null;
  /* Registration & Attendance ends */

  /* Registration Form starts */
  regFormBody?: Record<string, any>;
  /* Registration Form ends */

  /* Walk in Form starts */
  walkInFormBody?: Record<string, any>;
  /* Walk in Form ends */

  /* Publish starts */
  pubAgent?: PublishAgentEnum;
  pubAgentConfig?: TargetApplicantRadioModeConfig;
  agentForceEnrol?: AgentForceEnrolEnum;
  agentForceEnrolConfig?: TargetApplicantRadioModeConfig;
  typeOfViews?: ITypeOfView[];
  /* Publish ends */
};

// const ADMIN_URL = 'http://localhost:1111'

export const fetchEventList = async (
  params: EventListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<EventItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/${eventEndpoint}?`, params);
  return apiClient
    .get<PaginateList<EventItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchEventTagList = async (
  params: EventTagListParam,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<EventTagItem>> => {
  const queryPath = getQueryPath(`${ADMIN_URL}/${eventTagEndpoint}?`, params);
  return apiClient
    .get<PaginateList<EventTagItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchEventItem = async (id: string, dispatch?: Dispatch<any>): Promise<EventItem> => {
  return apiClient
    .get<EventItem>(`${ADMIN_URL}/${eventEndpoint}/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchEventShortenCodeUrl = async (
  id: string,
  dispatch?: Dispatch<any>,
): Promise<EventShortenCodeUrlItem> => {
  return apiClient
    .get<EventShortenCodeUrlItem>(`${ADMIN_URL}/${eventEndpoint}/${id}/shorten-code-url`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const createEventItem = async (body: CreateUpdateEventBody, dispatch?: Dispatch<any>): Promise<EventItem> => {
  return apiClient
    .post<EventItem>(`${ADMIN_URL}/${eventEndpoint}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyEventItem = async (
  id: string,
  body: CreateUpdateEventBody,
  dispatch?: Dispatch<any>,
): Promise<EventItem> => {
  return apiClient
    .patch<EventItem>(`${ADMIN_URL}/${eventEndpoint}/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const cloneEventItem = async (id: string, dispatch?: Dispatch<any>): Promise<EventItem> => {
  return apiClient
    .post<EventItem>(`${ADMIN_URL}/${eventEndpoint}/${id}/clone`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
