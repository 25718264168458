export enum PublishStatus {
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED"
}

export enum MdrtStampIconFormMode {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
};

export type MdrtStampIconItem = {
  id: string
  stampCode: string
  stampName: string
  status: PublishStatus
  createdAt: string
  updatedAt: string
  lastUpdatedBy: string
};

export interface FileUpload {
  url?: string;
  id: string;
  fileName: string;
  inclusive?: boolean,
  receiverType?: string,
  contentType?:string
  fileType: string
}

export type MdrtStampIconDetail = {
  id?: string
  stampCode: string
  stampName: string
  icon?: FileUpload
  status?: string
}

export type MdrtStampIconFormState = {
  itemId?: string | undefined
  stampCode: string | undefined
  stampName: string | undefined
  icon: FileUpload | undefined
}
