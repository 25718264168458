export const MDRT_BASE_PATH = '/mdrt';
export const MDRT_NAMESPACE = "MDRT";

export const DATE_ERROR_TEXT = 'End date must be after Begin date';

export const MANDATORY_FIELD_ERROR_TEXT = 'This field is mandatory';
export const CHOOSE_ONE_ERROR_TEXT = 'One of the fields must be filled';

export const DATE_FORMAT_ERROR_TEXT = 'Invalid date format';
//  These constants map to aside menu, header menu to display title
export const MDRT_TITLE = "mdrt.title";

export enum AdminMdrtPermissionType {
  MDRT_READ = "READ_ANY_MDRT",
  MDRT_CREATE = "CREATE_ANY_MDRT",
  MDRT_UPDATE = "UPDATE_ANY_MDRT"
};

export const supportLangList = ['vi', 'en'];

export const mainLang = 'vi';
export const subLang = 'en';


