import { Button, FormControl, MenuItem, Select, TextField } from '@mui/material';
import moment from 'moment';
import React, { CSSProperties, FC, Fragment, useEffect, useMemo, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { PruDatePicker, PruDateTimePicker } from 'src/app/common/components/PruDatePicker';
import { DATE_ERROR_TEXT } from '../../constants';
import { useCommonStyles } from '../../styles/common-styles';
import useFilterStyles from '../../styles/filter-styles';
import { ErrorFieldDef, ErrorFieldType, getDayEnd, useErrorHandler } from '../../utils';
import AsyncAutocomplete from '../AsyncAutocomplete';
import { ParamsProps } from '../ParamsProvider';

// PVA
import { makeStyles } from 'tss-react/mui';
import usePopUp from '../Criteria/usePopUp';
import { MANDATORY_FIELD_ERROR_TEXT, OVER_PERIOD_TIME } from '../../constants';
import { getDayStart, toAbsoluteUrl } from '../../utils';
import { get, reduce } from 'lodash';
import { Checkbox } from '@mui/material';
import { ListItemText, InputLabel } from '@mui/material';

// PVA
const useStyles = makeStyles()(() => ({
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1040,
    width: '100vw',
    height: '100vh',
    background: '#000',
    opacity: '0.5',
  },
  modalWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1050,
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    outline: 0,
    justifyContent: 'center',
    alignItem: 'center',
    display: 'flex',
  },
  modal: {
    zIndex: 100,
    background: 'white',
    position: 'relative',
    margin: '16.75rem auto',
    borderRadius: '3px',
    padding: '1.5rem',
    width: '700px',
    maxHeight: '400px',
    overflowY: 'scroll',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  modalCloseButton: {
    fontSize: '2.4rem',
    fontWeight: 700,
    lineHeight: 1,
    cursor: 'pointer',
    border: 'none',
    background: '#DE1127',
    marginBottom: '10px',
    borderRadius: '10px',
    height: '40px',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100px',
  },
  close: {
    cursor: 'pointer',
    fontSize: 10,
  },
  titleHeader: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export enum PruFilterItemType {
  FREE_TEXT = 'FREE_TEXT',
  DATE_RANGE = 'DATE_RANGE',
  DATE_TIME_RANGE = 'DATE_TIME_RANGE',
  DROPDOWN = 'DROPDOWN',
  MULTIPLE_DROPDOWN = 'MULTIPLE_DROPDOWN',
  ASYNC_DROPDOWN = 'ASYNC_DROPDOWN',
  ASYNC_MULTIPLE_DROPDOWN = 'ASYNC_MULTIPLE_DROPDOWN',
  DATE = 'DATE',

  // PVA
  DATE_MONTH = 'DATE_MONTH',
  MULTIPLE_SELECT = 'MULTIPLE_SELECT',
  CRITERIA = 'CRITERIA',
  DATE_TYPE_RANGE = 'DATE_TYPE_RANGE',
}

export enum PruFilterDateType {
  YTD = 'YTD',
  Last3Months = 'Last 3 Months',
  Last30Days = 'Last 30 days',
  Custom = 'Customised',
}

const ITEM_LENGTH = {
  [PruFilterItemType.FREE_TEXT]: 1,
  [PruFilterItemType.DATE_RANGE]: 2,
  [PruFilterItemType.DATE_TIME_RANGE]: 2,
  [PruFilterItemType.DROPDOWN]: 1,
  [PruFilterItemType.MULTIPLE_DROPDOWN]: 1,
  [PruFilterItemType.ASYNC_DROPDOWN]: 1,
  [PruFilterItemType.ASYNC_MULTIPLE_DROPDOWN]: 1,
  [PruFilterItemType.DATE]: 1,
  [PruFilterItemType.DATE_TYPE_RANGE]: 2,

  // PVA
  [PruFilterItemType.DATE_MONTH]: 1,
  [PruFilterItemType.MULTIPLE_SELECT]: 1,
  [PruFilterItemType.CRITERIA]: 1,
};

// PVA
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  [PruFilterItemType.DATE_TYPE_RANGE]: 2,
};

type PruFilterProps = {
  title: string;
  titleClassName?: string;
  filterClassName?: string;
  style?: CSSProperties;
  disableReset?: boolean;
  disableKeydownEvent?: boolean;
  updateFilterOnChange?: boolean;
  enableBack?: boolean;
  itemDef: PruFilterItemDef[];
  onChangeFilter: (filterState: PruFilterState) => void;
  fetchData: () => void;
  onBack?: () => void;
  downloadExcel?: () => void;

  // PVA
  customDateRangeMonth?: boolean;
  setCriterias?: any;
  isClearDateRange?: boolean;
} & ParamsProps;

export type PruFilterFreeTextDef = {
  type: PruFilterItemType.FREE_TEXT;
  style?: CSSProperties;
  field: string;
  displayName: string;
  initialValue?: string;
  defaultValue?: string;
  enableIfExist?: string[];
  length?: number;

  // PVA
  disabled?: boolean;
  required?: boolean;
  maxLength?: number;
};

export type PruFilterDateRangeDef = {
  type: PruFilterItemType.DATE_RANGE;
  fieldFrom: string;
  fieldTo: string;
  displayName: string;
  initialDateFrom?: Date | null;
  initialDateTo?: Date | null;
  defaultDateFrom?: Date | null;
  defaultDateTo?: Date | null;
  enableIfExist?: string[];
  length?: number;

  // PVA
  required?: boolean;
  disabled?: boolean;
  isRequired?: boolean;
  periodTime?: PruFilterDateRangePeriodTime;
  errorText?: string;
};

// PVA
export type PruFilterDateRangePeriodTime = {
  minute?: number;
  minutes?: number;
  hour?: number;
  hours?: number;
  day?: number;
  days?: number;
  month?: number;
  months?: number;
};

export type PruFilterDateTimeRangeDef = {
  type: PruFilterItemType.DATE_TIME_RANGE;
  fieldFrom: string;
  fieldTo: string;
  displayName: string;
  initialDateFrom?: Date | null;
  initialDateTo?: Date | null;
  defaultDateFrom?: Date | null;
  defaultDateTo?: Date | null;
  enableIfExist?: string[];
  length?: number;

  // PVA
  required?: boolean;
  disabled?: boolean;
  isRequired?: boolean;
  periodTime?: PruFilterDateRangePeriodTime;
  errorText?: string;
};
export type PruFilterDateTypeRangeDef = {
  defaultDateFrom?: any;
  initialDateFrom?: any;
  type: PruFilterItemType.DATE_TYPE_RANGE;
  displayName: string;
  enableIfExist?: string[];
  initialValue?: string;
  defaultValue?: string;
  fieldFrom: string;
  fieldTo: string;
  length?: number;
  defaultDateType?: PruFilterDateType;
  dateType: string;

  // PVA
  required?: boolean;
  periodTime?: PruFilterDateRangePeriodTime;
};

export type PruFilterDropdownItem = {
  displayName: string;
  value: string;
};

export type PruFilterDropdownDef = {
  type: PruFilterItemType.DROPDOWN;
  style?: CSSProperties;
  field: string;
  displayName: string;
  initialValue?: string;
  defaultValue?: string;
  enableIfExist?: string[];
  choices: PruFilterDropdownItem[];
  length?: number;

  // PVA
  disabled?: boolean;
  required?: boolean;
};

export type PruFilterMultipleDropdownDef = {
  type: PruFilterItemType.MULTIPLE_DROPDOWN;
  style?: CSSProperties;
  field: string;
  displayName: string;
  initialValue?: string[];
  defaultValue?: string[];
  enableIfExist?: string[];
  choices: PruFilterDropdownItem[];
  length?: number;
};

// PVA
export type PruFilterMultipleSelectDef = {
  type: PruFilterItemType.MULTIPLE_SELECT;
  style?: CSSProperties;
  field: string;
  displayName: string;
  initialValue?: string[];
  defaultValue?: string[];
  enableIfExist?: string[];
  allValue?: string;
  choices: PruFilterDropdownItem[];
  disabled?: boolean;
  required?: boolean;
  length?: number;
};

export type PruFilterAsyncDropdownDef = {
  type: PruFilterItemType.ASYNC_DROPDOWN;
  style?: CSSProperties;
  field: string;
  displayName: string;
  initialValue?: string;
  defaultValue?: string;
  enableIfExist?: string[];
  initialOptions?: PruFilterDropdownItem[];
  fetchList: () => Promise<PruFilterDropdownItem[]>;
  length?: number;

  // PVA
  disabled?: boolean;
  required?: boolean;
};

// PVA
export type PruFilterDateMonthDef = {
  type: PruFilterItemType.DATE_MONTH;
  field: string;
  displayName: string;
  initialValue?: Date | null | string;
  defaultValue?: Date | null | string;
  enableIfExist?: string[];
  disabled?: boolean;
  required?: boolean;
  style?: CSSProperties;
  length?: number;
};

export type PruFilterAsyncMultipleDropdownDef = {
  type: PruFilterItemType.ASYNC_MULTIPLE_DROPDOWN;
  style?: CSSProperties;
  field: string;
  displayName: string;
  initialValue?: string[];
  defaultValue?: string[];
  enableIfExist?: string[];
  initialOptions?: PruFilterDropdownItem[];
  fetchList: () => Promise<PruFilterDropdownItem[]>;
  length?: number;
};

export type PruFilterDateDef = {
  type: PruFilterItemType.DATE;
  field: string;
  displayName: string;
  initialDate?: Date | null;
  defaultDate?: Date | null;
  enableIfExist?: string[];
  length?: number;

  // PVA
  disabled?: boolean;
};

// PVA
export type PruFilterCriteriaDef = {
  type: PruFilterItemType.CRITERIA;
  field: string;
  conditionArr: any[];
  length?: number;
};

export type PruFilterItemDef =
  | PruFilterFreeTextDef
  | PruFilterDateRangeDef
  | PruFilterDateTimeRangeDef
  | PruFilterDropdownDef
  | PruFilterMultipleDropdownDef
  | PruFilterAsyncDropdownDef
  | PruFilterDateDef
  | PruFilterAsyncMultipleDropdownDef
  | PruFilterDateTypeRangeDef
  // PVA
  | PruFilterDateMonthDef
  | PruFilterMultipleSelectDef
  | PruFilterCriteriaDef;

type PruFilterState = {
  [id: string]: any;
};

type ModifyFilterAction = {
  type: 'CHANGE_FILTER';
  payload: {
    field: keyof PruFilterState;
    // COE
    //value: Array<Date | null> | string | Date | null;

    // PVA
    value: Array<Date | null> | string | Date | null | string[];
  };
};

type ResetFilterAction = {
  type: 'RESET_FILTER';
  payload: {
    itemDef: PruFilterItemDef[];
  };
};

type PruFilterAction = ModifyFilterAction | ResetFilterAction;

const paramsInitiator = (mode: 'INIT' | 'DEFAULT', itemDef: PruFilterItemDef[]): PruFilterState => {
  const state: PruFilterState = {};
  itemDef.forEach((item) => {
    switch (item.type) {
      case PruFilterItemType.FREE_TEXT:
        state[item.field] = (mode === 'INIT' ? item.initialValue || item.defaultValue : item.defaultValue) || '';
        break;
      case PruFilterItemType.DATE_RANGE:
        // COE
        // state[item.fieldFrom] =
        //   (mode === 'INIT' ? item.initialDateFrom || item.defaultDateFrom : item.defaultDateFrom) || null;
        // state[item.fieldTo] = (mode === 'INIT' ? item.initialDateTo || item.defaultDateTo : item.defaultDateTo) || null;

        // PVA
        state[item.fieldFrom] =
          (mode === 'INIT' || mode === 'DEFAULT'
            ? item.initialDateFrom || item.defaultDateFrom
            : item.defaultDateFrom) || null;
        state[item.fieldTo] =
          (mode === 'INIT' || mode === 'DEFAULT' ? item.initialDateTo || item.defaultDateTo : item.defaultDateTo) ||
          null;

        break;
      case PruFilterItemType.DATE_TIME_RANGE:
        // COE
        // state[item.fieldFrom] =
        //   (mode === 'INIT' ? item.initialDateFrom || item.defaultDateFrom : item.defaultDateFrom) || null;
        // state[item.fieldTo] = (mode === 'INIT' ? item.initialDateTo || item.defaultDateTo : item.defaultDateTo) || null;

        // PVA
        state[item.fieldFrom] =
          (mode === 'INIT' ? item.initialDateFrom || item.defaultDateFrom : item.defaultDateFrom) || null;
        state[item.fieldTo] = (mode === 'INIT' ? item.initialDateTo || item.defaultDateTo : item.defaultDateTo) || null;

        break;
      case PruFilterItemType.DROPDOWN:
        state[item.field] = (mode === 'INIT' ? item.initialValue || item.defaultValue : item.defaultValue) || '';
        break;

      // PVA
      case PruFilterItemType.MULTIPLE_SELECT:
        state[item.field] = (mode === 'INIT' ? item.initialValue || item.defaultValue : item.defaultValue) || [];
        break;

      case PruFilterItemType.MULTIPLE_DROPDOWN:
        state[item.field] = (mode === 'INIT' ? item.initialValue || item.defaultValue : item.defaultValue) || [];
        break;
      case PruFilterItemType.ASYNC_DROPDOWN:
        state[item.field] = (mode === 'INIT' ? item.initialValue || item.defaultValue : item.defaultValue) || '';
        break;
      case PruFilterItemType.ASYNC_MULTIPLE_DROPDOWN:
        state[item.field] = (mode === 'INIT' ? item.initialValue || item.defaultValue : item.defaultValue) || [];
        break;
      case PruFilterItemType.DATE:
        state[item.field] = (mode === 'INIT' ? item.initialDate || item.defaultDate : item.defaultDate) || null;
        break;

      // PVA
      case PruFilterItemType.DATE_MONTH:
        state[item.field] = (mode === 'INIT' ? item.initialValue || item.defaultValue : item.defaultValue) || null;
        break;
      case PruFilterItemType.DATE:
        state[item.field] = (mode === 'INIT' ? item.initialDate || item.defaultDate : item.defaultDate) || null;
        break;
      case PruFilterItemType.CRITERIA:
        state['item.field'] = {};
        break;
      case PruFilterItemType.DATE_TYPE_RANGE:
        state[item.fieldFrom] =
          (mode === 'INIT' ? item.initialDateFrom || item.defaultDateFrom : item.defaultDateFrom) || null;
        state[item.fieldTo] =
          (mode === 'INIT' ? item.initialDateFrom || item.defaultDateFrom : item.defaultDateFrom) || null;
        state[item.dateType] = item.defaultDateType || PruFilterDateType.Custom;
        break;
    }
  });
  return state;
};

const filterReducer = (state: PruFilterState, action: PruFilterAction) => {
  switch (action.type) {
    case 'CHANGE_FILTER': {
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
    case 'RESET_FILTER': {
      return paramsInitiator('DEFAULT', action.payload.itemDef);
    }
  }
};

type ErrorState = {
  mandatory: Record<string, boolean>;
  immediate: Record<string, boolean>;
};

const PruFilter: FC<PruFilterProps> = ({
  title,
  titleClassName,
  filterClassName,
  style,
  itemDef,
  disableReset,
  disableKeydownEvent,
  updateFilterOnChange,
  enableBack,
  onChangeFilter,
  fetchData,
  onBack,
  downloadExcel,

  // PVA
  customDateRangeMonth,
  setCriterias,
  isClearDateRange = false,
}) => {
  const { classes: commonClasses, cx } = useCommonStyles();
  const { classes: filterClasses } = useFilterStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [filterState, filterDispatch] = useReducer(filterReducer, paramsInitiator('INIT', itemDef));
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [textDate, setTextDate] = useState<any>();

  // PVA
  const { isShowing, toggle } = usePopUp();
  const { classes } = useStyles();

  const [criteria, setCriteria] = useState<any>(
    (itemDef?.find((x) => x.type === 'CRITERIA') as PruFilterCriteriaDef)?.conditionArr,
  );

  const [selectedConditions, setSelectedConditions] = useState<any>([]);
  const [selectedApplyConditions, setSelectedApplyConditions] = useState<any>([]);

  const handleChangeCondition = (event: any) => {
    setCriteria(criteria.filter((x: any) => x.propName !== event.target.value.propName));

    setSelectedConditions([
      ...selectedConditions,
      {
        name: event.target.value.name,
        propName: event.target.value.propName,
        order: event.target.value.order,
      },
    ]);
  };

  const handleRemoveCondition = (index: number, condition: any) => {
    let data = [...selectedConditions];
    data.splice(index, 1);
    setSelectedConditions(data);
    setCriteria((prev: any) => {
      const sort = [...prev, condition].sort((a, b) => a.order - b.order);
      return sort;
    });
  };

  const handlClearAllCondition = () => {
    setSelectedConditions((prev: any) => []);
    setSelectedApplyConditions([]);
    setCriteria((prev: any) => (itemDef?.find((x) => x.type === 'CRITERIA') as PruFilterCriteriaDef)?.conditionArr);
    if (isClearDateRange) {
      itemDef.map((item: any) => {
        if (item.type === PruFilterItemType.DATE_RANGE.toString())
          filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldFrom, value: '' } });
        filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldTo, value: '' } });
      });
    }
  };

  const handleApply = async () => {
    setCriterias(
      await convertArrayToObject(
        selectedConditions.filter((item: any) => item.value),
        'propName',
      ),
    );
    setSelectedApplyConditions(selectedConditions);
    toggle();
  };

  const handleChangeInputSelectedCondition = (event: any, propsName: string, index: number) => {
    let data = [...selectedConditions];
    data.find((item) => item.propName === propsName).value = event.target.value;
    setSelectedConditions(data);
  };

  const convertArrayToObject = async (array: [], key: string) =>
    array.reduce(
      (obj: any, item: any) => ({
        ...obj,
        [item[key]]: item.value,
      }),
      {},
    );

  // End of PVA

  const errorDefinition: ErrorFieldDef[] = useMemo(() => {
    const errorDef: ErrorFieldDef[] = [];
    itemDef.forEach((item) => {
      if (
        item.type === PruFilterItemType.DATE_RANGE ||
        item.type === PruFilterItemType.DATE_TIME_RANGE ||
        item.type === PruFilterItemType.DATE_TYPE_RANGE
      ) {
        errorDef.push({
          name: `${item.fieldTo}Before${item.fieldFrom}`,
          fieldType: ErrorFieldType.IMMEDIATE,
          condition: () => {
            const startDate = filterState[item.fieldFrom];
            const endDate = filterState[item.fieldTo];
            if (startDate && endDate) {
              return !!moment(new Date(startDate)).isAfter(moment(new Date(endDate)));
            } else {
              return false;
            }
          },
        });

        // PVA
        if (item?.required) {
          errorDef.push({
            name: `${item.fieldTo}${item.fieldFrom}Required`,
            fieldType: ErrorFieldType.IMMEDIATE,
            condition: () => {
              return !filterState[item.fieldFrom] || !filterState[item.fieldTo];
            },
          });
        }

        if (item.periodTime) {
          errorDef.push({
            name: `${item.fieldFrom}${item.fieldTo}OverPeriodTime`,
            fieldType: ErrorFieldType.IMMEDIATE,
            condition: () => {
              const startDate = filterState[item.fieldFrom] ? moment(filterState[item.fieldFrom]).startOf('day') : null;
              const endDate = filterState[item.fieldTo] ? moment(filterState[item.fieldTo]).startOf('day') : null;

              if (item.periodTime && startDate && endDate) {
                Object.keys(item.periodTime).forEach((key) => {
                  startDate?.add(key, get(item.periodTime, key));
                });
              }

              return !!startDate && !!endDate && startDate.valueOf() < endDate.valueOf();
            },
          });
        }
      }

      // PVA
      if (
        item.type === PruFilterItemType.FREE_TEXT ||
        item.type === PruFilterItemType.DROPDOWN ||
        item.type === PruFilterItemType.MULTIPLE_SELECT ||
        item.type === PruFilterItemType.ASYNC_DROPDOWN ||
        item.type === PruFilterItemType.DATE_MONTH
      ) {
        if (item.required) {
          errorDef.push({
            name: `${item.displayName}Required`,
            fieldType: ErrorFieldType.IMMEDIATE,
            condition: () => !filterState[item.field],
          });
        }
        // End of PVA
      }
    });
    return errorDef;
  }, [itemDef, filterState]);

  const checkIfFilterEnable = (enableIfExist?: string[]) => {
    if (enableIfExist) {
      let enable = true;
      enableIfExist.forEach((item) => {
        if (filterState[item] === '' || filterState[item] === null || filterState[item] === undefined) {
          enable = false;
        }
      });
      return enable;
    } else {
      return true;
    }
  };

  const filterItemRender = (item: PruFilterItemDef) => {
    switch (item.type) {
      case PruFilterItemType.FREE_TEXT:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            {showDatePicker ? (
              textDate ? (
                <>
                  <PruDatePicker
                    disabled={!checkIfFilterEnable(item.enableIfExist)}
                    format="DD/MM/YYYY"
                    value={textDate.startDate}
                    onChange={(date) => {
                      if (textDate) {
                        setTextDate({
                          ...textDate,
                          startDate: date ? date : moment(new Date()).format('MM/DD/YYYY'),
                        });
                        filterDispatch({
                          type: 'CHANGE_FILTER',
                          payload: {
                            field: item.field,
                            value: [date, textDate.endDate],
                          },
                        });
                      } else {
                        filterDispatch({
                          type: 'CHANGE_FILTER',
                          payload: { field: item.field, value: date },
                        });
                      }
                    }}
                  />
                  <div className="PruFilter-date-divider" />
                  <PruDatePicker
                    disabled={!checkIfFilterEnable(item.enableIfExist)}
                    slotProps={{
                      textField: {
                        style: { marginRight: 20 },
                      },
                    }}
                    format="DD/MM/YYYY"
                    value={textDate.endDate}
                    onChange={(date) => {
                      setTextDate({
                        ...textDate,
                        endDate: date ? date : moment(new Date()).format('MM/DD/YYYY'),
                      });
                      filterDispatch({
                        type: 'CHANGE_FILTER',
                        payload: {
                          field: item.field,
                          value: [textDate.startDate, date],
                        },
                      });
                    }}
                  />
                </>
              ) : (
                <PruDatePicker
                  disabled={!checkIfFilterEnable(item.enableIfExist)}
                  format="DD/MM/YYYY"
                  value={filterState[item.field]}
                  onChange={(date) =>
                    filterDispatch({
                      type: 'CHANGE_FILTER',
                      payload: { field: item.field, value: date },
                    })
                  }
                />
              )
            ) : (
              <TextField
                disabled={!checkIfFilterEnable(item.enableIfExist)}
                style={{
                  ...item.style,
                  marginRight: 20,
                }}
                margin="dense"
                variant="outlined"
                value={filterState[item.field]}
                onChange={(e) =>
                  filterDispatch({
                    type: 'CHANGE_FILTER',
                    payload: { field: item.field, value: e.target.value },
                  })
                }
              />
            )}
          </>
        );
      case PruFilterItemType.DATE_RANGE:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <PruDatePicker
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              slotProps={{
                textField: {
                  // COE
                  // error: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`],

                  //PVA
                  error:
                    errorState.immediate[`${item.fieldFrom}${item.fieldTo}OverPeriodTime`] ||
                    errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`] ||
                    errorState.immediate[`${item.fieldTo}${item.fieldFrom}Required`],
                  helperText: errorState.immediate[`${item.fieldFrom}${item.fieldTo}OverPeriodTime`]
                    ? OVER_PERIOD_TIME.replace(
                        '[TIME]',
                        reduce(
                          Object.keys(get(item, 'periodTime', {})),
                          (result: string, key: string) => {
                            return result
                              ? `${result} ${get(item.periodTime, key)} ${key}`
                              : `${get(item.periodTime, key)} ${key}`;
                          },
                          '',
                        ),
                      )
                    : errorState.immediate[`${item.fieldTo}${item.fieldFrom}Required`] &&
                      !filterState[item.fieldFrom] &&
                      MANDATORY_FIELD_ERROR_TEXT,
                },
              }}
              format="DD/MM/YYYY"
              value={filterState[item.fieldFrom]}
              onChange={(date) =>
                filterDispatch({
                  type: 'CHANGE_FILTER',
                  payload: { field: item.fieldFrom, value: date },
                })
              }
              // PVA
              minDate={
                customDateRangeMonth && filterState[item.fieldTo]
                  ? moment(
                      getDayEnd(new Date(filterState[item.fieldTo])).setMonth(
                        getDayEnd(new Date(filterState[item.fieldTo])).getMonth() - 6,
                      ),
                    ).toDate()
                  : undefined
              }
            />
            <div className="PruFilter-date-divider" />
            <PruDatePicker
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              slotProps={{
                textField: {
                  // COE
                  // error: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`],
                  // helperText: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`] && DATE_ERROR_TEXT,
                  style: { marginRight: 20 },

                  // PVA
                  error:
                    errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`] ||
                    errorState.immediate[`${item.fieldTo}${item.fieldFrom}Required`] ||
                    item.errorText
                      ? true
                      : false,
                  helperText:
                    errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`] ||
                    errorState.immediate[`${item.fieldTo}${item.fieldFrom}Required`] ||
                    item.errorText
                      ? true
                      : false,
                },
              }}
              format="DD/MM/YYYY"
              value={filterState[item.fieldTo]}
              onChange={(date) =>
                filterDispatch({
                  type: 'CHANGE_FILTER',
                  payload: { field: item.fieldTo, value: date },
                })
              }
              // PVA
              maxDate={
                customDateRangeMonth && filterState[item.fieldFrom]
                  ? moment(
                      getDayEnd(new Date(filterState[item.fieldFrom])).setMonth(
                        getDayEnd(new Date(filterState[item.fieldFrom])).getMonth() + 6,
                      ),
                    ).toDate()
                  : undefined
              }
            />
          </>
        );
      case PruFilterItemType.DATE_TIME_RANGE:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <PruDateTimePicker
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              slotProps={{
                textField: {
                  error: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`],

                  // PVA
                  helperText: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`]
                    ? item.errorText
                      ? item.errorText
                      : DATE_ERROR_TEXT
                    : errorState.immediate[`${item.fieldTo}${item.fieldFrom}Required`] &&
                      !filterState[item.fieldFrom] &&
                      MANDATORY_FIELD_ERROR_TEXT,
                },
              }}
              ampm={false}
              format="DD/MM/YYYY HH:mm"
              value={filterState[item.fieldFrom]}
              onChange={(date) =>
                filterDispatch({
                  type: 'CHANGE_FILTER',
                  payload: { field: item.fieldFrom, value: date },
                })
              }
            />
            <div className="PruFilter-date-divider" />
            <PruDateTimePicker
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              slotProps={{
                textField: {
                  error: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`],
                  // COE
                  // helperText: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`] && DATE_ERROR_TEXT,
                  style: { marginRight: 20 },

                  // PVA
                  helperText: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`]
                    ? item.errorText
                      ? item.errorText
                      : DATE_ERROR_TEXT
                    : errorState.immediate[`${item.fieldTo}${item.fieldFrom}Required`] &&
                      !filterState[item.fieldTo] &&
                      MANDATORY_FIELD_ERROR_TEXT,
                },
              }}
              ampm={false}
              format="DD/MM/YYYY HH:mm"
              value={filterState[item.fieldTo]}
              onChange={(date) =>
                filterDispatch({
                  type: 'CHANGE_FILTER',
                  payload: { field: item.fieldTo, value: date },
                })
              }
            />
          </>
        );
      case PruFilterItemType.DROPDOWN:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <FormControl
              style={{
                ...item.style,
                marginRight: 20,
              }}
              margin="dense"
              variant="outlined"
            >
              <Select
                native
                disabled={!checkIfFilterEnable(item.enableIfExist)}
                value={filterState[item.field]}
                onChange={(e) => {
                  if (
                    e.target.value == 'Start Date' ||
                    e.target.value == 'Campaign Start Date' ||
                    e.target.value == 'Create Date' ||
                    e.target.value == 'Assigned Date'
                  ) {
                    setShowDatePicker(true);
                    setTextDate({
                      startDate: moment(new Date()).format('MM/DD/YYYY'),
                      endDate: moment(new Date()).format('MM/DD/YYYY'),
                    });
                  } else {
                    setShowDatePicker(false);
                    setTextDate(undefined);
                  }
                  filterDispatch({
                    type: 'CHANGE_FILTER',
                    payload: {
                      field: item.field,
                      value: e.target.value as string,
                    },
                  });
                }}
              >
                {item.choices.map((choice, index) => (
                  <option key={`dropdown-choice-${choice.value}-${index}`} value={choice.value}>
                    {choice.displayName}
                  </option>
                ))}
              </Select>
            </FormControl>
          </>
        );
      case PruFilterItemType.MULTIPLE_DROPDOWN:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <FormControl
              style={{
                ...item.style,
                marginRight: 20,
              }}
              margin="dense"
              variant="outlined"
            >
              <Select
                multiple
                disabled={!checkIfFilterEnable(item.enableIfExist)}
                value={filterState[item.field]}
                onChange={(e) => {
                  filterDispatch({
                    type: 'CHANGE_FILTER',
                    payload: {
                      field: item.field,
                      value: e.target.value as string,
                    },
                  });
                }}
              >
                {item.choices.map((choice, index) => (
                  <MenuItem key={`dropdown-choice-${choice.value}-${index}`} value={choice.value}>
                    {choice.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        );

      // PVA

      case PruFilterItemType.MULTIPLE_SELECT:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <FormControl
              style={{
                ...item.style,
                marginRight: 20,
                maxWidth: 250,
                width: 200,
              }}
              margin="dense"
              variant="outlined"
            >
              <Select
                disabled={item.disabled || !checkIfFilterEnable(item.enableIfExist)}
                multiple
                value={filterState[item.field]}
                required={item.required}
                onChange={(e) => {
                  let value = Array.isArray(e.target.value) ? e.target.value : [];
                  if (item.allValue && value.length > 0) {
                    let indexAll = value.findIndex((x) => x === item.allValue);
                    if (value.length - 1 === indexAll || item.choices.length - 1 === value.length) {
                      value = [item.allValue];
                    } else {
                      if (indexAll !== -1) {
                        value = item.choices.reduce((previousValue: string[], currentValue) => {
                          if (value.indexOf(currentValue.value) === -1) {
                            previousValue.push(currentValue.value);
                          }
                          return previousValue;
                        }, []);
                      }
                    }
                  }

                  filterDispatch({
                    type: 'CHANGE_FILTER',
                    payload: {
                      field: item.field,
                      value: value,
                    },
                  });
                }}
                renderValue={(selected) => {
                  if (selected && Array.isArray(selected)) {
                    return selected
                      .reduce((previousValue, currentValue) => {
                        let itemFound = item.choices.find((x) => x.value === currentValue);
                        if (itemFound) {
                          previousValue.push(itemFound.displayName);
                        }
                        return previousValue;
                      }, [])
                      .join(', ');
                  } else {
                    return '';
                  }
                }}
                MenuProps={MenuProps}
              >
                {item.choices.map((choice) => (
                  <MenuItem key={`dropdown-choice-${choice.value}`} value={choice.value}>
                    <Checkbox
                      checked={
                        filterState[item.field] &&
                        ((item.allValue && filterState[item.field].indexOf(item.allValue) !== -1) ||
                          filterState[item.field].indexOf(choice.value) !== -1)
                      }
                    />
                    <ListItemText primary={choice.displayName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        );

      // End of PVA
      case PruFilterItemType.ASYNC_DROPDOWN:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <AsyncAutocomplete
              style={{
                ...item.style,
                marginRight: 20,
              }}
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              value={String(filterState[item.field])}
              //COE
              // onChange={(value) =>
              //   filterDispatch({
              //     type: 'CHANGE_FILTER',
              //     payload: { field: item.field, value: value },
              //   })
              // }
              initialOptions={item.initialOptions}
              getData={item.fetchList}
              // PVA
              onChange={(value) => {
                filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.field, value: value } });
                if (typeof item.fetchList !== 'undefined') {
                  onChangeFilter({ ...filterState, [item.field]: value });
                }
              }}
            />
          </>
        );
      case PruFilterItemType.ASYNC_MULTIPLE_DROPDOWN:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <AsyncAutocomplete
              multiple
              style={{
                ...item.style,
                marginRight: 20,
              }}
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              value={filterState[item.field]}
              onChange={(value) =>
                filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.field, value: value } })
              }
              initialOptions={item.initialOptions}
              getData={item.fetchList}
            />
          </>
        );
      case PruFilterItemType.DATE:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <PruDatePicker
              slotProps={{
                textField: {
                  style: { marginRight: 20 },
                },
              }}
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              format="DD/MM/YYYY"
              value={filterState[item.field]}
              onChange={(date) =>
                filterDispatch({
                  type: 'CHANGE_FILTER',
                  payload: { field: item.field, value: date },
                })
              }
            />
          </>
        );

      // PVA
      case PruFilterItemType.DATE_MONTH:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <PruDateTimePicker
              disabled={item.disabled || !checkIfFilterEnable(item.enableIfExist)}
              slotProps={{}}
              format="MM/YYYY"
              views={['year', 'month']}
              value={filterState[item.field]}
              onChange={(date) =>
                filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.field, value: date } })
              }
            />
          </>
        );
      case PruFilterItemType.CRITERIA:
        return (
          <>
            {isShowing && (
              <>
                <div className={classes.modalOverlay} />
                <div className={classes.modalWrapper} aria-modal aria-hidden tabIndex={-1} role="dialog">
                  <div className={classes.modal}>
                    <div className={classes.titleHeader}>
                      <h2>
                        <b>Add/Edit Criteria</b>
                      </h2>
                      <a onClick={handlClearAllCondition} style={{ position: 'absolute', right: 50, color: 'red' }}>
                        Clear all
                      </a>
                    </div>
                    <br />
                    <hr />
                    {selectedConditions.length > 0 && (
                      <>
                        {selectedConditions.map((condition: any, index: number) => (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              marginTop: 10,
                            }}
                          >
                            <p style={{ width: '90px' }}>
                              <b>{condition.name}</b>
                            </p>
                            <TextField
                              onChange={(event: any) =>
                                handleChangeInputSelectedCondition(event, condition.propName, index)
                              }
                              style={{ width: '400px' }}
                              size="small"
                              variant="outlined"
                              value={condition.value}
                            />
                            <img
                              onClick={() => handleRemoveCondition(index, condition)}
                              src={toAbsoluteUrl('/media/icon/bin.png')}
                              alt={'Select from list'}
                              style={{ width: 24, height: 24, margin: 10 }}
                            />
                          </div>
                        ))}
                        <br />
                        <hr />
                      </>
                    )}
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginTop: 10,
                        }}
                      >
                        <p style={{ width: '150px' }}>
                          <b>Add Criteria</b>
                        </p>
                        <FormControl style={{ width: '80%' }} size="small">
                          <InputLabel>Select Criteria</InputLabel>
                          <Select disabled={criteria.length === 0} onChange={handleChangeCondition}>
                            {criteria.map((condition: any) => {
                              return (
                                <MenuItem value={condition} key={condition.propName}>
                                  {condition.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className={classes.modalFooter}>
                      <Button style={{ marginRight: 20 }} variant="contained" color="error" onClick={toggle}>
                        {Translation('app.button.cancel')}
                      </Button>
                      <Button variant="contained" color="success" onClick={handleApply}>
                        {Translation('app.button.apply')}
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
            <a
              style={{
                color: '#3699FF',
              }}
              onClick={() => toggle()}
            >
              Add/Edit Criteria({selectedApplyConditions.length > 0 && selectedApplyConditions.length})
            </a>
          </>
        );
      case PruFilterItemType.DATE_TYPE_RANGE:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <Select
              defaultValue={filterState[item.dateType]}
              style={{
                marginRight: 20,
                marginTop: 4,
              }}
              native
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              onChange={(e) => {
                const currentYear = moment().year();
                const ytdTime = moment(`${currentYear}-01-01`).startOf('day').valueOf();
                const past30DaysTime = moment().subtract(30, 'days').valueOf();
                const past3MonthsTime = moment().subtract(2, 'months').startOf('month').valueOf();
                filterDispatch({
                  type: 'CHANGE_FILTER',
                  payload: { field: item.dateType, value: e.target.value as PruFilterDateType.Last3Months },
                });
                switch (e.target.value) {
                  case PruFilterDateType.Last3Months:
                    filterDispatch({
                      type: 'CHANGE_FILTER',
                      payload: { field: item.fieldFrom, value: new Date(past3MonthsTime) },
                    });
                    filterDispatch({
                      type: 'CHANGE_FILTER',
                      payload: { field: item.fieldTo, value: getDayEnd(new Date()) },
                    });
                    break;
                  case PruFilterDateType.Last30Days:
                    filterDispatch({
                      type: 'CHANGE_FILTER',
                      payload: { field: item.fieldFrom, value: new Date(past30DaysTime) },
                    });
                    filterDispatch({
                      type: 'CHANGE_FILTER',
                      payload: { field: item.fieldTo, value: getDayEnd(new Date()) },
                    });
                    break;
                  case PruFilterDateType.YTD:
                    filterDispatch({
                      type: 'CHANGE_FILTER',
                      payload: { field: item.fieldFrom, value: new Date(ytdTime) },
                    });
                    filterDispatch({
                      type: 'CHANGE_FILTER',
                      payload: { field: item.fieldTo, value: getDayEnd(new Date()) },
                    });
                    break;
                  case PruFilterDateType.Custom:
                    filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldFrom, value: null } });
                    filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldTo, value: null } });
                    break;
                }
              }}
            >
              {Object.values(PruFilterDateType).map((type, index) => (
                <option key={`dropdown-choice-${type}-${index}`} value={type}>
                  {type}
                </option>
              ))}
            </Select>
            {filterState[item.dateType] === PruFilterDateType.Custom && (
              <>
                <PruDatePicker
                  disabled={!checkIfFilterEnable(item.enableIfExist)}
                  slotProps={{
                    textField: {
                      error: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`],
                    },
                  }}
                  format="DD/MM/YYYY"
                  value={filterState[item.fieldFrom]}
                  onChange={(date) => {
                    filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldFrom, value: date } });
                  }}
                />
                <div className="PruFilter-date-divider" />
                <PruDatePicker
                  disabled={!checkIfFilterEnable(item.enableIfExist)}
                  slotProps={{
                    textField: {
                      error: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`],
                      helperText: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`] && DATE_ERROR_TEXT,
                      style: { marginRight: 20 },
                    },
                  }}
                  format="DD/MM/YYYY"
                  value={filterState[item.fieldTo]}
                  onChange={(date) => {
                    filterDispatch({
                      type: 'CHANGE_FILTER',
                      payload: { field: item.fieldTo, value: getDayEnd(date as Date) },
                    });
                  }}
                />
              </>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  const renderFilter = () => {
    const filterRows: React.ReactNode[] = [];
    let tempArr: React.ReactNode[] = [];
    let lengthCount = 0;
    itemDef.forEach((item, index) => {
      const itemLength = item.length ?? ITEM_LENGTH[item.type];
      if (lengthCount + itemLength <= 4) {
        lengthCount += itemLength;
        tempArr.push(<Fragment key={`filter-item-${index}`}>{filterItemRender(item)}</Fragment>);
        if (index >= itemDef.length - 1) {
          filterRows.push([...tempArr]);
        }
      } else {
        filterRows.push([...tempArr]);
        lengthCount = 0;
        tempArr = [];

        lengthCount += itemLength;
        tempArr.push(<Fragment key={`filter-item-${index}`}>{filterItemRender(item)}</Fragment>);
        if (!itemDef[index + 1]) {
          filterRows.push([...tempArr]);
        }
      }
    });
    return filterRows.map((filterRow, index) => (
      <div className="PruFilter-row" key={`PruFilter-row-${index}`}>
        {filterRow}
      </div>
    ));
  };

  const { errorState, onSubmitErrorValidator, immediateErrorValidator } = useErrorHandler<ErrorState>(
    filterState,
    errorDefinition,
  );

  useEffect(() => {
    if (updateFilterOnChange) {
      onChangeFilter(filterState);
    }
    immediateErrorValidator();
  }, [filterState]);

  const getFilterSearch = () => {
    onChangeFilter(filterState);
    immediateErrorValidator();
  };

  const filterList = () => {
    const { hasError } = onSubmitErrorValidator();

    // PVA
    if (customDateRangeMonth) {
      filterDispatch({
        type: 'CHANGE_FILTER',
        payload: {
          field: 'requestedDateFrom',
          value:
            !filterState.requestedDateFrom && !filterState.requestedDateTo
              ? moment(getDayStart(new Date()).setMonth(getDayStart(new Date()).getMonth() - 6)).toDate()
              : !filterState.requestedDateFrom && filterState.requestedDateTo
              ? moment(
                  getDayStart(new Date(filterState.requestedDateTo)).setMonth(
                    getDayStart(new Date(filterState.requestedDateTo)).getMonth() - 6,
                  ),
                ).toDate()
              : filterState.requestedDateFrom,
        },
      });
      filterDispatch({
        type: 'CHANGE_FILTER',
        payload: {
          field: 'requestedDateTo',
          value:
            !filterState.requestedDateFrom && !filterState.requestedDateTo
              ? getDayEnd(new Date())
              : filterState.requestedDateFrom && !filterState.requestedDateTo
              ? moment(
                  getDayEnd(new Date(filterState.requestedDateFrom)).setMonth(
                    getDayEnd(new Date(filterState.requestedDateFrom)).getMonth() + 6,
                  ),
                ).toDate()
              : filterState.requestedDateTo,
        },
      });
    }

    getFilterSearch();
    if (!hasError) {
      fetchData();
    }
  };

  const keyPressSearch = (e: KeyboardEvent) => {
    if (!disableKeydownEvent && e.key === 'Enter') {
      filterList();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', keyPressSearch);

    return () => {
      window.removeEventListener('keydown', keyPressSearch);
    };
    // eslint-disable-next-line
  }, [filterState, disableKeydownEvent]);

  return (
    <div style={{ ...style, marginBottom: 20 }} className={filterClasses.root}>
      <div style={{ marginBottom: 5 }} className="PruFilter-header-container">
        <div className={cx(commonClasses.header, titleClassName)}>{title}</div>
        <div className="PruFilter-row">
          {enableBack && (
            <Button
              style={{ marginRight: 20 }}
              variant="contained"
              color="inherit"
              onClick={() => {
                if (onBack) onBack();
              }}
            >
              {Translation('app.button.back')}
            </Button>
          )}
          {downloadExcel && (
            <Button style={{ marginRight: 20 }} variant="contained" color="inherit" onClick={downloadExcel}>
              {Translation('app.button.download')}
            </Button>
          )}
          {!disableReset && (
            <Button
              style={{ marginRight: 20 }}
              variant="contained"
              color="inherit"
              // COE
              // onClick={() => filterDispatch({ type: 'RESET_FILTER', payload: { itemDef } })}

              // PVA
              onClick={() => {
                filterDispatch({ type: 'RESET_FILTER', payload: { itemDef } });
                if (customDateRangeMonth) {
                  filterDispatch({ type: 'CHANGE_FILTER', payload: { field: 'requestedDateFrom', value: null } });
                  filterDispatch({ type: 'CHANGE_FILTER', payload: { field: 'requestedDateTo', value: null } });
                }
                handlClearAllCondition();
              }}
            >
              {Translation('app.button.reset')}
            </Button>
          )}
          <Button variant="contained" color="secondary" onClick={filterList}>
            {Translation('app.button.search')}
          </Button>
        </div>
      </div>
      <div className={filterClassName}>{renderFilter()}</div>
    </div>
  );
};

export default PruFilter;
