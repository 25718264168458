export type ReportItem = {
  _id?: string
  agentCodeScanner: string
  agentNameScanner: string
  rating: string
  ratingAt: string
  agentCode: string
  agentName: string
  content: {
    vi: string
    en: string
  },
  activityCode: string
}

export type ReportListParams = {
  requestedDateFrom: Date | null
  requestedDateTo: Date | null
  rating: ratingModel
  content: string
  lang: string
  page: number
  limit: number
}

export enum ratingModel {
  zero = '0',
  one = '1',
  two = '2',
  three = '3',
  four = '4',
  five = '5'
};
