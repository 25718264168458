import { forEach } from 'lodash';
import { PaginateListMore } from '../../../common/types/common-types';
import { apiClient, apiErrorHandler } from 'src/app/common/network';
import { Dispatch } from 'react';
import moment from 'moment';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

const formatDataFilters = (params: any) => {
  let data: any = {
    dataFilters: [],
    page: params.page,
    limit: params.limit,
  };
  forEach(params, (param, key) => {
    if (param) {
      switch (key) {
        case 'requestedDateFrom':
          data.dataFilters.push({
            name: 'createdAt',
            value: param,
            type: 'Date',
            operator: 'gt',
          });
          break;
        case 'requestedDateTo':
          data.dataFilters.push({
            name: 'createdAt',
            value: param,
            type: 'Date',
            operator: 'lt',
          });
          break;
        case 'agentCode':
          data.dataFilters.push({
            name: 'agentCode',
            value: param,
            type: 'string',
            operator: 'search',
          });
          break;
        case 'notificationTitle':
          data.dataFilters.push({
            name: 'notificationTitle',
            value: param,
            type: 'string',
            operator: 'search',
          });
          break;

        case 'unitCode':
          data.dataFilters.push({
            name: 'unitCode',
            value: param,
            type: 'string',
            operator: 'search',
          });
          break;

        case 'branchCode':
          data.dataFilters.push({
            name: 'branchCode',
            value: param,
            type: 'string',
            operator: 'search',
          });
          break;

        case 'officeCode':
          data.dataFilters.push({
            name: 'officeCode',
            value: param,
            type: 'string',
            operator: 'search',
          });
          break;
        case 'category':
          if (param.length > 0) {
            data.dataFilters.push({
              name: 'category',
              value: param,
              type: 'array',
              operator: 'search',
            });
          }
          break;
      }
    }
  });
  return data;
};

export const fetchOpeningNotificationReportDownload = (params: any, dispatch?: Dispatch<any>): void => {
  const uri = `${ADMIN_URL}/report/notification/opening/export`;
  const data = formatDataFilters(params);

  apiClient
    .post<Blob>(uri, data, { responseType: 'text' })
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob(['\uFEFF' + response.data], {
          type: 'text/csv;charset=utf-8;',
        }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'Notification-Contest-Opening-Report_' + moment(new Date()).format('YYYYMMDD') + '.csv',
      );
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchOpeningNotificationReport = async (
  params: any,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<any>> => {
  const uri = `${ADMIN_URL}/report/notification/opening`;
  const data = formatDataFilters(params);

  return apiClient
    .post<PaginateListMore<any>>(uri, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
