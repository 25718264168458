import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  REPORT_TITLE,
  REPORT_BASE_PATH,
  AdminReportType,
  REPORT_PERFORMANCE_TITLE,
  REPORT_PERFORMANCE_PATH,
  REPORT_PERFORMANCE_PRODUCTION_TARGET_TITLE,
  REPORT_PERFORMANCE_PRODUCTION_TARGET_PATH,
  REPORT_PERFORMANCE_PRODUCTION_TARGET_NAMESPACE,
  REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_TITLE,
  REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_PATH,
  REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_NAMESPACE,
  REPORT_PERFORMANCE_ADBU_TARGET_TITLE,
  REPORT_PERFORMANCE_ADBU_TARGET_PATH,
  REPORT_PERFORMANCE_ADBU_TARGET_NAMESPACE,
  REPORT_INCENTIVE_TITLE,
  REPORT_INCENTIVE_PATH,
  // PVA not use
  // REPORT_INCENTIVE_RESULT_TITLE,
  // REPORT_INCENTIVE_RESULT_PATH,
  // REPORT_INCENTIVE_RESULT_NAMESPACE,
  REPORT_RECRUITMENT_TITLE,
  REPORT_RECRUITMENT_PRUDNA_SUMMARY_TITLE,
  REPORT_RECRUITMENT_PRUDNA_DETAIL_TITLE,
  REPORT_RECRUITMENT_PATH,
  REPORT_RECRUITMENT_PRUDNA_SUMMARY_PATH,
  REPORT_RECRUITMENT_PRUDNA_DETAIL_PATH,
  REPORT_RECRUITMENT_PRUDNA_SUMMARY_NAMESPACE,
  REPORT_RECRUITMENT_PRUDNA_DETAIL_NAMESPACE,
  REPORT_NEWS_AGENTS,
  REPORT_NEWS_AGENTS_NAMESPACE,
  REPORT_NEWS_AGENTS_TITLE,
  REPORT_NEWS_COMMENTS,
  REPORT_NEWS_COMMENTS_NAMESPACE,
  REPORT_NEWS_COMMENTS_TITLE,
  REPORT_NEWS_PATH,
  REPORT_NEWS_TITLE,
  REPORT_NEWS_INFO_NAMESPACE,
  REPORT_NEWS_INFO,
  REPORT_NEWS_INFO_TITLE,
  REPORT_NEWS_AGENT_ACTIVITY,
  REPORT_NEWS_AGENT_ACTIVITY_NAMESPACE,
  REPORT_NEWS_AGENT_ACTIVITY_TITLE,
  REPORT_REGISTRATION_CANDIDATE_TITLE,
  REPORT_REGISTRATION_CANDIDATE,
  REPORT_REGISTRATION_CANDIDATE_NAMESPACE,
  REPORT_CUSTOMER_CAMPAIGN_PATH,
  REPORT_CUSTOMER_CAMPAIGN_TITLE,
  REPORT_CUSTOMER_CAMPAIGN_NAMESPACE,
  REPORT_INCENTIVE_V2_PATH,
  REPORT_INCENTIVE_REGISTRATION_V2_NAMESPACE,
  REPORT_INCENTIVE_OPENING_V2_NAMESPACE,
  REPORT_RECRUITMENT_MASS_PRUDNA_DETAIL_TITLE,
  REPORT_RECRUITMENT_MASS_PRUDNA_SUMMARY_TITLE,
  REPORT_RECRUITMENT_PRUDNA_MASS_DETAIL_PATH,
  REPORT_RECRUITMENT_PRUDNA_MASS_SUMMARY_PATH,
} from './constants';
import { isPermitted } from 'src/app/common/utils';
import ProductionTargetPage from './pages/Performance/ProductionTarget/ProductionTargetPage';
import ProductionTargetMTDPage from './pages/Performance/ProductionTargetMTD/ProductionTargetMTDPage';
import AdbuTargetPage from './pages/Performance/AdbuTarget/AdbuTargetPage';
import IncentiveResultPage from './pages/Incentive/Result/IncentiveResultPage';
import PruDNASummaryPage from './pages/Recruitment/SummaryReport/index';
import PruDNADetailPage from './pages/Recruitment/DetailReport/index';
import AgentsPage from './pages/News/Agents/AgentsPage';
import CommentsPage from './pages/News/Comments/CommentsPage';
import InfoPage from './pages/News/Info/InfoPage';
import AgentActivityPage from './pages/News/AgentActivity/AgentActicityPage';
import MassPRUdnaSummaryReport from './pages/Recruitment/mass-summary-report';
import MassPRUdnaDetailsReport from './pages/Recruitment/mass-detail-report';
import { massPRUdnaSharingIsEnabled } from './pages/Recruitment/utils';

//PVA
import {
  REPORT_INCENTIVE_REGISTRATION_TITLE,
  REPORT_INCENTIVE_REGISTRATION_PATH,
  REPORT_INCENTIVE_REGISTRATION_NAMESPACE,
  REPORT_INCENTIVE_OPENING_TITLE,
  REPORT_INCENTIVE_OPENING_PATH,
  REPORT_INCENTIVE_OPENING_NAMESPACE,
  REPORT_RECRUITMENT_NEW_REGISTERED_CANDIDATE_TITLE,
  REPORT_RECRUITMENT_NEW_REGISTERED_CANDIDATE_PATH,
  REPORT_RECRUITMENT_NEW_REGISTERED_CANDIDATE_NAMESPACE,
  REPORT_SALE_TITLE,
  REPORT_SALE_PATH,
  REPORT_SALE_CONVERSION_RATE_TITLE,
  REPORT_SALE_CONVERSION_RATE,
  REPORT_SALE_CONVERSION_RATE_NAMESPACE,
  REPORT_SALE_CUSTOMER_LEAD_MANAGEMENT_TITLE,
  REPORT_SALE_CUSTOMER_LEAD_MANAGEMENT,
  REPORT_SALE_CUSTOMER_LEAD_MANAGEMENT_NAMESPACE,
  REPORT_APP_PERF_TRACKING_TITLE,
  REPORT_APP_PERF_TRACKING_INSTALLMENT_TITLE,
  REPORT_APP_PERF_TRACKING_ACTIVE_USER_TITLE,
  REPORT_APP_PERF_TRACKING_PATH,
  REPORT_APP_PERF_TRACKING_APP_INSTALLATION,
  REPORT_APP_PERF_TRACKING_ACTIVE_USER,
  REPORT_APP_PERF_TRACKING_INSTALLMENT_NAMESPACE,
  REPORT_APP_PERF_TRACKING_ACTIVE_USER_NAMESPACE,
  REPORT_QR_CODE_TITLE,
  REPORT_QR_CODE_QR_ACTIVITY_TITLE,
  REPORT_QR_CODE_ACTIVITY_RATING_TITLE,
  REPORT_QR_CODE_PATH,
  REPORT_QR_CODE_QR_ACTIVITY,
  REPORT_QR_CODE_ACTIVITY_RATING,
  REPORT_QR_CODE_QR_ACTIVITY_NAMESPACE,
  REPORT_QR_CODE_ACTIVITY_RATING_NAMESPACE,
  REPORT_SERVING_SURVEY_OPENING_NAMESPACE,
  REPORT_SERVING_SURVEY_OPENING_PATH,
  REPORT_SURVING_SURVEY_OPENING_TITLE,
  REPORT_CUSTOMER_CONTACT_TRACKING_NAMESPACE,
  REPORT_CUSTOMER_CONTACT_TRACKING_TITLE,
  REPORT_CUSTOMER_CONTACT_TRACKING_PATH,
  REPORT_SALE_PLANNING_PATH,
  REPORT_SALE_PLANNING_TITLE,
  REPORT_TEAM_SALE_PLANNING_PATH,
  REPORT_TEAM_SALE_PLANNING_NAMESPACE,
  REPORT_PERSONAL_SALE_PLANNING_NAMESPACE,
  REPORT_PERSONAL_SALE_PLANNING_PATH,
  REPORT_TEAM_SALE_PLANNING_TITLE,
  REPORT_PERSONAL_SALE_PLANNING_TITLE,
  REPORT_CANDIDATE_TITLE,
  REPORT_CANDIDATE_ASSESSMENT_TITLE,
  REPORT_CANDIDATE_PATH,
  REPORT_CANDIDATE_ASSESSMENT,
  REPORT_CANDIDATE_ASSESSMENT_NAMESPACE,
  REPORT_CANDIDATE_DOCUMENT_TITLE,
  REPORT_CANDIDATE_DOCUMENT,
  REPORT_CANDIDATE_DOCUMENT_NAMESPACE,
  REPORT_CANDIDATE_INFORMATION_TITLE,
  REPORT_CANDIDATE_INFORMATION,
  REPORT_CANDIDATE_INFORMATION_NAMESPACE,
  REPORT_LIVESTREAM_ACTIVITY,
  REPORT_LIVESTREAM_ACTIVITY_NAMESPACE,
  REPORT_LIVESTREAM_ACTIVITY_TITLE,
  REPORT_LIVESTREAM_DETAILED_COMMENT,
  REPORT_LIVESTREAM_DETAILED_COMMENT_NAMESPACE,
  REPORT_LIVESTREAM_DETAILED_COMMENT_TITLE,
  REPORT_LIVESTREAM_PATH,
  REPORT_LIVESTREAM_PERFORMANCE,
  REPORT_LIVESTREAM_PERFORMANCE_NAMESPACE,
  REPORT_LIVESTREAM_PERFORMANCE_TITLE,
  REPORT_LIVESTREAM_TITLE,
  REPORT_MDRT_HUB_SHARING_ACTIVITY_NAMESPACE,
  REPORT_MDRT_HUB_TITLE,
  REPORT_MDRT_HUB_PATH,
  REPORT_MDRT_HUB_SHARING_ACTIVITY_TITLE,
  REPORT_MDRT_HUB_SHARING_ACTIVITY_PATH,
  REPORT_NOTIFICATION_TITLE,
  REPORT_NOTIFICATION_PATH,
  REPORT_OPENING_NOTIFICATION_TITLE,
  REPORT_OPENING_NOTIFICATION,
  REPORT_OPENING_NOTIFICATION_NAMESPACE,
  REPORT_EVENT_TITLE,
  REPORT_EVENT_PATH,
  REPORT_EVENT_NAMESPACE,
  REPORT_CANDIDATE_MANAGEMENT,
  REPORT_CANDIDATE_MANAGEMENT_NAMESPACE,
  REPORT_CANDIDATE_MANAGEMENT_TITLE,
  REPORT_EVENT_CUSTOMER_TITLE,
  REPORT_EVENT_CUSTOMER,
  REPORT_EVENT_CUSTOMER_NAMESPACE,
  REPORT_EVENT_CANDIDATE_TITLE,
  REPORT_EVENT_CANDIDATE,
  REPORT_EVENT_CANDIDATE_NAMESPACE,
  REPORT_CANDIDATE_INTERACTION_TITLE,
  REPORT_CANDIDATE_INTERACTION,
  REPORT_CANDIDATE_INTERACTION_NAMESPACE,
  REPORT_CANDIDATE_SURVEY_TITLE,
  REPORT_CANDIDATE_SURVEY,
  REPORT_CANDIDATE_SURVEY_NAMESPACE,
  REPORT_CANDIDATE_PERFORMANCE,
  REPORT_CANDIDATE_PERFORMANCE_NAMESPACE,
  REPORT_CANDIDATE_PERFORMANCE_TITLE,
  REPORT_SURVEY_TITLE,
  REPORT_SURVEY_PATH,
  REPORT_CUSTOMER_SURVEY_TITLE,
  REPORT_CUSTOMER_SURVEY_PATH,
  REPORT_CUSTOMER_SURVEY_NAMESPACE,
  REPORT_NON_PROSPECT_SURVEY_TITLE,
  REPORT_NON_PROSPECT_SURVEY_PATH,
  REPORT_NON_PROSPECT_SURVEY_NAMESPACE,
  REPORT_AGENT_LEADER_SURVEY_TITLE,
  REPORT_AGENT_LEADER_SURVEY_PATH,
  REPORT_AGENT_LEADER_SURVEY_NAMESPACE,
  REPORT_EVENT_CUSTOMER_AG_VIEW,
  REPORT_EVENT_CUSTOMER_AG_VIEW_NAMESPACE,
  REPORT_EVENT_CUSTOMER_TITLE_AG_VIEW,
  REPORT_SALE_MATERIAL_PERFORMANCE_TRACKING_NAMESPACE,
  REPORT_SALE_MATERIAL_PERFORMANCE_TRACKING,
  REPORT_SALE_MATERIAL_PERFORMANCE_TRACKING_TITLE,
} from './constants';
import IncentiveRegistrationPage from './pages/Incentive/Registration/IncentiveRegistrationPage';
import IncentiveOpeningReportPage from './pages/Incentive/OpeningReport/IncentiveOpeningReportPage';
import NewRegisteredCandidateReport from './pages/Recruitment/NewRegisteredCandidateReport';
import ConversionRatePage from './pages/Sale/ConversionRate/ConversionRatePage';
import SalesMPerformanceTrackingPage from './pages/Sale/SalesMPerformanceTracking/SalesMPerformanceTrackingPage';
import CustomerLeadManagementPage from './pages/Sale/CustomerLeadManagement';
import InstallmentPage from './pages/PerfTracking/Installment/InstallmentPage';
import ActiveUserPage from './pages/PerfTracking/ActiveUser/ActiveUserPage';
import CustomerContactTrackingPage from './pages/CustomerContact/CustomerContactTrackingPage';
import QrActivityReport from './pages/QrCode/QrActivity';
import ActivityRatingReport from './pages/QrCode/ActivityRating';
import ServingSurveyOpeningReport from './pages/Survey/ServingSurveyOpening';
import CustomerSurveyReport from './pages/Survey/Customer';
import NonProspectSurveyReport from './pages/Survey/NonProspectSurveyReport';
import AgentLeaderSurveyReport from './pages/Survey/AgentLeaderSurveyReport';
import TeamSalePlanningReport from './pages/SalePlanning/TeamSalePlanning';
import PersonalSalePlanningReport from './pages/SalePlanning/PersonalSalePlanning';
import CandidateAssessmentReport from './pages/Candidate/CandidateAssessment';
import CandidateDocumentReport from './pages/Candidate/CandidateDocument';
import CandidateInformationReport from './pages/Candidate/CandidateInformation';
import CandidateManagementReport from './pages/Candidate/CandidateManagement';
import CandidateInteractionReport from './pages/Candidate/CandidateInteraction';
import CandidateSurveyReport from './pages/Candidate/CandidateSurvey';
import CandidatePerformanceReport from './pages/Candidate/CandidatePerformance';
import DetailedCommentReportPage from './pages/Livestream/DetailedComment/DetailedCommentPage';
import ActivityReportPage from './pages/Livestream/Activity/ActivityPage';
import PerformanceReportPage from './pages/Livestream/Performance/PerformancePage';
import SharingActivityPage from './pages/MdrtHub/SharingActivity/SharingActivityPage';
import NotificationOpeningReportPage from './pages/Notification';
import ReportEvent from './pages/Event';
import EventManagementCustomer from './pages/Event/EventManagementCustomer';
import EventManagementCustomerAGView from './pages/Event/EventManagementCustomerAGview';
import EventManagementCandidate from './pages/Event/EventManagementCandidate';
import RegistrationCandidateReport from './pages/Candidate/RegistrationCandidateAssessment';
import CustomerCampaignReport from './pages/CustomerCampaign';
import IncentiveRegistrationPageV2 from './pages/Incentive-v2/Registration/IncentiveRegistrationPageV2';
import IncentiveOpeningReportPageV2 from './pages/Incentive-v2/OpeningReport/IncentiveOpeningReportPageV2';

export const reportModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(REPORT_TITLE),
    path: REPORT_BASE_PATH,
    icon: '/media/svg/icons/Communication/Chat1.svg',
    enableRead: isPermitted(Object.values(AdminReportType), permissions),
    child: [
      {
        title: Translation(REPORT_PERFORMANCE_TITLE),
        path: `${REPORT_BASE_PATH}${REPORT_PERFORMANCE_PATH}`,
        enableRead: isPermitted(
          [
            AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_READ,
            AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_CREATE,
            AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_UPDATE,
            AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_READ,
            AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_CREATE,
            AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_UPDATE,
            AdminReportType.REPORT_PERFORMANCE_ADBU_TARGET_READ,
            AdminReportType.REPORT_PERFORMANCE_ADBU_TARGET_CREATE,
            AdminReportType.REPORT_PERFORMANCE_ADBU_TARGET_UPDATE,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(REPORT_PERFORMANCE_PRODUCTION_TARGET_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_PERFORMANCE_PATH}${REPORT_PERFORMANCE_PRODUCTION_TARGET_PATH}`,
            namespace: REPORT_PERFORMANCE_PRODUCTION_TARGET_NAMESPACE,
            component: ProductionTargetPage,
            enableRead: isPermitted(
              [
                AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_READ,
                AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_CREATE,
                AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_UPDATE], permissions),
          },
          {
            title: Translation(REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_PERFORMANCE_PATH}${REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_PATH}`,
            namespace: REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_NAMESPACE,
            component: ProductionTargetMTDPage,
            enableRead: isPermitted(
              [
                AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_READ,
                AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_CREATE,
                AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_PERFORMANCE_PRODUCTION_TARGET_MTD_UPDATE], permissions),
          },
          {
            title: Translation(REPORT_PERFORMANCE_ADBU_TARGET_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_PERFORMANCE_PATH}${REPORT_PERFORMANCE_ADBU_TARGET_PATH}`,
            namespace: REPORT_PERFORMANCE_ADBU_TARGET_NAMESPACE,
            component: AdbuTargetPage,
            enableRead: isPermitted(
              [
                AdminReportType.REPORT_PERFORMANCE_ADBU_TARGET_READ,
                AdminReportType.REPORT_PERFORMANCE_ADBU_TARGET_CREATE,
                AdminReportType.REPORT_PERFORMANCE_ADBU_TARGET_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminReportType.REPORT_PERFORMANCE_ADBU_TARGET_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_PERFORMANCE_ADBU_TARGET_UPDATE], permissions),
          },
        ],
      },
      {
        title: Translation(REPORT_INCENTIVE_TITLE),
        path: `${REPORT_BASE_PATH}${REPORT_INCENTIVE_PATH}`,
        enableRead: isPermitted(
          [
            AdminReportType.REPORT_INCENTIVE_RESULT_READ,
            AdminReportType.REPORT_INCENTIVE_RESULT_CREATE,
            AdminReportType.REPORT_INCENTIVE_RESULT_UPDATE,

            // PVA
            AdminReportType.REPORT_INCENTIVE_REGISTRATION_READ,
            AdminReportType.REPORT_INCENTIVE_REGISTRATION_CREATE,
            AdminReportType.REPORT_INCENTIVE_REGISTRATION_UPDATE,

            AdminReportType.REPORT_INCENTIVE_OPENING_READ,
            AdminReportType.REPORT_INCENTIVE_OPENING_CREATE,
            AdminReportType.REPORT_INCENTIVE_OPENING_UPDATE,
          ],
          permissions,
        ),
        child: [
          // PVA not use
          // {
          //   title: Translation(REPORT_INCENTIVE_RESULT_TITLE),
          //   path: `${REPORT_BASE_PATH}${REPORT_INCENTIVE_PATH}${REPORT_INCENTIVE_RESULT_PATH}`,
          //   namespace: REPORT_INCENTIVE_RESULT_NAMESPACE,
          //   component: IncentiveResultPage,
          //   enableRead: isPermitted(
          //     [
          //       AdminReportType.REPORT_INCENTIVE_RESULT_READ,
          //       AdminReportType.REPORT_INCENTIVE_RESULT_CREATE,
          //       AdminReportType.REPORT_INCENTIVE_RESULT_UPDATE,
          //     ],
          //     permissions,
          //   ),
          //   enableCreate: isPermitted([AdminReportType.REPORT_INCENTIVE_RESULT_CREATE], permissions),
          //   enableUpdate: isPermitted([AdminReportType.REPORT_INCENTIVE_RESULT_UPDATE], permissions),
          // },

          // PVA
          {
            title: Translation(REPORT_INCENTIVE_REGISTRATION_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_INCENTIVE_PATH}${REPORT_INCENTIVE_REGISTRATION_PATH}`,
            namespace: REPORT_INCENTIVE_REGISTRATION_NAMESPACE,
            component: IncentiveRegistrationPage,
            enableRead: isPermitted(
              [
                AdminReportType.REPORT_INCENTIVE_REGISTRATION_READ,
                AdminReportType.REPORT_INCENTIVE_REGISTRATION_CREATE,
                AdminReportType.REPORT_INCENTIVE_REGISTRATION_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminReportType.REPORT_INCENTIVE_REGISTRATION_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_INCENTIVE_REGISTRATION_UPDATE], permissions),
          },
          {
            title: Translation(REPORT_INCENTIVE_OPENING_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_INCENTIVE_PATH}${REPORT_INCENTIVE_OPENING_PATH}`,
            namespace: REPORT_INCENTIVE_OPENING_NAMESPACE,
            component: IncentiveOpeningReportPage,
            enableRead: isPermitted(
              [
                AdminReportType.REPORT_INCENTIVE_OPENING_READ,
                AdminReportType.REPORT_INCENTIVE_OPENING_CREATE,
                AdminReportType.REPORT_INCENTIVE_OPENING_UPDATE,
              ],
              permissions,
            ),
            enableCreate: isPermitted([AdminReportType.REPORT_INCENTIVE_OPENING_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_INCENTIVE_OPENING_UPDATE], permissions),
          },
        ],
      },
      {
        title: 'New ' + Translation(REPORT_INCENTIVE_TITLE),
        path: `${REPORT_BASE_PATH}${REPORT_INCENTIVE_V2_PATH}`,
        enableRead: isPermitted(
          [AdminReportType.REPORT_INCENTIVE_OPENING_READ_V2, AdminReportType.REPORT_INCENTIVE_REGISTRATION_READ_V2],
          permissions,
        ),
        child: [
          // PVA
          {
            title: Translation(REPORT_INCENTIVE_REGISTRATION_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_INCENTIVE_V2_PATH}${REPORT_INCENTIVE_REGISTRATION_PATH}`,
            namespace: REPORT_INCENTIVE_REGISTRATION_V2_NAMESPACE,
            component: IncentiveRegistrationPageV2,
            enableRead: isPermitted([AdminReportType.REPORT_INCENTIVE_OPENING_READ_V2], permissions),
          },
          {
            title: Translation(REPORT_INCENTIVE_OPENING_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_INCENTIVE_V2_PATH}${REPORT_INCENTIVE_OPENING_PATH}`,
            namespace: REPORT_INCENTIVE_OPENING_V2_NAMESPACE,
            component: IncentiveOpeningReportPageV2,
            enableRead: isPermitted([AdminReportType.REPORT_INCENTIVE_REGISTRATION_READ_V2], permissions),
          },
        ],
      },
      {
        title: Translation(REPORT_RECRUITMENT_TITLE),
        path: `${REPORT_BASE_PATH}${REPORT_RECRUITMENT_PATH}`,
        enableRead: isPermitted(
          [
            AdminReportType.REPORT_RECRUITMENT_PRUDNA_SUMMARY_READ,
            AdminReportType.REPORT_RECRUITMENT_PRUDNA_DETAIL_READ,

            // PVA
            AdminReportType.REPORT_RECRUITMENT_NEW_REGISTERED_CANDIDATE_READ,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(REPORT_RECRUITMENT_PRUDNA_SUMMARY_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_RECRUITMENT_PATH}${REPORT_RECRUITMENT_PRUDNA_SUMMARY_PATH}`,
            namespace: REPORT_RECRUITMENT_PRUDNA_SUMMARY_NAMESPACE,
            component: PruDNASummaryPage,
            enableRead: isPermitted([AdminReportType.REPORT_RECRUITMENT_PRUDNA_SUMMARY_READ], permissions),
            enableCreate: isPermitted([AdminReportType.REPORT_RECRUITMENT_PRUDNA_SUMMARY_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_RECRUITMENT_PRUDNA_SUMMARY_UPDATE], permissions),
          },
          {
            title: Translation(REPORT_RECRUITMENT_PRUDNA_DETAIL_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_RECRUITMENT_PATH}${REPORT_RECRUITMENT_PRUDNA_DETAIL_PATH}`,
            namespace: REPORT_RECRUITMENT_PRUDNA_DETAIL_NAMESPACE,
            component: PruDNADetailPage,
            enableRead: isPermitted([AdminReportType.REPORT_RECRUITMENT_PRUDNA_DETAIL_READ], permissions),
            enableCreate: isPermitted([AdminReportType.REPORT_RECRUITMENT_PRUDNA_DETAIL_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_RECRUITMENT_PRUDNA_DETAIL_UPDATE], permissions),
          },

          // PVA
          {
            title: Translation(REPORT_RECRUITMENT_NEW_REGISTERED_CANDIDATE_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_RECRUITMENT_PATH}${REPORT_RECRUITMENT_NEW_REGISTERED_CANDIDATE_PATH}`,
            namespace: REPORT_RECRUITMENT_NEW_REGISTERED_CANDIDATE_NAMESPACE,
            component: NewRegisteredCandidateReport,
            enableRead: isPermitted([AdminReportType.REPORT_RECRUITMENT_NEW_REGISTERED_CANDIDATE_READ], permissions),
            enableCreate: isPermitted(
              [AdminReportType.REPORT_RECRUITMENT_NEW_REGISTERED_CANDIDATE_CREATE],
              permissions,
            ),
            enableUpdate: isPermitted(
              [AdminReportType.REPORT_RECRUITMENT_NEW_REGISTERED_CANDIDATE_UPDATE],
              permissions,
            ),
          },
          ...(massPRUdnaSharingIsEnabled()
            ? [
                {
                  title: Translation(REPORT_RECRUITMENT_MASS_PRUDNA_SUMMARY_TITLE),
                  path: `${REPORT_BASE_PATH}${REPORT_RECRUITMENT_PATH}${REPORT_RECRUITMENT_PRUDNA_MASS_SUMMARY_PATH}`,
                  namespace: REPORT_RECRUITMENT_PRUDNA_SUMMARY_NAMESPACE,
                  component: MassPRUdnaSummaryReport,
                  enableRead: isPermitted([AdminReportType.REPORT_RECRUITMENT_PRUDNA_SUMMARY_READ], permissions),
                  enableCreate: isPermitted([AdminReportType.REPORT_RECRUITMENT_PRUDNA_SUMMARY_CREATE], permissions),
                  enableUpdate: isPermitted([AdminReportType.REPORT_RECRUITMENT_PRUDNA_SUMMARY_UPDATE], permissions),
                },
                {
                  title: Translation(REPORT_RECRUITMENT_MASS_PRUDNA_DETAIL_TITLE),
                  path: `${REPORT_BASE_PATH}${REPORT_RECRUITMENT_PATH}${REPORT_RECRUITMENT_PRUDNA_MASS_DETAIL_PATH}`,
                  namespace: REPORT_RECRUITMENT_PRUDNA_DETAIL_NAMESPACE,
                  component: MassPRUdnaDetailsReport,
                  enableRead: isPermitted([AdminReportType.REPORT_RECRUITMENT_PRUDNA_DETAIL_READ], permissions),
                  enableCreate: isPermitted([AdminReportType.REPORT_RECRUITMENT_PRUDNA_DETAIL_CREATE], permissions),
                  enableUpdate: isPermitted([AdminReportType.REPORT_RECRUITMENT_PRUDNA_DETAIL_UPDATE], permissions),
                },
              ]
            : []),
        ],
      },
      {
        title: Translation(REPORT_NEWS_TITLE),
        path: `${REPORT_BASE_PATH}${REPORT_NEWS_PATH}`,
        enableRead: isPermitted(
          [
            AdminReportType.REPORT_NEWS_AGENTS_READ,
            AdminReportType.REPORT_NEWS_COMMENTS_READ,
            AdminReportType.REPORT_NEWS_INFO_READ,
            AdminReportType.REPORT_NEWS_AGENT_ACTIVITY_READ,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(REPORT_NEWS_AGENTS_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_NEWS_PATH}${REPORT_NEWS_AGENTS}`,
            namespace: REPORT_NEWS_AGENTS_NAMESPACE,
            component: AgentsPage,
            enableRead: isPermitted([AdminReportType.REPORT_NEWS_AGENTS_READ], permissions),
            enableCreate: isPermitted([AdminReportType.REPORT_NEWS_AGENTS_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_NEWS_AGENTS_UPDATE], permissions),
          },
          {
            title: Translation(REPORT_NEWS_COMMENTS_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_NEWS_PATH}${REPORT_NEWS_COMMENTS}`,
            namespace: REPORT_NEWS_COMMENTS_NAMESPACE,
            component: CommentsPage,
            enableRead: isPermitted([AdminReportType.REPORT_NEWS_COMMENTS_READ], permissions),
            enableCreate: isPermitted([AdminReportType.REPORT_NEWS_COMMENTS_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_NEWS_COMMENTS_UPDATE], permissions),
          },
          {
            title: Translation(REPORT_NEWS_INFO_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_NEWS_PATH}${REPORT_NEWS_INFO}`,
            namespace: REPORT_NEWS_INFO_NAMESPACE,
            component: InfoPage,
            enableRead: isPermitted([AdminReportType.REPORT_NEWS_INFO_READ], permissions),
            enableCreate: isPermitted([AdminReportType.REPORT_NEWS_INFO_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_NEWS_INFO_UPDATE], permissions),
          },
          {
            title: Translation(REPORT_NEWS_AGENT_ACTIVITY_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_NEWS_PATH}${REPORT_NEWS_AGENT_ACTIVITY}`,
            namespace: REPORT_NEWS_AGENT_ACTIVITY_NAMESPACE,
            component: AgentActivityPage,
            enableRead: isPermitted([AdminReportType.REPORT_NEWS_AGENT_ACTIVITY_READ], permissions),
            enableCreate: isPermitted([AdminReportType.REPORT_NEWS_AGENT_ACTIVITY_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_NEWS_AGENT_ACTIVITY_UPDATE], permissions),
          },
        ],
      },

      // PVA
      {
        title: Translation(REPORT_SALE_TITLE),
        path: `${REPORT_BASE_PATH}${REPORT_SALE_PATH}`,
        enableRead: isPermitted(
          [
            AdminReportType.REPORT_SALE_CONVERSION_RATE_READ,
            AdminReportType.REPORT_SALE_MATERIAL_PERFORMANCE_TRACKING_READ,
            AdminReportType.REPORT_SALE_CUSTOMER_LEAD_MANAGEMENT_READ,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(REPORT_SALE_CONVERSION_RATE_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_SALE_PATH}${REPORT_SALE_CONVERSION_RATE}`,
            namespace: REPORT_SALE_CONVERSION_RATE_NAMESPACE,
            component: ConversionRatePage,
            enableRead: isPermitted([AdminReportType.REPORT_SALE_CONVERSION_RATE_READ], permissions),
            enableCreate: isPermitted([AdminReportType.REPORT_SALE_CONVERSION_RATE_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_SALE_CONVERSION_RATE_UPDATE], permissions),
          },
          {
            title: Translation(REPORT_SALE_MATERIAL_PERFORMANCE_TRACKING_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_SALE_PATH}${REPORT_SALE_MATERIAL_PERFORMANCE_TRACKING}`,
            namespace: REPORT_SALE_MATERIAL_PERFORMANCE_TRACKING_NAMESPACE,
            component: SalesMPerformanceTrackingPage,
            enableRead: isPermitted([AdminReportType.REPORT_SALE_MATERIAL_PERFORMANCE_TRACKING_READ], permissions),
          },
          {
            title: Translation(REPORT_SALE_CUSTOMER_LEAD_MANAGEMENT_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_SALE_PATH}${REPORT_SALE_CUSTOMER_LEAD_MANAGEMENT}`,
            namespace: REPORT_SALE_CUSTOMER_LEAD_MANAGEMENT_NAMESPACE,
            component: CustomerLeadManagementPage,
            enableRead: isPermitted([AdminReportType.REPORT_SALE_CUSTOMER_LEAD_MANAGEMENT_READ], permissions),
            enableCreate: isPermitted([AdminReportType.REPORT_SALE_CUSTOMER_LEAD_MANAGEMENT_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_SALE_CUSTOMER_LEAD_MANAGEMENT_UPDATE], permissions),
          },
        ],
      },
      {
        title: Translation(REPORT_APP_PERF_TRACKING_TITLE),
        path: `${REPORT_BASE_PATH}${REPORT_APP_PERF_TRACKING_PATH}`,
        enableRead: isPermitted(
          [
            AdminReportType.REPORT_APP_PERF_TRACKING_ACTIVE_USER_READ,
            AdminReportType.REPORT_APP_PERF_TRACKING_INSTALLMENT_READ,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(REPORT_APP_PERF_TRACKING_INSTALLMENT_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_APP_PERF_TRACKING_PATH}${REPORT_APP_PERF_TRACKING_APP_INSTALLATION}`,
            namespace: REPORT_APP_PERF_TRACKING_INSTALLMENT_NAMESPACE,
            component: InstallmentPage,
            enableRead: isPermitted([AdminReportType.REPORT_APP_PERF_TRACKING_INSTALLMENT_READ], permissions),
            enableCreate: isPermitted([AdminReportType.REPORT_APP_PERF_TRACKING_INSTALLMENT_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_APP_PERF_TRACKING_INSTALLMENT_UPDATE], permissions),
          },
          {
            title: Translation(REPORT_APP_PERF_TRACKING_ACTIVE_USER_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_APP_PERF_TRACKING_PATH}${REPORT_APP_PERF_TRACKING_ACTIVE_USER}`,
            namespace: REPORT_APP_PERF_TRACKING_ACTIVE_USER_NAMESPACE,
            component: ActiveUserPage,
            enableRead: isPermitted([AdminReportType.REPORT_APP_PERF_TRACKING_ACTIVE_USER_READ], permissions),
            enableCreate: isPermitted([AdminReportType.REPORT_APP_PERF_TRACKING_ACTIVE_USER_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_APP_PERF_TRACKING_ACTIVE_USER_UPDATE], permissions),
          },
        ],
      },
      {
        title: Translation(REPORT_CUSTOMER_CONTACT_TRACKING_TITLE),
        path: `${REPORT_BASE_PATH}${REPORT_CUSTOMER_CONTACT_TRACKING_PATH}`,
        component: CustomerContactTrackingPage,
        namespace: REPORT_CUSTOMER_CONTACT_TRACKING_NAMESPACE,
        enableRead: isPermitted([AdminReportType.REPORT_CUSTOMER_CONTACT_TRACKING_READ], permissions),
      },
      {
        title: Translation(REPORT_QR_CODE_TITLE),
        path: `${REPORT_BASE_PATH}${REPORT_QR_CODE_PATH}`,
        enableRead: isPermitted(
          [AdminReportType.REPORT_QR_CODE_QR_ACTIVITY_READ, AdminReportType.REPORT_QR_CODE_ACTIVITY_RATING_READ],
          permissions,
        ),
        child: [
          {
            title: Translation(REPORT_QR_CODE_QR_ACTIVITY_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_QR_CODE_PATH}${REPORT_QR_CODE_QR_ACTIVITY}`,
            namespace: REPORT_QR_CODE_QR_ACTIVITY_NAMESPACE,
            component: QrActivityReport,
            enableRead: isPermitted([AdminReportType.REPORT_QR_CODE_QR_ACTIVITY_READ], permissions),
            enableCreate: isPermitted([AdminReportType.REPORT_QR_CODE_QR_ACTIVITY_CREATED], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_QR_CODE_QR_ACTIVITY_UPDATE], permissions),
          },
          {
            title: Translation(REPORT_QR_CODE_ACTIVITY_RATING_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_QR_CODE_PATH}${REPORT_QR_CODE_ACTIVITY_RATING}`,
            namespace: REPORT_QR_CODE_ACTIVITY_RATING_NAMESPACE,
            component: ActivityRatingReport,
            enableRead: isPermitted([AdminReportType.REPORT_QR_CODE_ACTIVITY_RATING_READ], permissions),
            enableCreate: isPermitted([AdminReportType.REPORT_QR_CODE_ACTIVITY_RATING_CREATE], permissions),
            enableUpdate: isPermitted([AdminReportType.REPORT_QR_CODE_ACTIVITY_RATING_UPDATE], permissions),
          },
        ],
      },
      {
        title: Translation(REPORT_SURVEY_TITLE),
        path: `${REPORT_BASE_PATH}${REPORT_SURVEY_PATH}`,
        enableRead: isPermitted(
          [
            AdminReportType.REPORT_SERVING_SURVEY_OPENING_READ,
            AdminReportType.REPORT_CUSTOMER_SURVEY_READ,
            AdminReportType.REPORT_NON_PROSPECT_SURVEY_READ,
            AdminReportType.REPORT_AGENT_LEADER_SURVEY_READ,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(REPORT_SURVING_SURVEY_OPENING_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_SURVEY_PATH}${REPORT_SERVING_SURVEY_OPENING_PATH}`,
            enableRead: isPermitted([AdminReportType.REPORT_SERVING_SURVEY_OPENING_READ], permissions),
            namespace: REPORT_SERVING_SURVEY_OPENING_NAMESPACE,
            component: ServingSurveyOpeningReport,
          },
          {
            title: Translation(REPORT_CUSTOMER_SURVEY_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_SURVEY_PATH}${REPORT_CUSTOMER_SURVEY_PATH}`,
            enableRead: isPermitted([AdminReportType.REPORT_CUSTOMER_SURVEY_READ], permissions),
            namespace: REPORT_CUSTOMER_SURVEY_NAMESPACE,
            component: CustomerSurveyReport,
          },
          {
            title: Translation(REPORT_NON_PROSPECT_SURVEY_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_SURVEY_PATH}${REPORT_NON_PROSPECT_SURVEY_PATH}`,
            enableRead: isPermitted([AdminReportType.REPORT_NON_PROSPECT_SURVEY_READ], permissions),
            namespace: REPORT_NON_PROSPECT_SURVEY_NAMESPACE,
            component: NonProspectSurveyReport,
          },
          {
            title: Translation(REPORT_AGENT_LEADER_SURVEY_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_SURVEY_PATH}${REPORT_AGENT_LEADER_SURVEY_PATH}`,
            enableRead: isPermitted([AdminReportType.REPORT_AGENT_LEADER_SURVEY_READ], permissions),
            namespace: REPORT_AGENT_LEADER_SURVEY_NAMESPACE,
            component: AgentLeaderSurveyReport,
          },
        ],
      },
      {
        title: Translation(REPORT_SALE_PLANNING_TITLE),
        path: `${REPORT_BASE_PATH}${REPORT_SALE_PLANNING_PATH}`,
        enableRead: isPermitted(
          [AdminReportType.REPORT_TEAM_SALE_PLANNING_READ, AdminReportType.REPORT_PERSONAL_SALE_PLANNING_READ],
          permissions,
        ),
        child: [
          {
            title: Translation(REPORT_TEAM_SALE_PLANNING_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_SALE_PLANNING_PATH}${REPORT_TEAM_SALE_PLANNING_PATH}`,
            namespace: REPORT_TEAM_SALE_PLANNING_NAMESPACE,
            component: TeamSalePlanningReport,
            enableRead: isPermitted([AdminReportType.REPORT_TEAM_SALE_PLANNING_READ], permissions),
          },
          {
            title: Translation(REPORT_PERSONAL_SALE_PLANNING_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_SALE_PLANNING_PATH}${REPORT_PERSONAL_SALE_PLANNING_PATH}`,
            namespace: REPORT_PERSONAL_SALE_PLANNING_NAMESPACE,
            component: PersonalSalePlanningReport,
            enableRead: isPermitted([AdminReportType.REPORT_PERSONAL_SALE_PLANNING_READ], permissions),
          },
        ],
      },
      {
        title: Translation(REPORT_CANDIDATE_TITLE),
        path: `${REPORT_BASE_PATH}${REPORT_CANDIDATE_PATH}`,
        enableRead: isPermitted(
          [
            AdminReportType.REPORT_CANDIDATE_ASSESSMENT_READ,
            AdminReportType.REPORT_REGISTRATION_CANDIDATE_READ,
            AdminReportType.REPORT_NEW_REGISTERED_CANDIDATE_READ,
            AdminReportType.REPORT_CANDIDATE_DOCUMENT_READ,
            AdminReportType.REPORT_CANDIDATE_INFORMATION_READ,
            AdminReportType.REPORT_CANDIDATE_MANAGEMENT_READ,
            AdminReportType.REPORT_CANDIDATE_SURVEY_READ,
            AdminReportType.REPORT_CANDIDATE_PERFORMANCE_READ,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(REPORT_CANDIDATE_ASSESSMENT_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_CANDIDATE_PATH}${REPORT_CANDIDATE_ASSESSMENT}`,
            namespace: REPORT_CANDIDATE_ASSESSMENT_NAMESPACE,
            component: CandidateAssessmentReport,
            enableRead: isPermitted([AdminReportType.REPORT_CANDIDATE_ASSESSMENT_READ], permissions),
          },
          {
            title: Translation(REPORT_CANDIDATE_DOCUMENT_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_CANDIDATE_PATH}${REPORT_CANDIDATE_DOCUMENT}`,
            namespace: REPORT_CANDIDATE_DOCUMENT_NAMESPACE,
            component: CandidateDocumentReport,
            enableRead: isPermitted([AdminReportType.REPORT_CANDIDATE_DOCUMENT_READ], permissions),
          },
          {
            title: Translation(REPORT_REGISTRATION_CANDIDATE_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_CANDIDATE_PATH}${REPORT_REGISTRATION_CANDIDATE}`,
            namespace: REPORT_REGISTRATION_CANDIDATE_NAMESPACE,
            component: RegistrationCandidateReport,
            enableRead: isPermitted([AdminReportType.REPORT_REGISTRATION_CANDIDATE_READ], permissions),
          },
          // {
          //   title: Translation(REPORT_NEW_REGISTERED_CANDIDATE_TITLE),
          //   path: `${REPORT_BASE_PATH}${REPORT_CANDIDATE_PATH}${REPORT_CANDIDATE_NEW_REGISTERED_CANDIDATE}`,
          //   namespace: REPORT_NEW_REGISTERED_CANDIDATE_NAMESPACE,
          //   component: NewRegisteredCandidateReportCD,
          //   enableRead: isPermitted([
          //     AdminReportType.REPORT_NEW_REGISTERED_CANDIDATE_READ,
          //   ], permissions),
          // },
          {
            title: Translation(REPORT_CANDIDATE_INFORMATION_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_CANDIDATE_PATH}${REPORT_CANDIDATE_INFORMATION}`,
            namespace: REPORT_CANDIDATE_INFORMATION_NAMESPACE,
            component: CandidateInformationReport,
            enableRead: isPermitted([AdminReportType.REPORT_CANDIDATE_INFORMATION_READ], permissions),
          },
          {
            title: Translation(REPORT_CANDIDATE_MANAGEMENT_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_CANDIDATE_PATH}${REPORT_CANDIDATE_MANAGEMENT}`,
            namespace: REPORT_CANDIDATE_MANAGEMENT_NAMESPACE,
            component: CandidateManagementReport,
            enableRead: isPermitted([AdminReportType.REPORT_CANDIDATE_MANAGEMENT_READ], permissions),
          },
          {
            title: Translation(REPORT_CANDIDATE_INTERACTION_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_CANDIDATE_PATH}${REPORT_CANDIDATE_INTERACTION}`,
            namespace: REPORT_CANDIDATE_INTERACTION_NAMESPACE,
            component: CandidateInteractionReport,
            enableRead: isPermitted([AdminReportType.REPORT_CANDIDATE_INFORMATION_READ], permissions),
          },
          {
            title: Translation(REPORT_CANDIDATE_SURVEY_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_CANDIDATE_PATH}${REPORT_CANDIDATE_SURVEY}`,
            namespace: REPORT_CANDIDATE_SURVEY_NAMESPACE,
            component: CandidateSurveyReport,
            enableRead: isPermitted([AdminReportType.REPORT_CANDIDATE_SURVEY_READ], permissions),
          },
          {
            title: Translation(REPORT_CANDIDATE_PERFORMANCE_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_CANDIDATE_PATH}${REPORT_CANDIDATE_PERFORMANCE}`,
            namespace: REPORT_CANDIDATE_PERFORMANCE_NAMESPACE,
            component: CandidatePerformanceReport,
            enableRead: isPermitted([AdminReportType.REPORT_CANDIDATE_PERFORMANCE_READ], permissions),
          },
        ],
      },
      {
        title: Translation(REPORT_LIVESTREAM_TITLE),
        path: `${REPORT_BASE_PATH}${REPORT_LIVESTREAM_PATH}`,
        enableRead: isPermitted(
          [
            AdminReportType.REPORT_LIVESTREAM_DETAILED_COMMENT_READ,
            AdminReportType.REPORT_LIVESTREAM_ACTIVITY_READ,
            AdminReportType.REPORT_LIVESTREAM_PERFORMANCE_READ,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(REPORT_LIVESTREAM_DETAILED_COMMENT_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_LIVESTREAM_PATH}${REPORT_LIVESTREAM_DETAILED_COMMENT}`,
            component: DetailedCommentReportPage,
            namespace: REPORT_LIVESTREAM_DETAILED_COMMENT_NAMESPACE,
            enableRead: isPermitted([AdminReportType.REPORT_LIVESTREAM_DETAILED_COMMENT_READ], permissions),
          },
          {
            title: Translation(REPORT_LIVESTREAM_ACTIVITY_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_LIVESTREAM_PATH}${REPORT_LIVESTREAM_ACTIVITY}`,
            component: ActivityReportPage,
            namespace: REPORT_LIVESTREAM_ACTIVITY_NAMESPACE,
            enableRead: isPermitted([AdminReportType.REPORT_LIVESTREAM_ACTIVITY_READ], permissions),
          },
          {
            title: Translation(REPORT_LIVESTREAM_PERFORMANCE_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_LIVESTREAM_PATH}${REPORT_LIVESTREAM_PERFORMANCE}`,
            component: PerformanceReportPage,
            namespace: REPORT_LIVESTREAM_PERFORMANCE_NAMESPACE,
            enableRead: isPermitted([AdminReportType.REPORT_LIVESTREAM_PERFORMANCE_READ], permissions),
          },
        ],
      },
      {
        title: Translation(REPORT_MDRT_HUB_TITLE),
        path: `${REPORT_BASE_PATH}${REPORT_MDRT_HUB_PATH}`,
        enableRead: isPermitted([AdminReportType.REPORT_MDRT_HUB_SHARING_ACTIVITY_READ], permissions),
        child: [
          {
            title: Translation(REPORT_MDRT_HUB_SHARING_ACTIVITY_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_MDRT_HUB_PATH}${REPORT_MDRT_HUB_SHARING_ACTIVITY_PATH}`,
            component: SharingActivityPage,
            namespace: REPORT_MDRT_HUB_SHARING_ACTIVITY_NAMESPACE,
            enableRead: isPermitted([AdminReportType.REPORT_MDRT_HUB_SHARING_ACTIVITY_READ], permissions),
          },
        ],
      },
      {
        title: Translation(REPORT_NOTIFICATION_TITLE),
        path: `${REPORT_BASE_PATH}${REPORT_NOTIFICATION_PATH}`,
        enableRead: isPermitted([AdminReportType.REPORT_OPENING_NOTIFICATION_READ], permissions),
        child: [
          {
            title: Translation(REPORT_OPENING_NOTIFICATION_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_NOTIFICATION_PATH}${REPORT_OPENING_NOTIFICATION}`,
            component: NotificationOpeningReportPage,
            namespace: REPORT_OPENING_NOTIFICATION_NAMESPACE,
            enableRead: isPermitted([AdminReportType.REPORT_OPENING_NOTIFICATION_READ], permissions),
          },
        ],
      },
      {
        title: Translation(REPORT_EVENT_TITLE),
        path: `${REPORT_BASE_PATH}${REPORT_EVENT_PATH}`,
        namespace: REPORT_EVENT_NAMESPACE,
        enableRead: isPermitted(
          [
            AdminReportType.REPORT_EVENT_READ,
            AdminReportType.REPORT_EVENT_CUSTOMER_READ,
            AdminReportType.REPORT_EVENT_CANDIDATE_READ,
            AdminReportType.REPORT_EVENT_CUSTOMER_AG_VIEW_READ,
          ],
          permissions,
        ),
        child: [
          {
            title: Translation(REPORT_EVENT_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_EVENT_PATH}/list`,
            namespace: REPORT_EVENT_NAMESPACE,
            component: ReportEvent,
            enableRead: isPermitted([AdminReportType.REPORT_EVENT_READ], permissions),
          },
          {
            title: Translation(REPORT_EVENT_CUSTOMER_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_EVENT_PATH}${REPORT_EVENT_CUSTOMER}`,
            namespace: REPORT_EVENT_CUSTOMER_NAMESPACE,
            component: EventManagementCustomer,
            enableRead: isPermitted([AdminReportType.REPORT_EVENT_CUSTOMER_READ], permissions),
          },
          {
            title: Translation(REPORT_EVENT_CUSTOMER_TITLE_AG_VIEW),
            path: `${REPORT_BASE_PATH}${REPORT_EVENT_PATH}${REPORT_EVENT_CUSTOMER_AG_VIEW}`,
            namespace: REPORT_EVENT_CUSTOMER_AG_VIEW_NAMESPACE,
            component: EventManagementCustomerAGView,
            enableRead: isPermitted([AdminReportType.REPORT_EVENT_CUSTOMER_AG_VIEW_READ], permissions),
          },
          {
            title: Translation(REPORT_EVENT_CANDIDATE_TITLE),
            path: `${REPORT_BASE_PATH}${REPORT_EVENT_PATH}${REPORT_EVENT_CANDIDATE}`,
            namespace: REPORT_EVENT_CANDIDATE_NAMESPACE,
            component: EventManagementCandidate,
            enableRead: isPermitted([AdminReportType.REPORT_EVENT_CANDIDATE_READ], permissions),
          },
        ],
      },
      {
        title: REPORT_CUSTOMER_CAMPAIGN_TITLE,
        path: `${REPORT_BASE_PATH}${REPORT_CUSTOMER_CAMPAIGN_PATH}`,
        namespace: REPORT_CUSTOMER_CAMPAIGN_NAMESPACE,
        component: CustomerCampaignReport,
        enableRead: isPermitted([AdminReportType.REPORT_CUSTOMER_CAMPAIGN_READ], permissions),
      },
    ],
  };
};
