import React from 'react';
import { getDisplayDate } from 'src/app/common/utils/time-utils';

interface tableConfigType {
  title: string;
  prop: string;
  align?: any;
  render?: any;
  minWidth?: number;
}

const tableConfig = (Translation: any, i18ConfigData: any): tableConfigType[] => {
  if (i18ConfigData.length === 0) {
    return [];
  }

  return [
    {
      title: Translation('report.event.table.header.formPhoneNumber'),
      prop: 'formPhoneNumber',
      render: (row: any) => <>{row?.agentFormPhone}</>,
    },
    {
      title: Translation('report.event.table.header.formName'),
      prop: 'agentFormName',
      render: (row: any) => <span>{row?.agentFormName}</span>,
    },
    {
      title: Translation('report.event.table.header.agentName'),
      prop: 'agentName',
      render: (row: any) => <span>{row?.agentName}</span>,
    },
    {
      title: Translation('report.event.table.header.agentCode'),
      prop: 'agentCode',
      render: (row: any) => <span>{row?.agentCode}</span>,
    },
    {
      title: Translation('report.event.table.header.officeCode'),
      prop: 'officeCode',
      render: (row: any) => <span>{row?.officeCode}</span>,
    },
    {
      title: Translation('report.event.table.header.role'),
      prop: 'role',
      render: (row: any) => <span>{row?.role}</span>,
    },
    {
      title: Translation('report.event.table.header.unitCode'),
      prop: 'unitCode',
      render: (row: any) => <span>{row?.unitPumCode || row?.unitCode}</span>,
    },
    {
      title: Translation('report.event.table.header.eventName'),
      prop: 'eventName',
      render: (row: any) => <span>{row?.eventName}</span>,
    },
    {
      title: Translation('report.event.table.header.eventTime'),
      prop: 'eventTime',
      render: (row: any) => (
        <span>{row?.durationStartDate ? getDisplayDate(row?.durationStartDate, 'YYYY-MM-DD HH:mm:ss') : ''}</span>
      ),
    },
    {
      title: Translation('report.event.table.header.invatationOpenTime'),
      prop: 'openTime',
      render: (row: any) => <span>{row?.openTime ? getDisplayDate(row?.openTime, 'YYYY-MM-DD HH:mm:ss') : '-'}</span>,
    },
    {
      title: Translation('report.event.table.header.registerTime'),
      prop: 'registrationTime',
      render: (row: any) => (
        <span>{row?.registrationTime ? getDisplayDate(row?.registrationTime, 'YYYY-MM-DD HH:mm:ss') : '-'}</span>
      ),
    },
    {
      title: Translation('report.event.table.header.checkinTime'),
      prop: 'checkInTime',
      render: (row: any) => (
        <span>{row?.checkInTime ? getDisplayDate(row?.checkInTime, 'YYYY-MM-DD HH:mm:ss') : '-'}</span>
      ),
    },
  ];
};

export default tableConfig;
