import { Dispatch } from 'react';
import { ITypeOfView } from '../types/view-types';
import apiClient from './axios';
import { apiErrorHandler } from './apiErrorHandler';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

export const createNewTypeOfView = async (data: ITypeOfView, dispatch?: Dispatch<any>): Promise<ITypeOfView> => {
  let createURL = `${ADMIN_URL}/content-manager/api/type-of-views`;
  return apiClient
    .post<ITypeOfView>(createURL, data, {
      headers: {
        'Content-Type': 'application/json',
        'raw-path': true,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const modifyTypeOfView = async (
  id: string,
  data: ITypeOfView,
  dispatch?: Dispatch<any>,
): Promise<ITypeOfView> => {
  const modifyURL = `${ADMIN_URL}/content-manager/collection-types/api::type-of-view.type-of-view/${id}`;
  return apiClient
    .put<ITypeOfView>(modifyURL, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteTypeOfView = async (id: string, dispatch?: Dispatch<any>): Promise<void> => {
  const deleteURL = `${ADMIN_URL}/content-manager/collection-types/api::type-of-view.type-of-view/${id}`;
  return apiClient
    .delete<void>(deleteURL, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
