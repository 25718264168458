import React, { FC, useMemo, useReducer, useState } from 'react';
import {
  FileUpload,
  QuotesAndCertificateDetail,
  QuotesAndCertificateFormMode,
  QuotesAndCertificateFormState,
  PublishStatus,
} from '../../../../types/quotes-and-certificate-types';
import { Button, FormHelperText, TextField } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useIntl } from 'react-intl';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils/form-error-utils';
import {
  AGENT_CODE_EXISTS_ERROR_TEXT,
  AGENT_CODE_NOT_ACTIVE_ERROR_TEXT,
  AGENT_CODE_NOT_FOUND_ERROR_TEXT,
  MANDATORY_FIELD_ERROR_TEXT,
  MDRT_HUB_BASE_PATH,
  QUOTES_AND_CERTIFICATE_BASE_PATH,
} from '../../../../constants';
import { create, getCertificateByAgentCode, update } from 'src/app/modules/MdrtHub/network/quotesAndCertificateCrud';
import { AgentStatus, StatusModel } from 'src/app/modules/MdrtHub/types/achievement-upload-types';
import { getAgentStatus } from 'src/app/modules/MdrtHub/network/achievementUploadCrud';
import { makeStyles } from 'tss-react/mui';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import { fileUpload, useDebounce } from 'src/app/common/utils';
import FileUploader from 'src/app/common/components/FileUploader';
import { useDispatch } from 'react-redux';
import { createBlob, getBlob } from 'src/app/common/network';
import { Close } from '@mui/icons-material';

type QuotesAndCertificateDetailFormProps = {
  itemId?: string;
  formMode: QuotesAndCertificateFormMode;
  itemDetail?: QuotesAndCertificateDetail;
  onReload: () => void;
  onRouteTo: (route: string) => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  fieldContainer: {
    width: 120,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 60,
  },
  closeIcon: {
    marginLeft: '5px',
    verticalAlign: 'middle',
  },
}));

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof QuotesAndCertificateFormState;
    value: any;
  };
};

type UploadAction = {
  type: 'UPLOAD_CERTIFICATE';
  payload: {
    type: 'certificate';
    value: any;
  };
};

type RemoveAction = {
  type: 'REMOVE_CERTIFICATE';
  payload: {
    type: 'certificate';
    value: any;
  };
};

type MdrtFormAction = ModifyFieldAction | UploadAction | RemoveAction;

const initialState: QuotesAndCertificateFormState = {
  id: undefined,
  agentCode: undefined,
  quotes: undefined,
  certificate: [],
};

const formReducer = (state: QuotesAndCertificateFormState, action: MdrtFormAction): QuotesAndCertificateFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'UPLOAD_CERTIFICATE':
      return {
        ...state,
        certificate: [...state.certificate, action.payload.value],
      };
    case 'REMOVE_CERTIFICATE':
      return {
        ...state,
        certificate: state.certificate.filter((certificate) => certificate.id !== action.payload.value),
      };
    default:
      return state;
  }
};

const detailToStateConvertor = (detail: QuotesAndCertificateDetail): QuotesAndCertificateFormState => {
  return {
    id: detail.id,
    agentCode: detail.agentCode,
    quotes: detail.quotes,
    certificate: detail.certificate || [],
  };
};

const QuotesAndCertificateDetailForm: FC<QuotesAndCertificateDetailFormProps> = ({
  itemId,
  formMode,
  itemDetail,
  onReload,
  onRouteTo,
}) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [isLoading, setIsLoading] = useState(false);
  const [formState, formDispatch] = useReducer(
    formReducer,
    itemDetail ? detailToStateConvertor(itemDetail) : initialState,
  );
  const isDisabled = useMemo(() => {
    return formMode === QuotesAndCertificateFormMode.CREATE || formMode === QuotesAndCertificateFormMode.EDIT
      ? false
      : true;
  }, [formMode]);
  const [agentStatus, setAgentStatus] = useState<AgentStatus>();
  const [existsAgent, setExistsAgent] = useState<Boolean>(false);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'agentCode',
      fieldType: ErrorFieldType.MANDATORY,
    },
  ]);

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        {
          mimeType: fileType,
          accessLevel: 'anonymous',
          module: 'mdrt-quotes-certificate',
        },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Upload successfully - ${fileName}`,
            },
          ]),
        );
        return {
          id: result.blobId,
          fileName: fileName,
          url: result.url,
          type: fileType,
        };
      }
    } catch (err) {
    } finally {
    }
  };

  const oncertificateFile = (result: any) => {
    formDispatch({
      type: 'UPLOAD_CERTIFICATE',
      payload: {
        type: 'certificate',
        value: result,
      },
    });
    onDismissErrorHandler('certificate', result);
  };

  const removeFile = (id: string) => {
    formDispatch({
      type: 'REMOVE_CERTIFICATE',
      payload: {
        type: 'certificate',
        value: id,
      },
    });

    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: `Remove ${id} file successfully`,
        },
      ]),
    );
  };

  const onSubmitForm = async (status: string) => {
    setIsLoading(true);
    let { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      const detail: QuotesAndCertificateDetail = {
        agentCode: formState.agentCode || '',
        quotes: formState.quotes || '',
        certificate: formState.certificate,
        status,
      };
      try {
        if (formMode === QuotesAndCertificateFormMode.CREATE) {
          await create(detail, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Saved successfully - ${detail.agentCode}`,
              },
            ]),
          );
        } else {
          detail.id = itemId;
          await update(detail, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Updated successfully - ${detail.id}`,
              },
            ]),
          );
        }
        onRouteTo(`${MDRT_HUB_BASE_PATH}${QUOTES_AND_CERTIFICATE_BASE_PATH}`);
      } catch (err) {}
    }
    setTimeout(() => dispatch(setIsLoading(false) as any), 2000);
  };

  const previewFile = ({ fileName, url, id }: FileUpload) => {
    return (
      <>
        <div key={fileName} className={classes.rowContainer} style={{ marginTop: 15 }}>
          <div className={classes.fieldContainer}></div>
          <div style={{ flexGrow: 1 }}>
            <div className={classes.rowContainer}>
              <div>
                <a href={url}>{fileName || ''}</a>
                {!isDisabled ? <Close className={classes.closeIcon} onClick={() => removeFile(id)} /> : <></>}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const checkAgentStatus = async (agentCode: string) => {
    setAgentStatus(undefined);
    if (!agentCode) return;
    try {
      const res = await getAgentStatus(agentCode, dispatch);
      if (res && res.agents && res.agents[0]) {
        const agentStatus = res.agents[0];
        setAgentStatus(agentStatus);
      } else {
        setAgentStatus({
          agentCode,
          status: StatusModel.NotFound,
        });
      }
    } catch (error) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'ERROR',
            content: `Get Agent Status ERROR - ${error}`,
          },
        ]),
      );
      setAgentStatus({
        agentCode,
        status: StatusModel.NotFound,
      });
    }
  };

  const checkExistsAgent = async (agentCode: string) => {
    setExistsAgent(false);
    if (!agentCode) return;
    try {
      const res = await getCertificateByAgentCode(agentCode, dispatch);
      if (res) {
        setExistsAgent(true);
      } else {
        setExistsAgent(false);
      }
    } catch (error) {}
  };

  const debounceCheckAgentStatus = useDebounce((agentCode: string) => {
    checkAgentStatus(agentCode);
    checkExistsAgent(agentCode);
  }, 1000);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>
              {Translation(formMode === QuotesAndCertificateFormMode.CREATE ? 'app.button.add' : 'app.button.edit')}
            </div>
          </div>
          <Button
            variant="contained"
            onClick={() => onRouteTo(`${MDRT_HUB_BASE_PATH}${QUOTES_AND_CERTIFICATE_BASE_PATH}/list`)}
          >
            Back
          </Button>
        </div>

        <div className={classes.sectionMargin}>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('mdrtHub.quotesAndCertificate.detail.agentCode')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                style={{ width: '100%' }}
                disabled={isDisabled}
                error={
                  errorState.mandatory.agentCode ||
                  (agentStatus && agentStatus.status !== StatusModel.Active) ||
                  (agentStatus && agentStatus.status === StatusModel.NotFound) ||
                  !!existsAgent
                }
                margin="dense"
                variant="outlined"
                helperText={
                  (errorState.mandatory.agentCode && MANDATORY_FIELD_ERROR_TEXT) ||
                  (agentStatus && agentStatus.status === StatusModel.NotFound && AGENT_CODE_NOT_FOUND_ERROR_TEXT) ||
                  (agentStatus && agentStatus.status !== StatusModel.Active && AGENT_CODE_NOT_ACTIVE_ERROR_TEXT) ||
                  (existsAgent && AGENT_CODE_EXISTS_ERROR_TEXT)
                }
                value={formState.agentCode}
                onChange={(e) => {
                  onDismissErrorHandler('agentCode', e.target.value);
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'agentCode', value: e.target.value },
                  });
                  debounceCheckAgentStatus(e.target.value);
                }}
                inputProps={{ maxLength: 100 }}
              />
            </div>
          </div>
        </div>

        <div className={classes.sectionMargin}>
          <div className={classes.rowContainer} style={{ marginTop: 15 }}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('mdrtHub.quotesAndCertificate.detail.certificate')} :</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <FileUploader
                color="secondary"
                disabled={isDisabled || formState.certificate.length >= 20}
                showAllowText={Translation('mdrtHub.common.imageMaximum')}
                upload={handleUploadFile}
                allowedFileTypes={['jpg', 'png', 'jpeg']}
                maxFileSize={5}
                onChange={oncertificateFile}
              />
              {formState.certificate.length >= 20 && (
                <FormHelperText className={classes.errorText}>Maximum number of images exceeded</FormHelperText>
              )}
            </div>
          </div>
          {formState.certificate?.map((certificate) => certificate?.url && previewFile(certificate))}
        </div>

        <div className={classes.sectionMargin}>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>{Translation('mdrtHub.quotesAndCertificate.detail.quotes')} :</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                style={{ width: '100%' }}
                disabled={isDisabled}
                error={errorState.mandatory.quotes}
                margin="dense"
                variant="outlined"
                value={formState.quotes}
                multiline
                InputProps={{
                  classes: {
                    input: classes.textArea,
                  },
                  inputProps: { maxLength: 250 },
                }}
                onChange={(e) => {
                  formDispatch({
                    type: 'MODIFY_FIELD',
                    payload: { field: 'quotes', value: e.target.value },
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className={classes.footerContainer}>
          {isDisabled ? (
            <></>
          ) : (
            <>
              <Button
                variant="contained"
                color="secondary"
                disabled={isLoading}
                onClick={() => onSubmitForm(PublishStatus.UNPUBLISHED)}
              >
                Save as Draft
              </Button>
              <Button
                style={{ marginLeft: 25 }}
                variant="contained"
                color="secondary"
                disabled={isLoading}
                onClick={() => onSubmitForm(PublishStatus.PUBLISHED)}
              >
                Publish
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default QuotesAndCertificateDetailForm;
