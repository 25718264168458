import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { getDisplayDate } from '../../../../../../common/utils/time-utils';
import { PaginateList } from '../../../../../../common/types/common-types';
import { useIntl } from 'react-intl';
import PruTable from '../../../../../../common/components/PruTable/PruTable';
import { useHistory } from 'react-router-dom';
import { MdrtStampIconFormMode, MdrtStampIconItem, PublishStatus } from '../../../../types/mdrt-stamp-icon-types';
import { publish, unpublish } from '../../../../network/mdrtStampIconCrud';
import { MDRT_HUB_BASE_PATH, MDRT_STAMP_ICON_BASE_PATH } from 'src/app/modules/MdrtHub/constants';

type MdrtStampIconListProps = {
  isLoading: boolean;
  itemList?: PaginateList<MdrtStampIconItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

const MdrtStampIconList: FC<MdrtStampIconListProps> = ({ isLoading, itemList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const onPublish = async (mdrt: MdrtStampIconItem) => {
    try {
      await publish(mdrt.id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Published successfully - ${mdrt.stampName}`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  const onUnpublish = async (mdrt: MdrtStampIconItem) => {
    try {
      await unpublish(mdrt.id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Unpublished successfully - ${mdrt.stampName}`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  return (
    <>
      <PruTable
        title={Translation('mdrtHub.mdrtStampIcon.listing')}
        headerBtnDef={
          [
            // {
            //   color: "primary",
            //   title: Translation("app.button.add"),
            //   onClick: () =>
            //     history.push(
            //       `${MDRT_HUB_BASE_PATH}${MDRT_STAMP_ICON_BASE_PATH}/create`,
            //       { type: MdrtStampIconFormMode.CREATE }
            //     ),
            // },
          ]
        }
        disableBulkSelect
        operationDef={[
          {
            title: Translation('app.button.view'),
            tooltipText: 'View Stamp Icon',
            onClick: (row) =>
              history.push(`${MDRT_HUB_BASE_PATH}${MDRT_STAMP_ICON_BASE_PATH}/edit/${row.id}`, {
                type: MdrtStampIconFormMode.VIEW,
              }),
          },
          {
            title: Translation('app.button.edit'),
            tooltipText: 'Edit Stamp Icon',
            onClick: (row) =>
              history.push(`${MDRT_HUB_BASE_PATH}${MDRT_STAMP_ICON_BASE_PATH}/edit/${row.id}`, {
                type: MdrtStampIconFormMode.EDIT,
              }),
            condition: (row) => row.status !== PublishStatus.PUBLISHED,
          },
          {
            title: Translation('app.button.publish'),
            tooltipText: 'Publish',
            onClick: (row) => onPublish(row),
            condition: (row) => row.status !== PublishStatus.PUBLISHED,
          },
          {
            title: Translation('app.button.unpublish'),
            tooltipText: 'Unpublish',
            onClick: (row) => onUnpublish(row),
            condition: (row) => row.status === PublishStatus.PUBLISHED,
          },
          // {
          //   title: Translation("app.button.delete"),
          //   tooltipText: "Delete",
          //   onClick: (row) => {
          //     idDelete = row.id;
          //     handleModal(true);
          //   },
          //   condition: () => true,
          // },
        ]}
        columnDef={[
          {
            keyIndex: 'name',
            displayName: Translation('mdrtHub.mdrtStampIcon.list.stampCode'),
            renderData: (row) => row.stampCode,
          },
          {
            keyIndex: 'stampName',
            align: 'center',
            displayName: Translation('mdrtHub.mdrtStampIcon.list.stampName'),
            renderData: (row) => row.stampName,
          },
          {
            keyIndex: 'updateAt',
            align: 'center',
            displayName: Translation('mdrtHub.mdrtStampIcon.list.lastUpdatedDate'),
            renderData: (row) => getDisplayDate(new Date(row.updatedAt), 'YYYY-MM-DD HH:mm:ss'),
          },
          {
            keyIndex: 'lastUpdatedBy',
            align: 'center',
            displayName: Translation('mdrtHub.mdrtStampIcon.list.lastUpdatedBy'),
            renderData: (row) => row.lastUpdatedBy || '',
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={itemList?.items}
        totalPages={itemList?.totalPages}
        totalRecords={itemList?.total}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default MdrtStampIconList;
