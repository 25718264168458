import React from 'react';
import { Button } from '@mui/material';

const filterConfig = (Translation: any, onSearch: any, onReset: any, listData: any) => {
  return [
    [
      {
        type: 'header',
        label: Translation('title.report.event.filter'),
        sm: true,
      },
      {
        type: 'custom',
        sm: false,
        render: () => (
          <Button variant="contained" onClick={onReset}>
            {Translation('golbal.filters.reset')}
          </Button>
        ),
      },
      {
        type: 'custom',
        sm: false,
        render: () => (
          <Button variant="contained" color="secondary" onClick={onSearch}>
            {Translation('golbal.filters.search')}
          </Button>
        ),
      },
    ],
    [
      {
        type: 'input',
        label: Translation('title.report.event.event_name'),
        prop: 'eventName',
        width: 300,
      },
      {
        type: 'input',
        label: Translation('title.report.event.location'),
        styles: {},
        prop: 'location',
      },
    ],
    [
      {
        type: 'dateRange',
        label: Translation('title.report.event.duration'),
        styles: {},
        prop: 'duration',
        format: 'dd/MM/yyyy HH:mm:ss',
      },
    ],
  ];
};

export default filterConfig;
