import React, { FC, useReducer, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { assignSurvingServey } from '../../../network/surveyCrud';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import useFilterStyles from 'src/app/common/styles/filter-styles';
import { find } from 'lodash';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { makeStyles } from 'tss-react/mui';
import { toAbsoluteUrl } from 'src/app/common/utils';

type SurveyListingPageProps = {} & ParamsProps;

interface ValidateModel {
  file: any;
  size: number;
  fileName: string;
  isSubmitBeforeUpload?: boolean;
}

const useStyles = makeStyles()(() => ({
  exampleFile: {
    color: 'gray',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  sectionMargin: {
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  fieldContainer: {
    width: 150,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const AssignServingSuveyUploadPage: FC<SurveyListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const maxSize = 2097152;
  const intl = useIntl();
  const { classes } = useStyles();
  const filterClasses = useFilterStyles().classes;
  const dispatch = useDispatch();

  const Translation = (id: string) => intl.formatMessage({ id });

  const [uploadFile, seUploadFile] = useState();
  const [fileName, setFileName] = useState('');
  const [size, setSize] = useState(0);
  const [data, setData] = useState([]);
  let form: any = useRef();

  const initForm = () => {
    seUploadFile(undefined);
    setFileName('');
    setSize(0);
    setData([]);
  };

  const [isOverSize, setIsOverSize] = useState<boolean>(false);
  const [isSubmitBeforeUpload, setIsSubmitBeforeUpload] = useState<boolean>(false);
  const [isWrongFormat, setIsWrongFormat] = useState<boolean>(false);
  const [isSubmitting, setIsSubmiting] = useState<boolean>(false);

  const verifyFile = (fileName: string) => {
    var val = fileName.toLowerCase(),
      regex = new RegExp('(.*?).(csv)$');

    if (!regex.test(val)) {
      return false;
    } else {
      return true;
    }
  };

  const validate = (validateModel: ValidateModel) => {
    validateModel.file ? setIsSubmitBeforeUpload(false) : setIsSubmitBeforeUpload(true);

    !verifyFile(validateModel.fileName) ? setIsWrongFormat(true) : setIsWrongFormat(false);

    validateModel.size > maxSize ? setIsOverSize(true) : setIsOverSize(false);
  };

  const submitAssignServingSurvey = async () => {
    if (form) {
      form.reset();
    }

    if (!uploadFile) {
      setIsSubmitBeforeUpload(true);
      return;
    }

    const res = await assignSurvingServey(uploadFile).catch((err) => {
      initForm();
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: Translation('global.submit.fail'),
            content: Translation('global.submit.fail'),
          },
        ]),
      );
    });

    if (res) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Record saved successfully.`,
          },
        ]),
      );
      setIsSubmiting(false);
      initForm();
    } else {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: Translation('global.submit.fail'),
            content: Translation('global.submit.fail'),
          },
        ]),
      );
      setIsSubmiting(false);
      setIsOverSize(false);
    }
  };

  const onChangeUpload = (e: any) => {
    validate({
      fileName: e.target.files[0].name,
      size: e.target.files[0].size,
      file: e.target.files[0],
    });

    seUploadFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
    setSize(e.target.files[0].size);

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsText(file);
  };

  return (
    <form ref={(el) => (form = el)}>
      <div style={{ marginBottom: 20 }} className={filterClasses.root}>
        <div style={{ marginBottom: 5 }} className="PruFilter-header-container">
          <div className={classes.sectionMargin}>
            <div className={classes.divideMargin}>
              <div className={classes.subHeader}>{Translation('title.assignServingSurvey')}</div>
            </div>

            <div style={{ marginBottom: 10 }} className={classes.rowContainer}>
              <div className={classes.fieldContainer}>
                <span className={classes.field}>
                  {Translation('field.assignServingSurvey.import')}
                  <span className={classes.mandatory}>*</span> :
                </span>
              </div>
              <input
                style={{ display: 'none' }}
                id="contained-button-file"
                type="file"
                accept=".csv"
                onChange={(e: any) => {
                  onChangeUpload(e);
                  setIsSubmiting(false);
                }}
              />
              <label style={{ marginRight: 5 }}>{fileName ? <div> {fileName}</div> : null}</label>
              <label style={{ marginRight: 15 }} htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span">
                  Upload
                </Button>
              </label>
              <a
                download
                href={toAbsoluteUrl('/templates/Template_Assign_Serving_Survey.csv')}
                className={classes.exampleFile}
              >
                <i>Example.csv</i>
              </a>
            </div>
            {isSubmitBeforeUpload ? (
              <div style={{ marginBottom: 10 }} className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}></span>
                </div>
                <div className={classes.mandatory}>{Translation('field.assignServingSurvey.import')}</div>
              </div>
            ) : null}
            {isOverSize ? (
              <div style={{ marginBottom: 10 }} className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}></span>
                </div>
                <div className={classes.mandatory}>{Translation('error.assignServingSurvey.overSize')}</div>
              </div>
            ) : null}
            {isWrongFormat ? (
              <div style={{ marginBottom: 10 }} className={classes.rowContainer}>
                <div className={classes.fieldContainer}>
                  <span className={classes.field}></span>
                </div>
                <div className={classes.mandatory}>{Translation('error.assignServingSurvey.wrongFormat')}</div>
              </div>
            ) : null}
          </div>
        </div>
        <div className={classes.footerContainer}>
          <Button
            variant="contained"
            color="secondary"
            disabled={isSubmitting || isOverSize || isWrongFormat || isSubmitBeforeUpload}
            onClick={(e: any) => {
              setIsSubmiting(true);
              e.preventDefault();
              submitAssignServingSurvey();
            }}
          >
            {Translation('button.assignServingSurvey.assign')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AssignServingSuveyUploadPage;
