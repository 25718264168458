import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { getDisplayDate } from '../../../../../../common/utils/time-utils';
import { PaginateList } from '../../../../../../common/types/common-types';
import { useIntl } from 'react-intl';
import PruTable from '../../../../../../common/components/PruTable/PruTable';
import { useHistory } from 'react-router-dom';
import { ACHIEVEMENT_UPLOAD_BASE_PATH, MDRT_HUB_BASE_PATH } from 'src/app/modules/MdrtHub/constants';
import {
  AchievementUploadFormMode,
  AchievementUploadItem,
  PublishStatus,
} from '../../../../types/achievement-upload-types';
import {
  deleteAchievement,
  publishAchievement,
  unpublishAchievement,
} from 'src/app/modules/MdrtHub/network/achievementUploadCrud';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';

type AchievementUploadListProps = {
  isLoading: boolean;
  achievementUploadList?: PaginateList<AchievementUploadItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const AchievementUploadList: FC<AchievementUploadListProps> = ({
  isLoading,
  achievementUploadList,
  onRefresh,
  onChangePage,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onPublish = async (item: AchievementUploadItem) => {
    try {
      await publishAchievement(item.id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Published successfully - ${item.id}`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  const onUnpublish = async (item: AchievementUploadItem) => {
    try {
      await unpublishAchievement(item.id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Unpublished successfully - ${item.id}`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  const onDelete = async (id: string) => {
    try {
      await deleteAchievement(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Deleted successfully - ${id}`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  return (
    <>
      <ItemDeleteDialog
        key={`delete-sla-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
      />
      <PruTable
        title={Translation('mdrtHub.achievementUpload.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('mdrtHub.common.upload'),
            onClick: () => history.push(`${MDRT_HUB_BASE_PATH}${ACHIEVEMENT_UPLOAD_BASE_PATH}/upload`),
          },
          {
            color: 'primary',
            title: Translation('mdrtHub.common.add'),
            onClick: () =>
              history.push(`${MDRT_HUB_BASE_PATH}${ACHIEVEMENT_UPLOAD_BASE_PATH}/add`, {
                type: AchievementUploadFormMode.CREATE,
              }),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('mdrtHub.common.view'),
            tooltipText: 'View Achievement Upload',
            onClick: (row) =>
              history.push(`${MDRT_HUB_BASE_PATH}${ACHIEVEMENT_UPLOAD_BASE_PATH}/edit/${row.id}`, {
                type: AchievementUploadFormMode.VIEW,
              }),
          },
          {
            title: Translation('mdrtHub.common.edit'),
            tooltipText: 'Edit Achievement Upload',
            onClick: (row) =>
              history.push(`${MDRT_HUB_BASE_PATH}${ACHIEVEMENT_UPLOAD_BASE_PATH}/edit/${row.id}`, {
                type: AchievementUploadFormMode.EDIT,
              }),
            condition: (row) => row.status !== PublishStatus.PUBLISHED,
          },
          {
            title: Translation('mdrtHub.common.publish'),
            tooltipText: 'Publish Achievement Upload',
            onClick: (row) => onPublish(row),
            condition: (row) => row.status !== PublishStatus.PUBLISHED,
          },
          {
            title: Translation('mdrtHub.common.unpublish'),
            tooltipText: 'Unpublish Achievement Upload',
            onClick: (row) => onUnpublish(row),
            condition: (row) => row.status === PublishStatus.PUBLISHED,
          },
          {
            title: Translation('mdrtHub.common.delete'),
            tooltipText: 'Delete Achievement Upload',
            onClick: (row) => setDialogState({ open: true, id: row.id }),
            condition: (row) => row.status !== PublishStatus.PUBLISHED,
          },
        ]}
        columnDef={[
          {
            keyIndex: 'agentCode',
            displayName: Translation('mdrtHub.achievementUpload.agentCode'),
            renderData: (row) => row.agentCode,
          },
          {
            keyIndex: 'totalRow',
            align: 'center',
            displayName: Translation('mdrtHub.achievementUpload.totalRow'),
            renderData: (row) => row.totalRow?.toString() || '',
          },
          {
            keyIndex: 'lastUpdatedBy',
            align: 'center',
            displayName: Translation('mdrtHub.achievementUpload.lastUpdatedBy'),
            renderData: (row) => row.lastUpdatedBy?.toString() || '',
          },
          {
            keyIndex: 'lastUpdatedDate',
            align: 'center',
            displayName: Translation('mdrtHub.achievementUpload.lastUpdatedDate'),
            renderData: (row) => getDisplayDate(new Date(row.updatedAt), 'YYYY-MM-DD HH:mm'),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={achievementUploadList?.items}
        totalPages={achievementUploadList?.totalPages}
        totalRecords={achievementUploadList?.total}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default AchievementUploadList;
