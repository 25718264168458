export const DATE_ERROR_TEXT = 'End date must be after Begin date';
export const MANDATORY_FIELD_ERROR_TEXT = 'This field is mandatory';
export const CHOOSE_ONE_ERROR_TEXT = 'One of the fields must be filled';
export const DATE_FORMAT_ERROR_TEXT = 'Invalid date format';
export const YEAR_FIELD_ERROR_TEXT = 'Agent data of this year already exists.';
export const AGENT_CODE_NOT_ACTIVE_ERROR_TEXT = 'Can not upload for this agent because agent is not active';
export const AGENT_CODE_NOT_FOUND_ERROR_TEXT = 'Can not find agent code';
export const AGENT_CODE_EXISTS_ERROR_TEXT = 'This agent already has data';

export const supportLangList = ['vi', 'en'];
export const mainLang = 'vi';
export const subLang = 'en';

export const MDRT_HUB_TITLE = "mdrtHub.title";
export const MDRT_HUB_BASE_PATH = "/mdrt-hub";

//------------------------------- Achievement Upload -----------------------------------//
export const ACHIEVEMENT_UPLOAD_BASE_PATH = '/achievement-upload';
export const ACHIEVEMENT_UPLOAD_NAMESPACE = "ACHIEVEMENT-UPLOAD";
export const ACHIEVEMENT_UPLOAD_TITLE = "mdrtHub.achievementUpload.title";

export enum AdminMdrtAchievementUploadPermissionType {
  ACHIEVEMENT_UPLOAD_READ = "READ_ANY_ACHIEVEMENT-UPLOAD",
  ACHIEVEMENT_UPLOAD_CREATE = "CREATE_ANY_ACHIEVEMENT-UPLOAD",
  ACHIEVEMENT_UPLOAD_UPDATE = "UPDATE_ANY_ACHIEVEMENT-UPLOAD",
  ACHIEVEMENT_UPLOAD_DELETE = "DELETE_ANY_ACHIEVEMENT-UPLOAD"
};
//------------------------------- END Quotes And Certificate --------------------------------//

//------------------------------- MDRT_PASSPORT -----------------------------------//
export const MDRT_PASSPORT_BASE_PATH = '/mdrt-passport';
export const MDRT_PASSPORT_NAMESPACE = "MDRT-PASSPORT";
export const MDRT_PASSPORT_TITLE = "mdrtHub.mdrtPassport.title";

export enum AdminMdrtPassportPermissionType {
  MDRT_PASSPORT_READ = "READ_ANY_MDRT-PASSPORT",
  MDRT_PASSPOR_CREATE = "CREATE_ANY_MDRT-PASSPORT",
  MDRT_PASSPOR_UPDATE = "UPDATE_ANY_MDRT-PASSPORT",
  MDRT_PASSPOR_DELETE = "DELETE_ANY_MDRT-PASSPORT"
};
//------------------------------- END MDRT_PASSPORT --------------------------------//


//------------------------------- Quotes And Certificate -----------------------------------//
export const QUOTES_AND_CERTIFICATE_BASE_PATH = '/quotes-and-certificate';
export const QUOTES_AND_CERTIFICATE_NAMESPACE = "QUOTES-AND-CERTIFICATE";
export const QUOTES_AND_CERTIFICATE_TITLE = "mdrtHub.quotesAndCertificate.title";

export enum AdminQuotesAndCertificatePermissionType {
  QUOTES_AND_CERTIFICATE_READ = "READ_ANY_QUOTES-AND-CERTIFICATE",
  QUOTES_AND_CERTIFICATE_CREATE = "CREATE_ANY_QUOTES-AND-CERTIFICATE",
  QUOTES_AND_CERTIFICATE_UPDATE = "UPDATE_ANY_QUOTES-AND-CERTIFICATE",
  QUOTES_AND_CERTIFICATE_DELETE = "DELETE_ANY_QUOTES-AND-CERTIFICATE"
};
//------------------------------- END Quotes And Certificate --------------------------------//

//------------------------------- MDRT Stamp Icon -----------------------------------//
export const MDRT_STAMP_ICON_BASE_PATH = '/mdrt-stamp-icon';
export const MDRT_STAMP_ICON_NAMESPACE = "MDRT-STAMP-ICON";
export const MDRT_STAMP_ICON_TITLE = "mdrtHub.mdrtStampIcon.title";

export enum AdminMdrtStampIconPermissionType {
  MDRT_STAMP_ICON_READ = "READ_ANY_MDRT-STAMP-ICON",
  MDRT_STAMP_ICON_CREATE = "CREATE_ANY_MDRT-STAMP-ICON",
  MDRT_STAMP_ICON_UPDATE = "UPDATE_ANY_MDRT-STAMP-ICON",
  MDRT_STAMP_ICON_DELETE = "DELETE_ANY_MDRT-STAMP-ICON"
};
//------------------------------- END MDRT Stamp Icon --------------------------------//

//------------------------------- MDRT Passport Background -----------------------------------//
export const MDRT_PASSPORT_BACKGROUND_BASE_PATH = '/mdrt-passport-background';
export const MDRT_PASSPORT_BACKGROUND_NAMESPACE = "MDRT-PASSPORT-BACKGROUND";
export const MDRT_PASSPORT_BACKGROUND_TITLE = "mdrtHub.mdrtPassportBackground.title";

export enum AdminMdrtPassportBackgroundPermissionType {
  MDRT_PASSPORT_BACKGROUND_READ = "READ_ANY_MDRT-PASSPORT-BACKGROUND",
  MDRT_PASSPORT_BACKGROUND_UPDATE = "UPDATE_ANY_MDRT-PASSPORT-BACKGROUND",
};
//------------------------------- END MDRT Passport Background --------------------------------//

//------------------------------- Mdrt Story -----------------------------------//
export const MDRT_STORY_BASE_PATH = '/mdrt-story';
export const MDRT_STORY_NAMESPACE = "MDRT-STORY";
export const MDRT_STORY_TITLE = "mdrtHub.mdrtStory.title";

export enum AdminMdrtStoryPermissionType {
  MDRT_STORY_READ = "READ_ANY_MDRT-STORY",
  MDRT_STORY_CREATE = "CREATE_ANY_MDRT-STORY",
  MDRT_STORY_UPDATE = "UPDATE_ANY_MDRT-STORY",
  MDRT_STORY_DELETE = "DELETE_ANY_MDRT-STORY"
};
//------------------------------- END Mdrt Story --------------------------------//

//------------------------------- Mdrt Video Library -----------------------------------//
export const MDRT_VIDEO_LIBRARY_BASE_PATH = '/mdrt-video-library';
export const MDRT_VIDEO_LIBRARY_NAMESPACE = "MDRT-VIDEO-LIBRARY";
export const MDRT_VIDEO_LIBRARY_TITLE = "mdrtHub.mdrtVideoLibrary.title";

export enum AdminMdrtVideoLibraryPermissionType {
  MDRT_VIDEO_LIBRARY_READ = "READ_ANY_MDRT-VIDEO-LIBRARY",
  MDRT_VIDEO_LIBRARY_CREATE = "CREATE_ANY_MDRT-VIDEO-LIBRARY",
  MDRT_VIDEO_LIBRARY_UPDATE = "UPDATE_ANY_MDRT-VIDEO-LIBRARY",
  MDRT_VIDEO_LIBRARY_DELETE = "DELETE_ANY_MDRT-VIDEO-LIBRARY"
};
//------------------------------- END Mdrt Video Library --------------------------------//

//------------------------------- Mdrt Banner -----------------------------------//
export const MDRT_BANNER_BASE_PATH = '/mdrt-banner';
export const MDRT_BANNER_NAMESPACE = "MDRT-BANNER";
export const MDRT_BANNER_TITLE = "mdrtHub.mdrtBanner.title";

export enum AdminMdrtBannerPermissionType {
  MDRT_BANNER_READ = "READ_ANY_MDRT-BANNER",
  MDRT_BANNER_CREATE = "CREATE_ANY_MDRT-BANNER",
  MDRT_BANNER_UPDATE = "UPDATE_ANY_MDRT-BANNER",
  MDRT_BANNER_DELETE = "DELETE_ANY_MDRT-BANNER"
};
//------------------------------- END Mdrt Banner --------------------------------//