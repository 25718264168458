import { mainLang, subLang } from "src/app/modules/Operation/constants";

export enum RedirectType {
  no = "no",
  external = "external",
}

export enum OpenByType {
  "pruforce-webview" = "webview",
  "device-default-browser" = "browser",
}

export enum CanBeShared {
  "yes" = "Y",
  "no" = "N",
}

export enum PublishStatus {
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED",
}

export enum MdrtBannerFormMode {
  CREATE = "create",
  EDIT = "edit",
  VIEW = "view",
}

export type MdrtBannerItem = {
  _id: string;
  linkType: RedirectType | null;
  linkTo: string | null;
  canShare: boolean | null;
  openBy: string | null;
  effectiveDateFrom: Date | null;
  effectiveDateTo: Date | null;
  publishAt: Date;
  publishStatus: PublishStatus;
  updatedAt: Date;
  updatedBy: string;
  createdAt: Date;
  createdBy: string;
  typeOfViews: string[];
  groupUserFile?: FileUpload | null;
  groupUserList: string[];
  i18nData: {
    [key in typeof mainLang | typeof subLang | string]: {
      banner?: FileUpload;
      name: string;
    };
  };
};

export type FileUpload = {
  url?: string;
  id: string;
  fileName: string;
  inclusive?: boolean;
  receiverType?: string;
  contentType?: string;
  fileType: string;
  importId?: string;
};

export type MdrtBannerDetail = {
  id?: string;
  linkType: RedirectType | null;
  linkTo: string | null;
  canShare: boolean | null;
  openBy: string | null;
  effectiveDateFrom: Date | null;
  effectiveDateTo: Date | null;
  publishStatus: PublishStatus;
  typeOfViews: string[];
  groupUserFile?: FileUpload | null;
  groupUserList: string[];
  i18nData: {
    [key in typeof mainLang | typeof subLang | string]: {
      banner?: FileUpload;
      name: string;
    };
  };
};

export type MdrtBannerFormState = {
  id?: string;
  linkType: RedirectType | null;
  linkTo: string | null;
  canShare: CanBeShared | null;
  openBy: string | null;
  typeOfViews: string[];
  groupUserFile?: FileUpload | null;
  groupUserList: string[];
  publishStatus: PublishStatus | null;
  i18nData: {
    [key in typeof mainLang | typeof subLang | string]: {
      banner?: FileUpload;
      name: string;
    };
  };
  effectiveDate: any[];
};

export type i18nDataFieldType = `i18nData.${
  | typeof mainLang
  | typeof subLang
  | string}`;

// export type NestedKeyOf<T> = {
//   [Key in keyof T]: T[Key] extends
//     | string
//     | number
//     | boolean
//     | null
//     | any[]
//     | Date
//     | FileUpload
//     | undefined
//     ? Key
//     : `${string & Key}.${string & NestedKeyOf<T[Key]>}`;
// }[keyof T];

export type Recursion<T> = keyof {
  [Property in keyof T as T[Property] extends
    | string
    | number
    | boolean
    | null
    | any[]
    | Date
    | FileUpload
    | undefined
    ? Property
    : `${string & Property}.${string & Recursion<T[Property]>}`]: true;
};

export const LINK_TYPE_TRANSLATE_MAP ={
  'no': 'component.formSelectItem.no-link',
  'app': 'component.formSelectItem.page-in-app',
  'external': 'component.formSelectItem.external-url'
}

export enum BannerPublishedMode {
  ALL = "ALL",
  PUBLISHED = "Published",
  UNPUBLISHED = "Unpublished"
}

export enum BannerValidMode {
  ALL = "ALL",
  VALID = "Valid",
  INVALID = "Invalid"
}