import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RegionLocale, regionLocale } from 'src/app/i18n';
import { PaginateList } from 'src/app/common/types';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { getDefaultDisplayDate } from 'src/app/common/utils';
import ItemDeleteDialog from 'src/app/common/components/ItemDeleteDialog';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { KpiItem } from '../../../../types/kpi-types';
import { deleteKpiItem } from '../../../../network/kpiCrud';
import { kpiPath } from '../../KpiRoutes';

type KpiListProps = {
  isLoading: boolean;
  kpiList?: PaginateList<KpiItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

type DialogState = {
  open: boolean;
  id: string;
};

const KpiList: FC<KpiListProps> = ({ isLoading, kpiList, onRefresh, onChangePage }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const history = useHistory();
  const locale = RegionLocale.VIETNAMESE;

  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    id: '',
  });

  const onDelete = async (id: string) => {
    try {
      await deleteKpiItem(id, dispatch);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `KPI deleted successfully`,
          },
        ]),
      );
    } catch (err) {}
  };

  return (
    <>
      <ItemDeleteDialog
        key={`delete-kpi-dialog-${dialogState.open}`}
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, id: '' })}
        onRefresh={onRefresh}
        onDelete={() => onDelete(dialogState.id)}
      />
      <PruTable
        title={Translation('incentive.kpi.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('incentive.kpi.form.title.create'),
            onClick: () => history.push(`${kpiPath}/create`),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('section.common.operation.edit'),
            tooltipText: 'Edit KPI',
            onClick: (row) => history.push(`${kpiPath}/edit/${row._id}`),
          },
          {
            title: Translation('section.common.operation.delete'),
            tooltipText: 'Delete KPI',
            onClick: (row) => setDialogState({ open: true, id: row._id }),
          },
        ]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: () => '',
          },
          // ...(() =>
          //   regionLocale.map((locale) => (
          {
            keyIndex: `name-${locale}`,
            displayName: Translation(`incentive.kpi.common.name`),
            renderData: (row: KpiItem) => row.name[locale] || '-',
            // })))()
          },
          {
            keyIndex: 'key',
            align: 'center',
            displayName: Translation('incentive.kpi.common.key'),
            renderData: (row) => row.key || '-',
          },
          {
            keyIndex: 'type',
            align: 'center',
            displayName: Translation('incentive.kpi.common.type'),
            renderData: (row) => (row.type || '-').toUpperCase(),
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: Translation('incentive.common.createdDate'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.createdAt)),
          },
          {
            keyIndex: 'updatedAt',
            align: 'center',
            displayName: Translation('incentive.common.updatedDate'),
            renderData: (row) => getDefaultDisplayDate(new Date(row.updatedAt)),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={kpiList?.docs}
        totalPages={kpiList?.totalPages}
        totalRecords={kpiList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default KpiList;
