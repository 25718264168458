import { isArray } from 'lodash';
import moment from 'moment';
import React, { FC, useState } from 'react';

interface CustomProps {
  children?: any;
  value?: any[];
  onChange?: (e: any[]) => void;
  style?: any;
  childStyle?: any;
}

const Error = ({ children, label }: { children: React.ReactNode[]; label?: React.ReactNode }) => (
  <ul
    style={{
      marginLeft: label ? 160 : 0,
      paddingLeft: 0,
      listStyle: 'none',
      color: '#f018a6',
      marginRight: 14,
      marginTop: 4,
      margin: 0,
      fontSize: '0.95rem',
      textAlign: 'left',
      fontFamily: 'Poppins',
      fontWeight: 400,
      lineHeight: 1.66,
    }}
  >
    {((children && children.length && [children[0]]) || []).map((error: React.ReactNode, index: number) => (
      <li key={index}>{error}</li>
    ))}
  </ul>
);

const FormItemDateCustom: FC<CustomProps> = ({ children, onChange, value = [], style, childStyle }) => {
  const [errorVaildData, setErrorVaildData] = useState(false);

  const childNode = React.cloneElement(children as React.ReactElement, {
    ...{ value },
    ...{
      onChange: (e: any) => {
        if (onChange) {
          if (isArray(e) && e.length > 0) {
            for (let i = 0; i < e.length; i++) {
              if (e[i]) {
                value[i] = e[i];
              }
            }
            onChange(value);

            // validate Date
            if (value[0] && value[1]) {
              if (moment(value[0]).isAfter(moment(value[1]))) {
                if (!errorVaildData) {
                  setErrorVaildData(true);
                }
              } else {
                if (errorVaildData) {
                  setErrorVaildData(false);
                }
              }
            } else {
              if (errorVaildData) {
                setErrorVaildData(false);
              }
            }
          } else {
            onChange(e);
          }
        }

        if (children && children !== 'function') {
          let cloneChildren = Object.assign({}, children) as any;

          if (cloneChildren && cloneChildren.props && cloneChildren.props.onChange) {
            cloneChildren.props.onChange(e);
          }
        }
      },
    },
  });
  return (
    <div style={{ position: 'relative', width: '100%', ...style }}>
      <div style={{ display: 'flex', alignItems: 'flex-start', ...childStyle }}>
        <>{childNode}</>
      </div>
      {errorVaildData ? <Error>{[<>{'Start time can not be later than end time.'}</>]}</Error> : <></>}
    </div>
  );
};

export default FormItemDateCustom;
