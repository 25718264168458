import React, { FC, useMemo, useReducer } from 'react';
import { FileUpload, MdrtDetail, MdrtFormMode, MdrtFormState, PublishStatus } from '../../../types/mdrt-types';
import { Button, InputAdornment, TextField } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils/form-error-utils';
import { MANDATORY_FIELD_ERROR_TEXT, MDRT_BASE_PATH } from '../../../constants';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import FileUploader from 'src/app/common/components/FileUploader';
import { createNewMdrt, updateMdrt } from '../../../network/mdrtCrud';
import { makeStyles } from 'tss-react/mui';
import { createBlob, getBlob } from 'src/app/common/network';
import { fileUpload } from 'src/app/common/utils';

type MdrtDetailFormProps = {
  mdrtId?: string;
  formMode: MdrtFormMode;
  mdrtDetail?: MdrtDetail;
  onReload: () => void;
  onRouteTo: (route: string) => void;
};

const numberSeparator = (num: string) => {
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const removeSeparator = (str: string) => {
  return str.replace(/\./g, '');
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  fieldContainer: {
    width: 120,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  dateDivider: {
    marginLeft: 8,
    marginRight: 8,
  },
  sectionMargin: {
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },

  flexItem: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '33.333333%',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  iconContainer: {
    width: 60,
    height: 60,
    overflow: 'hidden',
    borderRadius: 12,
  },
}));

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof MdrtFormState;
    value: any;
  };
};

type UploadAction = {
  type: 'UPLOAD_IMG';
  payload: {
    type: 'banner' | 'benefit' | 'condition';
    id: string;
    url: string;
    fileName: string;
    fileType: string;
  };
};

type MdrtFormAction = ModifyFieldAction | UploadAction;

const initialState: MdrtFormState = {
  mdrtId: undefined,
  name: undefined,
  status: undefined,
  quarter1: undefined,
  quarter2: undefined,
  quarter3: undefined,
  mdrt: undefined,
  cot: undefined,
  tot: undefined,
  banner: undefined,
  benefit: undefined,
  condition: undefined,
};

const formReducer = (state: MdrtFormState, action: MdrtFormAction): MdrtFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'UPLOAD_IMG':
      if (action.payload.type === 'banner') {
        return {
          ...state,
          banner: {
            id: action.payload.id,
            fileName: action.payload.fileName,
            url: action.payload.url,
            fileType: action.payload.fileType,
          },
        };
      } else if (action.payload.type === 'benefit') {
        return {
          ...state,
          benefit: {
            id: action.payload.id,
            fileName: action.payload.fileName,
            url: action.payload.url,
            fileType: action.payload.fileType,
          },
        };
      } else {
        return {
          ...state,
          condition: {
            id: action.payload.id,
            fileName: action.payload.fileName,
            url: action.payload.url,
            fileType: action.payload.fileType,
          },
        };
      }
  }
};

const detailToStateConvertor = (detail: MdrtDetail): MdrtFormState => {
  return {
    mdrtId: detail.id,
    status: detail.status,
    name: detail.name,
    quarter1: String(numberSeparator(String(detail.quarter1))) || '',
    quarter2: String(numberSeparator(String(detail.quarter2))) || '',
    quarter3: String(numberSeparator(String(detail.quarter3))) || '',
    mdrt: String(numberSeparator(String(detail.mdrt))) || '',
    cot: String(numberSeparator(String(detail.cot))) || '',
    tot: String(numberSeparator(String(detail.tot))) || '',
    banner: detail.banner,
    benefit: detail.benefit,
    condition: detail.condition,
  };
};

const targetsAccordingField = ['quarter1', 'quarter2', 'quarter3', 'mdrt', 'cot', 'tot'];

const MdrtDetailForm: FC<MdrtDetailFormProps> = ({ mdrtId, formMode, mdrtDetail, onReload, onRouteTo }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const commonClasses = useCommonStyles().classes;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [formState, formDispatch] = useReducer(
    formReducer,
    mdrtDetail ? detailToStateConvertor(mdrtDetail) : initialState,
  );
  const isDisabled = useMemo(() => {
    return formMode === MdrtFormMode.CREATE || formMode === MdrtFormMode.EDIT ? false : true;
  }, [formMode]);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'name',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'quarter1',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'quarter2',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'quarter3',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'cot',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'mdrt',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'tot',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'banner',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        return !!!(formState.banner && formState.banner.id && formState.banner.url && formState.banner.fileName);
      },
    },
    {
      name: 'benefit',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        return !!!(formState.benefit && formState.benefit.id && formState.benefit.fileName && formState.benefit.url);
      },
    },
    {
      name: 'condition',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        return !!!(
          formState.condition &&
          formState.condition.id &&
          formState.condition.url &&
          formState.condition.fileName
        );
      },
    },
  ]);

  const handleUploadFile = async ({
    file,
    fileName,
    fileType,
    description = '',
  }: {
    file: any;
    fileName: string;
    fileType: string;
    description?: string;
  }) => {
    try {
      const createBlobRes = await createBlob(
        { mimeType: fileType, accessLevel: 'anonymous', module: 'mdrt' },
        dispatch,
      );
      await fileUpload(createBlobRes.url, file, () => {});
      const blobDetail = await getBlob({ resourceIds: createBlobRes.blobId }, dispatch);
      const result = blobDetail[0];
      if (result) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Upload successfully - ${fileName}`,
            },
          ]),
        );
        return { id: result.blobId, fileName: fileName, url: result.url, type: fileType };
      }
    } catch (err) {
    } finally {
    }
  };

  const onChangeBannerFile = (result: any) => {
    formDispatch({
      type: 'UPLOAD_IMG',
      payload: {
        type: 'banner',
        id: result.id,
        url: result.url,
        fileName: result.fileName,
        fileType: result.type,
      },
    });
    onDismissErrorHandler('banner', result);
  };
  const onChangeBenefitFile = (result: any) => {
    formDispatch({
      type: 'UPLOAD_IMG',
      payload: {
        type: 'benefit',
        id: result.id,
        url: result.url,
        fileName: result.fileName,
        fileType: result.type,
      },
    });
    onDismissErrorHandler('benefit', result);
  };
  const onChangeConditionFile = (result: any) => {
    formDispatch({
      type: 'UPLOAD_IMG',
      payload: {
        type: 'condition',
        id: result.id,
        url: result.url,
        fileName: result.fileName,
        fileType: result.type,
      },
    });
    onDismissErrorHandler('condition', result);
  };

  const removeFile = (type: 'banner' | 'benefit' | 'condition') => {
    formDispatch({
      type: 'UPLOAD_IMG',
      payload: {
        type,
        id: '',
        url: '',
        fileName: '',
        fileType: '',
      },
    });
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: `Remove ${type} file successfully`,
        },
      ]),
    );
  };

  const onSubmitForm = async (status: string) => {
    let {
      hasError,
      currentErrorState: {
        mandatory: { banner, benefit, condition },
      },
    } = onSubmitErrorValidator();

    if (banner || benefit || condition) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.WARNING,
            title: 'Warning',
            content: `Please upload file ${banner ? '[Banner]' : ''} ${benefit ? '[Benefit]' : ''}  ${
              condition ? '[Condition]' : ''
            }`,
          },
        ]),
      );
    }

    if (!hasError) {
      const detail: MdrtDetail = {
        name: formState.name || '',
        quarter1: Number(removeSeparator(formState.quarter1 || '')),
        quarter2: Number(removeSeparator(formState.quarter2 || '')),
        quarter3: Number(removeSeparator(formState.quarter3 || '')),
        mdrt: Number(removeSeparator(formState.mdrt || '')),
        cot: Number(removeSeparator(formState.cot || '')),
        tot: Number(removeSeparator(formState.tot || '')),
        banner: formState.banner,
        benefit: formState.benefit,
        condition: formState.condition,
        status,
      };
      try {
        if (formMode === MdrtFormMode.CREATE) {
          await createNewMdrt(detail, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Saved successfully - ${detail.name}`,
              },
            ]),
          );
        } else {
          detail.id = mdrtId;
          await updateMdrt(detail, dispatch);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: 'Success',
                content: `Updated successfully - ${detail.name}`,
              },
            ]),
          );
        }
        onRouteTo(MDRT_BASE_PATH);
      } catch (err) {}
    }
  };

  const targetsAccordingForm = (props: any) => {
    const { title, error, value, field } = props;
    return (
      <div className={classes.flexItem}>
        <div className={classes.fieldContainer}>
          <span className={classes.field}>
            {title}
            <span className={classes.mandatory}>*</span> :
          </span>
        </div>
        <div style={{ flexGrow: 1 }}>
          <TextField
            style={{ width: 200 }}
            disabled={isDisabled}
            error={error}
            margin="dense"
            variant="outlined"
            helperText={error && MANDATORY_FIELD_ERROR_TEXT}
            value={value}
            onChange={(e) => {
              const onlyNums = e.target.value.replace(/[^0-9]/g, '');
              onDismissErrorHandler(field, e.target.value);
              formDispatch({ type: 'MODIFY_FIELD', payload: { field, value: numberSeparator(onlyNums) } });
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">VND</InputAdornment>,
            }}
            inputProps={{ min: 1, maxLength: 15 }}
          />
        </div>
      </div>
    );
  };

  const previewFile = ({ fileName, url, fileType }: FileUpload) => {
    return (
      <>
        <div className={classes.rowContainer} style={{ marginTop: 15 }}>
          <div className={classes.fieldContainer}></div>
          <div style={{ flexGrow: 1 }}>
            <div className={classes.rowContainer}>
              <div>
                <a href={url}>{fileName || ''}</a>
              </div>
            </div>
            <div className={classes.rowContainer}>
              {url && fileType !== 'application/pdf' && (
                <a href={url}>
                  <img style={{ width: 'auto', height: '120px' }} src={url} alt="fileName" />
                </a>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>
              {Translation(
                formMode === MdrtFormMode.CREATE
                  ? 'mdrt.detail.add'
                  : isDisabled
                  ? 'mdrt.detail.view'
                  : 'mdrt.detail.edit',
              )}
            </div>
          </div>
          <Button variant="contained" onClick={() => onRouteTo(MDRT_BASE_PATH)}>
            Back
          </Button>
        </div>

        <div className={classes.sectionMargin}>
          <div className={classes.divideMargin}>
            <span className={classes.subHeader}>{Translation('mdrt.detail.name')}</span>
          </div>
          <div className={classes.rowContainer}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('mdrt.common.name')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <TextField
                style={{ width: '100%' }}
                disabled={isDisabled}
                error={errorState.mandatory.name}
                margin="dense"
                variant="outlined"
                helperText={errorState.mandatory.name && MANDATORY_FIELD_ERROR_TEXT}
                value={formState.name}
                onChange={(e) => {
                  onDismissErrorHandler('name', e.target.value);
                  formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'name', value: e.target.value } });
                }}
                inputProps={{ maxLength: 100 }}
              />
            </div>
          </div>
        </div>
        <div className={classes.sectionMargin}>
          <div className={classes.divideMargin}>
            <span className={classes.subHeader}>{Translation('mdrt.detail.target')}</span>
          </div>
          <div className={classes.divideMargin}>
            <span className={classes.subHeader}>{Translation('mdrt.detail.targetFYP')}</span>
          </div>

          <div className={classes.rowContainer}>
            {targetsAccordingField.map((item) =>
              targetsAccordingForm({
                title: Translation(`mdrt.detail.${item}`),
                error: errorState.mandatory[item],
                value: formState[item as keyof MdrtFormState],
                field: item,
              }),
            )}
          </div>
        </div>

        <div className={classes.sectionMargin}>
          <div className={classes.divideMargin}>
            <span className={classes.subHeader}>{Translation('mdrt.detail.uploadFile')}</span>
          </div>

          {/* Banner Image */}
          <div className={classes.rowContainer} style={{ marginTop: 15 }}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('mdrt.detail.bannerImg')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <FileUploader
                color="secondary"
                disabled={isDisabled}
                showAllowText={formState.banner?.url ? '' : Translation('mdrt.detail.imageMaximum')}
                upload={handleUploadFile}
                allowedFileTypes={['jpg', 'png', 'jpeg']}
                maxFileSize={2}
                showAddIcon={false}
                btnTxt={
                  formState.banner?.url ? Translation('mdrt.detail.updateFile') : Translation('app.button.chooseFile')
                }
                onChange={onChangeBannerFile}
              />
              {formState.banner?.url && (
                <Button
                  disabled={isDisabled}
                  variant="contained"
                  color="secondary"
                  onClick={() => removeFile('banner')}
                >
                  {Translation('mdrt.detail.removeFile')}
                </Button>
              )}
            </div>
          </div>
          {formState.banner?.url && previewFile(formState.banner)}

          {/* Condition Image */}
          <div className={classes.rowContainer} style={{ marginTop: 15 }}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('mdrt.detail.condition')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <FileUploader
                color="secondary"
                disabled={isDisabled}
                showAllowText={formState.condition?.url ? '' : Translation('mdrt.detail.fileMaximum')}
                upload={handleUploadFile}
                allowedFileTypes={['pdf', 'jpg', 'png', 'jpeg']}
                maxFileSize={2}
                showAddIcon={false}
                btnTxt={
                  formState.condition?.url
                    ? Translation('mdrt.detail.updateFile')
                    : Translation('app.button.chooseFile')
                }
                onChange={onChangeConditionFile}
              />
              {formState.condition?.url && (
                <Button
                  disabled={isDisabled}
                  variant="contained"
                  color="secondary"
                  onClick={() => removeFile('condition')}
                >
                  {Translation('mdrt.detail.removeFile')}
                </Button>
              )}
            </div>
          </div>
          {formState.condition?.url && previewFile(formState.condition)}

          {/* Benefit Image */}
          <div className={classes.rowContainer} style={{ marginTop: 15 }}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>
                {Translation('mdrt.detail.benefit')}
                <span className={classes.mandatory}>*</span> :
              </span>
            </div>
            <div style={{ flexGrow: 1 }}>
              <FileUploader
                color="secondary"
                disabled={isDisabled}
                showAllowText={formState.benefit?.url ? '' : Translation('mdrt.detail.fileMaximum')}
                upload={handleUploadFile}
                allowedFileTypes={['pdf', 'jpg', 'png', 'jpeg']}
                maxFileSize={2}
                showAddIcon={false}
                btnTxt={
                  formState.benefit?.url ? Translation('mdrt.detail.updateFile') : Translation('app.button.chooseFile')
                }
                onChange={onChangeBenefitFile}
              />
              {formState.benefit?.url && (
                <Button
                  disabled={isDisabled}
                  variant="contained"
                  color="secondary"
                  onClick={() => removeFile('benefit')}
                >
                  {Translation('mdrt.detail.removeFile')}
                </Button>
              )}
            </div>
          </div>
          {formState.benefit?.url && previewFile(formState.benefit)}
        </div>

        <div className={classes.footerContainer}>
          {isDisabled ? (
            <></>
          ) : (
            <>
              <Button variant="contained" color="secondary" onClick={() => onSubmitForm(PublishStatus.UNPUBLISHED)}>
                Save as Draft
              </Button>
              <Button
                style={{ marginLeft: 25 }}
                variant="contained"
                color="secondary"
                onClick={() => onSubmitForm(PublishStatus.PUBLISHED)}
              >
                Publish
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MdrtDetailForm;
