import axios from 'axios';
import * as jose from 'jose';
import { Dispatch } from 'react';
import { CORE_URL, PUBLIC_URL, apiClient, apiErrorHandler } from 'src/app/common/network';
import {
  GetMongoChartTokenRes,
  RegionChannelListItem,
  AuthApiRes,
  LogoutRes,
  TokenInfo,
} from '@pruforce/common-adminweb-sdk';

const OEM_APIM_SUBSCRIPTION_KEY = window.envConfig['REACT_APP_OEM_APIM_SUBSCRIPTION_KEY'] || '';

export type GenerateJwtByRegionChannelBody = {
  region: string;
  channel: string;
};

export const staffLogin = async () => {
  const redirectUrl = `${window.location.href}/callback`;
  const application = 'Admin Portal';
  window.location.href = `${CORE_URL}/auth/aad?redirectUrl=${redirectUrl}&subscription-key=${OEM_APIM_SUBSCRIPTION_KEY}&application=${application}&grantType=azureAd`;
};

export const getToken = async (authorizationCode: string, dispatch?: Dispatch<any>): Promise<AuthApiRes> => {
  return apiClient
    .get<AuthApiRes>(`${CORE_URL}/auth/token?grantType=authorizationCode&code=${authorizationCode}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

const getJWTConfig = axios.get(`${PUBLIC_URL}/configs/system-appconfig`);

export const decodeJWT = async (jwtToken: string): Promise<TokenInfo> => {
  try {
    const config = await getJWTConfig;
    const jwk = config.data?.content?.jwks?.[0] || {};
    const data = await jose.jwtVerify(jwtToken, await jose.importJWK(jwk), {
      algorithms: ['RS256'],
    });

    console.log('decodeJWT', data?.payload?.sub);
    return data.payload as TokenInfo;
  } catch (e) {
    console.log('jwt token validate error');
    return jose['decodeJwt'](jwtToken) as TokenInfo;
  }
};

export const getRegionChannelList = async (dispatch?: Dispatch<any>): Promise<RegionChannelListItem[]> => {
  return apiClient
    .get<any>(`${CORE_URL}/auth/getRegionsChannels`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

// export const getPermissionByRegionChannel = async (
//   region: string,
//   channel: string,
//   dispatch?: Dispatch<any>,
// ): Promise<any> => {
//   return apiClient
//     .get<any>(`${CORE_URL}/auth/permission/regionChannel?region=${region}&channel=${channel}`)
//     .then((response) => response.data)
//     .catch((err) => {
//       throw apiErrorHandler(err, dispatch);
//     });
// }

export const generateJwtByRegionChannel = async (
  body: GenerateJwtByRegionChannelBody,
  dispatch?: Dispatch<any>,
): Promise<any> => {
  return apiClient
    .post<any>(`${CORE_URL}/auth/token/channel`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const agentLogin = async (username: string, password: string, dispatch?: Dispatch<any>): Promise<AuthApiRes> => {
  return apiClient
    .post<AuthApiRes>(`${CORE_URL}/auth/token?grantType=password`, {
      username,
      password,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const refreshToken = async (refreshToken: string, dispatch?: Dispatch<any>): Promise<AuthApiRes> => {
  return apiClient
    .get<AuthApiRes>(`${CORE_URL}/auth/token?grantType=refreshToken&code=${refreshToken}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const agentLogout = async (dispatch?: Dispatch<any>): Promise<LogoutRes> => {
  return apiClient
    .delete<LogoutRes>(`${CORE_URL}/auth/logout`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const revokeRefreshToken = async (refreshToken: string, dispatch?: Dispatch<any>): Promise<boolean> => {
  return apiClient
    .delete(`${CORE_URL}/auth/token?refreshToken=${refreshToken}`)
    .then((response) => response.status === 204)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getMongoChartToken = async (dispatch?: Dispatch<any>): Promise<GetMongoChartTokenRes> => {
  return apiClient
    .post<any>(`${CORE_URL}/auth/token/mongochart`, {})
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
