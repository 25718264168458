import React, { FC, useState } from 'react';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { getDayEnd, getFirstDayOfMonth, getDayStart, getLastDayOfMonth } from '../../../../../common/utils/time-utils';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { PaginateListMore } from 'src/app/common/types/common-types';
import { CandidateSurveyReportListParams } from '../../../types/candidateSurveyReportTypes';
import {
  fetchCandidateSurveyReport,
  fetchCandidateSurveyReportDownload,
} from '../../../network/candidateSurveyReportCrud';
import { AlertType, appendAlertItem } from '@pruforce/common-adminweb-sdk';
import moment from 'moment';

type CandidateSurveyReportProps = {} & ParamsProps;

const initialState: CandidateSurveyReportListParams = {
  requestedDateFrom: moment().subtract(3, 'months').startOf('day').toDate(),
  requestedDateTo: getDayEnd(new Date()),
  agentCode: '',
  officeCode: '',
  surveyName: '',
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): CandidateSurveyReportListParams => {
  return initialParams
    ? {
        requestedDateFrom: initialParams.requestedDateFrom
          ? getDayStart(new Date(initialParams.requestedDateFrom))
          : getFirstDayOfMonth(new Date()),
        requestedDateTo: initialParams.requestedDateTo
          ? getLastDayOfMonth(new Date(initialParams.requestedDateTo))
          : getLastDayOfMonth(new Date()),
        agentCode: '',
        officeCode: '',
        surveyName: '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const CandidateSurveyReport: FC<CandidateSurveyReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilterValue = paramsInitiator(initialParams);

  const [filterState, setFilterState] = useState<CandidateSurveyReportListParams>(defaultFilterValue);
  const [reportList, setReportList] = useState<PaginateListMore<any>>();
  const { isLoading, refreshData } = useDataProvider<PaginateListMore<any>>(
    async () => {
      try {
        if (filterState.requestedDateFrom && filterState.requestedDateTo) {
          const momentDateFrom = moment(filterState.requestedDateFrom);
          const momentDateTo = moment(filterState.requestedDateTo);

          if (momentDateTo.isBefore(momentDateFrom, 'day')) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  title: 'Error',
                  content: 'End date must be after Begin date',
                },
              ]),
            );
            return;
          }

          if (Number(momentDateTo.diff(momentDateFrom, 'months', true).toFixed(1)) > 3) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  title: 'Error',
                  content: 'Searching period must be less than or equal to 3 months depending on FROM MONTH',
                },
              ]),
            );
            return;
          }
        }

        return await fetchCandidateSurveyReport(filterState, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvtDownload = () => {
    try {
      fetchCandidateSurveyReportDownload(filterState, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('report.candidate.survey.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('report.candidate.survey.agentCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'officeCode',
            initialValue: filterState.officeCode,
            displayName: Translation('report.candidate.survey.officeCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'surveyName',
            initialValue: filterState.surveyName,
            displayName: Translation('report.candidate.survey.surveyName'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'requestedDateFrom',
            fieldTo: 'requestedDateTo',
            initialDateFrom: filterState.requestedDateFrom,
            initialDateTo: filterState.requestedDateTo,
            defaultDateFrom: defaultFilterValue.requestedDateFrom,
            defaultDateTo: defaultFilterValue.requestedDateTo,
            displayName: Translation('report.candidate.survey.periodDate'),
            isRequired: true,
          },
        ]}
        onChangeFilter={(data) => {
          setFilterState({
            ...filterState,
            agentCode: data?.agentCode,
            officeCode: data?.officeCode,
            surveyName: data?.surveyName,
            requestedDateFrom: data.requestedDateFrom,
            requestedDateTo: data.requestedDateTo,
          });
        }}
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.candidate.survey.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvtDownload(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            keyIndex: 'agentCode',
            align: 'center',
            displayName: 'AG Code',
            renderData: (row) => row.agentCode || '-',
          },
          {
            keyIndex: 'fullname',
            align: 'center',
            displayName: 'AG Name',
            renderData: (row) => row?.agentInfo?.fullname || '-',
          },
          {
            keyIndex: 'roleCode',
            align: 'center',
            displayName: 'AG Role',
            renderData: (row) => row?.agentInfo?.designation?.name || '-',
          },
          {
            keyIndex: 'officeCode',
            align: 'center',
            displayName: 'Office Code',
            renderData: (row) => row?.agentInfo?.division?.code || '-',
          },
          {
            keyIndex: 'officeName',
            align: 'center',
            displayName: 'Office Name',
            renderData: (row) => row?.agentInfo?.division?.description || '-',
          },
          {
            keyIndex: 'region',
            align: 'center',
            displayName: 'Region',
            renderData: (row) => row?.agentInfo?.region || '-',
          },
          {
            keyIndex: 'saleZone',
            align: 'center',
            displayName: 'Sale Zone',
            renderData: (row) => row?.agentInfo?.zone || '-',
          },
          {
            keyIndex: 'totalCandidate',
            align: 'center',
            displayName: 'Total Candidate',
            renderData: (row) => row?.candidateInfo?.totalCandidate || '-',
          },
          {
            keyIndex: 'candidateSent',
            align: 'center',
            displayName: 'Candidate Sent',
            renderData: (row) => row?.candidateSent || '-',
          },
          {
            keyIndex: 'surveyName',
            align: 'center',
            displayName: 'Survey Name',
            renderData: (row) => row?.surveyName || '-',
          },
          {
            keyIndex: 'totalSent',
            align: 'center',
            displayName: 'Survey Sent',
            renderData: (row) => row?.totalSent || '-',
          },
          {
            keyIndex: 'totalUnread',
            align: 'center',
            displayName: 'Not Opened',
            renderData: (row) => row?.totalUnread || '-',
          },
          {
            keyIndex: 'opened',
            align: 'center',
            displayName: 'Opened',
            renderData: (row) => row?.totalSent - (row?.totalUnread + row?.totalSubmits) || '-',
          },
          {
            keyIndex: 'totalSubmits',
            align: 'center',
            displayName: 'Completed',
            renderData: (row) => row?.totalSubmits || '-',
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default CandidateSurveyReport;
