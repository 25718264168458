import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { PaginateList } from 'src/app/common/types/common-types';
import { LeadReassignmentFailedItem } from 'src/app/modules/PulseLeads/types/lead-reassignment-types';

type LeadReassignmentFailedListProps = {
  isLoading: boolean;
  leadReassignmentFailedList?: PaginateList<LeadReassignmentFailedItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

const LeadReassignmentFailedList: FC<LeadReassignmentFailedListProps> = ({
  isLoading,
  leadReassignmentFailedList,
  onRefresh,
  onChangePage,
}) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <>
      <PruTable
        title={Translation('pulseleads.leadReassignment.common.failedList')}
        disableBulkSelect
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => '',
          },
          {
            keyIndex: 'csvRowNumber',
            displayName: Translation('pulseleads.leadReassignment.common.rowNumber'),
            renderData: (row) => (row.csvRowNumber ? row.csvRowNumber.toString() : '-'),
          },
          {
            keyIndex: 'failureReason',
            displayName: Translation('pulseleads.leadReassignment.common.failureReason'),
            renderData: (row) => row.failureReason || '-',
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={leadReassignmentFailedList?.docs}
        totalPages={leadReassignmentFailedList?.totalPages}
        totalRecords={leadReassignmentFailedList?.totalDocs}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default LeadReassignmentFailedList;
