import { EVENT_URL, apiClient } from 'src/app/common/network';

export const getCategoryList = async (params?: any) => {
  return apiClient.get(`${EVENT_URL}/resource/ad/categories`, { params });
};

export const addCategory = async (data?: any) => {
  return apiClient.post(`${EVENT_URL}/resource/ad/categories/add`, data);
};

export const editCategory = async (categoryId?: string, data?: any) => {
  return apiClient.put(`${EVENT_URL}/resource/ad/categories/edit/${categoryId}`, data);
};

export const getAllCategory = async (params?: any) => {
  return apiClient.get(`${EVENT_URL}/resource/ad/categories/all`, { params });
};
