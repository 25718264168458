import React, { FC, useState } from 'react';
import { PaginateList } from '../../../../../../common/types/common-types';
import { useIntl } from 'react-intl';
import PruTable from '../../../../../../common/components/PruTable/PruTable';
import { useHistory } from 'react-router-dom';
import { LIVESTREAM_MANAGEMENT_PATH } from 'src/app/modules/Livestream/constants';
import { getDisplayDate } from 'src/app/common/utils/time-utils';
import { StatusLivestream } from '../../types/livestream-type';
import {
  deleteAllDataLivestream,
  deleteLivestream,
  hiddenLivestream,
  trackDeleteLivestream,
} from '../../../network/livestreamCrud';
import PruDialog from 'src/app/common/components/PruDialog';

type LivestreamListProps = {
  isLoading: boolean;
  livestreamList?: PaginateList<any>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  filterState: any;
  dispatch: any;
};

const LivestreamList: FC<LivestreamListProps> = ({ isLoading, livestreamList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [openWarning, handleOpenWarning] = useState<boolean>(false);
  const [selectedRow, handleSelectedRow] = useState<any>(null);

  const closeWaringDialog = () => {
    handleOpenWarning(false);
  };

  const listStatus: Record<string, any> = {
    [StatusLivestream.COMING_SOON]: Translation('livestrean.filter.status.display.comingSoon'),
    [StatusLivestream.BROADCASTING]: Translation('livestrean.filter.status.display.broadcasting'),
    [StatusLivestream.BROADCASTED]: Translation('livestrean.filter.status.display.broadcasted'),
  };

  const onHiddenLivestream = async (row: any) => {
    let resultOriginal = await hiddenLivestream(row._id, { isHidden: !row.isHidden });

    onRefresh();
  };

  const onDeleteLivestream = async (row: any) => {
    handleSelectedRow((prev: any) => (prev = row));
    handleOpenWarning(true);
  };

  const aprroveDeleteLivestream = async () => {
    console.log(selectedRow, 'approve');
    handleOpenWarning(false);

    let resultOriginal = await deleteLivestream(selectedRow._id);
    await deleteAllDataLivestream(selectedRow._id);

    trackDeleteLivestream(selectedRow);

    onRefresh();
  };

  return (
    <>
      <PruTable
        title={Translation('livestream.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('livestream.list.create'),
            onClick: () => history.push(`${LIVESTREAM_MANAGEMENT_PATH}/create`),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('livestream.list.view'),
            tooltipText: 'View',
            onClick: (row) => history.push(`${LIVESTREAM_MANAGEMENT_PATH}/view/${row._id}`),
            // condition: (row) => row.publishStatus !== PublishStatus.DRAFT
          },
          {
            title: Translation('livestream.list.edit'),
            tooltipText: 'Edit',
            onClick: (row) => history.push(`${LIVESTREAM_MANAGEMENT_PATH}/edit/${row._id}`),
            condition: (row) => row.status !== StatusLivestream.BROADCASTED,
          },
          {
            title: Translation('livestream.list.hide'),
            tooltipText: 'Hide',
            onClick: (row) => onHiddenLivestream(row),
            condition: (row) =>
              !row.isHidden &&
              (row.status === StatusLivestream.COMING_SOON || row.status === StatusLivestream.BROADCASTED),
          },
          {
            title: Translation('livestream.list.unhide'),
            tooltipText: 'Unhide',
            onClick: (row) => onHiddenLivestream(row),
            condition: (row) => row.isHidden === true,
          },
          {
            title: Translation('livestream.list.delete'),
            tooltipText: 'Delete',
            onClick: (row) => onDeleteLivestream(row),
            condition: (row) => row.status === StatusLivestream.BROADCASTED,
          },
        ]}
        isOperationDefDropdown={true}
        columnDef={[
          {
            keyIndex: 'banner',
            displayName: 'Banner',
            align: 'center',
            renderData: (row) =>
              row?.banner?.url ? <img style={{ height: '60px' }} src={row.banner.url} alt="new" /> : '',
          },
          {
            keyIndex: 'name',
            align: 'center',
            displayName: 'Name',
            renderData: (row) => row?.name,
          },
          {
            keyIndex: 'link',
            align: 'center',
            displayName: 'Link',
            renderData: (row) => row?.link,
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: 'Created Date',
            renderData: (row) => getDisplayDate(new Date(row?.createdAt), 'DD-MM-YYYY HH:mm'),
          },
          {
            keyIndex: 'releaseAt',
            align: 'center',
            displayName: 'Released Date',
            renderData: (row) => getDisplayDate(new Date(row?.releaseAt), 'DD-MM-YYYY HH:mm'),
          },
          {
            keyIndex: 'status',
            align: 'center',
            displayName: 'Status',
            renderData: (row) =>
              row?.isHidden
                ? Translation('livestrean.filter.status.display.hidden')
                : row?.status
                ? listStatus[row.status]
                : '',
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={livestreamList?.items}
        totalPages={livestreamList?.totalPages}
        totalRecords={livestreamList?.total}
        onChangePage={onChangePage}
        defaultRowsPerPage={10}
      />
      <PruDialog
        open={openWarning}
        canCloseDialog={true}
        onCancel={closeWaringDialog}
        onOk={aprroveDeleteLivestream}
        canncelBtnText="No"
        confirmBtnText="Yes"
        dialogTitle="Caution"
        dialogTitleStyle={{ textAlign: 'center' }}
      >
        All reporting data will be deleted with this Livestream. Do you want to continue?
      </PruDialog>
    </>
  );
};

export default LivestreamList;
