import React, { FC, useState } from 'react';
import { getFirstDayOfMonth, getLastDayOfMonth, useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { PaginateListMore } from 'src/app/common/types/common-types';
import { useLang } from 'src/app/i18n';
import moment from 'moment';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruTable from 'src/app/common/components/PruTable/PruTable';
import { fetchCustomerCampaignDownload, fetchCustomerCampaignList } from '../../network/customerCampaignReportCrud';
// import {
//   fetchCustomerCampaignReport,
//   fetchCustomerCampaignReportDownload,
// } from '../../../network/CustomerCampaignReportCrud';
// import { CustomerCampaignReportListParams } from '../../../types/CustomerCampaignReportTypes';

type CustomerCampaignReportProps = {} & ParamsProps;

export type CustomerCampaignParams = {
  startSendDate: Date | null;
  endSendDate: Date | null;
  startFromDate: Date | null;
  startToDate: Date | null;
  endFromDate: Date | null;
  endToDate: Date | null;
  agentCode: string | '';
  campaignName: string | '';
  page: number;
  limit: number;
};

const initialState: CustomerCampaignParams = {
  startSendDate: null,
  endSendDate: null,
  startFromDate: null,
  startToDate: null,
  endFromDate: null,
  endToDate: null,
  agentCode: '',
  campaignName: '',
  page: 1,
  limit: 10,
};

const CustomerCampaignReport: FC<CustomerCampaignReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilterValue = initialState;
  const currentLanguage = useLang();

  const [filterState, setFilterState] = useState<CustomerCampaignParams>(defaultFilterValue);
  const [reportList, setReportList] = useState<PaginateListMore<any>>();
  const { isLoading, refreshData } = useDataProvider<PaginateListMore<any>>(
    async () => {
      try {
        console.log(filterState);

        return await fetchCustomerCampaignList(filterState, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvtDownload = () => {
    try {
      fetchCustomerCampaignDownload(filterState, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={'Report Filter'}
        itemDef={[
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'startSendDate',
            fieldTo: 'endSendDate',
            initialDateFrom: filterState.startSendDate,
            initialDateTo: filterState.endSendDate,
            defaultDateFrom: defaultFilterValue.startSendDate,
            defaultDateTo: defaultFilterValue.endSendDate,
            displayName: 'Send Date',
            periodTime: { months: 3 },
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: initialState.agentCode,
            displayName: 'Agent Code',
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'campaignName',
            initialValue: initialState.campaignName,
            displayName: 'Campaign Name',
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'startFromDate',
            fieldTo: 'startToDate',
            initialDateFrom: filterState.startFromDate,
            initialDateTo: filterState.startToDate,
            defaultDateFrom: defaultFilterValue.startFromDate,
            defaultDateTo: defaultFilterValue.startToDate,
            displayName: 'Start Date',
            periodTime: { months: 3 },
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'endFromDate',
            fieldTo: 'endToDate',
            initialDateFrom: filterState.endFromDate,
            initialDateTo: filterState.endToDate,
            defaultDateFrom: defaultFilterValue.endFromDate,
            defaultDateTo: defaultFilterValue.endToDate,
            displayName: 'End Date',
            periodTime: { months: 3 },
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            startSendDate: data.startSendDate,
            endSendDate: data.endSendDate,
            startFromDate: data.startFromDate,
            endFromDate: data.endFromDate,
            startToDate: data.startToDate,
            endToDate: data.endToDate,
            agentCode: data.agentCode,
            campaignName: data.campaignName,
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={'Campaign List'}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvtDownload(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            keyIndex: 'CampaignId',
            displayName: 'CampaignId',
            align: 'center',
            renderData: (row) => row.campaignId,
          },
          {
            keyIndex: 'CampaignName',
            displayName: 'CampaignName',
            align: 'center',
            renderData: (row) => row.campaignName,
          },
          {
            keyIndex: 'StartDate',
            displayName: 'Start Date',
            align: 'center',
            renderData: (row) => (row.startDate ? moment(row.startDate).format('DD/MM/YYYY') : '-'),
          },
          {
            keyIndex: 'EndDate',
            displayName: 'End Date',
            align: 'center',
            renderData: (row) => (row.endDate ? moment(row.endDate).format('DD/MM/YYYY') : '-'),
          },
          {
            keyIndex: 'AGCode',
            displayName: 'AG Code',
            align: 'center',
            renderData: (row) => (row.agentCode ? row.agentCode : '-'),
          },
          {
            keyIndex: 'ClientNo',
            displayName: 'Client No',
            align: 'center',
            renderData: (row) => (row.clientNumber ? row.clientNumber : '-'),
          },
          {
            keyIndex: 'SentDate',
            displayName: 'Sent Date',
            align: 'center',
            renderData: (row) => (row.sendDate ? moment(row.sendDate).format('DD/MM/YYYY HH:mm:ss') : '-'),
          },
          {
            keyIndex: 'SubmittedDate',
            displayName: 'Submitted Date',
            align: 'center',
            renderData: (row) => (row.submittedDate ? moment(row.submittedDate).format('DD/MM/YYYY HH:mm:ss') : '-'),
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.total}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default CustomerCampaignReport;
