import { forEach } from 'lodash';
import { Dispatch } from 'react';
import { PaginateListMore } from 'src/app/common/types/common-types';
import { BdmBackgroundImageDetail, BdmBackgroundImageItem } from '../types/bdm-background-image-types';
import moment from 'moment';
import { apiClient, apiErrorHandler } from 'src/app/common/network';

export const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

export type BdmBackgroundImageListParams = {
  name: string;
  categoryId?: string;
  type?: string;
  page: number;
  limit: number;
};

export const getDayStart = (date: Date) => {
  return moment(date).startOf('day').toDate();
};

export const getDayEnd = (date: Date) => {
  return moment(date).endOf('day').toDate();
};

export const create = async (
  data: BdmBackgroundImageDetail,
  dispatch?: Dispatch<any>,
): Promise<BdmBackgroundImageDetail> => {
  return apiClient
    .post<BdmBackgroundImageDetail>(`${ADMIN_URL}/resource/ad/background-images/add`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response: any) => response.data)
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const update = async (
  data: BdmBackgroundImageDetail,
  dispatch?: Dispatch<any>,
): Promise<BdmBackgroundImageDetail> => {
  return apiClient
    .put<BdmBackgroundImageDetail>(`${ADMIN_URL}/resource/ad/background-images/edit/${data.id}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response: any) => response.data)
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchItem = async (id: string, dispatch?: Dispatch<any>): Promise<BdmBackgroundImageDetail> => {
  return apiClient
    .get<BdmBackgroundImageDetail>(`${ADMIN_URL}/resource/ad/background-images/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchListCategory = async (id: string, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get<BdmBackgroundImageDetail>(`${ADMIN_URL}/resource/ad/categories`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchList = async (
  params: BdmBackgroundImageListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<BdmBackgroundImageItem>> => {
  let queryPath = `${ADMIN_URL}/resource/ad/background-images?`;
  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.includes('From')) {
          queryPath += `${key}=${getDayStart(param).toISOString()}&`;
        }
        if (key.includes('To')) {
          queryPath += `${key}=${getDayEnd(param).toISOString()}&`;
        }
      }
    } else {
      queryPath += param ? `${key}=${param}&` : '';
    }
  });

  return apiClient
    .get<PaginateListMore<BdmBackgroundImageItem>>(queryPath)
    .then((response: any) => response.data)
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteItem = async (id: string, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .delete(`${ADMIN_URL}/resource/ad/background-images/${id}`)
    .then((response: any) => response.data)
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getAllCategory = async (dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get(`${ADMIN_URL}/resource/ad/categories`)
    .then((response: any) => response.data)
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};
