import { forEach } from 'lodash';
import { PaginateListMore } from '../../../common/types/common-types';
import { Dispatch } from 'react';
import { apiClient, apiErrorHandler } from 'src/app/common/network';
import { ReportListParams, ReportItem } from '../types/recruitment-details-types';
import moment from 'moment';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

const formatDataFilters = (params: ReportListParams, tab?: Number) => {
  let data: any = {
    dataFilters: [],
    page: params.page,
    limit: params.limit,
  };
  forEach(params, (param, key) => {
    if (param) {
      switch (key) {
        case 'registerDateFrom':
          data.dataFilters.push({
            name: 'createdAt',
            value: param,
            type: 'Date',
            operator: 'gt',
          });
          break;
        case 'registerDateTo':
          data.dataFilters.push({
            name: 'createdAt',
            value: param,
            type: 'Date',
            operator: 'lt',
          });
          break;
        case 'agentCode':
          data.dataFilters.push({
            name: tab === 2 ? 'profile.agentCode' : 'agentCode',
            value: param,
            type: 'String',
            operator: 'search',
          });
          break;
        case 'agentName':
          data.dataFilters.push({
            name: tab === 2 ? 'profile.fullname' : tab === 0 ? 'agentProfile.fullName' : 'profile.fullname.enUs',
            value: param,
            type: 'String',
            operator: 'search',
          });
          break;
        case 'agentRole':
          if (Array.isArray(param) && param.length > 0 && !param.includes('all')) {
            data.dataFilters.push({
              name: tab === 0 ? 'agentProfile.designation.name' : 'profile.designation.name',
              value: param,
              type: 'Array',
              operator: 'search',
            });
          }
          break;
        case 'salesChannel':
          if (Array.isArray(param) && param.length > 0 && !param.includes('all')) {
            data.dataFilters.push({
              name: tab === 0 ? 'agentProfile.agency' : 'profile.agency',
              value: param,
              type: 'Array',
              operator: 'search',
            });
          }
          break;
        case 'leaderCode':
          data.dataFilters.push({
            name: tab === 0 ? 'agentProfile.uplineAgent.code' : 'profile.uplineAgent.code',
            value: param,
            type: 'String',
            operator: 'search',
          });
          break;
        case 'leaderName':
          data.dataFilters.push({
            name: tab === 0 ? 'agentProfile.uplineAgent.name' : 'profile.uplineAgent.name',
            value: param,
            type: 'String',
            operator: 'search',
          });
          break;
        case 'candidateName':
          data.dataFilters.push({
            name: 'dna-smg-assessment.fullName',
            value: param,
            type: 'String',
            operator: 'search',
          });
          break;
        case 'typesOfPRUDNA':
          if (tab === 1) {
            if (Array.isArray(param) && param.length > 0 && !param.includes('all')) {
              data.dataFilters.push({
                name: 'assessmentType',
                value: param,
                type: 'Array',
                operator: 'search',
              });
            }
          }
          break;
      }
    }
  });
  return data;
};

export const fetchReportDownload = (params: ReportListParams, tab: Number, dispatch?: Dispatch<any>): void => {
  let uri = `${ADMIN_URL}/report/agent-detail-export`;
  if (tab == 0) {
    uri = `${ADMIN_URL}/report/candidate/detail/export`;
  } else if (tab === 1) {
    uri = `${ADMIN_URL}/report/ob/detail/export`;
  }
  const data = formatDataFilters(params, tab);
  apiClient
    .post<Blob>(uri, data, { responseType: 'text' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Recruitment Details Report_' + moment(new Date()).format('YYYYMMDD') + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchRecruitmentDetailsReport = async (
  params: ReportListParams,
  tab: Number,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<ReportItem>> => {
  let uri = `${ADMIN_URL}/report/agent/detail`;
  if (tab === 0) {
    uri = `${ADMIN_URL}/report/candidate/detail`;
  } else if (tab === 1) {
    uri = `${ADMIN_URL}/report/ob/detail`;
  }
  const data = formatDataFilters(params, tab);
  return apiClient
    .post<PaginateListMore<ReportItem>>(uri, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
