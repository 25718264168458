export enum EPublishStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export enum ESubmitActionEnum {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
}

export enum EReceiverTypeCustomer {
  allCustomer = 'allCustomer',
  targetsCustomer = 'targetsCustomer',
  excludeCustomer = 'excludeCustomer',
}

export enum EReceiverTypeAgent {
  isAllAgents = 'isAllAgents',
  isPruventure = 'isPruventure',
  isGroupOfUsers = 'isGroupOfUsers',
}

export enum EReferralForm {
  isName = 'isName',
  isPhone = 'isPhone',
  isProvinceCity = 'isProvinceCity',
  isDob = 'isDob',
  isEmail = 'isEmail',
}
