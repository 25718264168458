import React, { FC, useState } from 'react';
import { PaginateListMore } from '../../../../../common/types/common-types';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { getDayStart, getDayEnd } from '../../../../../common/utils/time-utils';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { ReportItem, ReportListParams } from '../../../types/customer-contact-tracking-report-types';
import {
  fetchCustomerContactTrackingReport,
  fetchReportDownload,
} from '../../../network/customerContactTrackingReportCrud';

type CustomerContactTrackingListingPageProps = {} & ParamsProps;

const initialState: ReportListParams = {
  requestedDateFrom: getDayStart(new Date()),
  requestedDateTo: getDayEnd(new Date()),
  agentCode: '',
  agentName: '',
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): ReportListParams => {
  return initialParams
    ? {
        requestedDateFrom: initialParams.requestedDateFrom
          ? getDayStart(new Date(initialParams.requestedDateFrom))
          : getDayStart(new Date()),
        requestedDateTo: initialParams.requestedDateTo
          ? getDayEnd(new Date(initialParams.requestedDateTo))
          : getDayEnd(new Date()),
        agentCode: '',
        agentName: '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const CustomerContactTrackingListingPage: FC<CustomerContactTrackingListingPageProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilterValue = paramsInitiator(initialParams);

  const [filterState, setFilterState] = useState<ReportListParams>(defaultFilterValue);
  const [reportList, setReportList] = useState<PaginateListMore<ReportItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateListMore<ReportItem>>(
    async () => {
      try {
        return await fetchCustomerContactTrackingReport(filterState, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvDownload = () => {
    try {
      fetchReportDownload(filterState, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('report.customerContactTracking.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('report.customerContactTracking.agentCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentName',
            initialValue: filterState.agentName,
            displayName: Translation('report.customerContactTracking.agentName'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'requestedDateFrom',
            fieldTo: 'requestedDateTo',
            initialDateFrom: filterState.requestedDateFrom,
            initialDateTo: filterState.requestedDateTo,
            defaultDateFrom: defaultFilterValue.requestedDateFrom,
            defaultDateTo: defaultFilterValue.requestedDateTo,
            displayName: Translation('report.common.requestDate'),
            required: true,
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            requestedDateFrom: data.requestedDateFrom,
            requestedDateTo: data.requestedDateTo,
            agentCode: data.agentCode,
            agentName: data.agentName,
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.customerContactTracking.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvDownload(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: () => '',
          },
          {
            keyIndex: 'agentCode',
            displayName: Translation('report.customerContactTracking.agentCode'),
            renderData: (row) => row.agentCode,
          },
          {
            keyIndex: 'agentName',
            displayName: Translation('report.customerContactTracking.agentName'),
            renderData: (row) => row.agentName,
          },
          {
            keyIndex: 'agentRole',
            displayName: Translation('report.customerContactTracking.role'),
            renderData: (row) => row.agentRole,
          },
          {
            keyIndex: 'unitCode',
            displayName: Translation('report.customerContactTracking.unitCode'),
            renderData: (row) => row.unitCode,
          },
          {
            keyIndex: 'officeCode',
            displayName: Translation('report.customerContactTracking.officeCode'),
            renderData: (row) => row.officeCode,
          },
          {
            keyIndex: 'branchCode',
            displayName: Translation('report.customerContactTracking.branchCode'),
            renderData: (row) => row.branchCode,
          },
          {
            keyIndex: 'customerName',
            displayName: Translation('report.customerContactTracking.customerName'),
            renderData: (row) => row.customerName,
          },
          {
            keyIndex: 'phone',
            displayName: Translation('report.customerContactTracking.customerPhone'),
            renderData: (row) => row.phone,
          },
          {
            keyIndex: 'dateOfCall',
            displayName: Translation('report.customerContactTracking.dateOfCall'),
            renderData: (row) => row.dateOfCall,
          },
          {
            keyIndex: 'timeOfCall',
            displayName: Translation('report.customerContactTracking.timeOfCall'),
            renderData: (row) => row.timeOfCall,
          },
          {
            keyIndex: 'status',
            displayName: Translation('report.customerContactTracking.status'),
            renderData: (row) => row.status,
          },
          {
            keyIndex: 'note',
            displayName: Translation('report.customerContactTracking.note'),
            renderData: (row) => row.note,
          },
          {
            keyIndex: 'policyNo',
            displayName: Translation('report.customerContactTracking.policyNo'),
            renderData: (row) => row.policyNo,
          },
          {
            keyIndex: 'productName',
            displayName: Translation('report.customerContactTracking.productName'),
            renderData: (row) => row.productName,
          },
          {
            keyIndex: 'policyStatus',
            displayName: Translation('report.customerContactTracking.policyStatus'),
            renderData: (row) => row.policyStatus,
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default CustomerContactTrackingListingPage;
