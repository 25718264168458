import React, { FC } from 'react';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../../common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../common/constants';
import ParamsProvider from '../../../../common/components/ParamsProvider';
import { LIVESTREAM_MANAGEMENT_PATH } from '../../constants';
import LivestreamListingPage, { livestreamFilterKeys } from './List/LivestreamListingPage';
import LivestreamCreatePage from './Create/LivestreamCreatePage';

const LivestreamPage: FC<RouteComponentProps> = ({}) => {
  return (
    <Switch>
      <PruRoute exact path={`${LIVESTREAM_MANAGEMENT_PATH}`} render={(props) => (
        <ParamsProvider {...props} acceptKeys={livestreamFilterKeys}>
          <LivestreamListingPage></LivestreamListingPage>
        </ParamsProvider>
      )} />
      <PruRoute exact path={`${LIVESTREAM_MANAGEMENT_PATH}/view/:id`} component={LivestreamCreatePage} />
      <PruRoute exact path={`${LIVESTREAM_MANAGEMENT_PATH}/edit/:id`} component={LivestreamCreatePage} />
      <PruRoute exact path={`${LIVESTREAM_MANAGEMENT_PATH}/create`} component={LivestreamCreatePage} />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
};

export default LivestreamPage;