import { PaginateListMore } from '../../../common/types/common-types';
import { Dispatch } from 'react';
import { apiClient, apiErrorHandler } from 'src/app/common/network';
import {
  IReportFilter,
  INewRegisterCandidateReportItem,
  ICandidateInformationReportItem,
  ICandidateManagementReportItem,
  ICandidatePerformanceReportItem,
  IReportPerformanceFilter,
} from '../types/candidate-types';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

export const fetchNewRegisteredCandidateReportCSV = (params: IReportFilter, dispatch?: Dispatch<any>): void => {
  // const paramsUrl = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
  const url = `${ADMIN_URL}/report/candidate/new-registered-candidate/export`;

  apiClient
    .post(url, params, { responseType: 'text' })
    .then((response: any) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'new-registered-candidate-report-' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchNewRegisteredCandidateReport = async (
  params: IReportFilter,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<INewRegisterCandidateReportItem>> => {
  const url = `${ADMIN_URL}/report/candidate/new-registered-candidate`;
  return apiClient
    .post(url, params)
    .then((response: any) => response.data)
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCandidateInformationReportCSV = (params: IReportFilter, dispatch?: Dispatch<any>): void => {
  // const paramsUrl = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
  const url = `${ADMIN_URL}/report/candidate/candidate-information/export`;
  if (params.channelCandidate == '') {
    params.channelCandidate = undefined;
  }
  apiClient
    .post(url, params, { responseType: 'text' })
    .then((response: any) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'candidate-information-report-' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCandidateInformationReport = async (
  params: IReportFilter,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<ICandidateInformationReportItem>> => {
  const url = `${ADMIN_URL}/report/candidate/candidate-information`;
  if (params.channelCandidate == '') {
    params.channelCandidate = undefined;
  }
  return apiClient
    .post(url, params)
    .then((response: any) => response.data)
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCandidateManagementReport = async (
  params: IReportFilter,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<ICandidateManagementReportItem>> => {
  const url = `${ADMIN_URL}/report/candidate/candidate-management`;
  return apiClient
    .post(url, params)
    .then((response: any) => response.data)
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const exportCandidateManagementReport = (params: IReportFilter, dispatch?: Dispatch<any>): void => {
  // const paramsUrl = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
  const url = `${ADMIN_URL}/report/candidate/candidate-management/export`;

  apiClient
    .post(url, params, { responseType: 'text' })
    .then((response: any) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'candidate-management-report-' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCandidatePerformanceReport = async (
  params: IReportPerformanceFilter,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<ICandidatePerformanceReportItem>> => {
  const url = `${ADMIN_URL}/report/candidate/candidate-performance`;
  return apiClient
    .post(url, params)
    .then((response: any) => response.data)
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const exportCandidatePerformanceReport = async (
  params: IReportPerformanceFilter,
  dispatch?: Dispatch<any>,
): Promise<any> => {
  // const paramsUrl = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
  const url = `${ADMIN_URL}/report/candidate/candidate-performance/export`;

  return apiClient
    .post(url, params, { responseType: 'text' })
    .then((response: any) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'agent-performance-report-' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
      return true;
    })
    .catch((err: any) => {
      throw apiErrorHandler(err, dispatch);
    });
};
