import React, { FC, useEffect, useMemo, useReducer, useState } from 'react';
import { Button, FormHelperText, Select, TextField } from '@mui/material';
import { useCommonStyles } from 'src/app/common/styles/common-styles';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ErrorFieldType, useErrorHandler } from 'src/app/common/utils/form-error-utils';
import {
  MDRT_PASSPORT_BASE_PATH,
  AGENT_CODE_NOT_ACTIVE_ERROR_TEXT,
  AGENT_CODE_NOT_FOUND_ERROR_TEXT,
  MANDATORY_FIELD_ERROR_TEXT,
  MDRT_HUB_BASE_PATH,
  YEAR_FIELD_ERROR_TEXT,
  AGENT_CODE_EXISTS_ERROR_TEXT,
} from '../../../../constants';
import {
  MdrtPassportFormState,
  MdrtPassportDetail,
  MdrtPassportFormMode,
  AgentStatus,
  StatusModel,
  Passport,
  PublishStatus,
} from '../../../../types/mdrt-passport-types';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { showLoading } from 'src/redux/common/commonSlice';
import { addMdrtPassport, getPassportByAgentCode } from '../../../../network/mdrtPassportCrud';
import { useDebounce } from 'src/app/common/utils/common-utils';
import { MdrtStampIconItem } from 'src/app/modules/MdrtHub/types/mdrt-stamp-icon-types';
import { fetchList } from 'src/app/modules/MdrtHub/network/mdrtStampIconCrud';
import { getAgentStatus } from 'src/app/modules/MdrtHub/network/achievementUploadCrud';
import { RootState } from 'src/redux/store';
import { makeStyles } from 'tss-react/mui';

type MdrtPassportDetailFormProps = {
  id?: string;
  formMode: MdrtPassportFormMode;
  mdrtPassport?: MdrtPassportDetail;
  onReload: () => void;
  onRouteTo: (route: string) => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  fieldContainer: {
    width: 120,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
    fontWeight: 'bold',
  },
  mandatory: {
    color: 'red',
  },
  sectionMargin: {
    marginBottom: 20,
  },
  flexItem: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '33.333333%',
    marginTop: 15,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 9,
    color: '#F018A6',
  },
  fieldContainerYear: {
    flexBasis: '50%',
    paddingLeft: 15,
    paddingBottom: 10,
    display: 'inherit',
    alignItems: 'center',
  },
  removeBtn: {
    display: 'flex',
    marginLeft: 'auto',
  },
}));

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof MdrtPassportFormState;
    value: any;
  };
};

type AddMdrtPassportAction = {
  type: 'ADD_PASSPORT';
  payload: {
    field: keyof MdrtPassportFormState;
    value: Passport;
  };
};

type RemoveMdrtPassportAction = {
  type: 'REMOVE_PASSPORT';
  payload: {
    field: keyof MdrtPassportFormState;
    value: any;
  };
};

type SelectMdrtPassportAction = {
  type: 'SELECT_YEAR_ACHIEVEMENT';
  payload: {
    field: keyof MdrtPassportFormState;
    value: any;
  };
};

type CheckboxMdrtPassportAction = {
  type: 'SELECT_STAMP_CODE';
  payload: {
    field: keyof MdrtPassportFormState;
    value: any;
  };
};

const generateKey = () => {
  return `${Math.floor(Math.random() * 1000)}_${new Date().getTime()}`;
};

const initialMdrtPassport = {
  id: generateKey(),
  year: new Date().getFullYear().toString(),
  stampCodes: new Array(12).map((x) => ''),
  isDuplicate: false,
};

const initialState: MdrtPassportFormState = {
  id: undefined,
  agentCode: undefined,
  passports: [initialMdrtPassport],
};

const formReducer = (
  state: MdrtPassportFormState,
  action:
    | ModifyFieldAction
    | AddMdrtPassportAction
    | SelectMdrtPassportAction
    | CheckboxMdrtPassportAction
    | RemoveMdrtPassportAction,
): MdrtPassportFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    case 'ADD_PASSPORT':
      return {
        ...state,
        [action.payload.field]: [...(state.passports || []), action.payload.value],
      };
    case 'SELECT_YEAR_ACHIEVEMENT':
      const newMdrtPassport = state.passports?.map((passport) => {
        if (passport.id === action.payload.value.id) {
          passport.year = action.payload.value.year;
        }
        return passport;
      });

      const lookup = (newMdrtPassport || []).reduce((a: { [key: string]: number }, e) => {
        a[e.year] = ++a[e.year] || 0;
        return a;
      }, {});
      const duplicateYear = [
        ...new Set((newMdrtPassport || []).filter((e) => lookup[e.year] && lookup[e.year] > 0).map(({ year }) => year)),
      ];

      newMdrtPassport?.forEach((passport) => {
        duplicateYear.includes(passport.year) ? (passport.isDuplicate = true) : (passport.isDuplicate = false);
      });

      return {
        ...state,
        passports: newMdrtPassport,
      };

    case 'REMOVE_PASSPORT':
      const { passportId } = action.payload.value;

      return {
        ...state,
        passports: state.passports?.filter((passport) => passport.id !== passportId),
      };

    case 'SELECT_STAMP_CODE':
      const { id, month, stampCode } = action.payload.value;
      const selectStampCodes = state.passports?.map((passport) => {
        if (passport.id === id) {
          passport.stampCodes[month] = stampCode;
        }
        return passport;
      });

      return {
        ...state,
        passports: selectStampCodes,
      };
    default:
      return state;
  }
};

const detailToStateConvertor = (detail: any): MdrtPassportFormState => {
  return {
    id: detail.id,
    agentCode: detail.agentCode,
    passports: detail.passports.map((mdrtPassport: Passport) => ({
      ...mdrtPassport,
      id: generateKey(),
    })),
  };
};

const MdrtPassportYear = () => {
  const currentYear = new Date().getFullYear();
  const minYear = 2000;
  const yearSelectList = [];
  for (let i = currentYear + 1; i >= minYear; i--) {
    yearSelectList.push(i.toString());
  }

  return yearSelectList;
};

const MdrtPassportTitleGroup = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const initialParams = {
  page: 1,
  limit: 5,
};

const MdrtPassportDetailForm: FC<MdrtPassportDetailFormProps> = ({
  id,
  formMode,
  mdrtPassport,
  onReload,
  onRouteTo,
}) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const commonClasses = useCommonStyles().classes;
  const intl = useIntl();
  const isLoading = useSelector<RootState, boolean>((state) => !!state.common.loading);
  const Translation = (id: string) => intl.formatMessage({ id });
  const yearSelectList = MdrtPassportYear();

  const [formState, formDispatch] = useReducer(
    formReducer,
    mdrtPassport
      ? detailToStateConvertor({
          passports: mdrtPassport.passports,
          agentCode: mdrtPassport.agentCode,
          id,
        })
      : JSON.parse(JSON.stringify(initialState)),
  );

  const isDisabled = useMemo(() => {
    return formMode === MdrtPassportFormMode.CREATE || formMode === MdrtPassportFormMode.EDIT ? false : true;
  }, [formMode]);
  const [agentStatus, setAgentStatus] = useState<AgentStatus>();
  const [stampCodeList, setStampCodeList] = useState<MdrtStampIconItem[]>();
  const [existsAgent, setExistsAgent] = useState<Boolean>(false);

  useEffect(() => {
    fetchList(initialParams)
      .then((res) => setStampCodeList(res.items))
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const { errorState, onSubmitErrorValidator, onDismissErrorHandler } = useErrorHandler(formState, [
    {
      name: 'agentCode',
      fieldType: ErrorFieldType.MANDATORY,
    },
    {
      name: 'year',
      fieldType: ErrorFieldType.MANDATORY,
      condition: () => {
        const years = formState.passports?.map(({ year }) => year) || [];
        if (years.some((year) => year === '')) return true;

        return new Set(years).size !== years.length;
      },
    },
  ]);

  const onSubmitForm = async (status: PublishStatus) => {
    let { hasError } = onSubmitErrorValidator();
    if (
      (agentStatus && (agentStatus.status === StatusModel.NotFound || agentStatus.status !== StatusModel.Active)) ||
      existsAgent
    ) {
      return;
    }
    dispatch(showLoading(true));

    if (!hasError) {
      const mdrtPassportDetail: MdrtPassportDetail = {
        id: formState.id || id || '',
        agentCode: formState.agentCode || '',
        passports: (formState.passports || []).map(({ stampCodes, year }) => {
          const initStampCodes = Array.from(Array(12), (_, i) => stampCodes[i]);
          return {
            stampCodes: initStampCodes,
            year,
          };
        }),
        status,
      };
      if (!mdrtPassportDetail.id) delete mdrtPassportDetail.id;

      try {
        await addMdrtPassport(mdrtPassportDetail, dispatch);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: 'Success',
              content: `Updated successfully - ${mdrtPassportDetail.agentCode}`,
            },
          ]),
        );
        onRouteTo(`${MDRT_HUB_BASE_PATH}${MDRT_PASSPORT_BASE_PATH}`);
      } catch (err) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: 'Error',
              content: `Saved error - ${JSON.stringify(err)}`,
            },
          ]),
        );
      }
    }
    setTimeout(() => dispatch(showLoading(false)), 2000);
  };

  const addMore = () => {
    formDispatch({
      type: 'ADD_PASSPORT',
      payload: {
        field: 'passports',
        value: {
          id: generateKey(),
          year: '',
          stampCodes: [],
          isDuplicate: false,
        },
      },
    });
  };

  const remove = (passportId: string | undefined) => {
    if (!passportId) return;

    formDispatch({
      type: 'REMOVE_PASSPORT',
      payload: {
        field: 'passports',
        value: {
          passportId,
        },
      },
    });
  };

  const checkAgentStatus = async (agentCode: string) => {
    setAgentStatus(undefined);
    if (!agentCode) return;
    try {
      const res = await getAgentStatus(agentCode, dispatch);
      if (res && res.agents && res.agents[0]) {
        const agentStatus = res.agents[0];
        setAgentStatus(agentStatus);
      } else {
        setAgentStatus({
          agentCode,
          status: StatusModel.NotFound,
        });
      }
    } catch (error) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'ERROR',
            content: `Get Agent Status ERROR - ${error}`,
          },
        ]),
      );
      setAgentStatus({
        agentCode,
        status: StatusModel.NotFound,
      });
    }
  };
  const checkExistsAgent = async (agentCode: string) => {
    setExistsAgent(false);
    if (!agentCode) return;
    try {
      const res = await getPassportByAgentCode(agentCode, dispatch);
      if (res) {
        setExistsAgent(true);
      } else {
        setExistsAgent(false);
      }
    } catch (error) {}
  };

  const debounceCheckAgentStatus = useDebounce((agentCode: string) => {
    checkAgentStatus(agentCode);
    checkExistsAgent(agentCode);
  }, 1000);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.rowContainer}>
            <div className={commonClasses.header}>
              {Translation(
                formMode === MdrtPassportFormMode.CREATE ? 'mdrtHub.mdrtPassport.add' : 'mdrtHub.mdrtPassport.edit',
              )}
            </div>
          </div>
          <Button variant="contained" onClick={() => onRouteTo(`${MDRT_HUB_BASE_PATH}${MDRT_PASSPORT_BASE_PATH}`)}>
            Back
          </Button>
        </div>

        <div className={classes.sectionMargin}>
          <div className={classes.flexItem}>
            <div className={classes.fieldContainer}>
              <span className={classes.field}>Agent Code:</span>
            </div>
            <div style={{ flexGrow: 1 }}>
              {
                <TextField
                  style={{ width: '100%' }}
                  error={
                    errorState.mandatory.agentCode ||
                    (agentStatus && agentStatus.status !== StatusModel.Active) ||
                    (agentStatus && agentStatus.status === StatusModel.NotFound) ||
                    !!existsAgent
                  }
                  margin="dense"
                  variant="outlined"
                  helperText={
                    (errorState.mandatory.agentCode && MANDATORY_FIELD_ERROR_TEXT) ||
                    (agentStatus && agentStatus.status === StatusModel.NotFound && AGENT_CODE_NOT_FOUND_ERROR_TEXT) ||
                    (agentStatus && agentStatus.status !== StatusModel.Active && AGENT_CODE_NOT_ACTIVE_ERROR_TEXT) ||
                    (existsAgent && AGENT_CODE_EXISTS_ERROR_TEXT)
                  }
                  value={formState.agentCode}
                  disabled={isDisabled}
                  onChange={(e) => {
                    onDismissErrorHandler('name', e.target.value);
                    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'agentCode', value: e.target.value } });
                    debounceCheckAgentStatus(e.target.value);
                  }}
                  inputProps={{ maxLength: 100 }}
                />
              }
            </div>
          </div>

          <hr />

          {formState.passports?.map((passport) => {
            return (
              <>
                <div className={classes.flexItem} key={passport.id}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}>Year:</span>
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <Select
                      disabled={isDisabled}
                      style={{ width: '100%' }}
                      native
                      margin="dense"
                      variant="outlined"
                      value={passport.year}
                      error={passport.isDuplicate || (errorState.mandatory.year && passport.year === '')}
                      onChange={(e) => {
                        const year = {
                          id: passport.id,
                          year: e.target.value,
                        };
                        onDismissErrorHandler('year', year);
                        formDispatch({ type: 'SELECT_YEAR_ACHIEVEMENT', payload: { field: 'passports', value: year } });
                      }}
                    >
                      <option aria-label="None" value="" />
                      {map(yearSelectList, (option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                    {passport.isDuplicate && (
                      <FormHelperText className={classes.errorText}>{YEAR_FIELD_ERROR_TEXT}</FormHelperText>
                    )}
                    {errorState.mandatory.year && passport.year === '' && (
                      <FormHelperText className={classes.errorText}>{MANDATORY_FIELD_ERROR_TEXT}</FormHelperText>
                    )}
                  </div>
                </div>
                <div className={classes.flexItem}>
                  <div className={classes.fieldContainer}>
                    <span className={classes.field}></span>
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {map(MdrtPassportTitleGroup, (month, index) => {
                      return (
                        <>
                          <div className={classes.fieldContainerYear} key={month}>
                            <span className={classes.field} style={{ width: 50 }}>
                              {month}:
                            </span>
                            <Select
                              disabled={isDisabled}
                              style={{ width: '100%' }}
                              native
                              margin="dense"
                              variant="outlined"
                              value={passport.stampCodes[index]}
                              onChange={(e) => {
                                const stampCode = {
                                  id: passport.id,
                                  stampCode: e.target.value,
                                  month: index,
                                };
                                formDispatch({
                                  type: 'SELECT_STAMP_CODE',
                                  payload: { field: 'passports', value: stampCode },
                                });
                              }}
                            >
                              <option aria-label="None" value="" />
                              {stampCodeList &&
                                map(stampCodeList, (option) => (
                                  <option key={option.stampCode} value={option.stampCode}>
                                    {option.stampName}
                                  </option>
                                ))}
                            </Select>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                {formState.passports && formState.passports.length > 1 && !isDisabled ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.removeBtn}
                    onClick={() => remove(passport.id)}
                  >
                    Remove
                  </Button>
                ) : null}
                <hr />
              </>
            );
          })}

          {!isDisabled ? (
            <Button variant="contained" color="secondary" onClick={() => addMore()}>
              Add More
            </Button>
          ) : (
            <></>
          )}
        </div>

        <div className={classes.footerContainer}>
          {isDisabled ? (
            <></>
          ) : (
            <>
              <Button
                variant="contained"
                color="secondary"
                disabled={isLoading}
                onClick={() => onSubmitForm(PublishStatus.UNPUBLISHED)}
              >
                Save as Draft
              </Button>
              <Button
                style={{ marginLeft: 25 }}
                variant="contained"
                color="secondary"
                disabled={isLoading}
                onClick={() => onSubmitForm(PublishStatus.PUBLISHED)}
              >
                Publish
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MdrtPassportDetailForm;
