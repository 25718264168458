import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  AdminMdrtPassportPermissionType,
  MDRT_HUB_TITLE,
  MDRT_HUB_BASE_PATH,
  MDRT_PASSPORT_TITLE,
  MDRT_PASSPORT_BASE_PATH,
  MDRT_PASSPORT_NAMESPACE,
  QUOTES_AND_CERTIFICATE_TITLE,
  QUOTES_AND_CERTIFICATE_BASE_PATH,
  QUOTES_AND_CERTIFICATE_NAMESPACE,
  ACHIEVEMENT_UPLOAD_BASE_PATH,
  ACHIEVEMENT_UPLOAD_NAMESPACE,
  ACHIEVEMENT_UPLOAD_TITLE,
  AdminMdrtAchievementUploadPermissionType,
  MDRT_STAMP_ICON_BASE_PATH,
  MDRT_STAMP_ICON_NAMESPACE,
  MDRT_STAMP_ICON_TITLE,
  MDRT_PASSPORT_BACKGROUND_BASE_PATH,
  MDRT_PASSPORT_BACKGROUND_TITLE,
  MDRT_PASSPORT_BACKGROUND_NAMESPACE,
  AdminMdrtPassportBackgroundPermissionType,
  AdminMdrtStampIconPermissionType,
  AdminQuotesAndCertificatePermissionType,
  MDRT_STORY_BASE_PATH,
  MDRT_STORY_NAMESPACE,
  AdminMdrtStoryPermissionType,
  MDRT_VIDEO_LIBRARY_TITLE,
  MDRT_VIDEO_LIBRARY_BASE_PATH,
  MDRT_STORY_TITLE,
  MDRT_VIDEO_LIBRARY_NAMESPACE,
  AdminMdrtVideoLibraryPermissionType,
  MDRT_BANNER_TITLE,
  MDRT_BANNER_BASE_PATH,
  MDRT_BANNER_NAMESPACE,
  AdminMdrtBannerPermissionType,
} from './constants';
import AchievementUploadRoutes from './pages/AchievementUpload/AchievementUploadRoutes';
import MdrtBannerRoutes from './pages/MdrtBanner/MdrtBannerRoutes';
import MdrtPassportRoutes from './pages/MdrtPassport/MdrtPassportRoutes';
import MdrtPassportBackgroundRoutes from './pages/MdrtPassportBackground/MdrtPassportBackgroundRoutes';
import MdrtStampIconRoutes from './pages/MdrtStampIcon/MdrtStampIconRoutes';
import MdrtStoryRoutes from './pages/MdrtStory/MdrtStoryRoutes';
import MdrtVideoLibraryRoutes from './pages/MdrtVideoLibrary/MdrtVideoLibraryRoutes';
import QuotesAndCertificateRoutes from './pages/QuotesAndCertificate/QuotesAndCertificateRoutes';
import { isPermitted } from 'src/app/common/utils';

export const mdrtHubModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });

  return {
    title: Translation(MDRT_HUB_TITLE),
    path: MDRT_HUB_BASE_PATH,
    icon: '/media/svg/icons/Communication/Mdrt-config.svg',
    enableRead: isPermitted(
      [
        AdminMdrtAchievementUploadPermissionType.ACHIEVEMENT_UPLOAD_READ,
        AdminMdrtPassportPermissionType.MDRT_PASSPORT_READ,
        AdminQuotesAndCertificatePermissionType.QUOTES_AND_CERTIFICATE_READ,
        AdminMdrtStampIconPermissionType.MDRT_STAMP_ICON_READ,
        AdminMdrtPassportBackgroundPermissionType.MDRT_PASSPORT_BACKGROUND_READ,
        AdminMdrtBannerPermissionType.MDRT_BANNER_READ,
        AdminMdrtStoryPermissionType.MDRT_STORY_READ,
        AdminMdrtVideoLibraryPermissionType.MDRT_VIDEO_LIBRARY_READ
      ],
      permissions,
    ),
    child: [
      {
        title: Translation(ACHIEVEMENT_UPLOAD_TITLE),
        path: `${MDRT_HUB_BASE_PATH}${ACHIEVEMENT_UPLOAD_BASE_PATH}`,
        namespace: ACHIEVEMENT_UPLOAD_NAMESPACE,
        component: AchievementUploadRoutes,
        enableRead: isPermitted([AdminMdrtAchievementUploadPermissionType.ACHIEVEMENT_UPLOAD_READ], permissions),
        enableCreate: isPermitted([AdminMdrtAchievementUploadPermissionType.ACHIEVEMENT_UPLOAD_CREATE], permissions),
        enableUpdate: isPermitted([AdminMdrtAchievementUploadPermissionType.ACHIEVEMENT_UPLOAD_UPDATE], permissions),
        enableDelete: isPermitted([AdminMdrtAchievementUploadPermissionType.ACHIEVEMENT_UPLOAD_DELETE], permissions),
        options: {
          enableDelete: true,
        },
      },
      {
        title: Translation(MDRT_PASSPORT_TITLE),
        path: `${MDRT_HUB_BASE_PATH}${MDRT_PASSPORT_BASE_PATH}`,
        namespace: MDRT_PASSPORT_NAMESPACE,
        component: MdrtPassportRoutes,
        enableRead: isPermitted([AdminMdrtPassportPermissionType.MDRT_PASSPORT_READ], permissions),
        enableCreate: isPermitted([AdminMdrtPassportPermissionType.MDRT_PASSPOR_CREATE], permissions),
        enableUpdate: isPermitted([AdminMdrtPassportPermissionType.MDRT_PASSPOR_UPDATE], permissions),
        enableDelete: isPermitted([AdminMdrtPassportPermissionType.MDRT_PASSPOR_DELETE], permissions),
        options: {
          enableDelete: true,
        },
      },
      {
        title: Translation(QUOTES_AND_CERTIFICATE_TITLE),
        path: `${MDRT_HUB_BASE_PATH}${QUOTES_AND_CERTIFICATE_BASE_PATH}`,
        namespace: QUOTES_AND_CERTIFICATE_NAMESPACE,
        component: QuotesAndCertificateRoutes,
        enableRead: isPermitted([AdminQuotesAndCertificatePermissionType.QUOTES_AND_CERTIFICATE_READ], permissions),
        enableCreate: isPermitted([AdminQuotesAndCertificatePermissionType.QUOTES_AND_CERTIFICATE_CREATE], permissions),
        enableUpdate: isPermitted([AdminQuotesAndCertificatePermissionType.QUOTES_AND_CERTIFICATE_UPDATE], permissions),
        enableDelete: isPermitted([AdminQuotesAndCertificatePermissionType.QUOTES_AND_CERTIFICATE_DELETE], permissions),
        options: {
          enableDelete: true,
        },
      },
      {
        title: Translation(MDRT_STAMP_ICON_TITLE),
        path: `${MDRT_HUB_BASE_PATH}${MDRT_STAMP_ICON_BASE_PATH}`,
        namespace: MDRT_STAMP_ICON_NAMESPACE,
        component: MdrtStampIconRoutes,
        enableRead: isPermitted([AdminMdrtStampIconPermissionType.MDRT_STAMP_ICON_READ], permissions),
        enableCreate: isPermitted([AdminMdrtStampIconPermissionType.MDRT_STAMP_ICON_CREATE], permissions),
        enableUpdate: isPermitted([AdminMdrtStampIconPermissionType.MDRT_STAMP_ICON_UPDATE], permissions),
        enableDelete: isPermitted([AdminMdrtStampIconPermissionType.MDRT_STAMP_ICON_DELETE], permissions),
        options: {
          enableDelete: true,
        },
      },
      {
        title: Translation(MDRT_PASSPORT_BACKGROUND_TITLE),
        path: `${MDRT_HUB_BASE_PATH}${MDRT_PASSPORT_BACKGROUND_BASE_PATH}`,
        namespace: MDRT_PASSPORT_BACKGROUND_NAMESPACE,
        component: MdrtPassportBackgroundRoutes,
        enableRead: isPermitted([AdminMdrtPassportBackgroundPermissionType.MDRT_PASSPORT_BACKGROUND_READ], permissions),
        enableUpdate: isPermitted(
          [AdminMdrtPassportBackgroundPermissionType.MDRT_PASSPORT_BACKGROUND_UPDATE],
          permissions,
        ),
      },
      {
        title: Translation(MDRT_STORY_TITLE),
        path: `${MDRT_HUB_BASE_PATH}${MDRT_STORY_BASE_PATH}`,
        namespace: MDRT_STORY_NAMESPACE,
        component: MdrtStoryRoutes,
        enableRead: isPermitted([AdminMdrtStoryPermissionType.MDRT_STORY_READ], permissions),
        enableCreate: isPermitted([AdminMdrtStoryPermissionType.MDRT_STORY_CREATE], permissions),
        enableUpdate: isPermitted([AdminMdrtStoryPermissionType.MDRT_STORY_UPDATE], permissions),
        enableDelete: isPermitted([AdminMdrtStoryPermissionType.MDRT_STORY_DELETE], permissions),
        options: {
          enableDelete: true,
        },
      },
      {
        title: Translation(MDRT_VIDEO_LIBRARY_TITLE),
        path: `${MDRT_HUB_BASE_PATH}${MDRT_VIDEO_LIBRARY_BASE_PATH}`,
        namespace: MDRT_VIDEO_LIBRARY_NAMESPACE,
        component: MdrtVideoLibraryRoutes,
        enableRead: isPermitted([AdminMdrtVideoLibraryPermissionType.MDRT_VIDEO_LIBRARY_READ], permissions),
        enableCreate: isPermitted([AdminMdrtVideoLibraryPermissionType.MDRT_VIDEO_LIBRARY_CREATE], permissions),
        enableUpdate: isPermitted([AdminMdrtVideoLibraryPermissionType.MDRT_VIDEO_LIBRARY_UPDATE], permissions),
        enableDelete: isPermitted([AdminMdrtVideoLibraryPermissionType.MDRT_VIDEO_LIBRARY_DELETE], permissions),
        options: {
          enableDelete: true,
        },
      },
      {
        title: Translation(MDRT_BANNER_TITLE),
        path: `${MDRT_HUB_BASE_PATH}${MDRT_BANNER_BASE_PATH}`,
        namespace: MDRT_BANNER_NAMESPACE,
        component: MdrtBannerRoutes,
        enableRead: isPermitted([AdminMdrtBannerPermissionType.MDRT_BANNER_READ], permissions),
        enableCreate: isPermitted([AdminMdrtBannerPermissionType.MDRT_BANNER_CREATE], permissions),
        enableUpdate: isPermitted([AdminMdrtBannerPermissionType.MDRT_BANNER_UPDATE], permissions),
        enableDelete: isPermitted([AdminMdrtBannerPermissionType.MDRT_BANNER_DELETE], permissions),
        options: {
          enableDelete: true,
        },
      },
    ],
  };
};
