import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { appendAlertItem, AlertType } from '@pruforce/common-adminweb-sdk';
import { getDisplayDate } from '../../../../../../common/utils/time-utils';
import { PaginateListMore } from '../../../../../../common/types/common-types';
import { useIntl } from 'react-intl';
import PruTable from '../../../../../../common/components/PruTable/PruTable';
import { useHistory } from 'react-router-dom';
import {
  BdmBackgroundImageFormMode,
  BdmBackgroundImageItem,
  PublishStatus,
} from '../../../../components/BdmBackgroundImage/types/bdm-background-image-types';
import { deleteItem } from '../../../../components/BdmBackgroundImage/network/bdmBackgroundImageCrud';
import { SALESKIT_BASE_PATH, BDM_BACKGROUND_BASE_PATH, BDM_TEMPLETE_BASE_PATH } from '../../../../constants';

type BdmBackgroundImageListProps = {
  isLoading: boolean;
  itemList?: PaginateListMore<BdmBackgroundImageItem>;
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
};

let idDelete: string | null = null;

const BdmBackgroundImageList: FC<BdmBackgroundImageListProps> = ({ isLoading, itemList, onRefresh, onChangePage }) => {
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  // confirm delete modal
  const [statusModal, setStatusModal] = useState(false);
  const handleModal = (status: boolean) => {
    setStatusModal(status);
  };

  const onDelete = async () => {
    if (!idDelete) {
      return true;
    }

    try {
      await deleteItem(idDelete, dispatch);
      handleModal(false);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Deleted successfully`,
          },
        ]),
      );
      onRefresh();
    } catch (err) {}
  };

  return (
    <>
      <PruTable
        title={Translation('component.formTitle.bdm-templete-background')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('app.button.add'),
            onClick: () =>
              history.push(`${SALESKIT_BASE_PATH}${BDM_TEMPLETE_BASE_PATH}${BDM_BACKGROUND_BASE_PATH}/create`, {
                type: BdmBackgroundImageFormMode.CREATE,
              }),
          },
        ]}
        disableBulkSelect
        operationDef={[
          {
            title: Translation('app.button.view'),
            tooltipText: 'View Video',
            onClick: (row) =>
              history.push(
                `${SALESKIT_BASE_PATH}${BDM_TEMPLETE_BASE_PATH}${BDM_BACKGROUND_BASE_PATH}/view/${row._id}`,
                { type: BdmBackgroundImageFormMode.VIEW },
              ),
            condition: () => true,
          },
          {
            title: Translation('app.button.edit'),
            tooltipText: 'Edit Video',
            onClick: (row) =>
              history.push(
                `${SALESKIT_BASE_PATH}${BDM_TEMPLETE_BASE_PATH}${BDM_BACKGROUND_BASE_PATH}/edit/${row._id}`,
                { type: BdmBackgroundImageFormMode.EDIT },
              ),
            condition: (row) => row.publishStatus !== PublishStatus.PUBLISHED,
          },
        ]}
        columnDef={[
          {
            keyIndex: 'name',
            displayName: Translation('component.formLabel.name'),
            renderData: (row) => row.name || '',
          },
          {
            keyIndex: 'type',
            displayName: Translation('component.formLabel.type'),
            renderData: (row) => row.type || '',
          },
          {
            keyIndex: 'categoryName',
            displayName: Translation('component.formLabel.category'),
            renderData: (row) => row.categoryName || '',
          },

          {
            keyIndex: 'lastUpdatedDate',
            align: 'center',
            displayName: Translation('component.formTitle.bdm-templete-background.uploaded-time'),
            renderData: (row) => (row.updatedAt ? getDisplayDate(new Date(row.updatedAt), 'YYYY-MM-DD HH:mm') : ''),
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={itemList?.items}
        totalPages={itemList?.totalPages}
        totalRecords={itemList?.totalRecords}
        onChangePage={onChangePage}
      />
    </>
  );
};

export default BdmBackgroundImageList;
