import React, { FC, useState } from 'react';
import { fetchRecruitmentDetailsReport, fetchReportDownload } from '../../../network/recruitmentDetailsReportCrud';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { getDayEnd, getDisplayDate, getFirstDayOfMonth, getDayStart } from '../../../../../common/utils/time-utils';
import PruTableTab from '../../../../../common/components/PruTableTab/PruTableTab';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTableTab/PruFilter';
import { PaginateListMore } from 'src/app/common/types/common-types';
import { ReportListParams, ReportItem } from '../../../types/recruitment-details-types';
import { makeStyles } from 'tss-react/mui';
import { useDataProvider } from 'src/app/common/utils';
import { Align } from 'src/app/common/components/ProTable';

type PRUDNADetailReportProps = {} & ParamsProps;

enum AgentRoleEnum {
  All = 'all',
  Agent = 'AG',
  BM = 'BM',
  UM = 'UM',
  PUM = 'PM',
  Other = 'Other',
}

enum SalesChannelEnum {
  All = 'all',
  Traditional = 'AGENCY',
  Banca = 'BANCA',
  Pruventure = 'GALLERIE',
  Other = 'other',
}

enum TypesOfPRUDNAEnum {
  All = 'all',
  POP7 = 'pop7',
  POP8 = 'pop8',
  POPS = 'pops',
}

const initialState: ReportListParams = {
  registerDateFrom: getFirstDayOfMonth(new Date()),
  registerDateTo: getDayEnd(new Date()),
  agentCode: '',
  agentName: '',
  leaderCode: '',
  leaderName: '',
  candidateName: '',
  salesChannel: [SalesChannelEnum.All],
  agentRole: [AgentRoleEnum.All],
  typesOfPRUDNA: [TypesOfPRUDNAEnum.All],
  isDisableAL: false,
  isDisableAG: false,
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): ReportListParams => {
  return initialParams
    ? {
        registerDateFrom: initialParams.registerDateFrom
          ? getDayStart(new Date(initialParams.registerDateFrom))
          : getFirstDayOfMonth(new Date()),
        registerDateTo: initialParams.registerDateTo
          ? getDayEnd(new Date(initialParams.registerDateTo))
          : getDayEnd(new Date()),
        agentCode: '',
        agentName: '',
        leaderCode: '',
        leaderName: '',
        candidateName: '',
        salesChannel: [SalesChannelEnum.All],
        agentRole: [AgentRoleEnum.All],
        typesOfPRUDNA: [TypesOfPRUDNAEnum.All],
        isDisableAL: false,
        isDisableAG: false,
        page: 1,
        limit: 5,
      }
    : initialState;
};

const salesChannelOptions = [
  { displayName: 'All', value: SalesChannelEnum.All },
  { displayName: 'Traditional Agent', value: SalesChannelEnum.Traditional },
  { displayName: 'Pruventure', value: SalesChannelEnum.Pruventure },
  { displayName: 'Banca', value: SalesChannelEnum.Banca },
  { displayName: 'Other', value: SalesChannelEnum.Other },
];

const agentRoleOptions = [
  { displayName: 'All', value: AgentRoleEnum.All },
  { displayName: 'Agent', value: AgentRoleEnum.Agent },
  { displayName: 'BM', value: AgentRoleEnum.BM },
  { displayName: 'UM', value: AgentRoleEnum.UM },
  { displayName: 'PUM', value: AgentRoleEnum.PUM },
  { displayName: 'Other', value: AgentRoleEnum.Other },
];

const typeOfPRUDNAOptions = [
  { displayName: 'All', value: TypesOfPRUDNAEnum.All },
  { displayName: 'POP 7', value: TypesOfPRUDNAEnum.POP7 },
  { displayName: 'Express POP', value: TypesOfPRUDNAEnum.POP8 },
  { displayName: 'POP Screen', value: TypesOfPRUDNAEnum.POPS },
];

const formatSalesChannel = (channel: string) => {
  switch (channel) {
    case SalesChannelEnum.Traditional:
      return 'Agency';
    case SalesChannelEnum.Banca:
      return 'Banca';
    case SalesChannelEnum.Pruventure:
      return 'Pruventure';
    default:
      return 'Other';
  }
};

const formatAgentRole = (role: string) => {
  switch (role) {
    case AgentRoleEnum.All:
      return 'All';
    case AgentRoleEnum.Agent:
      return 'Agent';
    case AgentRoleEnum.BM:
      return 'BM';
    case AgentRoleEnum.UM:
      return 'UM';
    case AgentRoleEnum.PUM:
      return 'PUM';
    default:
      return 'Other';
  }
};

const formatTypeOfPRUDNA = (role: string) => {
  switch (role) {
    case TypesOfPRUDNAEnum.All:
      return 'All';
    case TypesOfPRUDNAEnum.POP7:
      return 'POP 7';
    case TypesOfPRUDNAEnum.POP8:
      return 'Express POP';
    case TypesOfPRUDNAEnum.POPS:
      return 'POP Screen';
    default:
      return '-';
  }
};

const formatAssessmentStatus = (role: string) => {
  switch (role) {
    case 'Completed':
      return 'Completed';
    case 'InComplete':
      return 'Not Completed';
    case 'NotSend':
      return 'Not Sent';
    default:
      return 'All';
  }
};

const formatWorkingMonth = (date: string) => {
  const currentDate = new Date();
  const joinedDate = new Date(date);
  let months;
  months = (currentDate.getFullYear() - joinedDate.getFullYear()) * 12;
  months -= joinedDate.getMonth();
  months += currentDate.getMonth();
  return months <= 0 ? 0 : months;
};

const useStyles = makeStyles()((theme) => ({
  disableInput: {
    '& fieldset': {
      background: '#DDD',
    },
  },
  reportLink: {
    color: '#4386E5',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '150%',
  },
}));

const PRUDNADetailReport: FC<PRUDNADetailReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const defaultFilterValue = paramsInitiator(initialParams);
  const tabOptions = [
    { displayName: Translation('report.recruitment.details.tab.potential'), value: AgentRoleEnum.All },
    { displayName: Translation('report.recruitment.details.tab.obclass'), value: AgentRoleEnum.Agent },
    { displayName: Translation('report.recruitment.details.tab.agent'), value: AgentRoleEnum.Agent },
  ];
  const [filterState, setFilterState] = useState<ReportListParams>(defaultFilterValue);
  const [reportList, setReportList] = useState<PaginateListMore<ReportItem>>();
  const [currentTab, setTabValue] = React.useState<number>(0);
  const onChangeTab = (event: any, tab: number) => {
    setTabValue(tab);
    refreshData();
  };
  const { isLoading, refreshData } = useDataProvider<PaginateListMore<ReportItem>>(
    async () => {
      try {
        return await fetchRecruitmentDetailsReport(filterState, currentTab, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvtDownload = () => {
    try {
      fetchReportDownload(filterState, currentTab, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('report.recruitment.details.reportFilter')}
        itemDef={[
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'registerDateFrom',
            fieldTo: 'registerDateTo',
            initialDateFrom: filterState.registerDateFrom,
            initialDateTo: filterState.registerDateTo,
            defaultDateFrom: defaultFilterValue.registerDateFrom,
            defaultDateTo: defaultFilterValue.registerDateTo,
            displayName: Translation('report.recruitment.details.timeRange'),
            required: true,
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            disabled: filterState.isDisableAG,
            className: filterState.isDisableAG ? classes.disableInput : null,
            displayName: Translation('report.recruitment.details.agentCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentName',
            initialValue: filterState.agentName,
            disabled: filterState.isDisableAG,
            className: filterState.isDisableAG ? classes.disableInput : null,
            displayName: Translation('report.recruitment.details.agentName'),
          },
          {
            type: PruFilterItemType.MULTIPLE_SELECT,
            field: 'agentRole',
            initialValue: filterState.agentRole,
            defaultValue: initialState.agentRole,
            allValue: AgentRoleEnum.All,
            displayName: Translation('report.recruitment.details.agentRole'),
            choices: agentRoleOptions,
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'leaderCode',
            initialValue: filterState.leaderCode,
            disabled: filterState.isDisableAL,
            className: filterState.isDisableAL ? classes.disableInput : null,
            displayName: Translation('report.recruitment.details.leaderCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'leaderName',
            initialValue: filterState.leaderName,
            disabled: filterState.isDisableAL,
            className: filterState.isDisableAL ? classes.disableInput : null,
            displayName: Translation('report.recruitment.details.leaderName'),
          },
          {
            type: PruFilterItemType.MULTIPLE_SELECT,
            field: 'salesChannel',
            initialValue: filterState.salesChannel,
            defaultValue: initialState.salesChannel,
            allValue: SalesChannelEnum.All,
            displayName: Translation('report.recruitment.details.salesChannel'),
            choices: salesChannelOptions,
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'candidateName',
            initialValue: filterState.candidateName,
            displayName: Translation('report.recruitment.details.candidateName'),
          },
          {
            type: PruFilterItemType.MULTIPLE_SELECT,
            field: 'typesOfPRUDNA',
            initialValue: filterState.typesOfPRUDNA,
            defaultValue: initialState.typesOfPRUDNA,
            allValue: TypesOfPRUDNAEnum.All,
            disabled: currentTab == 0 ? true : false,
            className: currentTab == 0 ? classes.disableInput : null,
            displayName: Translation('report.recruitment.details.typesOfPRUDNA'),
            choices: typeOfPRUDNAOptions,
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            agentCode: data.agentCode,
            agentName: data.agentName,
            leaderCode: data.leaderCode,
            leaderName: data.leaderName,
            candidateName: data.candidateName,
            registerDateFrom: data.registerDateFrom,
            registerDateTo: data.registerDateTo,
            salesChannel: data.salesChannel,
            agentRole: data.agentRole,
            typesOfPRUDNA: currentTab == 0 ? TypesOfPRUDNAEnum.All : data.typesOfPRUDNA,
            isDisableAL: data.agentCode || data.agentName ? true : false,
            isDisableAG: data.leaderCode || data.leaderName ? true : false,
          })
        }
        fetchData={refreshData}
      />
      <PruTableTab
        disableBulkSelect
        dataSource={reportList?.items}
        tabItems={tabOptions}
        tabValue={currentTab}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.recruitment.details.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvtDownload(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row: any) => row._id || '-',
          },
          {
            keyIndex: 'agentCode',
            align: 'center',
            displayName: Translation('report.recruitment.details.agentCode'),
            renderData: (row: any) => row.agentCode || '-',
          },
          {
            keyIndex: 'agentName',
            displayName: Translation('report.recruitment.details.agentName'),
            renderData: (row: any) => row.agentName || '-',
          },
          {
            keyIndex: 'agentRole',
            align: 'center',
            displayName: Translation('report.recruitment.details.agentRole'),
            renderData: (row: any) => formatAgentRole(row.agentRole),
          },
          {
            keyIndex: 'leaderCode',
            align: 'center',
            displayName: Translation('report.recruitment.details.leaderCode'),
            renderData: (row: any) => row.leaderCode || '-',
          },
          {
            keyIndex: 'leaderName',
            displayName: Translation('report.recruitment.details.leaderName'),
            renderData: (row: any) => row.leaderName || '-',
          },
          {
            keyIndex: 'unitCode',
            align: 'center',
            displayName: Translation('report.recruitment.details.unitCode'),
            renderData: (row: any) => row.unitCode || '-',
          },
          {
            keyIndex: 'officeCode',
            align: 'center',
            displayName: Translation('report.recruitment.details.officeCode'),
            renderData: (row: any) => row.officeCode || '-',
          },
          ...(currentTab === 2
            ? [
                {
                  keyIndex: 'regionCode',
                  align: 'center' as Align,
                  displayName: Translation('report.recruitment.details.regionCode'),
                  renderData: (row: any) => row.regionCode,
                },
                {
                  keyIndex: 'territory',
                  align: 'center' as Align,
                  displayName: Translation('report.recruitment.details.territory'),
                  renderData: (row: any) => row.territory,
                },
                {
                  keyIndex: 'salesChannel',
                  align: 'center' as Align,
                  displayName: Translation('report.recruitment.details.salesChannel'),
                  renderData: (row: any) => formatSalesChannel(row.saleChannel),
                },
                {
                  keyIndex: 'assessmentStatus',
                  align: 'center' as Align,
                  displayName: Translation('report.recruitment.details.assessmentStatus'),
                  renderData: (row: any) => row.assessmentStatus,
                },
                {
                  keyIndex: 'assessmentType',
                  align: 'center' as Align,
                  displayName: Translation('report.recruitment.details.assessmentType'),
                  renderData: (row: any) => row.assessmentType,
                },
                {
                  keyIndex: 'createdAt',
                  align: 'center' as Align,
                  displayName: Translation('report.recruitment.details.createdAt'),
                  renderData: (row: any) => getDisplayDate(row.createdAt, 'DD-MM-YYYY HH:MM:SS') || '-',
                },
                {
                  keyIndex: 'openTime',
                  align: 'center' as Align,
                  displayName: Translation('report.recruitment.details.openTime'),
                  renderData: (row: any) => getDisplayDate(row.openTime, 'DD-MM-YYYY HH:MM:SS') || '-',
                },
                {
                  keyIndex: 'completionTime',
                  align: 'center' as Align,
                  displayName: Translation('report.recruitment.details.completionTime'),
                  renderData: (row: any) =>
                    row.completionTime ? getDisplayDate(row.completionTime, 'DD-MM-YYYY HH:MM:SS') : '-',
                },
                {
                  keyIndex: 'reportLink',
                  align: 'center' as Align,
                  displayName: Translation('report.recruitment.details.reportLink'),
                  renderData: (row: any) =>
                    row.reportLink ? (
                      <a className={classes.reportLink} href={row.reportLink}>
                        {row.reportLink}
                      </a>
                    ) : (
                      '-'
                    ),
                },
                {
                  keyIndex: 'appointedDate',
                  align: 'center' as Align,
                  displayName: Translation('report.recruitment.details.appointedDate'),
                  renderData: (row: any) => getDisplayDate(row.appointedDate, 'DD-MM-YYYY') || '-',
                },
                {
                  keyIndex: 'workingMonth',
                  align: 'center' as Align,
                  displayName: Translation('report.recruitment.details.workingMonth'),
                  renderData: (row: any) => formatWorkingMonth(row.appointedDate),
                },
              ]
            : [
                {
                  keyIndex: 'salesChannel',
                  align: 'center' as Align,
                  displayName: Translation('report.recruitment.details.salesChannel'),
                  renderData: (row: any) => formatSalesChannel(row.saleChannel),
                },
                {
                  keyIndex: 'candidateName',
                  displayName: Translation('report.recruitment.details.candidateName'),
                  renderData: (row: any) => row.candidateName || '-',
                },
                {
                  keyIndex: 'candidatePhone',
                  displayName: Translation('report.recruitment.details.candidatePhone'),
                  renderData: (row: any) => row.candidatePhone || '-',
                },
                {
                  keyIndex: 'candidateId',
                  displayName: Translation('report.recruitment.details.candidateId'),
                  renderData: (row: any) => row.candidateId || '-',
                },
                {
                  keyIndex: 'candidateEmail',
                  displayName: Translation('report.recruitment.details.candidateEmail'),
                  renderData: (row: any) => row.candidateEmail || '-',
                },
                {
                  keyIndex: 'interViewArranged',
                  displayName: Translation('report.recruitment.details.interview_arranged(y/n)'),
                  renderData: (row: any) => row.interViewArranged || '-',
                },
                {
                  keyIndex: 'typesOfPRUDNA',
                  align: 'center' as Align,
                  displayName: Translation('report.recruitment.details.typesOfPRUDNAAssessmentSent'),
                  renderData: (row: any) => formatTypeOfPRUDNA(row.typeOfAssessment),
                },
                {
                  keyIndex: 'sendTime',
                  align: 'center' as Align,
                  displayName: Translation('report.recruitment.details.timeRange'),
                  renderData: (row: any) => getDisplayDate(row.sendTime, 'DD-MM-YYYY HH:MM:SS'),
                },
                {
                  keyIndex: 'assessmentStatus',
                  align: 'center' as Align,
                  displayName: Translation('report.recruitment.details.prudna_assessment_status'),
                  renderData: (row: any) => formatAssessmentStatus(row.assessmentStatus),
                },
                {
                  keyIndex: 'completionTime',
                  align: 'center' as Align,
                  displayName: Translation('report.recruitment.details.prudna_completion_time'),
                  renderData: (row: any) => getDisplayDate(row.completionTime, 'DD-MM-YYYY HH:MM:SS'),
                },
                {
                  keyIndex: 'reportLink',
                  displayName: Translation('report.recruitment.details.prudna_report_link'),
                  renderData: (row: any) => row.reportLink || '-',
                },
              ]),
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
        onChangeTab={onChangeTab}
      />
    </>
  );
};

export default PRUDNADetailReport;
