import React, { FC } from 'react';
import NotificationListingPage from './List/NotificationListingPage';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import PruRoute from '../../../../common/components/PruRoute';
import notificationDetailForm from './../Create/notificationDetailForm';
import { NOTIFICATION_BASE_PATH, NOTIFICATION_MANUAL_PATH } from '../../constants';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../common/constants';
import { ModulePermissionProps } from '../../../../common/module/types';

const NotificationPage: FC<RouteComponentProps & ModulePermissionProps> = ({
  enableRead,
  enableCreate,
  enableUpdate
}) => {

  const MANUAL_PATH = NOTIFICATION_BASE_PATH + NOTIFICATION_MANUAL_PATH;

  return (
    <Switch>
      <Redirect exact from={`${MANUAL_PATH}`} to={`${MANUAL_PATH}/list`} />
      <PruRoute
        // exact
        path={`${MANUAL_PATH}/list`}
        render={(props) => (
          <NotificationListingPage
            {...props}
            enableRead={enableRead}
            enableCreate={enableCreate}
            enableUpdate={enableUpdate}
          />
        )}
      />
      <PruRoute
        // exact
        path={`${MANUAL_PATH}/edit/:id`}
        component={notificationDetailForm}
      />
      <PruRoute
        // exact
        path={`${MANUAL_PATH}/view/:id`}
        component={notificationDetailForm}
      />
      <PruRoute
        // exact
        path={`${MANUAL_PATH}/create/:from`}
        component={notificationDetailForm}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  )
};

export default NotificationPage;