export type ReportItem = {
    _id?: string,
    fullName: string,
    phone: string,
    agentCode: string,
    agentRole: string,
    agentUnitCode: string,
    agentBranchCode: string,
    agentOfficeCode: string,
    materialName: string,
    createdAt: Date,
    callStatus: boolean,
    calledDate: Date,
    identifyNumber: string,
  };
  
  export type ReportListParams = {
    requestedDateFrom: Date | null;
    requestedDateTo: Date | null;
    agentType: AgentTypeMode,
    agentValue: string,
    customerName: '',
    page: number;
    limit: number;
  };

  export enum AgentTypeMode {
    AGENT_CODE = "agentCode",
    OFFICE_CODE = "agentOfficeCode",
    UNIT_CODE = "agentUnitCode",
    BRANCH_CODE = "agentBranchCode",
    ROLE = "agentRole",
  };
  