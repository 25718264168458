import React, { FC } from "react";
import { Switch, Redirect, RouteComponentProps } from "react-router-dom";
import ParamsProvider from "src/app/common/components/ParamsProvider";
import PruRoute from "src/app/common/components/PruRoute";
import { NOT_FOUND_ERROR_PAGE_PATH } from "src/app/common/constants";
import { MDRT_HUB_BASE_PATH, MDRT_STORY_BASE_PATH } from "../../constants";
import MdrtStoryListingPage, { filterKeys } from "./List/MdrtStoryListingPage";
import MdrtStoryDetailPage from "./Create/MdrtStoryDetailPage";

const MdrtStoryRoutes: FC<RouteComponentProps> = () => {
  return (
    <Switch>
      <Redirect
        exact
        from={`${MDRT_HUB_BASE_PATH}${MDRT_STORY_BASE_PATH}`}
        to={`${MDRT_HUB_BASE_PATH}${MDRT_STORY_BASE_PATH}/list`}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${MDRT_STORY_BASE_PATH}/list`}
        render={(props) => (
          <ParamsProvider {...props} acceptKeys={filterKeys}>
            <MdrtStoryListingPage />
          </ParamsProvider>
        )}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${MDRT_STORY_BASE_PATH}/view/:id`}
        component={MdrtStoryDetailPage}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${MDRT_STORY_BASE_PATH}/edit/:id`}
        component={MdrtStoryDetailPage}
      />
      <PruRoute
        exact
        path={`${MDRT_HUB_BASE_PATH}${MDRT_STORY_BASE_PATH}/create`}
        component={MdrtStoryDetailPage}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default MdrtStoryRoutes;
