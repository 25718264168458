import React, { FC } from "react";
import { RouteComponentProps, Switch, Redirect } from "react-router-dom";
import { EVENT_BASE_PATH, GREETING_CARD_PATH, EBIRTHDAY_CARD_TEMPLATE_LIST_PATH } from "../../../constants";
import PruRoute from "../../../../../common/components/PruRoute";
import TemplateListPage from "./List";
import { ModulePermissionProps } from "../../../../../common/module/types";

const templatePath = `${EVENT_BASE_PATH}${GREETING_CARD_PATH}${EBIRTHDAY_CARD_TEMPLATE_LIST_PATH}`;

const TemplateGreetingPage: FC<RouteComponentProps & ModulePermissionProps> = () => {
  return (
    <Switch>
      <Redirect exact from={templatePath}
        to={`${templatePath}/list`} />
      <PruRoute
        exact
        path={`${templatePath}/list`}
        render={(props) => (
          <TemplateListPage
            {...props}
          />
        )}
      />
    </Switch>
  )
};

export default TemplateGreetingPage;