import React, { FC, useState } from 'react';
import { useDataProvider } from 'src/app/common/utils';
import {
  fetchCustomerLeadManagementReport,
  fetchReportDownload,
} from '../../../network/customerLeadManagementReportCrud';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { getDayEnd, getDisplayDate, getFirstDayOfMonth, getDayStart } from '../../../../../common/utils/time-utils';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { PaginateListMore } from 'src/app/common/types/common-types';
import { ReportListParams, ReportItem, AgentTypeMode } from '../../../types/customerLeadManagementTypes';

type CustomerLeadManagementReportProps = {} & ParamsProps;

const initialState: ReportListParams = {
  requestedDateFrom: getFirstDayOfMonth(new Date()),
  requestedDateTo: getDayEnd(new Date()),
  agentType: AgentTypeMode.AGENT_CODE,
  agentValue: '',
  customerName: '',
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): ReportListParams => {
  return initialParams
    ? {
        requestedDateFrom: initialParams.requestedDateFrom
          ? getDayStart(new Date(initialParams.requestedDateFrom))
          : getFirstDayOfMonth(new Date()),
        requestedDateTo: initialParams.requestedDateTo
          ? getDayEnd(new Date(initialParams.requestedDateTo))
          : getDayEnd(new Date()),
        agentType: AgentTypeMode.AGENT_CODE,
        agentValue: '',
        customerName: '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const CustomerLeadManagementReport: FC<CustomerLeadManagementReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilterValue = paramsInitiator(initialParams);

  const [filterState, setFilterState] = useState<ReportListParams>(defaultFilterValue);
  const [reportList, setReportList] = useState<PaginateListMore<ReportItem>>();
  const { isLoading, refreshData } = useDataProvider<PaginateListMore<ReportItem>>(
    async () => {
      try {
        return await fetchCustomerLeadManagementReport(filterState, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvtDownload = () => {
    try {
      fetchReportDownload(filterState, dispatch);
    } catch (err) {}
  };

  const filterOptions = {
    agentTypes: [
      { displayName: Translation('report.common.agentInfoTypes.agentCode'), value: AgentTypeMode.AGENT_CODE },
      { displayName: Translation('report.common.agentInfoTypes.officeCode'), value: AgentTypeMode.OFFICE_CODE },
      { displayName: Translation('report.common.agentInfoTypes.unitCode'), value: AgentTypeMode.UNIT_CODE },
      { displayName: Translation('report.common.agentInfoTypes.branchCode'), value: AgentTypeMode.BRANCH_CODE },
      { displayName: Translation('report.common.agentInfoTypes.role'), value: AgentTypeMode.ROLE },
    ],
    agentTypeMapping: {
      [AgentTypeMode.AGENT_CODE]: Translation('report.common.agentInfoTypes.agentCode'),
      [AgentTypeMode.OFFICE_CODE]: Translation('report.common.agentInfoTypes.officeCode'),
      [AgentTypeMode.UNIT_CODE]: Translation('report.common.agentInfoTypes.unitCode'),
      [AgentTypeMode.BRANCH_CODE]: Translation('report.common.agentInfoTypes.branchCode'),
      [AgentTypeMode.ROLE]: Translation('report.common.agentInfoTypes.role'),
    },
  };

  return (
    <>
      <PruFilter
        title={Translation('report.sale.customerLeadManagement.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'customerName',
            initialValue: filterState.customerName,
            displayName: Translation('report.sale.customerLeadManagement.customerName'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'agentType',
            initialValue: filterState.agentType,
            displayName: Translation('report.common.agentInfoTypes'),
            choices: filterOptions.agentTypes,
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentValue',
            initialValue: filterState.agentValue,
            displayName: filterOptions.agentTypeMapping[filterState.agentType],
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'requestedDateFrom',
            fieldTo: 'requestedDateTo',
            initialDateFrom: filterState.requestedDateFrom,
            initialDateTo: filterState.requestedDateTo,
            defaultDateFrom: defaultFilterValue.requestedDateFrom,
            defaultDateTo: defaultFilterValue.requestedDateTo,
            displayName: Translation('report.common.requestDate'),
            required: true,
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            agentType: data.agentType,
            agentValue: data.agentValue,
            customerName: data.customerName,
            requestedDateFrom: data.requestedDateFrom,
            requestedDateTo: data.requestedDateTo,
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.sale.customerLeadManagement.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvtDownload(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: (row) => row._id || '-',
          },
          {
            keyIndex: 'customerName',
            displayName: 'Customer Name',
            renderData: (row) => row.fullName || '-',
          },
          {
            keyIndex: 'phone',
            align: 'center',
            displayName: 'Phone',
            renderData: (row) => row.phone || '-',
          },
          {
            keyIndex: 'agentCode',
            align: 'center',
            displayName: 'Share BY AG Code',
            renderData: (row) => row.agentCode || '-',
          },
          {
            keyIndex: 'agentRole',
            displayName: 'Role',
            renderData: (row) => row.agentRole || '-',
          },
          {
            keyIndex: 'unitCode',
            align: 'center',
            displayName: 'Unit Code',
            renderData: (row) => row.agentUnitCode || '-',
          },
          {
            keyIndex: 'branchCode',
            align: 'center',
            displayName: 'Branch Code',
            renderData: (row) => row.agentBranchCode || '-',
          },
          {
            keyIndex: 'officeCode',
            align: 'center',
            displayName: 'Office Code',
            renderData: (row) => row.agentOfficeCode || '-',
          },
          {
            keyIndex: 'materialName',
            align: 'center',
            displayName: 'Click from Landing page',
            renderData: (row) => row.materialName || '-',
          },
          {
            keyIndex: 'createdAt',
            align: 'center',
            displayName: 'Registration',
            renderData: (row) => getDisplayDate(row.createdAt, 'DD-MM-YYYY'),
          },
          {
            keyIndex: 'callStatus',
            align: 'center',
            displayName: 'Call Action',
            renderData: (row) => (row.callStatus ? 'YES' : 'NO'),
          },
          {
            keyIndex: 'calledDate',
            align: 'center',
            displayName: 'Date Of Call',
            renderData: (row) => (row.calledDate ? getDisplayDate(row.calledDate, 'DD-MM-YYYY') : '-'),
          },
          {
            keyIndex: 'identifyNumber',
            align: 'center',
            displayName: 'Identify Num',
            renderData: (row) => row.identifyNumber || '-',
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default CustomerLeadManagementReport;
