import React, { useState, useEffect, useMemo, useRef } from 'react';
import FormList from './components/Form';
import { makeStyles } from 'tss-react/mui';
import { Backdrop, CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useLang } from 'src/app/i18n';
import { regionLocale } from 'src/app/i18n';
import { forEach } from 'lodash';
import { getEnabledCustomWeight } from 'src/app/common/utils';
import { ETypeOfView } from 'src/app/common/types/view-types';
import { cloneDeep } from 'lodash';
import {
  LibraryItem,
  ResourceLibraryFormState,
} from 'src/app/modules/sales-kit/components/Library/types/library-types';
import { FormMode } from 'src/app/common/types';
import { fetchLibraryItem } from 'src/app/modules/sales-kit/components/Library/network/libraryCrud';
import { YesAndNo } from 'src/app/modules/sales-kit/components/Library/constants';
import { pathExtractor } from 'src/app/modules/sales-kit/components/Library/utils/common-utils';
import { getBgImageById } from '../../../network/bdm-event.api';
import { getTime } from '../../../util/bdm-event.util';

interface ResourceCreateProps
  extends RouteComponentProps<any, any, { selectedRows: any; type: string; allWeights: {} }> {
  blockName: string;
  blockType: string;
  categoryType: string;
  columnType: string;
  libraryBasePath: string;
  moduleType: string;
}

const useStyles = makeStyles()((theme) => ({
  formContainer: {
    position: 'relative',
    minHeight: '100%',
    backgroundColor: theme.palette.common.white,
  },
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  backdrop: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
    backgroundColor: `${theme.palette.common.white} !important`,
    opacity: '0.5 !important',
  },
  backdropIcon: {},
}));

const DEFAULT_CONFIG = {
  coverpage: null,
  name: '',
  introduction: '',
  tag: [],
  file: null,
  link: '',
  canShared: 'N',
  sharedTitle: '',
  sharedCoverpage: null,
  sharedIntroduction: '',
  allowedForEProfile: 'N',
  prodcutType: '',
  agentType: 'all',
};

const initialState: ResourceLibraryFormState = {
  rType: 'file',
  category: '',
  lastUpdatedBy: '', // todo: need to update
  status: 'Draft',
  materialSet: [],
  agent: '',
  agentType: 'all',
  moduleColumn: [
    {
      column: '',
      weight: null,
    },
  ],
  typeOfViews: [
    {
      type: ETypeOfView.AllTraditionalAgents,
    },
  ],
  eventInvitation: 'N',
};

const MaterialLibraryDetailPage = ({
  history,
  location,
  blockName,
  blockType,
  categoryType,
  columnType,
  libraryBasePath,
  moduleType,
}: ResourceCreateProps) => {
  const { classes } = useStyles();
  const locale = useLang();
  const refEnabledCustomWeight = useRef<boolean>(getEnabledCustomWeight());
  const [loading, setLoading] = useState<boolean>(false);

  const regionLocales: any[] = regionLocale;
  const [initData, setInitData] = useState<ResourceLibraryFormState>(
    regionLocales.reduce((initData, current) => {
      initData[current] = { ...DEFAULT_CONFIG };
      return initData;
    }, initialState),
  );
  const [isInitialDataSeted, setIsInitialDataSeted] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { formMode, code } = pathExtractor(location.pathname, libraryBasePath);
  const { selectedRows, type } = location.state || {};

  const disabled = useMemo(() => {
    return formMode === FormMode.VIEW;
  }, [formMode]);

  const loadingFun = () => {
    setLoading(true);
  };

  const queryData = async (resourceId: string) => {
    setLoading(true);
    const libraryDetail = await fetchLibraryItem(resourceId);
    if (libraryDetail) {
      const param = await convertEditParams(libraryDetail, locale);

      let bgImageObj: any = {};
      if (libraryDetail.backgroundImage) {
        bgImageObj = await getBgImageById(libraryDetail.backgroundImage).catch((err) => {
          console.log('fetch BG image error ===>', err);
        });
      }

      //@ts-ignore
      if (param) {
        setInitData({ ...param, backgroundImage: bgImageObj });
      }
      setLoading(false);
      setIsInitialDataSeted(true);
    }
  };

  const convertEditParams = async (rowData: LibraryItem, lang: string) => {
    let result: ResourceLibraryFormState = { ...initialState };
    const effectiveRange: [string, string] = [
      rowData.effectiveDate ? new Date(rowData.effectiveDate).toISOString() : '',
      rowData.expiredDate ? new Date(rowData.expiredDate).toISOString() : '',
    ];
    if (refEnabledCustomWeight.current === false && rowData.publishedAt && !effectiveRange[0]) {
      // PCAAEB-39423: For those material w/o effective start time, use its last publish time
      effectiveRange[0] = new Date(rowData.publishedAt).toISOString();
    }

    const eventDate: [string, string] = [
      rowData.eventStartDate ? new Date(rowData.eventStartDate).toISOString() : '',
      rowData.eventEndDate ? new Date(rowData.eventEndDate).toISOString() : '',
    ];

    const columnRef: any[] = (rowData.columnReferences as any[]).map((item, index) => {
      return {
        id: item?.column?.id,
        value: item?.column?.id,
        column: item?.column?.id,
        weight: item?.weight as number,
      };
    });

    let childItemsArray: any[] = [];
    //Reorder the childItems following the data.childItemList
    if (rowData?.childItems && rowData?.childItems?.length > 0) {
      if (rowData?.materialOrder && rowData?.materialOrder?.length > 0) {
        forEach(rowData.materialOrder, (item) => {
          const childItemDetail = (rowData.childItems as LibraryItem[]).find(
            (childItem: LibraryItem) => childItem.id === item || childItem?.uuid === item,
          );
          if (childItemDetail) {
            childItemsArray.push(childItemDetail);
          }
        });
      } else {
        childItemsArray = rowData.childItems;
      }
    }
    result = {
      ...initialState,
      effectiveRange: effectiveRange,
      rType: rowData.type ? rowData.type : '',
      category: rowData.category ? rowData.category.id : '',
      lastUpdatedBy: rowData.lastUpdatedBy ? rowData.lastUpdatedBy : '', // todo: need to update
      status: rowData.status || '',
      materialSet: childItemsArray ? childItemsArray : [],
      agent: '',
      moduleColumn: columnRef,
      designations: rowData.designations,
      zones: rowData.zones,
      parentItems: rowData.parentItems ? rowData.parentItems : [],
      agentType: rowData.agentType || '',
      targetAgentType: rowData.targetAgentType || '',
      targetAgentList: rowData.targetAgentList || [],
      agents: rowData.agents || [],
      includedAgents: rowData.includedAgents || [],
      excludedAgents: rowData.excludedAgents || [],
      eventDate,
      typeOfViews: rowData.typeOfViews || [],
      notificationMessage: rowData?.notificationMessage || '',
      eventInvitation: rowData.eventInvitation ? YesAndNo.yes : YesAndNo.no,
      checkInUrl: rowData.checkInUrl || '',
      eventInvitationTemplate: rowData?.eventInvitationTemplate || '',
      eventInvitationRegisterPageType: rowData?.eventInvitationRegisterPageType || '',
      backgroundImage: rowData?.backgroundImage || '',
      eventForOffices: rowData?.eventForOffices || [],
      agendaList: rowData.agendaList
        ? rowData.agendaList.map((element: any) => {
            element.time = getTime(element.time);
            return element;
          })
        : [],
      textColor: rowData?.textColor || '#FFFFFF',
    };

    //@ts-ignore
    result[rowData.locale] = {
      ...DEFAULT_CONFIG,
      ...rowData.i18nData,
      name: rowData.name,
      canShared: rowData.canShare ? 'Y' : 'N',
      allowedForEProfile: rowData.canShare && rowData.allowedForEProfile ? 'Y' : 'N',
      coverpage: rowData.coverpage || undefined,
      introduction: rowData.introduction || '',
      tag: rowData.tag || [],
      eventAddress: rowData?.eventAddress || '',
      eventLocation: rowData?.eventLocation || '',
    };

    if (rowData?.localizations && rowData?.localizations?.length >= 1) {
      for (var index = 0; index < rowData?.localizations?.length; index++) {
        //@ts-ignore
        const itemResult = await fetchLibraryItem(rowData.localizations[index].id, dispatch);
        //@ts-ignore
        result[itemResult.locale] = {
          ...DEFAULT_CONFIG,
          ...itemResult.i18nData,
          name: itemResult.name,
          canShared: itemResult.canShare ? 'Y' : 'N',
          allowedForEProfile: itemResult.canShare && itemResult.allowedForEProfile ? 'Y' : 'N',
          coverpage: itemResult.coverpage || undefined,
          introduction: itemResult.introduction || '',
          tag: itemResult.tag || [],
          eventAddress: itemResult?.eventAddress || '',
          eventLocation: rowData?.eventLocation || '',
        };
        if (index === rowData.localizations?.length - 1) {
          return result;
        }
      }
    } else {
      return result;
    }
  };

  useEffect(() => {
    if (code) {
      queryData(code);
    } else if (type) {
      setInitData({ ...initData, rType: 'materialSet', materialSet: [...selectedRows] });
      setIsInitialDataSeted(true);
    } else {
      setIsInitialDataSeted(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.formContainer}>
      {isInitialDataSeted ? (
        <FormList
          initialValues={cloneDeep(initData)}
          disabled={disabled}
          blockName={blockName}
          categoryType={categoryType}
          blockType={blockType}
          columnType={columnType}
          isEdit={formMode === FormMode.EDIT}
          history={history}
          loadingFun={loadingFun}
          moduleType={moduleType}
          currentId={code || ''}
        />
      ) : null}

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="secondary" className={classes.backdropIcon} />
      </Backdrop>
    </div>
  );
};

export default MaterialLibraryDetailPage;
