import { makeStyles } from 'tss-react/mui';

const LEFT_SIDE_WIDTH = 160;
const FIELD_CONTAINER_SPACING = 25;
const FIELD_WIDTH = 350;
const DOUBLE_FIELD_WIDTH = 300;
const DOUBLE_FIELD_SPACING = 50;
export const DOUBLE_FIELD_CONTAINER_WIDTH = DOUBLE_FIELD_WIDTH * 2 + DOUBLE_FIELD_SPACING;
const RADIO_GROUP_LABEL_WIDTH = 200;

export const useCommonFormStyles = makeStyles()(() => ({
  container: {
    width: '100%',
    height: '100%',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  sectionContainer: {
    display: 'flex',
  },
  titleContainer: {
    width: LEFT_SIDE_WIDTH,
    fontSize: 18,
    fontWeight: 600,
  },
  contentContainer: {
    flexGrow: 1,
  },
  fieldContainer: {
    marginBottom: FIELD_CONTAINER_SPACING,
  },
  inputLabelField: {
    width: FIELD_WIDTH,
    marginTop: 14,
  },
  doubleFieldContainer: {
    width: DOUBLE_FIELD_CONTAINER_WIDTH,
    display: 'flex',
    columnGap: DOUBLE_FIELD_SPACING,
    marginBottom: FIELD_CONTAINER_SPACING,
  },
  doubleField: {
    width: DOUBLE_FIELD_WIDTH,
    marginTop: 14,
  },
  radioGroupContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  radioGroup: {
    flexDirection: 'row',
  },
  radioGroupLabel: {
    width: RADIO_GROUP_LABEL_WIDTH,
    minWidth: RADIO_GROUP_LABEL_WIDTH,
    color: '#3F4254',
  },
  radioGroupMargin: {
    marginLeft: RADIO_GROUP_LABEL_WIDTH,
  },
  formControlLabel: {
    marginBottom: 0,
  },
  inputLabel: {
    color: '#3F4254',
    fontSize: 13,
    transform: 'translate(0, -10px)',
  },
  inputLabelWithTooltip: {
    color: '#3F4254',
    fontSize: 13,
    transform: 'translate(0, -15px)',
    display: 'flex',
    alignItems: 'center',
  },
  datePicker: {
    marginTop: 16,
  },
  mandatory: {
    color: 'red',
  },
  placeholder: {
    color: '#858585',
  },
  remark: {
    color: '#979797',
    fontSize: 12,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: 20,
    paddingBottom: 16,
  },
  infoIcon: {
    padding: 5,
    color: '#CCCCCC',
  },
}));
