import React, { FC, useState } from 'react';
import { PaginateListMore } from '../../../../../../common/types/common-types';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../../common/components/ParamsProvider';
import { getDayStart, getDayEnd, getFirstDayOfMonth } from '../../../../../../common/utils/time-utils';
import PruTable from '../../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../../common/components/PruTable/PruFilter';
import {
  AgentInfoTypeMode,
  ReportListParams,
  ReportItem,
} from 'src/app/modules/Report/types/conversion-rate-report-types';
import {
  fetchConversionRateReport,
  fetchReportDownload,
} from 'src/app/modules/Report/network/conversionRateReportCrud';

type ConversionRateListingPageProps = {} & ParamsProps;

const initialState: ReportListParams = {
  registerDateFrom: getFirstDayOfMonth(new Date()),
  registerDateTo: getDayEnd(new Date()),
  agentInfoType: AgentInfoTypeMode.AGENT_CODE,
  agentInfoValue: '',
  landingPageName: '',
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): ReportListParams => {
  return initialParams
    ? {
        registerDateFrom: initialParams.registerDateFrom
          ? getDayStart(new Date(initialParams.registerDateFrom))
          : getFirstDayOfMonth(new Date()),
        registerDateTo: initialParams.registerDateTo
          ? getDayEnd(new Date(initialParams.registerDateTo))
          : getDayEnd(new Date()),
        agentInfoType: AgentInfoTypeMode.AGENT_CODE,
        agentInfoValue: '',
        landingPageName: '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const ConversionRateListingPage: FC<ConversionRateListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const filterOptions: any = {
    agentInfoTypes: [
      { displayName: Translation('report.common.agentInfoTypes.agentCode'), value: AgentInfoTypeMode.AGENT_CODE },
      { displayName: Translation('report.common.agentInfoTypes.officeCode'), value: AgentInfoTypeMode.OFFICE_CODE },
      { displayName: Translation('report.common.agentInfoTypes.unitCode'), value: AgentInfoTypeMode.UNIT_CODE },
      { displayName: Translation('report.common.agentInfoTypes.branchCode'), value: AgentInfoTypeMode.BRANCH_CODE },
      { displayName: Translation('report.common.agentInfoTypes.role'), value: AgentInfoTypeMode.ROLE },
    ],
    agentInfoTypeMapping: {
      [AgentInfoTypeMode.AGENT_CODE]: Translation('report.common.agentInfoTypes.agentCode'),
      [AgentInfoTypeMode.OFFICE_CODE]: Translation('report.common.agentInfoTypes.officeCode'),
      [AgentInfoTypeMode.UNIT_CODE]: Translation('report.common.agentInfoTypes.unitCode'),
      [AgentInfoTypeMode.BRANCH_CODE]: Translation('report.common.agentInfoTypes.branchCode'),
      [AgentInfoTypeMode.ROLE]: Translation('report.common.agentInfoTypes.role'),
    },
  };

  const [filterState, setFilterState] = useState<ReportListParams>(paramsInitiator(initialParams));
  const [reportList, setReportList] = useState<PaginateListMore<ReportItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateListMore<ReportItem>>(
    async () => {
      try {
        return await fetchConversionRateReport(filterState, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvtDownload = () => {
    try {
      fetchReportDownload(filterState, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('report.sale.conversionRate.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'landingPageName',
            initialValue: filterState.landingPageName,
            displayName: Translation('report.sale.conversionRate.landingPageName'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'agentInfoType',
            initialValue: filterState.agentInfoType,
            displayName: Translation('report.common.agentInfoTypes'),
            choices: filterOptions.agentInfoTypes,
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentInfoValue',
            initialValue: filterState.agentInfoValue,
            displayName: filterOptions.agentInfoTypeMapping[filterState.agentInfoType],
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'registerDateFrom',
            fieldTo: 'registerDateTo',
            initialDateFrom: filterState.registerDateFrom,
            initialDateTo: filterState.registerDateTo,
            displayName: Translation('report.sale.conversionRate.registerDate'),
            required: true,
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            registerDateFrom: data.registerDateFrom,
            registerDateTo: data.registerDateTo,
            agentInfoType: data.agentInfoType,
            agentInfoValue: data.agentInfoValue,
            landingPageName: data.landingPageName,
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.sale.conversionRate.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvtDownload(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: () => '',
          },
          {
            keyIndex: 'landingPageName',
            displayName: Translation('report.sale.conversionRate.landingPageName'),
            renderData: (row) => row.materialName,
          },
          {
            keyIndex: 'AGCode',
            displayName: Translation('report.sale.conversionRate.AGCode'),
            renderData: (row) => row.agentCode,
          },
          {
            keyIndex: 'role',
            displayName: Translation('report.sale.conversionRate.role'),
            renderData: (row) => row.agentRole,
          },
          {
            keyIndex: 'unitCode',
            displayName: Translation('report.sale.conversionRate.unitCode'),
            renderData: (row) => row.agentUnitCode,
          },
          {
            keyIndex: 'branchCode',
            displayName: Translation('report.sale.conversionRate.branchCode'),
            renderData: (row) => row.agentBranchCode,
          },
          {
            keyIndex: 'officeCode',
            displayName: Translation('report.sale.conversionRate.officeCode'),
            renderData: (row) => row.agentOfficeCode,
          },
          {
            keyIndex: 'noOfShare',
            displayName: Translation('report.sale.conversionRate.noOfShare'),
            renderData: (row) => String(row.totalShare),
          },
          {
            keyIndex: 'noOfClickThrough',
            displayName: Translation('report.sale.conversionRate.noOfClickThrough'),
            renderData: (row) => String(row.totalClickThrough),
          },
          {
            keyIndex: 'noOfRegister',
            displayName: Translation('report.sale.conversionRate.noOfRegister'),
            renderData: (row) => String(row.totalRegister),
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.totalRecords}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default ConversionRateListingPage;
