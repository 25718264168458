import { Add, Refresh } from '@mui/icons-material';
import { Button, IconButton, Tooltip } from '@mui/material';
import React from 'react';

const tableHeader = (Translation: any, openPopUp: any, refreshData: any, enableCreate: any) => [
  [
    { type: 'header', label: Translation('component.formTitle.bdm-templete-category'), sm: true },
    {
      type: 'custom',
      sm: false,
      render: () => (
        <Tooltip title="Refresh">
          <IconButton style={{ marginRight: 15 }} onClick={refreshData}>
            <Refresh />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      type: 'custom',
      sm: false,
      render: () =>
        enableCreate && (
          <Button variant="contained" color="secondary" onClick={openPopUp}>
            <Add />
            {Translation('app.button.add')}
          </Button>
        ),
    },
  ],
];

export default tableHeader;
