import moment from 'moment';
import { getConfigurations } from './common-utils';

export const getHeaderDate = () => {
  return moment(new Date()).format('MMM DD');
};

export const getDisplayDate = (date: Date, format?: string) => {
  return moment(date).format(format ? format : 'DD/MM/YYYY');
};

export const onDisplayDateFormat = (date: string, format = 'MMMM Do YYYY, h:mm:ss a') => moment(date).format(format);

export const getDefaultDisplayDate = (date?: Date, formatKey = 'date') => {
  if (date) {
    const dateFormat = getConfigurations()?.region?.dateFormat;
    let defaultFormat = 'DD/MM/YYYY';
    if (dateFormat) {
      defaultFormat = dateFormat[formatKey] ? dateFormat[formatKey] : dateFormat['date'];
    }
    return moment(date).format(defaultFormat);
  } else {
    return '-';
  }
};

export const getDayStart = (date: Date) => {
  // set to 12:00 am
  return moment(date).startOf('day').toDate();
};

export const getDayEnd = (date: Date) => {
  return moment(date).endOf('day').toDate();
};

export const getDayStartUTC = (date: Date) => {
  return moment(date).utc().startOf('day').toDate();
};

export const getDayEndUTC = (date: Date) => {
  return moment(date).utc().endOf('day').toDate();
};

export const getFirstDayOfMonth = (date: Date) => {
  return moment(date).startOf('month').toDate();
};

export const getLastDayOfMonth = (date: Date) => {
  return moment(date).endOf('month').toDate();
};

export const ignoreTimezone = (date: Date) => {
  return moment(date).format('YYYY-MM-DDThh:mm:ss.sss');
};

export const getIsoString = (date: Date) => {
  return moment(date).toISOString();
};

export const getDayStartForStrapi = (date: Date) => {
  //Strapi count day start to be 00:00:00
  const a = moment(date).startOf('day').hour(0).format('YYYY-MM-DDT00:00:00.000');
  return a;
};

export const getDayStartForStrapiUTC0 = (date: Date) => {
  //Strapi count day start to be 00:00:00
  const a = moment(date).startOf('day').hour(0).subtract(7, 'h').format('YYYY-MM-DDTHH:mm:ss.000');
  return a;
};

export const getDayStartOfMonthForStrapiUTC0 = (date: Date) => {
  //Strapi count day start to be 00:00:00
  const a = moment(date).startOf('month').hour(0).subtract(7, 'h').format('YYYY-MM-DDTHH:mm:ss.000');
  return a;
};

export const getDayEndForStrapiUTC0 = (date: Date) => {
  //Strapi count day start to be 00:00:00
  const a = moment(date).endOf('day').subtract(7, 'h').format('YYYY-MM-DDTHH:mm:ss.000');
  return a;
};

export const getDayEndOfMonthForStrapiUTC0 = (date: Date) => {
  //Strapi count day start to be 00:00:00
  const a = moment(date).endOf('month').subtract(7, 'h').format('YYYY-MM-DDTHH:mm:ss.000');
  return a;
};

export const getDayEndForStrapi = (date: Date) => {
  return moment(date).endOf('day').format('YYYY-MM-DDTHH:mm:ss.sss');
};

export const getLocalDateFormat = (date: Date | string, format?: string) => {
  return moment(date)
    .local()
    .format(format || 'YYYY-MM-DD HH:mm');
};

export const getDisplayTimeForTimestamp = (timestamp: number): string => {
  let seconds = timestamp / 1000;
  // 2- Extract hours:
  const hours = Math.round(seconds / 3600); // 3,600 seconds in 1 hour
  seconds = seconds % 3600; // seconds remaining after extracting hours
  // 3- Extract minutes:
  const minutes = Math.round(seconds / 60); // 60 seconds in 1 minute
  // 4- Keep only seconds not extracted to minutes:
  seconds = Math.round(seconds % 60);

  const padding = (time: number) => {
    return time < 10 ? '0' : '';
  };

  return `${padding(hours)}${hours}:${padding(minutes)}${minutes}:${padding(seconds)}${seconds}`;
};
export const syncDateToTime = (date: Date, time: Date) => {
  const dateValue = moment(date);
  const timeValue = moment(time);
  return dateValue
    .set({
      hour: timeValue.get('hour'),
      minute: timeValue.get('minute'),
    })
    .toDate();
};
