import React, { FC, useEffect, useState } from 'react';
import { PaginateList } from '../../../../../../common/types/common-types';
import { ReportActiveUserItem } from '../../../../types/report-types';
import { useDataProvider } from 'src/app/common/utils';
import {
  fetchActiveUserReportList,
  ReportActiveUserParams,
  fetchActiveUserReporttDownload,
} from '../../../../network/reportCrud';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../../common/components/ParamsProvider';
import { getDayStart } from '../../../../../../common/utils/time-utils';
import PruTable from '../../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../../common/components/PruTable/PruFilter';
import moment from 'moment';
import { orderBy } from 'lodash';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type AgentsListingPageProps = {} & ParamsProps;
enum SalesChannelEnum {
  All = 'all',
  Traditional = 'agency',
  Banca = 'banca',
  Pruventure = 'gallerie',
  Other = 'other',
}

const initialState: ReportActiveUserParams = {
  agentCode: '',
  branchCode: '',
  officeCode: '',
  role: '',
  segment: '',
  unitCode: '',
  requestedDateFrom: new Date(),
  requestedDateTo: new Date(),
  salesChannel: [SalesChannelEnum.All],
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): ReportActiveUserParams => {
  return initialParams
    ? {
        agentCode: initialParams.agentCode ? initialParams.agentCode : '',
        branchCode: initialParams.branchCode ? initialParams.branchCode : '',
        officeCode: initialParams.officeCode ? initialParams.officeCode : '',
        role: initialParams.role ? initialParams.role : '',
        segment: initialParams.segment ? initialParams.segment : '',
        unitCode: initialParams.unitCode ? initialParams.unitCode : '',
        requestedDateFrom: initialParams.requestedDateFrom
          ? getDayStart(new Date(initialParams.requestedDateFrom))
          : new Date(),
        requestedDateTo: initialParams.requestedDateTo
          ? getDayStart(new Date(initialParams.requestedDateTo))
          : new Date(),
        salesChannel: [SalesChannelEnum.All],
        page: 1,
        limit: 5,
      }
    : initialState;
};

const salesChannelOptions = [
  { displayName: 'All', value: SalesChannelEnum.All },
  { displayName: 'Traditional Agent', value: SalesChannelEnum.Traditional },
  { displayName: 'Pruventure', value: SalesChannelEnum.Pruventure },
  { displayName: 'Banca', value: SalesChannelEnum.Banca },
  { displayName: 'Other', value: SalesChannelEnum.Other },
];

const formatSalesChannel = (channel: string) => {
  switch (channel) {
    case SalesChannelEnum.Traditional:
      return 'Agency';
    case SalesChannelEnum.Banca:
      return 'Banca';
    case SalesChannelEnum.Pruventure:
      return 'Pruventure';
    default:
      return 'Other';
  }
};

const ActiveUserListingPage: FC<AgentsListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [statusModal, setStatusModal] = useState(false);
  const [messageError, setMessageError] = useState('');
  const handleModal = (status: boolean) => {
    setStatusModal(status);
  };

  const [filterState, setFilterState] = useState<ReportActiveUserParams>(paramsInitiator(initialParams));

  const [reportList, setReportList] = useState<PaginateList<ReportActiveUserItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateList<ReportActiveUserItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }

        const now = new Date();
        if (
          filterState.requestedDateFrom &&
          filterState.requestedDateTo &&
          filterState.requestedDateFrom.getTime() > filterState.requestedDateTo.getTime()
        ) {
          setMessageError('Begin month cannot be greater than End month.');
          handleModal(true);
          return;
        } else if (
          filterState.requestedDateFrom &&
          filterState.requestedDateTo &&
          (filterState.requestedDateFrom.getTime() > now.getTime() ||
            filterState.requestedDateTo.getTime() > now.getTime())
        ) {
          setMessageError('Begin month and end month cannot be greater than the current month.');
          handleModal(true);
          return;
        } else {
          return await fetchActiveUserReportList(filterState, sortKey, dispatch);
        }
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvtDownload = () => {
    try {
      fetchActiveUserReporttDownload(filterState, dispatch);
    } catch (err) {}
  };

  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    setSortKey(sortingKeyArray);
  };

  useEffect(() => {
    let keys: string[] = [];
    let values: ('asc' | 'desc')[] = [];
    for (let i = 0; i < sortKey.length; i++) {
      if (sortKey[i].key) {
        keys.push(sortKey[i].key);
      }
      if (sortKey[i].value === 'asc') {
        values.push('asc');
      } else if (sortKey[i].value === 'desc') {
        values.push('desc');
      }
    }

    if (keys.length > 0 && values.length > 0) {
      setReportList(reportList ? { ...reportList, items: orderBy(reportList?.items || [], keys, values) } : undefined);
    }
  }, [sortKey]);

  return (
    <>
      <PruFilter
        title={Translation('report.appPerfTracking.activeUser.filter')}
        itemDef={[
          {
            type: PruFilterItemType.DATE_MONTH,
            field: 'requestedDateFrom',
            initialValue: filterState.requestedDateFrom,
            defaultValue: initialState.requestedDateFrom,
            displayName: Translation('report.appPerfTracking.requestedDateFrom'),
          },
          {
            type: PruFilterItemType.DATE_MONTH,
            field: 'requestedDateTo',
            initialValue: filterState.requestedDateTo,
            defaultValue: initialState.requestedDateTo,
            displayName: Translation('report.appPerfTracking.requestedDateTo'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentCode',
            initialValue: filterState.agentCode,
            displayName: Translation('report.appPerfTracking.agentCode'),
          },
          {
            type: PruFilterItemType.MULTIPLE_SELECT,
            field: 'salesChannel',
            initialValue: filterState.salesChannel,
            defaultValue: initialState.salesChannel,
            allValue: SalesChannelEnum.All,
            displayName: Translation('report.appPerfTracking.salesChannel'),
            choices: salesChannelOptions,
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'unitCode',
            initialValue: filterState.unitCode,
            displayName: Translation('report.appPerfTracking.unitCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'officeCode',
            initialValue: filterState.officeCode,
            displayName: Translation('report.appPerfTracking.officeCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'branchCode',
            initialValue: filterState.branchCode,
            displayName: Translation('report.appPerfTracking.branchCode'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'role',
            initialValue: filterState.role,
            displayName: Translation('report.appPerfTracking.role'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'segment',
            initialValue: filterState.segment,
            displayName: Translation('report.appPerfTracking.segment'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            agentCode: data.agentCode,
            officeCode: data.officeCode,
            unitCode: data.unitCode,
            branchCode: data.branchCode,
            role: data.role,
            segment: data.segment,
            salesChannel: data.salesChannel,
            requestedDateFrom: data.requestedDateFrom,
            requestedDateTo: data.requestedDateTo,
          })
        }
        fetchData={refreshData}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.appPerfTracking.activeUser.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvtDownload(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            isId: true,
            hidden: true,
            keyIndex: '_id',
            displayName: '',
            renderData: () => '',
          },
          {
            keyIndex: 'agentCode',
            align: 'center',
            displayName: Translation('report.appPerfTracking.agentCode'),
            sortable: true,
            renderData: (row) => row.agentCode,
            onSort: (sort) => {
              updateSortingKey({ key: 'agentCode', value: sort['agentCode'] });
            },
          },
          {
            keyIndex: 'salesChannel',
            align: 'center',
            displayName: Translation('report.appPerfTracking.salesChannel'),
            sortable: true,
            renderData: (row) => formatSalesChannel(row.salesChannel),
            onSort: (sort) => {
              updateSortingKey({ key: 'salesChannel', value: sort['salesChannel'] });
            },
          },
          {
            keyIndex: 'role',
            align: 'center',
            sortable: true,
            displayName: Translation('report.appPerfTracking.role'),
            renderData: (row) => row.role,
            onSort: (sort) => {
              updateSortingKey({ key: 'role', value: sort['role'] });
            },
          },
          {
            keyIndex: 'segment',
            align: 'center',
            sortable: true,
            displayName: Translation('report.appPerfTracking.segment'),
            renderData: (row) => row.segment,
            onSort: (sort) => {
              updateSortingKey({ key: 'segment', value: sort['segment'] });
            },
          },
          {
            keyIndex: 'unitCode',
            align: 'center',
            sortable: true,
            displayName: Translation('report.appPerfTracking.unitCode'),
            renderData: (row) => row.unitCode,
            onSort: (sort) => {
              updateSortingKey({ key: 'unitCode', value: sort['unitCode'] });
            },
          },
          {
            keyIndex: 'branchCode',
            align: 'center',
            sortable: true,
            displayName: Translation('report.appPerfTracking.branchCode'),
            renderData: (row) => row.branchCode,
            onSort: (sort) => {
              updateSortingKey({ key: 'branchCode', value: sort['branchCode'] });
            },
          },
          {
            keyIndex: 'officeCode',
            align: 'center',
            sortable: true,
            displayName: Translation('report.appPerfTracking.officeCode'),
            renderData: (row) => row.officeCode,
            onSort: (sort) => {
              updateSortingKey({ key: 'officeCode', value: sort['officeCode'] });
            },
          },
          {
            keyIndex: 'functionName',
            align: 'center',
            sortable: true,
            displayName: Translation('report.appPerfTracking.lastUsedFunction'),
            renderData: (row) => row.functionName,
            onSort: (sort) => {
              updateSortingKey({ key: 'functionName', value: sort['functionName'] });
            },
          },
          {
            keyIndex: 'updatedAt',
            align: 'center',
            sortable: true,
            displayName: Translation('report.appPerfTracking.lastActiveTime'),
            renderData: (row) => moment(row.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
            onSort: (sort) => {
              updateSortingKey({ key: 'updatedAt', value: sort['updatedAt'] });
            },
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.total}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
      <Dialog
        open={statusModal}
        onClose={() => handleModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Message'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ color: 'red' }}>
            {messageError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleModal(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActiveUserListingPage;
