import { forEach } from 'lodash';
import { Dispatch } from 'react';
import { PaginateList } from '../../../common/types/common-types';
import { getDayStart, getDayEnd } from 'src/app/common/utils/time-utils';
import { AchievementUploadDetail, AchievementUploadItem, AgentStatus } from '../types/achievement-upload-types';
import { apiClient, apiErrorHandler } from 'src/app/common/network';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_MDRT']}/mdrt-hub`;
// const ADMIN_URL = `http://localhost:3303`;

export type AchievementUploadListParams = {
  agentCode: string;
  lastUpdatedDateFrom: Date | null;
  lastUpdatedDateTo: Date | null;
  page: number;
  limit: number;
};

export const addAchievement = async (
  data: AchievementUploadDetail,
  dispatch?: Dispatch<any>,
): Promise<AchievementUploadDetail> => {
  return apiClient
    .post<AchievementUploadDetail>(`${ADMIN_URL}/achievement-upload/add`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchAchievementItem = async (id: string, dispatch?: Dispatch<any>): Promise<AchievementUploadItem> => {
  return apiClient
    .get<AchievementUploadItem>(`${ADMIN_URL}/achievement-upload/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchAchievementList = async (
  params: AchievementUploadListParams,
  dispatch?: Dispatch<any>,
): Promise<PaginateList<AchievementUploadItem>> => {
  let queryPath = `${ADMIN_URL}/achievement-upload/list?`;

  forEach(params, (param, key) => {
    if (typeof param === 'object') {
      if (param) {
        if (key.includes('From')) {
          queryPath += `${key}=${getDayStart(param).toISOString()}&`;
        }
        if (key.includes('To')) {
          queryPath += `${key}=${getDayEnd(param).toISOString()}&`;
        }
      }
    } else {
      queryPath += param ? `${key}=${param}&` : '';
    }
  });

  return apiClient
    .get<PaginateList<AchievementUploadItem>>(queryPath)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const publishAchievement = async (id: string, dispatch?: Dispatch<any>): Promise<AchievementUploadItem> => {
  return apiClient
    .get<AchievementUploadItem>(`${ADMIN_URL}/achievement-upload/publish/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const unpublishAchievement = async (id: string, dispatch?: Dispatch<any>): Promise<AchievementUploadItem> => {
  return apiClient
    .get<AchievementUploadItem>(`${ADMIN_URL}/achievement-upload/unpublish/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteAchievement = async (id: string, dispatch?: Dispatch<any>): Promise<AchievementUploadItem> => {
  return apiClient
    .delete<AchievementUploadItem>(`${ADMIN_URL}/achievement-upload/delete/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getAgentStatus = async (agentCode: string, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get<AgentStatus>(`${ADMIN_URL}/profile/agent-status/${agentCode}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getAchievementByAgentCode = async (agentCode: string, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get<AchievementUploadItem>(`${ADMIN_URL}/achievement-upload/detail`, { params: { agentCode } })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const achievementUploadFile = async (file: any, dispatch?: Dispatch<any>): Promise<any> => {
  const formData = new FormData();
  formData.append('file', file);

  return apiClient
    .post<any>(`${ADMIN_URL}/achievement-upload/file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
