import React, { FC, useState } from 'react';
import { useDataProvider } from 'src/app/common/utils';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { getDayStartUTC, getDayEndUTC, getDayEnd, getDayStart } from '../../../../../common/utils/time-utils';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { PaginateListMore } from 'src/app/common/types/common-types';
import { useLang } from 'src/app/i18n';
import moment from 'moment';
import { CustomerManagementReportListParams } from '../../../types/customerManagementReportTypes';
import {
  fetchCustomerManagementReport,
  fetchCustomerManagementReportDownload,
} from '../../../network/customerManagementReportCrud';

type CustomerManagementReportProps = {} & ParamsProps;
const initialState: CustomerManagementReportListParams = {
  requestedDateFrom: getDayEnd(
    moment()
      .set('month', moment().month() - 3)
      .toDate(),
  ),
  requestedDateTo: getDayEnd(new Date()),
  agentOfficeCode: '',
  eventName: '',
  type: 'customer',
  page: 1,
  limit: 5,
  criteria: {},
};

const paramsInitiator = (initialParams?: Record<string, string>): CustomerManagementReportListParams => {
  return initialParams
    ? {
        requestedDateFrom: initialParams.requestedDateTo ? getDayStart(new Date()) : null,
        requestedDateTo: initialParams.requestedDateTo ? getDayEnd(new Date()) : null,
        eventName: initialParams.eventName ? initialParams.eventName : '',
        agentOfficeCode: initialParams.agentOfficeCode ? initialParams.agentOfficeCode : '',
        page: 1,
        type: 'customer',
        limit: 5,
        criteria: {},
      }
    : initialState;
};

const EventManagementCustomer: FC<CustomerManagementReportProps> = ({ initialParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();
  const defaultFilterValue = paramsInitiator(initialParams);
  const currentLanguage = useLang();

  const [criterias, setCriterias] = useState([]);

  const handleGetCriteria = (criteriasData: any) => {
    console.log(criteriasData, 'criterias');

    setCriterias(criteriasData);

    setFilterState({
      ...filterState,
      criteria: {
        ...criteriasData,
      },
    });
  };

  const [filterState, setFilterState] = useState<CustomerManagementReportListParams>(defaultFilterValue);
  const [reportList, setReportList] = useState<PaginateListMore<any>>();
  const { isLoading, refreshData } = useDataProvider<PaginateListMore<any>>(
    async () => {
      try {
        return await fetchCustomerManagementReport(filterState, dispatch);
      } catch (err) {}
    },
    setReportList,
    false,
  );

  const fetchCsvtDownload = () => {
    try {
      fetchCustomerManagementReportDownload(filterState, dispatch);
    } catch (err) {}
  };

  return (
    <>
      <PruFilter
        title={Translation('report.customer.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'eventName',
            initialValue: filterState.eventName,
            displayName: Translation('report.customer.filter.event'),
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'agentOfficeCode',
            initialValue: filterState.agentOfficeCode,
            displayName: Translation('report.customer.filter.officeCode'),
          },
          {
            type: PruFilterItemType.DATE_RANGE,
            fieldFrom: 'requestedDateFrom',
            fieldTo: 'requestedDateTo',
            initialDateFrom: filterState.requestedDateFrom,
            initialDateTo: filterState.requestedDateTo,
            defaultDateFrom: defaultFilterValue.requestedDateFrom,
            defaultDateTo: defaultFilterValue.requestedDateTo,
            displayName: Translation('report.customer.filter.eventDate'),
            periodTime: { month: 3 },
          },
          {
            type: PruFilterItemType.CRITERIA,
            field: 'criteria',
            conditionArr: [
              {
                name: 'BDM',
                propName: 'agentBDMName',
                order: 1,
              },
              {
                name: 'Branch Code',
                propName: 'agentBranchCode',
                order: 2,
              },
              {
                name: 'Unit Code',
                propName: 'agentUnitCode',
                order: 3,
              },
              {
                name: 'Campaign Name',
                propName: 'campaignName',
                order: 4,
              },
              {
                name: 'Customer Name',
                propName: 'fullName',
                order: 5,
              },
              {
                name: 'Phone Number',
                propName: 'phone',
                order: 6,
              },
              {
                name: 'AG Code',
                propName: 'agentCode',
                order: 7,
              },
              {
                name: 'AG Name',
                propName: 'agentName',
                order: 8,
              },
              {
                name: 'AL Code',
                propName: 'leaderCode',
                order: 9,
              },
              {
                name: 'AL Name',
                propName: 'leaderName',
                order: 10,
              },
            ],
          },
        ]}
        onChangeFilter={(data) => {
          setFilterState({
            ...filterState,
            eventName: data.eventName,
            agentOfficeCode: data.agentOfficeCode,
            requestedDateFrom:
              !data.requestedDateFrom && !data.requestedDateTo
                ? moment(getDayEndUTC(new Date()).setMonth(getDayStartUTC(new Date()).getMonth() - 6)).toDate()
                : !data.requestedDateFrom && data.requestedDateTo
                ? moment(
                    getDayStartUTC(new Date(data.requestedDateTo)).setMonth(
                      getDayStartUTC(new Date(data.requestedDateTo)).getMonth() - 6,
                    ),
                  ).toDate()
                : data.requestedDateFrom,
            requestedDateTo:
              !data.requestedDateFrom && !data.requestedDateTo
                ? getDayEndUTC(new Date())
                : data.requestedDateFrom && !data.requestedDateTo
                ? moment(
                    getDayEndUTC(new Date(data.requestedDateFrom)).setMonth(
                      getDayEndUTC(new Date(data.requestedDateFrom)).getMonth() + 6,
                    ),
                  ).toDate()
                : data.requestedDateTo,
          });
        }}
        customDateRangeMonth={true}
        fetchData={refreshData}
        setCriterias={handleGetCriteria}
      />
      <PruTable
        disableBulkSelect
        dataSource={reportList?.items}
        isLoading={isLoading}
        onRefresh={refreshData}
        title={Translation('report.customer.list')}
        headerBtnDef={[
          {
            color: 'primary',
            title: Translation('report.common.export'),
            onClick: () => fetchCsvtDownload(),
          },
        ]}
        operationDef={[]}
        columnDef={[
          {
            keyIndex: 'bdm',
            align: 'center',
            displayName: 'BDM',
            renderData: (row) => (row.agentBDMName ? row.agentBDMName : '-'),
          },
          {
            keyIndex: 'OfficeCode',
            align: 'center',
            displayName: 'Office Code',
            renderData: (row) => (row.agentOfficeCode ? row.agentOfficeCode : '-'),
          },
          {
            keyIndex: 'branchCode',
            align: 'center',
            displayName: 'Branch Code',
            renderData: (row) => (row.agentBranchCode ? row.agentBranchCode : '-'),
          },
          {
            keyIndex: 'UnitCode',
            align: 'center',
            displayName: 'Unit Code',
            renderData: (row) => (row.agentUnitCode ? row.agentUnitCode : '-'),
          },
          {
            keyIndex: 'EventName',
            align: 'center',
            displayName: 'Event Name',
            renderData: (row) => row.materialName,
          },
          {
            keyIndex: 'campaignName',
            align: 'center',
            displayName: 'Campaign Name',
            renderData: (row) => (row.campaignName ? row.campaignName : '-'),
          },
          {
            keyIndex: 'CustomerName',
            align: 'center',
            displayName: 'Customer Name',
            renderData: (row) => row.fullName,
          },
          {
            keyIndex: 'PhoneNumber',
            align: 'center',
            displayName: 'Phone number',
            renderData: (row) => (row.phone ? row.phone : '-'),
          },
          {
            keyIndex: 'AgentCode',
            align: 'center',
            displayName: 'Agent Code',
            renderData: (row) => (row.agentCode ? row.agentCode : '-'),
          },
          {
            keyIndex: 'AgentName',
            align: 'center',
            displayName: 'Agent Name',
            renderData: (row) => (row.agentName ? row.agentName : '-'),
          },
          {
            keyIndex: 'AL Code',
            align: 'center',
            displayName: 'AL Code',
            renderData: (row) => (row.leaderCode ? row.leaderCode : '-'),
          },
          {
            keyIndex: 'AL Name',
            align: 'center',
            displayName: 'AL Name',
            renderData: (row) => (row.leaderName ? row.leaderName : '-'),
          },
          {
            keyIndex: 'EventDateStart',
            align: 'center',
            displayName: 'Event Date and Time Start',
            renderData: (row) =>
              row.materialEventStartDate ? moment(row.materialEventStartDate).format('DD/MM/YYYY HH:mm:ss') : '-',
          },
          {
            keyIndex: 'EventDateEnd',
            align: 'center',
            displayName: 'Event Date and Time End',
            renderData: (row) =>
              row.materialEventEndDate ? moment(row.materialEventEndDate).format('DD/MM/YYYY HH:mm:ss') : '-',
          },
          {
            keyIndex: 'RegisteredDate',
            align: 'center',
            displayName: 'Registered Date and Time',
            renderData: (row) => (row.registeredDate ? moment(row.registeredDate).format('DD/MM/YYYY HH:mm:ss') : '-'),
          },
          {
            keyIndex: 'CheckinDate',
            align: 'center',
            displayName: 'Checked-in Date and Time',
            renderData: (row) => (row.checkInDate ? moment(row.checkInDate).format('DD/MM/YYYY HH:mm:ss') : '-'),
          },
        ]}
        totalPages={reportList?.totalPages}
        totalRecords={reportList?.total}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default EventManagementCustomer;
