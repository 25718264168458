export enum PublishStatus {
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED"
}

export enum AchievementUploadFormMode {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view'
};

export enum StatusModel {
  Active = "Active",
  Terminated = "Terminated",
  NotFound = "NotFound"
}

export type AchievementUploadItem = {
  id: string
  agentCode: string
  status: string
  totalRow: number
  createdAt: string
  updatedAt: string
  lastUpdatedBy: string
  achievements: Achievement[]
};

export type Achievement = {
  id?: string
  year: string
  title: string[]
  isDuplicate?: boolean
}

export type AchievementFormState = {
  id: string | undefined
  agentCode: string | undefined
  achievements: Achievement[] | undefined
}

export type AchievementUploadDetail = {
  id?: string
  agentCode: string
  achievements: Achievement[]
  status: PublishStatus
}

export type AgentStatus = {
  agentCode: string,
  status: StatusModel
}

export type ErrorUploadFile = {
  duplicateAgentAndYear?: string[]
  notFoundAgent?: string[]
  termAgent?: string[]
}