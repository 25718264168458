import axios from 'axios';
import { Dispatch } from 'react';
import { apiClient, apiErrorHandler } from 'src/app/common/network';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

export const createCustomerCampaign = async (body: any, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .post(`${ADMIN_URL}/admin/customer-campaign/create`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });

  // axios
  //   .post(`http://localhost:1111/admin/customer-campaign/create`, body)
  //   .then((response) => response.data)
  //   .catch((err) => {
  //     throw apiErrorHandler(err, dispatch);
  //   })
};

export const getCustomerCampaign = async (body: any, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .post(`${ADMIN_URL}/admin/customer-campaign/list`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });

  //   axios
  //     .post(`http://localhost:1111/admin/customer-campaign/list`, body)
  //     .then((response) => response.data)
  //     .catch((err) => {
  //       throw apiErrorHandler(err, dispatch);
  //     })
};

export const getCustomerCampaignById = async (id: string, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .get(`${ADMIN_URL}/admin/customer-campaign/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });

  //   axios
  //     .get(`http://localhost:1111/admin/customer-campaign/${id}`)
  //     .then((response) => response.data)
  //     .catch((err) => {
  //       throw apiErrorHandler(err, dispatch);
  //     })
};

export const editCustomerCampaignById = async (id: string, body: any, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .put(`${ADMIN_URL}/admin/customer-campaign/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });

  //   axios
  //     .put(`http://localhost:1111/admin/customer-campaign/${id}`, body)
  //     .then((response) => response.data)
  //     .catch((err) => {
  //       throw apiErrorHandler(err, dispatch);
  //     })
};

export const editStatusCustomerCampaignById = async (id: string, dispatch?: Dispatch<any>): Promise<any> => {
  return apiClient
    .put(`${ADMIN_URL}/admin/customer-campaign/status/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });

  //   axios
  //     .put(`http://localhost:1111/admin/customer-campaign/status/${id}`)
  //     .then((response) => response.data)
  //     .catch((err) => {
  //       throw apiErrorHandler(err, dispatch);
  //     })
};
