import { Add, DeleteOutline } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { MuiColorInput } from 'mui-color-input';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: 20,
    marginBottom: 20,
    borderRadius: 5,
    backgroundColor: theme.palette.common.white,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  fieldContainer: {
    width: 120,
    boxSizing: 'border-box',
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  dateDivider: {
    marginLeft: 8,
    marginRight: 8,
  },
  sectionMargin: {
    marginBottom: 20,
  },
  divideMargin: {
    marginBottom: 10,
  },
  textArea: {
    lineHeight: 1.5,
    minHeight: 40,
  },
  root: {
    '& .MuiFormHelperText-root': {
      fontSize: '0.95rem',
      marginLeft: '0px !important',
    },
  },
}));

const defaultColor = '#FFFFFF';
const regexColor = new RegExp('^#[0-9A-F]{6}[0-9a-f]{0,2}$', 'i');

const TextColorList = ({ initValue, setValue, isDisabled, prefixName, actionType }: any) => {
  const { classes } = useStyles();
  const [textColorListState, setTextColorListState] = useState([defaultColor]);

  useEffect(() => {
    setTextColorListState([...initValue] || [defaultColor]);
  }, []);

  return (
    <div>
      {textColorListState.map((textColorItem: string, textColorIndex: any) => {
        return (
          <div
            className={classes.rowContainer}
            style={{ marginBottom: 5 }}
            key={`${prefixName}textColor${textColorIndex}`}
          >
            <div className={classes.fieldContainer}>
              {textColorIndex == 0 ? (
                <span className={classes.field}>
                  Text Color
                  <span className={classes.mandatory}>*</span> :
                </span>
              ) : (
                ''
              )}
            </div>
            <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  backgroundColor: textColorItem,
                  width: '20px',
                  height: '20px',
                  marginRight: '20px',
                  border: '1px solid #D2D2D2',
                }}
              ></div>
              {isDisabled ? (
                <div style={{ color: textColorItem }}>{textColorItem}</div>
              ) : (
                <MuiColorInput
                  isAlphaHidden
                  disabled={isDisabled}
                  name="color"
                  margin="dense"
                  variant="outlined"
                  format="hex"
                  value={textColorItem}
                  onChange={(textColorValue: any) => {
                    textColorListState[textColorIndex] = textColorValue;
                    setTextColorListState([...textColorListState]);

                    if (!regexColor.test(textColorValue)) {
                      textColorValue = defaultColor;
                    }

                    if (actionType === 'create') {
                      setValue(`${prefixName}.textColorList[${textColorIndex}]`, textColorValue);
                    } else {
                      setValue(`${prefixName}[${textColorIndex}]`, textColorValue);
                    }
                  }}
                />
              )}
              {textColorListState.length > 1 ? (
                <div style={{ paddingLeft: 5 }}>
                  <DeleteOutline
                    fontSize="large"
                    onClick={() => {
                      let tmp = [...textColorListState];
                      tmp.splice(textColorIndex, 1);
                      setTextColorListState(tmp);

                      if (actionType === 'create') {
                        setValue(`${prefixName}.textColorList`, tmp);
                      } else {
                        setValue(`${prefixName}`, tmp);
                      }
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        );
      })}

      {/* Button Add */}
      {textColorListState.length < 4 ? (
        <div className={classes.rowContainer} style={{ marginBottom: 5, marginTop: 9 }}>
          <div className={classes.fieldContainer}></div>
          <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                let tmp = textColorListState.concat([defaultColor]);
                setTextColorListState(tmp);

                if (actionType === 'create') {
                  setValue(`${prefixName}.textColorList`, tmp);
                } else {
                  setValue(`${prefixName}`, tmp);
                }
              }}
            >
              <Add />
              Add Color
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(TextColorList);
