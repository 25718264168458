import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import PruFilter, { PruFilterItemType } from 'src/app/common/components/PruTable/PruFilter';
import { PaginateListMore } from 'src/app/common/types/common-types';
import { useDataProvider } from 'src/app/common/utils';
import {
  fetchList,
  BdmBackgroundImageListParams,
  getAllCategory,
} from '../../../components/BdmBackgroundImage/network/bdmBackgroundImageCrud';
import {
  BdmBackgroundImageItem,
  PublishStatus,
} from '../../../components/BdmBackgroundImage/types/bdm-background-image-types';
import BdmBackgroundImageList from './components/BdmBackgroundImageList';
import { getDayStart, getDayEnd } from 'src/app/common/utils/time-utils';

type MdrtListingPageProps = {} & ParamsProps;

export const filterKeys = ['name', 'updatedBy'];

const initialState: BdmBackgroundImageListParams = {
  name: '',
  categoryId: '',
  type: '',
  page: 1,
  limit: 5,
};

const paramsInitiator = (initialParams?: Record<string, string>): BdmBackgroundImageListParams => {
  return initialParams
    ? {
        name: initialParams.name ? initialParams.name : '',
        categoryId: initialParams.categoryId ? initialParams.categoryId : '',
        type: initialParams.type ? initialParams.type : '',
        page: 1,
        limit: 5,
      }
    : initialState;
};

const BdmBackgroundImageListingPage: FC<MdrtListingPageProps> = ({ initialParams, onChangeQueryParams }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState<BdmBackgroundImageListParams>(paramsInitiator(initialParams));
  const [itemList, setItemList] = useState<PaginateListMore<BdmBackgroundImageItem>>();

  const { isLoading, refreshData } = useDataProvider<PaginateListMore<BdmBackgroundImageItem>>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchList(filterState, dispatch);
      } catch (err) {}
    },
    setItemList,
    false,
  );

  const [categoryList, setCategoryList] = useState<any>();
  useDataProvider<any>(
    async () => {
      try {
        return await getAllCategory(dispatch);
      } catch (err) {}
    },
    setCategoryList,
    false,
  );
  return (
    <>
      <PruFilter
        title={Translation('component.formTitle.bdm-templete-background.filter')}
        itemDef={[
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'name',
            initialValue: filterState.name,
            displayName: Translation('component.formLabel.name'),
          },
          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 200 },
            field: 'categoryId',
            initialValue: filterState.categoryId,
            displayName: Translation('component.formLabel.category'),
            choices: [
              { displayName: 'All', value: '' },
              ...(categoryList?.map((category: { name: any; _id: any }) => {
                return {
                  displayName: category.name,
                  value: category._id,
                };
              }) || []),
            ],
          },

          {
            type: PruFilterItemType.DROPDOWN,
            style: { width: 200 },
            field: 'type',
            initialValue: filterState.type,
            displayName: Translation('component.formLabel.type'),
            choices: [
              { displayName: 'All', value: '' },
              { displayName: 'GP', value: 'GP' },
              { displayName: 'BOP', value: 'BOP' },
            ],
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            name: data.name,
            categoryId: data.categoryId,
            type: data.type,
          })
        }
        fetchData={refreshData}
      />
      <BdmBackgroundImageList
        isLoading={isLoading}
        onRefresh={refreshData}
        itemList={itemList}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page,
            limit: rowsPerPage,
          });
          refreshData();
        }}
      />
    </>
  );
};

export default BdmBackgroundImageListingPage;
