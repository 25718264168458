import { ETypeOfView } from 'src/app/common/types/view-types';

export const OPERATION_BASE_PATH = '/management';
export const BANNER_BASE_PATH = '/banner';
export const POPUP_BASE_PATH = '/popup';


//  These constants map to aside menu, header menu to display title
export const OPERATION_TITLE = "title.operation";
export const OPERATION_BANNER_TITLE = "title.operation.banner";
export const OPERATION_POPUP_TITLE = "title.operation.popup";


//  These constants map to permission control, identical to permission enum defined below
export const OPERATION_BANNER_NAMESPACE = "OPERATION-BANNER";
export const OPERATION_POPUP_NAMESPACE = "OPERATION-POPUP";

export enum AdminOperationPermissionType {
  OPERATION_BANNER_READ = "READ_ANY_OPERATION-BANNER",
  OPERATION_BANNER_CREATE = "CREATE_ANY_OPERATION-BANNER",
  OPERATION_BANNER_UPDATE = "UPDATE_ANY_OPERATION-BANNER",

  OPERATION_POPUP_READ = "READ_ANY_OPERATION-POPUP",
  OPERATION_POPUP_CREATE = "CREATE_ANY_OPERATION-POPUP",
  OPERATION_POPUP_UPDATE = "UPDATE_ANY_OPERATION-POPUP",
};

export const supportLangList = ['vi', 'en'];

export const mainLang='vi';
export const subLang='en';

export const LINK_TYPE_TRANSLATE_MAP ={
  'no': 'component.formSelectItem.no-link',
  'app': 'component.formSelectItem.page-in-app',
  'external': 'component.formSelectItem.external-url'
}

export const FREQUENCY_TRANSLTAE_MAP = {
  'once': 'component.formSelectItem.only-once',
  'login': 'component.formSelectItem.after-login',
  'daily': 'component.formSelectItem.daily-display'
}

export const MAX_BANNER_PUBLISHED_LIMIT=10;
export const MAX_POPUP_PUBLISHED_LIMIT=1;

export const typeOfViewOptions = [
  { value: ETypeOfView.AllTraditionalAgents, label: 'all-traditional-agents' },
  { value: ETypeOfView.Pruventure, label: 'pruventure' },
  { value: ETypeOfView.GroupOfUsers, label: 'group-of-users' },
]