import { forEach } from 'lodash';
import { PaginateListMore } from '../../../common/types/common-types';
import { apiClient, apiErrorHandler } from 'src/app/common/network';
import { Dispatch } from 'react';
import { IReportFilter, IReportItem } from '../types/customer-feedback-types';

const ADMIN_URL = `${window.envConfig['REACT_APP_APIM_BASE_URL']}${window.envConfig['REACT_APP_APIM_ADMIN']}`;

export const fetchExportFileReport = (params: IReportFilter, dispatch?: Dispatch<any>): void => {
  // const paramsUrl = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
  const url = `${ADMIN_URL}/report/customer-feedback/serving-survey-opening/export`;

  apiClient
    .post(url, params, { responseType: 'text' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data], { type: 'text/csv;charset=utf-8;' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'serving-survey-openning-report-' + new Date().toISOString() + '.csv');
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchReport = async (
  params: IReportFilter,
  dispatch?: Dispatch<any>,
): Promise<PaginateListMore<IReportItem>> => {
  // const paramsUrl = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
  const url = `${ADMIN_URL}/report/customer-feedback/serving-survey-opening`;

  return apiClient
    .post(url, params)
    .then((response) => response.data)
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
